// eslint-disable-next-line no-restricted-imports
import { YearMonth, YMInterval } from '../utils/YearMonth';
import type { FilterValue, GQBiDataResult } from '../customScalarGqlTypes.ts';
import type { Expression } from '@watershed/shared-universal/biV2';
import { DateTime } from 'luxon';
import { JSONSchema7 } from 'json-schema';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Untyped according to the GraphQL spec, but typed according to codegen. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  BiDataResult: { input: GQBiDataResult; output: GQBiDataResult };
  BiFromClause: { input: any; output: any };
  /**
   * The 'Date' scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: Date; output: Date };
  /**
   * The 'DateTime' scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: Date; output: Date };
  Expression: { input: Expression; output: Expression };
  /** Untyped according to the GraphQL spec, but typed according to codegen as string | boolean. see it's type def in workspaces/shared-universal/customScalarGqlTypes.ts */
  FilterValue: { input: FilterValue; output: FilterValue };
  Int53: { input: number; output: number };
  /**
   * The 'JSONSchema' scalar type represents a JSONSchema
   * value as specified by
   * [JSONSchema Draft 7](https://datatracker.ietf.org/doc/html/draft-handrews-json-schema-01).
   */
  JSONSchema: { input: JSONSchema7; output: JSONSchema7 };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any };
  /**
   * Similar in concept to JSONString, but not (double-)encoded as a string. It's
   * just whatever data the server decided to send you. I hope you can make sense
   * of it!
   */
  UntypedData: { input: any; output: any };
  YMInterval: { input: YMInterval; output: YMInterval };
  YearMonth: { input: YearMonth; output: YearMonth };
};

export type GQAiAgentChatMessage = {
  __typename?: 'AIAgentChatMessage';
  commandRequests: Array<GQAiAgentCommandRequest>;
  content: Scalars['String']['output'];
  sender: GQAiAgentChatSenderType;
  timestamp: Scalars['Date']['output'];
};

export const GQAiAgentChatSenderType = {
  Ai: 'ai',
  System: 'system',
  User: 'user',
} as const;

export type GQAiAgentChatSenderType =
  (typeof GQAiAgentChatSenderType)[keyof typeof GQAiAgentChatSenderType];
export type GQAiAgentCommand = {
  __typename?: 'AIAgentCommand';
  actionName: Scalars['String']['output'];
  args: Scalars['JSONString']['output'];
  id: Scalars['String']['output'];
};

export type GQAiAgentCommandRequest = {
  __typename?: 'AIAgentCommandRequest';
  command: GQAiAgentCommand;
  status: GQCommandExecutionStatus;
};

export type GQAbbreviatedPlanSubtargetInput = {
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  shouldDelete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQAbbreviatedPlanTargetInput = {
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  shouldDelete?: InputMaybe<Scalars['Boolean']['input']>;
  subtargets?: InputMaybe<Array<GQAbbreviatedPlanSubtargetInput>>;
};

export type GQAcceptFinanceImportDiffInput = {
  financeImportId: Scalars['ID']['input'];
};

export type GQAcceptFinanceImportDiffPayload = {
  __typename?: 'AcceptFinanceImportDiffPayload';
  financeImport: GQFinanceImport;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQAcceptOrgStructureVersionUploadInput = {
  orgStructureVersionUploadId: Scalars['ID']['input'];
};

export type GQAcceptOrgStructureVersionUploadPayload = {
  __typename?: 'AcceptOrgStructureVersionUploadPayload';
  orgStructureVersionUpload: GQOrgStructureVersionUpload;
};

export type GQAcknowledgeReportAnswerWarningInput = {
  id: Scalars['ID']['input'];
};

export type GQActiveOrganization = {
  __typename?: 'ActiveOrganization';
  canAccessCorporate: Scalars['Boolean']['output'];
  canAccessFinance: Scalars['Boolean']['output'];
  companyId: Scalars['ID']['output'];
  country: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  demoOrg: Scalars['Boolean']['output'];
  domains: Array<Scalars['String']['output']>;
  financeSavedViews: Array<GQFinanceSavedView>;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  funds: Maybe<Array<GQFund>>;
  helpChannel: Maybe<Scalars['String']['output']>;
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  loginAsUser: Maybe<GQUser>;
  logoUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  region: GQRegion;
  sessionId: Scalars['String']['output'];
  sessionTimeoutMinutes: Maybe<Scalars['Int']['output']>;
  stagingOrg: Maybe<Scalars['Boolean']['output']>;
  testOrg: Scalars['Boolean']['output'];
  user: GQUser;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
  userPermissionsV2: Array<GQUserPermission>;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQActivityBasedInitiativeImpact = {
  __typename?: 'ActivityBasedInitiativeImpact';
  filterableReductionDifference: Array<GQFilterableReducibleFootprintAggregate>;
  reducedKgco2eFraction: Scalars['Float']['output'];
};

export type GQActivityContextMetadata = {
  __typename?: 'ActivityContextMetadata';
  activityDatasets: Array<GQActivityDataset>;
};

export type GQActivityDataset = {
  __typename?: 'ActivityDataset';
  businessActivityType: Scalars['String']['output'];
  datasets: Array<GQDataset>;
  lastUpdater: Maybe<GQUser>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  uploads: Array<GQUserUpload>;
};

export type GQActivityRecord = {
  __typename?: 'ActivityRecord';
  activityItemCreatedAt: Scalars['DateTime']['output'];
  datasets: Array<GQDataset>;
  displayName: Scalars['String']['output'];
  stableId: Scalars['ID']['output'];
};

export const GQActivityTimelineItemType = {
  AssignedTaskApprover: 'AssignedTaskApprover',
  AssignedUploader: 'AssignedUploader',
  BuildingsAdded: 'BuildingsAdded',
  BuildingsApproved: 'BuildingsApproved',
  BuildingsDeleted: 'BuildingsDeleted',
  BuildingsEdited: 'BuildingsEdited',
  CommentReply: 'CommentReply',
  CommentRoot: 'CommentRoot',
  CtsFormClosed: 'CtsFormClosed',
  CtsFormCreated: 'CtsFormCreated',
  CtsFormEntryCreated: 'CtsFormEntryCreated',
  DeletedFile: 'DeletedFile',
  EditedFile: 'EditedFile',
  RemovedTaskApprover: 'RemovedTaskApprover',
  RemovedUploader: 'RemovedUploader',
  SupportIssueReply: 'SupportIssueReply',
  SupportIssueRoot: 'SupportIssueRoot',
  TagAdded: 'TagAdded',
  TagRemoved: 'TagRemoved',
  TaskApproved: 'TaskApproved',
  TaskRejected: 'TaskRejected',
  TaskSubmittedForApproval: 'TaskSubmittedForApproval',
  TaskUnlockRequested: 'TaskUnlockRequested',
  TaskUnlocked: 'TaskUnlocked',
  UploadedFile: 'UploadedFile',
} as const;

export type GQActivityTimelineItemType =
  (typeof GQActivityTimelineItemType)[keyof typeof GQActivityTimelineItemType];
export type GQActivityTypeSchema = {
  __typename?: 'ActivityTypeSchema';
  activityType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  schema: GQCanonicalSchema;
};

export type GQAddCompaniesToPeerCompanyGroupsInput = {
  companyIds: Array<Scalars['ID']['input']>;
  peerCompanyGroupIds: Array<Scalars['ID']['input']>;
};

export type GQAddCompaniesToPeerCompanyGroupsPayload = {
  __typename?: 'AddCompaniesToPeerCompanyGroupsPayload';
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
};

export type GQAddDataApprovalCommentInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  comment: Scalars['String']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQAddDataApprovalCommentPayload = {
  __typename?: 'AddDataApprovalCommentPayload';
  dataIssue: GQDataIssue;
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQAddEngagementTaskCommentInput = {
  comment: Scalars['String']['input'];
  engagementTaskId: Scalars['ID']['input'];
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQAddEngagementTaskCommentPayload = {
  __typename?: 'AddEngagementTaskCommentPayload';
  comment: GQEngagementTaskComment;
};

export type GQAddHeaderToCustomReportInput = {
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  kind: GQCustomReportHeaderKind;
  reportConfigId: Scalars['ID']['input'];
};

export type GQAddHeaderToCustomReportPayload = {
  __typename?: 'AddHeaderToCustomReportPayload';
  report: GQReport;
};

export type GQAddMetricsToCustomReportInput = {
  biSavedViewStableIds: Array<Scalars['String']['input']>;
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  newBiSavedViews?: InputMaybe<Array<GQCreateBiSavedViewInput>>;
  reportConfigId: Scalars['ID']['input'];
};

export type GQAddMetricsToCustomReportPayload = {
  __typename?: 'AddMetricsToCustomReportPayload';
  report: GQReport;
};

export type GQAddReportQuestionNoteInput = {
  memberUserIds: Array<Scalars['String']['input']>;
  note: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
};

export type GQAddReportQuestionNotePayload = {
  __typename?: 'AddReportQuestionNotePayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQAddUserUploadCandidatesToFileFeedEmbeddedSessionInput = {
  embeddedSessionToken: Scalars['String']['input'];
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQAdditionalQuestionInput = {
  answer: Scalars['JSONString']['input'];
  questionId: GQIngestionQuestionKey;
};

export type GQAdditionalQuestionResponse = {
  __typename?: 'AdditionalQuestionResponse';
  answer: Scalars['JSONString']['output'];
  questionId: GQIngestionQuestionKey;
};

export const GQAggregateKind = {
  CustomIntensity: 'CustomIntensity',
  Electricity: 'Electricity',
  HeadcountIntensity: 'HeadcountIntensity',
  LocationBased: 'LocationBased',
  OriginalTotal: 'OriginalTotal',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
  PercentageOfTotalFootprint: 'PercentageOfTotalFootprint',
  RevenueIntensity: 'RevenueIntensity',
  Total: 'Total',
} as const;

export type GQAggregateKind =
  (typeof GQAggregateKind)[keyof typeof GQAggregateKind];
export type GQAliasedBiTableQuery = {
  alias: Scalars['String']['input'];
  from: Scalars['BiFromClause']['input'];
  groupBy?: InputMaybe<Array<Scalars['Expression']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Scalars['Expression']['input']>>;
  select: Array<Scalars['Expression']['input']>;
  where?: InputMaybe<Array<Scalars['Expression']['input']>>;
};

export type GQApiEditor = {
  __typename?: 'ApiEditor';
  apiKeyId: Scalars['ID']['output'];
};

export type GQApiKey = {
  __typename?: 'ApiKey';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<GQApiKeyRoleAssignment>;
  secret: Scalars['String']['output'];
};

export type GQApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  edges: Array<Maybe<GQApiKeyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQApiKeyEdge = {
  __typename?: 'ApiKeyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQApiKey>;
};

export type GQApiKeyRoleAssignment = {
  __typename?: 'ApiKeyRoleAssignment';
  apiKey: GQApiKey;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQApiUpload = {
  __typename?: 'ApiUpload';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQAppendOnlyUpdateCsrdDataRequirementsConfigInput = {
  configJson?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  reportId?: InputMaybe<Scalars['String']['input']>;
};

export type GQAppendOnlyUpdateCsrdDataRequirementsConfigPayload = {
  __typename?: 'AppendOnlyUpdateCsrdDataRequirementsConfigPayload';
  payload: GQCsrdDataRequirementsConfig;
};

export type GQApplyIntegrationConnectionInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQApplyIntegrationConnectionPayload = {
  __typename?: 'ApplyIntegrationConnectionPayload';
  datasources: Array<GQDatasource>;
  integrationConnection: GQIntegrationConnection;
};

export const GQApprovalStatus = {
  Approved: 'Approved',
  NotReadyForApproval: 'NotReadyForApproval',
  SubmittedForApproval: 'SubmittedForApproval',
} as const;

export type GQApprovalStatus =
  (typeof GQApprovalStatus)[keyof typeof GQApprovalStatus];
export type GQApprovalTarget = {
  approvalStatus: GQApprovalStatus;
  approvers: Array<GQUserWithApprovalStatus>;
  id: Scalars['ID']['output'];
  lockState: GQApprovalTargetLockState;
};

export const GQApprovalTargetLockState = {
  Locked: 'Locked',
  UnlockRequested: 'UnlockRequested',
  Unlocked: 'Unlocked',
} as const;

export type GQApprovalTargetLockState =
  (typeof GQApprovalTargetLockState)[keyof typeof GQApprovalTargetLockState];
export type GQApproveAllValueMappingRecordsInput = {
  configId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  ruleId?: InputMaybe<Scalars['String']['input']>;
};

export type GQApproveAllValueMappingRecordsPayload = {
  __typename?: 'ApproveAllValueMappingRecordsPayload';
  success: Scalars['Boolean']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQApproveDataInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQApproveDataPayload = {
  __typename?: 'ApproveDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQApproveReportQuestionInstanceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reportQuestionInstanceId: Scalars['ID']['input'];
};

export type GQApproveReportQuestionInstancePayload = {
  __typename?: 'ApproveReportQuestionInstancePayload';
  reportQuestionInstance: GQReportQuestionInstance;
  success: Scalars['Boolean']['output'];
};

export type GQArchiveReportInput = {
  reportId: Scalars['ID']['input'];
};

export type GQArchiveReportPayload = {
  __typename?: 'ArchiveReportPayload';
  report: GQReport;
};

export type GQArchiveUploadInput = {
  uploadVersionId: Scalars['ID']['input'];
};

export type GQArchiveUploadPayload = {
  __typename?: 'ArchiveUploadPayload';
  upload: GQUpload;
};

export type GQAssetAutoMatchData = {
  __typename?: 'AssetAutoMatchData';
  autoMatched: Maybe<GQAssetAutoMatched>;
  autoMatches: Array<GQCompanyAutoMatch>;
  id: Scalars['ID']['output'];
  ranAutoMapping: Scalars['Boolean']['output'];
};

export type GQAssetAutoMatched = {
  __typename?: 'AssetAutoMatched';
  confidenceScore: Scalars['Float']['output'];
};

export type GQAssetComment = {
  __typename?: 'AssetComment';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  attachments: Maybe<Array<GQAssetCommentAttachment>>;
  commentHistory: Maybe<Scalars['UntypedData']['output']>;
  commentText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastEdited: Maybe<Scalars['DateTime']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userId: Scalars['ID']['output'];
};

export type GQAssetCommentAttachment = {
  __typename?: 'AssetCommentAttachment';
  assetCommentId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQAssetCorporate = GQIFinanceAsset & {
  __typename?: 'AssetCorporate';
  anonymousCompanyUuid: Maybe<Scalars['String']['output']>;
  /** @deprecated fetch in bulk query instead */
  assetAutoMatchData: Maybe<GQAssetAutoMatchData>;
  assetComments: Array<GQAssetComment>;
  assetFinancialData: Maybe<GQAssetFinancialData>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  benchmarks: Array<GQBenchmark>;
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  company: Maybe<GQCompany>;
  companyBea: Maybe<Scalars['String']['output']>;
  companyDunsNumber: Maybe<Scalars['String']['output']>;
  companyEin: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIsin: Maybe<Scalars['String']['output']>;
  companyLei: Maybe<Scalars['String']['output']>;
  companyLocalRegistryId: Maybe<Scalars['String']['output']>;
  companySAndPId: Maybe<Scalars['String']['output']>;
  companyTicker: Maybe<Scalars['String']['output']>;
  companyUrl: Maybe<Scalars['String']['output']>;
  companyVatId: Maybe<Scalars['String']['output']>;
  contacts: Array<GQSupplierContact>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  disclosures: Array<GQDisclosure>;
  /** @deprecated Fetch from emissionsYear instead, deprecate after 2/21/25 */
  emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
  engagementTasks: Array<GQEngagementTask>;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  naicsCode: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  selfBenchmark: Maybe<GQBenchmark>;
  /** @deprecated use tasks */
  surveys: Array<GQCompanySurvey>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetCorporateAssetFinancialDataArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateEmissionsByGhgCategoryArgs = {
  emissionsYearId: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type GQAssetCorporateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetCorporateSelfBenchmarkArgs = {
  year: Scalars['Int']['input'];
};

export type GQAssetFinancialData = {
  __typename?: 'AssetFinancialData';
  assetValue: Maybe<Scalars['Float']['output']>;
  assetValueCurrencyCode: Maybe<GQCurrencyCode>;
  id: Scalars['ID']['output'];
  isPrivate: Maybe<Scalars['Boolean']['output']>;
  revenue: Maybe<Scalars['Float']['output']>;
  revenueCurrencyConversionRate: Maybe<Scalars['Float']['output']>;
  usedSAndPAssetValue: Maybe<Scalars['Boolean']['output']>;
  usedSAndPRevenue: Maybe<Scalars['Boolean']['output']>;
};

export type GQAssetGroup = GQIFinanceAsset & {
  __typename?: 'AssetGroup';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Array<GQAssetGroupAsset>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetGroupAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetGroupAsset =
  | GQAssetCorporate
  | GQAssetGroup
  | GQAssetRealEstate;

export type GQAssetIndustryCodeData = {
  __typename?: 'AssetIndustryCodeData';
  id: Scalars['ID']['output'];
  industryCode: Maybe<Scalars['String']['output']>;
  usedSAndPIndustryCode: Maybe<Scalars['Boolean']['output']>;
};

export type GQAssetPersonalMotorVehicleInsurance = GQIFinanceAsset & {
  __typename?: 'AssetPersonalMotorVehicleInsurance';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetPersonalMotorVehicleInsuranceAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetPersonalMotorVehicleInsuranceRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstate = GQIFinanceAsset & {
  __typename?: 'AssetRealEstate';
  assetComments: Array<GQAssetComment>;
  assetGroup: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  buildingKind: Maybe<Scalars['String']['output']>;
  /** @deprecated Use name instead */
  buildingName: Scalars['String']['output'];
  buildingSizeUnit: Maybe<GQBuildingSizeUnit>;
  city: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use countryAlpha2 instead */
  country: Maybe<Scalars['String']['output']>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  postalCode: Maybe<Scalars['String']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  /** @deprecated Use buildingSizeUnit instead */
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetRealEstateAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetRealEstateRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBond = GQIFinanceAsset & {
  __typename?: 'AssetSovereignBond';
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  sovereignEntity: Scalars['String']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQAssetSovereignBondAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQAssetSovereignBondRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export const GQAssetYearStatus = {
  Contracting: 'Contracting',
  Estimation: 'Estimation',
  Measurement: 'Measurement',
  Survey: 'Survey',
} as const;

export type GQAssetYearStatus =
  (typeof GQAssetYearStatus)[keyof typeof GQAssetYearStatus];
export type GQAssetsCorporateConnection = {
  __typename?: 'AssetsCorporateConnection';
  edges: Array<GQAssetsCorporateEdge>;
  pageInfo: GQPageInfo;
};

export type GQAssetsCorporateEdge = {
  __typename?: 'AssetsCorporateEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAssetCorporate>;
};

export type GQAssignFacilitiesToTasksInput = {
  allowReassignment?: InputMaybe<Scalars['Boolean']['input']>;
  includeListBuildingIds: Array<Scalars['String']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  userUploadTaskIds: Array<Scalars['ID']['input']>;
};

export type GQAssignFacilitiesToTasksPayload = {
  __typename?: 'AssignFacilitiesToTasksPayload';
  facilitiesAssigned: Array<GQFacility>;
  facilitiesRequiringConfirmation: Array<GQFacility>;
  lockedDestinationTasks: Array<GQUserUploadTask>;
  lockedSourceTasks: Array<GQUserUploadTask>;
  measurementProject: GQMeasurementProject;
};

export type GQAssignMeasurementTaskInput = {
  assigneeIds: Array<Scalars['ID']['input']>;
  kind: Scalars['String']['input'];
  measurementTaskId: Scalars['ID']['input'];
  objectId: Scalars['String']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
};

export type GQAssignMeasurementTaskPayload = {
  __typename?: 'AssignMeasurementTaskPayload';
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  organization: GQOrganization;
};

export type GQAssignReportQuestionContainerPreviewEmailInput = {
  assigneeIds: Array<Scalars['String']['input']>;
  reportId: Scalars['String']['input'];
  /** @deprecated Not used anymore, delete 2024-09-26 */
  reportObjectType?: InputMaybe<GQReportObjectType>;
  reportShortName: Scalars['String']['input'];
};

export type GQAssignToContainerInput = {
  assigneeId: Scalars['ID']['input'];
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  reportShortName: Scalars['String']['input'];
  shouldSendEmail: Scalars['Boolean']['input'];
};

export type GQAssignToContainerPayload = {
  __typename?: 'AssignToContainerPayload';
  assignedCount: Scalars['Float']['output'];
  container: GQReportQuestionContainer;
};

export type GQAssignUserUploadTaskInput = {
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQAssignUserUploadTaskPayload = {
  __typename?: 'AssignUserUploadTaskPayload';
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQAssumptionDescription = {
  __typename?: 'AssumptionDescription';
  assumptionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleConversionDescription: Scalars['String']['output'];
};

export type GQAssumptionRecordForDashboard = {
  __typename?: 'AssumptionRecordForDashboard';
  assumptionDescription: Maybe<GQAssumptionDescription>;
  assumptionSource: GQAssumptionSource;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  endMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  inputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  inputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  methodologySourceId: Maybe<Scalars['String']['output']>;
  outputEmissionsSpecifier: Maybe<Scalars['String']['output']>;
  outputEmissionsSubspecifier: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
  startMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQAssumptionSource = {
  __typename?: 'AssumptionSource';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasMultiplierLicenseRestrictions: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Scalars['String']['output'];
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQAttachmentMetadata = {
  __typename?: 'AttachmentMetadata';
  fileMetadataId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQAuditLogConnection = {
  __typename?: 'AuditLogConnection';
  edges: Array<Maybe<GQAuditLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQAuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQAuditLogEntry>;
};

export type GQAuditLogEntry = {
  __typename?: 'AuditLogEntry';
  appType: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  eventMetadata: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Possibly sensitive data, use resolved fields instead. Remove EOW Aug 14 */
  loggingContext: Maybe<Scalars['JSONString']['output']>;
  userDisplayName: Maybe<Scalars['String']['output']>;
};

export type GQAuditReportColumn = {
  __typename?: 'AuditReportColumn';
  column: Scalars['String']['output'];
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQAuditReportMetadataInput = {
  footprintEmissionsKind: GQFootprintEmissionsKind;
};

export type GQAuditReportMetadataPayload = {
  __typename?: 'AuditReportMetadataPayload';
  columns: Array<GQAuditReportColumn>;
};

export type GQAutoMapAssetsPayload = {
  __typename?: 'AutoMapAssetsPayload';
  workflowId: Scalars['String']['output'];
};

export type GQAutoMapSuppliersInput = {
  isPreMeasurement: Scalars['Boolean']['input'];
  postMeasurementParams?: InputMaybe<GQAutoMapSuppliersPostMeasurementInput>;
  preMeasurementParams?: InputMaybe<GQAutoMapSuppliersPreMeasurementInput>;
};

export type GQAutoMapSuppliersPayload = {
  __typename?: 'AutoMapSuppliersPayload';
  workflowId: Scalars['String']['output'];
};

export type GQAutoMapSuppliersPostMeasurementInput = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId?: InputMaybe<Scalars['String']['input']>;
  footprintVersion?: InputMaybe<Scalars['String']['input']>;
};

export type GQAutoMapSuppliersPreMeasurementInput = {
  measurementProjectId: Scalars['String']['input'];
  vendorMatchingTaskId: Scalars['String']['input'];
};

export type GQAutoMappingButtonConditions = {
  __typename?: 'AutoMappingButtonConditions';
  isDemoOrTestOrg: Scalars['Boolean']['output'];
  noEligibleSuppliersByEmissions: Maybe<Scalars['Boolean']['output']>;
};

export const GQAutoMappingEntity = {
  CorporateAsset: 'CorporateAsset',
  Supplier: 'Supplier',
} as const;

export type GQAutoMappingEntity =
  (typeof GQAutoMappingEntity)[keyof typeof GQAutoMappingEntity];
export type GQAutoMatched = {
  __typename?: 'AutoMatched';
  confidenceScore: Scalars['Float']['output'];
};

export type GQBackgroundJob = GQIdInterface & {
  __typename?: 'BackgroundJob';
  args: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: Maybe<GQUser | GQWatershedEmployee>;
  durationSeconds: Maybe<Scalars['Float']['output']>;
  error: Maybe<GQSerializableError>;
  finishedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  queueName: GQBackgroundJobQueueName;
  remainingPreemptions: Maybe<Scalars['Int']['output']>;
  result: Maybe<Scalars['JSONString']['output']>;
  state: GQBackgroundJobState;
  /** @deprecated Use 'creator' instead */
  user: Maybe<GQUser>;
};

export const GQBackgroundJobQueueName = {
  FinanceEstimations: 'financeEstimations',
  UserWaiting: 'userWaiting',
} as const;

export type GQBackgroundJobQueueName =
  (typeof GQBackgroundJobQueueName)[keyof typeof GQBackgroundJobQueueName];
export const GQBackgroundJobState = {
  Done: 'DONE',
  Error: 'ERROR',
  Failed: 'FAILED',
  Processing: 'PROCESSING',
  Queued: 'QUEUED',
} as const;

export type GQBackgroundJobState =
  (typeof GQBackgroundJobState)[keyof typeof GQBackgroundJobState];
export type GQBackgroundJobStatus = GQIdInterface & {
  __typename?: 'BackgroundJobStatus';
  error: Maybe<GQSerializableError>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  state: GQBackgroundJobState;
};

export type GQBartColumnDetails = {
  __typename?: 'BartColumnDetails';
  columnName: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  emissionsModelEvaluationId: Scalars['String']['output'];
};

export type GQBartInstanceTableReference = GQBaseTableReference & {
  __typename?: 'BartInstanceTableReference';
  alias: Scalars['String']['output'];
  areStableIds: Maybe<Scalars['Boolean']['output']>;
  bartIds: Maybe<Array<Scalars['String']['output']>>;
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQBartInstancesToRowIds = {
  __typename?: 'BartInstancesToRowIds';
  instanceId: Scalars['ID']['output'];
  rowIds: Array<Scalars['Int']['output']>;
};

export type GQBartStableIdWithBatNameInput = {
  bartStableId: Scalars['String']['input'];
  businessActivityTypeName: Scalars['String']['input'];
};

export type GQBartTableReference = GQBaseTableReference & {
  __typename?: 'BartTableReference';
  alias: Scalars['String']['output'];
  datasourceIds: Maybe<Array<Scalars['String']['output']>>;
  measurementProjectIds: Maybe<Array<Scalars['String']['output']>>;
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQBaseTableReference = {
  alias: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQBatchSetCustomFieldForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
  ingestionCustomFieldValue: Scalars['String']['input'];
};

export type GQBatchSetCustomFieldForBuildingsPayload = {
  __typename?: 'BatchSetCustomFieldForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchSetCustomFieldForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
  ingestionCustomFieldValue: Scalars['String']['input'];
};

export type GQBatchSetCustomFieldForDatasourcesPayload = {
  __typename?: 'BatchSetCustomFieldForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatchSetOrgUnitForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  orgUnitStableId: Scalars['ID']['input'];
  orgUnitTypeStableId: Scalars['ID']['input'];
};

export type GQBatchSetOrgUnitForBuildingsPayload = {
  __typename?: 'BatchSetOrgUnitForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchSetOrgUnitForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  orgUnitStableId: Scalars['ID']['input'];
  orgUnitTypeStableId: Scalars['ID']['input'];
};

export type GQBatchSetOrgUnitForDatasourcesPayload = {
  __typename?: 'BatchSetOrgUnitForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatchUnsetCustomFieldForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBatchUnsetCustomFieldForBuildingsPayload = {
  __typename?: 'BatchUnsetCustomFieldForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchUnsetCustomFieldForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBatchUnsetCustomFieldForDatasourcesPayload = {
  __typename?: 'BatchUnsetCustomFieldForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatchUnsetOrgUnitForBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  orgUnitTypeStableId: Scalars['ID']['input'];
};

export type GQBatchUnsetOrgUnitForBuildingsPayload = {
  __typename?: 'BatchUnsetOrgUnitForBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
};

export type GQBatchUnsetOrgUnitForDatasourcesInput = {
  datasourceIds: Array<Scalars['ID']['input']>;
  orgUnitTypeStableId: Scalars['ID']['input'];
};

export type GQBatchUnsetOrgUnitForDatasourcesPayload = {
  __typename?: 'BatchUnsetOrgUnitForDatasourcesPayload';
  datasources: Array<GQDatasource>;
};

export type GQBatchUpdateReportQuestionAssigneesInput = {
  actions: Array<GQReportQuestionAssigneeActionInput>;
  questionIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQBatchUpdateReportQuestionAssigneesPayload = {
  __typename?: 'BatchUpdateReportQuestionAssigneesPayload';
  questions: Array<GQReportQuestion>;
  success: Scalars['Boolean']['output'];
};

export type GQBenchmark = {
  __typename?: 'Benchmark';
  amountKgco2e: Scalars['Int53']['output'];
  companyId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  industries: Array<Scalars['String']['output']>;
  kgco2eByScope: Array<GQBenchmarkScopeBreakdownDataPoint>;
  kind: GQBenchmarkKind;
  name: Scalars['String']['output'];
  normalizationKind: GQAggregateKind;
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  reportId: Maybe<Scalars['String']['output']>;
  reportingYear: Scalars['Int']['output'];
  scope2Source: Maybe<GQScope2Source>;
  source: Maybe<GQExternalReportType>;
};

export type GQBenchmarkBreakdownDataPoint = {
  __typename?: 'BenchmarkBreakdownDataPoint';
  businessCategory: Scalars['String']['output'];
  kgco2e: Scalars['Int']['output'];
};

export const GQBenchmarkKind = {
  /** Another business. */
  Business: 'Business',
  IndustryAverage: 'IndustryAverage',
  /** The active organization itself. */
  Self: 'Self',
} as const;

export type GQBenchmarkKind =
  (typeof GQBenchmarkKind)[keyof typeof GQBenchmarkKind];
export type GQBenchmarkScopeBreakdownDataPoint = {
  __typename?: 'BenchmarkScopeBreakdownDataPoint';
  kgco2e: Maybe<Scalars['Float']['output']>;
  scope: GQGhgScope;
};

export type GQBiActivityQueryContextInput = {
  businessActivityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  datasourceIds?: InputMaybe<Array<Scalars['String']['input']>>;
  filterSchemaToBartsMatchingFilter?: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
};

export const GQBiAggregateMethod = {
  Avg: 'avg',
  CountDistinct: 'countDistinct',
  MonthlyAvg: 'monthlyAvg',
  Sum: 'sum',
} as const;

export type GQBiAggregateMethod =
  (typeof GQBiAggregateMethod)[keyof typeof GQBiAggregateMethod];
export type GQBiAiQueryInput = {
  queryContext: GQBiQueryContextInput;
  queryMetadata: GQBiAiQueryMetadata;
  userQuery: Scalars['String']['input'];
};

export type GQBiAiQueryMetadata = {
  availableDimensionsMetadata: Array<GQBiAiQueryAvailableDimensionsMetadata>;
  availableMetricsMetadata: Array<GQBiAiQueryAvailableMetricMetadata>;
  maxDateRange: Scalars['YMInterval']['input'];
};

export type GQBiAiQueryPayload = {
  __typename?: 'BiAiQueryPayload';
  dimensions: Array<Scalars['String']['output']>;
  filters: Array<GQBiQueryFilter>;
  normalizedMeasureId: Scalars['String']['output'];
  timeIntervals: Array<Scalars['YMInterval']['output']>;
};

export type GQBiAiQueryResponse = {
  __typename?: 'BiAiQueryResponse';
  payload: Maybe<GQBiAiQueryPayload>;
};

export type GQBiBaseFieldMeta = {
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export const GQBiChartKind = {
  BarHorizontal: 'BarHorizontal',
  BarVertical: 'BarVertical',
  BigNumber: 'BigNumber',
  Meter: 'Meter',
  Pareto: 'Pareto',
  Pie: 'Pie',
  TimeSeries: 'TimeSeries',
  Treemap: 'Treemap',
} as const;

export type GQBiChartKind = (typeof GQBiChartKind)[keyof typeof GQBiChartKind];
export type GQBiCustomMetric = {
  __typename?: 'BiCustomMetric';
  creator: GQUser;
  expression: Scalars['Expression']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  stableId: Scalars['ID']['output'];
};

export type GQBiCustomMetricRef = {
  __typename?: 'BiCustomMetricRef';
  id: Maybe<Scalars['String']['output']>;
  stableId: Scalars['String']['output'];
};

export type GQBiCustomMetricRefInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  stableId: Scalars['String']['input'];
};

export const GQBiDataFormat = {
  Columnar: 'Columnar',
  Row: 'Row',
} as const;

export type GQBiDataFormat =
  (typeof GQBiDataFormat)[keyof typeof GQBiDataFormat];
export type GQBiDimensionFieldMeta =
  | GQBiFieldBooleanMeta
  | GQBiFieldDateMeta
  | GQBiFieldNumberMeta
  | GQBiFieldStringMeta
  | GQBiFieldYearMonthMeta;

export type GQBiDimensionLimitInput = {
  dimension: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};

export type GQBiDimensionListOptionItem = {
  __typename?: 'BiDimensionListOptionItem';
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['UntypedData']['output']>;
};

export type GQBiDimensionListOptionsInput = {
  dimension: Scalars['String']['input'];
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  queryContext: GQBiQueryContextInput;
  search?: InputMaybe<Scalars['String']['input']>;
  timeInterval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQBiDimensionListOptionsResult = {
  __typename?: 'BiDimensionListOptionsResult';
  id: Scalars['String']['output'];
  listOptionItems: Array<GQBiDimensionListOptionItem>;
};

export type GQBiDimensionMeta = {
  __typename?: 'BiDimensionMeta';
  fieldMeta: GQBiDimensionFieldMeta;
};

export const GQBiDisplayMappingType = {
  ApprovalStatus: 'approvalStatus',
  AreaUnit: 'areaUnit',
  BeaCode: 'beaCode',
  DataOwners: 'dataOwners',
  DatasetId: 'datasetId',
  FileIds: 'fileIds',
  FinanceAssetHoldingClass: 'financeAssetHoldingClass',
  GhgRemovalActivity: 'ghgRemovalActivity',
  GhgRemovalStoragePool: 'ghgRemovalStoragePool',
  IndustryCode: 'industryCode',
  IndustrySectorCode: 'industrySectorCode',
  IndustrySectorLabel: 'industrySectorLabel',
  MeasurementProjectIds: 'measurementProjectIds',
  Natura2000SiteNames: 'natura2000SiteNames',
  OrgUnit: 'orgUnit',
  PollutantSubstanceOfConcernHazardClass:
    'pollutantSubstanceOfConcernHazardClass',
  ReleaseId: 'releaseId',
  SbtCommitmentStage: 'sbtCommitmentStage',
  UncollapsedCompanyId: 'uncollapsedCompanyId',
  Unit: 'unit',
  UploaderIds: 'uploaderIds',
  VehicleType: 'vehicleType',
  VehicleUsageReason: 'vehicleUsageReason',
} as const;

export type GQBiDisplayMappingType =
  (typeof GQBiDisplayMappingType)[keyof typeof GQBiDisplayMappingType];
export type GQBiDisplayValueMapping = {
  __typename?: 'BiDisplayValueMapping';
  dimension: Scalars['String']['output'];
  listOptionItems: Array<GQBiDimensionListOptionItem>;
};

export type GQBiDisplayValueMappingRequestInput = {
  dimension: Scalars['String']['input'];
  mappingType: GQBiDisplayMappingType;
  values: Array<Scalars['UntypedData']['input']>;
};

export type GQBiDynamicNormalizedMeasureInput = {
  baseMeasure: Scalars['String']['input'];
  normalizingQuery?: InputMaybe<GQBiDynamicNormalizingQueryInput>;
};

export type GQBiDynamicNormalizingMeasure = {
  __typename?: 'BiDynamicNormalizingMeasure';
  baseFilters: Maybe<Array<GQBiQueryFilter>>;
  baseMeasure: Scalars['String']['output'];
  normalizingQuery: Maybe<GQBiDynamicNormalizingQuery>;
};

export type GQBiDynamicNormalizingQuery = {
  __typename?: 'BiDynamicNormalizingQuery';
  computedDimensionType: Maybe<GQDimensionComputedType>;
  computedFiltersType: Maybe<GQDimensionComputedType>;
  dimensions: Maybe<Array<Scalars['String']['output']>>;
  filters: Maybe<Array<GQBiQueryFilter>>;
  measure: Scalars['String']['output'];
};

export type GQBiDynamicNormalizingQueryInput = {
  computedDimensionType?: InputMaybe<GQDimensionComputedType>;
  computedFiltersType?: InputMaybe<GQDimensionComputedType>;
  dimensions?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  measure: Scalars['String']['input'];
};

export const GQBiEmptyStringType = {
  Emdash: 'Emdash',
  Empty: 'Empty',
  UnspecifiedWithDisplayName: 'UnspecifiedWithDisplayName',
} as const;

export type GQBiEmptyStringType =
  (typeof GQBiEmptyStringType)[keyof typeof GQBiEmptyStringType];
export type GQBiFieldBooleanMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldBooleanMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFieldDateMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldDateMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  format: Maybe<GQDateTimeFormatOptions>;
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFieldNumberMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldNumberMeta';
  aggregateType: Maybe<GQBiAggregateMethod>;
  aggregatedUnitDimension: Maybe<Scalars['String']['output']>;
  /** @deprecated Use aggregatedUnitDimension instead as Feb 1 2024 */
  dependentDimensions: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  disableCommas: Maybe<Scalars['Boolean']['output']>;
  displayName: Scalars['String']['output'];
  divideBy: Maybe<Scalars['Int']['output']>;
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  includeUnit: Maybe<Scalars['Boolean']['output']>;
  isCurrency: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
  precision: Maybe<Scalars['Int']['output']>;
  shouldFormatToPercent: Maybe<Scalars['Boolean']['output']>;
  unit: Maybe<Scalars['String']['output']>;
  unitDimension: Maybe<Scalars['String']['output']>;
  unitUserFacing: Maybe<Scalars['String']['output']>;
};

export type GQBiFieldStringMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldStringMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  hrefTemplate: Maybe<Scalars['String']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
  knownDisplayValueMappingType: Maybe<GQBiKnownDisplayValueMappingType>;
  shouldNullifyPseudoNulls: Maybe<Scalars['Boolean']['output']>;
  shouldSentenceCaseValues: Scalars['Boolean']['output'];
  staticListOptions: Maybe<Array<GQBiDimensionListOptionItem>>;
};

export type GQBiFieldYearMonthMeta = GQBiBaseFieldMeta & {
  __typename?: 'BiFieldYearMonthMeta';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emptyStringType: Maybe<GQBiEmptyStringType>;
  fieldFamily: Maybe<Scalars['String']['output']>;
  fieldId: Scalars['String']['output'];
  format: Maybe<GQDateTimeFormatOptions>;
  hiddenInFilters: Maybe<Scalars['Boolean']['output']>;
  hiddenInGroupBy: Maybe<Scalars['Boolean']['output']>;
  isCustomField: Maybe<Scalars['Boolean']['output']>;
  isMultiValue: Maybe<Scalars['Boolean']['output']>;
};

export type GQBiFilterDisplayValueMappingsInput = {
  filters: Array<GQBiQueryFilterInput>;
  queryContext: GQBiQueryContextInput;
};

export const GQBiFilterOperator = {
  Contains: 'Contains',
  DoesNotContain: 'DoesNotContain',
  Empty: 'Empty',
  Equal: 'Equal',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  In: 'In',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
  NotEmpty: 'NotEmpty',
  NotEqual: 'NotEqual',
  NotIn: 'NotIn',
} as const;

export type GQBiFilterOperator =
  (typeof GQBiFilterOperator)[keyof typeof GQBiFilterOperator];
export type GQBiFinanceQueryContextInput = {
  assetId?: InputMaybe<Scalars['String']['input']>;
  financeSnapshotId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export const GQBiKnownDisplayValueMappingType = {
  CountryCode: 'CountryCode',
  LabelMappingsNew: 'LabelMappingsNew',
} as const;

export type GQBiKnownDisplayValueMappingType =
  (typeof GQBiKnownDisplayValueMappingType)[keyof typeof GQBiKnownDisplayValueMappingType];
export type GQBiMeasureFieldMeta = GQBiFieldNumberMeta;

export type GQBiMeasureMeta = {
  __typename?: 'BiMeasureMeta';
  fieldMeta: GQBiMeasureFieldMeta;
  supportedDimensions: Array<Scalars['String']['output']>;
};

export type GQBiNormalizedMeasureInput = {
  baseMeasure: Scalars['String']['input'];
  fieldId: Scalars['String']['input'];
  normalizingQuery?: InputMaybe<GQBiNormalizingQueryInput>;
};

export type GQBiNormalizingQueryInput = {
  dimensions: Array<Scalars['String']['input']>;
  exampleFields?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  measure: Scalars['String']['input'];
};

export type GQBiPostgresToActivityDataPayload = {
  __typename?: 'BiPostgresToActivityDataPayload';
  isFacilitiesDataStale: Scalars['Boolean']['output'];
};

export type GQBiQuery = {
  query: GQBiTableQuery;
  tables: Array<GQAliasedBiTableQuery>;
};

export type GQBiQueryComponentsInput = {
  dimensionLimits?: InputMaybe<Array<GQBiDimensionLimitInput>>;
  dimensions: Array<Scalars['String']['input']>;
  exampleFields?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  normalizedMeasures: Array<GQBiNormalizedMeasureInput>;
  order?: InputMaybe<Array<GQBiQueryOrderInput>>;
  select?: InputMaybe<Array<Scalars['Expression']['input']>>;
  timeInterval: Scalars['YMInterval']['input'];
};

export type GQBiQueryContextInput = {
  activity?: InputMaybe<GQBiActivityQueryContextInput>;
  cacheKey?: InputMaybe<Scalars['String']['input']>;
  finance?: InputMaybe<GQBiFinanceQueryContextInput>;
  mode: GQBiQueryMode;
  snapshot?: InputMaybe<GQBiSnapshotQueryContextInput>;
};

export type GQBiQueryDataInput = {
  queryInput: GQBiQuery;
  queryOptions?: InputMaybe<GQBiQueryOptions>;
};

export type GQBiQueryDataInputLegacy = {
  queryComponents: GQBiQueryComponentsInput;
  queryContext: GQBiQueryContextInput;
};

export type GQBiQueryDataMultipleInput = {
  inputs: Array<GQBiQueryDataInputLegacy>;
};

export type GQBiQueryDataResult = {
  __typename?: 'BiQueryDataResult';
  data: Scalars['BiDataResult']['output'];
  displayValueMappings: Maybe<Array<GQBiDisplayValueMapping>>;
  id: Scalars['String']['output'];
  warnings: Maybe<Array<GQBiWarning>>;
};

export type GQBiQueryFilter = {
  __typename?: 'BiQueryFilter';
  dimension: Scalars['String']['output'];
  operator: GQBiFilterOperator;
  value: Array<Maybe<Scalars['FilterValue']['output']>>;
};

export type GQBiQueryFilterInput = {
  dimension: Scalars['String']['input'];
  operator: GQBiFilterOperator;
  value: Array<InputMaybe<Scalars['FilterValue']['input']>>;
};

export type GQBiQueryMetadataInput = {
  queryContext: GQBiQueryContextInput;
};

export type GQBiQueryMetadataResult = {
  __typename?: 'BiQueryMetadataResult';
  dimensions: Array<GQBiDimensionMeta>;
  id: Scalars['String']['output'];
  measures: Array<GQBiMeasureMeta>;
  /** @deprecated Delete in Oct 2024 */
  schemaNames: Maybe<Array<Scalars['String']['output']>>;
  timeInterval: Maybe<Scalars['YMInterval']['output']>;
};

export const GQBiQueryMode = {
  Activity: 'Activity',
  Finance: 'Finance',
  FinanceFootprintSnapshot: 'FinanceFootprintSnapshot',
  Snapshot: 'Snapshot',
} as const;

export type GQBiQueryMode = (typeof GQBiQueryMode)[keyof typeof GQBiQueryMode];
export type GQBiQueryOptions = {
  dataFormat?: InputMaybe<GQBiDataFormat>;
  displayValueMappings?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

export type GQBiQueryOptionsParity = {
  dataFormat?: InputMaybe<GQBiDataFormat>;
  preferredCurrency?: InputMaybe<GQCurrencyCode>;
};

export type GQBiQueryOrder = {
  __typename?: 'BiQueryOrder';
  dimensionOrMeasure: Scalars['String']['output'];
  direction: GQBiQueryOrderDirection;
};

export const GQBiQueryOrderDirection = {
  Asc: 'Asc',
  Desc: 'Desc',
} as const;

export type GQBiQueryOrderDirection =
  (typeof GQBiQueryOrderDirection)[keyof typeof GQBiQueryOrderDirection];
export type GQBiQueryOrderInput = {
  dimensionOrMeasure: Scalars['String']['input'];
  direction?: InputMaybe<GQBiQueryOrderDirection>;
};

export type GQBiQuerySingleMeasureSelectorInput = {
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  footprintKind: Scalars['String']['input'];
  measure: Scalars['String']['input'];
  timeInterval: Scalars['YMInterval']['input'];
};

export type GQBiSavedView = {
  __typename?: 'BiSavedView';
  chartKind: GQBiChartKind;
  createdAt: Scalars['DateTime']['output'];
  customMetrics: Maybe<Array<GQBiCustomMetricRef>>;
  dimensions: Array<Scalars['String']['output']>;
  editVisibility: GQBiSavedViewVisibility;
  editor: GQUser;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  jsonSchemaVersion: Scalars['String']['output'];
  mode: GQBiQueryMode;
  name: Scalars['String']['output'];
  normalizedMeasures: Array<GQBiDynamicNormalizingMeasure>;
  order: Maybe<GQBiQueryOrder>;
  org: GQOrganization;
  owner: GQUser;
  queryContext: Maybe<GQBiSavedViewQueryContext>;
  searchTerm: Maybe<Scalars['String']['output']>;
  stableId: Scalars['ID']['output'];
  timeIntervals: Array<Scalars['YMInterval']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQBiSavedViewQueryContext = GQBiSavedViewSnapshotQueryContext;

export type GQBiSavedViewQueryContextInput = {
  mode: GQBiQueryMode;
  snapshot?: InputMaybe<GQBiSavedViewSnapshotQueryContextInput>;
};

export type GQBiSavedViewSnapshotQueryContext = {
  __typename?: 'BiSavedViewSnapshotQueryContext';
  footprintKind: Scalars['String']['output'];
};

export type GQBiSavedViewSnapshotQueryContextInput = {
  footprintKind: Scalars['String']['input'];
};

export const GQBiSavedViewVisibility = {
  Org: 'Org',
  Private: 'Private',
} as const;

export type GQBiSavedViewVisibility =
  (typeof GQBiSavedViewVisibility)[keyof typeof GQBiSavedViewVisibility];
export type GQBiSavedViewsInput = {
  mode: GQBiQueryMode;
};

export type GQBiSchema = {
  __typename?: 'BiSchema';
  schema: Scalars['JSONString']['output'];
  slug: Scalars['String']['output'];
};

export type GQBiSchemasInput = {
  queryContext: GQBiQueryContextInput;
};

export type GQBiSchemasResult = {
  __typename?: 'BiSchemasResult';
  schemas: Array<GQBiSchema>;
  tables: Array<GQBiTableSchemaMapping>;
};

export type GQBiSnapshotQueryContextInput = {
  footprintKind?: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  footprintSnapshotIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeActivityData?: InputMaybe<Scalars['Boolean']['input']>;
  includeDedupedRows?: InputMaybe<Scalars['Boolean']['input']>;
  includeIntensityData?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['String']['input'];
};

export type GQBiTableQuery = {
  from: Scalars['BiFromClause']['input'];
  groupBy?: InputMaybe<Array<Scalars['Expression']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<Scalars['Expression']['input']>>;
  select: Array<Scalars['Expression']['input']>;
  where?: InputMaybe<Array<Scalars['Expression']['input']>>;
};

export type GQBiTableReference =
  | GQBartInstanceTableReference
  | GQBartTableReference
  | GQDataRegistryTableReference
  | GQFinanceFootprintSnapshotTableReference
  | GQFootprintBartTableReference
  | GQFootprintSidecarTableReference
  | GQFootprintSnapshotTableReference
  | GQLineageAssetFileMetadataTableReference
  | GQSyncedPostgresTableReference;

export type GQBiTableSchemaMapping = {
  __typename?: 'BiTableSchemaMapping';
  slug: Scalars['String']['output'];
  tableRef: GQBiTableReference;
};

export type GQBiWarning = {
  __typename?: 'BiWarning';
  learnMoreLink: Maybe<Scalars['String']['output']>;
  warningMessage: Scalars['String']['output'];
  warningType: GQBiWarningType;
};

export const GQBiWarningType = {
  LegacyCadtData: 'LegacyCadtData',
  PresetData: 'PresetData',
} as const;

export type GQBiWarningType =
  (typeof GQBiWarningType)[keyof typeof GQBiWarningType];
export type GQBuildingCompletionIntervals = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervals';
  data: Array<GQBuildingCompletionIntervalsData>;
  id: Scalars['ID']['output'];
};

export type GQBuildingCompletionIntervalsData = {
  __typename?: 'BuildingCompletionIntervalsData';
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  hasOnsiteCleanElectricity: Maybe<Scalars['Boolean']['output']>;
  periodsWithActuals: Array<GQDateTimeInterval>;
  periodsWithSupportingDocs: Array<GQDateTimeInterval>;
  utilityType: GQBuildingUtilityType;
};

export type GQBuildingCompletionIntervalsUntyped = GQIdInterface & {
  __typename?: 'BuildingCompletionIntervalsUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingDiffError = {
  __typename?: 'BuildingDiffError';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffErrorCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffErrorCode = {
  ForbiddenError: 'ForbiddenError',
  TimeIntersectionInData: 'TimeIntersectionInData',
} as const;

export type GQBuildingDiffErrorCode =
  (typeof GQBuildingDiffErrorCode)[keyof typeof GQBuildingDiffErrorCode];
export type GQBuildingDiffWarning = {
  __typename?: 'BuildingDiffWarning';
  adminMessage: Scalars['String']['output'];
  code: GQBuildingDiffWarningCode;
  message: Scalars['String']['output'];
};

export const GQBuildingDiffWarningCode = {
  KeyInfoChangeDetected: 'KeyInfoChangeDetected',
  TimeGapInData: 'TimeGapInData',
} as const;

export type GQBuildingDiffWarningCode =
  (typeof GQBuildingDiffWarningCode)[keyof typeof GQBuildingDiffWarningCode];
export const GQBuildingEditState = {
  Full: 'Full',
  NeedsConfirmation: 'NeedsConfirmation',
  Partial: 'Partial',
} as const;

export type GQBuildingEditState =
  (typeof GQBuildingEditState)[keyof typeof GQBuildingEditState];
export type GQBuildingEstimate = GQIdInterface & {
  __typename?: 'BuildingEstimate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelInput: Maybe<Scalars['JSONString']['output']>;
  modelVersion: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  rawOutputFileMetadataId: Scalars['ID']['output'];
  scope1Kgco2e: Scalars['Float']['output'];
  scope2Kgco2e: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQBuildingFacilitiesTaskFilter = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQBuildingForDiff = GQBuildingInterface & {
  __typename?: 'BuildingForDiff';
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  /** @deprecated Moved to BuildingPeriod */
  isEmpty: Maybe<Scalars['Boolean']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export type GQBuildingHistory = GQBuildingInterface & {
  __typename?: 'BuildingHistory';
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriod>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export type GQBuildingInterface = {
  buildingKind: Maybe<Scalars['String']['output']>;
  buildingName: Scalars['String']['output'];
  buildingSubkind: Maybe<Scalars['String']['output']>;
  buildingUniqueId: Scalars['String']['output'];
  city: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  editState: GQBuildingEditState;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
  isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  leaseType: Maybe<GQBuildingLeaseType>;
  longitude: Maybe<Scalars['Float']['output']>;
  periods: Array<GQBuildingPeriod | GQBuildingPeriodForDiff>;
  postalCode: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
  sizeUnit: Maybe<GQBuildingSizeUnit>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  state: Maybe<Scalars['String']['output']>;
  streetAddress: Maybe<Scalars['String']['output']>;
  streetAddress2: Maybe<Scalars['String']['output']>;
};

export const GQBuildingLeaseType = {
  CoworkingOrServicedOffice: 'CoworkingOrServicedOffice',
  DownstreamLeaseNotPayingUtilities: 'DownstreamLeaseNotPayingUtilities',
  DownstreamLeasePayingUtilities: 'DownstreamLeasePayingUtilities',
  DownstreamSubleaseNotPayingUtilities: 'DownstreamSubleaseNotPayingUtilities',
  DownstreamSubleasePayingUtilities: 'DownstreamSubleasePayingUtilities',
  Franchise: 'Franchise',
  Leased: 'Leased',
  Owned: 'Owned',
  SoldBuilding: 'SoldBuilding',
  Unknown: 'Unknown',
  UpstreamSublease: 'UpstreamSublease',
} as const;

export type GQBuildingLeaseType =
  (typeof GQBuildingLeaseType)[keyof typeof GQBuildingLeaseType];
export type GQBuildingLockedRevisions = {
  __typename?: 'BuildingLockedRevisions';
  id: Maybe<Scalars['String']['output']>;
  revision: Maybe<Scalars['Int']['output']>;
};

export type GQBuildingPeriod = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriod';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  id: Scalars['ID']['output'];
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodForDiff = GQBuildingPeriodInterface & {
  __typename?: 'BuildingPeriodForDiff';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export type GQBuildingPeriodInterface = {
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  customFieldId: Maybe<Scalars['String']['output']>;
  customFieldValue: Maybe<Scalars['String']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  isBuildingEmpty: Maybe<Scalars['Boolean']['output']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId: Maybe<Scalars['String']['output']>;
  orgUnitTypeStableId: Maybe<Scalars['String']['output']>;
  partialCleanPowerPercent: Maybe<Scalars['Float']['output']>;
  powerUsageEffectiveness: Maybe<Scalars['Float']['output']>;
  sizeQuantity: Maybe<Scalars['Float']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
};

export const GQBuildingPeriodKind = {
  BuildingSize: 'BuildingSize',
  CleanPower: 'CleanPower',
  CustomField: 'CustomField',
  IsBuildingEmpty: 'IsBuildingEmpty',
  OrgUnit: 'OrgUnit',
  PowerUsageEffectiveness: 'PowerUsageEffectiveness',
} as const;

export type GQBuildingPeriodKind =
  (typeof GQBuildingPeriodKind)[keyof typeof GQBuildingPeriodKind];
export const GQBuildingSizeUnit = {
  SquareFeet: 'SquareFeet',
  SquareMeters: 'SquareMeters',
} as const;

export type GQBuildingSizeUnit =
  (typeof GQBuildingSizeUnit)[keyof typeof GQBuildingSizeUnit];
export type GQBuildingSupportingDocsExport = {
  __typename?: 'BuildingSupportingDocsExport';
  jobId: Scalars['ID']['output'];
};

export type GQBuildingSurveyStatus = {
  __typename?: 'BuildingSurveyStatus';
  bartActivityType: Maybe<Scalars['String']['output']>;
  latestSnapshotIsEmptyFile: Scalars['Boolean']['output'];
  latestSnapshotUpToDate: Scalars['Boolean']['output'];
  latestSnapshotUut: Maybe<GQUserUploadedTable>;
  measurementProjectIntervalMatches: Scalars['Boolean']['output'];
  numAddedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numChangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numDeletedBuildingsSinceUutGenerated: Scalars['Int']['output'];
  numOmittedPartialBuildings: Scalars['Int']['output'];
  numUnchangedBuildingsSinceUutGenerated: Scalars['Int']['output'];
};

export type GQBuildingUntyped = GQIdInterface & {
  __typename?: 'BuildingUntyped';
  data: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
};

export type GQBuildingUtilitiesExport = {
  __typename?: 'BuildingUtilitiesExport';
  jobId: Scalars['ID']['output'];
};

export type GQBuildingUtility = GQIdInterface & {
  __typename?: 'BuildingUtility';
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  cleanPowerPercent: Maybe<Scalars['Float']['output']>;
  customFieldValues: Array<GQBuildingUtilityCustomField>;
  fuelCombustionReason: Maybe<Scalars['String']['output']>;
  fuelKind: Maybe<GQStationaryCombustionFuelKind>;
  id: Scalars['ID']['output'];
  isWasteHazardous: Maybe<Scalars['Boolean']['output']>;
  isWasteRadioactive: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  onsiteCleanFuelKind: Maybe<Scalars['String']['output']>;
  periodEnd: Scalars['Date']['output'];
  periodStart: Scalars['Date']['output'];
  productExternalId: Maybe<Scalars['String']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  refrigerantLeakageSource: Maybe<GQRefrigerantLeakageSource>;
  refrigerantName: Maybe<Scalars['String']['output']>;
  sourceFile: Maybe<GQUserUpload>;
  supportingDocuments: Array<GQSupportingDocument>;
  unit: GQBuildingUtilityUnit;
  utilityType: GQBuildingUtilityType;
  wasteMaterial: Maybe<GQBuildingWasteMaterial>;
  wasteTreatment: Maybe<GQBuildingWasteTreatment>;
  waterUseKind: Maybe<GQBuildingWaterUseKind>;
};

export type GQBuildingUtilityConnection = {
  __typename?: 'BuildingUtilityConnection';
  edges: Array<Maybe<GQBuildingUtilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQBuildingUtilityCustomField = GQIdInterface & {
  __typename?: 'BuildingUtilityCustomField';
  customFieldValue: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
};

export type GQBuildingUtilityCustomFieldInput = {
  customFieldValue?: InputMaybe<Scalars['String']['input']>;
  ingestionCustomFieldId: Scalars['ID']['input'];
};

export type GQBuildingUtilityEdge = {
  __typename?: 'BuildingUtilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQBuildingUtility>;
};

export const GQBuildingUtilityType = {
  ElectricityConsumption: 'ElectricityConsumption',
  Refrigerant: 'Refrigerant',
  StationaryCombustion: 'StationaryCombustion',
  Waste: 'Waste',
  Water: 'Water',
} as const;

export type GQBuildingUtilityType =
  (typeof GQBuildingUtilityType)[keyof typeof GQBuildingUtilityType];
export const GQBuildingUtilityUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  G: 'G',
  Gallons: 'Gallons',
  Gj: 'Gj',
  Joule: 'Joule',
  Kbtu: 'Kbtu',
  Kg: 'Kg',
  Kilogallon: 'Kilogallon',
  Kw: 'Kw',
  Kwh: 'Kwh',
  Lb: 'Lb',
  Liter: 'Liter',
  M3NaturalGas: 'M3NaturalGas',
  Mcf: 'Mcf',
  Mj: 'Mj',
  Mmbtu: 'Mmbtu',
  Oz: 'Oz',
  Therm: 'Therm',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingUtilityUnit =
  (typeof GQBuildingUtilityUnit)[keyof typeof GQBuildingUtilityUnit];
export const GQBuildingWasteMaterial = {
  AluminumCans: 'AluminumCans',
  Beef: 'Beef',
  Bread: 'Bread',
  Clothing: 'Clothing',
  CommercialAndIndustrial: 'CommercialAndIndustrial',
  Concrete: 'Concrete',
  ConstructionWaste: 'ConstructionWaste',
  CorrugatedContainers: 'CorrugatedContainers',
  CrtDisplays: 'CrtDisplays',
  DairyProducts: 'DairyProducts',
  DesktopCpus: 'DesktopCpus',
  ElectronicPeripherals: 'ElectronicPeripherals',
  FlatPanelDisplays: 'FlatPanelDisplays',
  FoodWaste: 'FoodWaste',
  FoodWasteMeatOnly: 'FoodWasteMeatOnly',
  FoodWasteNonMeat: 'FoodWasteNonMeat',
  FruitsAndVegetables: 'FruitsAndVegetables',
  Glass: 'Glass',
  Grains: 'Grains',
  HardCopyDevices: 'HardCopyDevices',
  Hdpe: 'Hdpe',
  Ldpe: 'Ldpe',
  Lldpe: 'Lldpe',
  MixedElectronics: 'MixedElectronics',
  MixedMetals: 'MixedMetals',
  MixedMsw: 'MixedMsw',
  MixedOrganics: 'MixedOrganics',
  MixedPaperGeneral: 'MixedPaperGeneral',
  MixedPlastics: 'MixedPlastics',
  MixedRecyclables: 'MixedRecyclables',
  Newspaper: 'Newspaper',
  Pet: 'Pet',
  Pla: 'Pla',
  PortableElectronicDevices: 'PortableElectronicDevices',
  Poultry: 'Poultry',
  Pp: 'Pp',
  Ps: 'Ps',
  Pvc: 'Pvc',
  SteelCans: 'SteelCans',
  Unspecified: 'Unspecified',
  Wood: 'Wood',
} as const;

export type GQBuildingWasteMaterial =
  (typeof GQBuildingWasteMaterial)[keyof typeof GQBuildingWasteMaterial];
export const GQBuildingWasteTreatment = {
  AnaerobicallyDigested: 'AnaerobicallyDigested',
  Combusted: 'Combusted',
  Composted: 'Composted',
  Landfilled: 'Landfilled',
  PreparedForReuse: 'PreparedForReuse',
  Recycled: 'Recycled',
  Unspecified: 'Unspecified',
} as const;

export type GQBuildingWasteTreatment =
  (typeof GQBuildingWasteTreatment)[keyof typeof GQBuildingWasteTreatment];
export const GQBuildingWasteUnit = {
  G: 'G',
  Kg: 'Kg',
  Lb: 'Lb',
  Tonnes: 'Tonnes',
  Tons: 'Tons',
} as const;

export type GQBuildingWasteUnit =
  (typeof GQBuildingWasteUnit)[keyof typeof GQBuildingWasteUnit];
export const GQBuildingWaterUnit = {
  Ccf: 'Ccf',
  Centigallon: 'Centigallon',
  Cf: 'Cf',
  CubicMeter: 'CubicMeter',
  Gallons: 'Gallons',
  Kilogallon: 'Kilogallon',
  Liter: 'Liter',
  Mcf: 'Mcf',
} as const;

export type GQBuildingWaterUnit =
  (typeof GQBuildingWaterUnit)[keyof typeof GQBuildingWaterUnit];
export const GQBuildingWaterUseKind = {
  Consumed: 'Consumed',
  Discharged: 'Discharged',
  Recycled: 'Recycled',
  Stored: 'Stored',
  Used: 'Used',
} as const;

export type GQBuildingWaterUseKind =
  (typeof GQBuildingWaterUseKind)[keyof typeof GQBuildingWaterUseKind];
export type GQBuildingWithUtilities = GQIdInterface & {
  __typename?: 'BuildingWithUtilities';
  completionIntervals: GQBuildingCompletionIntervals;
  id: Scalars['ID']['output'];
  utilities: Array<GQBuildingUtility>;
  utilitiesPaginated: GQBuildingUtilityConnection;
};

export type GQBuildingWithUtilitiesCompletionIntervalsArgs = {
  combineFuelKinds: InputMaybe<Scalars['Boolean']['input']>;
  separateOnsiteCleanElectricity: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQBuildingWithUtilitiesUtilitiesPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  notUserUploadTaskId: InputMaybe<Scalars['ID']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
  utilityType: InputMaybe<GQBuildingUtilityType>;
};

export const GQBuildingsMergeStrategy = {
  MergePeriods: 'mergePeriods',
  OverwriteDuplicates: 'overwriteDuplicates',
} as const;

export type GQBuildingsMergeStrategy =
  (typeof GQBuildingsMergeStrategy)[keyof typeof GQBuildingsMergeStrategy];
export type GQBulkAssignFacilitiesToTasksInput = {
  measurementProjectId: Scalars['ID']['input'];
  rows: Array<GQBulkAssignFacilitiesToTasksRowInput>;
};

export type GQBulkAssignFacilitiesToTasksPayload = {
  __typename?: 'BulkAssignFacilitiesToTasksPayload';
  success: Scalars['Boolean']['output'];
};

export type GQBulkAssignFacilitiesToTasksRowInput = {
  buildingName: Scalars['String']['input'];
  facilitiesTaskName?: InputMaybe<Scalars['String']['input']>;
  utilitiesTaskName?: InputMaybe<Scalars['String']['input']>;
};

export type GQBulkAssignMeasurementTasksPayload = {
  __typename?: 'BulkAssignMeasurementTasksPayload';
  measurementTasks: Maybe<
    Array<GQMeasurementParentTask | GQMeasurementTaskFlat>
  >;
  organization: GQOrganization;
};

export type GQBulkCreateUploadTasksInput = {
  measurementProjectId: Scalars['String']['input'];
  rows: Array<GQCreateUploadTaskInput>;
};

export type GQBulkCreateUploadTasksPayload = {
  __typename?: 'BulkCreateUploadTasksPayload';
  success: Scalars['Boolean']['output'];
};

export type GQBulkCreateUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQBulkCreateUsersForFacilitiesInput = {
  users: Array<GQBulkCreateUserInput>;
};

export type GQBulkCreateUsersForFacilitiesPayload = {
  __typename?: 'BulkCreateUsersForFacilitiesPayload';
  organization: GQOrganization;
  users: Array<GQUser>;
};

export type GQBulkSetApproversPayload = {
  __typename?: 'BulkSetApproversPayload';
  organization: GQOrganization;
  targets: Array<GQUserUploadTask>;
};

export type GQBulkStartCompanySurveyEngagementInput = {
  autoEmailSelectedContacts: Scalars['Boolean']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  isFinance?: InputMaybe<Scalars['Boolean']['input']>;
  suppliersSpecificInfo: Array<GQSupplierSpecificInfoForBulkDeploy>;
  tasksSpecificInfo: Array<GQCreateCompanySurveyInput>;
};

export type GQBulkStartCompanySurveyEngagementPayload = {
  __typename?: 'BulkStartCompanySurveyEngagementPayload';
  engagementTaskBatchIds: Array<Scalars['ID']['output']>;
};

export type GQBulkUpdateReportQuestionsInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentIds: Array<Scalars['String']['input']>;
  reportConfigId: Scalars['ID']['input'];
};

export type GQBulkUpdateReportQuestionsPayload = {
  __typename?: 'BulkUpdateReportQuestionsPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQBusinessActivityType = {
  __typename?: 'BusinessActivityType';
  description: Scalars['String']['output'];
  fields: Array<GQBusinessActivityTypeField>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  schemaActivationMode: GQBusinessActivityTypeSettingsSchemaActivationModes;
  schemaJson: Scalars['JSONString']['output'];
  tSchema: Scalars['JSONString']['output'];
  tschemaVersionId: Maybe<Scalars['ID']['output']>;
  version: GQBusinessActivityTypeVersion;
  versionId: Scalars['ID']['output'];
  versionName: Scalars['String']['output'];
};

export type GQBusinessActivityTypeField = {
  __typename?: 'BusinessActivityTypeField';
  description: Scalars['String']['output'];
  fieldName: Scalars['String']['output'];
  fieldType: GQBusinessActivityTypeFieldType;
  fieldValueOneOf: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  isProratable: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  typeId: Scalars['ID']['output'];
  unitFieldName: Maybe<Scalars['String']['output']>;
  versionId: Scalars['ID']['output'];
};

export const GQBusinessActivityTypeFieldType = {
  Boolean: 'BOOLEAN',
  Bytes: 'BYTES',
  Date: 'DATE',
  Float: 'FLOAT',
  Int: 'INT',
  Json: 'JSON',
  OneOf: 'ONE_OF',
  String: 'STRING',
  Timestamp: 'TIMESTAMP',
  Uuid: 'UUID',
} as const;

export type GQBusinessActivityTypeFieldType =
  (typeof GQBusinessActivityTypeFieldType)[keyof typeof GQBusinessActivityTypeFieldType];
export type GQBusinessActivityTypeSettings = {
  __typename?: 'BusinessActivityTypeSettings';
  businessActivityTypeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataReadWriteMode: GQBusinessActivityTypeSettingsDataReadWriteModes;
  id: Scalars['ID']['output'];
  latestTypeInstance: GQBusinessActivityType;
  schemaActivationMode: GQBusinessActivityTypeSettingsSchemaActivationModes;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQBusinessActivityTypeSettingsConnection = {
  __typename?: 'BusinessActivityTypeSettingsConnection';
  edges: Array<Maybe<GQBusinessActivityTypeSettingsEdge>>;
  pageInfo: GQPageInfo;
};

export const GQBusinessActivityTypeSettingsDataReadWriteModes = {
  DataRegistryOnly: 'DataRegistryOnly',
  DualWriteBlocking: 'DualWriteBlocking',
  DualWriteNonBlocking: 'DualWriteNonBlocking',
  FileMetadataOnly: 'FileMetadataOnly',
  ReadDataRegistry: 'ReadDataRegistry',
} as const;

export type GQBusinessActivityTypeSettingsDataReadWriteModes =
  (typeof GQBusinessActivityTypeSettingsDataReadWriteModes)[keyof typeof GQBusinessActivityTypeSettingsDataReadWriteModes];
export type GQBusinessActivityTypeSettingsEdge = {
  __typename?: 'BusinessActivityTypeSettingsEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQBusinessActivityTypeSettings>;
};

export const GQBusinessActivityTypeSettingsSchemaActivationModes = {
  Legacy: 'Legacy',
  TSchema: 'TSchema',
} as const;

export type GQBusinessActivityTypeSettingsSchemaActivationModes =
  (typeof GQBusinessActivityTypeSettingsSchemaActivationModes)[keyof typeof GQBusinessActivityTypeSettingsSchemaActivationModes];
export type GQBusinessActivityTypeVersion = {
  __typename?: 'BusinessActivityTypeVersion';
  businessActivityTypes: Array<GQBusinessActivityType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryAndExtensionBusinessActivityTypes: Array<GQBusinessActivityType>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQBusinessActivityTypeWithReleases = {
  __typename?: 'BusinessActivityTypeWithReleases';
  businessActivityType: Scalars['String']['output'];
  datasetIds: Array<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  releases: Array<GQEmissionsModelRelease>;
};

export type GQCadtBuildingDiff = {
  __typename?: 'CadtBuildingDiff';
  buildingPresentInBart: Maybe<Scalars['Boolean']['output']>;
  errors: Array<GQBuildingDiffError>;
  new: Maybe<GQBuildingForDiff>;
  old: Maybe<GQBuildingForDiff>;
  warnings: Array<GQBuildingDiffWarning>;
};

export type GQCadtBuildingDiffSet = {
  __typename?: 'CadtBuildingDiffSet';
  added: Scalars['Int']['output'];
  changed: Scalars['Int']['output'];
  diffs: Array<GQCadtBuildingDiff>;
  removed: Scalars['Int']['output'];
  unchanged: Scalars['Int']['output'];
};

export type GQCadtBuildingsPushPreview = {
  __typename?: 'CadtBuildingsPushPreview';
  customFields: Array<GQIngestionCustomField>;
  mergePeriodsDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
  orgUnitTypes: Array<GQOrgUnitType>;
  orgUnits: Array<GQOrgUnit>;
  overwriteDuplicatesDiffUntyped: Maybe<Scalars['UntypedData']['output']>;
};

export type GQCalculationChain = {
  __typename?: 'CalculationChain';
  conversionFactor: Maybe<GQConversionFactorForDashboard>;
  convertedQuantity: Scalars['Float']['output'];
  emissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  emissionsModelVersionId: Maybe<Scalars['String']['output']>;
  inputQuantity: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  locationEmissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  sumOfMeasure: Scalars['Float']['output'];
  /** @deprecated Switch to sumOfMeasure */
  tco2eLocationQuantity: Scalars['Float']['output'];
  /** @deprecated Switch to sumOfMeasure */
  tco2eQuantity: Scalars['Float']['output'];
};

export type GQCalculationDataLineagePayload = {
  __typename?: 'CalculationDataLineagePayload';
  bartRows: Maybe<Array<GQFileDataLineage>>;
  extBartRows: Maybe<Array<GQFileDataLineage>>;
  fileMetadataIds: Maybe<Array<Scalars['ID']['output']>>;
  isTruncated: Scalars['Boolean']['output'];
  uutRows: Maybe<Array<GQUutRowsDataLineage>>;
};

export type GQCalculationTag = {
  __typename?: 'CalculationTag';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCancelWorkflowInput = {
  workflowId: Scalars['String']['input'];
};

export type GQCancelWorkflowPayload = {
  __typename?: 'CancelWorkflowPayload';
  success: Scalars['Boolean']['output'];
};

export type GQCanonicalClimateProgramProject = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProject';
  commitmentType: Maybe<Scalars['String']['output']>;
  costEstimate: Maybe<Scalars['String']['output']>;
  datasetRequirements: Array<GQCanonicalClimateProgramProjectRequirementDataset>;
  description: Scalars['String']['output'];
  ghgpRequirements: Array<GQCanonicalClimateProgramProjectRequirementGhgp>;
  hasBenchmarkMapping: Scalars['Boolean']['output'];
  hidden: Maybe<Scalars['Boolean']['output']>;
  hiddenFromMeasurement: Maybe<Scalars['Boolean']['output']>;
  iconType: GQClimateProgramProjectIconType;
  iconTypeOverride: Maybe<GQClimateProgramProjectIconType>;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  matchObjectType: Maybe<Scalars['String']['output']>;
  measurementObjectCopy: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  peerBenchmarkCompanies: Array<GQCompany>;
  reportKind: Maybe<Scalars['String']['output']>;
  routeType: Maybe<Scalars['String']['output']>;
  timeEstimate: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalClimateProgramProjectRequirementDataset =
  GQIdInterface & {
    __typename?: 'CanonicalClimateProgramProjectRequirementDataset';
    additionalNotesMd: Maybe<Scalars['String']['output']>;
    canonicalDataset: GQCanonicalDataset;
    id: Scalars['ID']['output'];
  };

export type GQCanonicalClimateProgramProjectRequirementGhgp = GQIdInterface & {
  __typename?: 'CanonicalClimateProgramProjectRequirementGhgp';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  ghgScope: Scalars['Int']['output'];
  ghgScope3Category: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minimumFidelity: GQClimateProgramProjectRequirementFidelity;
};

export type GQCanonicalDataset = GQIdInterface & {
  __typename?: 'CanonicalDataset';
  canonicalDatasources: Array<GQCanonicalDatasource>;
  customerTargetSchemas: Array<GQCustomerTargetSchema>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  kind: GQCanonicalDatasetKind;
  name: Scalars['String']['output'];
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  selfServeDisabledReason: Maybe<Scalars['String']['output']>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQCanonicalDatasetCustomerTargetSchemasArgs = {
  hasPublishedVersion: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export const GQCanonicalDatasetKind = {
  BillOfMaterials: 'BillOfMaterials',
  BriberyAndCorruption: 'BriberyAndCorruption',
  Buildings: 'Buildings',
  CapitalExpenses: 'CapitalExpenses',
  CarbonCreditRetirements: 'CarbonCreditRetirements',
  CareInstructions: 'CareInstructions',
  ChartOfAccounts: 'ChartOfAccounts',
  CloudCosts: 'CloudCosts',
  CloudUsage: 'CloudUsage',
  CloudUsageAndCost: 'CloudUsageAndCost',
  CorporateInvestments: 'CorporateInvestments',
  CostOfRevenue: 'CostOfRevenue',
  CryptoHoldings: 'CryptoHoldings',
  CryptoTransactions: 'CryptoTransactions',
  CustomEmissionsFactor: 'CustomEmissionsFactor',
  DataCenters: 'DataCenters',
  DirectEmissions: 'DirectEmissions',
  DownstreamLogistics: 'DownstreamLogistics',
  EmployeeTravelExpenses: 'EmployeeTravelExpenses',
  Employees: 'Employees',
  EndOfLifeTreatmentOfSoldProducts: 'EndOfLifeTreatmentOfSoldProducts',
  EnergyAttributeCertificatesPurchased: 'EnergyAttributeCertificatesPurchased',
  Factories: 'Factories',
  FinancedEmissions: 'FinancedEmissions',
  Financials: 'Financials',
  Flights: 'Flights',
  Food: 'Food',
  GhgRemoval: 'GhgRemoval',
  HistoricalResults: 'HistoricalResults',
  Hotels: 'Hotels',
  HumanRightsViolations: 'HumanRightsViolations',
  Lobbying: 'Lobbying',
  MaterialAggregate: 'MaterialAggregate',
  MaterialContents: 'MaterialContents',
  Microplastics: 'Microplastics',
  OperatingExpenses: 'OperatingExpenses',
  OperationalPayments: 'OperationalPayments',
  Packaging: 'Packaging',
  PrivateFlights: 'PrivateFlights',
  ProductsPurchased: 'ProductsPurchased',
  ProductsSold: 'ProductsSold',
  Rails: 'Rails',
  Refrigerants: 'Refrigerants',
  Revenue: 'Revenue',
  SafetyIncidents: 'SafetyIncidents',
  Sites: 'Sites',
  SoldProductCircularity: 'SoldProductCircularity',
  SubstanceInflow: 'SubstanceInflow',
  Substances: 'Substances',
  SupplierFactoriesTier1: 'SupplierFactoriesTier1',
  SupplierUpstreamLogistics: 'SupplierUpstreamLogistics',
  TextileProductSales: 'TextileProductSales',
  UpstreamLogistics: 'UpstreamLogistics',
  UseOfGoods: 'UseOfGoods',
  UseOfSoldProductsCommonRetail: 'UseOfSoldProductsCommonRetail',
  UseOfSoldProductsDataTransfer: 'UseOfSoldProductsDataTransfer',
  UseOfSoldProductsFuels: 'UseOfSoldProductsFuels',
  UseOfSoldProductsRefrigerants: 'UseOfSoldProductsRefrigerants',
  UseOfSoldProductsVehicles: 'UseOfSoldProductsVehicles',
  UsePhaseOfGoods: 'UsePhaseOfGoods',
  Utilities: 'Utilities',
  Vehicles: 'Vehicles',
  Waste: 'Waste',
  WaterUsage: 'WaterUsage',
  WeightOfGoodsPurchased: 'WeightOfGoodsPurchased',
  WorkforceSocial: 'WorkforceSocial',
  WorkplaceReports: 'WorkplaceReports',
} as const;

export type GQCanonicalDatasetKind =
  (typeof GQCanonicalDatasetKind)[keyof typeof GQCanonicalDatasetKind];
export type GQCanonicalDatasource = GQIdInterface & {
  __typename?: 'CanonicalDatasource';
  activeIntegrationConnection: Maybe<GQIntegrationConnection>;
  canonicalDataset: GQCanonicalDataset;
  externalId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  instructions: Maybe<GQInstructionsBundle>;
  integrationPartner: Maybe<GQIntegrationPartner>;
  name: Scalars['String']['output'];
};

export const GQCanonicalReportObjective = {
  All: 'All',
  Awareness: 'Awareness',
  Regulatory: 'Regulatory',
  Stakeholder: 'Stakeholder',
} as const;

export type GQCanonicalReportObjective =
  (typeof GQCanonicalReportObjective)[keyof typeof GQCanonicalReportObjective];
export type GQCanonicalSchema = {
  __typename?: 'CanonicalSchema';
  exampleData: GQCanonicalSchemaExampleData;
  id: Scalars['ID']['output'];
  jsonSchema: Scalars['JSONString']['output'];
};

export type GQCanonicalSchemaExampleData = {
  __typename?: 'CanonicalSchemaExampleData';
  columns: Array<Scalars['String']['output']>;
  rows: Array<GQCanonicalSchemaExampleRow>;
};

export type GQCanonicalSchemaExampleRow = {
  __typename?: 'CanonicalSchemaExampleRow';
  fields: Array<GQCanonicalSchemaExampleRowFields>;
  id: Scalars['String']['output'];
};

export type GQCanonicalSchemaExampleRowFields = {
  __typename?: 'CanonicalSchemaExampleRowFields';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQCarbonNeutralCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CarbonNeutralCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export type GQCategorizedEmissionData = {
  __typename?: 'CategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  proportion: Scalars['Float']['output'];
};

export type GQCdpApiRegistration = {
  __typename?: 'CdpApiRegistration';
  cdpOrganizationId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQCdpApiRegistrationPayload = {
  __typename?: 'CdpApiRegistrationPayload';
  cdpOrganizationId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReportRegistered: Scalars['Boolean']['output'];
  registeredReportId: Maybe<Scalars['ID']['output']>;
};

export type GQCdpLatestSyncWorkflowStatusPayload = {
  __typename?: 'CdpLatestSyncWorkflowStatusPayload';
  workflowStatus: Maybe<GQCdpSyncWorkflowStatusPayload>;
};

export type GQCdpOrganization = {
  __typename?: 'CdpOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQCdpOrganizationPayload = {
  __typename?: 'CdpOrganizationPayload';
  error: Maybe<Scalars['String']['output']>;
  organizations: Maybe<Array<GQCdpOrganization>>;
  success: Scalars['Boolean']['output'];
};

export type GQCdpSyncQuestionHistoryConnection = {
  __typename?: 'CdpSyncQuestionHistoryConnection';
  edges: Array<Maybe<GQCdpSyncQuestionHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCdpSyncQuestionHistoryEdge = {
  __typename?: 'CdpSyncQuestionHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCdpSyncQuestionStatus>;
};

export type GQCdpSyncQuestionStatus = {
  __typename?: 'CdpSyncQuestionStatus';
  actor: GQUser;
  actorName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  failureDetails: Maybe<Scalars['JSONString']['output']>;
  failureKind: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSuccess: Maybe<Scalars['Boolean']['output']>;
  oldCdpResponses: Maybe<Scalars['JSONString']['output']>;
  reportId: Scalars['ID']['output'];
  reportQuestion: GQReportQuestion;
  reportQuestionId: Scalars['ID']['output'];
  syncTime: Scalars['DateTime']['output'];
};

export type GQCdpSyncWorkflowStatusPayload = {
  __typename?: 'CdpSyncWorkflowStatusPayload';
  id: Scalars['ID']['output'];
  questions: Array<GQCdpSyncQuestionStatus>;
  workflowId: Scalars['String']['output'];
};

export type GQCdpVendorData = {
  __typename?: 'CdpVendorData';
  disclosureQualityScore: GQDisclosureQualityScore;
  id: Scalars['ID']['output'];
  pctEvaluationStatusesMatchResponse: Scalars['Float']['output'];
  publishingYear: Scalars['Int']['output'];
  reportingYear: Scalars['Int']['output'];
  scope1Nonzero: Scalars['Boolean']['output'];
  scope1Verified: Scalars['Boolean']['output'];
  scope2Nonzero: Scalars['Boolean']['output'];
  scope2Verified: Scalars['Boolean']['output'];
  scope3Nonzero: Scalars['Boolean']['output'];
  scope3Verified: Scalars['Boolean']['output'];
  scope301EvaluationStatus: GQScope3EvaluationStatus;
  scope301Or302Nonzero: Scalars['Boolean']['output'];
  scope302EvaluationStatus: GQScope3EvaluationStatus;
  scope303EvaluationStatus: GQScope3EvaluationStatus;
  scope304EvaluationStatus: GQScope3EvaluationStatus;
  scope305EvaluationStatus: GQScope3EvaluationStatus;
  scope306EvaluationStatus: GQScope3EvaluationStatus;
  scope307EvaluationStatus: GQScope3EvaluationStatus;
  scope308EvaluationStatus: GQScope3EvaluationStatus;
  scope309EvaluationStatus: GQScope3EvaluationStatus;
  scope310EvaluationStatus: GQScope3EvaluationStatus;
  scope311EvaluationStatus: GQScope3EvaluationStatus;
  scope312EvaluationStatus: GQScope3EvaluationStatus;
  scope313EvaluationStatus: GQScope3EvaluationStatus;
  scope314EvaluationStatus: GQScope3EvaluationStatus;
  scope315EvaluationStatus: GQScope3EvaluationStatus;
  scope316EvaluationStatus: GQScope3EvaluationStatus;
  scope317EvaluationStatus: GQScope3EvaluationStatus;
  totalEmissionsNonzero: Scalars['Boolean']['output'];
};

export type GQChartOrderInput = {
  chartId: Scalars['ID']['input'];
  visible: Scalars['Boolean']['input'];
};

export type GQCheckAndGenerateFacilitiesBartsInput = {
  orgId: Scalars['ID']['input'];
};

export type GQCleanEnergyCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'CleanEnergyCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetPercentageCleanEnergy: Maybe<Scalars['Int']['output']>;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export const GQCleanPowerContractualInstrument = {
  CompetitiveRetailProduct: 'CompetitiveRetailProduct',
  Ppa: 'PPA',
  SpotEac: 'SpotEAC',
  Unknown: 'Unknown',
  UtilityCleanPowerProgram: 'UtilityCleanPowerProgram',
  UtilityGreenTariff: 'UtilityGreenTariff',
  Vppa: 'VPPA',
} as const;

export type GQCleanPowerContractualInstrument =
  (typeof GQCleanPowerContractualInstrument)[keyof typeof GQCleanPowerContractualInstrument];
export type GQClimateProgramCalendarEvent = {
  __typename?: 'ClimateProgramCalendarEvent';
  endDate: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
};

export type GQClimateProgramCalendarEventPayload = {
  __typename?: 'ClimateProgramCalendarEventPayload';
  climateProgramCalendarEvent: GQClimateProgramCalendarEvent;
};

export type GQClimateProgramInfo = {
  __typename?: 'ClimateProgramInfo';
  hasCustomizedSteps: Scalars['Boolean']['output'];
  steps: Array<GQClimateProgramInfoStep>;
  targets: Array<GQClimateProgramInfoTarget>;
};

export type GQClimateProgramInfoStep = {
  __typename?: 'ClimateProgramInfoStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQClimateProgramInfoTarget = {
  __typename?: 'ClimateProgramInfoTarget';
  amount: Scalars['String']['output'];
  descriptor: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQClimateProgramLifecycle = {
  __typename?: 'ClimateProgramLifecycle';
  completedStage: GQCompletedLifecycleStage;
  kickoffBlurb: Maybe<Scalars['String']['output']>;
  nextStage: Maybe<GQNextLifecycleStage>;
};

export type GQClimateProgramProject = GQClimateProgramTimelineObject &
  GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    measurementProject: Maybe<GQMeasurementProject>;
    name: Scalars['String']['output'];
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
    status: GQClimateProgramProjectStatus;
  };

export const GQClimateProgramProjectIconType = {
  CarbonOffset: 'CarbonOffset',
  CarbonRemoval: 'CarbonRemoval',
  CleanPower: 'CleanPower',
  Commitments: 'Commitments',
  Custom: 'Custom',
  GenericReduction: 'GenericReduction',
  Kickoff: 'Kickoff',
  ReductionPlan: 'ReductionPlan',
  ReportDisclosure: 'ReportDisclosure',
  ReportGoverningBody: 'ReportGoverningBody',
  ReportPublic: 'ReportPublic',
  Simulate: 'Simulate',
  SupplierEngagement: 'SupplierEngagement',
  Targets: 'Targets',
  UnderstandEmissions: 'UnderstandEmissions',
} as const;

export type GQClimateProgramProjectIconType =
  (typeof GQClimateProgramProjectIconType)[keyof typeof GQClimateProgramProjectIconType];
export const GQClimateProgramProjectKind = {
  Custom: 'Custom',
  Insight: 'Insight',
  Reduction: 'Reduction',
  Report: 'Report',
  Target: 'Target',
} as const;

export type GQClimateProgramProjectKind =
  (typeof GQClimateProgramProjectKind)[keyof typeof GQClimateProgramProjectKind];
export type GQClimateProgramProjectPayload = {
  __typename?: 'ClimateProgramProjectPayload';
  climateProgramProject: GQClimateProgramProject;
};

export type GQClimateProgramProjectRequirementDataset = GQIdInterface & {
  __typename?: 'ClimateProgramProjectRequirementDataset';
  additionalNotesMd: Maybe<Scalars['String']['output']>;
  canonicalGhgpRequirement: Maybe<GQCanonicalClimateProgramProjectRequirementGhgp>;
  climateProgramProject: GQClimateProgramProject;
  dataset: GQDataset;
  id: Scalars['ID']['output'];
};

export type GQClimateProgramProjectRequirementDatasetPayload = {
  __typename?: 'ClimateProgramProjectRequirementDatasetPayload';
  climateProgramProjectRequirementDataset: GQClimateProgramProjectRequirementDataset;
};

export const GQClimateProgramProjectRequirementFidelity = {
  ActivityBased: 'ActivityBased',
  Cee: 'Cee',
  SpendBased: 'SpendBased',
  SquareFootageBased: 'SquareFootageBased',
  UtilitiesBased: 'UtilitiesBased',
} as const;

export type GQClimateProgramProjectRequirementFidelity =
  (typeof GQClimateProgramProjectRequirementFidelity)[keyof typeof GQClimateProgramProjectRequirementFidelity];
export const GQClimateProgramProjectStatus = {
  Active: 'Active',
  Completed: 'Completed',
  Overdue: 'Overdue',
  Planned: 'Planned',
} as const;

export type GQClimateProgramProjectStatus =
  (typeof GQClimateProgramProjectStatus)[keyof typeof GQClimateProgramProjectStatus];
export const GQClimateProgramProjectSubkind = {
  MarketBased: 'MarketBased',
  OperationalSolutions: 'OperationalSolutions',
} as const;

export type GQClimateProgramProjectSubkind =
  (typeof GQClimateProgramProjectSubkind)[keyof typeof GQClimateProgramProjectSubkind];
export type GQClimateProgramTimelineObject = {
  id: Scalars['ID']['output'];
};

export type GQClimateProgramTimelineProject = GQIClimateProgramProject &
  GQIdInterface & {
    __typename?: 'ClimateProgramTimelineProject';
    canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    description: Scalars['String']['output'];
    endDate: Maybe<Scalars['Date']['output']>;
    iconType: GQClimateProgramProjectIconType;
    id: Scalars['ID']['output'];
    kind: GQClimateProgramProjectKind;
    name: Scalars['String']['output'];
    object: GQClimateProgramProject | GQMeasurementProject | GQReport;
    objectType: GQClimateProgramTimelineProjectObjectType;
    plannedYear: Scalars['Int']['output'];
    startDate: Maybe<Scalars['Date']['output']>;
  };

export const GQClimateProgramTimelineProjectObjectType = {
  ClimateProgramProject: 'ClimateProgramProject',
  MeasurementProject: 'MeasurementProject',
  Report: 'Report',
} as const;

export type GQClimateProgramTimelineProjectObjectType =
  (typeof GQClimateProgramTimelineProjectObjectType)[keyof typeof GQClimateProgramTimelineProjectObjectType];
export type GQClimateProgramTimelineProjectPayload = {
  __typename?: 'ClimateProgramTimelineProjectPayload';
  climateProgramTimelineProject: GQClimateProgramTimelineProject;
};

export type GQCliqReferenceDataConnection = {
  __typename?: 'CliqReferenceDataConnection';
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  referenceDataSource: GQReferenceDataSource;
};

export const GQColumnDimension = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQColumnDimension =
  (typeof GQColumnDimension)[keyof typeof GQColumnDimension];
export type GQColumnMapping = {
  __typename?: 'ColumnMapping';
  sourceFileColumnName: Maybe<Scalars['String']['output']>;
  watershedColumnName: Scalars['String']['output'];
};

export type GQColumnMappingInput = {
  sourceFileColumnName?: InputMaybe<Scalars['String']['input']>;
  watershedColumnName: Scalars['String']['input'];
};

export type GQColumnVisibility = {
  columnId: Scalars['ID']['input'];
  visible: Scalars['Boolean']['input'];
};

export const GQCommandExecutionStatus = {
  Executed: 'EXECUTED',
  ExecutedReturnedToAgent: 'EXECUTED_RETURNED_TO_AGENT',
  Executing: 'EXECUTING',
  Failed: 'FAILED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
} as const;

export type GQCommandExecutionStatus =
  (typeof GQCommandExecutionStatus)[keyof typeof GQCommandExecutionStatus];
export type GQComment = {
  __typename?: 'Comment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  person: Maybe<GQUser | GQWatershedEmployee>;
};

export type GQCommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<Maybe<GQCommentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQComment>;
};

export type GQCompany = GQIdInterface & {
  __typename?: 'Company';
  alternateNames: Maybe<Array<Scalars['String']['output']>>;
  beas: Array<GQCompanyBea>;
  betterCompanyId: Maybe<Scalars['ID']['output']>;
  climateProgress: GQCompanyClimateProgress;
  companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
  companyRelationships: Array<GQCompanyRelationship>;
  companyWebsite: Maybe<GQCompanyUrl>;
  companyWebsiteUrlId: Maybe<Scalars['ID']['output']>;
  contacts: Array<GQSupplierContact>;
  countries: Array<GQCompanyCountry>;
  country: Maybe<Scalars['String']['output']>;
  countryAlpha2: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  disclosures: Array<GQDisclosure>;
  disclosuresV2: Array<GQDisclosure>;
  duns: Array<GQCompanyDuns>;
  editor: Maybe<GQUser>;
  eins: Array<GQCompanyEin>;
  fullLogoUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDemo: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isWatershedCorporateCustomer: Scalars['Boolean']['output'];
  isins: Array<GQCompanyIsin>;
  leis: Array<GQCompanyLei>;
  localRegistries: Array<GQCompanyLocalRegistry>;
  logo: Maybe<GQCompanyUrl>;
  logoUrl: Maybe<Scalars['String']['output']>;
  logoUrlId: Maybe<Scalars['ID']['output']>;
  naicsCode: Maybe<Scalars['String']['output']>;
  naicsCodeSource: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  names: Array<GQCompanyName>;
  parentRelationships: Array<GQParentRelationship>;
  replacedBy: Maybe<GQCompany>;
  sAndP: Maybe<GQCompanySAndP>;
  sbtiStage: GQCompanySbtCommitmentStage;
  sfdcOpportunityId: Maybe<Scalars['String']['output']>;
  sfdcStage: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  sourceId: Scalars['ID']['output'];
  sourceKey: Maybe<GQCompanySourceKey>;
  sustainabilityWebsite: Maybe<GQCompanyUrl>;
  sustainabilityWebsiteUrl: Maybe<Scalars['String']['output']>;
  sustainabilityWebsiteUrlId: Maybe<Scalars['ID']['output']>;
  tickerSymbols: Array<GQCompanyTickerSymbol>;
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
  userEditorId: Maybe<Scalars['ID']['output']>;
  vats: Array<GQCompanyVat>;
};

export type GQCompanyDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyDisclosuresV2Args = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQCompanyParentRelationshipsArgs = {
  onlyGetActiveRelationships: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCompanyAutoMatch = {
  __typename?: 'CompanyAutoMatch';
  company: GQCompany;
  confidenceScore: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

export type GQCompanyBea = GQIdInterface & {
  __typename?: 'CompanyBea';
  bea: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyChangeRequest = {
  __typename?: 'CompanyChangeRequest';
  action: GQCompanyChangeRequestAction;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  org: GQOrganization;
  payload: Scalars['JSONString']['output'];
  userEditor: Maybe<GQUser>;
};

export const GQCompanyChangeRequestAction = {
  Create: 'Create',
} as const;

export type GQCompanyChangeRequestAction =
  (typeof GQCompanyChangeRequestAction)[keyof typeof GQCompanyChangeRequestAction];
export type GQCompanyClimateCommitment = {
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
};

export const GQCompanyClimateCommitmentKind = {
  CarbonNeutral: 'CarbonNeutral',
  CleanEnergy: 'CleanEnergy',
  NetZero: 'NetZero',
  ScienceBasedTarget: 'ScienceBasedTarget',
} as const;

export type GQCompanyClimateCommitmentKind =
  (typeof GQCompanyClimateCommitmentKind)[keyof typeof GQCompanyClimateCommitmentKind];
export type GQCompanyClimateCommitmentRaw = {
  __typename?: 'CompanyClimateCommitmentRaw';
  commitmentMadeDate: Maybe<Scalars['Date']['output']>;
  commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
  commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: GQCompanyClimateCommitmentKind;
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  sbtBaselineYear: Maybe<Scalars['Int']['output']>;
  sbtCommitmentDeadline: Maybe<Scalars['Date']['output']>;
  sbtCommitmentStage: Maybe<GQSbtCommitmentStage>;
  sbtCommitmentType: Maybe<GQSbtCommitmentType>;
  sbtLongTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNearTermTargetYear: Maybe<Scalars['Int']['output']>;
  sbtNetZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  sbtNetZeroTargetYear: Maybe<Scalars['Int']['output']>;
  sbtTargetClassification: Maybe<GQSbtTargetClassification>;
  targetPercentageCleanEnergy: Maybe<Scalars['Float']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyClimateProgress = {
  DisclosedEmissions: 'DisclosedEmissions',
  DisclosedEmissionsAndSetCommitments: 'DisclosedEmissionsAndSetCommitments',
  DisclosedEmissionsInitiatives: 'DisclosedEmissionsInitiatives',
  DisclosedEmissionsTargetsInitiatives: 'DisclosedEmissionsTargetsInitiatives',
  DisclosedInitiatives: 'DisclosedInitiatives',
  DisclosedTargets: 'DisclosedTargets',
  DisclosedTargetsInitiatives: 'DisclosedTargetsInitiatives',
  None: 'None',
  Unknown: 'Unknown',
} as const;

export type GQCompanyClimateProgress =
  (typeof GQCompanyClimateProgress)[keyof typeof GQCompanyClimateProgress];
export type GQCompanyConnection = {
  __typename?: 'CompanyConnection';
  edges: Array<Maybe<GQCompanyEdge>>;
  pageInfo: GQPageInfo;
};

export type GQCompanyCountry = GQIdInterface & {
  __typename?: 'CompanyCountry';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyDataFiltersForMetric = {
  cdpScoreSet?: InputMaybe<Array<Scalars['String']['input']>>;
  industry?: InputMaybe<Scalars['String']['input']>;
  peerCompanyGroupId?: InputMaybe<Scalars['ID']['input']>;
  reportingYear?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCompanyDuns = GQIdInterface & {
  __typename?: 'CompanyDuns';
  dunsNumber: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyEdge = {
  __typename?: 'CompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompany>;
};

export type GQCompanyEin = GQIdInterface & {
  __typename?: 'CompanyEin';
  editor: GQUser;
  ein: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyEmissionsInterface = {
  scope1Kgco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationKgco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketKgco2e: Maybe<Scalars['Float']['output']>;
  scope3Kgco2e: Maybe<Scalars['Float']['output']>;
  scope301Kgco2e: Maybe<Scalars['Float']['output']>;
  scope302Kgco2e: Maybe<Scalars['Float']['output']>;
  scope303Kgco2e: Maybe<Scalars['Float']['output']>;
  scope304Kgco2e: Maybe<Scalars['Float']['output']>;
  scope305Kgco2e: Maybe<Scalars['Float']['output']>;
  scope306Kgco2e: Maybe<Scalars['Float']['output']>;
  scope307Kgco2e: Maybe<Scalars['Float']['output']>;
  scope308Kgco2e: Maybe<Scalars['Float']['output']>;
  scope309Kgco2e: Maybe<Scalars['Float']['output']>;
  scope310Kgco2e: Maybe<Scalars['Float']['output']>;
  scope311Kgco2e: Maybe<Scalars['Float']['output']>;
  scope312Kgco2e: Maybe<Scalars['Float']['output']>;
  scope313Kgco2e: Maybe<Scalars['Float']['output']>;
  scope314Kgco2e: Maybe<Scalars['Float']['output']>;
  scope315Kgco2e: Maybe<Scalars['Float']['output']>;
  scope316Kgco2e: Maybe<Scalars['Float']['output']>;
  scope317Kgco2e: Maybe<Scalars['Float']['output']>;
};

export type GQCompanyEmissionsInterfaceV2 = {
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export const GQCompanyEmissionsUnits = {
  AllocatedKgco2e: 'allocatedKgco2e',
  Kgco2e: 'kgco2e',
  Kgco2ePerDollar: 'kgco2ePerDollar',
} as const;

export type GQCompanyEmissionsUnits =
  (typeof GQCompanyEmissionsUnits)[keyof typeof GQCompanyEmissionsUnits];
export type GQCompanyFilterValues = {
  __typename?: 'CompanyFilterValues';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQCompanyIngestionDiff = GQIdInterface & {
  __typename?: 'CompanyIngestionDiff';
  company: Maybe<GQCompany>;
  companyMatchResults: Maybe<Scalars['JSONString']['output']>;
  companyMatchScore: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isCompanyDemo: Scalars['Boolean']['output'];
  primaryCompanyName: Scalars['String']['output'];
  processedAt: Maybe<Scalars['DateTime']['output']>;
  processedBy: Maybe<GQUser>;
  recordUpdates: Array<GQCompanyIngestionRecordUpdate>;
  sourceRecord: Maybe<GQCompanyIngestionSourceRecord>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQCompanyIngestionRecordUpdate = GQIdInterface & {
  __typename?: 'CompanyIngestionRecordUpdate';
  createdAt: Scalars['DateTime']['output'];
  diff: GQCompanyIngestionDiff;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['JSONString']['output']>;
  oldValue: Maybe<Scalars['JSONString']['output']>;
  recordId: Maybe<Scalars['String']['output']>;
  status: GQCompanyIngestionRecordUpdateStatus;
  tableName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQCompanyIngestionRecordUpdateStatus = {
  Approved: 'Approved',
  Declined: 'Declined',
  Unprocessed: 'Unprocessed',
} as const;

export type GQCompanyIngestionRecordUpdateStatus =
  (typeof GQCompanyIngestionRecordUpdateStatus)[keyof typeof GQCompanyIngestionRecordUpdateStatus];
export type GQCompanyIngestionSourceRecord =
  | GQCdpVendorData
  | GQCompany
  | GQCompanyChangeRequest
  | GQIngestedSbtiCompanyUpdate
  | GQSbtTarget;

export type GQCompanyIsin = GQIdInterface & {
  __typename?: 'CompanyIsin';
  editor: GQUser;
  id: Scalars['ID']['output'];
  isin: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyLei = GQIdInterface & {
  __typename?: 'CompanyLei';
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  lei: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyLocalRegistry = GQIdInterface & {
  __typename?: 'CompanyLocalRegistry';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  localRegistryId: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyMatch = GQIdInterface & {
  __typename?: 'CompanyMatch';
  beaMatch: Maybe<Scalars['String']['output']>;
  company: GQCompany;
  id: Scalars['ID']['output'];
  isinMatch: Maybe<Scalars['String']['output']>;
  leiMatch: Maybe<Scalars['String']['output']>;
  nameMatch: Maybe<Scalars['String']['output']>;
  sAndPIdMatch: Maybe<Scalars['String']['output']>;
  score: GQCompanyMatchScore;
  tickerMatch: Maybe<Scalars['String']['output']>;
};

export const GQCompanyMatchScore = {
  Conflict: 'CONFLICT',
  Exact: 'EXACT',
  High: 'HIGH',
  Mid: 'MID',
  None: 'NONE',
  VeryHigh: 'VERY_HIGH',
} as const;

export type GQCompanyMatchScore =
  (typeof GQCompanyMatchScore)[keyof typeof GQCompanyMatchScore];
export type GQCompanyName = GQIdInterface & {
  __typename?: 'CompanyName';
  cleanedName: Maybe<Scalars['String']['output']>;
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanyPortalSettings = {
  __typename?: 'CompanyPortalSettings';
  id: Maybe<Scalars['ID']['output']>;
  portalCallout: Maybe<Scalars['String']['output']>;
  portalTitle: Scalars['String']['output'];
  sidebarContent: Maybe<Scalars['String']['output']>;
  toggleHeaderImage: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanyPublicDisclosure = GQIdInterface & {
  __typename?: 'CompanyPublicDisclosure';
  editor: GQUser;
  id: Scalars['ID']['output'];
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['ID']['output'];
};

export type GQCompanyRelationship = {
  __typename?: 'CompanyRelationship';
  companyA: GQCompany;
  companyAId: Scalars['ID']['output'];
  companyB: GQCompany;
  companyBId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipType: GQCompanyRelationshipType;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export const GQCompanyRelationshipType = {
  ParentChild: 'ParentChild',
} as const;

export type GQCompanyRelationshipType =
  (typeof GQCompanyRelationshipType)[keyof typeof GQCompanyRelationshipType];
export type GQCompanyReportingFacts = GQIdInterface & {
  __typename?: 'CompanyReportingFacts';
  asOfMonth: Scalars['YearMonth']['output'];
  assetsEuUsd: Maybe<Scalars['Float']['output']>;
  assetsGlobalUsd: Maybe<Scalars['Float']['output']>;
  assetsUkUsd: Maybe<Scalars['Float']['output']>;
  companyId: Scalars['ID']['output'];
  federalContractsUkUsd: Maybe<Scalars['Float']['output']>;
  federalContractsUsUsd: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isBank: Maybe<Scalars['Boolean']['output']>;
  isDirectorsFilerUk: Maybe<Scalars['Boolean']['output']>;
  isDoingBusinessCa: Maybe<Scalars['Boolean']['output']>;
  isInsurance: Maybe<Scalars['Boolean']['output']>;
  isLlpUk: Maybe<Scalars['Boolean']['output']>;
  isNfisFilerUk: Maybe<Scalars['Boolean']['output']>;
  isPublicEu: Maybe<Scalars['Boolean']['output']>;
  isSmallCreditInsurance: Maybe<Scalars['Boolean']['output']>;
  isTradedBankingLlpUk: Maybe<Scalars['Boolean']['output']>;
  largestContractUkUsd: Maybe<Scalars['Float']['output']>;
  legalEntityCountries: Maybe<Array<Scalars['String']['output']>>;
  listedExchanges: Maybe<Array<Scalars['String']['output']>>;
  lseListingStatus: Maybe<Scalars['String']['output']>;
  nfrdFilerStatus: Maybe<Scalars['String']['output']>;
  numEmployeesEu: Maybe<Scalars['Int']['output']>;
  numEmployeesGlobal: Maybe<Scalars['Int']['output']>;
  numEmployeesUk: Maybe<Scalars['Int']['output']>;
  overriddenAt: Maybe<Scalars['DateTime']['output']>;
  primaryJurisdiction: Maybe<Scalars['String']['output']>;
  revenueEuUsd: Maybe<Scalars['Float']['output']>;
  revenueGlobalUsd: Maybe<Scalars['Float']['output']>;
  revenueUkUsd: Maybe<Scalars['Float']['output']>;
  secFilerStatus: Maybe<Scalars['String']['output']>;
};

export type GQCompanyReportingFactsInput = {
  assetsEuUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  assetsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUkUsd?: InputMaybe<Scalars['Float']['input']>;
  federalContractsUsUsd?: InputMaybe<Scalars['Float']['input']>;
  isBank?: InputMaybe<Scalars['Boolean']['input']>;
  isDirectorsFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isDoingBusinessCa?: InputMaybe<Scalars['Boolean']['input']>;
  isInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  isNfisFilerUk?: InputMaybe<Scalars['Boolean']['input']>;
  isPublicEu?: InputMaybe<Scalars['Boolean']['input']>;
  isSmallCreditInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  isTradedBankingLlpUk?: InputMaybe<Scalars['Boolean']['input']>;
  largestContractUkUsd?: InputMaybe<Scalars['Float']['input']>;
  legalEntityCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  listedExchanges?: InputMaybe<Array<Scalars['String']['input']>>;
  lseListingStatus?: InputMaybe<Scalars['String']['input']>;
  nfrdFilerStatus?: InputMaybe<Scalars['String']['input']>;
  numEmployeesEu?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesGlobal?: InputMaybe<Scalars['Int']['input']>;
  numEmployeesUk?: InputMaybe<Scalars['Int']['input']>;
  primaryJurisdiction?: InputMaybe<Scalars['String']['input']>;
  revenueEuUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueGlobalUsd?: InputMaybe<Scalars['Float']['input']>;
  revenueUkUsd?: InputMaybe<Scalars['Float']['input']>;
  secFilerStatus?: InputMaybe<Scalars['String']['input']>;
};

export type GQCompanySAndP = GQIdInterface & {
  __typename?: 'CompanySAndP';
  editor: GQUser;
  id: Scalars['ID']['output'];
  sAndPId: Scalars['String']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export const GQCompanySbtCommitmentStage = {
  Committed: 'Committed',
  None: 'None',
  TargetsDeveloped: 'TargetsDeveloped',
  TargetsSet: 'TargetsSet',
  TargetsSubmitted: 'TargetsSubmitted',
} as const;

export type GQCompanySbtCommitmentStage =
  (typeof GQCompanySbtCommitmentStage)[keyof typeof GQCompanySbtCommitmentStage];
export type GQCompanySalesforce = GQIdInterface & {
  __typename?: 'CompanySalesforce';
  editor: GQUser;
  id: Scalars['ID']['output'];
  opportunityId: Maybe<Scalars['String']['output']>;
  opportunityStage: Maybe<Scalars['String']['output']>;
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
};

export type GQCompanySearchResult = {
  __typename?: 'CompanySearchResult';
  companyIdsWithSupplierSpecificEfs: Array<Scalars['String']['output']>;
  results: Array<GQCompany>;
};

export type GQCompanySourceKey = GQIdInterface & {
  __typename?: 'CompanySourceKey';
  createdAt: Scalars['DateTime']['output'];
  editor: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  priority: Scalars['Int']['output'];
  sourceKey: Scalars['String']['output'];
  sourceNotes: Maybe<Scalars['String']['output']>;
  sourceUrl: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userVisibleSourceNotes: Maybe<Scalars['String']['output']>;
  userVisibleSourceUrl: Maybe<Scalars['String']['output']>;
};

export type GQCompanySurvey = {
  __typename?: 'CompanySurvey';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQCompanySurveyComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Maybe<Scalars['DateTime']['output']>;
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQCompanySurveyAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Maybe<Scalars['ID']['output']>;
  /** @deprecated replaced with custom portal callout configuration */
  supplierPortalCustomEducation: Maybe<Scalars['Boolean']['output']>;
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQCompanySurveyAnswer = {
  __typename?: 'CompanySurveyAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQCompanySurveyAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQCompanySurveyAnswerAttachment = {
  __typename?: 'CompanySurveyAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export const GQCompanySurveyAssignee = {
  RootCustomer: 'RootCustomer',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyAssignee =
  (typeof GQCompanySurveyAssignee)[keyof typeof GQCompanySurveyAssignee];
export type GQCompanySurveyComment = {
  __typename?: 'CompanySurveyComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQCompanySurveyConnection = {
  __typename?: 'CompanySurveyConnection';
  edges: Array<GQCompanySurveyEdge>;
  pageInfo: GQPageInfo;
};

export type GQCompanySurveyEdge = {
  __typename?: 'CompanySurveyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQCompanySurvey>;
};

export type GQCompanySurveyEdit = {
  __typename?: 'CompanySurveyEdit';
  columnName: GQEditColumn;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
  valueType: Maybe<GQEditValueType>;
};

export const GQCompanySurveyKind = {
  Nzsep: 'NZSEP',
  PortfolioCompany: 'PortfolioCompany',
  Supplier: 'Supplier',
} as const;

export type GQCompanySurveyKind =
  (typeof GQCompanySurveyKind)[keyof typeof GQCompanySurveyKind];
export const GQCompanySurveyQuestionKey = {
  AcceptedProposeTarget: 'AcceptedProposeTarget',
  ActivitiesNaics: 'ActivitiesNaics',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  AllocateEmissionsMethodV2: 'AllocateEmissionsMethodV2',
  AllocationMethodology: 'AllocationMethodology',
  AnnualPercentTurnover: 'AnnualPercentTurnover',
  BoardDiversity: 'BoardDiversity',
  CSuiteDiversity: 'CSuiteDiversity',
  CarbonCommitments: 'CarbonCommitments',
  CarbonMeasurementCompletionDate: 'CarbonMeasurementCompletionDate',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CarbonMeasurementProvider: 'CarbonMeasurementProvider',
  CarbonRemoval: 'CarbonRemoval',
  CarbonRemovalAmount: 'CarbonRemovalAmount',
  CeeAbsoluteEmissionsByScope: 'CeeAbsoluteEmissionsByScope',
  CeeAggregatedBuildingSize: 'CeeAggregatedBuildingSize',
  CeeBiomassPercentage: 'CeeBiomassPercentage',
  CeeBuildings: 'CeeBuildings',
  CeeBusinessAirMiles: 'CeeBusinessAirMiles',
  CeeBusinessAirSpend: 'CeeBusinessAirSpend',
  CeeBusinessAirSpendCurrency: 'CeeBusinessAirSpendCurrency',
  CeeBusinessGroundMiles: 'CeeBusinessGroundMiles',
  CeeCloud: 'CeeCloud',
  CeeCloudProviders: 'CeeCloudProviders',
  CeeCloudTotalInstanceHours: 'CeeCloudTotalInstanceHours',
  CeeComputationNonCloud: 'CeeComputationNonCloud',
  CeeComputationNonCloudCleanPower: 'CeeComputationNonCloudCleanPower',
  CeeConsulting: 'CeeConsulting',
  CeeDairyAvgMilkGallonsPerYear: 'CeeDairyAvgMilkGallonsPerYear',
  CeeDairyManureManagement: 'CeeDairyManureManagement',
  CeeDairyManureManagementLiquidCap: 'CeeDairyManureManagementLiquidCap',
  CeeDairyNumberOfCows: 'CeeDairyNumberOfCows',
  CeeDairyProductionSystem: 'CeeDairyProductionSystem',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed',
  CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow:
    'CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow',
  CeeLogisticsAirKmTonnes: 'CeeLogisticsAirKmTonnes',
  CeeLogisticsAirLoading: 'CeeLogisticsAirLoading',
  CeeLogisticsFreezerSeaKmTonnes: 'CeeLogisticsFreezerSeaKmTonnes',
  CeeLogisticsFreezerTruckKmTonnes: 'CeeLogisticsFreezerTruckKmTonnes',
  CeeLogisticsRefrigeratedSeaKmTonnes: 'CeeLogisticsRefrigeratedSeaKmTonnes',
  CeeLogisticsRefrigeratedTruckKmTonnes:
    'CeeLogisticsRefrigeratedTruckKmTonnes',
  CeeLogisticsSeaKmTonnes: 'CeeLogisticsSeaKmTonnes',
  CeeLogisticsSeaLoading: 'CeeLogisticsSeaLoading',
  CeeLogisticsTrainKmTonnes: 'CeeLogisticsTrainKmTonnes',
  CeeLogisticsTrainLoading: 'CeeLogisticsTrainLoading',
  CeeLogisticsTruckKmTonnes: 'CeeLogisticsTruckKmTonnes',
  CeeLogisticsTruckLoading: 'CeeLogisticsTruckLoading',
  CeeOfficeDaysPerWeek: 'CeeOfficeDaysPerWeek',
  CeePaperCleanPowerPercentage: 'CeePaperCleanPowerPercentage',
  CeePaperless: 'CeePaperless',
  CeeRemotePercent: 'CeeRemotePercent',
  CeeRevenueIntensityByScope: 'CeeRevenueIntensityByScope',
  CeeTmsFileUpload: 'CeeTmsFileUpload',
  CeeTmsManualInput: 'CeeTmsManualInput',
  CeeWoodByCountry: 'CeeWoodByCountry',
  ChallengesProcuringRenewableForCustomer:
    'ChallengesProcuringRenewableForCustomer',
  CleanPowerDescription: 'CleanPowerDescription',
  CleanPowerImportReport: 'CleanPowerImportReport',
  CleanPowerInitiativeRenewableEnergyUsage:
    'CleanPowerInitiativeRenewableEnergyUsage',
  CleanPowerInitiativeRenewableEnergyUsageImport:
    'CleanPowerInitiativeRenewableEnergyUsageImport',
  CleanPowerPercentage: 'CleanPowerPercentage',
  CleanPowerPurchased: 'CleanPowerPurchased',
  Comment: 'Comment',
  CompanyIndustry: 'CompanyIndustry',
  CompanyName: 'CompanyName',
  ContactEmail: 'ContactEmail',
  ContactName: 'ContactName',
  ContactTitle: 'ContactTitle',
  CountriesOfOperation: 'CountriesOfOperation',
  CountryOfHeadquarters: 'CountryOfHeadquarters',
  CustomQuestions: 'CustomQuestions',
  CustomerSpendInReportingYear: 'CustomerSpendInReportingYear',
  DaysLostDueToInjury: 'DaysLostDueToInjury',
  DerivedSustainabilityInitiatives: 'DerivedSustainabilityInitiatives',
  EdciAdditionalGhgTargetDetails: 'EdciAdditionalGhgTargetDetails',
  EdciAdditionalMethodologyDetails: 'EdciAdditionalMethodologyDetails',
  EdciAnnualPercentTurnoverForFtes: 'EdciAnnualPercentTurnoverForFtes',
  EdciAnnualRevenueInMostRecentCalendarYear:
    'EdciAnnualRevenueInMostRecentCalendarYear',
  EdciCompanyGrowthStage: 'EdciCompanyGrowthStage',
  EdciCompanyStructure: 'EdciCompanyStructure',
  EdciCountryOfDomicile: 'EdciCountryOfDomicile',
  EdciCountryOfOperations: 'EdciCountryOfOperations',
  EdciDaysLostToInjury: 'EdciDaysLostToInjury',
  EdciDecarbonizationPlan: 'EdciDecarbonizationPlan',
  EdciEmissionsDataReviewDetails: 'EdciEmissionsDataReviewDetails',
  EdciEmployeeSurvey: 'EdciEmployeeSurvey',
  EdciFinancialDataCurrency: 'EdciFinancialDataCurrency',
  EdciFteCountEndOfPriorYear: 'EdciFteCountEndOfPriorYear',
  EdciFteCountEndOfYear: 'EdciFteCountEndOfYear',
  EdciLgbtqBoardMembers: 'EdciLgbtqBoardMembers',
  EdciLongTermNetZeroGoal: 'EdciLongTermNetZeroGoal',
  EdciNetChangeFteDueToMnA: 'EdciNetChangeFteDueToMnA',
  EdciOrganicNetNewHires: 'EdciOrganicNetNewHires',
  EdciPercentResponseEmployeeSurvey: 'EdciPercentResponseEmployeeSurvey',
  EdciPrimaryIndustryOfOperations: 'EdciPrimaryIndustryOfOperations',
  EdciPrimarySectorOfOperations: 'EdciPrimarySectorOfOperations',
  EdciScope1Emissions: 'EdciScope1Emissions',
  EdciScope1Methodology: 'EdciScope1Methodology',
  EdciScope2Emissions: 'EdciScope2Emissions',
  EdciScope2Methodology: 'EdciScope2Methodology',
  EdciScope3Emissions: 'EdciScope3Emissions',
  EdciScope3Methdology: 'EdciScope3Methdology',
  EdciShortTermReductionTarget: 'EdciShortTermReductionTarget',
  EdciTotalBoardMembers: 'EdciTotalBoardMembers',
  EdciTotalCSuitePositions: 'EdciTotalCSuitePositions',
  EdciTotalEnergyConsumption: 'EdciTotalEnergyConsumption',
  EdciTotalNetNewHires: 'EdciTotalNetNewHires',
  EdciTotalRenewableEnergyConsumption: 'EdciTotalRenewableEnergyConsumption',
  EdciTotalWomenCSuitePositions: 'EdciTotalWomenCSuitePositions',
  EdciUnderrepresentedGroupBoardMembers:
    'EdciUnderrepresentedGroupBoardMembers',
  EdciWomenBoardMembers: 'EdciWomenBoardMembers',
  EdciWorkRelatedFatalities: 'EdciWorkRelatedFatalities',
  EdciWorkRelatedInjuries: 'EdciWorkRelatedInjuries',
  EmployeeDiversity: 'EmployeeDiversity',
  EmployeeSurvey: 'EmployeeSurvey',
  EmployeeSurveyKinds: 'EmployeeSurveyKinds',
  EmployeeSurveyResponseRate: 'EmployeeSurveyResponseRate',
  EnergyUseEstimateMethod: 'EnergyUseEstimateMethod',
  EnvironmentalRiskAssessment: 'EnvironmentalRiskAssessment',
  EsgBoardDiscussion: 'EsgBoardDiscussion',
  EsgBoardMember: 'EsgBoardMember',
  EsgRiskAssessment: 'EsgRiskAssessment',
  EvaluateProposedTarget: 'EvaluateProposedTarget',
  ExpenseCategories: 'ExpenseCategories',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  ExpensesNaics: 'ExpensesNaics',
  ExternalReporting: 'ExternalReporting',
  FacilityBiomassNonWoodUsageAmount: 'FacilityBiomassNonWoodUsageAmount',
  FacilityBiomassNonWoodUsageFileUpload:
    'FacilityBiomassNonWoodUsageFileUpload',
  FacilityBiomassNonWoodUsageUnit: 'FacilityBiomassNonWoodUsageUnit',
  FacilityBrackishSurfaceWaterOrSeawaterAmount:
    'FacilityBrackishSurfaceWaterOrSeawaterAmount',
  FacilityBrackishSurfaceWaterOrSeawaterFileUpload:
    'FacilityBrackishSurfaceWaterOrSeawaterFileUpload',
  FacilityBrackishSurfaceWaterOrSeawaterUnit:
    'FacilityBrackishSurfaceWaterOrSeawaterUnit',
  FacilityCleanPowerDescribeCleanPowerSources:
    'FacilityCleanPowerDescribeCleanPowerSources',
  FacilityCleanPowerDoesUseCleanPower: 'FacilityCleanPowerDoesUseCleanPower',
  FacilityCleanPowerRelevantReports: 'FacilityCleanPowerRelevantReports',
  FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower:
    'FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower',
  FacilityCoalUsageAmount: 'FacilityCoalUsageAmount',
  FacilityCoalUsageFileUpload: 'FacilityCoalUsageFileUpload',
  FacilityCoalUsageUnit: 'FacilityCoalUsageUnit',
  FacilityDetailedAllocationDoesSubMeterOrTrackProperties:
    'FacilityDetailedAllocationDoesSubMeterOrTrackProperties',
  FacilityDetailedAllocationDoesTrackMetricForCompanyProducts:
    'FacilityDetailedAllocationDoesTrackMetricForCompanyProducts',
  FacilityDetailedAllocationMetricUnit: 'FacilityDetailedAllocationMetricUnit',
  FacilityDetailedAllocationRelevantReport:
    'FacilityDetailedAllocationRelevantReport',
  FacilityDetailedAllocationTotalAmountOfMetricForCompany:
    'FacilityDetailedAllocationTotalAmountOfMetricForCompany',
  FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility:
    'FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility',
  FacilityElectrictyFromGridUsageAmount:
    'FacilityElectrictyFromGridUsageAmount',
  FacilityElectrictyFromGridUsageFileUpload:
    'FacilityElectrictyFromGridUsageFileUpload',
  FacilityElectrictyFromGridUsageUnit: 'FacilityElectrictyFromGridUsageUnit',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterAmount: 'FacilityGroundwaterAmount',
  FacilityGroundwaterFileUpload: 'FacilityGroundwaterFileUpload',
  FacilityGroundwaterUnit: 'FacilityGroundwaterUnit',
  FacilityInfoAddress: 'FacilityInfoAddress',
  FacilityInfoCountryOfOperation: 'FacilityInfoCountryOfOperation',
  FacilityInfoHeadcount: 'FacilityInfoHeadcount',
  FacilityInfoIndustry: 'FacilityInfoIndustry',
  FacilityInfoListAllProcesses: 'FacilityInfoListAllProcesses',
  FacilityInfoName: 'FacilityInfoName',
  FacilityMunicipalWaterAmount: 'FacilityMunicipalWaterAmount',
  FacilityMunicipalWaterFileUpload: 'FacilityMunicipalWaterFileUpload',
  FacilityMunicipalWaterUnit: 'FacilityMunicipalWaterUnit',
  FacilityNaturalGasUsageAmount: 'FacilityNaturalGasUsageAmount',
  FacilityNaturalGasUsageFileUpload: 'FacilityNaturalGasUsageFileUpload',
  FacilityNaturalGasUsageUnit: 'FacilityNaturalGasUsageUnit',
  FacilityOtherAmount: 'FacilityOtherAmount',
  FacilityOtherFileUpload: 'FacilityOtherFileUpload',
  FacilityOtherUnit: 'FacilityOtherUnit',
  FacilityPetrolUsageAmount: 'FacilityPetrolUsageAmount',
  FacilityPetrolUsageFileUpload: 'FacilityPetrolUsageFileUpload',
  FacilityPetrolUsageUnit: 'FacilityPetrolUsageUnit',
  FacilityProductionListOfTypesOfProducts:
    'FacilityProductionListOfTypesOfProducts',
  FacilityProductionTotalNumberOfUnits: 'FacilityProductionTotalNumberOfUnits',
  FacilityPropaneUsageAmount: 'FacilityPropaneUsageAmount',
  FacilityPropaneUsageFileUpload: 'FacilityPropaneUsageFileUpload',
  FacilityPropaneUsageUnit: 'FacilityPropaneUsageUnit',
  FacilityRainwaterAmount: 'FacilityRainwaterAmount',
  FacilityRainwaterFileUpload: 'FacilityRainwaterFileUpload',
  FacilityRainwaterUnit: 'FacilityRainwaterUnit',
  FacilitySolarUsageAmount: 'FacilitySolarUsageAmount',
  FacilitySolarUsageFileUpload: 'FacilitySolarUsageFileUpload',
  FacilitySolarUsageUnit: 'FacilitySolarUsageUnit',
  FacilityWastewaterAmount: 'FacilityWastewaterAmount',
  FacilityWastewaterFileUpload: 'FacilityWastewaterFileUpload',
  FacilityWastewaterUnit: 'FacilityWastewaterUnit',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodUsageAmount: 'FacilityWoodUsageAmount',
  FacilityWoodUsageFileUpload: 'FacilityWoodUsageFileUpload',
  FacilityWoodUsageUnit: 'FacilityWoodUsageUnit',
  FootprintMethodologyImportReport: 'FootprintMethodologyImportReport',
  FootprintProviderName: 'FootprintProviderName',
  GhgInventoryInLastYear: 'GhgInventoryInLastYear',
  GhgInventoryInLastYearMethodology: 'GhgInventoryInLastYearMethodology',
  GhgScope1CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope1CustomerShareOfEmissionsTonnesCo2e',
  GhgScope1FugitiveEmissionsRevenueIntensity:
    'GhgScope1FugitiveEmissionsRevenueIntensity',
  GhgScope1FugitiveEmissionsTonnesCo2e: 'GhgScope1FugitiveEmissionsTonnesCo2e',
  GhgScope1Methodology: 'GhgScope1Methodology',
  GhgScope1MobileCombustionRevenueIntensity:
    'GhgScope1MobileCombustionRevenueIntensity',
  GhgScope1MobileCombustionTonnesCo2e: 'GhgScope1MobileCombustionTonnesCo2e',
  GhgScope1RevenueIntensity: 'GhgScope1RevenueIntensity',
  GhgScope1StationaryCombustionRevenueIntensity:
    'GhgScope1StationaryCombustionRevenueIntensity',
  GhgScope1StationaryCombustionTonnesCo2e:
    'GhgScope1StationaryCombustionTonnesCo2e',
  GhgScope1TonnesCo2e: 'GhgScope1TonnesCo2e',
  GhgScope2CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope2CustomerShareOfEmissionsTonnesCo2e',
  GhgScope2DisclosureIncludesLeasedBuildings:
    'GhgScope2DisclosureIncludesLeasedBuildings',
  GhgScope2LocationBasedRevenueIntensity:
    'GhgScope2LocationBasedRevenueIntensity',
  GhgScope2LocationBasedTonnesCo2e: 'GhgScope2LocationBasedTonnesCo2e',
  GhgScope2MarketBasedRevenueIntensity: 'GhgScope2MarketBasedRevenueIntensity',
  GhgScope2MarketBasedTonnesCo2e: 'GhgScope2MarketBasedTonnesCo2e',
  GhgScope3ByCategoryRevenueIntensity: 'GhgScope3ByCategoryRevenueIntensity',
  GhgScope3ByCategoryTonnesCo2e: 'GhgScope3ByCategoryTonnesCo2e',
  GhgScope3CustomerShareOfEmissionsTonnesCo2e:
    'GhgScope3CustomerShareOfEmissionsTonnesCo2e',
  GhgScope3Methodology: 'GhgScope3Methodology',
  GhgScope3TotalRevenueIntensity: 'GhgScope3TotalRevenueIntensity',
  GhgScope3TotalTonnesCo2e: 'GhgScope3TotalTonnesCo2e',
  HasEnvironmentalTeam: 'HasEnvironmentalTeam',
  HasMeasuredScope3: 'HasMeasuredScope3',
  HasSbtTargets: 'HasSbtTargets',
  HasSubmittedSbtiTargets: 'HasSubmittedSbtiTargets',
  HasTargets: 'HasTargets',
  HaveCommittedSbtiTargets: 'HaveCommittedSbtiTargets',
  HaveMeasuredEmissions: 'HaveMeasuredEmissions',
  HaveMeasuredEmissionsV2: 'HaveMeasuredEmissionsV2',
  Headcount: 'Headcount',
  HealthBenefits: 'HealthBenefits',
  HelpNeeded: 'HelpNeeded',
  HelpNeededMeasuringEmissions: 'HelpNeededMeasuringEmissions',
  HelpNeededReductionTargets: 'HelpNeededReductionTargets',
  HelpNeededSbtTargets: 'HelpNeededSbtTargets',
  HelpNeededSettingTargets: 'HelpNeededSettingTargets',
  HelpNeededSustainabilityProgram: 'HelpNeededSustainabilityProgram',
  HelpNeededToSetSbtTargets: 'HelpNeededToSetSbtTargets',
  HelpNeededToSetTargets: 'HelpNeededToSetTargets',
  ImportReport: 'ImportReport',
  LearningBusinessCaseCorporateClimateActionBenefits:
    'LearningBusinessCaseCorporateClimateActionBenefits',
  LearningBusinessCaseEmployees: 'LearningBusinessCaseEmployees',
  LearningBusinessCaseMarketTrends: 'LearningBusinessCaseMarketTrends',
  LearningBusinessCaseOperationalCosts: 'LearningBusinessCaseOperationalCosts',
  LearningBusinessCaseRisk: 'LearningBusinessCaseRisk',
  LearningCorporateClimateActionReduceYourEmissions:
    'LearningCorporateClimateActionReduceYourEmissions',
  LearningCorporateClimateActionStep2: 'LearningCorporateClimateActionStep2',
  LearningCorporateClimateActionStep3: 'LearningCorporateClimateActionStep3',
  LearningEngageYourTeamEngagingEmployeesTactics:
    'LearningEngageYourTeamEngagingEmployeesTactics',
  LearningEngageYourTeamEngagingLeadershipTactics:
    'LearningEngageYourTeamEngagingLeadershipTactics',
  LearningEngageYourTeamIdentifyingStakeholders:
    'LearningEngageYourTeamIdentifyingStakeholders',
  LearningFinanceImpAuditSlider: 'LearningFinanceImpAuditSlider',
  LearningFinanceImpAuditingVideoFinished:
    'LearningFinanceImpAuditingVideoFinished',
  LearningFinanceImpDataManagementSlider:
    'LearningFinanceImpDataManagementSlider',
  LearningFinanceImpDataManagementVideoFinished:
    'LearningFinanceImpDataManagementVideoFinished',
  LearningFinanceImpDeepDiveVideoFinished:
    'LearningFinanceImpDeepDiveVideoFinished',
  LearningFinanceImpEmissionsQuantificationVideoFinished:
    'LearningFinanceImpEmissionsQuantificationVideoFinished',
  LearningFinanceImpManagementToolsVideoFinished:
    'LearningFinanceImpManagementToolsVideoFinished',
  LearningFinanceImpRecap: 'LearningFinanceImpRecap',
  LearningFinanceImpValue: 'LearningFinanceImpValue',
  LearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'LearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  LearningLaunchingAClimateProgramLongTerm:
    'LearningLaunchingAClimateProgramLongTerm',
  LearningLaunchingAClimateProgramSupport:
    'LearningLaunchingAClimateProgramSupport',
  LearningLaunchingAClimateProgramVoluntaryReporting:
    'LearningLaunchingAClimateProgramVoluntaryReporting',
  LearningMeasuringEmissionsBusinessActivities:
    'LearningMeasuringEmissionsBusinessActivities',
  LearningMeasuringEmissionsCarbonAccounting:
    'LearningMeasuringEmissionsCarbonAccounting',
  LearningMeasuringEmissionsEmissionsFactors:
    'LearningMeasuringEmissionsEmissionsFactors',
  LearningMeasuringEmissionsScopeOne: 'LearningMeasuringEmissionsScopeOne',
  LearningMeasuringEmissionsScopeThree: 'LearningMeasuringEmissionsScopeThree',
  LearningMeasuringEmissionsScopeTwo: 'LearningMeasuringEmissionsScopeTwo',
  LearningMeasuringEmissionsSpectrum: 'LearningMeasuringEmissionsSpectrum',
  LearningScienceBasedTargetsClimateScienceBasics:
    'LearningScienceBasedTargetsClimateScienceBasics',
  LearningScienceBasedTargetsDefinition3:
    'LearningScienceBasedTargetsDefinition3',
  LearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'LearningScienceBasedTargetsDevelopTargetKeyQuestions',
  LearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'LearningScienceBasedTargetsNumberOfCompaniesCommitted',
  LearningVideosFinished: 'LearningVideosFinished',
  LivingWage: 'LivingWage',
  MeasurementProjectsToShare: 'MeasurementProjectsToShare',
  ModifiedProposeTarget: 'ModifiedProposeTarget',
  NetChangeEmployeesNonOrganic: 'NetChangeEmployeesNonOrganic',
  NoGhgInventoryHelpNeeded: 'NoGhgInventoryHelpNeeded',
  NoSustainabilityProgramHelpNeeded: 'NoSustainabilityProgramHelpNeeded',
  Operations: 'Operations',
  OtherTargets: 'OtherTargets',
  Paperless: 'Paperless',
  ParentalLeave: 'ParentalLeave',
  PlanOfRecordCommitToMeasureGhg: 'PlanOfRecordCommitToMeasureGhg',
  PlanOfRecordCommitToMeasureGhgNoExplain:
    'PlanOfRecordCommitToMeasureGhgNoExplain',
  PlanOfRecordCommitToWorkingTowardsSbt:
    'PlanOfRecordCommitToWorkingTowardsSbt',
  PlanOfRecordCommitToWorkingTowardsSbtNoExplain:
    'PlanOfRecordCommitToWorkingTowardsSbtNoExplain',
  PlanOfRecordSubmittedSbtLetter: 'PlanOfRecordSubmittedSbtLetter',
  PlanOfRecordSubmittedSbtLetterExpectedDate:
    'PlanOfRecordSubmittedSbtLetterExpectedDate',
  PlanOfRecordSubmittedSbtLetterFileUpload:
    'PlanOfRecordSubmittedSbtLetterFileUpload',
  PlanOfRecordSubmittedSbtLetterNoExplain:
    'PlanOfRecordSubmittedSbtLetterNoExplain',
  PleeEstimationOutputs: 'PleeEstimationOutputs',
  ProcessCheckEmissionsAlreadyAllocated:
    'ProcessCheckEmissionsAlreadyAllocated',
  ProviderVerifiedFootprint: 'ProviderVerifiedFootprint',
  ReducedTravelPercentage: 'ReducedTravelPercentage',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionInitiativesSet: 'ReductionInitiativesSet',
  ReductionTargets: 'ReductionTargets',
  ReductionTargetsPlanOfAction: 'ReductionTargetsPlanOfAction',
  ReductionTargetsSet: 'ReductionTargetsSet',
  RemainingSitesElectricityMWh: 'RemainingSitesElectricityMWh',
  RenewableEnergyTargets: 'RenewableEnergyTargets',
  Revenue: 'Revenue',
  RevenueCurrency: 'RevenueCurrency',
  SasbIndustry: 'SasbIndustry',
  SasbSector: 'SasbSector',
  SbtDevelopTargetHasDevelopedTarget: 'SbtDevelopTargetHasDevelopedTarget',
  SbtEngagementStatus: 'SbtEngagementStatus',
  SbtGhgMeasurementHasCompletedMeasurement:
    'SbtGhgMeasurementHasCompletedMeasurement',
  SbtSubmitTargetHasSubmittedTarget: 'SbtSubmitTargetHasSubmittedTarget',
  SbtSubmitTargetSubmissionLetterImport:
    'SbtSubmitTargetSubmissionLetterImport',
  SbtiCommitmentLetterImport: 'SbtiCommitmentLetterImport',
  Scope1And2EmissionTargets: 'Scope1And2EmissionTargets',
  Scope3EmissionTargets: 'Scope3EmissionTargets',
  SfdrBiodiversitySensitiveAreas: 'SfdrBiodiversitySensitiveAreas',
  SfdrBoardGenderDiversity: 'SfdrBoardGenderDiversity',
  SfdrEmissionsToWater: 'SfdrEmissionsToWater',
  SfdrEnergyConsumptionHighImpact: 'SfdrEnergyConsumptionHighImpact',
  SfdrExposureToControversialWeapons: 'SfdrExposureToControversialWeapons',
  SfdrFossilFuelSector: 'SfdrFossilFuelSector',
  SfdrGenderPayGap: 'SfdrGenderPayGap',
  SfdrHazardousWaste: 'SfdrHazardousWaste',
  SfdrUnGlobalCompactCompliance: 'SfdrUnGlobalCompactCompliance',
  SfdrUnGlobalCompactViolations: 'SfdrUnGlobalCompactViolations',
  ShareEmissionsPreference: 'ShareEmissionsPreference',
  ShareFootprint: 'ShareFootprint',
  ShareFootprintDisclosureData: 'ShareFootprintDisclosureData',
  ShareMethod: 'ShareMethod',
  ShareMethodV2: 'ShareMethodV2',
  SitesElectricityData: 'SitesElectricityData',
  SmeHeadcount: 'SmeHeadcount',
  SubmittedSbtiCommitment: 'SubmittedSbtiCommitment',
  SustainabilityCommitments: 'SustainabilityCommitments',
  SustainabilityInitiatives: 'SustainabilityInitiatives',
  SustainabilityInitiativesWithDetails: 'SustainabilityInitiativesWithDetails',
  SustainabilityProgram: 'SustainabilityProgram',
  TopMaterialIssues: 'TopMaterialIssues',
  TotalEmployeesPriorYearEnd: 'TotalEmployeesPriorYearEnd',
  TotalEmployeesYearEnd: 'TotalEmployeesYearEnd',
  TotalEnergyConsumption: 'TotalEnergyConsumption',
  VisibilityOfCountryForAnimalFeed: 'VisibilityOfCountryForAnimalFeed',
  WasteManagement: 'WasteManagement',
  WorkplaceFatality: 'WorkplaceFatality',
  WorkplaceInjury: 'WorkplaceInjury',
  YearFullyRenewableForCustomer: 'YearFullyRenewableForCustomer',
} as const;

export type GQCompanySurveyQuestionKey =
  (typeof GQCompanySurveyQuestionKey)[keyof typeof GQCompanySurveyQuestionKey];
export const GQCompanySurveyStatus = {
  Approved: 'Approved',
  AutoApproved: 'AutoApproved',
  ChangesRequested: 'ChangesRequested',
  Closed: 'Closed',
  InProgress: 'InProgress',
  LoggedIn: 'LoggedIn',
  Submitted: 'Submitted',
  SurveyCreated: 'SurveyCreated',
} as const;

export type GQCompanySurveyStatus =
  (typeof GQCompanySurveyStatus)[keyof typeof GQCompanySurveyStatus];
export const GQCompanySurveyStep = {
  Activities: 'Activities',
  AllocateEmissionsMethod: 'AllocateEmissionsMethod',
  CarbonMeasurementPlan: 'CarbonMeasurementPlan',
  CleanPowerInitiativeConfirm: 'CleanPowerInitiativeConfirm',
  CleanPowerInitiativeElectricitySource:
    'CleanPowerInitiativeElectricitySource',
  CleanPowerInitiativeIntro: 'CleanPowerInitiativeIntro',
  Company: 'Company',
  Confirm: 'Confirm',
  CustomQuestions: 'CustomQuestions',
  ElectricitySites: 'ElectricitySites',
  ElectricitySource: 'ElectricitySource',
  EmissionAllocation: 'EmissionAllocation',
  Environmental: 'Environmental',
  EsgdcSocial: 'EsgdcSocial',
  EstimationCompanyInfo: 'EstimationCompanyInfo',
  ExpenseCategoryEmissions: 'ExpenseCategoryEmissions',
  ExpenseCategoryIntro: 'ExpenseCategoryIntro',
  ExpenseCategoryShareMethod: 'ExpenseCategoryShareMethod',
  Expenses: 'Expenses',
  FacilityBiomassNonWoodReporting: 'FacilityBiomassNonWoodReporting',
  FacilityBrackishSurfaceWaterOrSeawaterReporting:
    'FacilityBrackishSurfaceWaterOrSeawaterReporting',
  FacilityCleanPower: 'FacilityCleanPower',
  FacilityCoalReporting: 'FacilityCoalReporting',
  FacilityDetailedAllocation: 'FacilityDetailedAllocation',
  FacilityElectrictyFromGridReporting: 'FacilityElectrictyFromGridReporting',
  FacilityEnergySources: 'FacilityEnergySources',
  FacilityGroundwaterReporting: 'FacilityGroundwaterReporting',
  FacilityInfo: 'FacilityInfo',
  FacilityMunicipalWaterReporting: 'FacilityMunicipalWaterReporting',
  FacilityNaturalGasReporting: 'FacilityNaturalGasReporting',
  FacilityOtherReporting: 'FacilityOtherReporting',
  FacilityPetrolReporting: 'FacilityPetrolReporting',
  FacilityProductionInfo: 'FacilityProductionInfo',
  FacilityPropaneReporting: 'FacilityPropaneReporting',
  FacilityRainwaterReporting: 'FacilityRainwaterReporting',
  FacilitySolarReporting: 'FacilitySolarReporting',
  FacilityWastewaterReporting: 'FacilityWastewaterReporting',
  FacilityWaterSources: 'FacilityWaterSources',
  FacilityWoodReporting: 'FacilityWoodReporting',
  FootprintQuality: 'FootprintQuality',
  GhgMeasurementProgress: 'GhgMeasurementProgress',
  Governance: 'Governance',
  HaveMeasuredMethod: 'HaveMeasuredMethod',
  ImportReport: 'ImportReport',
  Intro: 'Intro',
  LearningContent: 'LearningContent',
  LearningVideo: 'LearningVideo',
  ProposeTarget: 'ProposeTarget',
  ProposeTargetConfirm: 'ProposeTargetConfirm',
  ProposeTargetIntro: 'ProposeTargetIntro',
  ProposeTargetModify: 'ProposeTargetModify',
  ReductionInitiatives: 'ReductionInitiatives',
  ReductionTargets: 'ReductionTargets',
  Revenue: 'Revenue',
  SbtCommitmentCommitted: 'SbtCommitmentCommitted',
  SbtCommitmentConfirm: 'SbtCommitmentConfirm',
  SbtCommitmentIntro: 'SbtCommitmentIntro',
  SbtCommitmentUploadLetter: 'SbtCommitmentUploadLetter',
  SbtDevelopTargetConfirm: 'SbtDevelopTargetConfirm',
  SbtDevelopTargetIntro: 'SbtDevelopTargetIntro',
  SbtDevelopTargetProgress: 'SbtDevelopTargetProgress',
  SbtGhgMeasurementConfirm: 'SbtGhgMeasurementConfirm',
  SbtGhgMeasurementIntro: 'SbtGhgMeasurementIntro',
  SbtGhgMeasurementProgress: 'SbtGhgMeasurementProgress',
  SbtGhgMeasurementShareMethod: 'SbtGhgMeasurementShareMethod',
  SbtProgress: 'SbtProgress',
  SbtProgressConfirm: 'SbtProgressConfirm',
  SbtProgressIntro: 'SbtProgressIntro',
  SbtProgressSbtEngagementStatus: 'SbtProgressSbtEngagementStatus',
  SbtSubmitTargetConfirm: 'SbtSubmitTargetConfirm',
  SbtSubmitTargetIntro: 'SbtSubmitTargetIntro',
  SbtSubmitTargetProgress: 'SbtSubmitTargetProgress',
  Scope1And2Emission: 'Scope1And2Emission',
  Scope3Emission: 'Scope3Emission',
  SfdrClimateAndEnvironmentIndicators: 'SfdrClimateAndEnvironmentIndicators',
  SfdrSocialIndicators: 'SfdrSocialIndicators',
  ShareMethod: 'ShareMethod',
  SmeStatus: 'SmeStatus',
  Social: 'Social',
  SustainabilityMaturity: 'SustainabilityMaturity',
  SustainabilityMaturityTargetFollowup: 'SustainabilityMaturityTargetFollowup',
} as const;

export type GQCompanySurveyStep =
  (typeof GQCompanySurveyStep)[keyof typeof GQCompanySurveyStep];
export type GQCompanyTickerSymbol = GQIdInterface & {
  __typename?: 'CompanyTickerSymbol';
  editor: GQUser;
  exchangeSymbol: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSourcePrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  tickerSymbol: Scalars['String']['output'];
};

export type GQCompanyUrl = GQIdInterface & {
  __typename?: 'CompanyUrl';
  createdAt: Scalars['DateTime']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type GQCompanyVat = GQIdInterface & {
  __typename?: 'CompanyVat';
  countryAlpha2: Scalars['String']['output'];
  editor: GQUser;
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  source: GQCompanySourceKey;
  sourceId: Scalars['String']['output'];
  vatId: Scalars['String']['output'];
};

export type GQCompleteOnboardingInput = {
  kind: GQOnboardingKind;
  userId: Scalars['ID']['input'];
};

export type GQCompleteOnboardingPayload = {
  __typename?: 'CompleteOnboardingPayload';
  user: Maybe<GQUser>;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
};

export type GQCompleteUserUploadTaskInput = {
  /** @deprecated Not used anymore, delete 8/1/2024 */
  markedCompleteManually?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCompleteUserUploadTaskPayload = {
  __typename?: 'CompleteUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
  userUploadTask: GQUserUploadTask;
};

export const GQCompletedLifecycleStage = {
  Kickoff: 'Kickoff',
  Measurement: 'Measurement',
  Reductions: 'Reductions',
} as const;

export type GQCompletedLifecycleStage =
  (typeof GQCompletedLifecycleStage)[keyof typeof GQCompletedLifecycleStage];
export type GQCompositeDataOrgDetail = {
  __typename?: 'CompositeDataOrgDetail';
  latestPublishedRevision: Maybe<GQReferenceDataRevision>;
  organization: Maybe<GQOrganization>;
};

export type GQComputeReportAnswerWarningsForOrgInput = {
  componentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['ID']['input'];
};

export type GQComputeReportAnswerWarningsForOrgPayload = {
  __typename?: 'ComputeReportAnswerWarningsForOrgPayload';
  numVerified: Scalars['Int']['output'];
};

export type GQComputeReportQuestionMetricInput = {
  dataFilters?: InputMaybe<GQCompanyDataFiltersForMetric>;
  metricId: Scalars['ID']['input'];
  metricInputs: Scalars['JSONString']['input'];
  questionIdentifier: Scalars['String']['input'];
};

export type GQComputedFilterExpressionPrimitive = {
  __typename?: 'ComputedFilterExpressionPrimitive';
  field: GQComputedFilterField;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

/** Computed fields are derived from attributes of the footprint. */
export const GQComputedFilterField = {
  ElectricityType: 'electricityType',
} as const;

export type GQComputedFilterField =
  (typeof GQComputedFilterField)[keyof typeof GQComputedFilterField];
export type GQConfigLinterError = {
  __typename?: 'ConfigLinterError';
  message: Scalars['String']['output'];
  reportObjectId: Scalars['ID']['output'];
  type: GQConfigLinterErrorType;
};

export const GQConfigLinterErrorType = {
  Error: 'Error',
  Warning: 'Warning',
} as const;

export type GQConfigLinterErrorType =
  (typeof GQConfigLinterErrorType)[keyof typeof GQConfigLinterErrorType];
export const GQConfigLinterStatus = {
  Invalid: 'Invalid',
  Valid: 'Valid',
  ValidWithWarnings: 'ValidWithWarnings',
} as const;

export type GQConfigLinterStatus =
  (typeof GQConfigLinterStatus)[keyof typeof GQConfigLinterStatus];
export type GQConfirmMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
};

export type GQConfirmMarketplacePurchasePayload = {
  __typename?: 'ConfirmMarketplacePurchasePayload';
  purchase: GQMarketplacePurchase;
};

export type GQConfirmUtilitySchemaInput = {
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQConfirmUtilitySchemaPayload = {
  __typename?: 'ConfirmUtilitySchemaPayload';
  error: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQContextOrgInfo = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQContextRelationInterface = {
  adminUrl: Scalars['String']['output'];
  /** @deprecated Context items are being deprecated */
  ancestorRelations: Array<
    | GQDataIssue
    | GQDataset
    | GQDatasource
    | GQFileMetadata
    | GQFinancialsReviewItem
    | GQMeasurementProject
  >;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export const GQContractingStatus = {
  InDiscussion: 'InDiscussion',
  MovingToMeasurement: 'MovingToMeasurement',
  NotMovingForward: 'NotMovingForward',
  Referred: 'Referred',
} as const;

export type GQContractingStatus =
  (typeof GQContractingStatus)[keyof typeof GQContractingStatus];
export type GQConversionChainItemForDashboard =
  | GQAssumptionRecordForDashboard
  | GQUnitConversionRecord;

export type GQConversionFactorForDashboard = GQIdInterface & {
  __typename?: 'ConversionFactorForDashboard';
  assumptions: Array<GQAssumptionRecordForDashboard>;
  conversionChain: Maybe<Array<GQConversionChainItemForDashboard>>;
  conversionFactorId: Scalars['String']['output'];
  conversionMultiplier: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  inputUnit: Scalars['String']['output'];
  outputUnit: Scalars['String']['output'];
};

export const GQCountryAlpha2 = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW',
} as const;

export type GQCountryAlpha2 =
  (typeof GQCountryAlpha2)[keyof typeof GQCountryAlpha2];
export type GQCreateAndSubmitUserUploadCandidateInput = {
  ctsId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  datasourceId: Scalars['ID']['input'];
  kind: GQUserUploadCandidateKind;
  name: Scalars['String']['input'];
  rows: Array<Scalars['JSONString']['input']>;
  supportingDocumentUserUploadIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateAndSubmitUserUploadCandidatePayload = {
  __typename?: 'CreateAndSubmitUserUploadCandidatePayload';
  userUploadCandidate: GQUserUploadCandidate;
  userUploadCandidateSubmission: Maybe<GQUserUploadCandidateSubmission>;
};

export type GQCreateArcadiaIntegrationInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type GQCreateAssetCommentAttachmentInput = {
  contentSize: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
};

export type GQCreateAssetCommentInput = {
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  attachments: Array<GQCreateAssetCommentAttachmentInput>;
  commentText: Scalars['String']['input'];
};

export type GQCreateAssetCommentPayload = {
  __typename?: 'CreateAssetCommentPayload';
  assetComment: GQAssetComment;
  attachmentMetadata: Array<GQAttachmentMetadata>;
};

export type GQCreateAssetCorporateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyBea?: InputMaybe<Scalars['String']['input']>;
  companyDunsNumber?: InputMaybe<Scalars['String']['input']>;
  companyEin?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyIsin?: InputMaybe<Scalars['String']['input']>;
  companyLei?: InputMaybe<Scalars['String']['input']>;
  companyLocalRegistryId?: InputMaybe<Scalars['String']['input']>;
  companySAndPId?: InputMaybe<Scalars['String']['input']>;
  companyTicker?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  companyVatId?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetCorporatePayload = {
  __typename?: 'CreateAssetCorporatePayload';
  asset: GQAssetCorporate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetGroupInput = {
  action: GQFinanceAuditAction;
  assetClass?: InputMaybe<GQFundAssetClass>;
  /** @deprecated remove 10/14 */
  companyId?: InputMaybe<Scalars['String']['input']>;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetGroupPayload = {
  __typename?: 'CreateAssetGroupPayload';
  asset: GQAssetGroup;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['input'];
  attributionFactorOverride?: InputMaybe<Scalars['Float']['input']>;
  currencyCode: GQCurrencyCode;
  fundId: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQCreateAssetHoldingPayload = {
  __typename?: 'CreateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetPersonalMotorVehicleInsuranceInput = {
  action: GQFinanceAuditAction;
  currencyCode: GQCurrencyCode;
  name: Scalars['String']['input'];
};

export type GQCreateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'CreateAssetPersonalMotorVehicleInsurancePayload';
  asset: GQAssetPersonalMotorVehicleInsurance;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetRealEstateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  buildingKind: Scalars['String']['input'];
  buildingSizeUnit: GQBuildingSizeUnit;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2: GQCountryAlpha2;
  currencyCode: GQCurrencyCode;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateAssetRealEstatePayload = {
  __typename?: 'CreateAssetRealEstatePayload';
  asset: GQAssetRealEstate;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetSovereignBondInput = {
  action: GQFinanceAuditAction;
  currencyCode: GQCurrencyCode;
  name: Scalars['String']['input'];
  sovereignEntity: Scalars['String']['input'];
};

export type GQCreateAssetSovereignBondPayload = {
  __typename?: 'CreateAssetSovereignBondPayload';
  asset: GQAssetSovereignBond;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode: GQCurrencyCode;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['String']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['String']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year: Scalars['Int']['input'];
};

export type GQCreateAssetYearNewPayload = {
  __typename?: 'CreateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateBiCustomMetricInput = {
  expression: Scalars['Expression']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateBiSavedViewAndPropagateToCustomReportsInput = {
  oldBiSavedViewStableId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigIdsToUpdate: Array<Scalars['ID']['input']>;
  savedViewDefinition: GQCreateBiSavedViewInput;
};

export type GQCreateBiSavedViewInput = {
  chartKind: GQBiChartKind;
  customMetrics?: InputMaybe<Array<GQBiCustomMetricRefInput>>;
  dimensions: Array<Scalars['String']['input']>;
  editVisibility: GQBiSavedViewVisibility;
  filters: Array<GQBiQueryFilterInput>;
  mode: GQBiQueryMode;
  name: Scalars['String']['input'];
  normalizedMeasures: Array<GQBiDynamicNormalizedMeasureInput>;
  order?: InputMaybe<GQBiQueryOrderInput>;
  queryContext?: InputMaybe<GQBiSavedViewQueryContextInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  timeIntervals: Array<Scalars['YMInterval']['input']>;
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQCreateBuildingInput = {
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingName: Scalars['String']['input'];
  buildingSubkind?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  isWithoutNaturalGas?: InputMaybe<Scalars['Boolean']['input']>;
  isWithoutRefrigerants?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leaseType?: InputMaybe<GQBuildingLeaseType>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
  needsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  periods: Array<GQCreateBuildingPeriodInput>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  supportingDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateBuildingPayload = {
  __typename?: 'CreateBuildingPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQCreateBuildingPeriodInput = {
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  customFieldId?: InputMaybe<Scalars['String']['input']>;
  customFieldValue?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  isBuildingEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  kind: GQBuildingPeriodKind;
  orgUnitStableId?: InputMaybe<Scalars['String']['input']>;
  orgUnitTypeStableId?: InputMaybe<Scalars['String']['input']>;
  partialCleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  powerUsageEffectiveness?: InputMaybe<Scalars['Float']['input']>;
  sizeQuantity?: InputMaybe<Scalars['Float']['input']>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQCreateBuildingUtilityInput = {
  buildingId: Scalars['ID']['input'];
  cleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  fuelCombustionReason?: InputMaybe<Scalars['String']['input']>;
  fuelKind?: InputMaybe<GQStationaryCombustionFuelKind>;
  isWasteHazardous?: InputMaybe<Scalars['Boolean']['input']>;
  isWasteRadioactive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  onsiteCleanFuelKind?: InputMaybe<Scalars['String']['input']>;
  periodEnd: Scalars['Date']['input'];
  periodStart: Scalars['Date']['input'];
  productExternalId?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  refrigerantLeakageSource?: InputMaybe<GQRefrigerantLeakageSource>;
  refrigerantName?: InputMaybe<Scalars['String']['input']>;
  unit: GQBuildingUtilityUnit;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
  utilityType: GQBuildingUtilityType;
  wasteMaterial?: InputMaybe<GQBuildingWasteMaterial>;
  wasteTreatment?: InputMaybe<GQBuildingWasteTreatment>;
  waterUseKind?: InputMaybe<GQBuildingWaterUseKind>;
};

export type GQCreateBuildingUtilityPayload = {
  __typename?: 'CreateBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUntyped: GQBuildingUntyped;
  buildingUtility: GQBuildingUtility;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQCreateCandidateRowInput = {
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQCreateCdpApiRegistrationInput = {
  cdpOrganizationId: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateCdpApiRegistrationPayload = {
  __typename?: 'CreateCdpApiRegistrationPayload';
  cdpOrganizationId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQCreateClimateProgramCalendarEventInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type GQCreateClimateProgramProjectDatasetRequirementInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  climateProgramProjectId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
};

export type GQCreateClimateProgramProjectInput = {
  canonicalClimateProgramProjectId?: InputMaybe<Scalars['ID']['input']>;
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  createdForMeasureWhatMatters?: InputMaybe<Scalars['Boolean']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  descriptionOverride?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  nameOverride?: InputMaybe<Scalars['String']['input']>;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQCreateCompanyChangeRequestCreateInput = {
  assetCorporateId?: InputMaybe<Scalars['String']['input']>;
  bea?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgCompanyName?: InputMaybe<Scalars['String']['input']>;
  sAndPId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<GQOrgCompanySource>;
  sourceKey?: InputMaybe<Scalars['String']['input']>;
  tickerSymbol?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanyChangeRequestCreatePayload = {
  __typename?: 'CreateCompanyChangeRequestCreatePayload';
  companyChangeRequest: GQCompanyChangeRequest;
};

export type GQCreateCompanyRequestCaseInput = {
  companyName: Scalars['String']['input'];
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  sAndPId?: InputMaybe<Scalars['String']['input']>;
  supplierName: Scalars['String']['input'];
  tickerSymbol?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCompanyRequestCasePayload = {
  __typename?: 'CreateCompanyRequestCasePayload';
  id: Scalars['ID']['output'];
};

export type GQCreateCompanySurveyInput = {
  /** @deprecated Everything is backed by engagementTaskConfig now that we've migrated. Delete 10/12 */
  backedByEngagementTaskConfig?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  facilityName?: InputMaybe<Scalars['String']['input']>;
  proposeTargetFilters?: InputMaybe<GQFilterExpressionGroupInput>;
  proposeTargetIntensityType?: InputMaybe<GQDisclosureTargetIntensityType>;
  proposeTargetSimpleTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  reportingYear?: InputMaybe<Scalars['Int']['input']>;
  rootAssignee?: InputMaybe<Scalars['ID']['input']>;
  supplierFacingDescription?: InputMaybe<Scalars['String']['input']>;
  surveyKind?: InputMaybe<GQCompanySurveyKind>;
  templateId: Scalars['ID']['input'];
  type?: InputMaybe<GQEngagementTaskConfigType>;
  useExpenseCategories?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateCorporateSandboxEstimationInput = {
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  countryAlpha2: GQCountryAlpha2;
  currencyCode: GQCurrencyCode;
  estimationMethodology: GQEstimationMethodology;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  naicsCode: Scalars['String']['input'];
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative: Scalars['Float']['input'];
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  shouldIncludeDownstreamScope3?: InputMaybe<Scalars['Boolean']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateCorporateSandboxEstimationPayload = {
  __typename?: 'CreateCorporateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateCsrdDataRequirementsConfigInput = {
  configJson?: InputMaybe<Scalars['JSONString']['input']>;
  reportId?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCsrdDataRequirementsConfigPayload = {
  __typename?: 'CreateCsrdDataRequirementsConfigPayload';
  payload: GQCsrdDataRequirementsConfig;
};

export type GQCreateCtsFormEntryInput = {
  ctsFormId: Scalars['ID']['input'];
  data: Scalars['JSONString']['input'];
};

export type GQCreateCtsFormInput = {
  ctsId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  rank: Scalars['Int']['input'];
};

export type GQCreateCtsPayload = {
  __typename?: 'CreateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQCreateCtsVersionInput = {
  businessActivityTypeId?: InputMaybe<Scalars['String']['input']>;
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  customerTargetSchemaId: Scalars['ID']['input'];
  exampleData?: InputMaybe<Scalars['JSONString']['input']>;
  formattingNotesMd?: InputMaybe<Scalars['String']['input']>;
  oneSchemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  schemaJson?: InputMaybe<Scalars['JSONString']['input']>;
  semverVersion: Scalars['String']['input'];
  sql: Scalars['String']['input'];
  transformTarget: GQCustomerTargetSchemaTransformTarget;
  versionNotesMd: Scalars['String']['input'];
};

export type GQCreateCtsVersionPayload = {
  __typename?: 'CreateCtsVersionPayload';
  customerTargetSchema: Maybe<GQCustomerTargetSchema>;
};

export type GQCreateCtsvOneSchemaFileFeedInput = {
  ctsVersionId: Scalars['ID']['input'];
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionInput = {
  measurementProjectId: Scalars['String']['input'];
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQCreateCustomEmissionsFactorsReferenceDataRevisionPayload = {
  __typename?: 'CreateCustomEmissionsFactorsReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQCreateCustomIntensityConfigInput = {
  description: Scalars['String']['input'];
  humanReadable: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateCustomerInitiatedDataIssueInput = {
  fileMetadataIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  linkedObjectId: Scalars['ID']['input'];
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateCustomerInitiatedDataIssuePayload = {
  __typename?: 'CreateCustomerInitiatedDataIssuePayload';
  issue: GQDataIssue;
  measurementProject: GQMeasurementProject;
  userUploadTask: Maybe<GQUserUploadTask>;
  valueMappingTask: Maybe<GQValueMappingTask>;
};

export type GQCreateDatasourceInput = {
  approverIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customPotentialOverlaps?: InputMaybe<Scalars['String']['input']>;
  customUsedFor?: InputMaybe<Scalars['String']['input']>;
  datasetId: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  orgAccessIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
  uploaderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userIdsWithPermission?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQCreateDefaultOrgStructureVersionPayload = {
  __typename?: 'CreateDefaultOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQCreateDemoOrgInput = {
  companySourceKey: Scalars['String']['input'];
  grantAccessUserIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  stagingOrg: Scalars['Boolean']['input'];
  testOrg: Scalars['Boolean']['input'];
  userName: Scalars['String']['input'];
};

export type GQCreateDemoOrgPayload = {
  __typename?: 'CreateDemoOrgPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQCreateDiscussionInput = {
  anchor: GQDiscussionAnchor;
  fileMetadataIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type GQCreateDiscussionPayload = {
  __typename?: 'CreateDiscussionPayload';
  discussion: GQDiscussion;
};

export type GQCreateEngagementCohortInput = {
  name: Scalars['String']['input'];
};

export type GQCreateEngagementCohortsForCompanyInput = {
  companyId: Scalars['String']['input'];
  names: Array<Scalars['String']['input']>;
};

export type GQCreateEngagementTaskAttachmentsInput = {
  attachmentMetadataInput: Array<GQEngagementTaskAttachmentMetadataInput>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQCreateEngagementTaskAttachmentsPayload = {
  __typename?: 'CreateEngagementTaskAttachmentsPayload';
  attachmentMetadata: Array<GQEngagementTaskAttachment>;
};

export type GQCreateFinanceExportInput = {
  year: Scalars['Int']['input'];
};

export type GQCreateFinanceExportPayload = {
  __typename?: 'CreateFinanceExportPayload';
  signedDownloadUrl: Scalars['String']['output'];
};

export type GQCreateFinanceHoldingsExportInput = {
  year: Scalars['Int']['input'];
};

export type GQCreateFinanceHoldingsExportPayload = {
  __typename?: 'CreateFinanceHoldingsExportPayload';
  signedDownloadUrl: Scalars['String']['output'];
};

export type GQCreateFinanceImportInput = {
  dataFormat: GQOneSchemaDataFormat;
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaErrorMessages: Scalars['UntypedData']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  originalFileName: Scalars['String']['input'];
};

export type GQCreateFinanceImportPayload = {
  __typename?: 'CreateFinanceImportPayload';
  backgroundJobId: Scalars['ID']['output'];
  financeImport: GQFinanceImport;
};

export type GQCreateFinanceReportingTagsInput = {
  reportType: GQExternalReportType;
};

export type GQCreateFinanceReportingTagsPayload = {
  __typename?: 'CreateFinanceReportingTagsPayload';
  financeTags: Array<GQFinanceTag>;
};

export type GQCreateFinanceSavedViewInput = {
  charts: Array<Scalars['JSONString']['input']>;
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  hiddenColumns?: InputMaybe<Array<Scalars['String']['input']>>;
  layout: Scalars['String']['input'];
  metrics: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type GQCreateFinanceSavedViewPayload = {
  __typename?: 'CreateFinanceSavedViewPayload';
  financeSavedView: GQFinanceSavedView;
};

export type GQCreateFinanceTagInput = {
  columnConfiguration?: InputMaybe<Scalars['UntypedData']['input']>;
  columnFormat: GQGridColumnFormat;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tagType: GQFinanceTagType;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFinanceTagPayload = {
  __typename?: 'CreateFinanceTagPayload';
  financeTag: GQFinanceTag;
};

export type GQCreateFinanceUploadTemplateInput = {
  dataFormat: GQOneSchemaDataFormat;
};

export type GQCreateFinanceUploadTemplatePayload = {
  __typename?: 'CreateFinanceUploadTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateFootprintInput = {
  name: Scalars['String']['input'];
};

export type GQCreateFootprintPayload = {
  __typename?: 'CreateFootprintPayload';
  footprint: GQFootprint;
};

export type GQCreateFootprintSliceInitiativeInput = {
  costTimeseries: GQSimpleTimeseriesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  filters: GQFilterExpressionGroupWithNewFiltersInput;
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  reductionTimeseries: GQSimpleTimeseriesInput;
  scheduleType: GQScheduleType;
};

export type GQCreateFootprintSliceInitiativePayload = {
  __typename?: 'CreateFootprintSliceInitiativePayload';
  plan: GQPlan;
};

export type GQCreateForecastInput = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQCreateForecastPayload = {
  __typename?: 'CreateForecastPayload';
  forecast: GQForecast;
};

export type GQCreateForecastScenarioInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  growthForecasts: Array<GQCreateGrowthForecastInput>;
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedByName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateFundInput = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQCreateFundPayload = {
  __typename?: 'CreateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQCreateGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast: GQCreateSimpleTimeseriesInput;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQCreateIngestionCustomFieldInput = {
  /** @deprecated unused, remove may13 */
  canVaryOverTime?: InputMaybe<Scalars['Boolean']['input']>;
  datasetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  exampleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  isMetric?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  possibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  type: GQIngestionCustomFieldType;
  utilityTypes?: InputMaybe<Array<GQBuildingUtilityType>>;
};

export type GQCreateIngestionCustomFieldPayload = {
  __typename?: 'CreateIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
  organization: GQOrganization;
};

export type GQCreateLifecycleAssessmentInput = {
  boundary: GQLifecycleAssessmentBoundaryEnum;
  notes?: InputMaybe<Scalars['String']['input']>;
  productDescription?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  productUnit: Scalars['String']['input'];
  referenceEndDate: Scalars['DateTime']['input'];
  referenceStartDate: Scalars['DateTime']['input'];
  supplierProductId?: InputMaybe<Scalars['String']['input']>;
  validityEndDate: Scalars['DateTime']['input'];
  validityStartDate: Scalars['DateTime']['input'];
};

export type GQCreateMarketplaceEacPurchaseCountryInput = {
  countryWithEacs: Scalars['String']['input'];
  countryWithEmissions: Scalars['String']['input'];
  coverageInterval: Scalars['YMInterval']['input'];
  eacFeeUsdCents: Scalars['Int']['input'];
  eacPriceUsdCents: Scalars['Int']['input'];
  marketTco2e: Scalars['Float']['input'];
  mwh: Scalars['Float']['input'];
  numEacsRequested: Scalars['Int']['input'];
};

export type GQCreateMarketplaceEacPurchaseInput = {
  countryEacRequests: Array<GQCreateMarketplaceEacPurchaseCountryInput>;
  ghgCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Scalars['YMInterval']['input'];
  marketTco2e: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  requestNotes?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateMarketplaceEacPurchasePayload = {
  __typename?: 'CreateMarketplaceEacPurchasePayload';
  marketplacePurchase: Maybe<GQMarketplacePurchase>;
  marketplacePurchaseId: Maybe<Scalars['ID']['output']>;
};

export type GQCreateMaterialityAssessmentDataPointInput = {
  frameworkDataPointId: Scalars['ID']['input'];
  status: GQMaterialityStatus;
};

export type GQCreateMaterialityAssessmentInput = {
  dataPoints: Array<GQCreateMaterialityAssessmentDataPointInput>;
  name: Scalars['String']['input'];
};

export type GQCreateMaterialityAssessmentPayload = {
  __typename?: 'CreateMaterialityAssessmentPayload';
  materialityAssessment: GQMaterialityAssessment;
};

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectInput =
  {
    canonicalClimateProgramProjectId: Scalars['ID']['input'];
    coverageInterval: Scalars['YMInterval']['input'];
    deadline: Scalars['Date']['input'];
    kickoff: Scalars['Date']['input'];
    name: Scalars['String']['input'];
  };

export type GQCreateMeasurementProjectFromCanonicalClimateProgramProjectPayload =
  {
    __typename?: 'CreateMeasurementProjectFromCanonicalClimateProgramProjectPayload';
    climateProgramProjects: Array<GQClimateProgramProject>;
    measurementProject: GQMeasurementProject;
  };

export type GQCreateMeasurementProjectFromWizardInput = {
  climateProgramProjectIds: Array<Scalars['ID']['input']>;
  coverageInterval: Scalars['YMInterval']['input'];
  deadline: Scalars['Date']['input'];
  isApprovalFlowEnabled: Scalars['Boolean']['input'];
  kickoff: Scalars['Date']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateMeasurementProjectFromWizardPayload = {
  __typename?: 'CreateMeasurementProjectFromWizardPayload';
  climateProgramProjects: Array<GQClimateProgramProject>;
  measurementProject: GQMeasurementProject;
};

export type GQCreateMeasurementProjectPayload = {
  __typename?: 'CreateMeasurementProjectPayload';
  measurementProject: Maybe<GQMeasurementProject>;
  org: Maybe<GQOrganization>;
};

export type GQCreateMergeIntegrationInput = {
  publicToken: Scalars['String']['input'];
};

export type GQCreateNetSuiteIntegrationInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  realm: Scalars['String']['input'];
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type GQCreateOneSchemaSessionForApiUploadInput = {
  apiUploadId: Scalars['ID']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreateOneSchemaSessionForApiUploadPayload = {
  __typename?: 'CreateOneSchemaSessionForApiUploadPayload';
  fileMetadataId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  measurementProjectId: Scalars['String']['output'];
  oneSchemaEmbedId: Scalars['String']['output'];
  oneSchemaEmbedStatus: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
  oneSchemaSessionToken: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  userUploadTaskId: Scalars['String']['output'];
};

export type GQCreateOneSchemaSessionForTargetSchemaInput = {
  ctsVersionId: Scalars['ID']['input'];
  fullStorySessionUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateOneSchemaSessionForTargetSchemaPayload = {
  __typename?: 'CreateOneSchemaSessionForTargetSchemaPayload';
  oneSchemaEmbedId: Scalars['String']['output'];
  oneSchemaEmbedStatus: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
  oneSchemaSessionToken: Scalars['String']['output'];
  uploadId: Scalars['ID']['output'];
};

export type GQCreateOneSchemaSessionForUserUploadInput = {
  userUploadId: Scalars['ID']['input'];
};

export type GQCreateOneSchemaSessionForUserUploadPayload = {
  __typename?: 'CreateOneSchemaSessionForUserUploadPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  ctsVersionId: Scalars['ID']['output'];
  fileMetadataId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  oneSchemaEmbedId: Scalars['String']['output'];
  oneSchemaEmbedStatus: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
  oneSchemaSessionToken: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  templateKey: Scalars['String']['output'];
  userUploadTask: GQUserUploadTask;
  userUploadTaskId: Scalars['ID']['output'];
};

export type GQCreateOrgStructureImportTemplateInput = {
  orgStructureVersionId: Scalars['ID']['input'];
};

export type GQCreateOrgStructureImportTemplatePayload = {
  __typename?: 'CreateOrgStructureImportTemplatePayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQCreateOrgStructureVersionUploadInput = {
  oneSchemaEmbedId: Scalars['Int']['input'];
  orgStructureVersionId: Scalars['ID']['input'];
  originalFileName: Scalars['String']['input'];
};

export type GQCreateOrgStructureVersionUploadPayload = {
  __typename?: 'CreateOrgStructureVersionUploadPayload';
  backgroundJobId: Scalars['ID']['output'];
  orgStructureVersionUpload: GQOrgStructureVersionUpload;
};

export type GQCreateOrgUnitCreateRelationshipInput = {
  direction: GQOrgUnitRelationshipDirection;
  otherOrgUnitId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgUnitTypeId: Scalars['String']['input'];
  relationships?: InputMaybe<Array<GQCreateOrgUnitCreateRelationshipInput>>;
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitPayload = {
  __typename?: 'CreateOrgUnitPayload';
  orgUnit: GQOrgUnit;
};

export type GQCreateOrgUnitRelationshipInput = {
  orgUnitIdFrom: Scalars['ID']['input'];
  orgUnitIdTo: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitRelationshipPayload = {
  __typename?: 'CreateOrgUnitRelationshipPayload';
  orgUnitRelationship: GQOrgUnitRelationship;
};

export type GQCreateOrgUnitTypeInput = {
  externalId: Scalars['String']['input'];
  isLegalEntity: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitTypePayload = {
  __typename?: 'CreateOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQCreateOrgUnitTypeRelationshipInput = {
  cardinality: GQOrgUnitRelationshipCardinality;
  defaultOrgUnitId?: InputMaybe<Scalars['ID']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  orgUnitTypeIdFrom: Scalars['ID']['input'];
  orgUnitTypeIdTo: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQCreateOrgUnitTypeRelationshipPayload = {
  __typename?: 'CreateOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
  orgUnits: Array<GQOrgUnit>;
};

export type GQCreatePeerCompanyGroupInput = {
  footprintKinds?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreatePermissionItemInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  orgAccessId?: InputMaybe<Scalars['ID']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  permission: GQPermissionType;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQCreatePermissionItemNestedInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQCreatePermissionItemPayload = {
  __typename?: 'CreatePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQCreatePlanTargetInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  baseYear: Scalars['YearMonth']['input'];
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQPlanTargetIntensityType;
  parentTargetId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  reductionRate?: InputMaybe<GQTargetReductionRate>;
  reductionTargets: Array<GQReductionTargetByYearInput>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreatePlanTargetPayload = {
  __typename?: 'CreatePlanTargetPayload';
  plan: GQPlan;
  planTarget: GQPlanTarget;
};

export type GQCreatePlanTargetReduxInput = {
  baseYear: Scalars['YearMonth']['input'];
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  emissionsTargetCustom: GQSimpleTimeseriesInput;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQPlanTargetIntensityType;
  planId: Scalars['ID']['input'];
};

export type GQCreatePlanWithDefaultsInput = {
  autogenerateSbtTargets?: InputMaybe<Scalars['Boolean']['input']>;
  baselineYearStart?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastIntervalEndYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastReferencePeriodEnd?: InputMaybe<Scalars['YearMonth']['input']>;
  forecastReferencePeriodStart?: InputMaybe<Scalars['YearMonth']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  overrideFootprintKind?: InputMaybe<Scalars['String']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQCreatePlanWithDefaultsPayload = {
  __typename?: 'CreatePlanWithDefaultsPayload';
  plan: GQPlan;
};

export type GQCreatePrecomputedActivityBasedInitiativeInput = {
  costTimeseries: GQSimpleTimeseriesInput;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  filters: GQFilterExpressionGroupWithNewFiltersInput;
  implementationTimeseries: GQSimpleTimeseriesInput;
  lever: GQPrecomputedLever;
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
  scheduleType: GQScheduleType;
};

export type GQCreatePrecomputedActivityBasedInitiativePayload = {
  __typename?: 'CreatePrecomputedActivityBasedInitiativePayload';
  plan: GQPlan;
};

export type GQCreatePrivateDisclosureInput = {
  companyId: Scalars['ID']['input'];
  disclosureTargets?: InputMaybe<Array<GQDisclosureTargetInput>>;
};

export type GQCreatePrivateDisclosurePayload = {
  __typename?: 'CreatePrivateDisclosurePayload';
  disclosure: GQDisclosure;
};

export type GQCreateRealEstateSandboxEstimationInput = {
  buildingKind: Scalars['String']['input'];
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  buildingSizeUnit: GQBuildingSizeUnit;
  countryAlpha2: GQCountryAlpha2;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
};

export type GQCreateRealEstateSandboxEstimationPayload = {
  __typename?: 'CreateRealEstateSandboxEstimationPayload';
  backgroundJobId: Scalars['String']['output'];
};

export type GQCreateRenewableElectricityTargetInput = {
  baseYear: Scalars['YearMonth']['input'];
  planId: Scalars['ID']['input'];
  targetYearExclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQCreateReportAttachmentFileInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateReportAttachmentsInput = {
  attachmentItemIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQCreateReportAttachmentsPayload = {
  __typename?: 'CreateReportAttachmentsPayload';
  attachmentItems: Array<GQReportAttachmentItem>;
};

export type GQCreateReportBCorpInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportCdpInput = {
  baseInterval: Scalars['YMInterval']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportConfigFromSrcInput = {
  configIdToExtend?: InputMaybe<Scalars['ID']['input']>;
  importBehaviorFlags?: InputMaybe<GQReportConfigImportBehaviorFlagsInput>;
  src: Scalars['JSONString']['input'];
};

export type GQCreateReportConfigInput = {
  brandColor: Scalars['String']['input'];
  description: Scalars['String']['input'];
  isCorporateReport: Scalars['Boolean']['input'];
  isCreationBlocked: Scalars['Boolean']['input'];
  isFinanceReport: Scalars['Boolean']['input'];
  isHiddenFromOverview: Scalars['Boolean']['input'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['input'];
  longName: Scalars['String']['input'];
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['input'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['input'];
  status?: InputMaybe<GQReportConfigStatus>;
  useApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  useMateriality?: InputMaybe<Scalars['Boolean']['input']>;
  useOverrides?: InputMaybe<Scalars['Boolean']['input']>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportConfigPayload = {
  __typename?: 'CreateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQCreateReportEuropeTcfdInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQCreateReportFileAttachmentItemPayload = {
  __typename?: 'CreateReportFileAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportFileAttachmentsInput = {
  files: Array<GQCreateReportAttachmentFileInput>;
};

export type GQCreateReportFileAttachmentsPayload = {
  __typename?: 'CreateReportFileAttachmentsPayload';
  items: Array<GQCreateReportFileAttachmentItemPayload>;
};

export type GQCreateReportPayload = {
  __typename?: 'CreateReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export type GQCreateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance: Scalars['JSONString']['input'];
  insertionIndex?: InputMaybe<Scalars['Int']['input']>;
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  status: Scalars['JSONString']['input'];
};

export type GQCreateReportQuestionContainerPayload = {
  __typename?: 'CreateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQCreateReportQuestionInput = {
  allowNotes: Scalars['Boolean']['input'];
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId: Scalars['String']['input'];
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance: Scalars['JSONString']['input'];
  identifier: Scalars['String']['input'];
  isComputed: Scalars['Boolean']['input'];
  isDynamic: Scalars['Boolean']['input'];
  isInput?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  reportConfigId: Scalars['ID']['input'];
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status: Scalars['JSONString']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateReportQuestionPayload = {
  __typename?: 'CreateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQCreateReportSasbInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  industry: GQSasbIndustry;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportSecrInput = {
  balanceSheet?: InputMaybe<Scalars['Boolean']['input']>;
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  employees?: InputMaybe<Scalars['Boolean']['input']>;
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  previouslyFiled?: InputMaybe<Scalars['Boolean']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
  turnover?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateReportTcfdInput = {
  baseInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportUrlAttachmentItemInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type GQCreateReportUrlAttachmentItemPayload = {
  __typename?: 'CreateReportURLAttachmentItemPayload';
  attachment: GQReportAttachmentItem;
  url: Scalars['String']['output'];
};

export type GQCreateReportUkTenderInput = {
  baseInterval: Scalars['YMInterval']['input'];
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId: Scalars['ID']['input'];
  reductionPctIn5Years: Scalars['Float']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateReportWithConfigInput = {
  configId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQCreateReportWithCustomConfigInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  reportMode: GQReportMode;
};

export type GQCreateReportWithCustomConfigPayload = {
  __typename?: 'CreateReportWithCustomConfigPayload';
  report: GQReport;
};

export type GQCreateReportWsInput = {
  drilldownSavedViewId?: InputMaybe<Scalars['ID']['input']>;
  planId?: InputMaybe<Scalars['ID']['input']>;
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQCreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  permissions?: InputMaybe<Array<GQCreatePermissionItemNestedInput>>;
};

export type GQCreateRolePayload = {
  __typename?: 'CreateRolePayload';
  role: GQRole;
};

export type GQCreateSbtiTargetExclusionInput = {
  filters: GQFilterExpressionGroupWithNewFiltersInput;
};

export type GQCreateSbtiTargetExclusionPayload = {
  __typename?: 'CreateSbtiTargetExclusionPayload';
  org: GQOrganization;
};

export type GQCreateSimpleTimeseriesInput = {
  base: Scalars['Date']['input'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['input']>;
};

export type GQCreateSingleUploaderFacilitiesTaskInput = {
  measurementProjectId: Scalars['ID']['input'];
  type: GQFacilitiesType;
};

export type GQCreateSingleUploaderFacilitiesTaskPayload = {
  __typename?: 'CreateSingleUploaderFacilitiesTaskPayload';
  measurementProject: GQMeasurementProject;
  task: GQUserUploadTask;
};

export type GQCreateSnapshotInput = {
  config?: InputMaybe<GQFinanceSnapshotConfigV1Input>;
  note?: InputMaybe<Scalars['String']['input']>;
  snapshotName?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateSnapshotPayload = {
  __typename?: 'CreateSnapshotPayload';
  snapshot: GQFinanceSnapshot;
  workflowId: Scalars['String']['output'];
};

export type GQCreateSupplierAttachmentItemInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateSupplierAttachmentItemPayload = {
  __typename?: 'CreateSupplierAttachmentItemPayload';
  attachment: GQSupplierAttachment;
  url: Scalars['String']['output'];
};

export type GQCreateSupplierAttachmentsInput = {
  companyId: Scalars['ID']['input'];
  files: Array<GQCreateSupplierAttachmentItemInput>;
};

export type GQCreateSupplierAttachmentsPayload = {
  __typename?: 'CreateSupplierAttachmentsPayload';
  fileUrls: Array<GQCreateSupplierAttachmentItemPayload>;
};

export type GQCreateSupplierChartInput = {
  supplierChartInput: GQSupplierChartInput;
};

export type GQCreateSupplierChartPayload = {
  __typename?: 'CreateSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQCreateSupplierContactInput = {
  companyId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type GQCreateSupplierContactPayload = {
  __typename?: 'CreateSupplierContactPayload';
  contact: GQSupplierContact;
};

export type GQCreateSupplierSavedViewInput = {
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  name: Scalars['String']['input'];
};

export type GQCreateSupplierSavedViewPayload = {
  __typename?: 'CreateSupplierSavedViewPayload';
  view: GQSupplierView;
};

export type GQCreateSupplierScoreCriteriaInput = {
  filters: Array<GQBiQueryFilterInput>;
  label: Scalars['String']['input'];
  supplierScoreId: Scalars['ID']['input'];
};

export type GQCreateSupplierScoreCriteriaPayload = {
  __typename?: 'CreateSupplierScoreCriteriaPayload';
  supplierScoreCriteria: GQSupplierScoreCriteria;
};

export type GQCreateSupplierTableColumnInput = {
  baseEngagementTaskConfigId?: InputMaybe<Scalars['ID']['input']>;
  columnFormat?: InputMaybe<GQSupplierTableColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  headerName?: InputMaybe<Scalars['String']['input']>;
  questionKey?: InputMaybe<Scalars['String']['input']>;
  selectOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQCreateSupplierTableColumnPayload = {
  __typename?: 'CreateSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQCreateSupportCaseAttachmentsInput = {
  files: Array<GQSupportCaseAttachmentInput>;
};

export type GQCreateSupportCaseAttachmentsPayload = {
  __typename?: 'CreateSupportCaseAttachmentsPayload';
  files: Array<GQSupportCaseAttachments>;
};

export type GQCreateSupportCaseCommentInput = {
  attachments?: InputMaybe<Array<GQSupportCaseAttachmentForComment>>;
  comment: Scalars['String']['input'];
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
  supportCaseId: Scalars['ID']['input'];
};

export type GQCreateSupportCaseCommentPayload = {
  __typename?: 'CreateSupportCaseCommentPayload';
  supportCase: GQSupportCase;
};

export type GQCreateSupportCaseInput = {
  attachments?: InputMaybe<Array<GQSupportCaseAttachmentForComment>>;
  description?: InputMaybe<Scalars['String']['input']>;
  issueType: GQSupportCaseIssueType;
  mentionedUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  objectId?: InputMaybe<Scalars['String']['input']>;
  priority: GQSupportCasePriority;
  productUrl?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type GQCreateSupportCasePayload = {
  __typename?: 'CreateSupportCasePayload';
  supportCase: GQSupportCase;
};

export type GQCreateSupportingDocumentsInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  buildingUtilityId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<GQCreateUserUploadItemInput>;
  /** @deprecated Use temporaryDirectoryUuid instead */
  newBuildingToken?: InputMaybe<Scalars['String']['input']>;
  temporaryDirectoryUuid?: InputMaybe<Scalars['String']['input']>;
  utilityType?: InputMaybe<GQBuildingUtilityType>;
};

export type GQCreateSupportingDocumentsPayload = {
  __typename?: 'CreateSupportingDocumentsPayload';
  buildingUntyped: Maybe<GQBuildingUntyped>;
  buildingUtility: Maybe<GQBuildingUtility>;
  files: Array<GQCreateUserUploadItemPayload>;
  supportingDocuments: Array<GQSupportingDocument>;
};

export type GQCreateTripActionsIntegrationInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
};

export type GQCreateUpdateAndDeleteForecastScenariosInput = {
  created: Array<GQCreateForecastScenarioInput>;
  deletedScenarioIds: Array<Scalars['String']['input']>;
  forecastId: Scalars['String']['input'];
  updated: Array<GQUpdateForecastScenarioInput>;
};

export type GQCreateUpdateAndDeleteForecastScenariosPayload = {
  __typename?: 'CreateUpdateAndDeleteForecastScenariosPayload';
  forecast: GQForecast;
  forecastScenarios: Array<GQForecastScenario>;
  plans: Array<GQPlan>;
};

export type GQCreateUploadTaskInput = {
  commaSeparatedApproverEmails?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated New user creation is no longer supported here */
  commaSeparatedApproverNames?: InputMaybe<Scalars['String']['input']>;
  commaSeparatedAssigneeEmails?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated New user creation is no longer supported here */
  commaSeparatedAssigneeNames?: InputMaybe<Scalars['String']['input']>;
  commaSeparatedFacilityIds?: InputMaybe<Scalars['String']['input']>;
  datasetName: Scalars['String']['input'];
  datasourceName: Scalars['String']['input'];
};

export type GQCreateUserInput = {
  activateUser?: InputMaybe<Scalars['Boolean']['input']>;
  allowOutsideDomain?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<
    Array<InputMaybe<GQCreatePermissionItemNestedInput>>
  >;
  permissionsV2?: InputMaybe<Scalars['UntypedData']['input']>;
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  source?: InputMaybe<GQCreateUserSource>;
};

export type GQCreateUserPayload = {
  __typename?: 'CreateUserPayload';
  org: GQOrganization;
  user: GQUser;
};

export type GQCreateUserPermissionRequestInput = {
  permission: GQPermissionType;
};

export type GQCreateUserPermissionRequestPayload = {
  __typename?: 'CreateUserPermissionRequestPayload';
  userPermissionRequest: Maybe<GQUserPermissionRequest>;
};

export const GQCreateUserSource = {
  Admin: 'Admin',
  Dashboard: 'Dashboard',
  FinancePortal: 'FinancePortal',
  SupplierPortal: 'SupplierPortal',
} as const;

export type GQCreateUserSource =
  (typeof GQCreateUserSource)[keyof typeof GQCreateUserSource];
export type GQCreateUserUploadItemInput = {
  filename: Scalars['String']['input'];
  size: Scalars['Int53']['input'];
};

export type GQCreateUserUploadItemPayload = {
  __typename?: 'CreateUserUploadItemPayload';
  fileId: Scalars['ID']['output'];
  filename: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GQCreateUserUploadsInput = {
  ctsVersionId?: InputMaybe<Scalars['ID']['input']>;
  files: Array<GQCreateUserUploadItemInput>;
  fullStorySessionUrl?: InputMaybe<Scalars['String']['input']>;
  processingMode?: InputMaybe<GQUserUploadProcessingMode>;
  updateFileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQCreateUserUploadsPayload = {
  __typename?: 'CreateUserUploadsPayload';
  failedToUpload: Array<GQUserUploadFailure>;
  files: Array<GQCreateUserUploadItemPayload>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userUploads: Array<GQUserUpload>;
};

export type GQCreateUtilityDocsInput = {
  ctsId: Scalars['ID']['input'];
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  files: Array<GQCreateUserUploadItemInput>;
  utilityType?: InputMaybe<GQBuildingUtilityType>;
};

export type GQCreateUtilityDocsPayload = {
  __typename?: 'CreateUtilityDocsPayload';
  candidateIds: Array<Scalars['String']['output']>;
  failedToUpload: Maybe<Array<GQUserUploadFailure>>;
  files: Array<GQCreateUserUploadItemPayload>;
  supportingDocuments: Array<GQSupportingDocument>;
};

export type GQCreateValueMappingCommentInput = {
  createdBy: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQCreateWatershedFootprintReviewRequestInput = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQCreateWatershedFootprintReviewRequestPayload = {
  __typename?: 'CreateWatershedFootprintReviewRequestPayload';
  discussionThreads: GQDiscussionsPayload;
  footprintSnapshot: GQFootprintSnapshot;
};

export type GQCsrdDataRequirementsConfig = {
  __typename?: 'CsrdDataRequirementsConfig';
  configJson: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editedByUser: Maybe<GQUser>;
  editedByUserId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  reportId: Maybe<Scalars['ID']['output']>;
};

export type GQCtsForm = {
  __typename?: 'CtsForm';
  closedAt: Maybe<Scalars['Date']['output']>;
  createdBy: GQUser;
  ctsVersionId: Scalars['ID']['output'];
  customerTargetSchemaId: Scalars['ID']['output'];
  dataset: GQDataset;
  datasetId: Scalars['ID']['output'];
  description: Maybe<Scalars['String']['output']>;
  entries: Array<GQCtsFormEntry>;
  id: Scalars['ID']['output'];
  title: Maybe<Scalars['String']['output']>;
};

export type GQCtsFormEntry = {
  __typename?: 'CtsFormEntry';
  createdBy: GQUser;
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
};

export const GQCtsTestRunStatus = {
  Approved: 'Approved',
  Error: 'Error',
  Fail: 'Fail',
  Pass: 'Pass',
  Stale: 'Stale',
} as const;

export type GQCtsTestRunStatus =
  (typeof GQCtsTestRunStatus)[keyof typeof GQCtsTestRunStatus];
export const GQCtsTransformTestKind = {
  File: 'File',
  Json: 'Json',
} as const;

export type GQCtsTransformTestKind =
  (typeof GQCtsTransformTestKind)[keyof typeof GQCtsTransformTestKind];
export type GQCtsUserUpload = {
  __typename?: 'CtsUserUpload';
  batvName: Scalars['String']['output'];
  businessActivityType: Scalars['String']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  userUploadFileName: Scalars['String']['output'];
  userUploadId: Scalars['ID']['output'];
};

export type GQCtsvOneSchemaFileFeed = {
  __typename?: 'CtsvOneSchemaFileFeed';
  ctsVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lastRun: Maybe<GQCtsvOneSchemaFileFeedImport>;
  numErrorImports: Scalars['Int']['output'];
  numSuccessfulImports: Scalars['Int']['output'];
  oneSchemaFileFeedId: Scalars['String']['output'];
};

export type GQCtsvOneSchemaFileFeedImport = {
  __typename?: 'CtsvOneSchemaFileFeedImport';
  createdAt: Scalars['DateTime']['output'];
  ctsvOneSchemaFileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  destinationDataPreview: Maybe<Scalars['UntypedData']['output']>;
  id: Scalars['ID']['output'];
  oneSchemaFileFeedImportId: Scalars['String']['output'];
  sourceUserUploadCandidateId: Scalars['ID']['output'];
  status: GQCtsvOneSchemaFileFeedImportStatus;
};

export const GQCtsvOneSchemaFileFeedImportStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
} as const;

export type GQCtsvOneSchemaFileFeedImportStatus =
  (typeof GQCtsvOneSchemaFileFeedImportStatus)[keyof typeof GQCtsvOneSchemaFileFeedImportStatus];
export const GQCurrencyCode = {
  Aed: 'AED',
  Afn: 'AFN',
  All: 'ALL',
  Amd: 'AMD',
  Ang: 'ANG',
  Aoa: 'AOA',
  Ars: 'ARS',
  Aud: 'AUD',
  Awg: 'AWG',
  Azn: 'AZN',
  Bam: 'BAM',
  Bbd: 'BBD',
  Bdt: 'BDT',
  Bgn: 'BGN',
  Bhd: 'BHD',
  Bif: 'BIF',
  Bmd: 'BMD',
  Bnd: 'BND',
  Bob: 'BOB',
  Brl: 'BRL',
  Bsd: 'BSD',
  Btn: 'BTN',
  Bwp: 'BWP',
  Byn: 'BYN',
  Bzd: 'BZD',
  Cad: 'CAD',
  Cdf: 'CDF',
  Chf: 'CHF',
  Clp: 'CLP',
  Cny: 'CNY',
  Cop: 'COP',
  Crc: 'CRC',
  Cup: 'CUP',
  Cve: 'CVE',
  Czk: 'CZK',
  Djf: 'DJF',
  Dkk: 'DKK',
  Dop: 'DOP',
  Dzd: 'DZD',
  Egp: 'EGP',
  Ern: 'ERN',
  Etb: 'ETB',
  Eur: 'EUR',
  Fjd: 'FJD',
  Fkp: 'FKP',
  Gbp: 'GBP',
  Gel: 'GEL',
  Ggp: 'GGP',
  Ghs: 'GHS',
  Gip: 'GIP',
  Gmd: 'GMD',
  Gnf: 'GNF',
  Gtq: 'GTQ',
  Gyd: 'GYD',
  Hkd: 'HKD',
  Hnl: 'HNL',
  Hrk: 'HRK',
  Htg: 'HTG',
  Huf: 'HUF',
  Idr: 'IDR',
  Ils: 'ILS',
  Imp: 'IMP',
  Inr: 'INR',
  Iqd: 'IQD',
  Irr: 'IRR',
  Isk: 'ISK',
  Jep: 'JEP',
  Jmd: 'JMD',
  Jod: 'JOD',
  Jpy: 'JPY',
  Kes: 'KES',
  Kgs: 'KGS',
  Khr: 'KHR',
  Kmf: 'KMF',
  Kpw: 'KPW',
  Krw: 'KRW',
  Kwd: 'KWD',
  Kyd: 'KYD',
  Kzt: 'KZT',
  Lak: 'LAK',
  Lbp: 'LBP',
  Lkr: 'LKR',
  Lrd: 'LRD',
  Lsl: 'LSL',
  Lyd: 'LYD',
  Mad: 'MAD',
  Mdl: 'MDL',
  Mga: 'MGA',
  Mkd: 'MKD',
  Mmk: 'MMK',
  Mnt: 'MNT',
  Mop: 'MOP',
  Mru: 'MRU',
  Mur: 'MUR',
  Mvr: 'MVR',
  Mwk: 'MWK',
  Mxn: 'MXN',
  Myr: 'MYR',
  Mzn: 'MZN',
  Nad: 'NAD',
  Ngn: 'NGN',
  Nio: 'NIO',
  Nok: 'NOK',
  Npr: 'NPR',
  Nzd: 'NZD',
  Omr: 'OMR',
  Pab: 'PAB',
  Pen: 'PEN',
  Pgk: 'PGK',
  Php: 'PHP',
  Pkr: 'PKR',
  Pln: 'PLN',
  Pyg: 'PYG',
  Qar: 'QAR',
  Ron: 'RON',
  Rsd: 'RSD',
  Rub: 'RUB',
  Rur: 'RUR',
  Rwf: 'RWF',
  Sar: 'SAR',
  Sbd: 'SBD',
  Scr: 'SCR',
  Sdg: 'SDG',
  Sek: 'SEK',
  Sgd: 'SGD',
  Shp: 'SHP',
  Sll: 'SLL',
  Sos: 'SOS',
  Srd: 'SRD',
  Ssp: 'SSP',
  Std: 'STD',
  Svc: 'SVC',
  Syp: 'SYP',
  Szl: 'SZL',
  Thb: 'THB',
  Tjs: 'TJS',
  Tmt: 'TMT',
  Tnd: 'TND',
  Top: 'TOP',
  Try: 'TRY',
  Ttd: 'TTD',
  Tvd: 'TVD',
  Twd: 'TWD',
  Tzs: 'TZS',
  Uah: 'UAH',
  Ugx: 'UGX',
  Usd: 'USD',
  Uyu: 'UYU',
  Uzs: 'UZS',
  Vef: 'VEF',
  Ves: 'VES',
  Vnd: 'VND',
  Vuv: 'VUV',
  Wst: 'WST',
  Xaf: 'XAF',
  Xcd: 'XCD',
  Xof: 'XOF',
  Xpf: 'XPF',
  Yer: 'YER',
  Zar: 'ZAR',
  Zmw: 'ZMW',
} as const;

export type GQCurrencyCode =
  (typeof GQCurrencyCode)[keyof typeof GQCurrencyCode];
export type GQCurrencyExchangeRate = {
  __typename?: 'CurrencyExchangeRate';
  baseCurrency: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type GQCurrencyExchangeRateInput = {
  baseCurrency: Scalars['String']['input'];
  interval: Scalars['YMInterval']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type GQCurrencyOverride = {
  __typename?: 'CurrencyOverride';
  selectedCurrency: Scalars['String']['output'];
};

export type GQCurrencyOverrideInput = {
  selectedCurrency?: InputMaybe<Scalars['String']['input']>;
};

export type GQCustomEmissionsFactorsForDashboard = {
  __typename?: 'CustomEmissionsFactorsForDashboard';
  citationNotes: Scalars['String']['output'];
  citationTitle: Scalars['String']['output'];
  citationUrl: Scalars['String']['output'];
  description: Scalars['String']['output'];
  emissionsSpecifier: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  startDate: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type GQCustomEmissionsFactorsSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'CustomEmissionsFactorsSetup';
    id: Scalars['ID']['output'];
  };

export type GQCustomIntensityConfig = {
  __typename?: 'CustomIntensityConfig';
  description: Scalars['String']['output'];
  humanReadable: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isSbtValidated: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  quantityType: GQCustomIntensityConfigQuantityType;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQCustomIntensityConfigInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  humanReadable?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  quantityType?: InputMaybe<GQCustomIntensityConfigQuantityType>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export const GQCustomIntensityConfigQuantityType = {
  Currency: 'Currency',
  Number: 'Number',
} as const;

export type GQCustomIntensityConfigQuantityType =
  (typeof GQCustomIntensityConfigQuantityType)[keyof typeof GQCustomIntensityConfigQuantityType];
export type GQCustomIntensityDataPoint = {
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQCustomIntensityMetadata = {
  __typename?: 'CustomIntensityMetadata';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  intensityUnit: Scalars['String']['output'];
  label: Scalars['String']['output'];
  scaleFactor: Scalars['Float']['output'];
  tooltipUnit: Scalars['String']['output'];
  unit: Scalars['String']['output'];
  unitUserFacing: Scalars['String']['output'];
};

export type GQCustomReportControlsInput = {
  reportId: Scalars['ID']['input'];
};

export type GQCustomReportControlsPayload = {
  __typename?: 'CustomReportControlsPayload';
  customReportControlsQuestion: GQReportQuestion;
};

export const GQCustomReportHeaderKind = {
  H1: 'H1',
  Text: 'Text',
} as const;

export type GQCustomReportHeaderKind =
  (typeof GQCustomReportHeaderKind)[keyof typeof GQCustomReportHeaderKind];
export type GQCustomReportsWithBiSavedViewInput = {
  biSavedViewStableId: Scalars['ID']['input'];
};

export const GQCustomerStatus = {
  Canceled: 'Canceled',
  Completed: 'Completed',
  ConfirmedByCustomer: 'ConfirmedByCustomer',
  ConfirmedByWatershed: 'ConfirmedByWatershed',
  ContractReceived: 'ContractReceived',
  ContractSigned: 'ContractSigned',
  Expired: 'Expired',
  Initiated: 'Initiated',
  InvoicePaid: 'InvoicePaid',
  InvoiceReceived: 'InvoiceReceived',
} as const;

export type GQCustomerStatus =
  (typeof GQCustomerStatus)[keyof typeof GQCustomerStatus];
export type GQCustomerTargetSchema = {
  __typename?: 'CustomerTargetSchema';
  canonicalDatasets: Array<GQCanonicalDataset>;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latestPublishedVersion: Maybe<GQCustomerTargetSchemaVersion>;
  name: Scalars['String']['output'];
  org: Maybe<GQOrganization>;
  publishedVersions: Array<GQCustomerTargetSchemaVersion>;
  rank: Scalars['Int']['output'];
  schemaId: Maybe<Scalars['ID']['output']>;
  utilityType: Maybe<GQBuildingUtilityType>;
  versions: Array<GQCustomerTargetSchemaVersion>;
};

export type GQCustomerTargetSchemaTransform = {
  __typename?: 'CustomerTargetSchemaTransform';
  businessActivityType: Maybe<GQBusinessActivityType>;
  businessActivityTypeId: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  runs: Array<GQCustomerTargetSchemaTransformRun>;
  sql: Scalars['String']['output'];
  testCases: Array<GQCustomerTargetSchemaTransformTestCase>;
  transformTarget: GQCustomerTargetSchemaTransformTarget;
};

export const GQCustomerTargetSchemaTransformParentType = {
  CtsVersion: 'CtsVersion',
  UserUpload: 'UserUpload',
} as const;

export type GQCustomerTargetSchemaTransformParentType =
  (typeof GQCustomerTargetSchemaTransformParentType)[keyof typeof GQCustomerTargetSchemaTransformParentType];
export type GQCustomerTargetSchemaTransformRun = {
  __typename?: 'CustomerTargetSchemaTransformRun';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  input: GQFileMetadata;
  output: Maybe<GQParquetData>;
  runner: GQUser;
  sql: Scalars['String']['output'];
  standardizedOutput: Maybe<GQParquetData>;
  transform: GQCustomerTargetSchemaTransform;
  warnings: Array<GQValidationWarning>;
};

export const GQCustomerTargetSchemaTransformTarget = {
  Bart: 'Bart',
  ReferenceData: 'ReferenceData',
  Utilities: 'Utilities',
} as const;

export type GQCustomerTargetSchemaTransformTarget =
  (typeof GQCustomerTargetSchemaTransformTarget)[keyof typeof GQCustomerTargetSchemaTransformTarget];
export type GQCustomerTargetSchemaTransformTestCase = {
  __typename?: 'CustomerTargetSchemaTransformTestCase';
  expectedOutput: Maybe<GQParquetData>;
  id: Scalars['ID']['output'];
  inputJson: Maybe<Scalars['JSONString']['output']>;
  kind: GQCtsTransformTestKind;
  latestRun: Maybe<GQCustomerTargetSchemaTransformTestRun>;
  name: Scalars['String']['output'];
  transform: GQCustomerTargetSchemaTransform;
};

export type GQCustomerTargetSchemaTransformTestRun = {
  __typename?: 'CustomerTargetSchemaTransformTestRun';
  id: Scalars['ID']['output'];
  status: GQCtsTestRunStatus;
  testDiffResults: Maybe<Scalars['JSONString']['output']>;
  transformRun: GQCustomerTargetSchemaTransformRun;
};

export type GQCustomerTargetSchemaVersion = {
  __typename?: 'CustomerTargetSchemaVersion';
  createdAt: Scalars['DateTime']['output'];
  deprecatedAt: Maybe<Scalars['DateTime']['output']>;
  exampleData: Scalars['JSONString']['output'];
  existsInRegionalDb: Maybe<Scalars['Boolean']['output']>;
  formattingNotesMd: Maybe<Scalars['String']['output']>;
  globalIngestionExampleData: GQIngestionExampleData;
  id: Scalars['ID']['output'];
  lastEditedByUser: GQUser;
  oneSchemaFileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  orgDatasetExampleData: Array<GQIngestionExampleData>;
  orgSchemaJson: Scalars['JSONString']['output'];
  plannedRemovedAt: Maybe<Scalars['DateTime']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  publishedForTestingAt: Maybe<Scalars['DateTime']['output']>;
  removedAt: Maybe<Scalars['DateTime']['output']>;
  schema: GQCustomerTargetSchema;
  schemaJson: Scalars['JSONString']['output'];
  schemaVersionId: Maybe<Scalars['ID']['output']>;
  semverVersion: Scalars['String']['output'];
  transforms: Array<GQCustomerTargetSchemaTransform>;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  unpublishedAt: Maybe<Scalars['DateTime']['output']>;
  versionNotesMd: Scalars['String']['output'];
};

export type GQCustomerTargetSchemaVersionOrgSchemaJsonArgs = {
  buildingId: InputMaybe<Scalars['ID']['input']>;
  datasetId: Scalars['ID']['input'];
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export type GQDataApprovalChangelogEvent = {
  __typename?: 'DataApprovalChangelogEvent';
  activityType: GQActivityTimelineItemType;
  actorUser: Maybe<GQUser>;
  datasetName: Maybe<Scalars['String']['output']>;
  datasourceName: Maybe<Scalars['String']['output']>;
  eventTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  moreDetails: Scalars['JSONString']['output'];
};

export type GQDataApprovalMeasurementProjectChangelogInput = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDataApprovalMeasurementProjectChangelogPayload = {
  __typename?: 'DataApprovalMeasurementProjectChangelogPayload';
  changelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQDataApprovalObjectChangelogInput = {
  objectId: Scalars['ID']['input'];
  objectType: GQObjectType;
};

export type GQDataApprovalObjectChangelogPayload = {
  __typename?: 'DataApprovalObjectChangelogPayload';
  changelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQDataApprovalUserUploadTaskChangelogInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQObjectType>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQDataApprovalUserUploadTaskChangelogPayload = {
  __typename?: 'DataApprovalUserUploadTaskChangelogPayload';
  changelogEvents: Array<GQDataApprovalChangelogEvent>;
};

export type GQDataCollectionEmissionsData = {
  __typename?: 'DataCollectionEmissionsData';
  amountKgco2e: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  surveyStatus: Maybe<GQCompanySurveyStatus>;
};

export const GQDataGridFilterLogicOperator = {
  And: 'And',
  Or: 'Or',
} as const;

export type GQDataGridFilterLogicOperator =
  (typeof GQDataGridFilterLogicOperator)[keyof typeof GQDataGridFilterLogicOperator];
export const GQDataGridFilterOperator = {
  Contains: 'contains',
  EndsWith: 'endsWith',
  Equals: 'equals',
  GreaterThan: 'greaterThan',
  GreaterThanOrEqual: 'greaterThanOrEqual',
  IsAnyOf: 'isAnyOf',
  IsEmpty: 'isEmpty',
  IsNotAnyOf: 'isNotAnyOf',
  IsNotEmpty: 'isNotEmpty',
  LessThan: 'lessThan',
  LessThanOrEqual: 'lessThanOrEqual',
  NotEquals: 'notEquals',
  StartsWith: 'startsWith',
} as const;

export type GQDataGridFilterOperator =
  (typeof GQDataGridFilterOperator)[keyof typeof GQDataGridFilterOperator];
export const GQDataGridSortDirection = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type GQDataGridSortDirection =
  (typeof GQDataGridSortDirection)[keyof typeof GQDataGridSortDirection];
export type GQDataGridSortItem = {
  __typename?: 'DataGridSortItem';
  field: Scalars['String']['output'];
  sort: GQDataGridSortDirection;
};

export type GQDataGridSortItemInput = {
  field: Scalars['String']['input'];
  sort: GQDataGridSortDirection;
};

export const GQDataIngestionMeasurementEventKind = {
  DataIngestionAdditionalQuestionsCompleted:
    'DataIngestionAdditionalQuestionsCompleted',
  DataIngestionDataFormatSelectionCompleted:
    'DataIngestionDataFormatSelectionCompleted',
  DataIngestionUserColumnMappingCompleted:
    'DataIngestionUserColumnMappingCompleted',
  DataIngestionUserFooterSelectionCompleted:
    'DataIngestionUserFooterSelectionCompleted',
  DataIngestionUserHeaderSelectionCompleted:
    'DataIngestionUserHeaderSelectionCompleted',
} as const;

export type GQDataIngestionMeasurementEventKind =
  (typeof GQDataIngestionMeasurementEventKind)[keyof typeof GQDataIngestionMeasurementEventKind];
export type GQDataIssue = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject &
  GQQuestion & {
    __typename?: 'DataIssue';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    anchor: GQDiscussionAnchorPayload;
    assignee: Maybe<GQUser>;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    isPublished: Scalars['Boolean']['output'];
    linkedObject: Maybe<GQDataIssueLinkedObject>;
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    state: GQDataIssueState;
    tags: Maybe<Array<GQDataIssueTag>>;
    title: Scalars['String']['output'];
  };

export type GQDataIssueCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDataIssueConnection = {
  __typename?: 'DataIssueConnection';
  edges: Array<Maybe<GQDataIssueEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDataIssueEdge = {
  __typename?: 'DataIssueEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataIssue>;
};

export type GQDataIssueLinkedObject =
  | GQFinancialsReviewItem
  | GQUserUploadTask
  | GQValueMappingTask;

export const GQDataIssueState = {
  Done: 'Done',
  NeedsUserResponse: 'NeedsUserResponse',
} as const;

export type GQDataIssueState =
  (typeof GQDataIssueState)[keyof typeof GQDataIssueState];
export const GQDataIssueTag = {
  ConfirmColumnToUse: 'ConfirmColumnToUse',
  ConfirmDataAnomaly: 'ConfirmDataAnomaly',
  ConfirmDataInterpretation: 'ConfirmDataInterpretation',
  ConfirmDataSourceToUse: 'ConfirmDataSourceToUse',
  MissingColumn: 'MissingColumn',
  MissingDataForCategory: 'MissingDataForCategory',
  MissingDataForDateRange: 'MissingDataForDateRange',
  Other: 'Other',
} as const;

export type GQDataIssueTag =
  (typeof GQDataIssueTag)[keyof typeof GQDataIssueTag];
export type GQDataRegistryTableReference = {
  __typename?: 'DataRegistryTableReference';
  alias: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQDataset = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject &
  GQPermissionObjectWithChildren & {
    __typename?: 'Dataset';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    canonicalDataset: Maybe<GQCanonicalDataset>;
    children: Array<GQDatasource>;
    customExampleData: Maybe<GQTabularData>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    datasources: Array<GQDatasource>;
    explainer: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    instructions: Maybe<GQInstructionsBundle>;
    name: Scalars['String']['output'];
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    usedFor: Maybe<Scalars['String']['output']>;
  };

export type GQDatasetChildrenArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export const GQDatasetCadence = {
  Never: 'Never',
  Once: 'Once',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
} as const;

export type GQDatasetCadence =
  (typeof GQDatasetCadence)[keyof typeof GQDatasetCadence];
export type GQDatasetConnection = {
  __typename?: 'DatasetConnection';
  edges: Array<Maybe<GQDatasetEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDatasetEdge = {
  __typename?: 'DatasetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDataset>;
};

export type GQDatasetForOrgUnitType = {
  __typename?: 'DatasetForOrgUnitType';
  datasetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQDatasetForOrgUnitTypeInput = {
  datasetId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isRequired: Scalars['Boolean']['input'];
};

export type GQDatasetIdsPerMeasurementProjectId = {
  datasetIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export const GQDatasetStage = {
  NeedsMapping: 'NeedsMapping',
  NeedsResponse: 'NeedsResponse',
  NeedsUpload: 'NeedsUpload',
  Processing: 'Processing',
  ReadyForFootprint: 'ReadyForFootprint',
} as const;

export type GQDatasetStage =
  (typeof GQDatasetStage)[keyof typeof GQDatasetStage];
export type GQDatasetSummaryFilter = {
  __typename?: 'DatasetSummaryFilter';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQDatasetWithStage = GQIdInterface & {
  __typename?: 'DatasetWithStage';
  areAllTasksComplete: Scalars['Boolean']['output'];
  areUploadsComplete: Scalars['Boolean']['output'];
  dataset: Maybe<GQDataset>;
  hasNoApprovedData: Scalars['Boolean']['output'];
  hasUnsupportedBarts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isNonEmissive: Scalars['Boolean']['output'];
  isUsableInFootprint: Scalars['Boolean']['output'];
  largeUserUploadedFiles: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Please check whether uploads or tasks are complete instead */
  stage: GQDatasetStage;
  taskIds: Array<Scalars['String']['output']>;
};

export type GQDatasetsByBat = {
  __typename?: 'DatasetsByBat';
  businessActivityType: Scalars['String']['output'];
  businessActivityTypeDisplayName: Scalars['String']['output'];
  datasets: Array<GQLiteDataset>;
  id: Scalars['ID']['output'];
};

export type GQDatasetsByBatWithMetadata = {
  __typename?: 'DatasetsByBatWithMetadata';
  ctsUserUploads: Array<GQCtsUserUpload>;
  datasetsByBat: Array<GQDatasetsByBat>;
  id: Scalars['ID']['output'];
};

export type GQDatasource = GQContextRelationInterface &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Datasource';
    activeIntegrationConnection: Maybe<GQIntegrationConnection>;
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    canDelete: Scalars['Boolean']['output'];
    canonicalDatasource: Maybe<GQCanonicalDatasource>;
    customPotentialOverlaps: Maybe<Scalars['String']['output']>;
    customUsedFor: Maybe<Scalars['String']['output']>;
    customerTargetSchemaIds: Maybe<Array<Scalars['ID']['output']>>;
    dataset: GQDataset;
    hasApiAccess: Scalars['Boolean']['output'];
    hasIntegration: Scalars['Boolean']['output'];
    hasIntegrationOption: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importInstructionsMd: Maybe<Scalars['String']['output']>;
    instructions: Maybe<GQInstructionsBundle>;
    isApi: Maybe<Scalars['Boolean']['output']>;
    isUserConfirmed: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    notesFromWatershedMd: Maybe<Scalars['String']['output']>;
    permissions: Maybe<Array<GQPermissionItem>>;
    potentialOverlaps: Maybe<Scalars['String']['output']>;
    presetCustomTags: Array<GQPresetCustomTag>;
    /** @deprecated Use presetCustomTags instead */
    presetIngestionCustomFields: Array<GQIngestionCustomFieldDatasource>;
    presetOrgUnits: Array<GQPresetOrgUnit>;
    refreshCadence: GQDatasetCadence;
    sharedFiles: GQSharedFileConnection;
    /** @deprecated No longer used */
    summary: Maybe<Array<GQDatasourceSummary>>;
    /** @deprecated No longer used. */
    summaryWithMetadata: Maybe<GQDatasourceSummaryWithMetadata>;
    usedFor: Maybe<Scalars['String']['output']>;
    /** @deprecated Use userCanManageDatasource instead */
    userCanAccessDetails: Maybe<Scalars['Boolean']['output']>;
    userCanApproveDatasource: Maybe<Scalars['Boolean']['output']>;
    userCanManageDatasource: Maybe<Scalars['Boolean']['output']>;
    userUploadTaskForMeasurementProject: GQUserUploadTask;
    userUploadTasks: GQUserUploadTaskConnection;
  };

export type GQDatasourceSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceUserUploadTaskForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDatasourceUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDatasourceConnection = {
  __typename?: 'DatasourceConnection';
  edges: Array<GQDatasourceEdge>;
  pageInfo: GQPageInfo;
};

export type GQDatasourceEdge = {
  __typename?: 'DatasourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDatasource>;
};

export const GQDatasourceImportState = {
  Complete: 'Complete',
  ConfirmDetails: 'ConfirmDetails',
  DataCleaning: 'DataCleaning',
  HasDataIssues: 'HasDataIssues',
  InProgress: 'InProgress',
  InReview: 'InReview',
  NoTask: 'NoTask',
  NotStarted: 'NotStarted',
} as const;

export type GQDatasourceImportState =
  (typeof GQDatasourceImportState)[keyof typeof GQDatasourceImportState];
export type GQDatasourcePayload = {
  __typename?: 'DatasourcePayload';
  datasource: GQDatasource;
};

export type GQDatasourceSummary = {
  __typename?: 'DatasourceSummary';
  bartActivityType: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQDatasourceSummaryWithMetadata = {
  __typename?: 'DatasourceSummaryWithMetadata';
  id: Scalars['ID']['output'];
  legacyUploads: Array<GQUserUpload>;
  summaries: Array<GQDatasourceSummary>;
  unsupportedUploads: Array<GQUserUpload>;
};

export type GQDatasourceValueMappingRevisionData = {
  __typename?: 'DatasourceValueMappingRevisionData';
  id: Scalars['ID']['output'];
  revisionState: Maybe<GQReferenceDataRevisionState>;
};

export type GQDatasourceValueMappingRule = GQIdInterface & {
  __typename?: 'DatasourceValueMappingRule';
  applicableDatasetKinds: Array<GQCanonicalDatasetKind>;
  /** @deprecated As we start using reverse feature flags, this makes less sense. Instead, encode flagging behavior in getSelfIfPassingFlag */
  featureFlag: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revisionData: GQDatasourceValueMappingRevisionData;
  /** @deprecated With SSP we won't fetch whole ValueMapping at once */
  valueMapping: Maybe<GQValueMapping>;
  valueMappingConfigId: Scalars['ID']['output'];
  valueMappingTask: GQValueMappingTask;
};

export type GQDateTimeFormatOptions = {
  __typename?: 'DateTimeFormatOptions';
  day: Maybe<Scalars['String']['output']>;
  month: Maybe<Scalars['String']['output']>;
  year: Maybe<Scalars['String']['output']>;
};

export type GQDateTimeInterval = {
  __typename?: 'DateTimeInterval';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type GQDeleteAllAnswersForReportPayload = {
  __typename?: 'DeleteAllAnswersForReportPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQDeleteAllFinanceThingsInput = {
  dataFormat: GQOneSchemaDataFormat;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GQDeleteAllFinanceThingsPayload = {
  __typename?: 'DeleteAllFinanceThingsPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQDeleteAssetCommentInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetCommentPayload = {
  __typename?: 'DeleteAssetCommentPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  id: Scalars['ID']['output'];
};

export type GQDeleteAssetHoldingInput = {
  action: GQFinanceAuditAction;
  id: Scalars['ID']['input'];
};

export type GQDeleteAssetHoldingPayload = {
  __typename?: 'DeleteAssetHoldingPayload';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  deletedFinanceDataFlagIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteAssetsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteAssetsPayload = {
  __typename?: 'DeleteAssetsPayload';
  assetCorporateIds: Array<Scalars['ID']['output']>;
  assetGroupIds: Array<Scalars['ID']['output']>;
  assetHoldingIds: Array<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceIds: Array<Scalars['ID']['output']>;
  assetRealEstateIds: Array<Scalars['ID']['output']>;
  assetSovereignBondIds: Array<Scalars['ID']['output']>;
  assetYearIds: Array<Scalars['ID']['output']>;
  emissionsYearIds: Array<Scalars['ID']['output']>;
  footprintEstimateOutputIds: Array<Scalars['ID']['output']>;
  fundHoldingBenchmarkIds: Array<Scalars['ID']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
  privateDisclosureIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteBiCustomMetricInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteBiSavedViewInput = {
  /** @deprecated Use stableId instead. */
  id?: InputMaybe<Scalars['ID']['input']>;
  stableId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQDeleteBuildingUtilityInput = {
  id: Scalars['ID']['input'];
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQDeleteBuildingUtilityPayload = {
  __typename?: 'DeleteBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUntyped: GQBuildingUntyped;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteBuildingsInput = {
  buildingIds: Array<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQDeleteBuildingsPayload = {
  __typename?: 'DeleteBuildingsPayload';
  measurementProject: GQMeasurementProject;
  organization: GQOrganization;
};

export type GQDeleteCandidateRowInput = {
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadCandidateRowId: Scalars['ID']['input'];
};

export type GQDeleteCdpApiRegistrationInput = {
  orgId: Scalars['ID']['input'];
};

export type GQDeleteCdpApiRegistrationPayload = {
  __typename?: 'DeleteCdpApiRegistrationPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteClimateProgramCalendarEventInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteClimateProgramCalendarEventPayload = {
  __typename?: 'DeleteClimateProgramCalendarEventPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteClimateProgramProjectInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteClimateProgramProjectPayload = {
  __typename?: 'DeleteClimateProgramProjectPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteClimateProgramTimelineProjectInput = {
  id: Scalars['ID']['input'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQDeleteClimateProgramTimelineProjectPayload = {
  __typename?: 'DeleteClimateProgramTimelineProjectPayload';
  id: Scalars['ID']['output'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQDeleteCompanyEngagementCohortInput = {
  cohortId: Scalars['ID']['input'];
};

export type GQDeleteCompanyEngagementCohortPayload = {
  __typename?: 'DeleteCompanyEngagementCohortPayload';
  affectedCompanyIds: Array<Scalars['ID']['output']>;
};

export type GQDeleteCompanyReportingFactsInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteCompanyReportingFactsPayload = {
  __typename?: 'DeleteCompanyReportingFactsPayload';
  companyReportingFacts: GQCompanyReportingFacts;
};

export type GQDeleteCtsInput = {
  ctsId: Scalars['ID']['input'];
};

export type GQDeleteCtsPayload = {
  __typename?: 'DeleteCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDeleteCustomEmissionsFactorsInput = {
  measurementProjectId: Scalars['String']['input'];
  stableRowIds: Array<Scalars['String']['input']>;
};

export type GQDeleteCustomEmissionsFactorsPayload = {
  __typename?: 'DeleteCustomEmissionsFactorsPayload';
  deletedIds: Array<Scalars['String']['output']>;
  jobId: Maybe<Scalars['ID']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQDeleteDatasourceInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteEngagementTaskConfigPayload = {
  __typename?: 'DeleteEngagementTaskConfigPayload';
  message: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GQDeleteFileInput = {
  fileId: Scalars['ID']['input'];
};

export type GQDeleteFilePayload = {
  __typename?: 'DeleteFilePayload';
  datasource: Maybe<GQDatasource>;
  file: Maybe<GQFileMetadata>;
  measurementProject: Maybe<GQMeasurementProject>;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQDeleteFinanceSavedViewInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteFinanceSavedViewPayload = {
  __typename?: 'DeleteFinanceSavedViewPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFinanceTagInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteFinanceTagPayload = {
  __typename?: 'DeleteFinanceTagPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteFinanceTagsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteFinanceTagsPayload = {
  __typename?: 'DeleteFinanceTagsPayload';
  ids: Array<Scalars['ID']['output']>;
};

export type GQDeleteFinanceThingsInput = {
  dataFormat: GQOneSchemaDataFormat;
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteFinanceThingsPayload = {
  __typename?: 'DeleteFinanceThingsPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQDeleteFootprintExportInput = {
  auditBundleId: Scalars['ID']['input'];
};

export type GQDeleteFootprintExportPayload = {
  __typename?: 'DeleteFootprintExportPayload';
  auditBundleId: Scalars['ID']['output'];
};

export type GQDeleteFundsInput = {
  ids: Array<Scalars['String']['input']>;
};

export type GQDeleteFundsPayload = {
  __typename?: 'DeleteFundsPayload';
  ids: Array<Scalars['String']['output']>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQDeleteIngestionCustomFieldInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteIngestionCustomFieldPayload = {
  __typename?: 'DeleteIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
  organization: GQOrganization;
};

export type GQDeleteInitiativeInput = {
  initiativeId: Scalars['ID']['input'];
};

export type GQDeleteInitiativePayload = {
  __typename?: 'DeleteInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQDeleteMaterialityAssessmentInput = {
  materialityAssessmentId: Scalars['ID']['input'];
};

export type GQDeleteMaterialityAssessmentPayload = {
  __typename?: 'DeleteMaterialityAssessmentPayload';
  materialityAssessments: Array<GQMaterialityAssessment>;
  success: Scalars['Boolean']['output'];
};

export type GQDeleteMetricFromCustomReportInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDeleteMetricFromCustomReportPayload = {
  __typename?: 'DeleteMetricFromCustomReportPayload';
  report: GQReport;
};

export type GQDeleteNonFootprintSupplierInput = {
  companyId: Scalars['ID']['input'];
};

export type GQDeleteNonFootprintSupplierPayload = {
  __typename?: 'DeleteNonFootprintSupplierPayload';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type GQDeleteOrgCompanyInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgCompanyPayload = {
  __typename?: 'DeleteOrgCompanyPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgStructureVersionPayload = {
  __typename?: 'DeleteOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQDeleteOrgUnitTypeInput = {
  id: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQDeleteOrgUnitTypePayload = {
  __typename?: 'DeleteOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQDeleteOrgUnitTypeRelationshipInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteOrgUnitTypeRelationshipPayload = {
  __typename?: 'DeleteOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
};

export type GQDeleteOrgUnitsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQDeleteOrgUnitsPayload = {
  __typename?: 'DeleteOrgUnitsPayload';
  orgUnits: Array<GQOrgUnit>;
};

export type GQDeletePeerCompanyGroupInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePeerCompanyGroupPayload = {
  __typename?: 'DeletePeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
};

export type GQDeletePlanInput = {
  planId: Scalars['ID']['input'];
};

export type GQDeletePlanTargetInput = {
  id: Scalars['ID']['input'];
};

export type GQDeletePlanTargetPayload = {
  __typename?: 'DeletePlanTargetPayload';
  plan: GQPlan;
};

export type GQDeletePrivateDisclosureTargetByIdPayload = {
  __typename?: 'DeletePrivateDisclosureTargetByIdPayload';
  disclosure: GQDisclosure;
  targetId: Scalars['ID']['output'];
};

export type GQDeleteReportAnswerInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDeleteReportAnswerPayload = {
  __typename?: 'DeleteReportAnswerPayload';
  numDeleted: Scalars['Int']['output'];
  question: GQReportQuestion;
};

export type GQDeleteReportAttachmentInput = {
  itemId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportAttachmentItemPayload = {
  __typename?: 'DeleteReportAttachmentItemPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportAttachmentPayload = {
  __typename?: 'DeleteReportAttachmentPayload';
  success: Scalars['Boolean']['output'];
};

export type GQDeleteReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportConfigPayload = {
  __typename?: 'DeleteReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQDeleteReportQuestionContainerInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionContainerPayload = {
  __typename?: 'DeleteReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
  parentId: Scalars['ID']['output'];
};

export type GQDeleteReportQuestionInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteReportQuestionNoteItemInput = {
  reportQuestionNoteItemId: Scalars['String']['input'];
};

export type GQDeleteReportQuestionNoteItemPayload = {
  __typename?: 'DeleteReportQuestionNoteItemPayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQDeleteReportQuestionPayload = {
  __typename?: 'DeleteReportQuestionPayload';
  parentId: Scalars['ID']['output'];
  question: GQReportQuestion;
};

export type GQDeleteRoleInput = {
  roleId: Scalars['ID']['input'];
};

export type GQDeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  orgId: Scalars['ID']['output'];
  roleId: Scalars['ID']['output'];
};

export type GQDeleteSnapshotInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSnapshotPayload = {
  __typename?: 'DeleteSnapshotPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteSupplierAttachmentInput = {
  companyId: Scalars['ID']['input'];
  fileMetadataId: Scalars['ID']['input'];
};

export type GQDeleteSupplierAttachmentPayload = {
  __typename?: 'DeleteSupplierAttachmentPayload';
  numberDeleted: Scalars['Int']['output'];
};

export type GQDeleteSupplierChartInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierChartPayload = {
  __typename?: 'DeleteSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQDeleteSupplierContactInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierContactPayload = {
  __typename?: 'DeleteSupplierContactPayload';
  company: GQCompany;
  id: Scalars['ID']['output'];
  numberDeleted: Scalars['Int']['output'];
};

export type GQDeleteSupplierSavedViewInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierSavedViewPayload = {
  __typename?: 'DeleteSupplierSavedViewPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteSupplierScoreCriteriaInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierScoreCriteriaPayload = {
  __typename?: 'DeleteSupplierScoreCriteriaPayload';
  supplierScorecard: GQSupplierScorecard;
};

export type GQDeleteSupplierTableColumnInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteSupplierTableColumnPayload = {
  __typename?: 'DeleteSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQDeleteSupportCaseAttachmentsInput = {
  fileMetadataIds: Array<Scalars['ID']['input']>;
};

export type GQDeleteSupportCaseAttachmentsPayload = {
  __typename?: 'DeleteSupportCaseAttachmentsPayload';
  files: Array<GQFileMetadata>;
};

export type GQDeleteSupportingDocumentInput = {
  supportingDocumentId: Scalars['ID']['input'];
};

export type GQDeleteSupportingDocumentPayload = {
  __typename?: 'DeleteSupportingDocumentPayload';
  buildingUntyped: Maybe<GQBuildingUntyped>;
  supportingDocument: GQSupportingDocument;
};

export type GQDeleteUserUploadCandidateAndRowsInput = {
  id: Scalars['ID']['input'];
};

export type GQDeleteUserUploadCandidateAndRowsPayload = {
  __typename?: 'DeleteUserUploadCandidateAndRowsPayload';
  id: Scalars['ID']['output'];
};

export type GQDeleteUserUploadTaskInput = {
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQDeleteUserUploadTaskPayload = {
  __typename?: 'DeleteUserUploadTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQDeleteUserUploadTasksInput = {
  forceDelete?: InputMaybe<Scalars['Boolean']['input']>;
  userUploadTaskIds: Array<Scalars['ID']['input']>;
};

export type GQDeleteValueMappingCommentInput = {
  deletedBy: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};

export type GQDeleteWatershedFootprintReviewRequestInput = {
  message: Scalars['String']['input'];
  watershedFootprintReviewRequestId: Scalars['ID']['input'];
};

export type GQDeleteWatershedFootprintReviewRequestPayload = {
  __typename?: 'DeleteWatershedFootprintReviewRequestPayload';
  footprintSnapshot: GQFootprintSnapshot;
};

export type GQDemoPlatformRun = {
  __typename?: 'DemoPlatformRun';
  config: Scalars['JSONString']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  statusJson: Maybe<Scalars['JSONString']['output']>;
  user: GQUser;
};

export type GQDemoPlatformRunConnection = {
  __typename?: 'DemoPlatformRunConnection';
  edges: Array<Maybe<GQDemoPlatformRunEdge>>;
  pageInfo: GQPageInfo;
};

export type GQDemoPlatformRunEdge = {
  __typename?: 'DemoPlatformRunEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQDemoPlatformRun>;
};

export type GQDependentQuestionFilter = {
  excludedComputed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDependentReportItems = {
  __typename?: 'DependentReportItems';
  containerProgresses: Array<GQReportQuestionContainer>;
  containerStatuses: Array<GQReportQuestionContainer>;
  inputs: Array<GQReportQuestion>;
  questionStatuses: Array<GQReportQuestion>;
};

export const GQDimensionComputedType = {
  NumeratorIntersection: 'NumeratorIntersection',
} as const;

export type GQDimensionComputedType =
  (typeof GQDimensionComputedType)[keyof typeof GQDimensionComputedType];
export type GQDiscloseEmissionsExpenseCategories = {
  __typename?: 'DiscloseEmissionsExpenseCategories';
  beaCode: Scalars['String']['output'];
  /** @deprecated This is no longer used */
  inputQuantity: Scalars['Float']['output'];
  /** @deprecated This is no longer used */
  inputUnit: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
};

export type GQDiscloseEmissionsExpenseCategoriesInput = {
  beaCode: Scalars['String']['input'];
  inputQuantity: Scalars['Float']['input'];
  inputUnit: Scalars['String']['input'];
  kgco2e: Scalars['Float']['input'];
};

export type GQDisclosure = {
  __typename?: 'Disclosure';
  climateCommitments: Maybe<
    Array<
      | GQCarbonNeutralCommitment
      | GQCleanEnergyCommitment
      | GQNetZeroCommitment
      | GQScienceBasedTargetCommitment
    >
  >;
  company: Maybe<GQCompany>;
  historicalEmissionsYears: Maybe<Array<GQEmissionsYear>>;
  id: Scalars['ID']['output'];
  initiatives: Maybe<Array<GQDisclosureInitiative>>;
  privateDisclosure: Maybe<GQPrivateDisclosure>;
  publicDisclosure: Maybe<GQPublicDisclosure>;
  qualityScore: Maybe<GQDisclosureQualityScore>;
  targets: Maybe<Array<GQDisclosureTargetDetails>>;
};

export const GQDisclosureCenterMadLibAnnualTurnover = {
  Between36And500Million: 'Between36And500Million',
  LessThan36Million: 'LessThan36Million',
  MoreThan500Million: 'MoreThan500Million',
} as const;

export type GQDisclosureCenterMadLibAnnualTurnover =
  (typeof GQDisclosureCenterMadLibAnnualTurnover)[keyof typeof GQDisclosureCenterMadLibAnnualTurnover];
export const GQDisclosureCenterMadLibCompanyBasedIn = {
  Eu: 'EU',
  Uk: 'UK',
  Us: 'US',
} as const;

export type GQDisclosureCenterMadLibCompanyBasedIn =
  (typeof GQDisclosureCenterMadLibCompanyBasedIn)[keyof typeof GQDisclosureCenterMadLibCompanyBasedIn];
export const GQDisclosureCenterMadLibEmployeeCount = {
  LessThan250: 'LessThan250',
  MoreThan250LessThan499: 'MoreThan250LessThan499',
  MoreThan500: 'MoreThan500',
} as const;

export type GQDisclosureCenterMadLibEmployeeCount =
  (typeof GQDisclosureCenterMadLibEmployeeCount)[keyof typeof GQDisclosureCenterMadLibEmployeeCount];
export type GQDisclosureCenterMadLibInput = {
  annualTurnover: GQDisclosureCenterMadLibAnnualTurnover;
  companyBasedIn: GQDisclosureCenterMadLibCompanyBasedIn;
  employeeCount: GQDisclosureCenterMadLibEmployeeCount;
  isBankingInsurance: Scalars['Boolean']['input'];
  isMoreThan18MillionBalanceSheet: Scalars['Boolean']['input'];
  isPubliclyListed: Scalars['Boolean']['input'];
};

export type GQDisclosureClimateCommitmentFilters = {
  commitmentKind?: InputMaybe<GQCompanyClimateCommitmentKind>;
};

export type GQDisclosureEmissionsFactor = {
  __typename?: 'DisclosureEmissionsFactor';
  emissionsFactorRow: GQReferenceDataRevisionRow;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
};

export type GQDisclosureHistoricalYearFilters = {
  allScopes?: InputMaybe<Scalars['Boolean']['input']>;
  includeExpenseCategoryEmissions?: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketBased?: InputMaybe<Scalars['Boolean']['input']>;
  revenueMustExist?: InputMaybe<Scalars['Boolean']['input']>;
  units?: InputMaybe<GQCompanyEmissionsUnits>;
  withinOrderOfMagnitudeOfNaicsEf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureIdWithReportingYear = {
  disclosureId: Scalars['String']['input'];
  reportingYear: Scalars['Int']['input'];
};

export type GQDisclosureInitiative = GQIdInterface & {
  __typename?: 'DisclosureInitiative';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  emissionsTimeseries: Maybe<GQSimpleTimeseries>;
  emissionsTimeseriesId: Maybe<Scalars['ID']['output']>;
  endDate: Maybe<Scalars['Int']['output']>;
  endYearMonth: Maybe<Scalars['YearMonth']['output']>;
  filters: Maybe<GQFilterExpressionGroup>;
  id: Scalars['ID']['output'];
  initiativeType: GQDisclosureInitiativeType;
  intensityType: Maybe<GQDisclosureTargetIntensityType>;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  privateDisclosureId: Scalars['ID']['output'];
  startDate: Maybe<Scalars['Int']['output']>;
  startYearMonth: Maybe<Scalars['YearMonth']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQDisclosureInitiativeFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQDisclosureInitiativeType = {
  AlternativeFuels: 'AlternativeFuels',
  BusinessTravelEfficiency: 'BusinessTravelEfficiency',
  CarbonRemoval: 'CarbonRemoval',
  Circularity: 'Circularity',
  ConductInventory: 'ConductInventory',
  DeforestationPrevention: 'DeforestationPrevention',
  EnergyEfficiency: 'EnergyEfficiency',
  EngageSuppliers: 'EngageSuppliers',
  HeavyTransportEfficiency: 'HeavyTransportEfficiency',
  ManureManagement: 'ManureManagement',
  MeasureFootprint: 'MeasureFootprint',
  Other: 'Other',
  RegenerativeAgriculture: 'RegenerativeAgriculture',
  RenewableEnergy: 'RenewableEnergy',
} as const;

export type GQDisclosureInitiativeType =
  (typeof GQDisclosureInitiativeType)[keyof typeof GQDisclosureInitiativeType];
export type GQDisclosureInput = {
  climateCommitmentFilters?: InputMaybe<GQDisclosureClimateCommitmentFilters>;
  excludeArchivedDisclosures?: InputMaybe<Scalars['Boolean']['input']>;
  historicalYearFilters?: InputMaybe<GQDisclosureHistoricalYearFilters>;
  initiativeFilters?: InputMaybe<GQDisclosureInitiativeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  privateDisclosureFilters?: InputMaybe<GQPrivateDisclosureInput>;
  publicDisclosureFilters?: InputMaybe<GQPublicDisclosureInput>;
  targetFilters?: InputMaybe<GQDisclosureTargetFilters>;
};

export const GQDisclosureQualityScore = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
  Unusable: 'Unusable',
  VeryHigh: 'VeryHigh',
} as const;

export type GQDisclosureQualityScore =
  (typeof GQDisclosureQualityScore)[keyof typeof GQDisclosureQualityScore];
export type GQDisclosureTargetDetails = GQIdInterface & {
  __typename?: 'DisclosureTargetDetails';
  baseYear: Scalars['YearMonth']['output'];
  companyClimateCommitmentId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTarget: GQSimpleTimeseries;
  emissionsTargetId: Scalars['ID']['output'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  privateDisclosureId: Maybe<Scalars['ID']['output']>;
  publicDisclosureId: Maybe<Scalars['ID']['output']>;
  reductionRate: GQDisclosureTargetReductionRate;
  unit: Maybe<Scalars['String']['output']>;
  unitDescription: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQDisclosureTargetEmissionsType = {
  GrossEmissions: 'GrossEmissions',
  NetEmissions: 'NetEmissions',
} as const;

export type GQDisclosureTargetEmissionsType =
  (typeof GQDisclosureTargetEmissionsType)[keyof typeof GQDisclosureTargetEmissionsType];
export type GQDisclosureTargetFilters = {
  placeholder?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQDisclosureTargetInput = {
  baseYear: Scalars['YearMonth']['input'];
  description: Scalars['String']['input'];
  emissionsType: GQDisclosureTargetEmissionsType;
  filters: GQFilterExpressionGroupInput;
  intensityType: GQDisclosureTargetIntensityType;
  name: Scalars['String']['input'];
  reductionRate: GQDisclosureTargetReductionRate;
  reductionTarget: Scalars['Float']['input'];
  targetYear: Scalars['YearMonth']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  unitDescription?: InputMaybe<Scalars['String']['input']>;
};

export const GQDisclosureTargetIntensityType = {
  Absolute: 'Absolute',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  Unit: 'Unit',
} as const;

export type GQDisclosureTargetIntensityType =
  (typeof GQDisclosureTargetIntensityType)[keyof typeof GQDisclosureTargetIntensityType];
export const GQDisclosureTargetReductionRate = {
  LinearAnnualReduction: 'LinearAnnualReduction',
  Logarithmic: 'Logarithmic',
  YearOverYear: 'YearOverYear',
} as const;

export type GQDisclosureTargetReductionRate =
  (typeof GQDisclosureTargetReductionRate)[keyof typeof GQDisclosureTargetReductionRate];
export const GQDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQDisclosureType =
  (typeof GQDisclosureType)[keyof typeof GQDisclosureType];
export type GQDisconnectIntegrationConnectionInput = {
  integrationConnectionId: Scalars['ID']['input'];
};

export type GQDisconnectIntegrationConnectionPayload = {
  __typename?: 'DisconnectIntegrationConnectionPayload';
  integrationConnection: GQIntegrationConnection;
  integrationPartner: GQIntegrationPartner;
  revoked: Scalars['Boolean']['output'];
};

export type GQDiscussion = GQIdInterface &
  GQQuestion & {
    __typename?: 'Discussion';
    anchor: GQDiscussionAnchorPayload;
    comments: GQCommentConnection;
    createdAt: Scalars['DateTime']['output'];
    description: Maybe<Scalars['String']['output']>;
    files: GQFileMetadataConnection;
    id: Scalars['ID']['output'];
    isCustomerInitiated: Scalars['Boolean']['output'];
    state: GQDataIssueState;
    title: Scalars['String']['output'];
  };

export type GQDiscussionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQDiscussionAnchor = {
  buildingId?: InputMaybe<Scalars['String']['input']>;
  dataApprovalEventId?: InputMaybe<Scalars['String']['input']>;
  financialsReviewItemId?: InputMaybe<Scalars['String']['input']>;
  measurementVerificationItemQuestionId?: InputMaybe<
    Scalars['String']['input']
  >;
  productSection?: InputMaybe<GQDiscussionProductSection>;
  reportQuestionInstance?: InputMaybe<GQDiscussionAnchorReportQuestionInstance>;
  userUploadTaskId?: InputMaybe<Scalars['String']['input']>;
  valueMappingTaskId?: InputMaybe<Scalars['String']['input']>;
};

export type GQDiscussionAnchorPayload = {
  __typename?: 'DiscussionAnchorPayload';
  financialsReviewItemId: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove Feb 21st, 2024 */
  measurementProjectId: Maybe<Scalars['String']['output']>;
  measurementVerificationItemQuestionId: Maybe<Scalars['String']['output']>;
  productSection: Maybe<GQDiscussionProductSection>;
  reportQuestionInstance: Maybe<GQReportQuestionInstancePayload>;
  userUploadTaskId: Maybe<Scalars['String']['output']>;
  valueMappingTaskId: Maybe<Scalars['String']['output']>;
};

export type GQDiscussionAnchorReportQuestionInstance = {
  reportId?: InputMaybe<Scalars['String']['input']>;
  reportQuestionId?: InputMaybe<Scalars['String']['input']>;
};

export const GQDiscussionProductSection = {
  EngagementTaskPortal: 'EngagementTaskPortal',
  Exclusions: 'Exclusions',
  Facilities: 'Facilities',
  Finance: 'Finance',
  FinanceFootprintAssembly: 'FinanceFootprintAssembly',
  FootprintQuestion: 'FootprintQuestion',
  Global: 'Global',
  Recategorization: 'Recategorization',
  SupplyChain: 'SupplyChain',
} as const;

export type GQDiscussionProductSection =
  (typeof GQDiscussionProductSection)[keyof typeof GQDiscussionProductSection];
export const GQDiscussionType = {
  Comment: 'Comment',
  Support: 'Support',
} as const;

export type GQDiscussionType =
  (typeof GQDiscussionType)[keyof typeof GQDiscussionType];
export type GQDiscussionsPayload = {
  __typename?: 'DiscussionsPayload';
  discussions: Array<GQDiscussion>;
  id: Scalars['ID']['output'];
};

export type GQDocumentCandidate = {
  __typename?: 'DocumentCandidate';
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pdfUrl: Scalars['String']['output'];
};

export type GQDocumentCandidatesPayload = {
  __typename?: 'DocumentCandidatesPayload';
  documentEntries: Array<GQDocumentCandidate>;
};

export type GQDocumentParseAttempt = GQIdInterface & {
  __typename?: 'DocumentParseAttempt';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modelType: GQDocumentParseAttemptModelType;
  updatedAt: Scalars['DateTime']['output'];
  userUploadId: Scalars['ID']['output'];
  utilityUsageData: Maybe<GQUtilityUsageData>;
};

export const GQDocumentParseAttemptModelType = {
  HumanReview: 'HumanReview',
  InvoiceParser: 'InvoiceParser',
  UtilityParser: 'UtilityParser',
} as const;

export type GQDocumentParseAttemptModelType =
  (typeof GQDocumentParseAttemptModelType)[keyof typeof GQDocumentParseAttemptModelType];
export const GQDrilldownAggregateKind = {
  Electricity: 'Electricity',
  Emissions: 'Emissions',
  Intensity: 'Intensity',
  LocationBasedEmissions: 'LocationBasedEmissions',
  PercentageEmissions: 'PercentageEmissions',
  PercentageOfCurrentView: 'PercentageOfCurrentView',
} as const;

export type GQDrilldownAggregateKind =
  (typeof GQDrilldownAggregateKind)[keyof typeof GQDrilldownAggregateKind];
export type GQDrilldownForReportQuestionInput = {
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQDrilldownForReportQuestionPayload = {
  __typename?: 'DrilldownForReportQuestionPayload';
  cardControlsJson: Maybe<Scalars['JSONString']['output']>;
  mergedControlsJson: Scalars['JSONString']['output'];
  question: GQReportQuestion;
  report: GQReport;
  reportControlsJson: Scalars['JSONString']['output'];
  savedViewControlsJson: Scalars['JSONString']['output'];
};

export const GQDrilldownInclusionKind = {
  Gross: 'Gross',
  Net: 'Net',
  Removal: 'Removal',
} as const;

export type GQDrilldownInclusionKind =
  (typeof GQDrilldownInclusionKind)[keyof typeof GQDrilldownInclusionKind];
export type GQDrilldownParameters = {
  aggregateKind: GQDrilldownAggregateKind;
  defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
  filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
  footprintKind: Scalars['String']['output'];
  groupBy: Maybe<Array<Scalars['String']['output']>>;
  inclusionKind: GQDrilldownInclusionKind;
  intensityDenominatorName: Maybe<Scalars['String']['output']>;
  timeSeriesKind: GQDrilldownTimeSeriesKind;
};

export type GQDrilldownSavedView = GQDrilldownParameters &
  GQIdInterface & {
    __typename?: 'DrilldownSavedView';
    aggregateKind: GQDrilldownAggregateKind;
    biSavedViewId: Maybe<Scalars['ID']['output']>;
    defaultTimeInterval: Maybe<Scalars['YMInterval']['output']>;
    description: Maybe<Scalars['String']['output']>;
    filterExpressions: Maybe<GQFilterExpressionGroupWithNewFilters>;
    footprintKind: Scalars['String']['output'];
    groupBy: Maybe<Array<Scalars['String']['output']>>;
    hasDataTable: Scalars['Boolean']['output'];
    hasPieChart: Scalars['Boolean']['output'];
    hasTimeSeriesChart: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    inclusionKind: GQDrilldownInclusionKind;
    intensityDenominatorName: Maybe<Scalars['String']['output']>;
    isPrivate: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    searchTerm: Maybe<Scalars['String']['output']>;
    timeSeriesKind: GQDrilldownTimeSeriesKind;
  };

export const GQDrilldownTimeSeriesKind = {
  Monthly: 'Monthly',
  Summary: 'Summary',
  Yearly: 'Yearly',
} as const;

export type GQDrilldownTimeSeriesKind =
  (typeof GQDrilldownTimeSeriesKind)[keyof typeof GQDrilldownTimeSeriesKind];
export type GQDuplicateCtsInput = {
  customerTargetSchemaId: Scalars['ID']['input'];
};

export type GQDuplicateCtsPayload = {
  __typename?: 'DuplicateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQDuplicateInitiativeInput = {
  duplicatedInitiativeId: Scalars['ID']['input'];
  kind: GQInitiativeKind;
  planId: Scalars['ID']['input'];
};

export type GQDuplicateInitiativePayload = {
  __typename?: 'DuplicateInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQDuplicateOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQDuplicateOrgStructureVersionPayload = {
  __typename?: 'DuplicateOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQDuplicatePlanInput = {
  name: Scalars['String']['input'];
  planId: Scalars['ID']['input'];
};

export type GQDuplicatePlanPayload = {
  __typename?: 'DuplicatePlanPayload';
  plan: GQPlan;
};

export const GQDustBot = {
  LearningHub: 'LearningHub',
  OrgStructure: 'OrgStructure',
} as const;

export type GQDustBot = (typeof GQDustBot)[keyof typeof GQDustBot];
export type GQDustBotHiddenContextInput = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type GQDustBotQueryInput = {
  dustBot: GQDustBot;
  hiddenContext?: InputMaybe<GQDustBotRichHiddenContext>;
  message: Scalars['String']['input'];
};

export type GQDustBotQueryResponse = {
  __typename?: 'DustBotQueryResponse';
  response: Scalars['String']['output'];
};

export type GQDustBotRichHiddenContext = {
  items: Array<GQDustBotHiddenContextInput>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

export const GQEmReleaseLifecyclePhase = {
  Alpha: 'Alpha',
  Beta: 'Beta',
  Current: 'Current',
  EndOfLife: 'EndOfLife',
  Lts: 'LTS',
  Maintenance: 'Maintenance',
} as const;

export type GQEmReleaseLifecyclePhase =
  (typeof GQEmReleaseLifecyclePhase)[keyof typeof GQEmReleaseLifecyclePhase];
export type GQEacPriceEstimate = GQIdInterface & {
  __typename?: 'EacPriceEstimate';
  alternativeCountries: Array<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  defaultProject: Maybe<GQMarketplaceProject>;
  id: Scalars['ID']['output'];
  minCreditPurchase: Scalars['Int']['output'];
  priceEstimateUsdCents: Scalars['Int']['output'];
  standard: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQEdciCompanyGrowthStageOption = {
  Buyout: 'Buyout',
  Growth: 'Growth',
  Venture: 'Venture',
} as const;

export type GQEdciCompanyGrowthStageOption =
  (typeof GQEdciCompanyGrowthStageOption)[keyof typeof GQEdciCompanyGrowthStageOption];
export const GQEdciCompanyStructureOption = {
  Private: 'Private',
  Public: 'Public',
} as const;

export type GQEdciCompanyStructureOption =
  (typeof GQEdciCompanyStructureOption)[keyof typeof GQEdciCompanyStructureOption];
export const GQEdciScope1MethodologyOption = {
  ActivityBased: 'ActivityBased',
  DirectMonitoring: 'DirectMonitoring',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEdciScope1MethodologyOption =
  (typeof GQEdciScope1MethodologyOption)[keyof typeof GQEdciScope1MethodologyOption];
export const GQEdciScope2MethodologyOption = {
  LocationBased: 'LocationBased',
  MarketBased: 'MarketBased',
  Other: 'Other',
} as const;

export type GQEdciScope2MethodologyOption =
  (typeof GQEdciScope2MethodologyOption)[keyof typeof GQEdciScope2MethodologyOption];
export const GQEditColumn = {
  DueDate: 'DueDate',
  Status: 'Status',
} as const;

export type GQEditColumn = (typeof GQEditColumn)[keyof typeof GQEditColumn];
export type GQEditIngestionCustomFieldInput = {
  /** @deprecated unused, remove may13 */
  canVaryOverTime?: InputMaybe<Scalars['Boolean']['input']>;
  datasetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  exampleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  isMetric?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  possibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  type: GQIngestionCustomFieldType;
  utilityTypes?: InputMaybe<Array<GQBuildingUtilityType>>;
};

export type GQEditIngestionCustomFieldPayload = {
  __typename?: 'EditIngestionCustomFieldPayload';
  ingestionCustomField: GQIngestionCustomField;
};

export type GQEditValueMappingCommentInput = {
  editedBy: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export const GQEditValueType = {
  Date: 'Date',
  String: 'String',
} as const;

export type GQEditValueType =
  (typeof GQEditValueType)[keyof typeof GQEditValueType];
export type GQEditor = GQApiEditor | GQUserEditor;

export const GQElectricityType = {
  Clean: 'Clean',
  Grid: 'Grid',
} as const;

export type GQElectricityType =
  (typeof GQElectricityType)[keyof typeof GQElectricityType];
export type GQEmailContents = {
  __typename?: 'EmailContents';
  bccs: Maybe<Array<Maybe<GQEmailUser>>>;
  bodyHtml: Scalars['String']['output'];
  ccs: Maybe<Array<GQEmailUser>>;
  recipients: Array<GQEmailUser>;
  sender: GQEmailUser;
  subject: Scalars['String']['output'];
};

export type GQEmailContentsInput = {
  bccs?: InputMaybe<Array<InputMaybe<GQEmailUserInput>>>;
  bodyHtml: Scalars['String']['input'];
  ccs?: InputMaybe<Array<GQEmailUserInput>>;
  recipients: Array<GQEmailUserInput>;
  sender: GQEmailUserInput;
  subject: Scalars['String']['input'];
};

export const GQEmailKind = {
  CompanyEngagement: 'CompanyEngagement',
  GeneralMillsCompanyEngagement: 'GeneralMillsCompanyEngagement',
  MeasurementNudge: 'MeasurementNudge',
  Referral: 'Referral',
  SurveyComment: 'SurveyComment',
  SurveyKickoff: 'SurveyKickoff',
  SurveyNudge: 'SurveyNudge',
} as const;

export type GQEmailKind = (typeof GQEmailKind)[keyof typeof GQEmailKind];
export const GQEmailRelationshipKind = {
  Bcc: 'Bcc',
  Cc: 'Cc',
  To: 'To',
} as const;

export type GQEmailRelationshipKind =
  (typeof GQEmailRelationshipKind)[keyof typeof GQEmailRelationshipKind];
export type GQEmailSent = {
  __typename?: 'EmailSent';
  clickCount: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fromEmail: Scalars['String']['output'];
  htmlContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  openCount: Maybe<Scalars['Int']['output']>;
  org: GQOrganization;
  subject: Scalars['String']['output'];
  users: Array<GQEmailSentUser>;
};

export type GQEmailSentConnection = {
  __typename?: 'EmailSentConnection';
  edges: Array<GQEmailSentEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmailSentEdge = {
  __typename?: 'EmailSentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEmailSent>;
};

export type GQEmailSentUser = {
  __typename?: 'EmailSentUser';
  id: Scalars['ID']['output'];
  latestEmailStatus: GQEmailStatus;
  recipientEmail: Scalars['String']['output'];
  recipientUser: Maybe<GQUser>;
  /** @deprecated redundant, use recipientUser.id instead */
  recipientUserId: Maybe<Scalars['ID']['output']>;
  relationshipKind: GQEmailRelationshipKind;
};

export const GQEmailStatus = {
  Bounce: 'Bounce',
  Deferred: 'Deferred',
  Delivered: 'Delivered',
  Dropped: 'Dropped',
  Processed: 'Processed',
  Unknown: 'Unknown',
} as const;

export type GQEmailStatus = (typeof GQEmailStatus)[keyof typeof GQEmailStatus];
export type GQEmailUser = {
  __typename?: 'EmailUser';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQEmailUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQEmissionsByGhgCategoryForFinance = {
  __typename?: 'EmissionsByGhgCategoryForFinance';
  /** @deprecated No longer used, deprecate after 2/21/25 */
  fullIntervalGross: Maybe<Scalars['Float']['output']>;
  topGhgCategories: Array<GQGhgCategorizedEmissionData>;
  /** @deprecated No longer used, deprecate after 2/21/25 */
  topGhgCategoriesWithSubcategories: Array<GQGhgCategorizedEmissionData>;
};

export type GQEmissionsByGhgScope = {
  __typename?: 'EmissionsByGhgScope';
  id: Scalars['ID']['output'];
  isEstimated: Scalars['Boolean']['output'];
  kgco2e: Scalars['Int53']['output'];
  scope: GQGhgScope;
  sourceUrl: Maybe<Scalars['String']['output']>;
  sourceYear: Maybe<Scalars['Int']['output']>;
};

export type GQEmissionsFactorDescription = {
  __typename?: 'EmissionsFactorDescription';
  emissionsFactorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVisibleEfName: Scalars['String']['output'];
};

export type GQEmissionsFactorRecordForDashboard = {
  __typename?: 'EmissionsFactorRecordForDashboard';
  assumptionSource: Maybe<GQAssumptionSource>;
  assumptionSourceMemo: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  efActivityType: Maybe<Scalars['String']['output']>;
  efName: Maybe<Scalars['String']['output']>;
  emissionsFactorDescription: Maybe<GQEmissionsFactorDescription>;
  emissionsFactorId: Scalars['ID']['output'];
  endMonth: Scalars['YearMonth']['output'];
  id: Scalars['ID']['output'];
  isCleanPower: Maybe<Scalars['Boolean']['output']>;
  isElectricityMarketEf: Maybe<Scalars['Boolean']['output']>;
  isVendorSpecific: Maybe<Scalars['Boolean']['output']>;
  kgCo2e: Maybe<Scalars['Float']['output']>;
  startMonth: Scalars['YearMonth']['output'];
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
};

export type GQEmissionsFactorRecordForDashboardSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelActiveDraft = {
  __typename?: 'EmissionsModelActiveDraft';
  activeAuthor: GQWatershedEmployee;
  activeAuthorId: Scalars['ID']['output'];
  autosavedAt: Scalars['DateTime']['output'];
  emissionsModelJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  isCheckedOut: Scalars['Boolean']['output'];
  matchers: Scalars['JSONString']['output'];
  parameterResolverJson: Maybe<Scalars['JSONString']['output']>;
  releasedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQEmissionsModelAncestor = {
  __typename?: 'EmissionsModelAncestor';
  ancestorForkOriginStableModelId: Maybe<Scalars['ID']['output']>;
  ancestorStableModel: GQEmissionsModelStable;
  relationship: GQEmissionsModelImportRelationship;
  rootIsLatestPublishedVersion: Scalars['Boolean']['output'];
  rootVersionId: Scalars['String']['output'];
};

export type GQEmissionsModelAncestry = {
  __typename?: 'EmissionsModelAncestry';
  ancestors: Array<GQEmissionsModelAncestor>;
};

export type GQEmissionsModelEditorEvalInProductInput = {
  activityRecordJson: Scalars['JSONString']['input'];
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  emissionsModelJson: Scalars['JSONString']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  parameterResolverJson: Scalars['JSONString']['input'];
  unboundParameterBindings?: InputMaybe<Array<GQUnboundParameterBinding>>;
};

export type GQEmissionsModelEditorEvalInProductPayload = {
  __typename?: 'EmissionsModelEditorEvalInProductPayload';
  emissionsEquations: Array<GQEmissionsModelEvalEquation>;
  resultJson: Scalars['JSONString']['output'];
  warnings: Array<Scalars['String']['output']>;
};

export type GQEmissionsModelEditorResolvedParameters = {
  __typename?: 'EmissionsModelEditorResolvedParameters';
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelEmissionsFactor = {
  __typename?: 'EmissionsModelEmissionsFactor';
  externalDescription: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierSpecific: Maybe<Scalars['Boolean']['output']>;
  supplierDisclosure: Maybe<GQDisclosure>;
  unit: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export type GQEmissionsModelEmissionsFactorSupplierDisclosureArgs = {
  opts: GQDisclosureInput;
};

export type GQEmissionsModelEvalEquation = {
  __typename?: 'EmissionsModelEvalEquation';
  emissionsVariableName: Scalars['String']['output'];
  trace: Scalars['String']['output'];
  traceDetails: GQTraceDetails;
};

export type GQEmissionsModelFork = {
  __typename?: 'EmissionsModelFork';
  createdAt: Scalars['DateTime']['output'];
  forkedParameterResolverId: Scalars['ID']['output'];
  forkedStable: GQEmissionsModelStable;
  forkedStableId: Scalars['ID']['output'];
  forkedVersionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  newStable: GQEmissionsModelStable;
  newStableId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelForkMatcher = {
  __typename?: 'EmissionsModelForkMatcher';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelForkId: Scalars['ID']['output'];
  forkedMatcherId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelImportRelationship = {
  __typename?: 'EmissionsModelImportRelationship';
  childId: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
};

export type GQEmissionsModelMatcher = {
  __typename?: 'EmissionsModelMatcher';
  bindings: Scalars['JSONString']['output'];
  businessActivityType: Scalars['String']['output'];
  businessActivityTypeId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  emissionsModelStableId: Scalars['ID']['output'];
  filterExpression: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keepBatVersionAtLatest: Scalars['Boolean']['output'];
  kind: GQEmissionsModelMatcherKind;
  orgId: Maybe<Scalars['String']['output']>;
  quantitySpecifier: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQEmissionsModelMatcherKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelMatcherKind =
  (typeof GQEmissionsModelMatcherKind)[keyof typeof GQEmissionsModelMatcherKind];
export type GQEmissionsModelRelease = {
  __typename?: 'EmissionsModelRelease';
  businessActivityTypeName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  emissionsModels: Array<GQEmissionsModelStable>;
  externalNotes: Scalars['String']['output'];
  globalFootprintTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  internalNotes: Scalars['String']['output'];
  isCustomToUserOrg: Maybe<Scalars['Boolean']['output']>;
  isOrgGated: Scalars['Boolean']['output'];
  latestCompatibleBat: Maybe<GQBusinessActivityType>;
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  lifecyclePhaseHistory: Array<GQEmissionsModelReleaseLifecyclePhase>;
  measurementTestSuites: Array<GQMeasurementTestSuite>;
  org: Maybe<GQOrganization>;
  orgAccess: Array<GQEmissionsModelReleaseOrgAccess>;
  orgId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQEmissionsModelReleaseByBatName = {
  __typename?: 'EmissionsModelReleaseByBatName';
  batName: Scalars['String']['output'];
  currentLifecyclePhase: Maybe<GQEmReleaseLifecyclePhase>;
  currentLifecyclePhaseUpdateDate: Maybe<Scalars['Date']['output']>;
  lifecyclePhase: Maybe<GQEmReleaseLifecyclePhase>;
  release: GQEmissionsModelRelease;
};

export type GQEmissionsModelReleaseLifecyclePhase = {
  __typename?: 'EmissionsModelReleaseLifecyclePhase';
  id: Scalars['ID']['output'];
  lifecyclePhase: GQEmReleaseLifecyclePhase;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
};

export type GQEmissionsModelReleaseOrgAccess = {
  __typename?: 'EmissionsModelReleaseOrgAccess';
  createdAt: Scalars['DateTime']['output'];
  emissionsModelRelease: GQEmissionsModelRelease;
  emissionsModelReleaseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
};

export type GQEmissionsModelResolvedParameters = GQIdInterface & {
  __typename?: 'EmissionsModelResolvedParameters';
  emissionsModelVersionId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  modelParameters: Scalars['JSONString']['output'];
};

export type GQEmissionsModelStable = GQIdInterface & {
  __typename?: 'EmissionsModelStable';
  ancestry: GQEmissionsModelAncestry;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isMutable: Scalars['Boolean']['output'];
  kind: GQEmissionsModelStableKind;
  latestPublishedVersion: Maybe<GQEmissionsModelVersion>;
  latestVersion: GQEmissionsModelVersion;
  matchers: Array<GQEmissionsModelMatcher>;
  org: Maybe<GQOrganization>;
  relatedReleases: Array<GQEmissionsModelRelease>;
  releases: Array<GQEmissionsModelRelease>;
  scope: GQEmissionsModelStableScope;
  tags: Array<GQCalculationTag>;
  title: Scalars['String']['output'];
  versions: Maybe<Array<GQEmissionsModelVersion>>;
};

export type GQEmissionsModelStableConnection = {
  __typename?: 'EmissionsModelStableConnection';
  edges: Array<GQEmissionsModelStableEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsModelStableEdge = {
  __typename?: 'EmissionsModelStableEdge';
  cursor: Scalars['String']['output'];
  node: GQEmissionsModelStable;
};

export const GQEmissionsModelStableKind = {
  Core: 'core',
  Customer: 'customer',
} as const;

export type GQEmissionsModelStableKind =
  (typeof GQEmissionsModelStableKind)[keyof typeof GQEmissionsModelStableKind];
export const GQEmissionsModelStableScope = {
  Full: 'full',
  Partial: 'partial',
} as const;

export type GQEmissionsModelStableScope =
  (typeof GQEmissionsModelStableScope)[keyof typeof GQEmissionsModelStableScope];
export type GQEmissionsModelVariableDetails = {
  __typename?: 'EmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  emissionsModelEvaluationId: Scalars['String']['output'];
  variableName: Scalars['String']['output'];
};

export type GQEmissionsModelVersion = GQIdInterface & {
  __typename?: 'EmissionsModelVersion';
  activeDraftForVersion: Maybe<GQEmissionsModelActiveDraft>;
  author: Maybe<GQUser>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  emissionsModelJson: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  importedDescendants: Array<GQEmissionsModelVersion>;
  kind: GQEmissionsModelVersionKind;
  parameterResolver: GQParameterResolver;
  parameterResolverJson: Scalars['JSONString']['output'];
  referenceDataVersions: Array<GQReferenceDataVersion>;
  schemaVersion: Scalars['String']['output'];
  stableId: Scalars['String']['output'];
  stableModel: GQEmissionsModelStable;
  title: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export type GQEmissionsModelVersionHistory = {
  __typename?: 'EmissionsModelVersionHistory';
  id: Scalars['ID']['output'];
  records: Array<GQEmissionsModelVersion>;
};

export const GQEmissionsModelVersionKind = {
  Draft: 'Draft',
  Published: 'Published',
} as const;

export type GQEmissionsModelVersionKind =
  (typeof GQEmissionsModelVersionKind)[keyof typeof GQEmissionsModelVersionKind];
export type GQEmissionsModelVersionWithReleaseIds = {
  __typename?: 'EmissionsModelVersionWithReleaseIds';
  emv: GQEmissionsModelVersion;
  id: Scalars['ID']['output'];
  releaseIds: Array<Scalars['ID']['output']>;
};

export const GQEmissionsSource = {
  Cee: 'CEE',
  CompanyNaicsCodeEstimate: 'CompanyNaicsCodeEstimate',
  ExternalReport: 'ExternalReport',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsSource =
  (typeof GQEmissionsSource)[keyof typeof GQEmissionsSource];
export type GQEmissionsYear = GQCompanyEmissionsInterfaceV2 &
  GQIdInterface & {
    __typename?: 'EmissionsYear';
    assetCorporateId: Maybe<Scalars['ID']['output']>;
    buildingEstimateOutputId: Maybe<Scalars['ID']['output']>;
    /** @deprecated unused, remove jul23 */
    cleanEnergyNotes: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    currencyConversionRate: Maybe<Scalars['Float']['output']>;
    emissionsByGhgCategory: Maybe<GQEmissionsByGhgCategoryForFinance>;
    emissionsYearSource: GQEmissionsYearSource;
    expenseCategory: Maybe<Scalars['String']['output']>;
    footprintEstimateOutputId: Maybe<Scalars['ID']['output']>;
    id: Scalars['ID']['output'];
    numEmployees: Maybe<Scalars['Float']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    percentageCleanEnergy: Maybe<Scalars['Float']['output']>;
    priorEstimateInputs: Maybe<Scalars['JSONString']['output']>;
    privateDisclosureId: Maybe<Scalars['ID']['output']>;
    publicDisclosureId: Maybe<Scalars['ID']['output']>;
    reportingYear: Scalars['Int']['output'];
    revenue: Maybe<Scalars['Float']['output']>;
    revenueCurrency: Maybe<Scalars['String']['output']>;
    revenueUsd: Maybe<Scalars['Float']['output']>;
    scenario: Maybe<Scalars['String']['output']>;
    scope1: Maybe<Scalars['Float']['output']>;
    scope2: Maybe<Scalars['Float']['output']>;
    scope2Location: Maybe<Scalars['Float']['output']>;
    scope2Market: Maybe<Scalars['Float']['output']>;
    scope3: Maybe<Scalars['Float']['output']>;
    scope123RevenueIntensityKgco2e: Maybe<GQExternalReportRevenueIntensityKgco2eByScope>;
    scope301: Maybe<Scalars['Float']['output']>;
    scope302: Maybe<Scalars['Float']['output']>;
    scope303: Maybe<Scalars['Float']['output']>;
    scope304: Maybe<Scalars['Float']['output']>;
    scope305: Maybe<Scalars['Float']['output']>;
    scope306: Maybe<Scalars['Float']['output']>;
    scope307: Maybe<Scalars['Float']['output']>;
    scope308: Maybe<Scalars['Float']['output']>;
    scope309: Maybe<Scalars['Float']['output']>;
    scope310: Maybe<Scalars['Float']['output']>;
    scope311: Maybe<Scalars['Float']['output']>;
    scope312: Maybe<Scalars['Float']['output']>;
    scope313: Maybe<Scalars['Float']['output']>;
    scope314: Maybe<Scalars['Float']['output']>;
    scope315: Maybe<Scalars['Float']['output']>;
    scope316: Maybe<Scalars['Float']['output']>;
    scope317: Maybe<Scalars['Float']['output']>;
    totalMwh: Maybe<Scalars['Int']['output']>;
    units: GQCompanyEmissionsUnits;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQEmissionsYearConnection = {
  __typename?: 'EmissionsYearConnection';
  edges: Array<GQEmissionsYearEdge>;
  pageInfo: GQPageInfo;
};

export type GQEmissionsYearEdge = {
  __typename?: 'EmissionsYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEmissionsYear>;
};

export const GQEmissionsYearScenario = {
  Actual: 'Actual',
  BauForecast: 'BAUForecast',
  Sandbox: 'Sandbox',
} as const;

export type GQEmissionsYearScenario =
  (typeof GQEmissionsYearScenario)[keyof typeof GQEmissionsYearScenario];
export const GQEmissionsYearSource = {
  BuildingEstimationEngine: 'BuildingEstimationEngine',
  CompanyEstimatationEngine: 'CompanyEstimatationEngine',
  ManualEntry: 'ManualEntry',
  PrivateCdp: 'PrivateCdp',
  PublicDisclosure: 'PublicDisclosure',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQEmissionsYearSource =
  (typeof GQEmissionsYearSource)[keyof typeof GQEmissionsYearSource];
export type GQEnergyConsumptionBreakdown = {
  __typename?: 'EnergyConsumptionBreakdown';
  dataPoints: Array<GQEnergyConsumptionDataPoint>;
  fields: Array<Scalars['String']['output']>;
  totalCleanMwh: Scalars['Float']['output'];
  totalElectricityTco2e: Scalars['Float']['output'];
  totalFootprintResults: Array<GQFootprintKindTotals>;
  /** @deprecated This returns the total footprint accross all footprint kinds. Use totalFootprintResults instead. */
  totalFootprintTco2e: Scalars['Float']['output'];
  totalMwh: Scalars['Float']['output'];
};

export type GQEnergyConsumptionDataPoint = {
  __typename?: 'EnergyConsumptionDataPoint';
  avoidedMarketTco2e: Scalars['Float']['output'];
  fieldValues: Array<GQFootprintFieldValue>;
  locationTco2e: Scalars['Float']['output'];
  marketTco2e: Scalars['Float']['output'];
  mwhConsumed: Scalars['Float']['output'];
};

export type GQEngagementCohort = {
  __typename?: 'EngagementCohort';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQEngagementCohortWithCompanyIds = {
  __typename?: 'EngagementCohortWithCompanyIds';
  companyIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQEngagementFunnel = {
  __typename?: 'EngagementFunnel';
  displayOnSupplierDetailsPage: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  steps: Array<GQEngagementFunnelStep>;
};

export type GQEngagementFunnelInput = {
  displayOnSupplierDetailsPage: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  steps: Array<GQEngagementFunnelStepInput>;
};

export type GQEngagementFunnelStep = {
  __typename?: 'EngagementFunnelStep';
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sbtStage: Maybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['output'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumn: Maybe<GQSupplierTableColumn>;
  supplierTableColumnValues: Maybe<Array<Scalars['String']['output']>>;
};

export type GQEngagementFunnelStepInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  sbtStage?: InputMaybe<GQCompanySbtCommitmentStage>;
  stepName: Scalars['String']['input'];
  stepType: GQEngagementFunnelStepType;
  supplierTableColumnId: Scalars['ID']['input'];
  supplierTableColumnValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

export const GQEngagementFunnelStepType = {
  CustomSupplierTableColumn: 'CustomSupplierTableColumn',
  EmissionsDisclosed: 'EmissionsDisclosed',
  InitiativesDisclosed: 'InitiativesDisclosed',
  NotStarted: 'NotStarted',
  SbtStage: 'SbtStage',
  SupplierLoggedIn: 'SupplierLoggedIn',
  SurveyApproved: 'SurveyApproved',
  SurveyCreated: 'SurveyCreated',
  SurveyInProgress: 'SurveyInProgress',
  SurveySubmitted: 'SurveySubmitted',
  TargetsDisclosed: 'TargetsDisclosed',
} as const;

export type GQEngagementFunnelStepType =
  (typeof GQEngagementFunnelStepType)[keyof typeof GQEngagementFunnelStepType];
export type GQEngagementNotificationSetting = GQIdInterface & {
  __typename?: 'EngagementNotificationSetting';
  companyId: Maybe<Scalars['ID']['output']>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  shouldNotify: Scalars['Boolean']['output'];
  userId: Scalars['ID']['output'];
};

export type GQEngagementNotificationSettingInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  engagementTaskId?: InputMaybe<Scalars['ID']['input']>;
  shouldNotify: Scalars['Boolean']['input'];
};

export const GQEngagementNotificationSide = {
  MappedCompany: 'MappedCompany',
  RootCustomer: 'RootCustomer',
} as const;

export type GQEngagementNotificationSide =
  (typeof GQEngagementNotificationSide)[keyof typeof GQEngagementNotificationSide];
export type GQEngagementTask = {
  __typename?: 'EngagementTask';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  comments: Array<GQEngagementTaskComment>;
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  crossOrgModelId: Scalars['String']['output'];
  currentAssignee: GQCompanySurveyAssignee;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dueDate: Maybe<Scalars['Date']['output']>;
  edits: Array<GQCompanySurveyEdit>;
  engagementTaskBatchId: Maybe<Scalars['ID']['output']>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQDiscloseEmissionsExpenseCategories>>;
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  footprintEstimate: Maybe<GQFootprintEstimateOutput>;
  id: Scalars['ID']['output'];
  initialSupplierAssigneeEmail: Maybe<Scalars['String']['output']>;
  latestCeeWorkflowId: Maybe<Scalars['ID']['output']>;
  latestFootprintDisclosureWorkflowId: Maybe<Scalars['ID']['output']>;
  latestPleeWorkflowId: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  proposeTargetEmissionsTarget: Maybe<GQSimpleTimeseries>;
  proposeTargetFilters: Maybe<GQFilterExpressionGroup>;
  proposeTargetIntensityType: Maybe<GQDisclosureTargetIntensityType>;
  reportingYear: Maybe<Scalars['Int']['output']>;
  rootAssignee: Maybe<Scalars['ID']['output']>;
  rootAssigneeDisplayName: Maybe<Scalars['String']['output']>;
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
  rootOrgId: Scalars['ID']['output'];
  status: GQCompanySurveyStatus;
  statusChangedAt: Maybe<Scalars['DateTime']['output']>;
  submittedAnswers: Array<GQEngagementTaskAnswer>;
  submittedAt: Maybe<Scalars['DateTime']['output']>;
  supplierAssignee: Maybe<Scalars['ID']['output']>;
  supplierFacingDescription: Maybe<Scalars['String']['output']>;
  supplierOrgId: Scalars['ID']['output'];
  surveyKind: GQCompanySurveyKind;
  watershedGeneric: Maybe<Scalars['Boolean']['output']>;
};

export type GQEngagementTaskAnswer = {
  __typename?: 'EngagementTaskAnswer';
  answer: Maybe<Scalars['JSONString']['output']>;
  attachments: Array<GQEngagementTaskAnswerAttachment>;
  id: Scalars['ID']['output'];
  questionIndex: Maybe<Scalars['Int']['output']>;
  questionKey: Scalars['String']['output'];
  skipped: Scalars['Boolean']['output'];
  surveyId: Scalars['ID']['output'];
};

export type GQEngagementTaskAnswerAttachment = {
  __typename?: 'EngagementTaskAnswerAttachment';
  fileMetadata: GQFileMetadata;
  originalFilename: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  signedDownloadUrl: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskAnswerInput = {
  answer?: InputMaybe<Scalars['JSONString']['input']>;
  explanation?: InputMaybe<Scalars['JSONString']['input']>;
  questionIndex?: InputMaybe<Scalars['Int']['input']>;
  questionKey: Scalars['String']['input'];
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQEngagementTaskAttachment = {
  __typename?: 'EngagementTaskAttachment';
  fileMetadataId: Scalars['ID']['output'];
  signedUploadUrl: Scalars['String']['output'];
  supplierAttachmentCrossOrgId: Scalars['ID']['output'];
};

export type GQEngagementTaskAttachmentMetadataInput = {
  name: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
};

export type GQEngagementTaskBatch = GQIdInterface & {
  __typename?: 'EngagementTaskBatch';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deployStatuses: Array<GQEngagementTaskDeployStatus>;
  engagedSuppliers: Scalars['Int']['output'];
  engagementTaskConfig: Maybe<GQEngagementTaskConfig>;
  engagementTaskConfigId: Maybe<Scalars['ID']['output']>;
  estimatedMinutesToAssignTasks: Scalars['Int']['output'];
  fromBulkDeployment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isFinance: Scalars['Boolean']['output'];
  isManuallyResolved: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  percentageApproved: Scalars['Float']['output'];
  percentageSubmitted: Scalars['Float']['output'];
  workflowId: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskBatchDeployStatus = GQIdInterface & {
  __typename?: 'EngagementTaskBatchDeployStatus';
  failedDeployCompanies: Array<GQCompany>;
  id: Scalars['ID']['output'];
  successfulDeployCompanies: Array<GQCompany>;
};

export type GQEngagementTaskComment = {
  __typename?: 'EngagementTaskComment';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  userEditorId: Maybe<Scalars['ID']['output']>;
  userEditorName: Maybe<Scalars['String']['output']>;
};

export type GQEngagementTaskConfig = GQIdInterface & {
  __typename?: 'EngagementTaskConfig';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  baseConfig: Maybe<GQEngagementTaskConfig>;
  canDelete: Scalars['Boolean']['output'];
  canDeleteInAdmin: Scalars['Boolean']['output'];
  category: GQSurveyDefinitionCategory;
  configGroup: Maybe<GQEngagementTaskConfigGroup>;
  configHasTaskAssignedToCompany: Scalars['Boolean']['output'];
  containers: Array<GQEngagementTaskContainer>;
  crossOrgModelId: Maybe<Scalars['String']['output']>;
  defaultSupplierFacingDescription: Maybe<Scalars['String']['output']>;
  engagementTaskInputs: Array<GQEngagementTaskInput>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optionalEngagementTaskInputs: Array<GQEngagementTaskInput>;
  order: Scalars['Int']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  publishedAt: Maybe<Scalars['DateTime']['output']>;
  rootFacingDescription: Maybe<Scalars['String']['output']>;
  sidebarTitle: Scalars['String']['output'];
  timeNeeded: Maybe<Scalars['Int']['output']>;
  type: Maybe<GQEngagementTaskConfigType>;
};

export const GQEngagementTaskConfigGroup = {
  Finance: 'Finance',
  SupplyChain: 'SupplyChain',
} as const;

export type GQEngagementTaskConfigGroup =
  (typeof GQEngagementTaskConfigGroup)[keyof typeof GQEngagementTaskConfigGroup];
export const GQEngagementTaskConfigType = {
  DiscloseEmissions: 'DiscloseEmissions',
  DiscloseReductionProjects: 'DiscloseReductionProjects',
  DiscloseTargets: 'DiscloseTargets',
  LearningBusinessCaseForClimateAction: 'LearningBusinessCaseForClimateAction',
  LearningEngageYourTeam: 'LearningEngageYourTeam',
  LearningGettingStartedWithCorporateScienceBasedTargets:
    'LearningGettingStartedWithCorporateScienceBasedTargets',
  LearningLaunchingAClimateProgram: 'LearningLaunchingAClimateProgram',
  LearningMeasuringEmissions: 'LearningMeasuringEmissions',
  LearningScienceBasedTargets: 'LearningScienceBasedTargets',
  SbtCommitment: 'SbtCommitment',
  SbtGhgMeasurement: 'SbtGhgMeasurement',
  SbtProgress: 'SbtProgress',
  SetASpecificTarget: 'SetASpecificTarget',
  ShareFootprint: 'ShareFootprint',
  ShareMeasurementProjectStatus: 'ShareMeasurementProjectStatus',
  SustainabilityScreening: 'SustainabilityScreening',
} as const;

export type GQEngagementTaskConfigType =
  (typeof GQEngagementTaskConfigType)[keyof typeof GQEngagementTaskConfigType];
export type GQEngagementTaskConnection = {
  __typename?: 'EngagementTaskConnection';
  edges: Array<GQEngagementTaskEdge>;
  pageInfo: GQPageInfo;
};

export type GQEngagementTaskContainer = GQIdInterface & {
  __typename?: 'EngagementTaskContainer';
  componentId: Maybe<GQEngagementTaskContainerComponentId>;
  containers: Array<GQEngagementTaskContainer>;
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  eyebrowText: Maybe<Scalars['String']['output']>;
  headerImage: Maybe<GQEngagementTaskImageId>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  questions: Array<GQEngagementTaskQuestion>;
  sidebarStepLabelOverride: Maybe<Scalars['String']['output']>;
  statusJson: Scalars['JSONString']['output'];
};

export const GQEngagementTaskContainerComponentId = {
  EngagementTaskConfirmSection: 'EngagementTaskConfirmSection',
  EngagementTaskDiscloseReductionProjectsIntroSteps:
    'EngagementTaskDiscloseReductionProjectsIntroSteps',
  EngagementTaskDiscloseTargetsIntroSteps:
    'EngagementTaskDiscloseTargetsIntroSteps',
  EngagementTaskEmissionsIntroSteps: 'EngagementTaskEmissionsIntroSteps',
  EngagementTaskExpenseCategoryIntro: 'EngagementTaskExpenseCategoryIntro',
  EngagementTaskFinanceImpBaseYearImage:
    'EngagementTaskFinanceImpBaseYearImage',
  EngagementTaskFinanceImpCaseIntro: 'EngagementTaskFinanceImpCaseIntro',
  EngagementTaskFinanceImpDataQualityScores:
    'EngagementTaskFinanceImpDataQualityScores',
  EngagementTaskFinanceImpDataTables: 'EngagementTaskFinanceImpDataTables',
  EngagementTaskFinanceImpGoals: 'EngagementTaskFinanceImpGoals',
  EngagementTaskFinanceImpScheduleCall: 'EngagementTaskFinanceImpScheduleCall',
  EngagementTaskFinanceImpSection1_4Image:
    'EngagementTaskFinanceImpSection1_4Image',
  EngagementTaskFinanceImpSection3_2Image:
    'EngagementTaskFinanceImpSection3_2Image',
  EngagementTaskFinanceImpSection3_3Image:
    'EngagementTaskFinanceImpSection3_3Image',
  EngagementTaskFinanceImpSection3_6Image:
    'EngagementTaskFinanceImpSection3_6Image',
  EngagementTaskFinanceImpSubmit: 'EngagementTaskFinanceImpSubmit',
  EngagementTaskFinanceUsingImp: 'EngagementTaskFinanceUsingImp',
  EngagementTaskLearningActivatingEmployeesDeepDive:
    'EngagementTaskLearningActivatingEmployeesDeepDive',
  EngagementTaskLearningActivatingEmployeesRecap:
    'EngagementTaskLearningActivatingEmployeesRecap',
  EngagementTaskLearningBuildingSupportDeepDive:
    'EngagementTaskLearningBuildingSupportDeepDive',
  EngagementTaskLearningBuildingSupportRecap:
    'EngagementTaskLearningBuildingSupportRecap',
  EngagementTaskLearningBusinessCaseCompletion:
    'EngagementTaskLearningBusinessCaseCompletion',
  EngagementTaskLearningBusinessCaseIntro:
    'EngagementTaskLearningBusinessCaseIntro',
  EngagementTaskLearningBusinessPartners:
    'EngagementTaskLearningBusinessPartners',
  EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsAfterSettingSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsCompletion:
    'EngagementTaskLearningCorporateScienceBasedTargetsCompletion',
  EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy:
    'EngagementTaskLearningCorporateScienceBasedTargetsEmployeeEngagementStrategy',
  EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange:
    'EngagementTaskLearningCorporateScienceBasedTargetsFoundationForChange',
  EngagementTaskLearningCorporateScienceBasedTargetsIntro:
    'EngagementTaskLearningCorporateScienceBasedTargetsIntro',
  EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsJourneyImage',
  EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsLearnMoreAboutSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage:
    'EngagementTaskLearningCorporateScienceBasedTargetsMeasureImage',
  EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands:
    'EngagementTaskLearningCorporateScienceBasedTargetsRegulatoryDemands',
  EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt:
    'EngagementTaskLearningCorporateScienceBasedTargetsStartingWithSbt',
  EngagementTaskLearningCorporateScienceBasedTargetsStep1:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep1',
  EngagementTaskLearningCorporateScienceBasedTargetsStep4:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep4',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbts',
  EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVmWareOnly:
    'EngagementTaskLearningCorporateScienceBasedTargetsWhatAreSbtsVMWareOnly',
  EngagementTaskLearningDeepDives: 'EngagementTaskLearningDeepDives',
  EngagementTaskLearningEmissionsScopes:
    'EngagementTaskLearningEmissionsScopes',
  EngagementTaskLearningEngageYourTeamCompletion:
    'EngagementTaskLearningEngageYourTeamCompletion',
  EngagementTaskLearningEngageYourTeamIntro:
    'EngagementTaskLearningEngageYourTeamIntro',
  EngagementTaskLearningEngagingLeadershipDeepDive:
    'EngagementTaskLearningEngagingLeadershipDeepDive',
  EngagementTaskLearningEngagingLeadershipRecap:
    'EngagementTaskLearningEngagingLeadershipRecap',
  EngagementTaskLearningEngagingPartners:
    'EngagementTaskLearningEngagingPartners',
  EngagementTaskLearningGettingStartedWithSbt:
    'EngagementTaskLearningGettingStartedWithSbt',
  EngagementTaskLearningIdentifyingPartnersRecap:
    'EngagementTaskLearningIdentifyingPartnersRecap',
  EngagementTaskLearningInvestors: 'EngagementTaskLearningInvestors',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevel',
  EngagementTaskLearningLaunchingAClimateProgramCompletion:
    'EngagementTaskLearningLaunchingAClimateProgramCompletion',
  EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction:
    'EngagementTaskLearningLaunchingAClimateProgramInsightsIntoAction',
  EngagementTaskLearningLaunchingAClimateProgramIntro:
    'EngagementTaskLearningLaunchingAClimateProgramIntro',
  EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramMandatoryReporting',
  EngagementTaskLearningLaunchingAClimateProgramMeasurementWork:
    'EngagementTaskLearningLaunchingAClimateProgramMeasurementWork',
  EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions:
    'EngagementTaskLearningLaunchingAClimateProgramMeasuringEmissions',
  EngagementTaskLearningLaunchingAClimateProgramPartingThoughts:
    'EngagementTaskLearningLaunchingAClimateProgramPartingThoughts',
  EngagementTaskLearningLaunchingAClimateProgramRationale:
    'EngagementTaskLearningLaunchingAClimateProgramRationale',
  EngagementTaskLearningLaunchingAClimateProgramReporting:
    'EngagementTaskLearningLaunchingAClimateProgramReporting',
  EngagementTaskLearningLaunchingAClimateProgramShortTerm:
    'EngagementTaskLearningLaunchingAClimateProgramShortTerm',
  EngagementTaskLearningLaunchingAClimateProgramStrategy:
    'EngagementTaskLearningLaunchingAClimateProgramStrategy',
  EngagementTaskLearningMeasuredAndManaged:
    'EngagementTaskLearningMeasuredAndManaged',
  EngagementTaskLearningMeasurement: 'EngagementTaskLearningMeasurement',
  EngagementTaskLearningMeasuringEmissionsCompletion:
    'EngagementTaskLearningMeasuringEmissionsCompletion',
  EngagementTaskLearningMeasuringEmissionsIntro:
    'EngagementTaskLearningMeasuringEmissionsIntro',
  EngagementTaskLearningRegulations: 'EngagementTaskLearningRegulations',
  EngagementTaskLearningScienceBasedTargetsApproved:
    'EngagementTaskLearningScienceBasedTargetsApproved',
  EngagementTaskLearningScienceBasedTargetsCompletion:
    'EngagementTaskLearningScienceBasedTargetsCompletion',
  EngagementTaskLearningScienceBasedTargetsDefinition1:
    'EngagementTaskLearningScienceBasedTargetsDefinition1',
  EngagementTaskLearningScienceBasedTargetsDefinition2:
    'EngagementTaskLearningScienceBasedTargetsDefinition2',
  EngagementTaskLearningScienceBasedTargetsDisclose:
    'EngagementTaskLearningScienceBasedTargetsDisclose',
  EngagementTaskLearningScienceBasedTargetsExpertsAdvice:
    'EngagementTaskLearningScienceBasedTargetsExpertsAdvice',
  EngagementTaskLearningScienceBasedTargetsFaq:
    'EngagementTaskLearningScienceBasedTargetsFAQ',
  EngagementTaskLearningScienceBasedTargetsIntro:
    'EngagementTaskLearningScienceBasedTargetsIntro',
  EngagementTaskLearningScienceBasedTargetsNextSteps:
    'EngagementTaskLearningScienceBasedTargetsNextSteps',
  EngagementTaskLearningScienceBasedTargetsSubmit:
    'EngagementTaskLearningScienceBasedTargetsSubmit',
  EngagementTaskSbtCommitmentIntroSteps:
    'EngagementTaskSbtCommitmentIntroSteps',
  EngagementTaskSbtGhgMeasurementIntroSteps:
    'EngagementTaskSbtGhgMeasurementIntroSteps',
  EngagementTaskSbtProgressIntroSteps: 'EngagementTaskSbtProgressIntroSteps',
  EngagementTaskSetTargetConfirmSection:
    'EngagementTaskSetTargetConfirmSection',
  EngagementTaskSetTargetIntroSteps: 'EngagementTaskSetTargetIntroSteps',
  EngagementTaskSustainabilityIntroSteps:
    'EngagementTaskSustainabilityIntroSteps',
  EngagementTaskVmWarePlanofRecordIntroSteps:
    'EngagementTaskVMWarePlanofRecordIntroSteps',
} as const;

export type GQEngagementTaskContainerComponentId =
  (typeof GQEngagementTaskContainerComponentId)[keyof typeof GQEngagementTaskContainerComponentId];
export type GQEngagementTaskContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export type GQEngagementTaskDeployStatus = GQIdInterface & {
  __typename?: 'EngagementTaskDeployStatus';
  company: GQCompany;
  engagementTaskBatch: GQEngagementTaskBatch;
  id: Scalars['ID']['output'];
  latestContactEmail: Maybe<Scalars['String']['output']>;
  latestContactName: Maybe<Scalars['String']['output']>;
  latestEmailSent: Maybe<GQEmailSent>;
  success: Scalars['Boolean']['output'];
};

export type GQEngagementTaskEdge = {
  __typename?: 'EngagementTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQEngagementTask>;
};

export const GQEngagementTaskImageId = {
  BriefcaseBrimmingWithGrass: 'BriefcaseBrimmingWithGrass',
  ClimateBuildingBlocks: 'ClimateBuildingBlocks',
  ClimateCurve: 'ClimateCurve',
  MeasuringGif: 'MeasuringGif',
  Target: 'Target',
  ToolsForClimateChange: 'ToolsForClimateChange',
} as const;

export type GQEngagementTaskImageId =
  (typeof GQEngagementTaskImageId)[keyof typeof GQEngagementTaskImageId];
export const GQEngagementTaskInput = {
  ExpenseCategories: 'expenseCategories',
  FacilityName: 'facilityName',
  ProposeTargetFilters: 'proposeTargetFilters',
  ProposeTargetIntensityType: 'proposeTargetIntensityType',
  ProposeTargetSimpleTimeseries: 'proposeTargetSimpleTimeseries',
  ReportingYear: 'reportingYear',
} as const;

export type GQEngagementTaskInput =
  (typeof GQEngagementTaskInput)[keyof typeof GQEngagementTaskInput];
export type GQEngagementTaskLimitedProfile = {
  __typename?: 'EngagementTaskLimitedProfile';
  id: Scalars['ID']['output'];
  rootCompany: GQCompany;
  rootCompanyId: Scalars['ID']['output'];
};

export type GQEngagementTaskQuestion = GQIdInterface & {
  __typename?: 'EngagementTaskQuestion';
  answerIsPrivate: Scalars['Boolean']['output'];
  componentId: GQEngagementTaskQuestionComponentId;
  deletable: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  editable: Scalars['Boolean']['output'];
  engagementTaskConfigId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Maybe<Scalars['String']['output']>;
  questionKey: Scalars['String']['output'];
  statusJson: Scalars['JSONString']['output'];
  videoLink: Maybe<Scalars['String']['output']>;
  whyCollectThis: Maybe<Scalars['String']['output']>;
};

export const GQEngagementTaskQuestionComponentId = {
  EngagementTaskAcceptedProposeTargetInput:
    'EngagementTaskAcceptedProposeTargetInput',
  EngagementTaskAllocateEmissionsMethodInput:
    'EngagementTaskAllocateEmissionsMethodInput',
  EngagementTaskAllocateEmissionsMethodV2Input:
    'EngagementTaskAllocateEmissionsMethodV2Input',
  EngagementTaskCarbonMeasurementPlanInput:
    'EngagementTaskCarbonMeasurementPlanInput',
  EngagementTaskCeeBuildingsInput: 'EngagementTaskCeeBuildingsInput',
  EngagementTaskCeeBusinessAirMilesInput:
    'EngagementTaskCeeBusinessAirMilesInput',
  EngagementTaskCeeBusinessAirSpendCurrencyInput:
    'EngagementTaskCeeBusinessAirSpendCurrencyInput',
  EngagementTaskCeeBusinessAirSpendInput:
    'EngagementTaskCeeBusinessAirSpendInput',
  EngagementTaskCeeBusinessGroundMilesInput:
    'EngagementTaskCeeBusinessGroundMilesInput',
  EngagementTaskCeeCloudInput: 'EngagementTaskCeeCloudInput',
  EngagementTaskCeeCloudProvidersInput: 'EngagementTaskCeeCloudProvidersInput',
  EngagementTaskCeeComputationNonCloudInput:
    'EngagementTaskCeeComputationNonCloudInput',
  EngagementTaskCeeEstimationOutputsInput:
    'EngagementTaskCeeEstimationOutputsInput',
  EngagementTaskCeeOfficeDaysPerMonthInput:
    'EngagementTaskCeeOfficeDaysPerMonthInput',
  EngagementTaskCleanPowerDescriptionInput:
    'EngagementTaskCleanPowerDescriptionInput',
  EngagementTaskCompanyGrowthStageSelectField:
    'EngagementTaskCompanyGrowthStageSelectField',
  EngagementTaskCompanyIndustryInput: 'EngagementTaskCompanyIndustryInput',
  EngagementTaskCompanyStructureSelectField:
    'EngagementTaskCompanyStructureSelectField',
  EngagementTaskCountryAutocompleteField:
    'EngagementTaskCountryAutocompleteField',
  EngagementTaskDecarbonizationPlanSelectField:
    'EngagementTaskDecarbonizationPlanSelectField',
  EngagementTaskDiversityInput: 'EngagementTaskDiversityInput',
  EngagementTaskEmissionAndContextAnswer:
    'EngagementTaskEmissionAndContextAnswer',
  EngagementTaskEmployeeSurveyKindsMultiSelect:
    'EngagementTaskEmployeeSurveyKindsMultiSelect',
  EngagementTaskEvaluateProposedTargetInput:
    'EngagementTaskEvaluateProposedTargetInput',
  EngagementTaskExpenseCategoryEmissionsInput:
    'EngagementTaskExpenseCategoryEmissionsInput',
  EngagementTaskExpenseCategoryShareMethodInput:
    'EngagementTaskExpenseCategoryShareMethodInput',
  EngagementTaskExpensesInput: 'EngagementTaskExpensesInput',
  EngagementTaskFinanceImpAuditSlider: 'EngagementTaskFinanceImpAuditSlider',
  EngagementTaskFinanceImpRecap: 'EngagementTaskFinanceImpRecap',
  EngagementTaskFinanceImpSlider: 'EngagementTaskFinanceImpSlider',
  EngagementTaskFinanceImpValue: 'EngagementTaskFinanceImpValue',
  EngagementTaskFootprintDisclosureOutputs:
    'EngagementTaskFootprintDisclosureOutputs',
  EngagementTaskFootprintShareSingleSelect:
    'EngagementTaskFootprintShareSingleSelect',
  EngagementTaskGhgMethodologySelectField:
    'EngagementTaskGhgMethodologySelectField',
  EngagementTaskHasEnvironmentalTeamInput:
    'EngagementTaskHasEnvironmentalTeamInput',
  EngagementTaskHaveCommittedSbtiTargetsInput:
    'EngagementTaskHaveCommittedSbtiTargetsInput',
  EngagementTaskHaveMeasuredEmissionsInput:
    'EngagementTaskHaveMeasuredEmissionsInput',
  EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input:
    'EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input',
  EngagementTaskHaveMeasuredEmissionsV2Input:
    'EngagementTaskHaveMeasuredEmissionsV2Input',
  EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput:
    'EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput',
  EngagementTaskHelpNeededMeasuringEmissions:
    'EngagementTaskHelpNeededMeasuringEmissions',
  EngagementTaskHelpNeededSettingTargets:
    'EngagementTaskHelpNeededSettingTargets',
  EngagementTaskHelpNeededSustainabilityProgram:
    'EngagementTaskHelpNeededSustainabilityProgram',
  EngagementTaskImportReportInput: 'EngagementTaskImportReportInput',
  EngagementTaskLearningBusinessActivities:
    'EngagementTaskLearningBusinessActivities',
  EngagementTaskLearningCarbonAccounting:
    'EngagementTaskLearningCarbonAccounting',
  EngagementTaskLearningCorporateClimateActionBenefits:
    'EngagementTaskLearningCorporateClimateActionBenefits',
  EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions:
    'EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions',
  EngagementTaskLearningCorporateScienceBasedTargetsStep2:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep2',
  EngagementTaskLearningCorporateScienceBasedTargetsStep3:
    'EngagementTaskLearningCorporateScienceBasedTargetsStep3',
  EngagementTaskLearningEmissionsFactors:
    'EngagementTaskLearningEmissionsFactors',
  EngagementTaskLearningEmployees: 'EngagementTaskLearningEmployees',
  EngagementTaskLearningEngagingEmployeesTactics:
    'EngagementTaskLearningEngagingEmployeesTactics',
  EngagementTaskLearningEngagingLeadershipTactics:
    'EngagementTaskLearningEngagingLeadershipTactics',
  EngagementTaskLearningIdentifyingStakeholders:
    'EngagementTaskLearningIdentifyingStakeholders',
  EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    'EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum',
  EngagementTaskLearningLaunchingAClimateProgramLongTerm:
    'EngagementTaskLearningLaunchingAClimateProgramLongTerm',
  EngagementTaskLearningLaunchingAClimateProgramSupport:
    'EngagementTaskLearningLaunchingAClimateProgramSupport',
  EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting:
    'EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting',
  EngagementTaskLearningMarketTrends: 'EngagementTaskLearningMarketTrends',
  EngagementTaskLearningOperationalCosts:
    'EngagementTaskLearningOperationalCosts',
  EngagementTaskLearningRisk: 'EngagementTaskLearningRisk',
  EngagementTaskLearningScienceBasedTargetsClimateScienceBasics:
    'EngagementTaskLearningScienceBasedTargetsClimateScienceBasics',
  EngagementTaskLearningScienceBasedTargetsDefinition3:
    'EngagementTaskLearningScienceBasedTargetsDefinition3',
  EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions:
    'EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions',
  EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted:
    'EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted',
  EngagementTaskLearningScopeOne: 'EngagementTaskLearningScopeOne',
  EngagementTaskLearningScopeThree: 'EngagementTaskLearningScopeThree',
  EngagementTaskLearningScopeTwo: 'EngagementTaskLearningScopeTwo',
  EngagementTaskLongTermNetZeroSelectField:
    'EngagementTaskLongTermNetZeroSelectField',
  EngagementTaskMeasurementProjectMultiSelect:
    'EngagementTaskMeasurementProjectMultiSelect',
  EngagementTaskMeasuringEmissionsSpectrum:
    'EngagementTaskMeasuringEmissionsSpectrum',
  EngagementTaskModifyProposedTargetInput:
    'EngagementTaskModifyProposedTargetInput',
  EngagementTaskMonthYearInput: 'EngagementTaskMonthYearInput',
  EngagementTaskNullComponent: 'EngagementTaskNullComponent',
  EngagementTaskNumberField: 'EngagementTaskNumberField',
  EngagementTaskNumberFieldOptional: 'EngagementTaskNumberFieldOptional',
  EngagementTaskNumberFieldWithBooleanSelect:
    'EngagementTaskNumberFieldWithBooleanSelect',
  EngagementTaskParentalLeaveInput: 'EngagementTaskParentalLeaveInput',
  EngagementTaskPercentageNumberField: 'EngagementTaskPercentageNumberField',
  EngagementTaskPleeEstimationOutputsInput:
    'EngagementTaskPleeEstimationOutputsInput',
  EngagementTaskPrimaryIndustryOfOperationsSelectField:
    'EngagementTaskPrimaryIndustryOfOperationsSelectField',
  EngagementTaskPrimarySectorOperationsSelectField:
    'EngagementTaskPrimarySectorOperationsSelectField',
  EngagementTaskProviderVerifiedFootprintInput:
    'EngagementTaskProviderVerifiedFootprintInput',
  EngagementTaskReductionInitiativesInput:
    'EngagementTaskReductionInitiativesInput',
  EngagementTaskReductionTargetsInput: 'EngagementTaskReductionTargetsInput',
  EngagementTaskSasbIndustrySelectField:
    'EngagementTaskSASBIndustrySelectField',
  EngagementTaskSasbSelectField: 'EngagementTaskSASBSelectField',
  EngagementTaskSbtEngagementStatusInput:
    'EngagementTaskSbtEngagementStatusInput',
  EngagementTaskScope1MethodologySelectField:
    'EngagementTaskScope1MethodologySelectField',
  EngagementTaskScope2MethodologySelectField:
    'EngagementTaskScope2MethodologySelectField',
  EngagementTaskScope3ByCategoryInput: 'EngagementTaskScope3ByCategoryInput',
  EngagementTaskScopeBoxInput: 'EngagementTaskScopeBoxInput',
  EngagementTaskSelectCurrencyField: 'EngagementTaskSelectCurrencyField',
  EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect:
    'EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect',
  EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput:
    'EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput',
  EngagementTaskShareMethodInput: 'EngagementTaskShareMethodInput',
  EngagementTaskShareMethodV2Input: 'EngagementTaskShareMethodV2Input',
  EngagementTaskShortTermReductionTargetSelectField:
    'EngagementTaskShortTermReductionTargetSelectField',
  EngagementTaskSingleCountryAutocompleteField:
    'EngagementTaskSingleCountryAutocompleteField',
  EngagementTaskSustainabilityCommitmentsInput:
    'EngagementTaskSustainabilityCommitmentsInput',
  EngagementTaskSustainabilityInitiativesWithDetailsInput:
    'EngagementTaskSustainabilityInitiativesWithDetailsInput',
  EngagementTaskTextField: 'EngagementTaskTextField',
  EngagementTaskTextFieldMultiline: 'EngagementTaskTextFieldMultiline',
  EngagementTaskVideo: 'EngagementTaskVideo',
  EngagementTaskYesNoInput: 'EngagementTaskYesNoInput',
  EngagementTaskYesNoMaybeInput: 'EngagementTaskYesNoMaybeInput',
} as const;

export type GQEngagementTaskQuestionComponentId =
  (typeof GQEngagementTaskQuestionComponentId)[keyof typeof GQEngagementTaskQuestionComponentId];
export type GQEngagementTaskQuestionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type GQEngagementTaskQuestionSelectOption = {
  __typename?: 'EngagementTaskQuestionSelectOption';
  componentId: GQEngagementTaskQuestionComponentId;
  description: Maybe<Scalars['String']['output']>;
  engagementTaskConfigId: Scalars['ID']['output'];
  engagementTaskConfigName: Scalars['String']['output'];
  questionKey: Scalars['String']['output'];
  taskConfigsWithQuestion: Array<Scalars['String']['output']>;
};

export const GQEsgdcGhgMethodologyType = {
  ActivityBased: 'ActivityBased',
  DirectEmissionsOrSupplierBased: 'DirectEmissionsOrSupplierBased',
  Other: 'Other',
  SpendBased: 'SpendBased',
} as const;

export type GQEsgdcGhgMethodologyType =
  (typeof GQEsgdcGhgMethodologyType)[keyof typeof GQEsgdcGhgMethodologyType];
export const GQEstimationMethodology = {
  Ceda: 'CEDA',
  CedaV5: 'CEDA_V5',
  CedaV7: 'CEDA_V7',
  Useeio: 'USEEIO',
  Useeio_2_1: 'USEEIO_2_1',
  Useeio_2_2_22: 'USEEIO_2_2_22',
} as const;

export type GQEstimationMethodology =
  (typeof GQEstimationMethodology)[keyof typeof GQEstimationMethodology];
export const GQEstimationStatus = {
  Estimated: 'Estimated',
  JustEmissions: 'JustEmissions',
  JustHoldings: 'JustHoldings',
  None: 'None',
} as const;

export type GQEstimationStatus =
  (typeof GQEstimationStatus)[keyof typeof GQEstimationStatus];
export type GQEvaluatedEmissionsModelsResults = {
  __typename?: 'EvaluatedEmissionsModelsResults';
  emissionsModelVersions: Array<GQEmissionsModelVersion>;
};

export type GQExampleTraceByFingerprint = {
  __typename?: 'ExampleTraceByFingerprint';
  emissionsModelEvaluationId: Scalars['String']['output'];
  exampleTrace: Scalars['String']['output'];
  fingerprint: Scalars['String']['output'];
};

export type GQExampleTracesForFingerprints = {
  __typename?: 'ExampleTracesForFingerprints';
  bartColumnDetails: Array<GQBartColumnDetails>;
  emissionsModelVariableDetails: Array<GQEmissionsModelVariableDetails>;
  referenceValueDetails: Array<GQReferenceValueDetails>;
  tracesByFingerprint: Array<Scalars['UntypedData']['output']>;
};

export type GQExecuteExtractSchemasInput = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQExecuteExtractSchemasPayload = {
  __typename?: 'ExecuteExtractSchemasPayload';
  success: Scalars['Boolean']['output'];
};

export type GQExpenseCategoryData = {
  __typename?: 'ExpenseCategoryData';
  beaCode: Scalars['String']['output'];
  /** @deprecated This is no longer used */
  inputQuantity: Scalars['Float']['output'];
  /** @deprecated This is no longer used */
  inputUnit: Scalars['String']['output'];
  kgco2e: Scalars['Float']['output'];
};

export type GQExternalReportAnswer = {
  __typename?: 'ExternalReportAnswer';
  answer: Scalars['JSONString']['output'];
  answerType: Scalars['String']['output'];
  externalReportQuestionId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  publicDisclosure: GQPublicDisclosure;
  question: GQExternalReportQuestion;
  questionPhrasing: Maybe<Scalars['String']['output']>;
  watershedQualityScore: Maybe<GQDisclosureQualityScore>;
};

export type GQExternalReportAnswerSuggestion = {
  __typename?: 'ExternalReportAnswerSuggestion';
  externalReportExtractionRun: GQExternalReportExtractionRun;
  externalReportQuestion: GQExternalReportQuestion;
  id: Scalars['ID']['output'];
  suggestion: Scalars['String']['output'];
  suggestionSource: GQSuggestionSource;
  suggestionSourceType: GQSuggestionSourceType;
};

export type GQExternalReportExtractionRun = {
  __typename?: 'ExternalReportExtractionRun';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  publicDisclosure: GQPublicDisclosure;
};

export type GQExternalReportQuestion = {
  __typename?: 'ExternalReportQuestion';
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  reportQuestionIdentifier: GQReportQuestionIdentifier;
  type: GQExternalReportTypeWithRevision;
};

export type GQExternalReportRevenueIntensityKgco2eByScope = {
  __typename?: 'ExternalReportRevenueIntensityKgco2eByScope';
  id: Scalars['ID']['output'];
  scope1: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
};

export const GQExternalReportSectionKind = {
  E1: 'E1',
  E2: 'E2',
  E3: 'E3',
  E4: 'E4',
  E5: 'E5',
  G1: 'G1',
  Governance: 'Governance',
  Metrics: 'Metrics',
  Risk: 'Risk',
  S1: 'S1',
  S2: 'S2',
  S3: 'S3',
  S4: 'S4',
  Strategy: 'Strategy',
  Targets: 'Targets',
  Taxonomy: 'Taxonomy',
} as const;

export type GQExternalReportSectionKind =
  (typeof GQExternalReportSectionKind)[keyof typeof GQExternalReportSectionKind];
export type GQExternalReportSectionUrl = {
  __typename?: 'ExternalReportSectionUrl';
  id: Scalars['ID']['output'];
  sectionKind: GQExternalReportSectionKind;
  urlFragment: Scalars['String']['output'];
};

export const GQExternalReportType = {
  BCorp: 'BCorp',
  Carb: 'CARB',
  Cdp: 'CDP',
  Csrd: 'CSRD',
  Djsi: 'DJSI',
  Edci: 'EDCI',
  Edgar: 'EDGAR',
  Esg: 'ESG',
  Issb: 'ISSB',
  Impact: 'Impact',
  Msci: 'MSCI',
  Marketing: 'Marketing',
  Other: 'Other',
  Sasb: 'SASB',
  Sb253: 'SB253',
  Sbt: 'SBT',
  Sec: 'SEC',
  Secr: 'SECR',
  Sfdr: 'SFDR',
  Sustainalytics: 'Sustainalytics',
  Tcfd: 'TCFD',
  Unfccc: 'UNFCCC',
  UkTender: 'UkTender',
} as const;

export type GQExternalReportType =
  (typeof GQExternalReportType)[keyof typeof GQExternalReportType];
export type GQExternalReportTypeWithRevision = {
  __typename?: 'ExternalReportTypeWithRevision';
  id: Scalars['ID']['output'];
  questions: Array<GQExternalReportQuestion>;
  revision: Scalars['String']['output'];
  specUrl: Maybe<Scalars['String']['output']>;
  type: GQExternalReportType;
};

export type GQFacilitiesAdditionInput = {
  buildingName: Scalars['String']['input'];
  buildingOwnerIds: Array<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  utilityOwnerIds: Array<Scalars['ID']['input']>;
};

export type GQFacilitiesExportForMeasurementProject = {
  __typename?: 'FacilitiesExportForMeasurementProject';
  jobId: Scalars['ID']['output'];
};

export type GQFacilitiesFilter = {
  filter: Array<GQFacilitiesFilterInput>;
  filterLogicOperator: GQDataGridFilterLogicOperator;
};

export type GQFacilitiesFilterInput = {
  field: GQFacilityFieldInput;
  operator: GQDataGridFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFacilitiesMetadata = {
  __typename?: 'FacilitiesMetadata';
  buildingsDataset: Maybe<GQDataset>;
  utilitiesDataset: Maybe<GQDataset>;
};

export type GQFacilitiesPreview = {
  __typename?: 'FacilitiesPreview';
  preview: Array<GQFacility>;
  totalCount: Scalars['Int']['output'];
};

export type GQFacilitiesSdiPayload = {
  __typename?: 'FacilitiesSdiPayload';
  workflowId: Scalars['String']['output'];
};

export type GQFacilitiesSetup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FacilitiesSetup';
    id: Scalars['ID']['output'];
  };

export type GQFacilitiesSortInput = {
  direction: GQDataGridSortDirection;
  field: GQFacilityFieldInput;
};

export const GQFacilitiesType = {
  Buildings: 'Buildings',
  Utilities: 'Utilities',
} as const;

export type GQFacilitiesType =
  (typeof GQFacilitiesType)[keyof typeof GQFacilitiesType];
export type GQFacility = GQBuildingInterface &
  GQIdInterface & {
    __typename?: 'Facility';
    buildingKind: Maybe<Scalars['String']['output']>;
    buildingName: Scalars['String']['output'];
    /** @deprecated Use periods instead, removing this soon */
    buildingPeriods: Array<GQBuildingPeriod>;
    buildingSubkind: Maybe<Scalars['String']['output']>;
    buildingUniqueId: Scalars['String']['output'];
    buildingWithUtilities: GQBuildingWithUtilities;
    city: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    editState: GQBuildingEditState;
    endYearMonth: Maybe<Scalars['YearMonth']['output']>;
    history: Maybe<Array<GQBuildingHistory>>;
    id: Scalars['ID']['output'];
    isWithoutNaturalGas: Maybe<Scalars['Boolean']['output']>;
    isWithoutRefrigerants: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Use lockedRevisionForTask instead. Remove after Nov 11, 2024. */
    latestApprovedVersion: Maybe<GQBuildingForDiff>;
    latitude: Maybe<Scalars['Float']['output']>;
    leaseType: Maybe<GQBuildingLeaseType>;
    lockedRevisionForMeasurementProject: Maybe<GQBuildingHistory>;
    /** @deprecated Use lockedRevisionForMeasurementProject instead. Remove after Nov 14, 2024. */
    lockedRevisionForTask: Maybe<GQBuildingHistory>;
    longitude: Maybe<Scalars['Float']['output']>;
    periods: Array<GQBuildingPeriod>;
    postalCode: Maybe<Scalars['String']['output']>;
    revision: Maybe<Scalars['Int']['output']>;
    sizeUnit: Maybe<GQBuildingSizeUnit>;
    startYearMonth: Maybe<Scalars['YearMonth']['output']>;
    state: Maybe<Scalars['String']['output']>;
    streetAddress: Maybe<Scalars['String']['output']>;
    streetAddress2: Maybe<Scalars['String']['output']>;
    supportingDocuments: Array<GQSupportingDocument>;
    tasks: Array<GQUserUploadTask>;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQFacilityLockedRevisionForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQFacilityLockedRevisionForTaskArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQFacilityTasksArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQFacilityConnection = {
  __typename?: 'FacilityConnection';
  edges: Array<Maybe<GQFacilityEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFacilityEdge = {
  __typename?: 'FacilityEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFacility>;
};

export type GQFacilityFieldInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type: GQFacilityFieldType;
};

export const GQFacilityFieldType = {
  BuildingId: 'BuildingId',
  BuildingIdentifier: 'BuildingIdentifier',
  BuildingKind: 'BuildingKind',
  BuildingTaskName: 'BuildingTaskName',
  City: 'City',
  Completion: 'Completion',
  Country: 'Country',
  CustomField: 'CustomField',
  EndYearMonth: 'EndYearMonth',
  Latitude: 'Latitude',
  LeaseType: 'LeaseType',
  Longitude: 'Longitude',
  OrgUnit: 'OrgUnit',
  Sqft: 'Sqft',
  StartYearMonth: 'StartYearMonth',
  State: 'State',
  StreetAddress: 'StreetAddress',
  UserUploadTask: 'UserUploadTask',
  UtilitiesTaskName: 'UtilitiesTaskName',
} as const;

export type GQFacilityFieldType =
  (typeof GQFacilityFieldType)[keyof typeof GQFacilityFieldType];
export type GQFeatureFlag = {
  __typename?: 'FeatureFlag';
  activated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  featureTags: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: GQFlags;
  stage: Scalars['String']['output'];
  targetType: GQFeatureFlagTargetType;
  team: GQTeam;
};

export const GQFeatureFlagTargetType = {
  Organization: 'Organization',
  User: 'User',
} as const;

export type GQFeatureFlagTargetType =
  (typeof GQFeatureFlagTargetType)[keyof typeof GQFeatureFlagTargetType];
export const GQFeedbackSurfaceArea = {
  Benchmarks: 'Benchmarks',
  Calculation: 'Calculation',
  ClimateProgramTimeline: 'ClimateProgramTimeline',
  DataGovernance: 'DataGovernance',
  DataLineage: 'DataLineage',
  Drilldown: 'Drilldown',
  Facilities: 'Facilities',
  Finance: 'Finance',
  Homepage: 'Homepage',
  LearningHub: 'LearningHub',
  MethodologyCustomization: 'MethodologyCustomization',
  Redux: 'Redux',
  Reporting: 'Reporting',
  SupplierEmissionsFactors: 'SupplierEmissionsFactors',
  SupplyChain: 'SupplyChain',
  Test: 'Test',
} as const;

export type GQFeedbackSurfaceArea =
  (typeof GQFeedbackSurfaceArea)[keyof typeof GQFeedbackSurfaceArea];
export type GQFetchSupportCaseAttachmentDownloadUrlInput = {
  fileMetadataId: Scalars['ID']['input'];
};

export type GQFetchSupportCaseAttachmentDownloadUrlPayload = {
  __typename?: 'FetchSupportCaseAttachmentDownloadUrlPayload';
  downloadUrl: Scalars['String']['output'];
};

export const GQFileCategory = {
  Admin: 'Admin',
  Automapper: 'Automapper',
  BuildingSurvey: 'BuildingSurvey',
  BusinessActivityRecordTable: 'BusinessActivityRecordTable',
  Cdp: 'CDP',
  DataRegistryTable: 'DataRegistryTable',
  DrilldownExport: 'DrilldownExport',
  EmissionsModel: 'EmissionsModel',
  FileExport: 'FileExport',
  Fixture: 'Fixture',
  GlobalReferenceData: 'GlobalReferenceData',
  Integration: 'Integration',
  MarketplaceCustomer: 'MarketplaceCustomer',
  MeasurementArtifact: 'MeasurementArtifact',
  NetworkedDataTemporaryDataTransfer: 'NetworkedDataTemporaryDataTransfer',
  Public: 'Public',
  RawVendors: 'RawVendors',
  ReferenceData: 'ReferenceData',
  Report: 'Report',
  SAndP: 'SAndP',
  Supplier: 'Supplier',
  SupplierActivity: 'SupplierActivity',
  SupplierAttachment: 'SupplierAttachment',
  SuppliersCache: 'SuppliersCache',
  Support: 'Support',
  System: 'System',
  Table: 'Table',
  TcfdReportUpload: 'TcfdReportUpload',
  User: 'User',
  UserAccessibleSystemData: 'UserAccessibleSystemData',
  UserBackup: 'UserBackup',
} as const;

export type GQFileCategory =
  (typeof GQFileCategory)[keyof typeof GQFileCategory];
export type GQFileDataLineage = {
  __typename?: 'FileDataLineage';
  batType: Maybe<Scalars['String']['output']>;
  fileMetadataId: Scalars['String']['output'];
  isTruncated: Scalars['Boolean']['output'];
  rows: Array<Scalars['JSONString']['output']>;
};

export type GQFileFeedEmbeddedSession = {
  __typename?: 'FileFeedEmbeddedSession';
  embeddedSessionToken: Scalars['String']['output'];
  oneSchemaEmbedUserJwt: Scalars['String']['output'];
};

export type GQFileMetadata = GQContextRelationInterface &
  GQIdInterface & {
    __typename?: 'FileMetadata';
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    category: GQFileCategory;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    directory: Scalars['String']['output'];
    gcloudStorageUrl: Scalars['String']['output'];
    hidden: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    remotePath: Scalars['String']['output'];
    remoteWritten: Scalars['Boolean']['output'];
    revisionRoot: Maybe<Scalars['ID']['output']>;
    revisionRootFileMetadata: Maybe<GQFileMetadata>;
    signedParquetUrl: Scalars['String']['output'];
    size: Scalars['Int53']['output'];
    uploader: Maybe<GQUser | GQWatershedEmployee>;
    userUploadTask: Maybe<GQUserUploadTask>;
  };

export type GQFileMetadataConnection = {
  __typename?: 'FileMetadataConnection';
  edges: Array<Maybe<GQFileMetadataEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'FileMetadata' and its cursor. */
export type GQFileMetadataEdge = {
  __typename?: 'FileMetadataEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFileMetadata>;
};

export const GQFilterConjunction = {
  AndConjunction: 'andConjunction',
} as const;

export type GQFilterConjunction =
  (typeof GQFilterConjunction)[keyof typeof GQFilterConjunction];
export type GQFilterExpressionGroup = {
  __typename?: 'FilterExpressionGroup';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitive>;
};

export type GQFilterExpressionGroupInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveInput>;
};

export type GQFilterExpressionGroupWithNewFilters = {
  __typename?: 'FilterExpressionGroupWithNewFilters';
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFilters>;
};

export type GQFilterExpressionGroupWithNewFiltersInput = {
  conjunction: GQFilterConjunction;
  expressions: Array<GQFilterExpressionPrimitiveWithNewFiltersInput>;
};

export type GQFilterExpressionPrimitive = {
  __typename?: 'FilterExpressionPrimitive';
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveInput = {
  field: GQFilterFieldLegacy;
  operator: GQFilterOperator;
  value: Array<Scalars['String']['input']>;
};

export type GQFilterExpressionPrimitiveWithNewFilters = {
  __typename?: 'FilterExpressionPrimitiveWithNewFilters';
  field: Scalars['String']['output'];
  operator: GQFilterOperator;
  value: Array<Scalars['String']['output']>;
};

export type GQFilterExpressionPrimitiveWithNewFiltersInput = {
  field: Scalars['String']['input'];
  operator: GQFilterOperator;
  value: Array<Scalars['UntypedData']['input']>;
};

/** Primitive fields map directly to attributes of the footprint. */
export const GQFilterFieldLegacy = {
  BuildingName: 'buildingName',
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  Location: 'location',
  LocationCountry: 'locationCountry',
  Product: 'product',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldLegacy =
  (typeof GQFilterFieldLegacy)[keyof typeof GQFilterFieldLegacy];
/**
 * These fields map directly to known attributes of the footprint in the new
 * Measurement Artifacts Interface, in contrast with fields custom to customers.
 * Listed in the same order as the fields in FilterField (but with the new
 * names), with the exception of productMarket - that has been removed.
 */
export const GQFilterFieldWatershed = {
  BuildingName: 'buildingName',
  CategoryId: 'categoryId',
  CompanyId: 'companyId',
  Description: 'description',
  ElectricityType: 'electricityType',
  FootprintKind: 'footprintKind',
  GhgCategoryId: 'ghgCategoryId',
  GhgScope: 'ghgScope',
  InputUnit: 'inputUnit',
  IsSupplierSpecific: 'isSupplierSpecific',
  Location: 'location',
  LocationCity: 'locationCity',
  LocationCountry: 'locationCountry',
  LocationState: 'locationState',
  Product: 'product',
  SubcategoryId: 'subcategoryId',
  Vendor: 'vendor',
} as const;

export type GQFilterFieldWatershed =
  (typeof GQFilterFieldWatershed)[keyof typeof GQFilterFieldWatershed];
export const GQFilterOperator = {
  In: 'in',
  NotIn: 'notIn',
} as const;

export type GQFilterOperator =
  (typeof GQFilterOperator)[keyof typeof GQFilterOperator];
export type GQFilterSelectedAllocationInstructionsPayload = {
  __typename?: 'FilterSelectedAllocationInstructionsPayload';
  activeSelectedAllocationInstructions: Array<GQMarketplaceAllocationInstruction>;
  deletedAllocationInstructionIds: Array<Scalars['ID']['output']>;
  numTotalAllocationInstructions: Scalars['Int']['output'];
};

export type GQFilterableReducibleFootprintAggregate = {
  __typename?: 'FilterableReducibleFootprintAggregate';
  footprintRowCategoryIds: Array<Scalars['String']['output']>;
  footprintRowSubcategoryIds: Array<Scalars['String']['output']>;
  ghgCategory: Scalars['String']['output'];
  locationCity: Scalars['String']['output'];
  locationCountry: Scalars['String']['output'];
  locationGrid: Scalars['String']['output'];
  locationState: Scalars['String']['output'];
  quantityKgco2e: Scalars['Float']['output'];
  reductionKgco2e: Scalars['Float']['output'];
};

export type GQFinanceAggregateMetric = {
  __typename?: 'FinanceAggregateMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  unit: Maybe<Scalars['String']['output']>;
};

export type GQFinanceAggregateMetricRecord = {
  __typename?: 'FinanceAggregateMetricRecord';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  colorIndex: Scalars['Int']['output'];
  isEstimate: Maybe<Scalars['Boolean']['output']>;
  value: Maybe<GQFinanceAggregateMetric>;
};

export type GQFinanceAnyMetric =
  | GQFinanceAggregateMetric
  | GQFinanceStringMetric;

export type GQFinanceAssetAuditReportOption = {
  __typename?: 'FinanceAssetAuditReportOption';
  emissionsYearId: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQFinanceAssetHolding = {
  __typename?: 'FinanceAssetHolding';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetClass: GQFundAssetClass;
  assetYearId: Scalars['ID']['output'];
  attributionFactorOverride: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  fund: Maybe<GQFund>;
  fundId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  outstandingAmountNative: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ1: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ2: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ3: Maybe<Scalars['Float']['output']>;
  outstandingAmountNativeQ4: Maybe<Scalars['Float']['output']>;
  premiumNative: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
  year: Maybe<Scalars['Int']['output']>;
  yearOfInitialInvestment: Maybe<Scalars['Int']['output']>;
};

export const GQFinanceAssetIigccStatus = {
  AchievingNetZero: 'AchievingNetZero',
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CommittedToAligning: 'CommittedToAligning',
  NotAligned: 'NotAligned',
} as const;

export type GQFinanceAssetIigccStatus =
  (typeof GQFinanceAssetIigccStatus)[keyof typeof GQFinanceAssetIigccStatus];
export type GQFinanceAssetYear = {
  __typename?: 'FinanceAssetYear';
  assetCommercialRealEstateId: Maybe<Scalars['String']['output']>;
  assetCorporateId: Maybe<Scalars['String']['output']>;
  assetGroupId: Maybe<Scalars['String']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationStatus: Maybe<Scalars['String']['output']>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  surveyPortalUrl: Maybe<Scalars['String']['output']>;
  surveyStatus: Maybe<GQCompanySurveyStatus>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearNew = {
  __typename?: 'FinanceAssetYearNew';
  asset: Maybe<
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond
  >;
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  assetSovereignBondId: Maybe<Scalars['ID']['output']>;
  buildingEstimationErr: Maybe<Scalars['String']['output']>;
  buildingSizeNative: Maybe<Scalars['Int']['output']>;
  cloudSpendNative: Maybe<Scalars['Float']['output']>;
  committedToNearTermSbtAlignedNetZero: Maybe<Scalars['Boolean']['output']>;
  companyEstimationErr: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  currencyConversionRate: Scalars['Float']['output'];
  demonstratingYoyEmissionsInLineWithNetZero: Maybe<
    Scalars['Boolean']['output']
  >;
  emissionsYear: Maybe<GQEmissionsYear>;
  emissionsYearId: Maybe<Scalars['ID']['output']>;
  estimationMethodology: Maybe<GQEstimationMethodology>;
  flightMiles: Maybe<Scalars['Int']['output']>;
  headcount: Maybe<Scalars['Int']['output']>;
  iciAlignmentOverride: Maybe<GQIciAlignmentStatus>;
  iciAlignmentStatus: Maybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['output'];
  initiatedDecarbonizationPlan: Maybe<Scalars['Boolean']['output']>;
  measuringMaterialScope3Emissions: Maybe<Scalars['Boolean']['output']>;
  measuringScope1And2Emissions: Maybe<Scalars['Boolean']['output']>;
  orgId: Scalars['ID']['output'];
  percentEmployeesWfh: Maybe<Scalars['Float']['output']>;
  percentRenewable: Maybe<Scalars['Float']['output']>;
  revenueNative: Maybe<Scalars['Float']['output']>;
  revenueSourceEmissionsYearId: Maybe<Scalars['ID']['output']>;
  scenario: Maybe<Scalars['String']['output']>;
  scope1OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope1OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2LocationOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2LocationOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope2MarketOverridePcaf: Maybe<Scalars['Int']['output']>;
  scope2MarketOverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope3OverrideKind: Maybe<GQScope3OverrideKind>;
  scope3OverridePcaf: Maybe<Scalars['Int']['output']>;
  scope3OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope301OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope302OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope303OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope304OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope305OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope306OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope307OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope308OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope309OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope310OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope311OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope312OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope313OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope314OverrideTco2e: Maybe<Scalars['Float']['output']>;
  scope315OverrideTco2e: Maybe<Scalars['Float']['output']>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  totalMwh: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  valueNative: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQFinanceAssetYearOverYearEdge = {
  __typename?: 'FinanceAssetYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceAssetsYearOverYear = {
  __typename?: 'FinanceAssetsYearOverYear';
  edges: Array<Maybe<GQFinanceAssetYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export const GQFinanceAuditAction = {
  ApiRequest: 'APIRequest',
  AssetPageUpdate: 'AssetPageUpdate',
  BulkImport: 'BulkImport',
  BulkUpdateAssetFromFlags: 'BulkUpdateAssetFromFlags',
  CompanyMatchUpdate: 'CompanyMatchUpdate',
  CreateAssetDialog: 'CreateAssetDialog',
  EngagementTask: 'EngagementTask',
  EstimateEmissions: 'EstimateEmissions',
  SidebarUpdate: 'SidebarUpdate',
  WatershedUpdate: 'WatershedUpdate',
} as const;

export type GQFinanceAuditAction =
  (typeof GQFinanceAuditAction)[keyof typeof GQFinanceAuditAction];
export type GQFinanceAuditFileInfoInput = {
  fileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  financeImportId: Scalars['ID']['input'];
};

export type GQFinanceAuditReportData = {
  emissionsYearId: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};

export type GQFinanceAuditReportForAssetYearInput = {
  assetId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQFinanceAuditReportForAssetYearPayload = {
  __typename?: 'FinanceAuditReportForAssetYearPayload';
  jobId: Scalars['ID']['output'];
};

export type GQFinanceAutomaticReminder = {
  __typename?: 'FinanceAutomaticReminder';
  deadline: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  reminderConfig: Scalars['JSONString']['output'];
  reminderType: GQFinanceReminderType;
};

export type GQFinanceChangelogConnection = {
  __typename?: 'FinanceChangelogConnection';
  edges: Array<Maybe<GQFinanceChangelogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinanceChangelogEdge = {
  __typename?: 'FinanceChangelogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinanceChangelogEntry>;
};

export type GQFinanceChangelogEntry = {
  __typename?: 'FinanceChangelogEntry';
  assetId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  fundId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lineItems: Array<GQFinanceChangelogLineItem>;
  objectLabel: Scalars['String']['output'];
  userDisplayName: Scalars['String']['output'];
};

export type GQFinanceChangelogLineItem = {
  __typename?: 'FinanceChangelogLineItem';
  field: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['String']['output']>;
  oldValue: Maybe<Scalars['String']['output']>;
};

export type GQFinanceChart = {
  __typename?: 'FinanceChart';
  activeObjectType: Maybe<GQFinanceChartActiveObjectType>;
  chartKind: GQFinanceChartKind;
  data: Scalars['UntypedData']['output'];
  highchartsData: Scalars['UntypedData']['output'];
  highchartsKind: GQFinanceHighchartsChartKind;
  highchartsUnit: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  metric: Maybe<GQFinanceChartMetric>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tooltipText: Maybe<Scalars['String']['output']>;
};

export const GQFinanceChartActiveObjectType = {
  Asset: 'Asset',
  Fund: 'Fund',
  FundCategory: 'FundCategory',
  FundGroup: 'FundGroup',
} as const;

export type GQFinanceChartActiveObjectType =
  (typeof GQFinanceChartActiveObjectType)[keyof typeof GQFinanceChartActiveObjectType];
export const GQFinanceChartKind = {
  FinanceColumnChart: 'FinanceColumnChart',
  FinanceStackedBarChart: 'FinanceStackedBarChart',
  FinancedEmissionsByScope: 'FinancedEmissionsByScope',
  FinancedEmissionsBySector: 'FinancedEmissionsBySector',
  TotalEmissionsByScope: 'TotalEmissionsByScope',
} as const;

export type GQFinanceChartKind =
  (typeof GQFinanceChartKind)[keyof typeof GQFinanceChartKind];
export const GQFinanceChartMetric = {
  AssetCount: 'assetCount',
  EconomicIntensity: 'economicIntensity',
  EconomicIntensityDisplay: 'economicIntensityDisplay',
  FinancedEmissions: 'financedEmissions',
  HoldingCount: 'holdingCount',
  OutstandingAmountDisplay: 'outstandingAmountDisplay',
  OutstandingAmountTarget: 'outstandingAmountTarget',
  Pcaf: 'pcaf',
  RevenueIntensity: 'revenueIntensity',
  RevenueIntensityDisplay: 'revenueIntensityDisplay',
  TotalEmissions: 'totalEmissions',
  Waci: 'waci',
  WaciDisplay: 'waciDisplay',
} as const;

export type GQFinanceChartMetric =
  (typeof GQFinanceChartMetric)[keyof typeof GQFinanceChartMetric];
export type GQFinanceColumn = {
  __typename?: 'FinanceColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinanceDataFlag = {
  __typename?: 'FinanceDataFlag';
  assetYearId: Maybe<Scalars['ID']['output']>;
  category: GQFinanceDataFlagCategory;
  checkKind: GQFinanceDataFlagCheck;
  columns: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  finSnapAssetId: Maybe<Scalars['ID']['output']>;
  finSnapFundId: Maybe<Scalars['ID']['output']>;
  finSnapHoldingId: Maybe<Scalars['ID']['output']>;
  finSnapYearId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  objectKind: GQFinanceDataFlagObjectKind;
  rowData: Scalars['UntypedData']['output'];
  stepsToResolve: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export const GQFinanceDataFlagCategory = {
  NoFinancedEmissions: 'NoFinancedEmissions',
  NoTotalEmissions: 'NoTotalEmissions',
  PossibleDataImprovement: 'PossibleDataImprovement',
  PossibleDataIssue: 'PossibleDataIssue',
} as const;

export type GQFinanceDataFlagCategory =
  (typeof GQFinanceDataFlagCategory)[keyof typeof GQFinanceDataFlagCategory];
export const GQFinanceDataFlagCheck = {
  AnomalousAssetValue: 'AnomalousAssetValue',
  AnomalousAttributionFactor: 'AnomalousAttributionFactor',
  AnomalousMwhValue: 'AnomalousMwhValue',
  AnomalousOutstandingAmount: 'AnomalousOutstandingAmount',
  AnomalousRevenueMultiple: 'AnomalousRevenueMultiple',
  AnomalousRevenueValue: 'AnomalousRevenueValue',
  AnomalousTotalBuildingArea: 'AnomalousTotalBuildingArea',
  AnomalousYearOverYearAssetValue: 'AnomalousYearOverYearAssetValue',
  AnomalousYearOverYearFinancedEmissions:
    'AnomalousYearOverYearFinancedEmissions',
  AnomalousYearOverYearOutstandingAmount:
    'AnomalousYearOverYearOutstandingAmount',
  AnomalousYearOverYearRevenueValue: 'AnomalousYearOverYearRevenueValue',
  AnomalousYearOverYearTotalEmissions: 'AnomalousYearOverYearTotalEmissions',
  AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides:
    'AssetInEmissionsIntensiveIndustryWithEstimatesNoOverrides',
  AssetUsingAdjustmentCode: 'AssetUsingAdjustmentCode',
  BottomUpFootprintAvailable: 'BottomUpFootprintAvailable',
  CedaForAluminumSector: 'CedaForAluminumSector',
  CedaForPowerSector: 'CedaForPowerSector',
  CedaForWasteSector: 'CedaForWasteSector',
  ExternalReportAvailable: 'ExternalReportAvailable',
  FundHighEconomicIntensity: 'FundHighEconomicIntensity',
  FundHighWaciScope1And2: 'FundHighWaciScope1And2',
  FundHighWaciScope123: 'FundHighWaciScope123',
  HighQualityCdpReportAvailable: 'HighQualityCdpReportAvailable',
  InvestmentVolumeSignificantPortionOfAssetValue:
    'InvestmentVolumeSignificantPortionOfAssetValue',
  MissingAssetValue: 'MissingAssetValue',
  MissingBuildingKind: 'MissingBuildingKind',
  MissingCountry: 'MissingCountry',
  MissingEmissionsData: 'MissingEmissionsData',
  MissingEstimation: 'MissingEstimation',
  MissingIndustry: 'MissingIndustry',
  MissingInvestmentVolume: 'MissingInvestmentVolume',
  MissingNextYearAnnualData: 'MissingNextYearAnnualData',
  MissingNextYearHoldingData: 'MissingNextYearHoldingData',
  MissingOutstandingAmount: 'MissingOutstandingAmount',
  MissingRevenue: 'MissingRevenue',
  MissingTotalBuildingArea: 'MissingTotalBuildingArea',
  OutstandingAmountSignificantPortionOfAssetValue:
    'OutstandingAmountSignificantPortionOfAssetValue',
  Scope2MarketOverrideGreaterThanLocation:
    'Scope2MarketOverrideGreaterThanLocation',
  Scope3TotalOverrideZero: 'Scope3TotalOverrideZero',
  WatershedMeasurementAvailable: 'WatershedMeasurementAvailable',
} as const;

export type GQFinanceDataFlagCheck =
  (typeof GQFinanceDataFlagCheck)[keyof typeof GQFinanceDataFlagCheck];
export const GQFinanceDataFlagObjectKind = {
  Asset: 'Asset',
  AssetCorporate: 'AssetCorporate',
  AssetHolding: 'AssetHolding',
  AssetRealEstate: 'AssetRealEstate',
  AssetYear: 'AssetYear',
  Fund: 'Fund',
} as const;

export type GQFinanceDataFlagObjectKind =
  (typeof GQFinanceDataFlagObjectKind)[keyof typeof GQFinanceDataFlagObjectKind];
export type GQFinanceFootprintSnapshotTableReference = GQBaseTableReference & {
  __typename?: 'FinanceFootprintSnapshotTableReference';
  alias: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQFinanceFundYearOverYearEdge = {
  __typename?: 'FinanceFundYearOverYearEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceFundsYearOverYear = {
  __typename?: 'FinanceFundsYearOverYear';
  edges: Array<Maybe<GQFinanceFundYearOverYearEdge>>;
  pageInfo: GQPageInfo;
};

export const GQFinanceGroupingType = {
  Asset: 'asset',
  Fund: 'fund',
  Holding: 'holding',
} as const;

export type GQFinanceGroupingType =
  (typeof GQFinanceGroupingType)[keyof typeof GQFinanceGroupingType];
export type GQFinanceHeader = {
  __typename?: 'FinanceHeader';
  fundExcluded: Scalars['Boolean']['output'];
  isAssetGroupItem: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export const GQFinanceHighchartsChartKind = {
  BarHorizontal: 'BarHorizontal',
  BarVertical: 'BarVertical',
  Meter: 'Meter',
  Pareto: 'Pareto',
  Pie: 'Pie',
  Treemap: 'Treemap',
} as const;

export type GQFinanceHighchartsChartKind =
  (typeof GQFinanceHighchartsChartKind)[keyof typeof GQFinanceHighchartsChartKind];
export type GQFinanceImport = {
  __typename?: 'FinanceImport';
  accepted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataFormat: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  diff: Maybe<Scalars['UntypedData']['output']>;
  diffFileMetadataId: Maybe<Scalars['ID']['output']>;
  errorMessages: Scalars['UntypedData']['output'];
  fileMetadata: Maybe<GQFileMetadata>;
  fileMetadataId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  rawOutput: Scalars['UntypedData']['output'];
  recordsCreated: Maybe<Scalars['Int']['output']>;
  recordsUnchanged: Maybe<Scalars['Int']['output']>;
  recordsUpdated: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFinanceInputDataFlag = {
  __typename?: 'FinanceInputDataFlag';
  assetCommercialRealEstateId: Maybe<Scalars['ID']['output']>;
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetGroupId: Maybe<Scalars['ID']['output']>;
  assetHoldingId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  assetSovereignBondId: Maybe<Scalars['ID']['output']>;
  assetYearId: Maybe<Scalars['ID']['output']>;
  checkKind: Scalars['String']['output'];
  columns: Array<Scalars['String']['output']>;
  fundId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  rowData: Scalars['UntypedData']['output'];
};

export type GQFinanceInsightsBlock = GQIFinanceViewSection & {
  __typename?: 'FinanceInsightsBlock';
  id: Scalars['ID']['output'];
  insights: Array<GQFinanceInsightsBlock>;
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  renderInCard: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQFinanceMeasurementProgressRow = {
  __typename?: 'FinanceMeasurementProgressRow';
  assetId: Scalars['String']['output'];
  assetName: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  contacts: Maybe<Array<GQSupplierContact>>;
  coverageEndDate: Maybe<Scalars['Date']['output']>;
  coverageStartDate: Maybe<Scalars['Date']['output']>;
  fundIds: Array<Scalars['String']['output']>;
  hasApprovedProgressRequest: Maybe<Scalars['Boolean']['output']>;
  hasRequestedFootprint: Maybe<Scalars['Boolean']['output']>;
  hasRequestedProgress: Maybe<Scalars['Boolean']['output']>;
  measuredFootprintDetails: Maybe<GQMeasuredFootprintDetails>;
  portcoFootprintReviewRequestMetadata: Maybe<
    Array<GQPortcoFootprintReviewRequestMetadata>
  >;
  projectName: Maybe<Scalars['String']['output']>;
  taskStatusDumps: Maybe<Array<GQTaskStatusDump>>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQFinanceMetadata = {
  __typename?: 'FinanceMetadata';
  anyAssetYearHasEstimationMethodology: Scalars['Boolean']['output'];
  hasSomeAssetCorporate: Scalars['Boolean']['output'];
};

export const GQFinanceMetric = {
  AssetCount: 'AssetCount',
  EconomicIntensity: 'EconomicIntensity',
  EconomicIntensityScope1And2: 'EconomicIntensityScope1And2',
  FinancedEmissions: 'FinancedEmissions',
  FinancedEmissionsScope1: 'FinancedEmissionsScope1',
  FinancedEmissionsScope1And2: 'FinancedEmissionsScope1And2',
  FinancedEmissionsScope2: 'FinancedEmissionsScope2',
  FinancedEmissionsScope3: 'FinancedEmissionsScope3',
  HoldingCount: 'HoldingCount',
  IciAlignment: 'IciAlignment',
  Pcaf: 'Pcaf',
  PcafScope1: 'PcafScope1',
  PcafScope2: 'PcafScope2',
  PcafScope3: 'PcafScope3',
  RevenueIntensity: 'RevenueIntensity',
  RevenueIntensityScope1: 'RevenueIntensityScope1',
  RevenueIntensityScope2: 'RevenueIntensityScope2',
  RevenueIntensityScope3: 'RevenueIntensityScope3',
  TotalEmissions: 'TotalEmissions',
  TotalEmissionsScope1: 'TotalEmissionsScope1',
  TotalEmissionsScope2: 'TotalEmissionsScope2',
  TotalEmissionsScope3: 'TotalEmissionsScope3',
  TotalOutstandingAmount: 'TotalOutstandingAmount',
  Waci: 'Waci',
  WaciScope1: 'WaciScope1',
  WaciScope2: 'WaciScope2',
  WaciScope3: 'WaciScope3',
} as const;

export type GQFinanceMetric =
  (typeof GQFinanceMetric)[keyof typeof GQFinanceMetric];
export type GQFinanceMetricBar = {
  __typename?: 'FinanceMetricBar';
  emptyMetrics: Array<GQFinanceAnyMetric>;
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  metrics: Array<GQFinanceAnyMetric>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQFinanceModelCardMetadataPayload = {
  __typename?: 'FinanceModelCardMetadataPayload';
  id: Scalars['ID']['output'];
  numAnnualData: Scalars['Int']['output'];
  numAssets: Scalars['Int']['output'];
  numContacts: Scalars['Int']['output'];
  numFunds: Scalars['Int']['output'];
  numHoldings: Scalars['Int']['output'];
};

export const GQFinanceReminderType = {
  FootprintFinalization: 'FootprintFinalization',
  MeasurementProject: 'MeasurementProject',
} as const;

export type GQFinanceReminderType =
  (typeof GQFinanceReminderType)[keyof typeof GQFinanceReminderType];
export type GQFinanceSavedView = {
  __typename?: 'FinanceSavedView';
  charts: Maybe<Array<Scalars['String']['output']>>;
  filters: Maybe<Scalars['UntypedData']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  fundId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  layout: Maybe<Scalars['String']['output']>;
  metrics: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  /** @deprecated Use charts length instead */
  showInsights: Scalars['Boolean']['output'];
  /** @deprecated Use metrics length instead */
  showMetrics: Scalars['Boolean']['output'];
  standardView: Maybe<GQFinanceStandardView>;
};

export type GQFinanceSectorEmissions = {
  __typename?: 'FinanceSectorEmissions';
  absoluteFinancedEmissionsTco2e: Maybe<Scalars['Int']['output']>;
  holdingNames: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GQFinanceSettings = {
  __typename?: 'FinanceSettings';
  disabledFinanceDataFlags: Array<Scalars['String']['output']>;
  estimateDownstreamScope3: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type GQFinanceShowYourWorkData = {
  __typename?: 'FinanceShowYourWorkData';
  aggregateRows: Maybe<Scalars['UntypedData']['output']>;
  rowsWithOnlyFinancedEmissions: Maybe<Scalars['UntypedData']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshot = {
  __typename?: 'FinanceSnapshot';
  completedAt: Maybe<Scalars['DateTime']['output']>;
  config: Maybe<Scalars['UntypedData']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  executionStatus: GQFinanceSnapshotExecutionStatus;
  executionStatusMessage: Scalars['String']['output'];
  flagsPopulated: Maybe<Scalars['Boolean']['output']>;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintSnapshotId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isStale: Maybe<Scalars['Boolean']['output']>;
  note: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  snapshotName: Maybe<Scalars['String']['output']>;
};

export type GQFinanceSnapshotAsset = {
  __typename?: 'FinanceSnapshotAsset';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetEdge = {
  __typename?: 'FinanceSnapshotAssetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompany';
  asset: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssetGroupedByCompanyEdge = {
  __typename?: 'FinanceSnapshotAssetGroupedByCompanyEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotAssets = {
  __typename?: 'FinanceSnapshotAssets';
  edges: Array<Maybe<GQFinanceSnapshotAssetEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotAssetsGroupedByCompany = {
  __typename?: 'FinanceSnapshotAssetsGroupedByCompany';
  edges: Array<Maybe<GQFinanceSnapshotAssetGroupedByCompanyEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotConfigV1Input = {
  estimateDownstreamScope3: Scalars['Boolean']['input'];
  estimationMethodologyOverride: GQFinanceSnapshotMethodology;
  version: Scalars['Int']['input'];
};

export const GQFinanceSnapshotExecutionStatus = {
  Error: 'ERROR',
  Processing: 'PROCESSING',
  Success: 'SUCCESS',
} as const;

export type GQFinanceSnapshotExecutionStatus =
  (typeof GQFinanceSnapshotExecutionStatus)[keyof typeof GQFinanceSnapshotExecutionStatus];
export type GQFinanceSnapshotFund = {
  __typename?: 'FinanceSnapshotFund';
  fund: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFundEdge = {
  __typename?: 'FinanceSnapshotFundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotFunds = {
  __typename?: 'FinanceSnapshotFunds';
  edges: Array<Maybe<GQFinanceSnapshotFundEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export type GQFinanceSnapshotHoldingEdge = {
  __typename?: 'FinanceSnapshotHoldingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFinanceSnapshotHoldings = {
  __typename?: 'FinanceSnapshotHoldings';
  edges: Array<Maybe<GQFinanceSnapshotHoldingEdge>>;
  id: Scalars['ID']['output'];
  pageInfo: GQPageInfo;
};

export const GQFinanceSnapshotMethodology = {
  Ceda: 'CEDA',
  CedaV5: 'CEDA_V5',
  CedaV7: 'CEDA_V7',
  Useeio: 'USEEIO',
  Useeio_2_1: 'USEEIO_2_1',
  Useeio_2_2_22: 'USEEIO_2_2_22',
  Custom: 'custom',
} as const;

export type GQFinanceSnapshotMethodology =
  (typeof GQFinanceSnapshotMethodology)[keyof typeof GQFinanceSnapshotMethodology];
export type GQFinanceSnapshotTag = {
  __typename?: 'FinanceSnapshotTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  description: Maybe<Scalars['String']['output']>;
  financeTag: GQFinanceTag;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tagType: GQFinanceTagType;
};

export const GQFinanceStandardView = {
  Asset: 'Asset',
  Fund: 'Fund',
  FundCategory: 'FundCategory',
  FundGroup: 'FundGroup',
  Overview: 'Overview',
  YearOverYear: 'YearOverYear',
} as const;

export type GQFinanceStandardView =
  (typeof GQFinanceStandardView)[keyof typeof GQFinanceStandardView];
export type GQFinanceStringMetric = {
  __typename?: 'FinanceStringMetric';
  additionalInfo: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isMissingInputs: Scalars['Boolean']['output'];
  metric: GQFinanceMetric;
  name: Scalars['String']['output'];
  unit: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQFinanceTag = {
  __typename?: 'FinanceTag';
  columnConfiguration: Maybe<Scalars['UntypedData']['output']>;
  columnFormat: GQGridColumnFormat;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  tagCategory: Maybe<Scalars['String']['output']>;
  tagType: GQFinanceTagType;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: Maybe<Scalars['String']['output']>;
};

export const GQFinanceTagType = {
  AssetTag: 'AssetTag',
  AssetYearTag: 'AssetYearTag',
  FundTag: 'FundTag',
  HoldingTag: 'HoldingTag',
} as const;

export type GQFinanceTagType =
  (typeof GQFinanceTagType)[keyof typeof GQFinanceTagType];
export type GQFinanceView = {
  __typename?: 'FinanceView';
  charts: Maybe<Array<GQFinanceChart>>;
  gridWorksheet: Maybe<GQFinanceWorksheet>;
  hasHoldings: Scalars['Boolean']['output'];
  header: GQFinanceHeader;
  metricBar: Maybe<GQFinanceMetricBar>;
  savedView: GQFinanceSavedView;
  standardView: GQFinanceStandardView;
};

export const GQFinanceViewSidebar = {
  AssetSidebar: 'AssetSidebar',
} as const;

export type GQFinanceViewSidebar =
  (typeof GQFinanceViewSidebar)[keyof typeof GQFinanceViewSidebar];
export const GQFinanceWaterfallChartType = {
  ExistingInvestments: 'ExistingInvestments',
  FinancedEmissions: 'FinancedEmissions',
  TotalEmissions: 'TotalEmissions',
} as const;

export type GQFinanceWaterfallChartType =
  (typeof GQFinanceWaterfallChartType)[keyof typeof GQFinanceWaterfallChartType];
export type GQFinanceWorksheet = GQIdInterface & {
  __typename?: 'FinanceWorksheet';
  createdAt: Maybe<Scalars['DateTime']['output']>;
  hiddenColumns: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  orgColumns: Array<GQFinanceColumn>;
  sortModel: Array<GQDataGridSortItem>;
  standardView: Maybe<GQFinanceStandardView>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQFinanceWorksheetColumn = {
  __typename?: 'FinanceWorksheetColumn';
  columnConfiguration: Maybe<
    GQGridColumnConfigurationMultiSelect | GQGridColumnConfigurationSingleSelect
  >;
  columnFormat: GQGridColumnFormat;
  description: Scalars['String']['output'];
  field: Scalars['String']['output'];
  financeWorksheetId: Maybe<Scalars['ID']['output']>;
  headerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  width: Maybe<Scalars['Int']['output']>;
};

export type GQFinancialMappingGroupHistory = {
  __typename?: 'FinancialMappingGroupHistory';
  id: Scalars['ID']['output'];
  json: Scalars['JSONString']['output'];
};

export type GQFinancialsAccount = {
  __typename?: 'FinancialsAccount';
  accountId: Scalars['String']['output'];
  description: Maybe<Scalars['String']['output']>;
  displayAccountId: Scalars['String']['output'];
};

export type GQFinancialsReviewGroup = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewGroup';
    datasources: Array<GQDatasource>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export type GQFinancialsReviewItem = GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'FinancialsReviewItem';
    account: GQFinancialsAccount;
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    askingEmployee: Maybe<GQWatershedEmployee>;
    createdAt: Scalars['DateTime']['output'];
    discussion: Maybe<GQDataIssue>;
    exclusionReason: Maybe<Scalars['String']['output']>;
    group: GQFinancialsReviewGroup;
    id: Scalars['ID']['output'];
    initialIndustryClassification: Maybe<GQIndustryClassification>;
    initiallyIncluded: Maybe<Scalars['Boolean']['output']>;
    kind: GQFinancialsReviewItemKind;
    name: Scalars['String']['output'];
    prompt: Maybe<Scalars['String']['output']>;
    respondingUser: Maybe<GQUser>;
    status: GQFinancialsReviewItemStatus;
    userResponse: Maybe<Scalars['String']['output']>;
  };

export type GQFinancialsReviewItemConnection = {
  __typename?: 'FinancialsReviewItemConnection';
  edges: Array<Maybe<GQFinancialsReviewItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFinancialsReviewItemEdge = {
  __typename?: 'FinancialsReviewItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFinancialsReviewItem>;
};

export const GQFinancialsReviewItemKind = {
  FreeText: 'FreeText',
  YesNo: 'YesNo',
} as const;

export type GQFinancialsReviewItemKind =
  (typeof GQFinancialsReviewItemKind)[keyof typeof GQFinancialsReviewItemKind];
export const GQFinancialsReviewItemStatus = {
  UserApproved: 'UserApproved',
  UserRejected: 'UserRejected',
  UserReplied: 'UserReplied',
  WatershedActioned: 'WatershedActioned',
  WatershedDraft: 'WatershedDraft',
  WatershedSubmitted: 'WatershedSubmitted',
} as const;

export type GQFinancialsReviewItemStatus =
  (typeof GQFinancialsReviewItemStatus)[keyof typeof GQFinancialsReviewItemStatus];
export type GQFingerprintLineageGraphPayload = {
  __typename?: 'FingerprintLineageGraphPayload';
  serializedGraph: Maybe<Scalars['UntypedData']['output']>;
};

export type GQFingerprintTransformRunGraphResult = {
  __typename?: 'FingerprintTransformRunGraphResult';
  bartInstancesToRowIds: Array<GQBartInstancesToRowIds>;
  extBartInstancesToRowIds: Array<GQBartInstancesToRowIds>;
  fingerprint: Scalars['String']['output'];
  transformRunGraphs: Array<GQTransformRunGraph>;
};

export const GQFlags = {
  ActivityDataAllActivityButton: 'ActivityDataAllActivityButton',
  ActivityDataListShowAllSchemas: 'ActivityDataListShowAllSchemas',
  ActivityDataMultipleMeasures: 'ActivityDataMultipleMeasures',
  ActivityDataNumericCustomColumns: 'ActivityDataNumericCustomColumns',
  AlwaysAllowTcfdQualitative: 'AlwaysAllowTcfdQualitative',
  ApiUploadDataRateLimiter: 'ApiUploadDataRateLimiter',
  ApiUploadDataRateLimiterLarge: 'ApiUploadDataRateLimiterLarge',
  ArchiveFootprints: 'ArchiveFootprints',
  AssetManagerAlternateAssetCorporateDownload:
    'AssetManagerAlternateAssetCorporateDownload',
  AssetManagerAlternateAssetYearDownload:
    'AssetManagerAlternateAssetYearDownload',
  AssetManagerAlternateDataManagementDownload:
    'AssetManagerAlternateDataManagementDownload',
  AssetManagerAutoMapping: 'AssetManagerAutoMapping',
  AssetManagerConfigureDataQualityFlags:
    'AssetManagerConfigureDataQualityFlags',
  AssetManagerCurrencyDisplay: 'AssetManagerCurrencyDisplay',
  AssetManagerDataExplorerSyw: 'AssetManagerDataExplorerSyw',
  AssetManagerDataFlagsFromInputModels: 'AssetManagerDataFlagsFromInputModels',
  AssetManagerDisableAuditReports: 'AssetManagerDisableAuditReports',
  AssetManagerDisableSnapshotCreation: 'AssetManagerDisableSnapshotCreation',
  AssetManagerDueDiligence: 'AssetManagerDueDiligence',
  AssetManagerEnableAuditReports: 'AssetManagerEnableAuditReports',
  AssetManagerEstimationMethodologyVersioning:
    'AssetManagerEstimationMethodologyVersioning',
  AssetManagerGroupByCompanyName: 'AssetManagerGroupByCompanyName',
  AssetManagerHistoricalAuditReports: 'AssetManagerHistoricalAuditReports',
  AssetManagerIncludeZeroFinancedEmissions:
    'AssetManagerIncludeZeroFinancedEmissions',
  AssetManagerMortgagesHoldingType: 'AssetManagerMortgagesHoldingType',
  AssetManagerNegativeOutstandingAmount:
    'AssetManagerNegativeOutstandingAmount',
  AssetManagerPcafScore5ValueBasedEstimation:
    'AssetManagerPcafScore5ValueBasedEstimation',
  AssetManagerProjectFinanceAssetClass: 'AssetManagerProjectFinanceAssetClass',
  AssetManagerRecordAttributionFactorOverride:
    'AssetManagerRecordAttributionFactorOverride',
  AssetManagerRenameFunds: 'AssetManagerRenameFunds',
  AssetManagerScope2Subscopes: 'AssetManagerScope2Subscopes',
  AssetManagerSidebarCanCollapse: 'AssetManagerSidebarCanCollapse',
  AssetManagerSkipFlagCreation: 'AssetManagerSkipFlagCreation',
  AssetManagerSkipLogEventCreation: 'AssetManagerSkipLogEventCreation',
  AssetManagerSkipOrgCheckDuringCompanyMatch:
    'AssetManagerSkipOrgCheckDuringCompanyMatch',
  AssetManagerSkipSAndPbqSync: 'AssetManagerSkipSAndPBQSync',
  AssetManagerSkipSywFootprintSnapshot: 'AssetManagerSkipSywFootprintSnapshot',
  AssetManagerSnapshotDeletion: 'AssetManagerSnapshotDeletion',
  AssetManagerUseBiV2ForSnapshotGrid: 'AssetManagerUseBiV2ForSnapshotGrid',
  AssetManagerUseSAndPData: 'AssetManagerUseSAndPData',
  AssetManagerUseSAndPIndustryCodes: 'AssetManagerUseSAndPIndustryCodes',
  AssetManagerViewEdciFieldsPreset: 'AssetManagerViewEdciFieldsPreset',
  AssetManagerWriteIndustrySectorCodeToBart:
    'AssetManagerWriteIndustrySectorCodeToBart',
  AssetManagerYearOverYearComparison: 'AssetManagerYearOverYearComparison',
  AutoRunFootprintOnUserUpload: 'AutoRunFootprintOnUserUpload',
  BenchmarkRevenueAtRisk: 'BenchmarkRevenueAtRisk',
  BiDrilldownAiQuerying: 'BiDrilldownAiQuerying',
  BiDrilldownFacilitiesMeasures: 'BiDrilldownFacilitiesMeasures',
  BiDrilldownMultipleMeasures: 'BiDrilldownMultipleMeasures',
  BiDrilldownSupplierMeasures: 'BiDrilldownSupplierMeasures',
  BiEnableMetricDescriptions: 'BiEnableMetricDescriptions',
  BiReadFromActivityDataset: 'BiReadFromActivityDataset',
  BiV2DarkQuery: 'BiV2DarkQuery',
  BiV2FrontEndFormulation: 'BiV2FrontEndFormulation',
  BiV2Query: 'BiV2Query',
  BigRocksUiFinance: 'BigRocksUiFinance',
  BulkUploadTaskManagement: 'BulkUploadTaskManagement',
  BumpCleanPowerLimits: 'BumpCleanPowerLimits',
  ByPassChonkerChecker: 'ByPassChonkerChecker',
  CalculationEmEvalRefactorCut: 'CalculationEmEvalRefactorCut',
  CalculationEmEvalRefactorParity: 'CalculationEmEvalRefactorParity',
  CalculationEnableAlphaReleases: 'CalculationEnableAlphaReleases',
  CalculationEnablePipelinesPrototype: 'CalculationEnablePipelinesPrototype',
  CalculationKeepZerosInPipelineResults:
    'CalculationKeepZerosInPipelineResults',
  CalculationMethodologyCustomizationPage:
    'CalculationMethodologyCustomizationPage',
  CalculationMethodologyInProductPage: 'CalculationMethodologyInProductPage',
  CompleteFiscalYearInOverview: 'CompleteFiscalYearInOverview',
  ConsumerGoodsApparelNewMethodologies: 'ConsumerGoodsApparelNewMethodologies',
  ContractOverrideUpsellBenchmarks: 'ContractOverrideUpsellBenchmarks',
  CsrdAvoidedCleanPower: 'CsrdAvoidedCleanPower',
  CsrdDataRequirementsValidation: 'CsrdDataRequirementsValidation',
  CsrdEnableAiExtractedAnswers: 'CsrdEnableAiExtractedAnswers',
  CsrdEnableReportQuestionApprovals: 'CsrdEnableReportQuestionApprovals',
  DangerousDoNotUseIntentionallyBreakThings:
    'DangerousDoNotUseIntentionallyBreakThings',
  DashboardCleanPowerAdvancedEstimation:
    'DashboardCleanPowerAdvancedEstimation',
  DataInfraBumpMaxExpressionDepthFootAssembly:
    'DataInfraBumpMaxExpressionDepthFootAssembly',
  DataInfraCreateTempTableInCleanPower: 'DataInfraCreateTempTableInCleanPower',
  DataInfraMaterializeTableInTraceAddendum:
    'DataInfraMaterializeTableInTraceAddendum',
  DataInfraOnlyUseDuckDbTestExtraLargeServer:
    'DataInfraOnlyUseDuckDbTestExtraLargeServer',
  DataInfraOnlyUseDuckDbTestServersForResourceEstimation:
    'DataInfraOnlyUseDuckDbTestServersForResourceEstimation',
  DataInfraUseDuckDbResourceEstimation: 'DataInfraUseDuckDbResourceEstimation',
  DataIngestionAllowUploadsOnActivityDataPage:
    'DataIngestionAllowUploadsOnActivityDataPage',
  DataIngestionApiDataRegistry: 'DataIngestionApiDataRegistry',
  DataIngestionColocatedDataCentersInFacilities:
    'DataIngestionColocatedDataCentersInFacilities',
  DataIngestionCsrdApprovalFlow: 'DataIngestionCsrdApprovalFlow',
  DataIngestionDataAuditTransformation: 'DataIngestionDataAuditTransformation',
  DataIngestionDatasourceFilesRedesign: 'DataIngestionDatasourceFilesRedesign',
  DataIngestionDirectEntry: 'DataIngestionDirectEntry',
  DataIngestionDisableExportFacilitiesDocs:
    'DataIngestionDisableExportFacilitiesDocs',
  DataIngestionDisableOneSchemaAi: 'DataIngestionDisableOneSchemaAi',
  DataIngestionDisableValueMappingTaskAssignment:
    'DataIngestionDisableValueMappingTaskAssignment',
  DataIngestionEditablePowerUsageEffectiveness:
    'DataIngestionEditablePowerUsageEffectiveness',
  DataIngestionFacilitiesApprovals: 'DataIngestionFacilitiesApprovals',
  DataIngestionFacilitiesExtraLargeMode:
    'DataIngestionFacilitiesExtraLargeMode',
  DataIngestionFacilitiesTracker: 'DataIngestionFacilitiesTracker',
  DataIngestionFacilitiesUseLoader: 'DataIngestionFacilitiesUseLoader',
  DataIngestionFilefeeds: 'DataIngestionFilefeeds',
  DataIngestionGlobalFacilitiesPage: 'DataIngestionGlobalFacilitiesPage',
  DataIngestionImporterV3: 'DataIngestionImporterV3',
  DataIngestionOnlyRegenerateStaleFacilitiesBarts:
    'DataIngestionOnlyRegenerateStaleFacilitiesBarts',
  DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen:
    'DataIngestionSkipFacilitiesBartsAutoRefreshInFootprintGen',
  DataIngestionSkipSendingApprovalFlowEmails:
    'DataIngestionSkipSendingApprovalFlowEmails',
  DataIngestionSnowfall: 'DataIngestionSnowfall',
  DataIngestionTestCts: 'DataIngestionTestCts',
  DataIngestionUpgradeImporterV1: 'DataIngestionUpgradeImporterV1',
  DataIngestionUserUploadEdit: 'DataIngestionUserUploadEdit',
  DataIngestionValueMappingDisableVendorPreviews:
    'DataIngestionValueMappingDisableVendorPreviews',
  DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission:
    'DataIngestionValueMappingForCustomersBetaDoNotUseWithoutPermission',
  DataIngestionValueMappingsVendorRowsExpanded:
    'DataIngestionValueMappingsVendorRowsExpanded',
  DataLineageFeedback: 'DataLineageFeedback',
  DataLineageV2: 'DataLineageV2',
  DefaultDrilldownChartsClosed: 'DefaultDrilldownChartsClosed',
  DemoPlatformEnableForRestrictedOrg: 'DemoPlatformEnableForRestrictedOrg',
  DisableContractorAccess: 'DisableContractorAccess',
  DisableDataGovernancePage: 'DisableDataGovernancePage',
  DisableFacilitiesGeocoding: 'DisableFacilitiesGeocoding',
  DisableFinancialMappingAiSuggestions: 'DisableFinancialMappingAiSuggestions',
  DisableFootprintGeneration: 'DisableFootprintGeneration',
  DisableSalesforceSyncSupportPermissions:
    'DisableSalesforceSyncSupportPermissions',
  DustBotInProductPrototype: 'DustBotInProductPrototype',
  EditFootprintAssemblyJsonConfig: 'EditFootprintAssemblyJsonConfig',
  EmissionsModelMinimizeVariableNameInSql:
    'EmissionsModelMinimizeVariableNameInSql',
  EnableCompanyMergingAdmin: 'EnableCompanyMergingAdmin',
  EnableEditProductRulesAlways: 'EnableEditProductRulesAlways',
  EnableFacilitiesWasteForGhg: 'EnableFacilitiesWasteForGhg',
  EnableFacilitiesWaterForGhg: 'EnableFacilitiesWaterForGhg',
  EnableGeneralAnomalyDetection: 'EnableGeneralAnomalyDetection',
  EnablePreSetCustomTagsForBuildings: 'EnablePreSetCustomTagsForBuildings',
  EnablePublishFileUploadFacilities: 'EnablePublishFileUploadFacilities',
  EnableUserUploadCountsOnMeasurementPage:
    'EnableUserUploadCountsOnMeasurementPage',
  EnableUtilityFileScanning: 'EnableUtilityFileScanning',
  EntFoundAccessSecuritySettings: 'EntFoundAccessSecuritySettings',
  EntFoundDirectorySync: 'EntFoundDirectorySync',
  EntFoundDirectorySyncWriteChanges: 'EntFoundDirectorySyncWriteChanges',
  EntFoundDisableRouteAccessRequestsToOpal:
    'EntFoundDisableRouteAccessRequestsToOpal',
  EntFoundEmailRestrictions: 'EntFoundEmailRestrictions',
  EntFoundGateAdminAccess: 'EntFoundGateAdminAccess',
  EntFoundIdleLogout: 'EntFoundIdleLogout',
  EntFoundSettingsV2: 'EntFoundSettingsV2',
  EntFoundSkilljarLinks: 'EntFoundSkilljarLinks',
  EntFoundTempApiSecretCompareToHash: 'EntFoundTempApiSecretCompareToHash',
  FootprintAuditBundleRowLimit: 'FootprintAuditBundleRowLimit',
  FootprintBenchmarksForNonCorporate: 'FootprintBenchmarksForNonCorporate',
  FootprintGenerationAllowPreEmissionsModelGaActivityData:
    'FootprintGenerationAllowPreEmissionsModelGaActivityData',
  FootprintProductLevelEmissions: 'FootprintProductLevelEmissions',
  FootprintShowProductModels: 'FootprintShowProductModels',
  FootprintSuppliersPage: 'FootprintSuppliersPage',
  GranularFootprintPermissions: 'GranularFootprintPermissions',
  IgnoreFootprintRowLimit: 'IgnoreFootprintRowLimit',
  LearningHubReplaceUrls: 'LearningHubReplaceUrls',
  LimitLargeGroupDataLineage: 'LimitLargeGroupDataLineage',
  LocalizationDynamicStrings: 'LocalizationDynamicStrings',
  LogAuditEventsToWorkOs: 'LogAuditEventsToWorkOs',
  MeasurementIntegrationsArcadia: 'MeasurementIntegrationsArcadia',
  MeasurementIntegrationsMergeFinancials:
    'MeasurementIntegrationsMergeFinancials',
  MeasurementIntegrationsNetSuite: 'MeasurementIntegrationsNetSuite',
  MeasurementIntegrationsQuickBooks: 'MeasurementIntegrationsQuickBooks',
  MeasurementIntegrationsUrjanet: 'MeasurementIntegrationsUrjanet',
  MeasurementProjectDateEditingInAdmin: 'MeasurementProjectDateEditingInAdmin',
  MultipleDatasourcesPerAdt: 'MultipleDatasourcesPerAdt',
  OrgStructure: 'OrgStructure',
  OrgStructureAutofillingEnabled: 'OrgStructureAutofillingEnabled',
  OrgStructureChangelog: 'OrgStructureChangelog',
  OrgStructureMultipleVersions: 'OrgStructureMultipleVersions',
  OrganizationDestroyLogEvents: 'OrganizationDestroyLogEvents',
  OrganizationDestroyWithFkDependencies:
    'OrganizationDestroyWithFkDependencies',
  OverrideBlockedPipelineV2Dialog: 'OverrideBlockedPipelineV2Dialog',
  PricingFy25BlockAllScope3Temp: 'PricingFy25BlockAllScope3Temp',
  PricingFy25BlockScope1And2Temp: 'PricingFy25BlockScope1And2Temp',
  PricingFy25BlockScope3_1Temp: 'PricingFy25BlockScope3_1Temp',
  PricingFy25BlockScope3_2Temp: 'PricingFy25BlockScope3_2Temp',
  PricingFy25BlockScope3_3Temp: 'PricingFy25BlockScope3_3Temp',
  PricingFy25BlockScope3_4Temp: 'PricingFy25BlockScope3_4Temp',
  PricingFy25BlockScope3_5Temp: 'PricingFy25BlockScope3_5Temp',
  PricingFy25BlockScope3_6Temp: 'PricingFy25BlockScope3_6Temp',
  PricingFy25BlockScope3_7Temp: 'PricingFy25BlockScope3_7Temp',
  PricingFy25BlockScope3_8Temp: 'PricingFy25BlockScope3_8Temp',
  PricingFy25BlockScope3_9Temp: 'PricingFy25BlockScope3_9Temp',
  PricingFy25BlockScope3_10Temp: 'PricingFy25BlockScope3_10Temp',
  PricingFy25BlockScope3_11Temp: 'PricingFy25BlockScope3_11Temp',
  PricingFy25BlockScope3_12Temp: 'PricingFy25BlockScope3_12Temp',
  PricingFy25BlockScope3_13Temp: 'PricingFy25BlockScope3_13Temp',
  PricingFy25BlockScope3_14Temp: 'PricingFy25BlockScope3_14Temp',
  PricingFy25CsrdE1ModuleTemp: 'PricingFy25CsrdE1ModuleTemp',
  PricingFy25CsrdE2ModuleTemp: 'PricingFy25CsrdE2ModuleTemp',
  PricingFy25CsrdE3ModuleTemp: 'PricingFy25CsrdE3ModuleTemp',
  PricingFy25CsrdE4ModuleTemp: 'PricingFy25CsrdE4ModuleTemp',
  PricingFy25CsrdE5ModuleTemp: 'PricingFy25CsrdE5ModuleTemp',
  PricingFy25CsrdReportBuilderModuleTemp:
    'PricingFy25CsrdReportBuilderModuleTemp',
  PricingFy25CsrdSocialAndGovernanceModuleTemp:
    'PricingFy25CsrdSocialAndGovernanceModuleTemp',
  PricingFy25ReductionsModuleTemp: 'PricingFy25ReductionsModuleTemp',
  PricingFy25ReportingModuleTemp: 'PricingFy25ReportingModuleTemp',
  ProductRulesContainsOperator: 'ProductRulesContainsOperator',
  ProductionGraphCompareMaterials: 'ProductionGraphCompareMaterials',
  ProductionGraphPurchaseOrdersDataset: 'ProductionGraphPurchaseOrdersDataset',
  RebuildValueMappingContextOnDuckDbExtraLarge:
    'RebuildValueMappingContextOnDuckDbExtraLarge',
  ReduxContributionsIntervalOptimization:
    'ReduxContributionsIntervalOptimization',
  ReduxDisableCustomTagsInContributions:
    'ReduxDisableCustomTagsInContributions',
  ReduxExperimentalCalculations: 'ReduxExperimentalCalculations',
  ReduxSupplierEngagementBySpend: 'ReduxSupplierEngagementBySpend',
  ReduxUseLocationBasedEmissions: 'ReduxUseLocationBasedEmissions',
  ReportAnswerPermissions: 'ReportAnswerPermissions',
  ReportPermissionManagement: 'ReportPermissionManagement',
  ReportingCdpAlwaysOverwriteConflicts: 'ReportingCdpAlwaysOverwriteConflicts',
  ReportingEnableCdpApi: 'ReportingEnableCdpApi',
  ReportingEnableCdpApiUnscoredDeadline:
    'ReportingEnableCdpApiUnscoredDeadline',
  ReportingEnableNewClimateIntelligencePanel:
    'ReportingEnableNewClimateIntelligencePanel',
  ReportingFreezeConfigEdits: 'ReportingFreezeConfigEdits',
  ReportingIgnoreCdpPlanRestrictions: 'ReportingIgnoreCdpPlanRestrictions',
  ReportingShowCdpApiIsDownWarnings: 'ReportingShowCdpApiIsDownWarnings',
  ReportingShowRegulatoryCheckup: 'ReportingShowRegulatoryCheckup',
  ReportingTextInputRichText: 'ReportingTextInputRichText',
  ReportingUseBiV2: 'ReportingUseBiV2',
  ReportingUseTitleSubtitleMigratedColumns:
    'ReportingUseTitleSubtitleMigratedColumns',
  SendEmailsOnDemoOrTestOrg: 'SendEmailsOnDemoOrTestOrg',
  ShouldAllowPipelineResultFootprintKind:
    'ShouldAllowPipelineResultFootprintKind',
  ShouldUseExtraLargeDuckDbServer: 'ShouldUseExtraLargeDuckDbServer',
  ShouldUseTestDuckDbServer: 'ShouldUseTestDuckDbServer',
  ShouldUseTestTemporalTaskQueue: 'ShouldUseTestTemporalTaskQueue',
  ShowAllLanguages: 'ShowAllLanguages',
  ShowTopSuppliersTableInFootprintOverview:
    'ShowTopSuppliersTableInFootprintOverview',
  ShowYourWorkNumeratorAndDenominator: 'ShowYourWorkNumeratorAndDenominator',
  ShowYourWorkTotalRow: 'ShowYourWorkTotalRow',
  SupplierEngagementSurveys: 'SupplierEngagementSurveys',
  SupplierExpenseCategories: 'SupplierExpenseCategories',
  SupplierFootprintUncollapse: 'SupplierFootprintUncollapse',
  SupplyChainAdminEditCompany: 'SupplyChainAdminEditCompany',
  SupplyChainAutoMap10k: 'SupplyChainAutoMap10k',
  SupplyChainAutoMappingDuckDbPagination:
    'SupplyChainAutoMappingDuckDbPagination',
  SupplyChainBulkImportContacts: 'SupplyChainBulkImportContacts',
  SupplyChainBulkSurveyResponseExport: 'SupplyChainBulkSurveyResponseExport',
  SupplyChainChartsConfigAdvancedControls:
    'SupplyChainChartsConfigAdvancedControls',
  SupplyChainCleanPowerQuoteEnabled: 'SupplyChainCleanPowerQuoteEnabled',
  SupplyChainCustomSupplierConfig: 'SupplyChainCustomSupplierConfig',
  SupplyChainDisableAutoMappingButton: 'SupplyChainDisableAutoMappingButton',
  SupplyChainDisableLearningTasks: 'SupplyChainDisableLearningTasks',
  SupplyChainEmissionsExport: 'SupplyChainEmissionsExport',
  SupplyChainEnableDefaultMappingCompanySearch:
    'SupplyChainEnableDefaultMappingCompanySearch',
  SupplyChainExperimentalCache: 'SupplyChainExperimentalCache',
  SupplyChainFilterIngestionToHighCdpDisclosureQuality:
    'SupplyChainFilterIngestionToHighCdpDisclosureQuality',
  SupplyChainGeneralMillsCompanyEngagementEmail:
    'SupplyChainGeneralMillsCompanyEngagementEmail',
  SupplyChainHideArchivedConfigsFromCharts:
    'SupplyChainHideArchivedConfigsFromCharts',
  SupplyChainHideNetworkTasksNavItem: 'SupplyChainHideNetworkTasksNavItem',
  SupplyChainManualTargets: 'SupplyChainManualTargets',
  SupplyChainManualTargetsUpdatedDesign:
    'SupplyChainManualTargetsUpdatedDesign',
  SupplyChainPaginateCompanies: 'SupplyChainPaginateCompanies',
  SupplyChainReopenApprovedTask: 'SupplyChainReopenApprovedTask',
  SupplyChainShowHiddenCompaniesInIngestionSearch:
    'SupplyChainShowHiddenCompaniesInIngestionSearch',
  SupplyChainTargetsInitiativesExport: 'SupplyChainTargetsInitiativesExport',
  SupplyChainTargetsVsActual: 'SupplyChainTargetsVsActual',
  SupplyChainUseCacheArtifactV2: 'SupplyChainUseCacheArtifactV2',
  SupplyChainUseNewRefDataStructure: 'SupplyChainUseNewRefDataStructure',
  SupplyChainUseSAndPDataFromParquet: 'SupplyChainUseSAndPDataFromParquet',
  TempIndividualWritesForMappings: 'TempIndividualWritesForMappings',
  TransformRunWrite: 'TransformRunWrite',
  TurnOffCdpUpdateFeatureOnboardingDialog:
    'TurnOffCdpUpdateFeatureOnboardingDialog',
  Unknown: 'Unknown',
  UseBartForCustomIntensity: 'UseBartForCustomIntensity',
  UseBartForHeadcountIntensity: 'UseBartForHeadcountIntensity',
  UseBartForMonetaryIntensity: 'UseBartForMonetaryIntensity',
  UseDedicatedTaskQueueForTraceAddendum:
    'UseDedicatedTaskQueueForTraceAddendum',
  UseDirectlyAssignedPermissions: 'UseDirectlyAssignedPermissions',
  UseV2SyncPgToParquet: 'UseV2SyncPgToParquet',
  UserUploadLimit: 'UserUploadLimit',
  ValueMappingSkipSeedingOnUpload: 'ValueMappingSkipSeedingOnUpload',
} as const;

export type GQFlags = (typeof GQFlags)[keyof typeof GQFlags];
export type GQFootprint = GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Footprint';
    canApprove: Scalars['Boolean']['output'];
    canEdit: Scalars['Boolean']['output'];
    createdAt: Scalars['DateTime']['output'];
    createdBy: Maybe<GQUser>;
    defaultFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    footprintSnapshots: Array<GQFootprintSnapshot>;
    id: Scalars['ID']['output'];
    isGenerating: Scalars['Boolean']['output'];
    latestDraftFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    latestFootprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
    latestFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    latestPublishedFootprintSnapshot: Maybe<GQFootprintSnapshot>;
    locked: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    nextFootprintVersionIdentifier: Scalars['String']['output'];
    orgId: Scalars['ID']['output'];
    status: GQFootprintStatus;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQFootprintFootprintSnapshotsArgs = {
  includeNonUserVisible: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysis = {
  __typename?: 'FootprintAnalysis';
  customFields: Array<Scalars['String']['output']>;
  /** @deprecated Clean power page is deprecated */
  energyConsumptionBreakdown: GQEnergyConsumptionBreakdown;
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKinds: Array<Scalars['String']['output']>;
  footprintSnapshot: Maybe<GQFootprintSnapshot>;
  id: Maybe<Scalars['String']['output']>;
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
  reductionsContributionsData: GQFootprintContributionsData;
  snapshotFootprintKindsMeta: Maybe<Array<GQSnapshotFootprintKindMeta>>;
  /** @deprecated Use FootprintSummary instead */
  timeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  topVendors: GQFootprintAnalysisTopVendors;
  totalSpendUsd: Scalars['Float']['output'];
  workivaContributions: GQWorkivaContributionsPayload;
};

export type GQFootprintAnalysisEnergyConsumptionBreakdownArgs = {
  groupBy: Array<Scalars['String']['input']>;
  interval: Scalars['YMInterval']['input'];
  isGridOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysisReductionsContributionsDataArgs = {
  columns: InputMaybe<Array<Scalars['String']['input']>>;
  enableReduxContributionsIntervalOptimization: InputMaybe<
    Scalars['Boolean']['input']
  >;
  footprintKind: Scalars['String']['input'];
  timeseriesKind: GQColumnDimension;
  useNewColumnarFormat: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQFootprintAnalysisTimeseriesArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  grouping: InputMaybe<Scalars['String']['input']>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintAnalysisTopVendorsArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintAnalysisTotalSpendUsdArgs = {
  filters: InputMaybe<GQFootprintAnalysisFilters>;
  footprintKind: Scalars['String']['input'];
};

export type GQFootprintAnalysisWorkivaContributionsArgs = {
  footprintKind: Scalars['String']['input'];
  footprintSnapshotId: Scalars['String']['input'];
  reportingInterval: Scalars['YMInterval']['input'];
};

export type GQFootprintAnalysisCustomIntensityDataPoint =
  GQCustomIntensityDataPoint & {
    __typename?: 'FootprintAnalysisCustomIntensityDataPoint';
    customIntensityId: Scalars['String']['output'];
    value: Maybe<Scalars['Float']['output']>;
  };

export type GQFootprintAnalysisFile = {
  __typename?: 'FootprintAnalysisFile';
  category: GQFileCategory;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  uploader: Maybe<GQUser>;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQFootprintAnalysisFilters = {
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupInput>;
  inclusionKind?: InputMaybe<GQFootprintAnalysisInclusionKind>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export const GQFootprintAnalysisInclusionKind = {
  Gross: 'Gross',
  Net: 'Net',
  Removal: 'Removal',
} as const;

export type GQFootprintAnalysisInclusionKind =
  (typeof GQFootprintAnalysisInclusionKind)[keyof typeof GQFootprintAnalysisInclusionKind];
export type GQFootprintAnalysisTimeSeries = {
  __typename?: 'FootprintAnalysisTimeSeries';
  id: Scalars['String']['output'];
  kind: GQAggregateKind;
  series: Array<GQFootprintAnalysisTimeseriesSeries>;
};

export type GQFootprintAnalysisTimeSeriesDataPoint = {
  __typename?: 'FootprintAnalysisTimeSeriesDataPoint';
  customIntensities: Maybe<Array<GQFootprintAnalysisCustomIntensityDataPoint>>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
  value: Scalars['Int53']['output'];
};

export type GQFootprintAnalysisTimeseriesSeries = {
  __typename?: 'FootprintAnalysisTimeseriesSeries';
  data: Array<GQFootprintAnalysisTimeSeriesDataPoint>;
  label: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type GQFootprintAnalysisTopVendor = {
  __typename?: 'FootprintAnalysisTopVendor';
  proportion: Scalars['Float']['output'];
  series: GQFootprintAnalysisTimeseriesSeries;
  vendorInfo: GQVendor;
};

export type GQFootprintAnalysisTopVendors = {
  __typename?: 'FootprintAnalysisTopVendors';
  summarySentences: GQFootprintAnalysisTopVendorsSummarySentences;
  vendors: Array<GQFootprintAnalysisTopVendor>;
};

export type GQFootprintAnalysisTopVendorsSummarySentences = {
  __typename?: 'FootprintAnalysisTopVendorsSummarySentences';
  all: Scalars['String']['output'];
  netZero: Scalars['String']['output'];
  other: Scalars['String']['output'];
  sbt: Scalars['String']['output'];
};

export type GQFootprintAssemblyRun = GQIdInterface & {
  __typename?: 'FootprintAssemblyRun';
  createdAt: Scalars['DateTime']['output'];
  footprintId: Maybe<Scalars['ID']['output']>;
  footprintSnapshotId: Maybe<Scalars['ID']['output']>;
  generatedBy: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  source: GQFootprintGenerationSource;
  status: GQFootprintAssemblyRunStatus;
  workflowId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintAssemblyRunStatus = {
  Canceled: 'Canceled',
  Failed: 'Failed',
  Running: 'Running',
  Success: 'Success',
  UserError: 'UserError',
} as const;

export type GQFootprintAssemblyRunStatus =
  (typeof GQFootprintAssemblyRunStatus)[keyof typeof GQFootprintAssemblyRunStatus];
export const GQFootprintAssemblyStep = {
  AssembledFootprintSnapshot: 'assembledFootprintSnapshot',
  CleanPowerAppliedFootprintSnapshot: 'cleanPowerAppliedFootprintSnapshot',
  EmEvalPipelineResult: 'emEvalPipelineResult',
  FinalFootprint: 'finalFootprint',
  MergedPipelineResultsWithTags: 'mergedPipelineResultsWithTags',
} as const;

export type GQFootprintAssemblyStep =
  (typeof GQFootprintAssemblyStep)[keyof typeof GQFootprintAssemblyStep];
export type GQFootprintAuditBundle = {
  __typename?: 'FootprintAuditBundle';
  createdAt: Scalars['DateTime']['output'];
  downloadSize: Maybe<Scalars['Int53']['output']>;
  downloadUrl: Maybe<Scalars['String']['output']>;
  footprintEmissionsKind: GQFootprintEmissionsKind;
  footprintSnapshot: GQFootprintSnapshot;
  id: Scalars['String']['output'];
  inputJson: Maybe<Scalars['JSONString']['output']>;
  status: GQFootprintExportStatus;
};

export type GQFootprintAuditBundleConnection = {
  __typename?: 'FootprintAuditBundleConnection';
  edges: Array<GQFootprintAuditBundleEdge>;
  pageInfo: GQPageInfo;
};

export type GQFootprintAuditBundleEdge = {
  __typename?: 'FootprintAuditBundleEdge';
  cursor: Scalars['String']['output'];
  node: GQFootprintAuditBundle;
};

export type GQFootprintBartTableReference = GQBaseTableReference & {
  __typename?: 'FootprintBartTableReference';
  alias: Scalars['String']['output'];
  businessActivityType: Scalars['String']['output'];
  footprintSnapshotIds: Maybe<Array<Scalars['String']['output']>>;
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQFootprintCategoryRule = GQIdInterface & {
  __typename?: 'FootprintCategoryRule';
  author: Maybe<GQUser>;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintCategoryRule>;
  outputColumn: Scalars['String']['output'];
  outputValue: Scalars['String']['output'];
  ruleOrder: Scalars['Int']['output'];
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintCategoryRuleInput = {
  filters: Array<GQBiQueryFilterInput>;
  outputColumn: Scalars['String']['input'];
  outputValue: Scalars['String']['input'];
  ruleOrder: Scalars['Int']['input'];
  stableId?: InputMaybe<Scalars['String']['input']>;
  timeIntervals?: InputMaybe<Array<Scalars['YMInterval']['input']>>;
};

export type GQFootprintConfigCarbonRemoval = {
  __typename?: 'FootprintConfigCarbonRemoval';
  id: Scalars['ID']['output'];
  totalTco2e: Scalars['Float']['output'];
};

export type GQFootprintConfiguration = {
  __typename?: 'FootprintConfiguration';
  carbonRemoval: Maybe<GQFootprintConfigCarbonRemoval>;
  categoryRules: Array<GQFootprintCategoryRule>;
  createdAt: Scalars['DateTime']['output'];
  exclusionRules: Array<GQFootprintExclusionRule>;
  footprintInterval: Scalars['YMInterval']['output'];
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructions: GQMarketplaceAllocationInstructionConnection;
  measurementProjectDatasets: Array<GQFootprintMeasurementProjectDataset>;
  methodologyConfig: Maybe<GQMethodologyConfig>;
  releasesByBat: Array<GQEmissionsModelReleaseByBatName>;
};

export type GQFootprintConnection = {
  __typename?: 'FootprintConnection';
  edges: Array<Maybe<GQFootprintEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintContributionLegacy = {
  __typename?: 'FootprintContributionLegacy';
  buildingName: Maybe<Scalars['String']['output']>;
  businessCategory: Scalars['String']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  conversionFactorId: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  electricityType: Maybe<GQElectricityType>;
  fileMetadataIds: Array<Scalars['ID']['output']>;
  footprintKind: Scalars['String']['output'];
  ghgCategoryId: Scalars['String']['output'];
  ghgScope: Scalars['String']['output'];
  inputQuantity: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2e: Scalars['Float']['output'];
  location: Maybe<Scalars['String']['output']>;
  locationBasedKgco2e: Maybe<Scalars['Float']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  productMarket: Maybe<Scalars['String']['output']>;
  quantityUnit: Maybe<Scalars['String']['output']>;
  quantityValue: Maybe<Scalars['Int']['output']>;
  rowId: Scalars['String']['output'];
  subEntity: Maybe<Scalars['String']['output']>;
  vendor: Maybe<Scalars['String']['output']>;
  vendorId: Maybe<Scalars['ID']['output']>;
  vendorProgram: Maybe<Scalars['String']['output']>;
  yearMonth: Scalars['YearMonth']['output'];
};

export type GQFootprintContributionsData = {
  __typename?: 'FootprintContributionsData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQFootprintDatasetOrDatasetGroup = {
  __typename?: 'FootprintDatasetOrDatasetGroup';
  dataset: Maybe<GQDataset>;
  datasetGroupName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type GQFootprintDisclosure = GQIdInterface & {
  __typename?: 'FootprintDisclosure';
  createdAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  footprintSnapshot: GQFootprintSnapshot;
  granularity: GQFootprintDisclosureGranularity;
  id: Scalars['ID']['output'];
  reportingYear: Maybe<Scalars['Int']['output']>;
  shareLogs: Array<GQFootprintDisclosureShareLog>;
};

export type GQFootprintDisclosureConnection = {
  __typename?: 'FootprintDisclosureConnection';
  edges: Array<Maybe<GQFootprintDisclosureEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureEdge = {
  __typename?: 'FootprintDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosure>;
};

export const GQFootprintDisclosureGranularity = {
  AggregatesOnly: 'AggregatesOnly',
  Full: 'Full',
} as const;

export type GQFootprintDisclosureGranularity =
  (typeof GQFootprintDisclosureGranularity)[keyof typeof GQFootprintDisclosureGranularity];
export type GQFootprintDisclosureShareLog = GQIdInterface & {
  __typename?: 'FootprintDisclosureShareLog';
  footprintDisclosureId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  sharedByUserId: Scalars['ID']['output'];
  sharedToOrgId: Scalars['ID']['output'];
};

export type GQFootprintDisclosureShareLogConnection = {
  __typename?: 'FootprintDisclosureShareLogConnection';
  edges: Array<Maybe<GQFootprintDisclosureShareLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFootprintDisclosureShareLogEdge = {
  __typename?: 'FootprintDisclosureShareLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprintDisclosureShareLog>;
};

export type GQFootprintEdge = {
  __typename?: 'FootprintEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFootprint>;
};

export const GQFootprintEmissionsKind = {
  Ghg: 'Ghg',
  MicroplasticsInflow: 'MicroplasticsInflow',
  MicroplasticsOutflow: 'MicroplasticsOutflow',
  SubstanceInflow: 'SubstanceInflow',
  SubstanceOutflow: 'SubstanceOutflow',
  Waste: 'Waste',
  WaterConsumption: 'WaterConsumption',
} as const;

export type GQFootprintEmissionsKind =
  (typeof GQFootprintEmissionsKind)[keyof typeof GQFootprintEmissionsKind];
export type GQFootprintEstimateOutput = GQIdInterface & {
  __typename?: 'FootprintEstimateOutput';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  companyId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  output: Maybe<GQFootprintEstimateOutputByScope>;
};

export type GQFootprintEstimateOutputByScope = GQCompanyEmissionsInterfaceV2 & {
  __typename?: 'FootprintEstimateOutputByScope';
  revenueCurrency: Maybe<Scalars['String']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Location: Maybe<Scalars['Float']['output']>;
  scope2Market: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQFootprintExclusionRule = GQIdInterface & {
  __typename?: 'FootprintExclusionRule';
  author: GQUser;
  description: Maybe<Scalars['String']['output']>;
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  latestActiveRule: Maybe<GQFootprintExclusionRule>;
  outputValue: Scalars['Float']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  ruleOrder: Scalars['Int']['output'];
  stableId: Scalars['String']['output'];
  timeIntervals: Maybe<Array<Scalars['YMInterval']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQFootprintExclusionRuleEmissions = {
  __typename?: 'FootprintExclusionRuleEmissions';
  excludedMwhConsumed: Maybe<Scalars['Float']['output']>;
  excludedTco2eQuantity: Scalars['Float']['output'];
};

export type GQFootprintExclusionRuleV2Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters: Array<GQBiQueryFilterInput>;
  outputValue: Scalars['Float']['input'];
  ruleOrder: Scalars['Int']['input'];
  stableId?: InputMaybe<Scalars['String']['input']>;
  timeIntervals?: InputMaybe<Array<Scalars['YMInterval']['input']>>;
};

export type GQFootprintExclusionSuggestion = GQIdInterface & {
  __typename?: 'FootprintExclusionSuggestion';
  id: Scalars['ID']['output'];
  suggestion:
    | GQFootprintExclusionSuggestionSuggestionConflict
    | GQFootprintExclusionSuggestionSuggestionUnmappedActivity;
};

export const GQFootprintExclusionSuggestionConflictingSourceKind = {
  Activity: 'Activity',
  Spend: 'Spend',
} as const;

export type GQFootprintExclusionSuggestionConflictingSourceKind =
  (typeof GQFootprintExclusionSuggestionConflictingSourceKind)[keyof typeof GQFootprintExclusionSuggestionConflictingSourceKind];
export type GQFootprintExclusionSuggestionSuggestion = {
  kind: GQFootprintExclusionSuggestionSuggestionKind;
};

export type GQFootprintExclusionSuggestionSuggestionConflict =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflict';
    beaCode: Scalars['String']['output'];
    beaCodeDescription: Scalars['String']['output'];
    conflictingSources: Array<
      | GQFootprintExclusionSuggestionSuggestionConflictActivity
      | GQFootprintExclusionSuggestionSuggestionConflictSpend
    >;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictActivity =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictActivity';
    dataset: GQDataset;
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
  };

export type GQFootprintExclusionSuggestionSuggestionConflictSpend =
  GQFootprintExclusionSuggestionSuggestionConflictingSource & {
    __typename?: 'FootprintExclusionSuggestionSuggestionConflictSpend';
    financialAccountId: Scalars['String']['output'];
    financialAccountName: Scalars['String']['output'];
    source: GQFootprintExclusionSuggestionConflictingSourceKind;
    tCO2e: Scalars['Float']['output'];
    vendorEntity: Maybe<Scalars['String']['output']>;
  };

export type GQFootprintExclusionSuggestionSuggestionConflictingSource = {
  source: GQFootprintExclusionSuggestionConflictingSourceKind;
  tCO2e: Scalars['Float']['output'];
};

export const GQFootprintExclusionSuggestionSuggestionKind = {
  Conflict: 'Conflict',
  UnmappedActivity: 'UnmappedActivity',
} as const;

export type GQFootprintExclusionSuggestionSuggestionKind =
  (typeof GQFootprintExclusionSuggestionSuggestionKind)[keyof typeof GQFootprintExclusionSuggestionSuggestionKind];
export type GQFootprintExclusionSuggestionSuggestionUnmappedActivity =
  GQFootprintExclusionSuggestionSuggestion & {
    __typename?: 'FootprintExclusionSuggestionSuggestionUnmappedActivity';
    dataset: GQDataset;
    kind: GQFootprintExclusionSuggestionSuggestionKind;
    tCO2e: Scalars['Float']['output'];
  };

export const GQFootprintExportStatus = {
  Complete: 'Complete',
  Error: 'Error',
  Processing: 'Processing',
} as const;

export type GQFootprintExportStatus =
  (typeof GQFootprintExportStatus)[keyof typeof GQFootprintExportStatus];
export type GQFootprintFieldValue = {
  __typename?: 'FootprintFieldValue';
  field: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQFootprintGenerationInfo = {
  __typename?: 'FootprintGenerationInfo';
  totalPublishedRecordsForOrg: Scalars['Int']['output'];
};

export type GQFootprintGenerationSource = GQApiKey | GQUser;

export type GQFootprintKindBreakdown = {
  __typename?: 'FootprintKindBreakdown';
  footprintKinds: Array<GQFootprintKindBreakdownItem>;
  id: Scalars['ID']['output'];
};

export type GQFootprintKindBreakdownItem = {
  __typename?: 'FootprintKindBreakdownItem';
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kgco2e: Scalars['Float']['output'];
  percentOfTotal: Scalars['Float']['output'];
};

export type GQFootprintKindTotals = {
  __typename?: 'FootprintKindTotals';
  footprintKind: Scalars['String']['output'];
  totalTco2e: Scalars['Float']['output'];
};

export type GQFootprintKindWithProductsOrMaterials = {
  __typename?: 'FootprintKindWithProductsOrMaterials';
  footprintKind: Scalars['String']['output'];
};

export type GQFootprintMeasurementProjectDataset = {
  __typename?: 'FootprintMeasurementProjectDataset';
  datasetsAndDatasetGroups: Array<GQFootprintDatasetOrDatasetGroup>;
  id: Scalars['ID']['output'];
  measurementProject: GQMeasurementProject;
};

export type GQFootprintQuantityKindMetadata = {
  __typename?: 'FootprintQuantityKindMetadata';
  includedFootprintQuantityKinds: Array<Scalars['String']['output']>;
};

export type GQFootprintSidecarTableReference = GQBaseTableReference & {
  __typename?: 'FootprintSidecarTableReference';
  alias: Scalars['String']['output'];
  footprintSnapshotIds: Maybe<Array<Scalars['String']['output']>>;
  ref: Scalars['String']['output'];
  tableName: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQFootprintSliceInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'FootprintSliceInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    plan: GQPlan;
    reductionTimeseries: GQSimpleTimeseries;
    scheduleType: GQScheduleType;
  };

export type GQFootprintSnapshot = GQIdInterface & {
  __typename?: 'FootprintSnapshot';
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  approvedBy: Maybe<GQUser>;
  assemblyDebugJson: Maybe<Scalars['JSONString']['output']>;
  categoryRules: Array<GQFootprintCategoryRule>;
  configs: Array<GQPipelineConfig>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  dataRegistryTableName: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  excludedScopesWithPriceGating: Array<Scalars['String']['output']>;
  exclusionRules: Array<GQFootprintExclusionRule>;
  extraAdeIdsForIntDenoms: Array<Scalars['String']['output']>;
  extraAdeIdsForNonEmissiveActivity: Array<Scalars['String']['output']>;
  footprint: GQFootprint;
  footprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
  footprintConfiguration: GQFootprintConfiguration;
  footprintInterval: Scalars['YMInterval']['output'];
  globalTags: Array<GQGlobalFootprintTag>;
  id: Scalars['ID']['output'];
  identifier: Maybe<Scalars['String']['output']>;
  intermediateFileIds: Maybe<Scalars['JSONString']['output']>;
  isAutoGenerated: Scalars['Boolean']['output'];
  isLatest: Scalars['Boolean']['output'];
  isUserVisible: Scalars['Boolean']['output'];
  marketplaceAllocationInstructionIds: Array<Scalars['String']['output']>;
  marketplaceAllocationInstructions: Array<GQMarketplaceAllocationInstruction>;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  reportsUsingFootprint: Array<GQReport>;
  reviewStatus: GQFootprintStatus;
  status: GQFootprintSnapshotStatus;
  summary: GQFootprintSnapshotSummary;
  tags: Array<GQFootprintTag>;
  userUploadedTableIds: Array<Scalars['String']['output']>;
  watershedFootprintReviewRequest: Maybe<GQWatershedFootprintReviewRequest>;
};

export type GQFootprintSnapshotExclusionRule = GQIdInterface & {
  __typename?: 'FootprintSnapshotExclusionRule';
  footprintExclusionRule: GQFootprintExclusionRule;
  footprintSnapshot: GQFootprintSnapshot;
  id: Scalars['ID']['output'];
  impactedEmissions: GQFootprintExclusionRuleEmissions;
};

export const GQFootprintSnapshotStatus = {
  Draft: 'Draft',
  Publish: 'Publish',
} as const;

export type GQFootprintSnapshotStatus =
  (typeof GQFootprintSnapshotStatus)[keyof typeof GQFootprintSnapshotStatus];
export type GQFootprintSnapshotSummary = {
  __typename?: 'FootprintSnapshotSummary';
  emissions: Array<GQFootprintSnapshotSummaryEmission>;
  format: Scalars['Int']['output'];
};

export type GQFootprintSnapshotSummaryEmission = {
  __typename?: 'FootprintSnapshotSummaryEmission';
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
  pipeline: Scalars['String']['output'];
};

export type GQFootprintSnapshotTableReference = GQBaseTableReference & {
  __typename?: 'FootprintSnapshotTableReference';
  alias: Scalars['String']['output'];
  footprintKind: Maybe<Scalars['String']['output']>;
  footprintSnapshotIds: Maybe<Array<Scalars['String']['output']>>;
  includeDedupedRows: Maybe<Scalars['Boolean']['output']>;
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQFootprintSourceOption = {
  __typename?: 'FootprintSourceOption';
  externalScore: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  pcafScore: Maybe<Scalars['Int']['output']>;
  source: GQEmissionsYearSource;
  year: Scalars['Int']['output'];
};

export const GQFootprintStatus = {
  Approved: 'Approved',
  Draft: 'Draft',
  PendingWatershedReview: 'PendingWatershedReview',
  ReviewedByWatershed: 'ReviewedByWatershed',
} as const;

export type GQFootprintStatus =
  (typeof GQFootprintStatus)[keyof typeof GQFootprintStatus];
export type GQFootprintSummary = {
  __typename?: 'FootprintSummary';
  customIntensityMetadata: Array<GQCustomIntensityMetadata>;
  heavyHitters: Array<GQCategorizedEmissionData>;
  id: Scalars['String']['output'];
  timeseries: Maybe<GQFootprintAnalysisTimeSeries>;
  topCategories: Array<GQCategorizedEmissionData>;
  topCategoriesWithSubcategories: Array<GQCategorizedEmissionData>;
  total: Maybe<Scalars['Float']['output']>;
};

export type GQFootprintSummaryHeavyHittersArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTimeseriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  grouping: InputMaybe<Scalars['String']['input']>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintSummaryTopCategoriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTopCategoriesWithSubcategoriesArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  first: InputMaybe<Scalars['Int']['input']>;
};

export type GQFootprintSummaryTotalArgs = {
  filters: InputMaybe<GQFootprintSummaryFilters>;
  kind: InputMaybe<GQAggregateKind>;
};

export type GQFootprintSummaryFilters = {
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  ghgScope?: InputMaybe<Scalars['String']['input']>;
  inclusionKind?: InputMaybe<GQFootprintAnalysisInclusionKind>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQFootprintTag = GQIdInterface & {
  __typename?: 'FootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFinance: Scalars['Boolean']['output'];
  orgId: Scalars['ID']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQFootprintTestExecutionRecordDiffData = {
  __typename?: 'FootprintTestExecutionRecordDiffData';
  actual: Maybe<Scalars['UntypedData']['output']>;
  equal: Scalars['Boolean']['output'];
  expected: Maybe<Scalars['UntypedData']['output']>;
  fieldName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQFootprintTestExecutionStepResult = {
  __typename?: 'FootprintTestExecutionStepResult';
  id: Scalars['ID']['output'];
  resultRecords: Array<GQFootprintTestExecutionStepResultRecord>;
};

export type GQFootprintTestExecutionStepResultRecord = {
  __typename?: 'FootprintTestExecutionStepResultRecord';
  allocationLocationQuantityKgCo2E: Maybe<Scalars['Float']['output']>;
  bartName: Scalars['String']['output'];
  bartRowId: Scalars['Int']['output'];
  categoryId: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  emStableId: Scalars['String']['output'];
  emStableTitle: Scalars['String']['output'];
  emVariableName: Scalars['String']['output'];
  fullDiff: Scalars['UntypedData']['output'];
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inActual: Maybe<Scalars['Boolean']['output']>;
  inExpected: Maybe<Scalars['Boolean']['output']>;
  month: Scalars['String']['output'];
  quantityKgCo2e: Maybe<Scalars['Float']['output']>;
  resultAccepted: Maybe<Scalars['Boolean']['output']>;
  rowSplitKey: Maybe<Scalars['String']['output']>;
  status: GQFootprintTestExecutionStepStatus;
  subcategoryId: Maybe<Scalars['String']['output']>;
};

export const GQFootprintTestExecutionStepStatus = {
  Failed: 'Failed',
  Passed: 'Passed',
} as const;

export type GQFootprintTestExecutionStepStatus =
  (typeof GQFootprintTestExecutionStepStatus)[keyof typeof GQFootprintTestExecutionStepStatus];
export type GQFootprintTestSuiteConfig = {
  __typename?: 'FootprintTestSuiteConfig';
  categoryRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  customTagNames: Maybe<Array<Scalars['String']['output']>>;
  exclusionRuleIds: Maybe<Array<Scalars['ID']['output']>>;
  id: Scalars['ID']['output'];
  marketplaceAllocationInstructionIds: Maybe<Array<Scalars['ID']['output']>>;
  pipelineEndDate: Maybe<Scalars['YearMonth']['output']>;
  pipelineStartDate: Maybe<Scalars['YearMonth']['output']>;
  shouldExclude: Scalars['Boolean']['output'];
};

export type GQFootprintTestSuiteExecution = {
  __typename?: 'FootprintTestSuiteExecution';
  createdAt: Scalars['DateTime']['output'];
  error: Maybe<Scalars['String']['output']>;
  failedStep: Maybe<GQFootprintAssemblyStep>;
  id: Scalars['ID']['output'];
  status: GQMeasurementTestSuiteStageExecutionStatus;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GQFootprintUsagesForExclusionRuleStable = {
  __typename?: 'FootprintUsagesForExclusionRuleStable';
  footprintsWithSnapshot: Array<GQFootprintWithSnapshot>;
  stableId: Scalars['ID']['output'];
};

export type GQFootprintWithSnapshot = {
  __typename?: 'FootprintWithSnapshot';
  footprint: GQFootprint;
  footprintSnapshot: GQFootprintSnapshot;
};

export type GQForecast = GQIdInterface & {
  __typename?: 'Forecast';
  acknowledgedFootprintSnapshotId: Maybe<Scalars['ID']['output']>;
  businessCategories: Array<GQCategorizedEmissionData>;
  customIntensityConfigs: Array<GQCustomIntensityConfig>;
  electricityConsumptionBreakdown: Array<GQRenewableEnergyPercentagePoint>;
  footprintInterval: Scalars['YMInterval']['output'];
  footprintKinds: Array<Scalars['String']['output']>;
  footprintKindsMeta: Array<GQSnapshotFootprintKindMeta>;
  /** @deprecated This shouldn't be user-visible, there is no dashboard resolver for this */
  footprintSnapshot: GQFootprintSnapshot;
  footprintSnapshotId: Scalars['ID']['output'];
  historicalPeriod: GQForecastPeriod;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  name: Scalars['String']['output'];
  overrideFootprintKind: Maybe<Scalars['String']['output']>;
  planTargets: Array<GQPlanTarget>;
  referencePeriod: GQForecastPeriod;
  referencePeriodInterval: Scalars['YMInterval']['output'];
  scenarios: Array<GQForecastScenario>;
  validReferenceInterval: Scalars['YMInterval']['output'];
  validYearsForIntervalEnd: Scalars['YMInterval']['output'];
};

export type GQForecastBusinessCategoriesArgs = {
  useLegacyFootprintVersionSelection: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQForecastCustomIntensityDataPoint = GQCustomIntensityDataPoint & {
  __typename?: 'ForecastCustomIntensityDataPoint';
  customIntensityId: Scalars['String']['output'];
  value: Maybe<Scalars['Float']['output']>;
};

export type GQForecastPeriod = {
  __typename?: 'ForecastPeriod';
  data: Array<GQForecastPeriodPoint>;
  interval: Scalars['YMInterval']['output'];
};

export type GQForecastPeriodPoint = {
  __typename?: 'ForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
  headcount: Maybe<Scalars['Int']['output']>;
  revenue: Maybe<Scalars['Int53']['output']>;
};

export type GQForecastScenario = GQIdInterface & {
  __typename?: 'ForecastScenario';
  description: Maybe<Scalars['String']['output']>;
  growthForecasts: Array<GQGrowthForecast>;
  historicalUserInputtedGrowthFactorDataPoints: Array<GQHistoricalUserInputtedGrowthFactorDataPoint>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
};

export type GQFrozenDatasourceSummary = {
  __typename?: 'FrozenDatasourceSummary';
  datasource: GQDatasource;
  isStale: Scalars['Boolean']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQFund = GQPermissionObject & {
  __typename?: 'Fund';
  anonymousFundUuid: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeAsSandbox: Maybe<Scalars['Boolean']['output']>;
  externalId: Maybe<Scalars['ID']['output']>;
  fundCategory: Maybe<Scalars['String']['output']>;
  fundGroup: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nameSortable: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQFundAssetClass = {
  AssetGroup: 'AssetGroup',
  BusinessLoans: 'BusinessLoans',
  CommercialLineOfInsurance: 'CommercialLineOfInsurance',
  CommercialRealEstate: 'CommercialRealEstate',
  CorporateBonds: 'CorporateBonds',
  ListedEquity: 'ListedEquity',
  Mortgages: 'Mortgages',
  PersonalMotorVehicleInsurance: 'PersonalMotorVehicleInsurance',
  ProjectFinance: 'ProjectFinance',
  SovereignDebt: 'SovereignDebt',
  UnlistedEquity: 'UnlistedEquity',
} as const;

export type GQFundAssetClass =
  (typeof GQFundAssetClass)[keyof typeof GQFundAssetClass];
export type GQFundConnection = {
  __typename?: 'FundConnection';
  edges: Array<Maybe<GQFundEdge>>;
  pageInfo: GQPageInfo;
};

export type GQFundEdge = {
  __typename?: 'FundEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQFund>;
};

export type GQFundId = {
  __typename?: 'FundId';
  fundId: Scalars['ID']['output'];
};

export type GQGenerateAuditReportInput = {
  filters?: InputMaybe<Array<GQFilterExpressionPrimitiveWithNewFiltersInput>>;
  footprintEmissionsKind?: InputMaybe<GQFootprintEmissionsKind>;
  footprintSnapshotId: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQGenerateAuditReportPayload = {
  __typename?: 'GenerateAuditReportPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateAutoFootprintAlertOptionsInput = {
  fullStorySessionUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateBartFromFileUploadInput = {
  ctsVersionId: Scalars['ID']['input'];
  embedId?: InputMaybe<Scalars['String']['input']>;
  facilitiesMergeStrategy?: InputMaybe<GQBuildingsMergeStrategy>;
  fileMetadataId: Scalars['ID']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQGenerateBartFromFileUploadPayload = {
  __typename?: 'GenerateBartFromFileUploadPayload';
  jobId: Scalars['ID']['output'];
  userUploadTask: GQUserUploadTask;
};

export type GQGenerateCustomIntensityConfigIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateCustomIntensityConfigIdPayload = {
  __typename?: 'GenerateCustomIntensityConfigIdPayload';
  id: Scalars['String']['output'];
};

export type GQGenerateCustomerUploadedReferenceDataRevisionInput = {
  customizationTypeId: Scalars['String']['input'];
  oneSchemaEmbedId: Scalars['Int']['input'];
  oneSchemaSheetId: Scalars['Int']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQGenerateCustomerUploadedReferenceDataRevisionPayload = {
  __typename?: 'GenerateCustomerUploadedReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateExportUrlInput = {
  input: Scalars['UntypedData']['input'];
  kind: Scalars['String']['input'];
};

export type GQGenerateExportUrlPayload = {
  __typename?: 'GenerateExportUrlPayload';
  workflowId: Scalars['ID']['output'];
};

export type GQGenerateFinanceAuditReportsForAssetInput = {
  auditDatum: Array<GQFinanceAuditReportData>;
  orgId: Scalars['ID']['input'];
};

export type GQGenerateFinanceAuditReportsForAssetPayload = {
  __typename?: 'GenerateFinanceAuditReportsForAssetPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateFinanceHomeExportInput = {
  financeSnapshotId: Scalars['ID']['input'];
  grouping: GQFinanceGroupingType;
  year: Scalars['Int']['input'];
};

export type GQGenerateFinanceHomeExportPayload = {
  __typename?: 'GenerateFinanceHomeExportPayload';
  workflowId: Scalars['ID']['output'];
};

export type GQGenerateFootprintV2Input = {
  alertOpts: GQGenerateAutoFootprintAlertOptionsInput;
  bartStableIdsWithBats: Array<GQBartStableIdWithBatNameInput>;
  categoryRuleIds: Array<Scalars['ID']['input']>;
  exclusionRuleIds: Array<Scalars['ID']['input']>;
  footprintId: Scalars['ID']['input'];
  methodologyConfig: GQMethodologyConfigInput;
  selectedAllocationInstructionIds: Array<Scalars['ID']['input']>;
  shouldSendEmailOnCompletion: Scalars['Boolean']['input'];
  shouldSkipFootprintPublish: Scalars['Boolean']['input'];
  userChangelogMd: Scalars['String']['input'];
  versionName: Scalars['String']['input'];
};

export type GQGenerateFootprintV2Payload = {
  __typename?: 'GenerateFootprintV2Payload';
  footprintAssemblyRun: GQFootprintAssemblyRun;
  jobId: Scalars['ID']['output'];
};

export type GQGenerateForecastScenarioIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type GQGenerateForecastScenarioIdPayload = {
  __typename?: 'GenerateForecastScenarioIdPayload';
  id: Scalars['String']['output'];
};

export type GQGenerateOverrideReferenceDataRevisionInput = {
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQGenerateOverrideReferenceDataRevisionPayload = {
  __typename?: 'GenerateOverrideReferenceDataRevisionPayload';
  jobId: Scalars['ID']['output'];
};

export type GQGenerateTemplateDownloadUrlForDatasetInput = {
  datasetId: Scalars['ID']['input'];
};

export type GQGenerateTemplateDownloadUrlForDatasetPayload = {
  __typename?: 'GenerateTemplateDownloadUrlForDatasetPayload';
  templateDownloadUrl: Scalars['String']['output'];
};

export type GQGenerateTemplateDownloadUrlForOrgDatasetsInput = {
  datasetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQGenerateTemplateDownloadUrlForOrgDatasetsPayload = {
  __typename?: 'GenerateTemplateDownloadUrlForOrgDatasetsPayload';
  templateDownloadUrl: Scalars['String']['output'];
};

export const GQGeocodingClient = {
  GoogleMaps: 'GoogleMaps',
  Mapbox: 'Mapbox',
} as const;

export type GQGeocodingClient =
  (typeof GQGeocodingClient)[keyof typeof GQGeocodingClient];
export type GQGetOrCreateDatasetFromCanonicalDatasetKindInput = {
  kind: GQCanonicalDatasetKind;
};

export type GQGetOrCreateDatasetFromCanonicalDatasetKindPayload = {
  __typename?: 'GetOrCreateDatasetFromCanonicalDatasetKindPayload';
  dataset: GQDataset;
};

export type GQGetOrSendEmailsInput = {
  blurb?: InputMaybe<Scalars['String']['input']>;
  contactIds: Array<Scalars['ID']['input']>;
  emailIsForPortco?: InputMaybe<Scalars['Boolean']['input']>;
  emailKind: GQEmailKind;
  shouldSendEmails: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type GQGetOrSendEmailsPayload = {
  __typename?: 'GetOrSendEmailsPayload';
  defaultBlurb: Scalars['String']['output'];
  defaultSubject: Scalars['String']['output'];
  sampleEmail: GQEmailContents;
};

export type GQGetOrSendMeasurementEmailsInput = {
  blurb?: InputMaybe<Scalars['String']['input']>;
  ccUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  measurementProjectId: Scalars['ID']['input'];
  recipientUserIds: Array<Scalars['ID']['input']>;
  shouldSendEmails: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  triggererUserId: Scalars['ID']['input'];
  type: GQMeasurementEmailType;
};

export type GQGetOrSendMeasurementEmailsPayload = {
  __typename?: 'GetOrSendMeasurementEmailsPayload';
  defaultBlurb: Scalars['String']['output'];
  measurementProject: GQMeasurementProject;
  sampleEmail: GQEmailContents;
};

export type GQGetReportObjectConfigPathInput = {
  id: Scalars['ID']['input'];
};

export type GQGetReportObjectConfigPathPayload = {
  __typename?: 'GetReportObjectConfigPathPayload';
  path: Maybe<Array<GQReportObjectConfigPathItem>>;
};

export type GQGhgCategorizedEmissionData = {
  __typename?: 'GhgCategorizedEmissionData';
  amountKgco2e: Scalars['Float']['output'];
  businessSubcategory: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  proportion: Scalars['Float']['output'];
};

export const GQGhgScope = {
  AdditionalCleanPower: 'AdditionalCleanPower',
  CarbonRemoval: 'CarbonRemoval',
  Scope1: 'Scope1',
  Scope2: 'Scope2',
  Scope3: 'Scope3',
} as const;

export type GQGhgScope = (typeof GQGhgScope)[keyof typeof GQGhgScope];
export type GQGiveFeedbackInput = {
  message: Scalars['String']['input'];
  surfaceArea: GQFeedbackSurfaceArea;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQGlobalFootprintTag = GQIdInterface & {
  __typename?: 'GlobalFootprintTag';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  rulesList: Scalars['JSONString']['output'];
  rulesSchemaVersion: Scalars['String']['output'];
  tagName: Scalars['String']['output'];
  watershedEditor: Maybe<GQWatershedEmployee>;
};

export type GQGrantUserRoleInput = {
  orgAccessId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type GQGrantUserRolePayload = {
  __typename?: 'GrantUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQGridColumnConfiguration = {
  columnFormat: GQGridColumnFormat;
};

export type GQGridColumnConfigurationMultiSelect = GQGridColumnConfiguration & {
  __typename?: 'GridColumnConfigurationMultiSelect';
  columnFormat: GQGridColumnFormat;
  options: Array<Scalars['String']['output']>;
};

export type GQGridColumnConfigurationSingleSelect =
  GQGridColumnConfiguration & {
    __typename?: 'GridColumnConfigurationSingleSelect';
    columnFormat: GQGridColumnFormat;
    options: Array<Scalars['String']['output']>;
  };

export const GQGridColumnFormat = {
  Boolean: 'Boolean',
  Checkbox: 'Checkbox',
  Country: 'Country',
  Currency: 'Currency',
  Date: 'Date',
  DateTime: 'DateTime',
  Float: 'Float',
  Integer: 'Integer',
  Metric: 'Metric',
  MultiSelect: 'MultiSelect',
  NullableCheckbox: 'NullableCheckbox',
  Percentage: 'Percentage',
  PercentageChange: 'PercentageChange',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQGridColumnFormat =
  (typeof GQGridColumnFormat)[keyof typeof GQGridColumnFormat];
export type GQGroupedAuditLogConnection = {
  __typename?: 'GroupedAuditLogConnection';
  edges: Array<Maybe<GQGroupedAuditLogEdge>>;
  pageInfo: GQPageInfo;
};

export type GQGroupedAuditLogEdge = {
  __typename?: 'GroupedAuditLogEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQGroupedAuditLogEntry>;
};

export type GQGroupedAuditLogEntry = {
  __typename?: 'GroupedAuditLogEntry';
  createdAt: Scalars['DateTime']['output'];
  eventKind: Scalars['String']['output'];
  firstEventMetadata: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  numEvents: Scalars['Int']['output'];
  objectKind: Maybe<Scalars['String']['output']>;
  user: Maybe<GQUser>;
  userId: Maybe<Scalars['String']['output']>;
};

export const GQGrowthFactor = {
  Headcount: 'Headcount',
  Revenue: 'Revenue',
} as const;

export type GQGrowthFactor =
  (typeof GQGrowthFactor)[keyof typeof GQGrowthFactor];
export type GQGrowthFactorDataPoint = {
  __typename?: 'GrowthFactorDataPoint';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  growthFactorType: GQGrowthFactorType;
  value: Maybe<Scalars['Float']['output']>;
};

export const GQGrowthFactorType = {
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  Revenue: 'Revenue',
  SupplierSpend: 'SupplierSpend',
} as const;

export type GQGrowthFactorType =
  (typeof GQGrowthFactorType)[keyof typeof GQGrowthFactorType];
export type GQGrowthForecast = {
  __typename?: 'GrowthForecast';
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  forecast: GQSimpleTimeseries;
  growthFactorType: GQGrowthFactorType;
  hasUserInputtedHistoricalPeriod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDefaultScalingFactor: Scalars['Boolean']['output'];
  scalingFactorFilters: Maybe<GQFilterExpressionGroupWithNewFilters>;
};

export type GQGrowthForecastInput = {
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  forecast?: InputMaybe<GQSimpleTimeseriesInput>;
  growthFactorType: GQGrowthFactorType;
  historicalGrowthValues?: InputMaybe<GQCreateSimpleTimeseriesInput>;
  isDefaultScalingFactor?: InputMaybe<Scalars['Boolean']['input']>;
  scalingFactorFilters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQHasPermissionInput = {
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permissionType: GQPermissionType;
};

export type GQHistoricalUserInputtedGrowthFactorDataPoint = {
  __typename?: 'HistoricalUserInputtedGrowthFactorDataPoint';
  date: Scalars['Date']['output'];
  growthFactors: Array<GQGrowthFactorDataPoint>;
};

export type GQI18nSandbox = {
  __typename?: 'I18nSandbox';
  name: Scalars['String']['output'];
  name2: Scalars['String']['output'];
  name3: Scalars['String']['output'];
};

export type GQIClimateProgramProject = {
  canonicalClimateProgramProject: Maybe<GQCanonicalClimateProgramProject>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  endDate: Maybe<Scalars['Date']['output']>;
  iconType: GQClimateProgramProjectIconType;
  id: Scalars['ID']['output'];
  kind: GQClimateProgramProjectKind;
  name: Scalars['String']['output'];
  startDate: Maybe<Scalars['Date']['output']>;
};

export type GQIContact = {
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  role: Maybe<Scalars['String']['output']>;
};

export type GQIdInterface = {
  id: Scalars['ID']['output'];
};

export type GQIFinanceAsset = {
  assetComments: Array<GQAssetComment>;
  assetGroupAssets: Maybe<Array<GQAssetGroupAsset>>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetYears: Array<GQFinanceAssetYearNew>;
  createdAt: Scalars['Date']['output'];
  currencyCode: GQCurrencyCode;
  externalId: Maybe<Scalars['ID']['output']>;
  footprintSourceOptions: Array<GQFootprintSourceOption>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  otherData: Maybe<Scalars['UntypedData']['output']>;
  revenueSourceOptions: Array<GQRevenueSourceOption>;
  tagData: Maybe<Scalars['UntypedData']['output']>;
  updatedAt: Scalars['Date']['output'];
};

export type GQIFinanceAssetAssetHoldingsArgs = {
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetAssetYearsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetFootprintSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceAssetRevenueSourceOptionsArgs = {
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQIFinanceViewSection = {
  id: Scalars['ID']['output'];
  isWideFormat: Maybe<Scalars['Boolean']['output']>;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQIReportItem = {
  id: Scalars['ID']['output'];
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQITcfdOpportunity = {
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export type GQITcfdRisk = {
  highTrajectoryImpact: GQTcfdRiskImpact;
  id: Scalars['ID']['output'];
  kind: GQTcfdRiskKind;
  lowTrajectoryImpact: GQTcfdRiskImpact;
  mediumTrajectoryImpact: GQTcfdRiskImpact;
  physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
  possibleImpactDescription: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  userDescription: Scalars['String']['output'];
};

export const GQIciAlignmentStatus = {
  Aligned: 'Aligned',
  Aligning: 'Aligning',
  CapturingData: 'CapturingData',
  NotStarted: 'NotStarted',
  PreparingToDecarbonize: 'PreparingToDecarbonize',
} as const;

export type GQIciAlignmentStatus =
  (typeof GQIciAlignmentStatus)[keyof typeof GQIciAlignmentStatus];
export type GQIdInput = {
  id: Scalars['ID']['input'];
};

export const GQImportFlowDataCleaningStep = {
  AdditionalQuestions: 'AdditionalQuestions',
  ColumnMapping: 'ColumnMapping',
  DataFormat: 'DataFormat',
  DataMelt: 'DataMelt',
  FooterSelection: 'FooterSelection',
  HeaderSelection: 'HeaderSelection',
} as const;

export type GQImportFlowDataCleaningStep =
  (typeof GQImportFlowDataCleaningStep)[keyof typeof GQImportFlowDataCleaningStep];
export type GQIndustryClassification = {
  __typename?: 'IndustryClassification';
  id: Scalars['ID']['output'];
  longDescription: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
};

export type GQIngestedSbtiCompanyUpdate = GQIdInterface & {
  __typename?: 'IngestedSbtiCompanyUpdate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sbtiCompanyId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQIngestionCustomField = GQIdInterface & {
  __typename?: 'IngestionCustomField';
  appliesToAllDatasets: Scalars['Boolean']['output'];
  appliesToAllUtilityTypes: Scalars['Boolean']['output'];
  author: Maybe<GQUser>;
  bartTagName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  exampleValues: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  ingestionCustomFieldDatasources: Maybe<
    Array<GQIngestionCustomFieldDatasource>
  >;
  isMetric: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  possibleValues: Maybe<Array<Scalars['String']['output']>>;
  specificDatasets: Array<GQDataset>;
  specificUtilityTypes: Array<GQBuildingUtilityType>;
  type: GQIngestionCustomFieldType;
  usedValues: Array<Scalars['String']['output']>;
};

export type GQIngestionCustomFieldDatasource = GQIdInterface & {
  __typename?: 'IngestionCustomFieldDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasetId: Scalars['ID']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  ingestionCustomFieldId: Scalars['ID']['output'];
  ingestionCustomFieldValue: Scalars['String']['output'];
  numUserUploadTasks: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQIngestionCustomFieldType = {
  Boolean: 'Boolean',
  Date: 'Date',
  Number: 'Number',
  String: 'String',
  StringEnum: 'StringEnum',
} as const;

export type GQIngestionCustomFieldType =
  (typeof GQIngestionCustomFieldType)[keyof typeof GQIngestionCustomFieldType];
export type GQIngestionExampleData = {
  __typename?: 'IngestionExampleData';
  columns: Array<GQIngestionExampleDataColumn>;
  datasetId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  rows: Array<GQIngestionExampleDataRow>;
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataColumn = {
  __typename?: 'IngestionExampleDataColumn';
  defaultValue: Maybe<Scalars['JSONString']['output']>;
  description: Scalars['String']['output'];
  examples: Array<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  optionalToMap: Maybe<Scalars['Boolean']['output']>;
  required: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type GQIngestionExampleDataRow = {
  __typename?: 'IngestionExampleDataRow';
  fields: Array<GQIngestionExampleDataRowField>;
};

export type GQIngestionExampleDataRowField = {
  __typename?: 'IngestionExampleDataRowField';
  fieldName: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionQuestion = GQIdInterface & {
  __typename?: 'IngestionQuestion';
  id: Scalars['ID']['output'];
  options: Array<GQIngestionQuestionOption>;
  responseType: GQIngestionQuestionResponseType;
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQIngestionQuestionAnswer = {
  __typename?: 'IngestionQuestionAnswer';
  booleanValue: Maybe<Scalars['Boolean']['output']>;
  numberValue: Maybe<Scalars['Int']['output']>;
  stringValue: Maybe<Scalars['String']['output']>;
  yearMonthValue: Maybe<Scalars['YearMonth']['output']>;
};

export const GQIngestionQuestionKey = {
  DataFormatIsUnmelted: 'DataFormatIsUnmelted',
  EmployeeTravelExpensesIncludedTravelExpenseTypes:
    'EmployeeTravelExpensesIncludedTravelExpenseTypes',
  EmployeeTravelExpensesPercentCoverage:
    'EmployeeTravelExpensesPercentCoverage',
  EmployeeTravelExpensesPercentCoverageIsUnknown:
    'EmployeeTravelExpensesPercentCoverageIsUnknown',
  EmployeeTravelExpensesTimeRangeEndDate:
    'EmployeeTravelExpensesTimeRangeEndDate',
  EmployeeTravelExpensesTimeRangeStartDate:
    'EmployeeTravelExpensesTimeRangeStartDate',
  EmployeesAccountedForCovid: 'EmployeesAccountedForCovid',
  EmployeesIncludedContractors: 'EmployeesIncludedContractors',
  EmployeesTimeDifferedBetweenOffices: 'EmployeesTimeDifferedBetweenOffices',
  EmployeesTimeDifferedBetweenOfficesAdditionalInfo:
    'EmployeesTimeDifferedBetweenOfficesAdditionalInfo',
  EmployeesWorkedRemotely: 'EmployeesWorkedRemotely',
  EmployeesWorkedRemotelyEndDate: 'EmployeesWorkedRemotelyEndDate',
  EmployeesWorkedRemotelyStartDate: 'EmployeesWorkedRemotelyStartDate',
  FlightsCancellationsIncluded: 'FlightsCancellationsIncluded',
  FlightsCancellationsIncludedColumn: 'FlightsCancellationsIncludedColumn',
  FlightsCancellationsIncludedOther: 'FlightsCancellationsIncludedOther',
  FlightsDataCoverageEndDate: 'FlightsDataCoverageEndDate',
  FlightsDataCoverageStartDate: 'FlightsDataCoverageStartDate',
  FlightsRoundTripsIncluded: 'FlightsRoundTripsIncluded',
  FlightsRoundTripsIncludedColumn: 'FlightsRoundTripsIncludedColumn',
  RailCancellationsIncluded: 'RailCancellationsIncluded',
  RailCancellationsIncludedColumn: 'RailCancellationsIncludedColumn',
  RailCancellationsIncludedOther: 'RailCancellationsIncludedOther',
  RailDataCoverageEndDate: 'RailDataCoverageEndDate',
  RailDataCoverageStartDate: 'RailDataCoverageStartDate',
  RailRoundTripsIncluded: 'RailRoundTripsIncluded',
  RailRoundTripsIncludedColumn: 'RailRoundTripsIncludedColumn',
} as const;

export type GQIngestionQuestionKey =
  (typeof GQIngestionQuestionKey)[keyof typeof GQIngestionQuestionKey];
export type GQIngestionQuestionOption = {
  __typename?: 'IngestionQuestionOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQIngestionQuestionResponse = {
  __typename?: 'IngestionQuestionResponse';
  answer: Maybe<GQIngestionQuestionAnswer>;
  questionId: Scalars['String']['output'];
  responseType: GQIngestionQuestionResponseType;
};

export const GQIngestionQuestionResponseType = {
  Boolean: 'Boolean',
  Number: 'Number',
  String: 'String',
  YearMonth: 'YearMonth',
} as const;

export type GQIngestionQuestionResponseType =
  (typeof GQIngestionQuestionResponseType)[keyof typeof GQIngestionQuestionResponseType];
export type GQInitializeDefaultSupplierViewPayload = {
  __typename?: 'InitializeDefaultSupplierViewPayload';
  view: GQSupplierView;
};

export type GQInitiative = {
  costTimeseries: GQSimpleTimeseries;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  plan: GQPlan;
  scheduleType: GQScheduleType;
};

export const GQInitiativeKind = {
  FootprintSlice: 'FootprintSlice',
  PrecomputedActivityBased: 'PrecomputedActivityBased',
} as const;

export type GQInitiativeKind =
  (typeof GQInitiativeKind)[keyof typeof GQInitiativeKind];
export const GQInitiativeType = {
  Buildings: 'Buildings',
  Cloud: 'Cloud',
  Commute: 'Commute',
  Custom: 'Custom',
  Travel: 'Travel',
} as const;

export type GQInitiativeType =
  (typeof GQInitiativeType)[keyof typeof GQInitiativeType];
export type GQInputDataQueryResult = {
  __typename?: 'InputDataQueryResult';
  rows: Array<Scalars['JSONString']['output']>;
  totalRows: Scalars['Int53']['output'];
};

export type GQInstructionsBundle = {
  __typename?: 'InstructionsBundle';
  ghgScopes: Maybe<Array<GQGhgScope>>;
  instructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  instructionsIntroMd: Maybe<Scalars['String']['output']>;
  instructionsSteps: Maybe<Array<GQInstructionsStep>>;
  potentialOverlaps: Maybe<Scalars['String']['output']>;
  sdiInstructionsDataFormattingMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsFaq: Maybe<Array<GQSdiInstructionsFaqType>>;
  sdiInstructionsIntroMd: Maybe<Scalars['String']['output']>;
  sdiInstructionsSteps: Maybe<Array<GQSdiInstructionsStep>>;
  usedFor: Maybe<Scalars['String']['output']>;
};

export type GQInstructionsStep = {
  __typename?: 'InstructionsStep';
  description: Scalars['String']['output'];
  descriptionMd: Scalars['String']['output'];
  fileMetadataId: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  remotePath: Maybe<Scalars['String']['output']>;
};

export type GQIntegrationConnection = GQIdInterface & {
  __typename?: 'IntegrationConnection';
  additionalIdentifier: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datasources: GQDatasourceConnection;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  integrationDataPulls: GQIntegrationDataPullConnection;
  integrationPartner: GQIntegrationPartner;
  integrationPartnerId: Scalars['ID']['output'];
  integrationRequests: GQIntegrationRequestConnection;
  isActive: Scalars['Boolean']['output'];
  netsuiteIntegrationSettings: Maybe<GQNetsuiteIntegrationSettings>;
  org: GQOrganization;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationConnectionDatasourcesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationDataPullsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionIntegrationRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQIntegrationConnectionConnection = {
  __typename?: 'IntegrationConnectionConnection';
  edges: Array<Maybe<GQIntegrationConnectionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationConnectionEdge = {
  __typename?: 'IntegrationConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationConnection>;
};

export type GQIntegrationDataPull = GQIdInterface & {
  __typename?: 'IntegrationDataPull';
  adtName: Scalars['String']['output'];
  adtUrl: Scalars['String']['output'];
  backgroundJob: Maybe<GQBackgroundJob>;
  chunksFetched: Scalars['Int']['output'];
  completed: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQWatershedEmployee;
  endYearMonth: Scalars['YearMonth']['output'];
  filesProcessed: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  integrationConnection: GQIntegrationConnection;
  startYearMonth: Scalars['YearMonth']['output'];
  totalChunksToFetch: Maybe<Scalars['Int']['output']>;
  totalFilesToProcess: Maybe<Scalars['Int']['output']>;
  userUploadedTable: Maybe<GQUserUploadedTable>;
};

export type GQIntegrationDataPullConnection = {
  __typename?: 'IntegrationDataPullConnection';
  edges: Array<Maybe<GQIntegrationDataPullEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationDataPullEdge = {
  __typename?: 'IntegrationDataPullEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationDataPull>;
};

export type GQIntegrationPartner = GQIdInterface & {
  __typename?: 'IntegrationPartner';
  activeIntegrationConnections: Array<GQIntegrationConnection>;
  applicableDatasources: Array<GQDatasource>;
  dataType: Scalars['String']['output'];
  externalId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isRequestOnly: Scalars['Boolean']['output'];
  logoUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GQIntegrationPartnerConnection = {
  __typename?: 'IntegrationPartnerConnection';
  edges: Array<Maybe<GQIntegrationPartnerEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationPartnerEdge = {
  __typename?: 'IntegrationPartnerEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationPartner>;
};

export type GQIntegrationRequest = {
  __typename?: 'IntegrationRequest';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationConnectionId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  requestBody: Maybe<Scalars['String']['output']>;
  requestMethod: Scalars['String']['output'];
  requestUrl: Maybe<Scalars['String']['output']>;
  responseBodyFileMetadataId: Maybe<Scalars['ID']['output']>;
  responseFileMetadata: Maybe<GQFileMetadata>;
  responseStatus: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQIntegrationRequestConnection = {
  __typename?: 'IntegrationRequestConnection';
  edges: Array<Maybe<GQIntegrationRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQIntegrationRequestEdge = {
  __typename?: 'IntegrationRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQIntegrationRequest>;
};

export type GQIntensityDenominatorDataPoint = {
  __typename?: 'IntensityDenominatorDataPoint';
  value: Scalars['Float']['output'];
  yearMonth: Scalars['YearMonth']['output'];
};

export type GQIntensityDenominatorFilters = {
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
};

export const GQIntensityDenominatorKind = {
  Gmv: 'GMV',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  Megawatts: 'Megawatts',
  Merchants: 'Merchants',
  MonthlyActiveUsers: 'MonthlyActiveUsers',
  NetRevenue: 'NetRevenue',
  NightsStayed: 'NightsStayed',
  OperationalRevenue: 'OperationalRevenue',
  Orders: 'Orders',
  OrdersKg: 'OrdersKg',
  Patients: 'Patients',
  PayingSitesUnderManagement: 'PayingSitesUnderManagement',
  Revenue: 'Revenue',
  Shipments: 'Shipments',
  SquareFeet: 'SquareFeet',
  SupplierSpend: 'SupplierSpend',
  Units: 'Units',
} as const;

export type GQIntensityDenominatorKind =
  (typeof GQIntensityDenominatorKind)[keyof typeof GQIntensityDenominatorKind];
export type GQIntensityDenominatorRecord = {
  __typename?: 'IntensityDenominatorRecord';
  aggregateKind: GQAggregateKind;
  data: Array<GQIntensityDenominatorDataPoint>;
  intensityKind: GQIntensityDenominatorKind;
};

export type GQInterimTargetInput = {
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export const GQInternalFieldWatershed = {
  InternalCategoryId: 'internalCategoryId',
  InternalDescription: 'internalDescription',
  InternalGhgCategoryId: 'internalGhgCategoryId',
  InternalSubcategoryId: 'internalSubcategoryId',
} as const;

export type GQInternalFieldWatershed =
  (typeof GQInternalFieldWatershed)[keyof typeof GQInternalFieldWatershed];
export type GQJoinProductWaitlistPayload = {
  __typename?: 'JoinProductWaitlistPayload';
  id: Scalars['ID']['output'];
  type: GQProductWaitlistType;
};

export type GQJoinReportWaitlistPayload = {
  __typename?: 'JoinReportWaitlistPayload';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
};

export type GQLabeledTimeseries = {
  __typename?: 'LabeledTimeseries';
  label: Scalars['String']['output'];
  timeseries: GQTimeseries;
};

export type GQLatestSupplierAutoMappingRun = {
  __typename?: 'LatestSupplierAutoMappingRun';
  entity: GQAutoMappingEntity;
  id: Scalars['ID']['output'];
  status: GQSupplierAutoMappingRunStatus;
  workflowId: Scalars['String']['output'];
};

export type GQLeaveProductWaitlistPayload = {
  __typename?: 'LeaveProductWaitlistPayload';
  type: GQProductWaitlistType;
};

export type GQLeaveReportWaitlistPayload = {
  __typename?: 'LeaveReportWaitlistPayload';
  kind: Scalars['String']['output'];
};

export type GQLegacyFootprint = {
  __typename?: 'LegacyFootprint';
  footprintKinds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  intensityDenominators: Array<GQIntensityDenominatorRecord>;
  summary: GQFootprintSummary;
  version: Scalars['String']['output'];
};

export type GQLegacyFootprintIntensityDenominatorsArgs = {
  filters: InputMaybe<GQIntensityDenominatorFilters>;
};

export type GQLegacyFootprintSummaryArgs = {
  footprintKind: Scalars['String']['input'];
};

export type GQLifecycleAssessment = {
  __typename?: 'LifecycleAssessment';
  boundary: GQLifecycleAssessmentBoundaryEnum;
  createdAt: Scalars['DateTime']['output'];
  dataUsedNotes: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  productDescription: Maybe<Scalars['String']['output']>;
  productName: Scalars['String']['output'];
  productUnit: Scalars['String']['output'];
  productionGraphData: Maybe<GQProductionGraphData>;
  referenceEndDate: Scalars['DateTime']['output'];
  referenceStartDate: Scalars['DateTime']['output'];
  supplierProductExternalId: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  validityEndDate: Scalars['DateTime']['output'];
  validityStartDate: Scalars['DateTime']['output'];
};

export const GQLifecycleAssessmentBoundaryEnum = {
  CradleToGate: 'CradleToGate',
  CradleToGrave: 'CradleToGrave',
  CradleToSite: 'CradleToSite',
  GateToGate: 'GateToGate',
} as const;

export type GQLifecycleAssessmentBoundaryEnum =
  (typeof GQLifecycleAssessmentBoundaryEnum)[keyof typeof GQLifecycleAssessmentBoundaryEnum];
export const GQLifecycleStage = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  A4: 'A4',
} as const;

export type GQLifecycleStage =
  (typeof GQLifecycleStage)[keyof typeof GQLifecycleStage];
export type GQLineageAssetFileMetadataTableReference = {
  __typename?: 'LineageAssetFileMetadataTableReference';
  alias: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export type GQLineageRun = {
  __typename?: 'LineageRun';
  additionalOutput: Maybe<Scalars['JSONString']['output']>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  jobSlug: Scalars['String']['output'];
  parameters: Maybe<Scalars['JSONString']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

export type GQLintReportConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQLintReportConfigPayload = {
  __typename?: 'LintReportConfigPayload';
  lintErrors: Array<GQConfigLinterError>;
  status: GQConfigLinterStatus;
};

export type GQLiteDataset = {
  __typename?: 'LiteDataset';
  datasetName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQLogCustomerHubViewedInput = {
  customerCompanyId: Scalars['ID']['input'];
};

export type GQLogCustomerHubViewedPayload = {
  __typename?: 'LogCustomerHubViewedPayload';
  customerCompanyId: Scalars['ID']['output'];
};

export type GQLogSupplyChainSurveyEventInput = {
  eventContext: GQSupplyChainSurveyEventContext;
  eventType: Scalars['String']['input'];
};

export type GQLogSupplyChainSurveyEventPayload = {
  __typename?: 'LogSupplyChainSurveyEventPayload';
  success: Scalars['Boolean']['output'];
};

export type GQLogUpdateVendorMatchingTaskInput = {
  vendorMatchingTaskId: Scalars['ID']['input'];
};

export type GQLogUpdateVendorMatchingTaskPayload = {
  __typename?: 'LogUpdateVendorMatchingTaskPayload';
  vendorMatchingTaskId: Scalars['ID']['output'];
};

export type GQMappedExternalReportQuestions = {
  __typename?: 'MappedExternalReportQuestions';
  id: Scalars['ID']['output'];
  identicalQuestions: Array<GQExternalReportQuestion>;
  relatedQuestions: Array<GQExternalReportQuestion>;
};

export type GQMarkDemoPlatformRunAsFailedInput = {
  id: Scalars['ID']['input'];
};

export type GQMarkDemoPlatformRunAsFailedPayload = {
  __typename?: 'MarkDemoPlatformRunAsFailedPayload';
  demoPlatformRun: GQDemoPlatformRun;
};

export type GQMarkSupportCaseNotificationReadInput = {
  supportCaseId: Scalars['ID']['input'];
};

export type GQMarkSupportCaseNotificationReadPayload = {
  __typename?: 'MarkSupportCaseNotificationReadPayload';
  supportCase: GQSupportCase;
};

export type GQMarkUserUploadAsProcessingInput = {
  fileId: Scalars['ID']['input'];
  shouldSubmitUserUploadTask?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQMarkUserUploadAsProcessingPayload = {
  __typename?: 'MarkUserUploadAsProcessingPayload';
  userUpload: Maybe<GQUserUpload>;
};

export type GQMarkUserUploadCompletedInput = {
  fileId: Scalars['ID']['input'];
};

export type GQMarkUserUploadCompletedPayload = {
  __typename?: 'MarkUserUploadCompletedPayload';
  file: GQFileMetadata;
  parseJobId: Maybe<Scalars['String']['output']>;
  userUpload: Maybe<GQUserUpload>;
};

export type GQMarkdownCardInfo = {
  __typename?: 'MarkdownCardInfo';
  contentMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMarketplaceAllocationInstruction = {
  __typename?: 'MarketplaceAllocationInstruction';
  amountKwh: Scalars['Int']['output'];
  buildingName: Maybe<Scalars['String']['output']>;
  categoryId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  footprintKind: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
  locationCity: Maybe<Scalars['String']['output']>;
  locationCountry: Maybe<Scalars['String']['output']>;
  locationGrid: Maybe<Scalars['String']['output']>;
  locationState: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  results: Maybe<GQMarketplaceAllocationInstructionResultConnection>;
  subcategoryId: Maybe<Scalars['String']['output']>;
  usedInFootprintSnapshot: Scalars['Boolean']['output'];
  vendorEntity: Maybe<Scalars['String']['output']>;
  vendorSubentity: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceAllocationInstructionResultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceAllocationInstructionConnection = {
  __typename?: 'MarketplaceAllocationInstructionConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionEdge = {
  __typename?: 'MarketplaceAllocationInstructionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstruction>;
};

export type GQMarketplaceAllocationInstructionResult = {
  __typename?: 'MarketplaceAllocationInstructionResult';
  amountAllocatedKwh: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isZeroedOut: Scalars['Boolean']['output'];
};

export type GQMarketplaceAllocationInstructionResultConnection = {
  __typename?: 'MarketplaceAllocationInstructionResultConnection';
  edges: Array<Maybe<GQMarketplaceAllocationInstructionResultEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceAllocationInstructionResultEdge = {
  __typename?: 'MarketplaceAllocationInstructionResultEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceAllocationInstructionResult>;
};

export type GQMarketplaceDeveloper = GQIdInterface & {
  __typename?: 'MarketplaceDeveloper';
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  descriptionMd: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  projects: GQMarketplaceProjectConnection;
  url: Maybe<Scalars['String']['output']>;
};

export type GQMarketplaceDeveloperConnection = {
  __typename?: 'MarketplaceDeveloperConnection';
  edges: Array<Maybe<GQMarketplaceDeveloperEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDeveloperEdge = {
  __typename?: 'MarketplaceDeveloperEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDeveloper>;
};

export type GQMarketplaceDocument = GQIdInterface & {
  __typename?: 'MarketplaceDocument';
  createdAt: Scalars['Date']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  downloadUrl: Scalars['String']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  kind: GQMarketplaceDocumentKind;
  name: Scalars['String']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  org: GQOrganization;
  purchase: Maybe<GQMarketplacePurchase>;
  purchaseLineItem: Maybe<GQMarketplacePurchaseLineItem>;
  updatedAt: Scalars['Date']['output'];
  uploader: Maybe<GQWatershedEmployee>;
};

export type GQMarketplaceDocumentConnection = {
  __typename?: 'MarketplaceDocumentConnection';
  edges: Array<Maybe<GQMarketplaceDocumentEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceDocumentEdge = {
  __typename?: 'MarketplaceDocumentEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceDocument>;
};

export const GQMarketplaceDocumentKind = {
  ContractAppendix: 'ContractAppendix',
  ContractMasterAgreement: 'ContractMasterAgreement',
  ContractSupplierErpa: 'ContractSupplierERPA',
  ContractSupplierOffering: 'ContractSupplierOffering',
  DeliveryCertificateSupplier: 'DeliveryCertificateSupplier',
  DeliveryCertificateWatershed: 'DeliveryCertificateWatershed',
  InvoiceCustomer: 'InvoiceCustomer',
  InvoiceSupplier: 'InvoiceSupplier',
  RetirementNotice: 'RetirementNotice',
} as const;

export type GQMarketplaceDocumentKind =
  (typeof GQMarketplaceDocumentKind)[keyof typeof GQMarketplaceDocumentKind];
export type GQMarketplacePointOfContact = GQIdInterface & {
  __typename?: 'MarketplacePointOfContact';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQMarketplacePointOfContactConnection = {
  __typename?: 'MarketplacePointOfContactConnection';
  edges: Array<Maybe<GQMarketplacePointOfContactEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePointOfContactEdge = {
  __typename?: 'MarketplacePointOfContactEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePointOfContact>;
};

export type GQMarketplaceProject = GQIdInterface & {
  __typename?: 'MarketplaceProject';
  archetype: GQMarketplaceProjectArchetype;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  developer: Maybe<GQMarketplaceDeveloper>;
  id: Scalars['ID']['output'];
  internalNotesMd: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  registryId: Maybe<Scalars['String']['output']>;
  status: GQMarketplaceProjectStatus;
  supplier: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceProjectOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetype = GQIdInterface & {
  __typename?: 'MarketplaceProjectArchetype';
  additionalityRating: Maybe<Scalars['Int']['output']>;
  availability: Scalars['Float']['output'];
  bannerImageUrl: Maybe<Scalars['String']['output']>;
  certificateBlurb: Maybe<Scalars['String']['output']>;
  certificateColor: Maybe<Scalars['String']['output']>;
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  isUserVisible: Scalars['Boolean']['output'];
  kind: GQMarketplaceProjectArchetypeKind;
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  medianPriceUsdCents: Maybe<Scalars['Int']['output']>;
  medianPriceWithFeeUsdCents: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  permanenceRating: Maybe<Scalars['String']['output']>;
  priceUsdCentsMax: Maybe<Scalars['Int']['output']>;
  priceUsdCentsMin: Maybe<Scalars['Int']['output']>;
  projects: GQMarketplaceProjectConnection;
  summaryMd: Scalars['String']['output'];
  unit: GQMarketplaceProjectArchetypeUnit;
  visibleDevelopers: GQMarketplaceDeveloperConnection;
};

export type GQMarketplaceProjectArchetypeOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeVisibleDevelopersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceProjectArchetypeConnection = {
  __typename?: 'MarketplaceProjectArchetypeConnection';
  edges: Array<Maybe<GQMarketplaceProjectArchetypeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectArchetypeEdge = {
  __typename?: 'MarketplaceProjectArchetypeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectArchetype>;
};

export const GQMarketplaceProjectArchetypeKind = {
  CarbonOffset: 'CarbonOffset',
  CleanEnergyCertificate: 'CleanEnergyCertificate',
  HighDemandCarbonOffset: 'HighDemandCarbonOffset',
  MatureCarbonRemoval: 'MatureCarbonRemoval',
  RAndDCarbonRemoval: 'RAndDCarbonRemoval',
} as const;

export type GQMarketplaceProjectArchetypeKind =
  (typeof GQMarketplaceProjectArchetypeKind)[keyof typeof GQMarketplaceProjectArchetypeKind];
export const GQMarketplaceProjectArchetypeMechanism = {
  CleanPower: 'CleanPower',
  Offset: 'Offset',
  Removal: 'Removal',
} as const;

export type GQMarketplaceProjectArchetypeMechanism =
  (typeof GQMarketplaceProjectArchetypeMechanism)[keyof typeof GQMarketplaceProjectArchetypeMechanism];
export const GQMarketplaceProjectArchetypeUnit = {
  MWh: 'MWh',
  Tonnes: 'Tonnes',
} as const;

export type GQMarketplaceProjectArchetypeUnit =
  (typeof GQMarketplaceProjectArchetypeUnit)[keyof typeof GQMarketplaceProjectArchetypeUnit];
export type GQMarketplaceProjectConnection = {
  __typename?: 'MarketplaceProjectConnection';
  edges: Array<Maybe<GQMarketplaceProjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectEdge = {
  __typename?: 'MarketplaceProjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProject>;
};

export type GQMarketplaceProjectOffering = GQIdInterface & {
  __typename?: 'MarketplaceProjectOffering';
  availability: Scalars['Float']['output'];
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  noteMd: Maybe<Scalars['String']['output']>;
  priceUsdCents: Scalars['Float']['output'];
  priceWithFeeUsdCents: Scalars['Float']['output'];
  project: GQMarketplaceProject;
  supplier: GQMarketplaceSupplier;
  vintage: Maybe<Scalars['Float']['output']>;
};

export type GQMarketplaceProjectOfferingConnection = {
  __typename?: 'MarketplaceProjectOfferingConnection';
  edges: Array<Maybe<GQMarketplaceProjectOfferingEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceProjectOfferingEdge = {
  __typename?: 'MarketplaceProjectOfferingEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceProjectOffering>;
};

export const GQMarketplaceProjectStatus = {
  Active: 'Active',
  LowQuality: 'LowQuality',
  Unknown: 'Unknown',
} as const;

export type GQMarketplaceProjectStatus =
  (typeof GQMarketplaceProjectStatus)[keyof typeof GQMarketplaceProjectStatus];
export type GQMarketplacePurchase = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchase';
    createdAt: Scalars['DateTime']['output'];
    customerStatus: GQCustomerStatus;
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseHistoryConnection;
    id: Scalars['ID']['output'];
    lineItems: GQMarketplacePurchaseLineItemConnection;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    status: GQMarketplacePurchaseStatus;
    totalCostUsdCents: Scalars['Int53']['output'];
    totalFeeUsdCents: Scalars['Int53']['output'];
    updatedAt: Scalars['DateTime']['output'];
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseConnection = {
  __typename?: 'MarketplacePurchaseConnection';
  edges: Array<Maybe<GQMarketplacePurchaseEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseEacMetadata = {
  __typename?: 'MarketplacePurchaseEacMetadata';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  ghgCategoryIds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
  marketTco2e: Scalars['Float']['output'];
  org: GQOrganization;
  purchase: GQMarketplacePurchase;
  requestNotes: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseEdge = {
  __typename?: 'MarketplacePurchaseEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchase>;
};

export type GQMarketplacePurchaseHistory = GQIdInterface &
  GQMarketplacePurchaseTrackedProperties & {
    __typename?: 'MarketplacePurchaseHistory';
    createdAt: Scalars['DateTime']['output'];
    customerUser: Maybe<GQUser>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offerExpiration: Maybe<Scalars['DateTime']['output']>;
    org: GQOrganization;
    purchaseId: Scalars['ID']['output'];
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseStatus;
    updatedAt: Scalars['DateTime']['output'];
  };

export type GQMarketplacePurchaseHistoryConnection = {
  __typename?: 'MarketplacePurchaseHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseHistoryEdge = {
  __typename?: 'MarketplacePurchaseHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseHistory>;
};

export type GQMarketplacePurchaseLineItem = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItem';
    allocations: GQMarketplaceAllocationInstructionConnection;
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    appliedToFootprint: Maybe<Scalars['Boolean']['output']>;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    documents: GQMarketplaceDocumentConnection;
    eacMetadata: Maybe<GQMarketplacePurchaseLineItemEacMetadata>;
    editor: Maybe<GQTrackedEditor>;
    history: GQMarketplacePurchaseLineItemHistoryConnection;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isConfirmed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
    waitingOnParty: Maybe<GQMarketplaceWaitingOnParty>;
  };

export type GQMarketplacePurchaseLineItemAllocationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemDocumentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplacePurchaseLineItemConnection = {
  __typename?: 'MarketplacePurchaseLineItemConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemEacMetadata = {
  __typename?: 'MarketplacePurchaseLineItemEacMetadata';
  countryWithEacs: Scalars['String']['output'];
  countryWithEmissions: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  marketTco2e: Scalars['Float']['output'];
  mwh: Scalars['Float']['output'];
  numEacsRequested: Scalars['Int']['output'];
  org: GQOrganization;
  purchaseLineItem: GQMarketplacePurchaseLineItem;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplacePurchaseLineItemEdge = {
  __typename?: 'MarketplacePurchaseLineItemEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItem>;
};

export type GQMarketplacePurchaseLineItemHistory = GQIdInterface &
  GQMarketplacePurchaseLineItemTrackedProperties & {
    __typename?: 'MarketplacePurchaseLineItemHistory';
    amount: Scalars['Float']['output'];
    amountUnit: GQMarketplaceProjectArchetypeUnit;
    archetype: Maybe<GQMarketplaceProjectArchetype>;
    cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
    closeDate: Maybe<Scalars['Date']['output']>;
    coverageInterval: Scalars['YMInterval']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    deliveryDate: Maybe<Scalars['Date']['output']>;
    editor: Maybe<GQTrackedEditor>;
    id: Scalars['ID']['output'];
    isClosed: Scalars['Boolean']['output'];
    isDelivered: Scalars['Boolean']['output'];
    lineItemId: Scalars['ID']['output'];
    mechanism: GQMarketplaceProjectArchetypeMechanism;
    name: Scalars['String']['output'];
    noteMd: Scalars['String']['output'];
    offering: Maybe<GQMarketplaceProjectOffering>;
    org: GQOrganization;
    project: Maybe<GQMarketplaceProject>;
    purchase: GQMarketplacePurchase;
    recordEndAt: Maybe<Scalars['DateTime']['output']>;
    recordStartAt: Scalars['DateTime']['output'];
    status: GQMarketplacePurchaseLineItemStatus;
    unitCostUsdCents: Scalars['Float']['output'];
    unitFeeUsdCents: Scalars['Float']['output'];
    unitPriceUsdCents: Scalars['Float']['output'];
    updatedAt: Scalars['DateTime']['output'];
    vintage: Maybe<Scalars['Int']['output']>;
  };

export type GQMarketplacePurchaseLineItemHistoryConnection = {
  __typename?: 'MarketplacePurchaseLineItemHistoryConnection';
  edges: Array<Maybe<GQMarketplacePurchaseLineItemHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplacePurchaseLineItemHistoryEdge = {
  __typename?: 'MarketplacePurchaseLineItemHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplacePurchaseLineItemHistory>;
};

export const GQMarketplacePurchaseLineItemStatus = {
  Draft: 'Draft',
  SupplierDeliveredToWatershed: 'SupplierDeliveredToWatershed',
  SupplierSentInvoiceToWatershed: 'SupplierSentInvoiceToWatershed',
  WatershedDeliveredToCustomer: 'WatershedDeliveredToCustomer',
  WatershedPaidInvoiceToSupplier: 'WatershedPaidInvoiceToSupplier',
  WatershedSentRequestToSupplier: 'WatershedSentRequestToSupplier',
} as const;

export type GQMarketplacePurchaseLineItemStatus =
  (typeof GQMarketplacePurchaseLineItemStatus)[keyof typeof GQMarketplacePurchaseLineItemStatus];
export type GQMarketplacePurchaseLineItemTrackedProperties = {
  amount: Scalars['Float']['output'];
  amountUnit: GQMarketplaceProjectArchetypeUnit;
  archetype: Maybe<GQMarketplaceProjectArchetype>;
  cleanPowerContractualInstrument: Maybe<GQCleanPowerContractualInstrument>;
  closeDate: Maybe<Scalars['Date']['output']>;
  coverageInterval: Scalars['YMInterval']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  deliveryDate: Maybe<Scalars['Date']['output']>;
  editor: Maybe<GQTrackedEditor>;
  isClosed: Scalars['Boolean']['output'];
  isDelivered: Scalars['Boolean']['output'];
  mechanism: GQMarketplaceProjectArchetypeMechanism;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offering: Maybe<GQMarketplaceProjectOffering>;
  project: Maybe<GQMarketplaceProject>;
  purchase: GQMarketplacePurchase;
  status: GQMarketplacePurchaseLineItemStatus;
  unitCostUsdCents: Scalars['Float']['output'];
  unitFeeUsdCents: Scalars['Float']['output'];
  unitPriceUsdCents: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vintage: Maybe<Scalars['Int']['output']>;
};

export const GQMarketplacePurchaseStatus = {
  CustomerCanceled: 'CustomerCanceled',
  CustomerConfirmedPurchase: 'CustomerConfirmedPurchase',
  CustomerInitiatedPurchase: 'CustomerInitiatedPurchase',
  CustomerPaidInvoiceAwaitingDelivery: 'CustomerPaidInvoiceAwaitingDelivery',
  CustomerRequestToWatershed: 'CustomerRequestToWatershed',
  CustomerSignedContract: 'CustomerSignedContract',
  WatershedConfirmedForCustomer: 'WatershedConfirmedForCustomer',
  WatershedFullyDelivered: 'WatershedFullyDelivered',
  WatershedPartiallyDelivered: 'WatershedPartiallyDelivered',
  WatershedSentContract: 'WatershedSentContract',
  WatershedSentInvoice: 'WatershedSentInvoice',
} as const;

export type GQMarketplacePurchaseStatus =
  (typeof GQMarketplacePurchaseStatus)[keyof typeof GQMarketplacePurchaseStatus];
export type GQMarketplacePurchaseTrackedProperties = {
  createdAt: Scalars['DateTime']['output'];
  customerUser: Maybe<GQUser>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editor: Maybe<GQTrackedEditor>;
  name: Scalars['String']['output'];
  noteMd: Scalars['String']['output'];
  offerExpiration: Maybe<Scalars['DateTime']['output']>;
  status: GQMarketplacePurchaseStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQMarketplaceSupplier = GQIdInterface & {
  __typename?: 'MarketplaceSupplier';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offerings: GQMarketplaceProjectOfferingConnection;
  pointsOfContact: Array<GQMarketplacePointOfContact>;
  projects: GQMarketplaceProjectConnection;
  watershedEmployees: Array<GQWatershedEmployee>;
};

export type GQMarketplaceSupplierOfferingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierProjectsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMarketplaceSupplierConnection = {
  __typename?: 'MarketplaceSupplierConnection';
  edges: Array<Maybe<GQMarketplaceSupplierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQMarketplaceSupplierEdge = {
  __typename?: 'MarketplaceSupplierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQMarketplaceSupplier>;
};

export type GQMarketplaceVoluntaryCarbonMarketDisclosure = {
  __typename?: 'MarketplaceVoluntaryCarbonMarketDisclosure';
  url: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export const GQMarketplaceWaitingOnParty = {
  Customer: 'Customer',
  Supplier: 'Supplier',
  Watershed: 'Watershed',
} as const;

export type GQMarketplaceWaitingOnParty =
  (typeof GQMarketplaceWaitingOnParty)[keyof typeof GQMarketplaceWaitingOnParty];
export type GQMaterial = {
  __typename?: 'Material';
  consumingFacilityExternalId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  materialExternalId: Scalars['String']['output'];
  materialGroupExternalId: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantityUnit: Scalars['String']['output'];
  supplierExternalId: Maybe<Scalars['String']['output']>;
  supplierFacilityExternalId: Maybe<Scalars['String']['output']>;
  supplierMaterialExternalId: Maybe<Scalars['String']['output']>;
  tier: Scalars['Int']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMaterialFootprintRowReference = {
  __typename?: 'MaterialFootprintRowReference';
  id: Scalars['String']['output'];
  material: Scalars['String']['output'];
  percentageOfProduct: Scalars['Float']['output'];
};

export type GQMaterialFootprintRowUpdatedTaxonomy = {
  __typename?: 'MaterialFootprintRowUpdatedTaxonomy';
  emissionsPerKg: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  inputQuantity: Scalars['Float']['output'];
  inputQuantityPercentage: Maybe<Scalars['Float']['output']>;
  orgMaterial: Scalars['String']['output'];
  orgRawMaterial: Scalars['String']['output'];
  product: Array<GQProductFootprintRowReference>;
  totalEmissions: Scalars['Float']['output'];
  totalEmissionsPercent: Maybe<Scalars['Float']['output']>;
  vectors: Maybe<Array<GQVectorNameAndValues>>;
};

export type GQMaterialVariant = {
  __typename?: 'MaterialVariant';
  createdAt: Scalars['DateTime']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefaultVariant: Scalars['Boolean']['output'];
  lifecycleAssessment: Maybe<GQLifecycleAssessment>;
  lifecycleAssessmentId: Maybe<Scalars['ID']['output']>;
  materialExternalId: Maybe<Scalars['String']['output']>;
  materialGroupExternalId: Maybe<Scalars['String']['output']>;
  materialName: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quantityUnit: Maybe<Scalars['String']['output']>;
  supplierExternalId: Maybe<Scalars['String']['output']>;
  supplierName: Maybe<Scalars['String']['output']>;
  tier: Maybe<Scalars['Int']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMaterialVariantAndLcaRow = {
  __typename?: 'MaterialVariantAndLCARow';
  biogenicA1KgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicA2KgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicA3KgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicA4KgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicCradleToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicCradleToGraveKgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicCradleToSiteKgCO2e: Maybe<Scalars['Float']['output']>;
  biogenicGateToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  flagA1KgCO2e: Maybe<Scalars['Float']['output']>;
  flagA2KgCO2e: Maybe<Scalars['Float']['output']>;
  flagA3KgCO2e: Maybe<Scalars['Float']['output']>;
  flagA4KgCO2e: Maybe<Scalars['Float']['output']>;
  flagCradleToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  flagCradleToGraveKgCO2e: Maybe<Scalars['Float']['output']>;
  flagCradleToSiteKgCO2e: Maybe<Scalars['Float']['output']>;
  flagGateToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  fossilA1KgCO2e: Maybe<Scalars['Float']['output']>;
  fossilA2KgCO2e: Maybe<Scalars['Float']['output']>;
  fossilA3KgCO2e: Maybe<Scalars['Float']['output']>;
  fossilA4KgCO2e: Maybe<Scalars['Float']['output']>;
  fossilCradleToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  fossilCradleToGraveKgCO2e: Maybe<Scalars['Float']['output']>;
  fossilCradleToSiteKgCO2e: Maybe<Scalars['Float']['output']>;
  fossilGateToGateKgCO2e: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lcaBoundary: Maybe<GQLifecycleAssessmentBoundaryEnum>;
  materialExternalId: Scalars['String']['output'];
  materialName: Scalars['String']['output'];
  materialVariantDescription: Maybe<Scalars['String']['output']>;
  materialVariantGroupId: Maybe<Scalars['String']['output']>;
  materialVariantName: Scalars['String']['output'];
  materialVariantQuantityUnit: Scalars['String']['output'];
  materialVariantSupplierExternalId: Maybe<Scalars['String']['output']>;
  materialVariantTier: Scalars['Int']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQMaterialityAssessment = {
  __typename?: 'MaterialityAssessment';
  createdAt: Scalars['DateTime']['output'];
  dataPoints: Array<GQMaterialityAssessmentDataPoint>;
  id: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  org: GQOrganization;
  reports: Array<GQReport>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQMaterialityAssessmentDataPoint = {
  __typename?: 'MaterialityAssessmentDataPoint';
  frameworkDataPoint: GQReportingFrameworkDataPoint;
  id: Scalars['ID']['output'];
  status: GQMaterialityStatus;
};

export const GQMaterialityStatus = {
  Material: 'Material',
  NotMaterial: 'NotMaterial',
} as const;

export type GQMaterialityStatus =
  (typeof GQMaterialityStatus)[keyof typeof GQMaterialityStatus];
export type GQMaybeCreateIntegrationConnectionInput = {
  arcadia?: InputMaybe<GQCreateArcadiaIntegrationInput>;
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  integrationPartnerId: Scalars['String']['input'];
  merge?: InputMaybe<GQCreateMergeIntegrationInput>;
  netSuite?: InputMaybe<GQCreateNetSuiteIntegrationInput>;
  tripActions?: InputMaybe<GQCreateTripActionsIntegrationInput>;
};

export type GQMaybeCreateIntegrationConnectionPayload = {
  __typename?: 'MaybeCreateIntegrationConnectionPayload';
  datasources: Array<GQDatasource>;
  integrationConnection: Maybe<GQIntegrationConnection>;
};

export type GQMeasuredFootprintDetails = {
  __typename?: 'MeasuredFootprintDetails';
  emissionsTco2e: Scalars['Float']['output'];
  footprintSharedAt: Scalars['Date']['output'];
};

export type GQMeasurementDataMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementDataMappingTask';
    completedAt: Maybe<Scalars['DateTime']['output']>;
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
  };

export const GQMeasurementEmailType = {
  Kickoff: 'Kickoff',
  Reminder: 'Reminder',
} as const;

export type GQMeasurementEmailType =
  (typeof GQMeasurementEmailType)[keyof typeof GQMeasurementEmailType];
export type GQMeasurementParentTask = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementParentTask';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    subTasks: Array<GQMeasurementParentTask | GQMeasurementTaskFlat>;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export type GQMeasurementProject = GQClimateProgramTimelineObject &
  GQContextRelationInterface &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProject';
    active: Scalars['Boolean']['output'];
    adminUrl: Scalars['String']['output'];
    /** @deprecated Context items are being deprecated */
    ancestorRelations: Array<
      | GQDataIssue
      | GQDataset
      | GQDatasource
      | GQFileMetadata
      | GQFinancialsReviewItem
      | GQMeasurementProject
    >;
    buildingSurveyStatus: Array<GQBuildingSurveyStatus>;
    canEditCoverageInterval: Scalars['Boolean']['output'];
    climateProgramProjects: Array<GQClimateProgramProject>;
    completedAt: Maybe<Scalars['Date']['output']>;
    coverageEndDate: Scalars['Date']['output'];
    coverageInterval: Scalars['YMInterval']['output'];
    coverageStartDate: Scalars['Date']['output'];
    createdAt: Scalars['DateTime']['output'];
    dataIssues: Array<GQDataIssue>;
    datasetsWithStages: Array<GQDatasetWithStage>;
    deadline: Maybe<Scalars['Date']['output']>;
    deletedAt: Maybe<Scalars['Date']['output']>;
    facilitiesBartsStatus: GQMeasurementProjectFacilitiesBartsStatus;
    footprintStatus: GQMeasurementProjectFootprintStatus;
    id: Scalars['ID']['output'];
    isApprovalFlowEnabled: Scalars['Boolean']['output'];
    measurementProjectDatasets: Array<GQMeasurementProjectDataset>;
    measurementTasks: Array<GQMeasurementParentTask>;
    measurementTimeline: Maybe<GQMeasurementTimeline>;
    name: Scalars['String']['output'];
    orgId: Scalars['ID']['output'];
    setupAt: Maybe<Scalars['Date']['output']>;
    setupBy: Maybe<GQUser>;
    sharedFiles: GQSharedFileConnection;
    status: GQMeasurementProjectStatus;
    supportedValueMappingRules: Array<Scalars['String']['output']>;
    taskStatusDumps: Array<GQTaskStatusDump>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadDataGovernance: Array<GQUserUploadDataGovernanceInfo>;
    userUploadTasks: GQUserUploadTaskConnection;
    /** @deprecated Use userUploadDataGovernance instead */
    userUploadVerificationLogs: Array<Maybe<GQUserUploadVerificationLog>>;
    valueMappingTask: Maybe<GQValueMappingTask>;
  };

export type GQMeasurementProjectSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  forUserId: InputMaybe<Scalars['ID']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectUserUploadTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQMeasurementProjectValueMappingTaskArgs = {
  valueMappingRuleId: Scalars['String']['input'];
};

export type GQMeasurementProjectDataset = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'MeasurementProjectDataset';
    dataset: GQDataset;
    id: Scalars['ID']['output'];
    isCompleted: Scalars['Boolean']['output'];
    modelId: Maybe<Scalars['ID']['output']>;
  };

export type GQMeasurementProjectDatasetProgress = {
  __typename?: 'MeasurementProjectDatasetProgress';
  completed: Array<GQDataset>;
  needsUpload: Array<GQDataset>;
  processing: Array<GQDataset>;
};

export type GQMeasurementProjectFacilitiesBartsStatus = {
  __typename?: 'MeasurementProjectFacilitiesBartsStatus';
  debugInfo: Array<Maybe<Scalars['String']['output']>>;
  upToDate: Scalars['Boolean']['output'];
};

export const GQMeasurementProjectFootprintStatus = {
  Draft: 'Draft',
  None: 'None',
  Published: 'Published',
} as const;

export type GQMeasurementProjectFootprintStatus =
  (typeof GQMeasurementProjectFootprintStatus)[keyof typeof GQMeasurementProjectFootprintStatus];
export const GQMeasurementProjectStatus = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Planned: 'Planned',
} as const;

export type GQMeasurementProjectStatus =
  (typeof GQMeasurementProjectStatus)[keyof typeof GQMeasurementProjectStatus];
export type GQMeasurementProjectStatusShare = {
  __typename?: 'MeasurementProjectStatusShare';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQMeasurementResourceEvent = {
  __typename?: 'MeasurementResourceEvent';
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  eventData: Scalars['JSONString']['output'];
  eventKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userId: Maybe<Scalars['ID']['output']>;
};

export const GQMeasurementStatus = {
  AtRisk: 'AtRisk',
  Complete: 'Complete',
  Measuring: 'Measuring',
  OffTrack: 'OffTrack',
  OnTrack: 'OnTrack',
  Planning: 'Planning',
} as const;

export type GQMeasurementStatus =
  (typeof GQMeasurementStatus)[keyof typeof GQMeasurementStatus];
export type GQMeasurementTask = {
  approvers: Array<GQUser>;
  assignees: Array<GQUser>;
  dataset: Maybe<GQDataset>;
  id: Scalars['ID']['output'];
  isAssignedToWatershed: Scalars['Boolean']['output'];
  link: Maybe<Scalars['String']['output']>;
  lockState: Maybe<GQApprovalTargetLockState>;
  object:
    | GQCustomEmissionsFactorsSetup
    | GQDataIssue
    | GQFacilitiesSetup
    | GQFinancialsReviewGroup
    | GQFinancialsReviewItem
    | GQMeasurementDataMappingTask
    | GQMeasurementProject
    | GQMeasurementProjectDataset
    | GQUserUploadTask
    | GQValueMappingTask
    | GQVendorMatchingTask;
  status: GQTaskWatershedProcessState;
  statusSimplified: GQTaskWatershedProcessStateSimplified;
  taskGroup: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQMeasurementTaskFlat = GQIdInterface &
  GQMeasurementTask & {
    __typename?: 'MeasurementTaskFlat';
    approvers: Array<GQUser>;
    assignees: Array<GQUser>;
    dataset: Maybe<GQDataset>;
    id: Scalars['ID']['output'];
    isAssignedToWatershed: Scalars['Boolean']['output'];
    link: Maybe<Scalars['String']['output']>;
    lockState: Maybe<GQApprovalTargetLockState>;
    object:
      | GQCustomEmissionsFactorsSetup
      | GQDataIssue
      | GQFacilitiesSetup
      | GQFinancialsReviewGroup
      | GQFinancialsReviewItem
      | GQMeasurementDataMappingTask
      | GQMeasurementProject
      | GQMeasurementProjectDataset
      | GQUserUploadTask
      | GQValueMappingTask
      | GQVendorMatchingTask;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
    taskGroup: Scalars['String']['output'];
    title: Scalars['String']['output'];
  };

export const GQMeasurementTaskForDataIssueKind = {
  UserUploadTask: 'UserUploadTask',
  ValueMappingTask: 'ValueMappingTask',
} as const;

export type GQMeasurementTaskForDataIssueKind =
  (typeof GQMeasurementTaskForDataIssueKind)[keyof typeof GQMeasurementTaskForDataIssueKind];
export type GQMeasurementTaskObject = {
  id: Scalars['ID']['output'];
};

export type GQMeasurementTestRow = {
  __typename?: 'MeasurementTestRow';
  activityRow: Scalars['JSONString']['output'];
  bartRowId: Scalars['Int']['output'];
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export const GQMeasurementTestRowResultStatus = {
  Added: 'Added',
  Changed: 'Changed',
  Matched: 'Matched',
  Removed: 'Removed',
} as const;

export type GQMeasurementTestRowResultStatus =
  (typeof GQMeasurementTestRowResultStatus)[keyof typeof GQMeasurementTestRowResultStatus];
export type GQMeasurementTestSuite = {
  __typename?: 'MeasurementTestSuite';
  calculationTags: Array<GQCalculationTag>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsModelReleases: Array<GQEmissionsModelRelease>;
  footprintTestSuiteConfig: Maybe<GQFootprintTestSuiteConfig>;
  id: Scalars['ID']['output'];
  kind: Maybe<GQMeasurementTestSuiteKind>;
  latestChangelogEntry: GQMeasurementTestSuiteChangelog;
  latestFootprintTestSuiteExecution: Maybe<GQFootprintTestSuiteExecution>;
  latestMethodologyTestSuiteExecution: Maybe<GQMethodologyTestSuiteExecution>;
  measurementTestSuiteBarts: Array<GQMeasurementTestSuiteBart>;
  organization: Maybe<GQOrganization>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMeasurementTestSuiteBart = {
  __typename?: 'MeasurementTestSuiteBart';
  activityTypeName: Scalars['String']['output'];
  businessActivityType: GQBusinessActivityType;
  fieldDefaults: Scalars['JSONString']['output'];
  hiddenFields: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  measurementTestRowCount: Scalars['Int']['output'];
  measurementTestRows: Array<GQMeasurementTestRow>;
  title: Scalars['String']['output'];
};

export type GQMeasurementTestSuiteChangelog = {
  __typename?: 'MeasurementTestSuiteChangelog';
  author: Maybe<GQUser>;
  authorId: Maybe<Scalars['ID']['output']>;
  changelog: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  measurementResourceEvent: Maybe<GQMeasurementResourceEvent>;
  measurementResourceEventId: Maybe<Scalars['ID']['output']>;
};

export type GQMeasurementTestSuiteChangelogConnection = {
  __typename?: 'MeasurementTestSuiteChangelogConnection';
  edges: Array<GQMeasurementTestSuiteChangelogEdge>;
  pageInfo: GQPageInfo;
};

export type GQMeasurementTestSuiteChangelogEdge = {
  __typename?: 'MeasurementTestSuiteChangelogEdge';
  cursor: Scalars['String']['output'];
  node: GQMeasurementTestSuiteChangelog;
};

export const GQMeasurementTestSuiteKind = {
  Critical: 'Critical',
} as const;

export type GQMeasurementTestSuiteKind =
  (typeof GQMeasurementTestSuiteKind)[keyof typeof GQMeasurementTestSuiteKind];
export const GQMeasurementTestSuiteStage = {
  Footprint: 'footprint',
  Methodology: 'methodology',
} as const;

export type GQMeasurementTestSuiteStage =
  (typeof GQMeasurementTestSuiteStage)[keyof typeof GQMeasurementTestSuiteStage];
export const GQMeasurementTestSuiteStageExecutionStatus = {
  Errored: 'Errored',
  Failed: 'Failed',
  Invalid: 'Invalid',
  Passed: 'Passed',
  Queued: 'Queued',
  Running: 'Running',
} as const;

export type GQMeasurementTestSuiteStageExecutionStatus =
  (typeof GQMeasurementTestSuiteStageExecutionStatus)[keyof typeof GQMeasurementTestSuiteStageExecutionStatus];
export type GQMeasurementTimeline = GQIdInterface & {
  __typename?: 'MeasurementTimeline';
  deadline: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kickoff: Scalars['Date']['output'];
};

export type GQMethodologyConfig = {
  __typename?: 'MethodologyConfig';
  id: Scalars['ID']['output'];
  spendMethodology: Maybe<GQSpendMethodology>;
  wasteEmissionsExclusions: Maybe<Array<GQWasteEmissionsExclusion>>;
};

export type GQMethodologyConfigInput = {
  selectedReleasesByBusinessActivityType?: InputMaybe<
    Scalars['JSONString']['input']
  >;
  spendMethodology?: InputMaybe<GQSpendMethodology>;
  wasteEmissionsExclusions?: InputMaybe<Array<GQWasteEmissionsExclusionInput>>;
};

export type GQMethodologyCustomizationBundle = {
  __typename?: 'MethodologyCustomizationBundle';
  id: Scalars['String']['output'];
  orgName: Scalars['String']['output'];
  referenceDataRevisionRows: Array<GQReferenceDataRevisionRow>;
  revisionCreatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GQMethodologyCustomizationColumnDisplayFormat = {
  __typename?: 'MethodologyCustomizationColumnDisplayFormat';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  parquetColumnName: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export const GQMethodologyCustomizationDisplayConfigIcon = {
  Flash: 'Flash',
  Logistics: 'Logistics',
  SupplierEngagement: 'SupplierEngagement',
} as const;

export type GQMethodologyCustomizationDisplayConfigIcon =
  (typeof GQMethodologyCustomizationDisplayConfigIcon)[keyof typeof GQMethodologyCustomizationDisplayConfigIcon];
export type GQMethodologyCustomizationDisplayRow = {
  __typename?: 'MethodologyCustomizationDisplayRow';
  customizationTypeId: Scalars['String']['output'];
  mappingConfigIds: Array<Scalars['String']['output']>;
  typeConfig: GQMethodologyCustomizationTypeConfig;
};

export type GQMethodologyCustomizationDisplaySection = {
  __typename?: 'MethodologyCustomizationDisplaySection';
  sectionName: Scalars['String']['output'];
  sectionRows: Array<GQMethodologyCustomizationDisplayRow>;
};

export type GQMethodologyCustomizationMappingConfig = {
  __typename?: 'MethodologyCustomizationMappingConfig';
  id: Scalars['String']['output'];
  keyColumns: Scalars['JSONString']['output'];
  missingSourceDataConfig: GQMethodologyCustomizationMissingSourceDataConfig;
};

export const GQMethodologyCustomizationMissingDataSourceConfigImage = {
  ClimateBuildingBlocks: 'ClimateBuildingBlocks',
} as const;

export type GQMethodologyCustomizationMissingDataSourceConfigImage =
  (typeof GQMethodologyCustomizationMissingDataSourceConfigImage)[keyof typeof GQMethodologyCustomizationMissingDataSourceConfigImage];
export type GQMethodologyCustomizationMissingSourceDataConfig = {
  __typename?: 'MethodologyCustomizationMissingSourceDataConfig';
  buttonLabel: Scalars['String']['output'];
  imageName: GQMethodologyCustomizationMissingDataSourceConfigImage;
  message: Scalars['String']['output'];
};

export type GQMethodologyCustomizationTypeConfig = {
  __typename?: 'MethodologyCustomizationTypeConfig';
  description: Scalars['String']['output'];
  displayColumns: Array<GQMethodologyCustomizationColumnDisplayFormat>;
  displayName: Scalars['String']['output'];
  globalColumnsToCustomerUploadedColumns: Scalars['JSONString']['output'];
  globalReferenceDataSources: Array<Scalars['String']['output']>;
  iconName: GQMethodologyCustomizationDisplayConfigIcon;
  id: Scalars['String']['output'];
  quantityColumnName: Scalars['String']['output'];
};

export type GQMethodologyExplainer = {
  __typename?: 'MethodologyExplainer';
  biCalculationChains: Array<GQCalculationChain>;
  biCalculationTraces: Array<Scalars['UntypedData']['output']>;
  biExampleTracesForFingerprints: GQExampleTracesForFingerprints;
  biGhgCategoryIds: Array<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
};

export type GQMethodologyExplainerBiCalculationChainsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiCalculationTracesArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiExampleTracesForFingerprintsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyExplainerBiGhgCategoryIdsArgs = {
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQMethodologyTestRowResult = {
  __typename?: 'MethodologyTestRowResult';
  createdAt: Scalars['DateTime']['output'];
  diff: Scalars['JSONString']['output'];
  emissionsModelStable: GQEmissionsModelStable;
  emissionsModelStableId: Scalars['ID']['output'];
  frozenExpectation: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
  measurementTestRow: GQMeasurementTestRow;
  measurementTestRowId: Scalars['ID']['output'];
  result: Scalars['JSONString']['output'];
  resultAccepted: Scalars['Boolean']['output'];
  status: GQMeasurementTestRowResultStatus;
};

export type GQMethodologyTestSuiteExecution = {
  __typename?: 'MethodologyTestSuiteExecution';
  author: Maybe<GQWatershedEmployee>;
  authorId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  methodologyTestRowResults: Array<GQMethodologyTestRowResult>;
  status: GQMeasurementTestSuiteStageExecutionStatus;
};

export type GQMethodologyTestSuiteValidationResults = {
  __typename?: 'MethodologyTestSuiteValidationResults';
  id: Scalars['ID']['output'];
  isValid: Scalars['Boolean']['output'];
  warningMessage: Maybe<Scalars['String']['output']>;
};

export type GQMultipleTransformRunGraphsForFingerprintPayload = {
  __typename?: 'MultipleTransformRunGraphsForFingerprintPayload';
  allBartInstancesToRowIds: Array<GQBartInstancesToRowIds>;
  allExtBartInstancesToRowIds: Array<GQBartInstancesToRowIds>;
  allTransformRunGraphs: Array<GQTransformRunGraph>;
  fingerprintResults: Array<GQFingerprintTransformRunGraphResult>;
};

export type GQMutation = {
  __typename?: 'Mutation';
  acceptFinanceImportDiff: GQAcceptFinanceImportDiffPayload;
  acceptOrgStructureVersionUpload: GQAcceptOrgStructureVersionUploadPayload;
  acknowledgeReportAnswerWarning: GQReportAnswerWarning;
  addCompaniesToPeerCompanyGroups: Maybe<GQAddCompaniesToPeerCompanyGroupsPayload>;
  addDataApprovalComment: GQAddDataApprovalCommentPayload;
  addEngagementTaskComment: Maybe<GQAddEngagementTaskCommentPayload>;
  addHeaderToCustomReport: Maybe<GQAddHeaderToCustomReportPayload>;
  addMetricsToCustomReport: Maybe<GQAddMetricsToCustomReportPayload>;
  addReportQuestionNote: GQAddReportQuestionNotePayload;
  addUserUploadCandidatesToFileFeedEmbeddedSession: Scalars['Boolean']['output'];
  appendOnlyUpdateCsrdDataRequirementsConfig: GQAppendOnlyUpdateCsrdDataRequirementsConfigPayload;
  applyIntegrationConnection: Maybe<GQApplyIntegrationConnectionPayload>;
  approveAllValueMappingRecords: Maybe<GQApproveAllValueMappingRecordsPayload>;
  approveData: GQApproveDataPayload;
  approveReportQuestionInstance: GQApproveReportQuestionInstancePayload;
  archiveReport: Maybe<GQArchiveReportPayload>;
  archiveUpload: GQArchiveUploadPayload;
  assignFacilitiesToTasks: GQAssignFacilitiesToTasksPayload;
  assignMeasurementTask: GQAssignMeasurementTaskPayload;
  assignToContainer: Maybe<GQAssignToContainerPayload>;
  assignUserUploadTask: GQAssignUserUploadTaskPayload;
  autoMapAssets: GQAutoMapAssetsPayload;
  autoMapSuppliers: GQAutoMapSuppliersPayload;
  batchSetCustomFieldForBuildings: GQBatchSetCustomFieldForBuildingsPayload;
  batchSetCustomFieldForDatasources: GQBatchSetCustomFieldForDatasourcesPayload;
  batchSetOrgUnitForBuildings: GQBatchSetOrgUnitForBuildingsPayload;
  batchSetOrgUnitForDatasources: GQBatchSetOrgUnitForDatasourcesPayload;
  batchUnsetCustomFieldForBuildings: GQBatchUnsetCustomFieldForBuildingsPayload;
  batchUnsetCustomFieldForDatasources: GQBatchUnsetCustomFieldForDatasourcesPayload;
  batchUnsetOrgUnitForBuildings: GQBatchUnsetOrgUnitForBuildingsPayload;
  batchUnsetOrgUnitForDatasources: GQBatchUnsetOrgUnitForDatasourcesPayload;
  batchUpdateReportQuestionAssignees: GQBatchUpdateReportQuestionAssigneesPayload;
  bulkAssignFacilitiesToTasks: GQBulkAssignFacilitiesToTasksPayload;
  bulkAssignMeasurementTasks: GQBulkAssignMeasurementTasksPayload;
  bulkCreateUploadTasks: GQBulkCreateUploadTasksPayload;
  bulkCreateUsersForFacilities: GQBulkCreateUsersForFacilitiesPayload;
  bulkSetApprovers: GQBulkSetApproversPayload;
  bulkStartCompanySurveyEngagement: Maybe<GQBulkStartCompanySurveyEngagementPayload>;
  cancelWorkflow: Maybe<GQCancelWorkflowPayload>;
  checkAndGenerateFacilitiesBarts: GQFacilitiesSdiPayload;
  completeOnboarding: Maybe<GQCompleteOnboardingPayload>;
  completeUserUploadTask: GQCompleteUserUploadTaskPayload;
  confirmMarketplacePurchase: Maybe<GQConfirmMarketplacePurchasePayload>;
  confirmUtilitySchema: GQConfirmUtilitySchemaPayload;
  createAndSubmitUserUploadCandidate: GQCreateAndSubmitUserUploadCandidatePayload;
  createAssetComment: GQCreateAssetCommentPayload;
  createAssetCorporate: Maybe<GQCreateAssetCorporatePayload>;
  createAssetGroup: Maybe<GQCreateAssetGroupPayload>;
  createAssetHolding: Maybe<GQCreateAssetHoldingPayload>;
  createAssetPersonalMotorVehicleInsurance: Maybe<GQCreateAssetPersonalMotorVehicleInsurancePayload>;
  createAssetRealEstate: Maybe<GQCreateAssetRealEstatePayload>;
  createAssetSovereignBond: Maybe<GQCreateAssetSovereignBondPayload>;
  createAssetYear: Maybe<GQCreateAssetYearNewPayload>;
  createBiCustomMetric: GQBiCustomMetric;
  createBiSavedView: GQBiSavedView;
  createBiSavedViewAndPropagateToCustomReports: GQBiSavedView;
  createBuilding: Maybe<GQCreateBuildingPayload>;
  createBuildingUtility: GQCreateBuildingUtilityPayload;
  createCandidateRowEntry: GQUserUploadCandidateEntry;
  createCdpApiRegistration: GQCreateCdpApiRegistrationPayload;
  createClimateProgramCalendarEvent: GQClimateProgramCalendarEventPayload;
  createClimateProgramProject: GQClimateProgramProjectPayload;
  createClimateProgramProjectDatasetRequirement: GQClimateProgramProjectPayload;
  createCompanyChangeRequestCreate: GQCreateCompanyChangeRequestCreatePayload;
  createCompanyRequestCase: GQCreateCompanyRequestCasePayload;
  createCorporateSandboxEstimation: Maybe<GQCreateCorporateSandboxEstimationPayload>;
  createCsrdDataRequirementsConfig: GQCreateCsrdDataRequirementsConfigPayload;
  createCtsForm: GQUserUploadTask;
  createCtsFormEntry: GQCtsFormEntry;
  createCtsvOneSchemaFileFeed: Maybe<GQCustomerTargetSchemaVersion>;
  createCustomEmissionsFactorsReferenceDataRevision: GQCreateCustomEmissionsFactorsReferenceDataRevisionPayload;
  /** @deprecated Support cases are now the law of the land */
  createCustomerInitiatedDataIssue: Maybe<GQCreateCustomerInitiatedDataIssuePayload>;
  createDatasource: GQDatasourcePayload;
  createDefaultOrgStructureVersion: GQCreateDefaultOrgStructureVersionPayload;
  createDemoOrg: Maybe<GQCreateDemoOrgPayload>;
  /** @deprecated Support cases are now the law of the land */
  createDiscussion: GQCreateDiscussionPayload;
  createEngagementCohort: GQEngagementCohort;
  createEngagementCohortsForCompany: Array<GQEngagementCohort>;
  createEngagementTaskAttachments: Maybe<GQCreateEngagementTaskAttachmentsPayload>;
  createFinanceAssetYearsExport: GQCreateFinanceExportPayload;
  createFinanceCorporateAssetsExport: GQCreateFinanceExportPayload;
  createFinanceHoldingsExport: GQCreateFinanceHoldingsExportPayload;
  createFinanceImport: GQCreateFinanceImportPayload;
  createFinanceReportingTags: GQCreateFinanceReportingTagsPayload;
  createFinanceSavedView: Maybe<GQCreateFinanceSavedViewPayload>;
  createFinanceTag: GQCreateFinanceTagPayload;
  createFinanceUploadTemplate: GQCreateFinanceUploadTemplatePayload;
  createFootprint: GQCreateFootprintPayload;
  createFootprintSliceInitiative: GQCreateFootprintSliceInitiativePayload;
  createForecast: GQCreateForecastPayload;
  createFund: Maybe<GQCreateFundPayload>;
  createIngestionCustomField: GQCreateIngestionCustomFieldPayload;
  createMarketplaceEacPurchase: Maybe<GQCreateMarketplaceEacPurchasePayload>;
  createMaterialityAssessment: GQCreateMaterialityAssessmentPayload;
  createMeasurementProjectFromCanonicalClimateProgramProject: GQCreateMeasurementProjectFromCanonicalClimateProgramProjectPayload;
  createMeasurementProjectFromWizard: GQCreateMeasurementProjectFromWizardPayload;
  createOneSchemaSessionForApiUpload: Maybe<GQCreateOneSchemaSessionForApiUploadPayload>;
  createOneSchemaSessionForTargetSchema: Maybe<GQCreateOneSchemaSessionForTargetSchemaPayload>;
  createOneSchemaSessionForUserUpload: Maybe<GQCreateOneSchemaSessionForUserUploadPayload>;
  createOrgStructureImportTemplate: GQCreateOrgStructureImportTemplatePayload;
  createOrgStructureVersionUpload: GQCreateOrgStructureVersionUploadPayload;
  createOrgUnit: GQCreateOrgUnitPayload;
  createOrgUnitRelationship: GQCreateOrgUnitRelationshipPayload;
  createOrgUnitType: GQCreateOrgUnitTypePayload;
  createOrgUnitTypeRelationship: GQCreateOrgUnitTypeRelationshipPayload;
  createPeerCompanyGroup: Maybe<GQPeerCompanyGroupPayload>;
  createPermissionItem: Maybe<GQCreatePermissionItemPayload>;
  createPlanTarget: Maybe<GQCreatePlanTargetPayload>;
  createPlanTargetRedux: Maybe<GQCreatePlanTargetPayload>;
  createPlanWithDefaults: GQCreatePlanWithDefaultsPayload;
  createPrecomputedActivityBasedInitiative: GQCreatePrecomputedActivityBasedInitiativePayload;
  createPrivateDisclosure: Maybe<GQCreatePrivateDisclosurePayload>;
  createRealEstateSandboxEstimation: Maybe<GQCreateRealEstateSandboxEstimationPayload>;
  createRenewableElectricityTarget: Maybe<GQCreatePlanTargetPayload>;
  createReportAttachments: Maybe<GQCreateReportAttachmentsPayload>;
  createReportFileAttachments: Maybe<GQCreateReportFileAttachmentsPayload>;
  createReportURLAttachmentItem: Maybe<GQCreateReportUrlAttachmentItemPayload>;
  createReportWithConfig: GQReportWithConfig;
  createReportWithCustomConfig: Maybe<GQCreateReportWithCustomConfigPayload>;
  createRole: Maybe<GQCreateRolePayload>;
  createSbtiTargetExclusion: GQCreateSbtiTargetExclusionPayload;
  createSingleUploaderFacilitiesTask: GQCreateSingleUploaderFacilitiesTaskPayload;
  createSnapshot: GQCreateSnapshotPayload;
  createSupplierAttachments: Maybe<GQCreateSupplierAttachmentsPayload>;
  createSupplierChart: Maybe<GQCreateSupplierChartPayload>;
  createSupplierContact: Maybe<GQCreateSupplierContactPayload>;
  createSupplierSavedView: GQCreateSupplierSavedViewPayload;
  createSupplierScoreCriteria: GQCreateSupplierScoreCriteriaPayload;
  createSupplierTableColumn: Maybe<GQCreateSupplierTableColumnPayload>;
  createSupportCase: GQCreateSupportCasePayload;
  createSupportCaseAttachments: GQCreateSupportCaseAttachmentsPayload;
  createSupportCaseComment: GQCreateSupportCaseCommentPayload;
  createSupportingDocuments: Maybe<GQCreateSupportingDocumentsPayload>;
  createUpdateAndDeleteForecastScenarios: GQCreateUpdateAndDeleteForecastScenariosPayload;
  createUser: Maybe<GQCreateUserPayload>;
  createUserPermissionRequest: Maybe<GQCreateUserPermissionRequestPayload>;
  createUserUploads: GQCreateUserUploadsPayload;
  createUtilityDocs: GQCreateUtilityDocsPayload;
  createValueMappingComment: GQValueMappingComment;
  createWatershedFootprintReviewRequest: GQCreateWatershedFootprintReviewRequestPayload;
  deleteAllFinanceThings: GQDeleteAllFinanceThingsPayload;
  deleteAssetComment: GQDeleteAssetCommentPayload;
  deleteAssetHolding: Maybe<GQDeleteAssetHoldingPayload>;
  deleteAssets: GQDeleteAssetsPayload;
  deleteBiCustomMetric: GQBiCustomMetric;
  deleteBiSavedView: GQBiSavedView;
  deleteBuildingUtility: GQDeleteBuildingUtilityPayload;
  deleteBuildings: GQDeleteBuildingsPayload;
  deleteCandidateRowEntry: GQUserUploadCandidateEntry;
  deleteCdpApiRegistration: GQDeleteCdpApiRegistrationPayload;
  deleteClimateProgramCalendarEvent: GQDeleteClimateProgramCalendarEventPayload;
  deleteClimateProgramProject: GQDeleteClimateProgramProjectPayload;
  deleteClimateProgramProjectDatasetRequirement: GQClimateProgramProjectPayload;
  deleteClimateProgramTimelineProject: GQDeleteClimateProgramTimelineProjectPayload;
  deleteCompanyEngagementCohort: GQDeleteCompanyEngagementCohortPayload;
  deleteCompanyReportingFacts: GQDeleteCompanyReportingFactsPayload;
  deleteCtsvOneSchemaFileFeed: Maybe<GQCustomerTargetSchemaVersion>;
  deleteCustomEmissionsFactors: GQDeleteCustomEmissionsFactorsPayload;
  deleteEngagementTaskConfig: Maybe<GQDeleteEngagementTaskConfigPayload>;
  deleteFile: Maybe<GQDeleteFilePayload>;
  deleteFinanceSavedView: Maybe<GQDeleteFinanceSavedViewPayload>;
  deleteFinanceTag: GQDeleteFinanceTagPayload;
  deleteFinanceTags: GQDeleteFinanceTagsPayload;
  deleteFinanceThings: GQDeleteFinanceThingsPayload;
  deleteFootprintExport: GQDeleteFootprintExportPayload;
  deleteFunds: Maybe<GQDeleteFundsPayload>;
  deleteIngestionCustomField: GQDeleteIngestionCustomFieldPayload;
  deleteInitiative: Maybe<GQDeleteInitiativePayload>;
  deleteMaterialityAssessment: GQDeleteMaterialityAssessmentPayload;
  deleteMetricFromCustomReport: Maybe<GQDeleteMetricFromCustomReportPayload>;
  deleteNonFootprintSupplier: Maybe<GQDeleteNonFootprintSupplierPayload>;
  deleteOrgCompany: GQDeleteOrgCompanyPayload;
  deleteOrgStructureVersion: GQDeleteOrgStructureVersionPayload;
  deleteOrgUnitType: GQDeleteOrgUnitTypePayload;
  deleteOrgUnitTypeRelationship: GQDeleteOrgUnitTypeRelationshipPayload;
  deleteOrgUnits: GQDeleteOrgUnitsPayload;
  deletePeerCompanyGroup: Maybe<GQDeletePeerCompanyGroupPayload>;
  deletePlan: Scalars['Boolean']['output'];
  deletePlanTarget: GQDeletePlanTargetPayload;
  deletePrivateDisclosureTargetById: Maybe<GQDeletePrivateDisclosureTargetByIdPayload>;
  deleteReportAnswer: GQDeleteReportAnswerPayload;
  deleteReportAttachment: Maybe<GQDeleteReportAttachmentPayload>;
  deleteReportAttachmentItem: Maybe<GQDeleteReportAttachmentItemPayload>;
  deleteReportQuestionNoteItem: GQDeleteReportQuestionNoteItemPayload;
  deleteRole: Maybe<GQDeleteRolePayload>;
  deleteSnapshot: GQDeleteSnapshotPayload;
  deleteSupplierAttachment: Maybe<GQDeleteSupplierAttachmentPayload>;
  deleteSupplierChart: Maybe<GQDeleteSupplierChartPayload>;
  deleteSupplierContact: Maybe<GQDeleteSupplierContactPayload>;
  deleteSupplierSavedView: GQDeleteSupplierSavedViewPayload;
  deleteSupplierScoreCriteria: GQDeleteSupplierScoreCriteriaPayload;
  deleteSupplierTableColumn: Maybe<GQDeleteSupplierTableColumnPayload>;
  deleteSupportCaseAttachments: GQDeleteSupportCaseAttachmentsPayload;
  deleteSupportingDocument: Maybe<GQDeleteSupportingDocumentPayload>;
  deleteUserUploadCandidateAndRows: GQDeleteUserUploadCandidateAndRowsPayload;
  deleteUserUploadTask: GQDeleteUserUploadTaskPayload;
  deleteUserUploadTasks: Maybe<GQDeleteUserUploadTaskPayload>;
  deleteValueMappingComment: GQValueMappingComment;
  deleteWatershedFootprintReviewRequest: GQDeleteWatershedFootprintReviewRequestPayload;
  disconnectIntegrationConnection: GQDisconnectIntegrationConnectionPayload;
  duplicateInitiative: GQDuplicateInitiativePayload;
  duplicateOrgStructureVersion: GQDuplicateOrgStructureVersionPayload;
  duplicatePlan: Maybe<GQDuplicatePlanPayload>;
  editIngestionCustomField: GQEditIngestionCustomFieldPayload;
  editValueMappingComment: GQValueMappingComment;
  executeExtractSchemas: GQExecuteExtractSchemasPayload;
  fetchSupportCaseAttachmentDownloadUrl: GQFetchSupportCaseAttachmentDownloadUrlPayload;
  generateAuditReport: GQGenerateAuditReportPayload;
  generateAuditReportForAssetYear: Maybe<GQFinanceAuditReportForAssetYearPayload>;
  generateBartFromFileUpload: GQGenerateBartFromFileUploadPayload;
  generateCustomIntensityConfigId: GQGenerateCustomIntensityConfigIdPayload;
  generateCustomerUploadedReferenceDataRevision: GQGenerateCustomerUploadedReferenceDataRevisionPayload;
  generateExportUrl: GQGenerateExportUrlPayload;
  generateFinanceAuditReportsForAsset: Maybe<GQGenerateFinanceAuditReportsForAssetPayload>;
  /** @deprecated Use generateExportUrl instead, remove march26 */
  generateFinanceHomeExport: GQGenerateFinanceHomeExportPayload;
  generateFootprintV2: GQGenerateFootprintV2Payload;
  generateForecastScenarioId: GQGenerateForecastScenarioIdPayload;
  generateOverrideReferenceDataRevision: GQGenerateOverrideReferenceDataRevisionPayload;
  generateTemplateDownloadUrlForDataset: GQGenerateTemplateDownloadUrlForDatasetPayload;
  generateTemplateDownloadUrlForOrgDatasets: GQGenerateTemplateDownloadUrlForOrgDatasetsPayload;
  generateValueMappingDownloadUrl: Scalars['String']['output'];
  getOrCreateDatasetFromCanonicalDatasetKind: GQGetOrCreateDatasetFromCanonicalDatasetKindPayload;
  getOrSendEmails: GQGetOrSendEmailsPayload;
  getOrSendMeasurementEmails: Maybe<GQGetOrSendMeasurementEmailsPayload>;
  giveFeedback: GQRequestHelpPayload;
  grantUserRole: Maybe<GQGrantUserRolePayload>;
  initializeDefaultSupplierView: GQInitializeDefaultSupplierViewPayload;
  joinReportWaitlist: GQJoinReportWaitlistPayload;
  joinWaitlist: GQJoinProductWaitlistPayload;
  leaveReportWaitlist: GQLeaveReportWaitlistPayload;
  leaveWaitlist: GQLeaveProductWaitlistPayload;
  logCustomerHubViewed: Maybe<GQLogCustomerHubViewedPayload>;
  logSupplyChainSurveyEvent: Maybe<GQLogSupplyChainSurveyEventPayload>;
  logUpdateVendorMatchingTask: GQLogUpdateVendorMatchingTaskPayload;
  markDemoPlatformRunAsFailed: Maybe<GQMarkDemoPlatformRunAsFailedPayload>;
  markSupportCaseNotificationRead: GQMarkSupportCaseNotificationReadPayload;
  markUserUploadAsProcessing: Maybe<GQMarkUserUploadAsProcessingPayload>;
  markUserUploadCompleted: Maybe<GQMarkUserUploadCompletedPayload>;
  maybeCreateIntegrationConnection: Maybe<GQMaybeCreateIntegrationConnectionPayload>;
  notifyEngagementTaskCreation: Maybe<GQNotifyEngagementTaskCreationPayload>;
  notifyReportAssignees: Maybe<GQNotifyReportAssigneesPayload>;
  overrideContainerProgress: GQReportQuestionContainer;
  parquetDataToCsv: GQParquetDataToCsvPayload;
  prepareActivityRecordExport: GQPrepareActivityRecordExportPayload;
  prepareFootprintGenerationParams: GQPrepareFootprintGenerationParamsPayload;
  previewUtilitiesDiff: GQFacilitiesSdiPayload;
  publishCustomTransformForUserUpload: GQPublishCustomTransformForUserUploadPayload;
  publishDraftFootprint: Maybe<GQPublishDraftFootprintPayload>;
  publishOrgStructureVersion: GQPublishOrgStructureVersionPayload;
  publishValueMappingArtifact: Maybe<GQPublishValueMappingArtifactPayload>;
  pushBartBuilding: Maybe<GQFacilitiesSdiPayload>;
  pushCtsVersionToOneSchemaInProduct: GQPushCtsVersionToOneSchemaInProductPayload;
  pushUtilitiesDiff: GQFacilitiesSdiPayload;
  reassignFacilitiesTask: GQReassignFacilitiesTaskPayload;
  refreshMarketplacePurchase: Maybe<GQConfirmMarketplacePurchasePayload>;
  regenerateAuditReport: GQGenerateAuditReportPayload;
  rejectData: GQRejectDataPayload;
  rejectReportQuestionInstance: GQRejectReportQuestionInstancePayload;
  removeCompanyFromPeerCompanyGroup: Maybe<GQRemoveCompanyFromPeerCompanyGroupPayload>;
  removeUser: Maybe<GQRemoveUserPayload>;
  renameBiSavedView: GQBiSavedView;
  renameFootprint: GQRenameFootprintPayload;
  renameReport: Maybe<GQRenameReportPayload>;
  requestDataApproval: GQRequestDataApprovalPayload;
  requestSupplyChainHelp: GQRequestHelpPayload;
  requestUnlockData: GQRequestUnlockDataPayload;
  /** @deprecated Dead code */
  rerunBartForPresetTagWorkflow: GQRerunBartForPresetTagWorkflowOutput;
  resendUserInviteLink: Maybe<GQResendUserInviteLinkPayload>;
  resetLockedUser: Maybe<GQResetLockedUserPayload>;
  /** @deprecated Support cases are now the law of the land */
  resolveDataIssue: Maybe<GQResolveDataIssuePayload>;
  /** @deprecated Support cases are now the law of the land */
  resolveDiscussion: Maybe<GQResolveDiscussionPayload>;
  /** @deprecated Support cases are now the law of the land */
  respondToDataIssue: Maybe<GQRespondToDataIssuePayload>;
  /** @deprecated Support cases are now the law of the land */
  respondToDiscussion: Maybe<GQRespondToDiscussionPayload>;
  restoreDeletedSavedView: Maybe<GQBiSavedView>;
  reviewFinancialsReviewItem: Maybe<GQReviewFinancialsReviewItemPayload>;
  revokePermissionItem: Maybe<GQRevokePermissionItemPayload>;
  revokeUserAppSessions: GQRevokeUserAppSessionsPayload;
  revokeUserRole: Maybe<GQRevokeUserRolePayload>;
  runCeeForEngagementTask: Maybe<GQRunCeeForEngagementTaskPayload>;
  runCtsTransformEditor: GQRunCtsTransformEditorPayload;
  runDemoPlatform: Maybe<GQRunDemoPlatformPayload>;
  runPleeForEngagementTask: Maybe<GQRunPleeForEngagementTaskPayload>;
  saveCategoryRuleGhgCategoryNote: Maybe<GQSaveCategoryRuleGhgCategoryNotePayload>;
  saveDeltaProgressValueMapping: GQSaveDeltaProgressValueMappingPayload;
  saveEmployeeFeedback: Maybe<GQSaveEmployeeFeedbackPayload>;
  saveIncompleteOneSchemaImport: GQSaveIncompleteOneSchemaImportPayload;
  saveProductionGraph: GQProductionGraphData;
  sendIntegrationInvite: GQSendIntegrationInvitePayload;
  setApprovers: GQSetApproversPayload;
  setArchiveEngagementTaskConfig: Maybe<GQSetArchiveEngagementTaskConfigPayload>;
  setAssetBenchmarks: GQSetAssetBenchmarksPayload;
  setEngagementCohortAndMembers: GQSetEngagementCohortAndMembersPayload;
  setEngagementCohortsForCompany: GQSetEngagementCohortsForCompanyPayload;
  setEngagementNotificationSettingsForUser: GQUser;
  setPeerCompanyGroupMemberFeatureStatus: Maybe<GQSetPeerCompanyGroupMemberFeatureStatusPayload>;
  setPreferredLocale: GQSetPreferredLocalePayload;
  setPublishEngagementTaskConfig: Maybe<GQSetPublishEngagementTaskConfigPayload>;
  setUserUploadedTableSheetsNotIngested: Maybe<GQSetUserUploadedTableSheetsNotIngestedPayload>;
  softDeleteCsrdDataRequirementsConfig: GQSoftDeleteCsrdDataRequirementsConfigPayload;
  startCdpSyncAnswers: GQStartCdpSyncAnswersPayload;
  startFileFeedEmbeddedSession: GQFileFeedEmbeddedSession;
  submitAndCloseCtsForm: GQUserUploadTask;
  submitBuildings: Maybe<GQSubmitFacilitiesPayload>;
  submitDataLineageFeedback: GQSubmitDataLineageFeedbackPayload;
  submitEarlyUploadHelpRequest: GQSubmitEarlyUploadHelpRequestPayload;
  submitEngagementTask: Maybe<GQSubmitEngagementTaskPayload>;
  submitFacilitiesBulkAddition: GQSubmitFacilitiesBulkAdditionPayload;
  submitReportQuestionInstance: GQSubmitReportQuestionInstancePayload;
  submitUserUploadTask: GQSubmitUserUploadTaskPayload;
  submitUtilities: GQSubmitFacilitiesPayload;
  submitVendorMatchingTask: GQSubmitVendorMatchingTaskPayload;
  suggestMappings: GQSuggestMappingsPayload;
  supplierDataExport: GQSupplierDataExportPayload;
  supplierSurveyResponseExport: GQSupplierSurveyResponseExportPayload;
  testingApproveReportQuestionInstances: GQTestingApproveReportQuestionInstancesPayload;
  testingRejectReportQuestionInstances: GQTestingRejectReportQuestionInstancesPayload;
  testingSubmitReportQuestionInstances: GQTestingSubmitReportQuestionInstancesPayload;
  testingWithdrawReportQuestionInstances: GQTestingWithdrawReportQuestionInstancesPayload;
  throwError: Maybe<GQThrowErrorPayload>;
  transformAndSubmitUpload: GQTransformAndSubmitUploadPayload;
  unassignFacilitiesFromTask: GQUnassignFacilitiesFromTaskPayload;
  unassignFromContainer: Maybe<GQUnassignFromContainerPayload>;
  uncompleteUserUploadTask: GQCompleteUserUploadTaskPayload;
  unlockData: GQUnlockDataPayload;
  unpublishOrgStructureVersion: GQUnpublishOrgStructureVersionPayload;
  updateApproversForReportQuestionInstances: GQUpdateApproversForReportQuestionInstancesPayload;
  updateAssetComment: GQUpdateAssetCommentPayload;
  updateAssetCorporate: Maybe<GQUpdateAssetCorporatePayload>;
  updateAssetGroup: Maybe<GQUpdateAssetGroupPayload>;
  updateAssetHolding: Maybe<GQUpdateAssetHoldingPayload>;
  updateAssetPersonalMotorVehicleInsurance: Maybe<GQUpdateAssetPersonalMotorVehicleInsurancePayload>;
  updateAssetRealEstate: Maybe<GQUpdateAssetRealEstatePayload>;
  updateAssetSovereignBond: Maybe<GQUpdateAssetSovereignBondPayload>;
  updateAssetYear: Maybe<GQUpdateAssetYearNewPayload>;
  updateAssetYears: Maybe<GQUpdateAssetYearsNewPayload>;
  updateBiCustomMetric: GQBiCustomMetric;
  updateBiSavedView: GQBiSavedView;
  updateBuilding: Maybe<GQUpdateBuildingPayload>;
  updateBuildingUtility: GQUpdateBuildingUtilityPayload;
  updateBuildings: GQUpdateBuildingsPayload;
  updateCandidateRowEntry: GQUserUploadCandidateEntry;
  updateClimateProgramCalendarEvent: GQClimateProgramCalendarEventPayload;
  updateClimateProgramProject: GQClimateProgramProjectPayload;
  updateClimateProgramProjectDatasetRequirement: GQClimateProgramProjectRequirementDatasetPayload;
  updateClimateProgramTimelineProject: GQClimateProgramTimelineProjectPayload;
  updateCtsForm: GQUserUploadTask;
  updateDatasource: GQDatasourcePayload;
  updateEngagementTask: Maybe<GQUpdateEngagementTaskPayload>;
  updateEngagementTaskConfig: Maybe<GQUpdateEngagementTaskConfigPayload>;
  updateFinanceAutomaticReminders: GQUpdateFinanceAutomaticRemindersPayload;
  updateFinanceSavedView: Maybe<GQUpdateFinanceSavedViewPayload>;
  updateFinanceSettings: Maybe<GQUpdateFinanceSettingsPayload>;
  updateFinanceTag: GQUpdateFinanceTagPayload;
  updateFinanceWorksheet: GQUpdateFinanceWorksheetPayload;
  updateFootprintAssemblyRunStatus: GQUpdateFootprintAssemblyRunStatusPayload;
  updateFootprintSliceInitiative: Maybe<GQUpdateFootprintSliceInitiativePayload>;
  updateForecast: GQUpdateForecastPayload;
  updateFund: Maybe<GQUpdateFundPayload>;
  updateMaterialityAssessment: GQUpdateMaterialityAssessmentPayload;
  updateMeasurementProjectDashboard: GQUpdateMeasurementProjectPayload;
  updateOrg: Maybe<GQUpdateOrgPayload>;
  updateOrgProfile: GQUpdateOrgProfilePayload;
  updateOrgUnitType: GQUpdateOrgUnitTypePayload;
  updateOrgUnitTypeRelationship: GQUpdateOrgUnitTypeRelationshipPayload;
  updateOrgUnits: GQUpdateOrgUnitsPayload;
  updatePeerCompanyGroup: Maybe<GQPeerCompanyGroupPayload>;
  updatePermissions: Maybe<GQUpdatePermissionsPayload>;
  updatePlan: Maybe<GQUpdatePlanPayload>;
  updatePlanSettings: Maybe<GQUpdatePlanSettingsPayload>;
  updatePlanState: Maybe<GQUpdatePlanStatePayload>;
  updatePlanTarget: Maybe<GQUpdatePlanTargetPayload>;
  updatePrecomputedActivityBasedInitiative: Maybe<GQUpdatePrecomputedActivityBasedInitiativePayload>;
  updatePrivateDisclosure: Maybe<GQUpdatePrivateDisclosurePayload>;
  updateReportAnswerOverrideStates: GQUpdateReportAnswerOverrideStatesPayload;
  updateReportConfigDashboard: Maybe<GQUpdateReportConfigDashboardPayload>;
  updateReportQuestionAssignees: Maybe<GQUpdateReportQuestionAssigneesPayload>;
  updateReportQuestionNote: GQUpdateReportQuestionNotePayload;
  updateReportQuestionNoteItem: GQUpdateReportQuestionNoteItemPayload;
  updateReportingIntentions: GQUpdateReportingIntentionsPayload;
  updateRole: Maybe<GQUpdateRolePayload>;
  updateSnapshotName: GQUpdateSnapshotNamePayload;
  updateSupplierAttachment: Maybe<GQUpdateSupplierAttachmentPayload>;
  updateSupplierChart: Maybe<GQUpdateSupplierChartPayload>;
  updateSupplierColumnOrder: Maybe<GQUpdateSupplierColumnOrderPayload>;
  updateSupplierColumnVisibilityBatch: Maybe<GQUpdateSupplierColumnVisibilityBatchPayload>;
  updateSupplierColumnWidth: Maybe<GQUpdateSupplierColumnWidthPayload>;
  updateSupplierContact: Maybe<GQUpdateSupplierContactPayload>;
  updateSupplierCustomData: Maybe<GQUpdateSupplierCustomDataPayload>;
  updateSupplierSavedView: GQUpdateSupplierSavedViewPayload;
  updateSupplierScoreCriteria: GQUpdateSupplierScoreCriteriaPayload;
  updateSupplierTableColumn: Maybe<GQUpdateSupplierTableColumnPayload>;
  updateSuppliersSettings: Maybe<GQUpdateSuppliersSettingsPayload>;
  updateSupportCase: GQUpdateSupportCasePayload;
  updateUser: Maybe<GQUpdateUserPayload>;
  updateUserIngestionReview: Maybe<GQUpdateUserIngestionReviewPayload>;
  updateUserName: Maybe<GQUpdateUserNamePayload>;
  updateUserPermissionRequest: Maybe<GQUpdateUserPermissionRequestPayload>;
  updateUserUpload: Maybe<GQUpdateUserUploadPayload>;
  updateWatershedFootprintReviewRequestStatus: GQUpdateWatershedFootprintReviewRequestStatusPayload;
  upsertCompanyPortalSettings: Maybe<GQUpsertCompanyPortalSettingsPayload>;
  upsertCompanyReportingFacts: GQUpsertCompanyReportingFactsPayload;
  upsertDocumentParseAttempt: GQDocumentParseAttempt;
  upsertEngagementTaskAnswers: Maybe<GQUpsertEngagementTaskAnswersPayload>;
  upsertFootprintCategoryRules: Maybe<GQUpsertFootprintCategoryRulesPayload>;
  upsertFootprintExclusionRulesV2: Maybe<GQUpsertFootprintExclusionRulesPayload>;
  upsertNonFootprintSupplier: Maybe<GQUpsertNonFootprintSupplierPayload>;
  upsertOrgCompanyForName: GQUpsertOrgCompanyForNamePayload;
  upsertOrgStructureVersion: GQUpsertOrgStructureVersionPayload;
  upsertReportAnswerOverride: GQUpsertReportAnswerOverridePayload;
  upsertReportAnswerWithMetadata: GQUpsertReportAnswerPayload;
  upsertReportQuestionInstancesAccess: GQUpsertReportQuestionInstancesAccessPayload;
  upsertReportsAccess: GQUpsertReportsAccessPayload;
  validateFormAgainstCts: GQValidateFormAgainstCtsPayload;
  validateUserUploadCandidate: GQUserUploadCandidate;
  withdrawCompanyChangeRequest: GQWithdrawCompanyChangeRequestPayload;
  withdrawReportQuestionInstance: GQWithdrawReportQuestionInstancePayload;
};

export type GQMutationAcceptFinanceImportDiffArgs = {
  input: GQAcceptFinanceImportDiffInput;
};

export type GQMutationAcceptOrgStructureVersionUploadArgs = {
  input: GQAcceptOrgStructureVersionUploadInput;
};

export type GQMutationAcknowledgeReportAnswerWarningArgs = {
  input: GQAcknowledgeReportAnswerWarningInput;
};

export type GQMutationAddCompaniesToPeerCompanyGroupsArgs = {
  input: GQAddCompaniesToPeerCompanyGroupsInput;
};

export type GQMutationAddDataApprovalCommentArgs = {
  input: GQAddDataApprovalCommentInput;
};

export type GQMutationAddEngagementTaskCommentArgs = {
  engagementTaskId: Scalars['ID']['input'];
  input: GQAddEngagementTaskCommentInput;
};

export type GQMutationAddHeaderToCustomReportArgs = {
  input: GQAddHeaderToCustomReportInput;
};

export type GQMutationAddMetricsToCustomReportArgs = {
  input: GQAddMetricsToCustomReportInput;
};

export type GQMutationAddReportQuestionNoteArgs = {
  input: GQAddReportQuestionNoteInput;
};

export type GQMutationAddUserUploadCandidatesToFileFeedEmbeddedSessionArgs = {
  input: GQAddUserUploadCandidatesToFileFeedEmbeddedSessionInput;
};

export type GQMutationAppendOnlyUpdateCsrdDataRequirementsConfigArgs = {
  input: GQAppendOnlyUpdateCsrdDataRequirementsConfigInput;
};

export type GQMutationApplyIntegrationConnectionArgs = {
  input: GQApplyIntegrationConnectionInput;
};

export type GQMutationApproveAllValueMappingRecordsArgs = {
  input: GQApproveAllValueMappingRecordsInput;
};

export type GQMutationApproveDataArgs = {
  input: GQApproveDataInput;
};

export type GQMutationApproveReportQuestionInstanceArgs = {
  input: GQApproveReportQuestionInstanceInput;
};

export type GQMutationArchiveReportArgs = {
  input: GQArchiveReportInput;
};

export type GQMutationArchiveUploadArgs = {
  input: GQArchiveUploadInput;
};

export type GQMutationAssignFacilitiesToTasksArgs = {
  input: GQAssignFacilitiesToTasksInput;
};

export type GQMutationAssignMeasurementTaskArgs = {
  input: GQAssignMeasurementTaskInput;
};

export type GQMutationAssignToContainerArgs = {
  input: GQAssignToContainerInput;
};

export type GQMutationAssignUserUploadTaskArgs = {
  input: GQAssignUserUploadTaskInput;
};

export type GQMutationAutoMapSuppliersArgs = {
  input: GQAutoMapSuppliersInput;
};

export type GQMutationBatchSetCustomFieldForBuildingsArgs = {
  input: GQBatchSetCustomFieldForBuildingsInput;
};

export type GQMutationBatchSetCustomFieldForDatasourcesArgs = {
  input: GQBatchSetCustomFieldForDatasourcesInput;
};

export type GQMutationBatchSetOrgUnitForBuildingsArgs = {
  input: GQBatchSetOrgUnitForBuildingsInput;
};

export type GQMutationBatchSetOrgUnitForDatasourcesArgs = {
  input: GQBatchSetOrgUnitForDatasourcesInput;
};

export type GQMutationBatchUnsetCustomFieldForBuildingsArgs = {
  input: GQBatchUnsetCustomFieldForBuildingsInput;
};

export type GQMutationBatchUnsetCustomFieldForDatasourcesArgs = {
  input: GQBatchUnsetCustomFieldForDatasourcesInput;
};

export type GQMutationBatchUnsetOrgUnitForBuildingsArgs = {
  input: GQBatchUnsetOrgUnitForBuildingsInput;
};

export type GQMutationBatchUnsetOrgUnitForDatasourcesArgs = {
  input: GQBatchUnsetOrgUnitForDatasourcesInput;
};

export type GQMutationBatchUpdateReportQuestionAssigneesArgs = {
  input: GQBatchUpdateReportQuestionAssigneesInput;
};

export type GQMutationBulkAssignFacilitiesToTasksArgs = {
  input: GQBulkAssignFacilitiesToTasksInput;
};

export type GQMutationBulkAssignMeasurementTasksArgs = {
  input: Array<GQAssignMeasurementTaskInput>;
};

export type GQMutationBulkCreateUploadTasksArgs = {
  input: GQBulkCreateUploadTasksInput;
};

export type GQMutationBulkCreateUsersForFacilitiesArgs = {
  input: GQBulkCreateUsersForFacilitiesInput;
};

export type GQMutationBulkSetApproversArgs = {
  input: Array<GQSetApproversInput>;
};

export type GQMutationBulkStartCompanySurveyEngagementArgs = {
  input: GQBulkStartCompanySurveyEngagementInput;
};

export type GQMutationCancelWorkflowArgs = {
  input: GQCancelWorkflowInput;
};

export type GQMutationCheckAndGenerateFacilitiesBartsArgs = {
  input: GQCheckAndGenerateFacilitiesBartsInput;
};

export type GQMutationCompleteOnboardingArgs = {
  input: GQCompleteOnboardingInput;
};

export type GQMutationCompleteUserUploadTaskArgs = {
  input: GQCompleteUserUploadTaskInput;
};

export type GQMutationConfirmMarketplacePurchaseArgs = {
  input: GQConfirmMarketplacePurchaseInput;
};

export type GQMutationConfirmUtilitySchemaArgs = {
  input: GQConfirmUtilitySchemaInput;
};

export type GQMutationCreateAndSubmitUserUploadCandidateArgs = {
  input: GQCreateAndSubmitUserUploadCandidateInput;
};

export type GQMutationCreateAssetCommentArgs = {
  input: GQCreateAssetCommentInput;
};

export type GQMutationCreateAssetCorporateArgs = {
  input: GQCreateAssetCorporateInput;
};

export type GQMutationCreateAssetGroupArgs = {
  input: GQCreateAssetGroupInput;
};

export type GQMutationCreateAssetHoldingArgs = {
  input: GQCreateAssetHoldingNewInput;
};

export type GQMutationCreateAssetPersonalMotorVehicleInsuranceArgs = {
  input: GQCreateAssetPersonalMotorVehicleInsuranceInput;
};

export type GQMutationCreateAssetRealEstateArgs = {
  input: GQCreateAssetRealEstateInput;
};

export type GQMutationCreateAssetSovereignBondArgs = {
  input: GQCreateAssetSovereignBondInput;
};

export type GQMutationCreateAssetYearArgs = {
  input: GQCreateAssetYearNewInput;
};

export type GQMutationCreateBiCustomMetricArgs = {
  input: GQCreateBiCustomMetricInput;
};

export type GQMutationCreateBiSavedViewArgs = {
  input: GQCreateBiSavedViewInput;
};

export type GQMutationCreateBiSavedViewAndPropagateToCustomReportsArgs = {
  input: GQCreateBiSavedViewAndPropagateToCustomReportsInput;
};

export type GQMutationCreateBuildingArgs = {
  input: GQCreateBuildingInput;
};

export type GQMutationCreateBuildingUtilityArgs = {
  input: GQCreateBuildingUtilityInput;
};

export type GQMutationCreateCandidateRowEntryArgs = {
  input: GQCreateCandidateRowInput;
};

export type GQMutationCreateCdpApiRegistrationArgs = {
  input: GQCreateCdpApiRegistrationInput;
};

export type GQMutationCreateClimateProgramCalendarEventArgs = {
  input: GQCreateClimateProgramCalendarEventInput;
};

export type GQMutationCreateClimateProgramProjectArgs = {
  input: GQCreateClimateProgramProjectInput;
};

export type GQMutationCreateClimateProgramProjectDatasetRequirementArgs = {
  input: GQCreateClimateProgramProjectDatasetRequirementInput;
};

export type GQMutationCreateCompanyChangeRequestCreateArgs = {
  input: GQCreateCompanyChangeRequestCreateInput;
};

export type GQMutationCreateCompanyRequestCaseArgs = {
  input: GQCreateCompanyRequestCaseInput;
};

export type GQMutationCreateCorporateSandboxEstimationArgs = {
  input: GQCreateCorporateSandboxEstimationInput;
};

export type GQMutationCreateCsrdDataRequirementsConfigArgs = {
  input: GQCreateCsrdDataRequirementsConfigInput;
};

export type GQMutationCreateCtsFormArgs = {
  input: GQCreateCtsFormInput;
};

export type GQMutationCreateCtsFormEntryArgs = {
  input: GQCreateCtsFormEntryInput;
};

export type GQMutationCreateCtsvOneSchemaFileFeedArgs = {
  input: GQCreateCtsvOneSchemaFileFeedInput;
};

export type GQMutationCreateCustomEmissionsFactorsReferenceDataRevisionArgs = {
  input: GQCreateCustomEmissionsFactorsReferenceDataRevisionInput;
};

export type GQMutationCreateCustomerInitiatedDataIssueArgs = {
  input: GQCreateCustomerInitiatedDataIssueInput;
};

export type GQMutationCreateDatasourceArgs = {
  input: GQCreateDatasourceInput;
};

export type GQMutationCreateDemoOrgArgs = {
  input: GQCreateDemoOrgInput;
};

export type GQMutationCreateDiscussionArgs = {
  input: GQCreateDiscussionInput;
};

export type GQMutationCreateEngagementCohortArgs = {
  input: GQCreateEngagementCohortInput;
};

export type GQMutationCreateEngagementCohortsForCompanyArgs = {
  input: GQCreateEngagementCohortsForCompanyInput;
};

export type GQMutationCreateEngagementTaskAttachmentsArgs = {
  input: GQCreateEngagementTaskAttachmentsInput;
};

export type GQMutationCreateFinanceAssetYearsExportArgs = {
  input: GQCreateFinanceExportInput;
};

export type GQMutationCreateFinanceHoldingsExportArgs = {
  input: GQCreateFinanceHoldingsExportInput;
};

export type GQMutationCreateFinanceImportArgs = {
  input: GQCreateFinanceImportInput;
};

export type GQMutationCreateFinanceReportingTagsArgs = {
  input: GQCreateFinanceReportingTagsInput;
};

export type GQMutationCreateFinanceSavedViewArgs = {
  input: GQCreateFinanceSavedViewInput;
};

export type GQMutationCreateFinanceTagArgs = {
  input: GQCreateFinanceTagInput;
};

export type GQMutationCreateFinanceUploadTemplateArgs = {
  input: GQCreateFinanceUploadTemplateInput;
};

export type GQMutationCreateFootprintArgs = {
  input: GQCreateFootprintInput;
};

export type GQMutationCreateFootprintSliceInitiativeArgs = {
  input: GQCreateFootprintSliceInitiativeInput;
};

export type GQMutationCreateForecastArgs = {
  input: GQCreateForecastInput;
};

export type GQMutationCreateFundArgs = {
  input: GQCreateFundInput;
};

export type GQMutationCreateIngestionCustomFieldArgs = {
  input: GQCreateIngestionCustomFieldInput;
};

export type GQMutationCreateMarketplaceEacPurchaseArgs = {
  input: GQCreateMarketplaceEacPurchaseInput;
};

export type GQMutationCreateMaterialityAssessmentArgs = {
  input: GQCreateMaterialityAssessmentInput;
};

export type GQMutationCreateMeasurementProjectFromCanonicalClimateProgramProjectArgs =
  {
    input: GQCreateMeasurementProjectFromCanonicalClimateProgramProjectInput;
  };

export type GQMutationCreateMeasurementProjectFromWizardArgs = {
  input: GQCreateMeasurementProjectFromWizardInput;
};

export type GQMutationCreateOneSchemaSessionForApiUploadArgs = {
  input: GQCreateOneSchemaSessionForApiUploadInput;
};

export type GQMutationCreateOneSchemaSessionForTargetSchemaArgs = {
  input: GQCreateOneSchemaSessionForTargetSchemaInput;
};

export type GQMutationCreateOneSchemaSessionForUserUploadArgs = {
  input: GQCreateOneSchemaSessionForUserUploadInput;
};

export type GQMutationCreateOrgStructureImportTemplateArgs = {
  input: GQCreateOrgStructureImportTemplateInput;
};

export type GQMutationCreateOrgStructureVersionUploadArgs = {
  input: GQCreateOrgStructureVersionUploadInput;
};

export type GQMutationCreateOrgUnitArgs = {
  input: GQCreateOrgUnitInput;
};

export type GQMutationCreateOrgUnitRelationshipArgs = {
  input: GQCreateOrgUnitRelationshipInput;
};

export type GQMutationCreateOrgUnitTypeArgs = {
  input: GQCreateOrgUnitTypeInput;
};

export type GQMutationCreateOrgUnitTypeRelationshipArgs = {
  input: GQCreateOrgUnitTypeRelationshipInput;
};

export type GQMutationCreatePeerCompanyGroupArgs = {
  input: GQCreatePeerCompanyGroupInput;
};

export type GQMutationCreatePermissionItemArgs = {
  input: GQCreatePermissionItemInput;
};

export type GQMutationCreatePlanTargetArgs = {
  input: GQCreatePlanTargetInput;
};

export type GQMutationCreatePlanTargetReduxArgs = {
  input: GQCreatePlanTargetReduxInput;
};

export type GQMutationCreatePlanWithDefaultsArgs = {
  input: InputMaybe<GQCreatePlanWithDefaultsInput>;
};

export type GQMutationCreatePrecomputedActivityBasedInitiativeArgs = {
  input: GQCreatePrecomputedActivityBasedInitiativeInput;
};

export type GQMutationCreatePrivateDisclosureArgs = {
  input: GQCreatePrivateDisclosureInput;
};

export type GQMutationCreateRealEstateSandboxEstimationArgs = {
  input: GQCreateRealEstateSandboxEstimationInput;
};

export type GQMutationCreateRenewableElectricityTargetArgs = {
  input: GQCreateRenewableElectricityTargetInput;
};

export type GQMutationCreateReportAttachmentsArgs = {
  input: GQCreateReportAttachmentsInput;
};

export type GQMutationCreateReportFileAttachmentsArgs = {
  input: GQCreateReportFileAttachmentsInput;
};

export type GQMutationCreateReportUrlAttachmentItemArgs = {
  input: GQCreateReportUrlAttachmentItemInput;
};

export type GQMutationCreateReportWithConfigArgs = {
  input: GQCreateReportWithConfigInput;
};

export type GQMutationCreateReportWithCustomConfigArgs = {
  input: GQCreateReportWithCustomConfigInput;
};

export type GQMutationCreateRoleArgs = {
  input: GQCreateRoleInput;
};

export type GQMutationCreateSbtiTargetExclusionArgs = {
  input: GQCreateSbtiTargetExclusionInput;
};

export type GQMutationCreateSingleUploaderFacilitiesTaskArgs = {
  input: GQCreateSingleUploaderFacilitiesTaskInput;
};

export type GQMutationCreateSnapshotArgs = {
  input: GQCreateSnapshotInput;
};

export type GQMutationCreateSupplierAttachmentsArgs = {
  input: GQCreateSupplierAttachmentsInput;
};

export type GQMutationCreateSupplierChartArgs = {
  input: GQCreateSupplierChartInput;
};

export type GQMutationCreateSupplierContactArgs = {
  input: GQCreateSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationCreateSupplierSavedViewArgs = {
  input: GQCreateSupplierSavedViewInput;
};

export type GQMutationCreateSupplierScoreCriteriaArgs = {
  input: GQCreateSupplierScoreCriteriaInput;
};

export type GQMutationCreateSupplierTableColumnArgs = {
  input: GQCreateSupplierTableColumnInput;
};

export type GQMutationCreateSupportCaseArgs = {
  input: GQCreateSupportCaseInput;
};

export type GQMutationCreateSupportCaseAttachmentsArgs = {
  input: GQCreateSupportCaseAttachmentsInput;
};

export type GQMutationCreateSupportCaseCommentArgs = {
  input: GQCreateSupportCaseCommentInput;
};

export type GQMutationCreateSupportingDocumentsArgs = {
  input: GQCreateSupportingDocumentsInput;
};

export type GQMutationCreateUpdateAndDeleteForecastScenariosArgs = {
  input: GQCreateUpdateAndDeleteForecastScenariosInput;
};

export type GQMutationCreateUserArgs = {
  input: GQCreateUserInput;
};

export type GQMutationCreateUserPermissionRequestArgs = {
  input: GQCreateUserPermissionRequestInput;
};

export type GQMutationCreateUserUploadsArgs = {
  input: GQCreateUserUploadsInput;
};

export type GQMutationCreateUtilityDocsArgs = {
  input: GQCreateUtilityDocsInput;
};

export type GQMutationCreateValueMappingCommentArgs = {
  input: GQCreateValueMappingCommentInput;
};

export type GQMutationCreateWatershedFootprintReviewRequestArgs = {
  input: GQCreateWatershedFootprintReviewRequestInput;
};

export type GQMutationDeleteAllFinanceThingsArgs = {
  input: GQDeleteAllFinanceThingsInput;
};

export type GQMutationDeleteAssetCommentArgs = {
  input: GQDeleteAssetCommentInput;
};

export type GQMutationDeleteAssetHoldingArgs = {
  input: GQDeleteAssetHoldingInput;
};

export type GQMutationDeleteAssetsArgs = {
  input: GQDeleteAssetsInput;
};

export type GQMutationDeleteBiCustomMetricArgs = {
  input: GQDeleteBiCustomMetricInput;
};

export type GQMutationDeleteBiSavedViewArgs = {
  input: GQDeleteBiSavedViewInput;
};

export type GQMutationDeleteBuildingUtilityArgs = {
  input: GQDeleteBuildingUtilityInput;
};

export type GQMutationDeleteBuildingsArgs = {
  input: GQDeleteBuildingsInput;
};

export type GQMutationDeleteCandidateRowEntryArgs = {
  input: GQDeleteCandidateRowInput;
};

export type GQMutationDeleteCdpApiRegistrationArgs = {
  input: GQDeleteCdpApiRegistrationInput;
};

export type GQMutationDeleteClimateProgramCalendarEventArgs = {
  input: GQDeleteClimateProgramCalendarEventInput;
};

export type GQMutationDeleteClimateProgramProjectArgs = {
  input: GQDeleteClimateProgramProjectInput;
};

export type GQMutationDeleteClimateProgramProjectDatasetRequirementArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteClimateProgramTimelineProjectArgs = {
  input: GQDeleteClimateProgramTimelineProjectInput;
};

export type GQMutationDeleteCompanyEngagementCohortArgs = {
  input: GQDeleteCompanyEngagementCohortInput;
};

export type GQMutationDeleteCompanyReportingFactsArgs = {
  input: GQDeleteCompanyReportingFactsInput;
};

export type GQMutationDeleteCtsvOneSchemaFileFeedArgs = {
  ctsvOneSchemaFileFeedId: Scalars['ID']['input'];
};

export type GQMutationDeleteCustomEmissionsFactorsArgs = {
  input: GQDeleteCustomEmissionsFactorsInput;
};

export type GQMutationDeleteEngagementTaskConfigArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteFileArgs = {
  input: GQDeleteFileInput;
};

export type GQMutationDeleteFinanceSavedViewArgs = {
  input: GQDeleteFinanceSavedViewInput;
};

export type GQMutationDeleteFinanceTagArgs = {
  input: GQDeleteFinanceTagInput;
};

export type GQMutationDeleteFinanceTagsArgs = {
  input: GQDeleteFinanceTagsInput;
};

export type GQMutationDeleteFinanceThingsArgs = {
  input: GQDeleteFinanceThingsInput;
};

export type GQMutationDeleteFootprintExportArgs = {
  input: GQDeleteFootprintExportInput;
};

export type GQMutationDeleteFundsArgs = {
  input: GQDeleteFundsInput;
};

export type GQMutationDeleteIngestionCustomFieldArgs = {
  input: GQDeleteIngestionCustomFieldInput;
};

export type GQMutationDeleteInitiativeArgs = {
  input: GQDeleteInitiativeInput;
};

export type GQMutationDeleteMaterialityAssessmentArgs = {
  input: GQDeleteMaterialityAssessmentInput;
};

export type GQMutationDeleteMetricFromCustomReportArgs = {
  input: GQDeleteMetricFromCustomReportInput;
};

export type GQMutationDeleteNonFootprintSupplierArgs = {
  input: GQDeleteNonFootprintSupplierInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationDeleteOrgCompanyArgs = {
  input: GQDeleteOrgCompanyInput;
};

export type GQMutationDeleteOrgStructureVersionArgs = {
  input: GQDeleteOrgStructureVersionInput;
};

export type GQMutationDeleteOrgUnitTypeArgs = {
  input: GQDeleteOrgUnitTypeInput;
};

export type GQMutationDeleteOrgUnitTypeRelationshipArgs = {
  input: GQDeleteOrgUnitTypeRelationshipInput;
};

export type GQMutationDeleteOrgUnitsArgs = {
  input: GQDeleteOrgUnitsInput;
};

export type GQMutationDeletePeerCompanyGroupArgs = {
  input: GQDeletePeerCompanyGroupInput;
};

export type GQMutationDeletePlanArgs = {
  input: GQDeletePlanInput;
};

export type GQMutationDeletePlanTargetArgs = {
  input: GQDeletePlanTargetInput;
};

export type GQMutationDeletePrivateDisclosureTargetByIdArgs = {
  input: GQIdInput;
};

export type GQMutationDeleteReportAnswerArgs = {
  input: GQDeleteReportAnswerInput;
};

export type GQMutationDeleteReportAttachmentArgs = {
  input: GQDeleteReportAttachmentInput;
};

export type GQMutationDeleteReportAttachmentItemArgs = {
  input: GQDeleteReportAttachmentItemInput;
};

export type GQMutationDeleteReportQuestionNoteItemArgs = {
  input: GQDeleteReportQuestionNoteItemInput;
};

export type GQMutationDeleteRoleArgs = {
  input: GQDeleteRoleInput;
};

export type GQMutationDeleteSnapshotArgs = {
  input: GQDeleteSnapshotInput;
};

export type GQMutationDeleteSupplierAttachmentArgs = {
  input: GQDeleteSupplierAttachmentInput;
};

export type GQMutationDeleteSupplierChartArgs = {
  input: GQDeleteSupplierChartInput;
};

export type GQMutationDeleteSupplierContactArgs = {
  input: GQDeleteSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationDeleteSupplierSavedViewArgs = {
  input: GQDeleteSupplierSavedViewInput;
};

export type GQMutationDeleteSupplierScoreCriteriaArgs = {
  input: GQDeleteSupplierScoreCriteriaInput;
};

export type GQMutationDeleteSupplierTableColumnArgs = {
  input: GQDeleteSupplierTableColumnInput;
};

export type GQMutationDeleteSupportCaseAttachmentsArgs = {
  input: GQDeleteSupportCaseAttachmentsInput;
};

export type GQMutationDeleteSupportingDocumentArgs = {
  input: GQDeleteSupportingDocumentInput;
};

export type GQMutationDeleteUserUploadCandidateAndRowsArgs = {
  input: GQDeleteUserUploadCandidateAndRowsInput;
};

export type GQMutationDeleteUserUploadTaskArgs = {
  input: GQDeleteUserUploadTaskInput;
};

export type GQMutationDeleteUserUploadTasksArgs = {
  input: GQDeleteUserUploadTasksInput;
};

export type GQMutationDeleteValueMappingCommentArgs = {
  input: GQDeleteValueMappingCommentInput;
};

export type GQMutationDeleteWatershedFootprintReviewRequestArgs = {
  input: GQDeleteWatershedFootprintReviewRequestInput;
};

export type GQMutationDisconnectIntegrationConnectionArgs = {
  input: GQDisconnectIntegrationConnectionInput;
};

export type GQMutationDuplicateInitiativeArgs = {
  input: GQDuplicateInitiativeInput;
};

export type GQMutationDuplicateOrgStructureVersionArgs = {
  input: GQDuplicateOrgStructureVersionInput;
};

export type GQMutationDuplicatePlanArgs = {
  input: GQDuplicatePlanInput;
};

export type GQMutationEditIngestionCustomFieldArgs = {
  input: GQEditIngestionCustomFieldInput;
};

export type GQMutationEditValueMappingCommentArgs = {
  input: GQEditValueMappingCommentInput;
};

export type GQMutationExecuteExtractSchemasArgs = {
  input: GQExecuteExtractSchemasInput;
};

export type GQMutationFetchSupportCaseAttachmentDownloadUrlArgs = {
  input: GQFetchSupportCaseAttachmentDownloadUrlInput;
};

export type GQMutationGenerateAuditReportArgs = {
  input: GQGenerateAuditReportInput;
};

export type GQMutationGenerateAuditReportForAssetYearArgs = {
  input: GQFinanceAuditReportForAssetYearInput;
};

export type GQMutationGenerateBartFromFileUploadArgs = {
  input: GQGenerateBartFromFileUploadInput;
};

export type GQMutationGenerateCustomIntensityConfigIdArgs = {
  input: GQGenerateCustomIntensityConfigIdInput;
};

export type GQMutationGenerateCustomerUploadedReferenceDataRevisionArgs = {
  input: GQGenerateCustomerUploadedReferenceDataRevisionInput;
};

export type GQMutationGenerateExportUrlArgs = {
  input: GQGenerateExportUrlInput;
};

export type GQMutationGenerateFinanceAuditReportsForAssetArgs = {
  input: GQGenerateFinanceAuditReportsForAssetInput;
};

export type GQMutationGenerateFinanceHomeExportArgs = {
  input: GQGenerateFinanceHomeExportInput;
};

export type GQMutationGenerateFootprintV2Args = {
  input: GQGenerateFootprintV2Input;
};

export type GQMutationGenerateForecastScenarioIdArgs = {
  input: GQGenerateForecastScenarioIdInput;
};

export type GQMutationGenerateOverrideReferenceDataRevisionArgs = {
  input: GQGenerateOverrideReferenceDataRevisionInput;
};

export type GQMutationGenerateTemplateDownloadUrlForDatasetArgs = {
  input: GQGenerateTemplateDownloadUrlForDatasetInput;
};

export type GQMutationGenerateTemplateDownloadUrlForOrgDatasetsArgs = {
  input: GQGenerateTemplateDownloadUrlForOrgDatasetsInput;
};

export type GQMutationGenerateValueMappingDownloadUrlArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  valueMappingConfigId: Scalars['ID']['input'];
  withComments: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQMutationGetOrCreateDatasetFromCanonicalDatasetKindArgs = {
  input: GQGetOrCreateDatasetFromCanonicalDatasetKindInput;
};

export type GQMutationGetOrSendEmailsArgs = {
  input: GQGetOrSendEmailsInput;
};

export type GQMutationGetOrSendMeasurementEmailsArgs = {
  input: GQGetOrSendMeasurementEmailsInput;
};

export type GQMutationGiveFeedbackArgs = {
  input: GQGiveFeedbackInput;
};

export type GQMutationGrantUserRoleArgs = {
  input: GQGrantUserRoleInput;
};

export type GQMutationJoinReportWaitlistArgs = {
  kind: Scalars['String']['input'];
};

export type GQMutationJoinWaitlistArgs = {
  type: GQProductWaitlistType;
};

export type GQMutationLeaveReportWaitlistArgs = {
  kind: Scalars['String']['input'];
};

export type GQMutationLeaveWaitlistArgs = {
  type: GQProductWaitlistType;
};

export type GQMutationLogCustomerHubViewedArgs = {
  input: GQLogCustomerHubViewedInput;
};

export type GQMutationLogSupplyChainSurveyEventArgs = {
  input: GQLogSupplyChainSurveyEventInput;
};

export type GQMutationLogUpdateVendorMatchingTaskArgs = {
  input: GQLogUpdateVendorMatchingTaskInput;
};

export type GQMutationMarkDemoPlatformRunAsFailedArgs = {
  input: GQMarkDemoPlatformRunAsFailedInput;
};

export type GQMutationMarkSupportCaseNotificationReadArgs = {
  input: GQMarkSupportCaseNotificationReadInput;
};

export type GQMutationMarkUserUploadAsProcessingArgs = {
  input: GQMarkUserUploadAsProcessingInput;
};

export type GQMutationMarkUserUploadCompletedArgs = {
  input: GQMarkUserUploadCompletedInput;
};

export type GQMutationMaybeCreateIntegrationConnectionArgs = {
  input: GQMaybeCreateIntegrationConnectionInput;
};

export type GQMutationNotifyEngagementTaskCreationArgs = {
  input: GQNotifyEngagementTaskCreationInput;
};

export type GQMutationNotifyReportAssigneesArgs = {
  input: GQNotifyReportAssigneesInput;
};

export type GQMutationOverrideContainerProgressArgs = {
  input: GQOverrideContainerProgressInput;
};

export type GQMutationParquetDataToCsvArgs = {
  input: GQParquetDataToCsvInput;
};

export type GQMutationPrepareActivityRecordExportArgs = {
  input: GQPrepareActivityRecordExportInput;
};

export type GQMutationPrepareFootprintGenerationParamsArgs = {
  input: GQPrepareFootprintGenerationParamsInput;
};

export type GQMutationPreviewUtilitiesDiffArgs = {
  input: GQPreviewUtilitiesDiffInput;
};

export type GQMutationPublishCustomTransformForUserUploadArgs = {
  input: GQPublishCustomTransformForUserUploadInput;
};

export type GQMutationPublishDraftFootprintArgs = {
  input: GQPublishDraftFootprintInput;
};

export type GQMutationPublishOrgStructureVersionArgs = {
  input: GQPublishOrgStructureVersionInput;
};

export type GQMutationPublishValueMappingArtifactArgs = {
  input: GQPublishValueMappingArtifactInput;
};

export type GQMutationPushBartBuildingArgs = {
  input: GQPushBartBuildingInput;
};

export type GQMutationPushCtsVersionToOneSchemaInProductArgs = {
  input: GQPushCtsVersionToOneSchemaInProductInput;
};

export type GQMutationPushUtilitiesDiffArgs = {
  input: GQPushUtilitiesDiffInput;
};

export type GQMutationReassignFacilitiesTaskArgs = {
  input: GQReassignFacilitiesTaskInput;
};

export type GQMutationRefreshMarketplacePurchaseArgs = {
  input: GQRefreshMarketplacePurchaseInput;
};

export type GQMutationRegenerateAuditReportArgs = {
  input: GQRegenerateAuditReportInput;
};

export type GQMutationRejectDataArgs = {
  input: GQRejectDataInput;
};

export type GQMutationRejectReportQuestionInstanceArgs = {
  input: GQRejectReportQuestionInstanceInput;
};

export type GQMutationRemoveCompanyFromPeerCompanyGroupArgs = {
  input: GQRemoveCompanyFromPeerCompanyGroupInput;
};

export type GQMutationRemoveUserArgs = {
  input: GQRemoveUserInput;
};

export type GQMutationRenameBiSavedViewArgs = {
  input: GQRenameBiSavedViewInput;
};

export type GQMutationRenameFootprintArgs = {
  input: GQRenameFootprintInput;
};

export type GQMutationRenameReportArgs = {
  input: GQRenameReportInput;
};

export type GQMutationRequestDataApprovalArgs = {
  input: GQRequestDataApprovalInput;
};

export type GQMutationRequestSupplyChainHelpArgs = {
  input: GQRequestHelpInput;
};

export type GQMutationRequestUnlockDataArgs = {
  input: GQRequestUnlockDataInput;
};

export type GQMutationRerunBartForPresetTagWorkflowArgs = {
  input: GQRerunBartForPresetTagWorkflowInput;
};

export type GQMutationResendUserInviteLinkArgs = {
  input: GQResendUserInviteLinkInput;
};

export type GQMutationResetLockedUserArgs = {
  input: GQResetLockedUserInput;
};

export type GQMutationResolveDataIssueArgs = {
  input: GQResolveDataIssueInput;
};

export type GQMutationResolveDiscussionArgs = {
  input: GQResolveDiscussionInput;
};

export type GQMutationRespondToDataIssueArgs = {
  input: GQRespondToDataIssueInput;
};

export type GQMutationRespondToDiscussionArgs = {
  input: GQRespondToDiscussionInput;
};

export type GQMutationRestoreDeletedSavedViewArgs = {
  input: GQRestoreDeletedSavedViewInput;
};

export type GQMutationReviewFinancialsReviewItemArgs = {
  input: GQReviewFinancialsReviewItemInput;
};

export type GQMutationRevokePermissionItemArgs = {
  input: GQRevokePermissionItemInput;
};

export type GQMutationRevokeUserAppSessionsArgs = {
  input: GQRevokeUserAppSessionsInput;
};

export type GQMutationRevokeUserRoleArgs = {
  input: GQRevokeUserRoleInput;
};

export type GQMutationRunCeeForEngagementTaskArgs = {
  input: GQRunCeeForEngagementTaskInput;
};

export type GQMutationRunCtsTransformEditorArgs = {
  input: GQRunCtsTransformEditorDashboardInput;
};

export type GQMutationRunDemoPlatformArgs = {
  input: GQRunDemoPlatformInput;
};

export type GQMutationRunPleeForEngagementTaskArgs = {
  input: GQRunPleeForEngagementTaskInput;
};

export type GQMutationSaveCategoryRuleGhgCategoryNoteArgs = {
  input: GQSaveCategoryRuleGhgCategoryNoteInput;
};

export type GQMutationSaveDeltaProgressValueMappingArgs = {
  input: GQSaveDeltaProgressValueMappingInput;
};

export type GQMutationSaveEmployeeFeedbackArgs = {
  input: GQSaveEmployeeFeedbackInput;
};

export type GQMutationSaveIncompleteOneSchemaImportArgs = {
  input: GQSaveIncompleteOneSchemaImportInput;
};

export type GQMutationSaveProductionGraphArgs = {
  input: GQSaveProductionGraphInput;
};

export type GQMutationSendIntegrationInviteArgs = {
  input: GQSendIntegrationInviteInput;
};

export type GQMutationSetApproversArgs = {
  input: GQSetApproversInput;
};

export type GQMutationSetArchiveEngagementTaskConfigArgs = {
  input: GQSetArchiveEngagementTaskConfigInput;
};

export type GQMutationSetAssetBenchmarksArgs = {
  input: GQSetAssetBenchmarksInput;
};

export type GQMutationSetEngagementCohortAndMembersArgs = {
  input: GQSetEngagementCohortAndMembersInput;
};

export type GQMutationSetEngagementCohortsForCompanyArgs = {
  input: GQSetEngagementCohortsForCompanyInput;
};

export type GQMutationSetEngagementNotificationSettingsForUserArgs = {
  input: GQSetEngagementNotificationSettingsForUserInput;
};

export type GQMutationSetPeerCompanyGroupMemberFeatureStatusArgs = {
  input: GQSetPeerCompanyGroupMemberFeatureStatusInput;
};

export type GQMutationSetPreferredLocaleArgs = {
  input: GQSetPreferredLocaleInput;
};

export type GQMutationSetPublishEngagementTaskConfigArgs = {
  input: GQSetPublishEngagementTaskConfigInput;
};

export type GQMutationSetUserUploadedTableSheetsNotIngestedArgs = {
  input: GQSetUserUploadedTableSheetsNotIngestedInput;
};

export type GQMutationSoftDeleteCsrdDataRequirementsConfigArgs = {
  input: GQSoftDeleteCsrdDataRequirementsConfigInput;
};

export type GQMutationStartCdpSyncAnswersArgs = {
  input: GQStartCdpSyncAnswersInput;
};

export type GQMutationStartFileFeedEmbeddedSessionArgs = {
  input: GQStartFileFeedEmbeddedSessionInput;
};

export type GQMutationSubmitAndCloseCtsFormArgs = {
  ctsFormId: Scalars['ID']['input'];
};

export type GQMutationSubmitBuildingsArgs = {
  input: GQSubmitFacilitiesInput;
};

export type GQMutationSubmitDataLineageFeedbackArgs = {
  input: GQSubmitDataLineageFeedbackInput;
};

export type GQMutationSubmitEarlyUploadHelpRequestArgs = {
  input: GQSubmitEarlyUploadHelpRequestInput;
};

export type GQMutationSubmitEngagementTaskArgs = {
  input: GQSubmitEngagementTaskInput;
};

export type GQMutationSubmitFacilitiesBulkAdditionArgs = {
  input: GQSubmitFacilitiesBulkAdditionInput;
};

export type GQMutationSubmitReportQuestionInstanceArgs = {
  input: GQSubmitReportQuestionInstanceInput;
};

export type GQMutationSubmitUserUploadTaskArgs = {
  input: GQSubmitUserUploadTaskInput;
};

export type GQMutationSubmitUtilitiesArgs = {
  input: GQSubmitFacilitiesInput;
};

export type GQMutationSubmitVendorMatchingTaskArgs = {
  input: GQSubmitVendorMatchingTaskInput;
};

export type GQMutationSuggestMappingsArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQMutationSupplierDataExportArgs = {
  input: GQSupplierDataExportInput;
};

export type GQMutationSupplierSurveyResponseExportArgs = {
  input: GQSupplierSurveyResponseExportInput;
};

export type GQMutationTestingApproveReportQuestionInstancesArgs = {
  input: GQTestingApproveReportQuestionInstancesInput;
};

export type GQMutationTestingRejectReportQuestionInstancesArgs = {
  input: GQTestingRejectReportQuestionInstancesInput;
};

export type GQMutationTestingSubmitReportQuestionInstancesArgs = {
  input: GQTestingSubmitReportQuestionInstancesInput;
};

export type GQMutationTestingWithdrawReportQuestionInstancesArgs = {
  input: GQTestingWithdrawReportQuestionInstancesInput;
};

export type GQMutationThrowErrorArgs = {
  input: GQThrowErrorInput;
};

export type GQMutationTransformAndSubmitUploadArgs = {
  input: GQTransformAndSubmitUploadInput;
};

export type GQMutationUnassignFacilitiesFromTaskArgs = {
  input: GQUnassignFacilitiesFromTaskInput;
};

export type GQMutationUnassignFromContainerArgs = {
  input: GQUnassignFromContainerInput;
};

export type GQMutationUncompleteUserUploadTaskArgs = {
  input: GQUncompleteUserUploadTaskInput;
};

export type GQMutationUnlockDataArgs = {
  input: GQUnlockDataInput;
};

export type GQMutationUnpublishOrgStructureVersionArgs = {
  input: GQUnpublishOrgStructureVersionInput;
};

export type GQMutationUpdateApproversForReportQuestionInstancesArgs = {
  input: GQUpdateApproversForReportQuestionInstancesInput;
};

export type GQMutationUpdateAssetCommentArgs = {
  input: GQUpdateAssetCommentInput;
};

export type GQMutationUpdateAssetCorporateArgs = {
  input: GQUpdateAssetCorporateInput;
};

export type GQMutationUpdateAssetGroupArgs = {
  input: GQUpdateAssetGroupInput;
};

export type GQMutationUpdateAssetHoldingArgs = {
  input: GQUpdateAssetHoldingNewInput;
};

export type GQMutationUpdateAssetPersonalMotorVehicleInsuranceArgs = {
  input: GQUpdateAssetPersonalMotorVehicleInsuranceInput;
};

export type GQMutationUpdateAssetRealEstateArgs = {
  input: GQUpdateAssetRealEstateInput;
};

export type GQMutationUpdateAssetSovereignBondArgs = {
  input: GQUpdateAssetSovereignBondInput;
};

export type GQMutationUpdateAssetYearArgs = {
  input: GQUpdateAssetYearNewInput;
};

export type GQMutationUpdateAssetYearsArgs = {
  input: GQUpdateAssetYearsNewInput;
};

export type GQMutationUpdateBiCustomMetricArgs = {
  input: GQUpdateBiCustomMetricInput;
};

export type GQMutationUpdateBiSavedViewArgs = {
  input: GQUpdateBiSavedViewInput;
};

export type GQMutationUpdateBuildingArgs = {
  input: GQUpdateBuildingInput;
};

export type GQMutationUpdateBuildingUtilityArgs = {
  input: GQUpdateBuildingUtilityInput;
};

export type GQMutationUpdateBuildingsArgs = {
  input: GQUpdateBuildingsInput;
};

export type GQMutationUpdateCandidateRowEntryArgs = {
  input: GQUpdateCandidateRowInput;
};

export type GQMutationUpdateClimateProgramCalendarEventArgs = {
  input: GQUpdateClimateProgramCalendarEventInput;
};

export type GQMutationUpdateClimateProgramProjectArgs = {
  input: GQUpdateClimateProgramProjectInput;
};

export type GQMutationUpdateClimateProgramProjectDatasetRequirementArgs = {
  input: GQUpdateClimateProgramProjectDatasetRequirementInput;
};

export type GQMutationUpdateClimateProgramTimelineProjectArgs = {
  input: GQUpdateClimateProgramTimelineProjectInput;
};

export type GQMutationUpdateCtsFormArgs = {
  input: GQUpdateCtsFormInput;
};

export type GQMutationUpdateDatasourceArgs = {
  input: GQUpdateDatasourceInput;
};

export type GQMutationUpdateEngagementTaskArgs = {
  input: GQUpdateEngagementTaskInput;
  supplierId: Scalars['ID']['input'];
};

export type GQMutationUpdateEngagementTaskConfigArgs = {
  input: GQUpdateEngagementTaskConfigInput;
};

export type GQMutationUpdateFinanceAutomaticRemindersArgs = {
  input: GQUpdateFinanceAutomaticRemindersInput;
};

export type GQMutationUpdateFinanceSavedViewArgs = {
  input: GQUpdateFinanceSavedViewInput;
};

export type GQMutationUpdateFinanceSettingsArgs = {
  input: GQUpdateFinanceSettingsInput;
};

export type GQMutationUpdateFinanceTagArgs = {
  input: GQUpdateFinanceTagInput;
};

export type GQMutationUpdateFinanceWorksheetArgs = {
  input: GQUpdateFinanceWorksheetInput;
};

export type GQMutationUpdateFootprintAssemblyRunStatusArgs = {
  input: GQUpdateFootprintAssemblyRunStatusInput;
};

export type GQMutationUpdateFootprintSliceInitiativeArgs = {
  input: GQUpdateFootprintSliceInitiativeInput;
};

export type GQMutationUpdateForecastArgs = {
  input: GQUpdateForecastInput;
};

export type GQMutationUpdateFundArgs = {
  input: GQUpdateFundInput;
};

export type GQMutationUpdateMaterialityAssessmentArgs = {
  input: GQUpdateMaterialityAssessmentInput;
};

export type GQMutationUpdateMeasurementProjectDashboardArgs = {
  input: GQUpdateMeasurementProjectInput;
};

export type GQMutationUpdateOrgArgs = {
  input: GQUpdateOrgInput;
};

export type GQMutationUpdateOrgProfileArgs = {
  input: GQUpdateOrgProfileInput;
};

export type GQMutationUpdateOrgUnitTypeArgs = {
  input: GQUpdateOrgUnitTypeInput;
};

export type GQMutationUpdateOrgUnitTypeRelationshipArgs = {
  input: GQUpdateOrgUnitTypeRelationshipInput;
};

export type GQMutationUpdateOrgUnitsArgs = {
  input: GQUpdateOrgUnitsInput;
};

export type GQMutationUpdatePeerCompanyGroupArgs = {
  input: GQUpdatePeerCompanyGroupInput;
};

export type GQMutationUpdatePermissionsArgs = {
  input: GQUpdatePermissionsInput;
};

export type GQMutationUpdatePlanArgs = {
  input: GQUpdatePlanAndArtifactsInput;
};

export type GQMutationUpdatePlanSettingsArgs = {
  input: GQUpdatePlanSettingsInput;
};

export type GQMutationUpdatePlanStateArgs = {
  input: GQUpdatePlanStateInput;
};

export type GQMutationUpdatePlanTargetArgs = {
  input: GQUpdatePlanTargetInput;
};

export type GQMutationUpdatePrecomputedActivityBasedInitiativeArgs = {
  input: GQUpdatePrecomputedActivityBasedInitiativeInput;
};

export type GQMutationUpdatePrivateDisclosureArgs = {
  input: GQUpdatePrivateDisclosureInput;
};

export type GQMutationUpdateReportAnswerOverrideStatesArgs = {
  input: GQUpdateReportAnswerOverrideStatesInput;
};

export type GQMutationUpdateReportConfigDashboardArgs = {
  input: GQUpdateReportConfigDashboardInput;
};

export type GQMutationUpdateReportQuestionAssigneesArgs = {
  input: GQUpdateReportQuestionAssigneesInput;
};

export type GQMutationUpdateReportQuestionNoteArgs = {
  input: GQUpdateReportQuestionNoteInput;
};

export type GQMutationUpdateReportQuestionNoteItemArgs = {
  input: GQUpdateReportQuestionNoteItemInput;
};

export type GQMutationUpdateReportingIntentionsArgs = {
  input: GQUpdateReportingIntentionsInput;
};

export type GQMutationUpdateRoleArgs = {
  input: GQUpdateRoleInput;
};

export type GQMutationUpdateSnapshotNameArgs = {
  input: GQUpdateSnapshotNameInput;
};

export type GQMutationUpdateSupplierAttachmentArgs = {
  input: GQUpdateSupplierAttachmentInput;
};

export type GQMutationUpdateSupplierChartArgs = {
  input: GQUpdateSupplierChartInput;
};

export type GQMutationUpdateSupplierColumnOrderArgs = {
  input: GQUpdateSupplierColumnOrderInput;
};

export type GQMutationUpdateSupplierColumnVisibilityBatchArgs = {
  input: GQUpdateSupplierColumnVisibilityBatchInput;
};

export type GQMutationUpdateSupplierColumnWidthArgs = {
  input: GQUpdateSupplierColumnWidthInput;
};

export type GQMutationUpdateSupplierContactArgs = {
  input: GQUpdateSupplierContactInput;
  supplierId: Scalars['String']['input'];
};

export type GQMutationUpdateSupplierCustomDataArgs = {
  input: GQUpdateSupplierCustomDataInput;
};

export type GQMutationUpdateSupplierSavedViewArgs = {
  input: GQUpdateSupplierSavedViewInput;
};

export type GQMutationUpdateSupplierScoreCriteriaArgs = {
  input: GQUpdateSupplierScoreCriteriaInput;
};

export type GQMutationUpdateSupplierTableColumnArgs = {
  input: GQUpdateSupplierTableColumnInput;
};

export type GQMutationUpdateSuppliersSettingsArgs = {
  input: GQUpdateSuppliersSettingsInput;
};

export type GQMutationUpdateSupportCaseArgs = {
  input: GQUpdateSupportCaseInput;
};

export type GQMutationUpdateUserArgs = {
  input: GQUpdateUserInput;
};

export type GQMutationUpdateUserIngestionReviewArgs = {
  input: GQUpdateUserIngestionReviewInput;
};

export type GQMutationUpdateUserNameArgs = {
  input: GQUpdateUserNameInput;
};

export type GQMutationUpdateUserPermissionRequestArgs = {
  input: GQUpdateUserPermissionRequestInput;
};

export type GQMutationUpdateUserUploadArgs = {
  input: GQUpdateUserUploadInput;
};

export type GQMutationUpdateWatershedFootprintReviewRequestStatusArgs = {
  input: GQUpdateWatershedFootprintReviewRequestStatusInput;
};

export type GQMutationUpsertCompanyPortalSettingsArgs = {
  input: GQUpsertCompanyPortalSettingsInput;
};

export type GQMutationUpsertCompanyReportingFactsArgs = {
  input: GQUpsertCompanyReportingFactsInput;
};

export type GQMutationUpsertDocumentParseAttemptArgs = {
  input: GQUpsertDocumentParseAttemptInput;
};

export type GQMutationUpsertEngagementTaskAnswersArgs = {
  input: GQUpsertEngagementTaskAnswersInput;
};

export type GQMutationUpsertFootprintCategoryRulesArgs = {
  input: GQUpsertFootprintCategoryRulesInput;
};

export type GQMutationUpsertFootprintExclusionRulesV2Args = {
  input: GQUpsertFootprintExclusionRulesV2Input;
};

export type GQMutationUpsertNonFootprintSupplierArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  input: GQUpsertNonFootprintSupplierInput;
};

export type GQMutationUpsertOrgCompanyForNameArgs = {
  input: GQUpsertOrgCompanyForNameInput;
};

export type GQMutationUpsertOrgStructureVersionArgs = {
  input: GQUpsertOrgStructureVersionInput;
};

export type GQMutationUpsertReportAnswerOverrideArgs = {
  input: GQUpsertReportAnswerOverrideInput;
};

export type GQMutationUpsertReportAnswerWithMetadataArgs = {
  input: GQUpsertReportAnswerInput;
};

export type GQMutationUpsertReportQuestionInstancesAccessArgs = {
  input: GQUpsertReportQuestionInstancesAccessInput;
};

export type GQMutationUpsertReportsAccessArgs = {
  input: GQUpsertReportsAccessInput;
};

export type GQMutationValidateFormAgainstCtsArgs = {
  input: GQValidateFormAgainstCtsInput;
};

export type GQMutationValidateUserUploadCandidateArgs = {
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQMutationWithdrawCompanyChangeRequestArgs = {
  input: GQWithdrawCompanyChangeRequestInput;
};

export type GQMutationWithdrawReportQuestionInstanceArgs = {
  input: GQWithdrawReportQuestionInstanceInput;
};

export type GQNetZeroCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'NetZeroCommitment';
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    targetYear: Maybe<Scalars['Int']['output']>;
  };

export const GQNetZeroNormalizationKind = {
  Revenue: 'Revenue',
  Total: 'Total',
} as const;

export type GQNetZeroNormalizationKind =
  (typeof GQNetZeroNormalizationKind)[keyof typeof GQNetZeroNormalizationKind];
export type GQNetZeroPlan = GQIdInterface & {
  __typename?: 'NetZeroPlan';
  descriptionMd: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  summary: Maybe<GQNetZeroSummary>;
};

export type GQNetZeroPlanSummaryArgs = {
  kind: InputMaybe<GQNetZeroNormalizationKind>;
};

export type GQNetZeroSummary = {
  __typename?: 'NetZeroSummary';
  kind: GQNetZeroNormalizationKind;
  years: Array<GQNetZeroSummaryYear>;
};

export type GQNetZeroSummaryYear = {
  __typename?: 'NetZeroSummaryYear';
  bauEmissionsAvoided: Scalars['Float']['output'];
  carbonRemoval: Scalars['Float']['output'];
  emissionsWithReductions: Scalars['Float']['output'];
  kind: GQNetZeroNormalizationKind;
  year: Scalars['Int']['output'];
};

export type GQNetsuiteIntegrationSettings = {
  __typename?: 'NetsuiteIntegrationSettings';
  accountNamesToExclude: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  transactionTypeFilters: Array<Scalars['String']['output']>;
  usePostingPeriodForDateFilters: Scalars['Boolean']['output'];
};

export const GQNextLifecycleStage = {
  Measurement: 'Measurement',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
} as const;

export type GQNextLifecycleStage =
  (typeof GQNextLifecycleStage)[keyof typeof GQNextLifecycleStage];
export type GQNotifyEngagementTaskCreationInput = {
  contactEmails: Array<Scalars['String']['input']>;
  emailBlurb?: InputMaybe<Scalars['String']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  engagementTaskBatchId: Scalars['ID']['input'];
  forPortcos?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQNotifyEngagementTaskCreationPayload = {
  __typename?: 'NotifyEngagementTaskCreationPayload';
  engagementTaskBatch: GQEngagementTaskBatch;
};

export type GQNotifyReportAssigneesInput = {
  assigneeIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportShortName: Scalars['String']['input'];
};

export type GQNotifyReportAssigneesPayload = {
  __typename?: 'NotifyReportAssigneesPayload';
  notifiedUserIds: Array<Scalars['ID']['output']>;
};

export const GQObjectType = {
  Building: 'Building',
} as const;

export type GQObjectType = (typeof GQObjectType)[keyof typeof GQObjectType];
export type GQOffsetPaginationArgsInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export const GQOnboardingKind = {
  BenchmarksV1Onboarding: 'BenchmarksV1Onboarding',
  BiDrilldownBeta: 'BiDrilldownBeta',
  BiDrilldownSavedViews: 'BiDrilldownSavedViews',
  CdpUpdateFy2024Q4Onboarding: 'CDPUpdateFY2024Q4Onboarding',
  ClimateProgramTimelineOnboarding: 'ClimateProgramTimelineOnboarding',
  ConvergenceHomepage: 'ConvergenceHomepage',
  ConvergenceOverall: 'ConvergenceOverall',
  ConvergenceReporting: 'ConvergenceReporting',
  ConvergenceWhereAreViews: 'ConvergenceWhereAreViews',
  DataLineageCalculationTableOnboarding:
    'DataLineageCalculationTableOnboarding',
  DataLineageDetailViewOnboarding: 'DataLineageDetailViewOnboarding',
  DataReviewOnboarding: 'DataReviewOnboarding',
  DedupeV2Banner: 'DedupeV2Banner',
  DrilldownDataGridBeta: 'DrilldownDataGridBeta',
  FinanceFlagsOnboarding: 'FinanceFlagsOnboarding',
  FootprintFeedbackSurvey: 'FootprintFeedbackSurvey',
  IdiOnboarding: 'IdiOnboarding',
  MarketplaceOnboarding: 'MarketplaceOnboarding',
  MeasurementEmptyStateExpandHowToMeasure:
    'MeasurementEmptyStateExpandHowToMeasure',
  MeasurementTaskTabOnboarding: 'MeasurementTaskTabOnboarding',
  MeasurementTasksOnboarding: 'MeasurementTasksOnboarding',
  MeasurementTimelineOnboarding: 'MeasurementTimelineOnboarding',
  MultipleFootprintsBeta: 'MultipleFootprintsBeta',
  MultipleFootprintsIntroTour: 'MultipleFootprintsIntroTour',
  NetworkTasksCalloutOnboarding: 'NetworkTasksCalloutOnboarding',
  NetworkTasksOnboarding: 'NetworkTasksOnboarding',
  NewBuildingsPageOnboarding: 'NewBuildingsPageOnboarding',
  NewToMeasurementOnboarding: 'NewToMeasurementOnboarding',
  ReductionsOnboarding: 'ReductionsOnboarding',
  ReduxEnterpriseOnboarding: 'ReduxEnterpriseOnboarding',
  ReportingCustomReportsWelcome: 'ReportingCustomReportsWelcome',
  SavedViewsLandingPage: 'SavedViewsLandingPage',
  TcfdOnboarding: 'TcfdOnboarding',
  ValueMappingOnboarding: 'ValueMappingOnboarding',
  WelcomeOnboarding: 'WelcomeOnboarding',
} as const;

export type GQOnboardingKind =
  (typeof GQOnboardingKind)[keyof typeof GQOnboardingKind];
export const GQOneSchemaDataFormat = {
  AssetCorporate: 'AssetCorporate',
  AssetGroup: 'AssetGroup',
  AssetHoldingAll: 'AssetHoldingAll',
  AssetPersonalMotorVehicleInsurance: 'AssetPersonalMotorVehicleInsurance',
  AssetRealEstate: 'AssetRealEstate',
  AssetSovereignBond: 'AssetSovereignBond',
  AssetYearDefault: 'AssetYearDefault',
  Fund: 'Fund',
  PersonalMotorVehicleInsuranceAssetHolding:
    'PersonalMotorVehicleInsuranceAssetHolding',
  PersonalMotorVehicleInsuranceAssetYear:
    'PersonalMotorVehicleInsuranceAssetYear',
  PointOfContact: 'PointOfContact',
  SovereignBondAssetYear: 'SovereignBondAssetYear',
  SupplierContact: 'SupplierContact',
} as const;

export type GQOneSchemaDataFormat =
  (typeof GQOneSchemaDataFormat)[keyof typeof GQOneSchemaDataFormat];
export type GQOneSchemaJwtPayload = {
  __typename?: 'OneSchemaJwtPayload';
  token: Scalars['String']['output'];
};

export type GQOrgAccess = {
  __typename?: 'OrgAccess';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  loginActivated: Scalars['Boolean']['output'];
};

export type GQOrgCompany = {
  __typename?: 'OrgCompany';
  company: Maybe<GQCompany>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  source: Maybe<GQOrgCompanySource>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export const GQOrgCompanySource = {
  CorporateSupplier: 'CorporateSupplier',
  FundHolding: 'FundHolding',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
} as const;

export type GQOrgCompanySource =
  (typeof GQOrgCompanySource)[keyof typeof GQOrgCompanySource];
/** An object representing limited essential information about an organization */
export type GQOrgLimitedProfile = {
  __typename?: 'OrgLimitedProfile';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  engagementTasks: Array<GQEngagementTaskLimitedProfile>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  watershedPlanLegacy: GQWatershedPlanLegacy;
};

export type GQOrgPointOfContact = GQIdInterface & {
  __typename?: 'OrgPointOfContact';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  kind: GQOrgPointOfContactKind;
  orgId: Scalars['String']['output'];
  watershedEmployee: GQWatershedEmployee;
};

export const GQOrgPointOfContactKind = {
  AccountManager: 'AccountManager',
  AccountOwner: 'AccountOwner',
  CarbonDataAnalyst: 'CarbonDataAnalyst',
  ClimateAdvisor: 'ClimateAdvisor',
  CustomerSuccessManager: 'CustomerSuccessManager',
} as const;

export type GQOrgPointOfContactKind =
  (typeof GQOrgPointOfContactKind)[keyof typeof GQOrgPointOfContactKind];
export type GQOrgPost = GQIdInterface & {
  __typename?: 'OrgPost';
  content: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export type GQOrgPostConnection = {
  __typename?: 'OrgPostConnection';
  edges: Array<Maybe<GQOrgPostEdge>>;
  pageInfo: GQPageInfo;
};

export type GQOrgPostEdge = {
  __typename?: 'OrgPostEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQOrgPost>;
};

export type GQOrgProfile = {
  __typename?: 'OrgProfile';
  climateCommitments: Maybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals: Maybe<Array<Scalars['String']['output']>>;
  climateMotivation: Maybe<Scalars['String']['output']>;
  completedAt: Maybe<Scalars['DateTime']['output']>;
  completedBy: Maybe<GQUser>;
  country: Maybe<Scalars['String']['output']>;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  hasClimateCommitments: Maybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed: Maybe<GQYesNoUnknown>;
  id: Scalars['ID']['output'];
  industry: Maybe<Scalars['String']['output']>;
  isCompanyDataConfirmed: Maybe<Scalars['Boolean']['output']>;
  lastNonWatershedMeasurementYearString: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  otherClimateCommitment: Maybe<Scalars['String']['output']>;
  /** @deprecated Use country. Remove in July 2023 */
  primaryOperatingCountry: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<GQUser>;
};

export type GQOrgStructureVersion = {
  __typename?: 'OrgStructureVersion';
  effectiveFromDate: Maybe<Scalars['DateTime']['output']>;
  effectiveToDate: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnitTypes: Array<GQOrgUnitType>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgStructureVersionConnection = {
  __typename?: 'OrgStructureVersionConnection';
  edges: Array<GQOrgStructureVersionEdge>;
  pageInfo: GQPageInfo;
};

export type GQOrgStructureVersionEdge = {
  __typename?: 'OrgStructureVersionEdge';
  cursor: Scalars['String']['output'];
  node: GQOrgStructureVersion;
};

export type GQOrgStructureVersionUpload = {
  __typename?: 'OrgStructureVersionUpload';
  accepted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  diff: Maybe<Scalars['UntypedData']['output']>;
  diffFileMetadataId: Maybe<Scalars['ID']['output']>;
  errorMessages: Scalars['UntypedData']['output'];
  id: Scalars['ID']['output'];
  isInvalid: Maybe<Scalars['Boolean']['output']>;
  orgId: Scalars['ID']['output'];
  orgStructureVersionId: Scalars['ID']['output'];
  originalFileMetadataId: Maybe<Scalars['ID']['output']>;
  rawOutputFileMetadataId: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadStats: Scalars['UntypedData']['output'];
};

export type GQOrgUnit = {
  __typename?: 'OrgUnit';
  description: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgUnitDatasources: Array<GQOrgUnitDatasource>;
  orgUnitTypeId: Scalars['String']['output'];
  relatedOrgUnits: Array<GQOrgUnit>;
  stableId: Scalars['String']['output'];
};

export type GQOrgUnitConnection = {
  __typename?: 'OrgUnitConnection';
  edges: Array<GQOrgUnitEdge>;
  pageInfo: GQPageInfo;
};

export type GQOrgUnitDatasource = {
  __typename?: 'OrgUnitDatasource';
  createdAt: Scalars['DateTime']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  orgUnitStableId: Scalars['String']['output'];
  orgUnitTypeStableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQOrgUnitEdge = {
  __typename?: 'OrgUnitEdge';
  cursor: Scalars['String']['output'];
  node: GQOrgUnit;
};

export type GQOrgUnitRelationship = {
  __typename?: 'OrgUnitRelationship';
  id: Scalars['ID']['output'];
  orgUnitIdFrom: Scalars['ID']['output'];
  orgUnitIdTo: Scalars['ID']['output'];
};

export const GQOrgUnitRelationshipCardinality = {
  ManyToMany: 'ManyToMany',
  OneToMany: 'OneToMany',
  OneToOne: 'OneToOne',
} as const;

export type GQOrgUnitRelationshipCardinality =
  (typeof GQOrgUnitRelationshipCardinality)[keyof typeof GQOrgUnitRelationshipCardinality];
export const GQOrgUnitRelationshipDirection = {
  From: 'from',
  To: 'to',
} as const;

export type GQOrgUnitRelationshipDirection =
  (typeof GQOrgUnitRelationshipDirection)[keyof typeof GQOrgUnitRelationshipDirection];
export type GQOrgUnitType = {
  __typename?: 'OrgUnitType';
  cyclesWhenFromNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  cyclesWhenToNode: Array<Maybe<Array<Scalars['String']['output']>>>;
  enabledDatasets: Array<GQDatasetForOrgUnitType>;
  enabledForAllDatasets: Scalars['Boolean']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isLegalEntity: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  orgUnits: Array<GQOrgUnit>;
  relatedOrgUnitTypes: Array<GQOrgUnitType>;
  relatedOrgUnits: Array<GQOrgUnit>;
  relationship: GQOrgUnitTypeRelationship;
  relationships: Array<GQOrgUnitTypeRelationship>;
  requiredForAllDatasets: Scalars['Boolean']['output'];
  stableId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: GQUser;
};

export type GQOrgUnitTypeCyclesWhenFromNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeCyclesWhenToNodeArgs = {
  baseOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelatedOrgUnitsArgs = {
  relatedOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeRelationshipArgs = {
  relatedOrgUnitTypeId: Scalars['ID']['input'];
};

export type GQOrgUnitTypeConnection = {
  __typename?: 'OrgUnitTypeConnection';
  edges: Array<GQOrgUnitTypeEdge>;
  pageInfo: GQPageInfo;
};

export type GQOrgUnitTypeEdge = {
  __typename?: 'OrgUnitTypeEdge';
  cursor: Scalars['String']['output'];
  node: GQOrgUnitType;
};

export type GQOrgUnitTypeRelationship = {
  __typename?: 'OrgUnitTypeRelationship';
  cardinality: GQOrgUnitRelationshipCardinality;
  defaultOrgUnitId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isRequired: Scalars['Boolean']['output'];
  orgUnitTypeIdFrom: Scalars['ID']['output'];
  orgUnitTypeIdTo: Scalars['ID']['output'];
};

export type GQOrganization = GQIdInterface & {
  __typename?: 'Organization';
  apiKeys: Array<GQApiKey>;
  bigqueryDatasetName: Maybe<Scalars['String']['output']>;
  bigqueryTableSchema: Scalars['String']['output'];
  buildingCompletionIntervalsUntyped: Array<GQBuildingCompletionIntervalsUntyped>;
  buildingNames: Array<Scalars['String']['output']>;
  buildingsUntyped: Array<GQBuildingUntyped>;
  canAccessCorporate: Maybe<Scalars['Boolean']['output']>;
  canAccessFinance: Maybe<Scalars['Boolean']['output']>;
  company: GQCompany;
  cookieTimeoutDays: Maybe<Scalars['Int']['output']>;
  country: Maybe<Scalars['String']['output']>;
  csrdDataRequirementsConfigs: Array<GQCsrdDataRequirementsConfig>;
  currency: Maybe<Scalars['String']['output']>;
  dataIssues: Array<GQDataIssue>;
  datasets: Array<GQDataset>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  demoOrg: Scalars['Boolean']['output'];
  domains: Array<Scalars['String']['output']>;
  engagementFunnels: Array<GQEngagementFunnel>;
  facilitiesMetadata: GQFacilitiesMetadata;
  fiscalYearStartMonth: Maybe<Scalars['Int']['output']>;
  footprintCategoryRules: Array<GQFootprintCategoryRule>;
  footprintExclusionRules: Array<GQFootprintExclusionRule>;
  footprintExclusionSuggestions: Array<GQFootprintExclusionSuggestion>;
  hasActiveWorkosConnection: Scalars['Boolean']['output'];
  hasAnyPv2PipelineResults: Scalars['Boolean']['output'];
  hasUsers: Scalars['Boolean']['output'];
  helpChannel: Maybe<Scalars['String']['output']>;
  iconRemotePath: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ingestionCustomFields: Array<GQIngestionCustomField>;
  isWatershed: Scalars['Boolean']['output'];
  legalName: Maybe<Scalars['String']['output']>;
  lifecycle: Maybe<GQClimateProgramLifecycle>;
  logoRemotePath: Maybe<Scalars['String']['output']>;
  magicLinkDisabled: Scalars['Boolean']['output'];
  measurementProjects: Array<GQMeasurementProject>;
  name: Scalars['String']['output'];
  notionUrl: Maybe<Scalars['String']['output']>;
  orgAccess: Maybe<GQOrgAccess>;
  orgCompanies: Array<GQOrgCompany>;
  passwordAuthDisabled: Scalars['Boolean']['output'];
  pointsOfContact: Array<GQOrgPointOfContact>;
  pointsOfContactIncludingDeleted: Array<GQOrgPointOfContact>;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  profile: GQOrgProfile;
  region: GQRegion;
  roles: Maybe<GQRoleConnection>;
  sbtiTargetExclusions: Array<GQSbtiTargetExclusion>;
  sessionTimeoutMinutes: Maybe<Scalars['Int']['output']>;
  sharedFileRequests: GQSharedFileRequestConnection;
  sharedFiles: GQSharedFileConnection;
  shouldShowOnboarding: Scalars['Boolean']['output'];
  stagingOrg: Maybe<Scalars['Boolean']['output']>;
  targets: GQTargetConnection;
  testOrg: Scalars['Boolean']['output'];
  users: Maybe<GQUserConnection>;
  watershedPlan: GQWatershedPlan;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedSlackChannelId: Maybe<Scalars['String']['output']>;
  workosConnections: Array<GQWorkosConnection>;
  workosDirectories: Array<GQWorkosDirectory>;
  workosOrgId: Maybe<Scalars['String']['output']>;
};

export type GQOrganizationCsrdDataRequirementsConfigsArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQOrganizationFootprintCategoryRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionRulesArgs = {
  footprintSnapshotId: InputMaybe<Scalars['ID']['input']>;
};

export type GQOrganizationFootprintExclusionSuggestionsArgs = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQOrganizationMeasurementProjectsArgs = {
  active: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQOrganizationPointsOfContactArgs = {
  kinds: InputMaybe<Array<GQOrgPointOfContactKind>>;
};

export type GQOrganizationRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFileRequestsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationSharedFilesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationTargetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQOrganizationUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<GQUserFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export const GQOrganizationState = {
  Active: 'ACTIVE',
  Onboarding: 'ONBOARDING',
} as const;

export type GQOrganizationState =
  (typeof GQOrganizationState)[keyof typeof GQOrganizationState];
export type GQOverrideContainerProgressInput = {
  containerId: Scalars['ID']['input'];
  progress?: InputMaybe<GQResolvedContainerProgress>;
  reportId: Scalars['ID']['input'];
};

/** The Relay compliant 'PageInfo' type, containing data necessary to paginate this connection. */
export type GQPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
  /**
   * Only some connections will provide a total row count, because it can be
   * expensive to calculate.
   */
  totalRowCount: Maybe<Scalars['Int']['output']>;
};

export type GQParameterResolver = GQIdInterface & {
  __typename?: 'ParameterResolver';
  authorId: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type GQParentRelationship = {
  __typename?: 'ParentRelationship';
  id: Scalars['ID']['output'];
  parent: GQCompany;
  parentId: Scalars['ID']['output'];
  relationshipEndDate: Maybe<Scalars['YearMonth']['output']>;
  relationshipStartDate: Maybe<Scalars['YearMonth']['output']>;
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQParquetColumn = {
  __typename?: 'ParquetColumn';
  approxDistinctValues: Scalars['Int']['output'];
  maxValue: Scalars['JSONString']['output'];
  minValue: Scalars['JSONString']['output'];
  name: Scalars['String']['output'];
  numNulls: Scalars['Int']['output'];
  sampleValues: Array<Scalars['JSONString']['output']>;
  sqlType: Scalars['String']['output'];
};

export type GQParquetData = GQIdInterface & {
  __typename?: 'ParquetData';
  columns: Array<GQParquetColumn>;
  id: Scalars['ID']['output'];
  ndjsonByteBoundaries: Maybe<Array<Scalars['Int53']['output']>>;
  ndjsonSignedUrl: Maybe<Scalars['String']['output']>;
  ndjsonSize: Maybe<Scalars['Int53']['output']>;
  numRecords: Scalars['Int']['output'];
};

export type GQParquetDataToCsvInput = {
  parquetDataId: Scalars['ID']['input'];
};

export type GQParquetDataToCsvPayload = {
  __typename?: 'ParquetDataToCsvPayload';
  jobId: Scalars['String']['output'];
};

export type GQPeerCompanyGroup = {
  __typename?: 'PeerCompanyGroup';
  createdAt: Scalars['DateTime']['output'];
  footprintKinds: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  members: Array<GQPeerCompanyGroupMember>;
  name: Scalars['String']['output'];
  revenueForRevenueAtRisk: Maybe<Scalars['Int53']['output']>;
  selfBenchmarks: Array<GQBenchmark>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupSelfBenchmarksArgs = {
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQPeerCompanyGroupMember = {
  __typename?: 'PeerCompanyGroupMember';
  company: GQCompany;
  id: Scalars['ID']['output'];
  mostRecentHighQualityBenchmark: Maybe<GQBenchmark>;
  revenueAtRisk: Maybe<Scalars['Int53']['output']>;
  shouldHideFromFootprintOverview: Scalars['Boolean']['output'];
};

export type GQPeerCompanyGroupPayload = {
  __typename?: 'PeerCompanyGroupPayload';
  id: Scalars['ID']['output'];
  peerCompanyGroup: GQPeerCompanyGroup;
};

export type GQPeerExternalReportAnswers = {
  __typename?: 'PeerExternalReportAnswers';
  answers: Array<GQExternalReportAnswer>;
  peerCompanies: Array<GQCompany>;
  questions: Array<GQExternalReportQuestion>;
};

export type GQPeerExternalReportAnswersForIdentifiersPayload = {
  __typename?: 'PeerExternalReportAnswersForIdentifiersPayload';
  answers: Array<GQExternalReportAnswer>;
  peerCompanies: Array<GQCompany>;
  questions: Array<GQExternalReportQuestion>;
};

export const GQPermission = {
  Measurement: 'Measurement',
  MeasurementAdmin: 'MeasurementAdmin',
  Viewer: 'Viewer',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermission = (typeof GQPermission)[keyof typeof GQPermission];
export type GQPermissionConnection = {
  __typename?: 'PermissionConnection';
  edges: Array<Maybe<GQPermissionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPermissionEdge = {
  __typename?: 'PermissionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPermissionItem>;
};

export type GQPermissionItem = {
  __typename?: 'PermissionItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  object: Maybe<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
  objectId: Maybe<Scalars['ID']['output']>;
  org: GQOrganization;
  permission: GQPermissionType;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: Maybe<GQRole>;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQPermissionItemInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  objectType?: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
  revokedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GQPermissionObject = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQPermissionObjectConnection = {
  __typename?: 'PermissionObjectConnection';
  edges: Array<Maybe<GQPermissionObjectEdge>>;
  pageInfo: GQPageInfo;
};

export type GQPermissionObjectEdge = {
  __typename?: 'PermissionObjectEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
};

export const GQPermissionObjectType = {
  Dataset: 'Dataset',
  Datasource: 'Datasource',
  Footprint: 'Footprint',
  Fund: 'Fund',
  Report: 'Report',
  ReportQuestionInstance: 'ReportQuestionInstance',
} as const;

export type GQPermissionObjectType =
  (typeof GQPermissionObjectType)[keyof typeof GQPermissionObjectType];
export type GQPermissionObjectWithChildren = {
  children: Array<
    | GQDataset
    | GQDatasource
    | GQFootprint
    | GQFund
    | GQReport
    | GQReportQuestionInstance
  >;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQPermissionObjectWithChildrenChildrenArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export const GQPermissionType = {
  Admin: 'Admin',
  AnyUser: 'AnyUser',
  ApproveDataset: 'ApproveDataset',
  ApproveDatasource: 'ApproveDatasource',
  ApproveFootprint: 'ApproveFootprint',
  CorporateAdmin: 'CorporateAdmin',
  EditReport: 'EditReport',
  EditReportQuestionInstance: 'EditReportQuestionInstance',
  FinanceAdmin: 'FinanceAdmin',
  FinanceReadOnly: 'FinanceReadOnly',
  ManageCompanyTags: 'ManageCompanyTags',
  ManageDataset: 'ManageDataset',
  ManageDatasource: 'ManageDatasource',
  ManageDisclosures: 'ManageDisclosures',
  ManageFund: 'ManageFund',
  ManageMarketplacePurchases: 'ManageMarketplacePurchases',
  ManageMeasurement: 'ManageMeasurement',
  ManageOrgHierarchy: 'ManageOrgHierarchy',
  ManageReductionPlans: 'ManageReductionPlans',
  ManageSingleSignOn: 'ManageSingleSignOn',
  ManageSuppliers: 'ManageSuppliers',
  ViewAuditDetail: 'ViewAuditDetail',
  ViewEmployeeReport: 'ViewEmployeeReport',
  ViewFootprint: 'ViewFootprint',
  ViewFootprintDetail: 'ViewFootprintDetail',
  ViewLearningHub: 'ViewLearningHub',
  ViewReductions: 'ViewReductions',
  ViewReport: 'ViewReport',
  ViewReportQuestionInstance: 'ViewReportQuestionInstance',
  WatershedAdmin: 'WatershedAdmin',
} as const;

export type GQPermissionType =
  (typeof GQPermissionType)[keyof typeof GQPermissionType];
export type GQPerson = {
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
  isWatershed: Scalars['Boolean']['output'];
  isWatershedContractor: Scalars['Boolean']['output'];
  isWatershedEmployee: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type GQPipelineConfig = {
  __typename?: 'PipelineConfig';
  coverageInterval: Scalars['YMInterval']['output'];
  pipelineResultId: Scalars['ID']['output'];
};

export type GQPipelineOutputSummary = {
  __typename?: 'PipelineOutputSummary';
  emissions: Array<GQPipelineOutputSummaryEmission>;
  footprintQuantityMeta: Maybe<GQFootprintQuantityKindMetadata>;
  format: Scalars['Int']['output'];
  warnings: Maybe<Array<GQValidationWarning>>;
};

export type GQPipelineOutputSummaryEmission = {
  __typename?: 'PipelineOutputSummaryEmission';
  adtName: Maybe<Scalars['String']['output']>;
  businessCategory: Maybe<Scalars['String']['output']>;
  footprintKind: Scalars['String']['output'];
  kgCo2e: Scalars['Float']['output'];
  month: Scalars['Date']['output'];
};

export type GQPlan = GQIdInterface & {
  __typename?: 'Plan';
  baselineYearStart: Scalars['YearMonth']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  fiscalMonth: Scalars['Int']['output'];
  forecast: GQForecast;
  hideCarbonAvoidance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  isSbtValidated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reduxCarbonOffsets: GQSimpleTimeseries;
  reduxCarbonOffsetsCosts: GQSimpleTimeseries;
  reduxCarbonRemoval: GQSimpleTimeseries;
  reduxCarbonRemovalCosts: GQSimpleTimeseries;
  reduxInitiatives: Array<
    GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative
  >;
  relevantFootprintFields: Array<Scalars['String']['output']>;
  state: GQPlanState;
  targets: Array<GQPlanTarget>;
  updatedAt: Scalars['DateTime']['output'];
  updatedByName: Maybe<Scalars['String']['output']>;
  variables: GQPlanVariables;
};

export type GQPlanTargetsArgs = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQPlanCarbonFund = {
  __typename?: 'PlanCarbonFund';
  highQualityOffset: Scalars['Float']['output'];
  natureRemoval: Scalars['Float']['output'];
  offset: Scalars['Float']['output'];
  rndRemoval: Scalars['Float']['output'];
};

export type GQPlanCarbonFundInput = {
  highQualityOffset: Scalars['Float']['input'];
  natureRemoval: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
  rndRemoval: Scalars['Float']['input'];
};

export type GQPlanConnection = {
  __typename?: 'PlanConnection';
  edges: Array<Maybe<GQPlanEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Plan' and its cursor. */
export type GQPlanEdge = {
  __typename?: 'PlanEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPlan>;
};

export const GQPlanState = {
  Archived: 'Archived',
  Committed: 'Committed',
  Draft: 'Draft',
} as const;

export type GQPlanState = (typeof GQPlanState)[keyof typeof GQPlanState];
export type GQPlanTarget = GQIdInterface & {
  __typename?: 'PlanTarget';
  assignedTo: Maybe<Scalars['String']['output']>;
  baseYear: Scalars['YearMonth']['output'];
  comparisonType: GQPlanTargetTargetComparisonType;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  customIntensityConfigId: Maybe<Scalars['ID']['output']>;
  customNotes: Scalars['String']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  emissionsTargetCustom: GQSimpleTimeseries;
  filters: GQFilterExpressionGroup;
  id: Scalars['ID']['output'];
  intensityType: GQPlanTargetIntensityType;
  interimTargetDate: Maybe<Scalars['YearMonth']['output']>;
  interimTargetValue: Maybe<Scalars['Float']['output']>;
  parentTarget: Maybe<GQPlanTarget>;
  parentTargetId: Maybe<Scalars['String']['output']>;
  reductionRate: GQTargetReductionRate;
  reportDescription: Scalars['String']['output'];
  subtargets: Array<GQPlanTarget>;
};

export const GQPlanTargetIntensityType = {
  Absolute: 'Absolute',
  Custom: 'Custom',
  GrossProfit: 'GrossProfit',
  Headcount: 'Headcount',
  NightsStayed: 'NightsStayed',
  Orders: 'Orders',
  Patients: 'Patients',
  RenewableElectricity: 'RenewableElectricity',
  Revenue: 'Revenue',
  SupplierEngagement: 'SupplierEngagement',
  SupplierEngagementBySpend: 'SupplierEngagementBySpend',
} as const;

export type GQPlanTargetIntensityType =
  (typeof GQPlanTargetIntensityType)[keyof typeof GQPlanTargetIntensityType];
export const GQPlanTargetTargetComparisonType = {
  BaseYear: 'BaseYear',
  BusinessAsUsual: 'BusinessAsUsual',
} as const;

export type GQPlanTargetTargetComparisonType =
  (typeof GQPlanTargetTargetComparisonType)[keyof typeof GQPlanTargetTargetComparisonType];
export type GQPlanVariables = {
  __typename?: 'PlanVariables';
  cleanPowerStart: Scalars['YearMonth']['output'];
  commitmentsHasNetZero: Scalars['Boolean']['output'];
  commitmentsHasSBT: Scalars['Boolean']['output'];
  commitmentsNetZeroYear: Scalars['Int']['output'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['output'];
  commitmentsSBTTargetYear: Scalars['Int']['output'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['output'];
  netZeroSuppliersReductionPercent: Scalars['Float']['output'];
  percentWorkFromHomeForecastEnd: Scalars['Float']['output'];
};

export type GQPlanVariablesInput = {
  cleanPowerStart: Scalars['YearMonth']['input'];
  commitmentsHasNetZero: Scalars['Boolean']['input'];
  commitmentsHasSBT: Scalars['Boolean']['input'];
  commitmentsNetZeroYear: Scalars['Int']['input'];
  commitmentsSBTScope3: GQSbtScope3Target;
  commitmentsSBTScope12: GQSbtScope1And2Target;
  commitmentsSBTSubmissionDate: Scalars['YearMonth']['input'];
  commitmentsSBTTargetYear: Scalars['Int']['input'];
  commitmentsSBTTermLength: GQSbtTermLength;
  flightsPerEmployeeForecastEnd: Scalars['Float']['input'];
  netZeroSuppliersReductionPercent?: InputMaybe<Scalars['Float']['input']>;
  percentWorkFromHomeForecastEnd: Scalars['Float']['input'];
};

export type GQPortcoFootprintReviewRequestMetadata = {
  __typename?: 'PortcoFootprintReviewRequestMetadata';
  footprintSnapshotId: Scalars['ID']['output'];
  footprintVersionKind: GQFootprintSnapshotStatus;
  id: Scalars['ID']['output'];
  status: GQWatershedFootprintReviewRequestStatus;
};

export type GQPrecomputedActivityBasedInitiative = GQIdInterface &
  GQInitiative & {
    __typename?: 'PrecomputedActivityBasedInitiative';
    costTimeseries: GQSimpleTimeseries;
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    description: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    filters: GQFilterExpressionGroupWithNewFilters;
    id: Scalars['ID']['output'];
    implementationTimeseries: GQSimpleTimeseries;
    lever: GQPrecomputedLever;
    name: Scalars['String']['output'];
    plan: GQPlan;
    scheduleType: GQScheduleType;
  };

export const GQPrecomputedLever = {
  ChangeCareLabelToAirDry: 'ChangeCareLabelToAirDry',
  ChangeCareLabelToWashCold: 'ChangeCareLabelToWashCold',
  ElectrifyGasStovesInOffices: 'ElectrifyGasStovesInOffices',
  EliminateEmployeeWfhElectricityEmissions:
    'EliminateEmployeeWfhElectricityEmissions',
  EnergyEfficiencyImprovementsHeatPumps:
    'EnergyEfficiencyImprovementsHeatPumps',
  GreenRideShareOptions: 'GreenRideShareOptions',
  GroundTransportInsteadOfAir: 'GroundTransportInsteadOfAir',
  ImplementGreenCodingPractices: 'ImplementGreenCodingPractices',
  ImproveElectricityEfficiencyOfficeBuildings:
    'ImproveElectricityEfficiencyOfficeBuildings',
  LocalizeSupply: 'LocalizeSupply',
  OptimizeCloudComputeType: 'OptimizeCloudComputeType',
  OptimizeCloudHostingLocation: 'OptimizeCloudHostingLocation',
  ProductUsersAdoptMoreEfficientWasherDryers:
    'ProductUsersAdoptMoreEfficientWasherDryers',
  PurchaseReCsOrEaCs: 'PurchaseRECsOrEACs',
  ReduceEmissionsFromElectricityProduction:
    'ReduceEmissionsFromElectricityProduction',
  ReduceRefrigerantLeakageRate: 'ReduceRefrigerantLeakageRate',
  ReduceTotalTravelMiles: 'ReduceTotalTravelMiles',
  ReplaceConventionalJetFuelWithSustainableAviationFuel:
    'ReplaceConventionalJetFuelWithSustainableAviationFuel',
  RequirePassengerTrainInsteadOfShortHaulFlight:
    'RequirePassengerTrainInsteadOfShortHaulFlight',
  SwapAirFreightToRailFreight: 'SwapAirFreightToRailFreight',
  SwapAirFreightToSeaFreight: 'SwapAirFreightToSeaFreight',
  SwapEmployeeCommuteFromIceVehicleToElectricVehicle:
    'SwapEmployeeCommuteFromIceVehicleToElectricVehicle',
  SwapEmployeeCommuteFromIceVehicleToPublicTransport:
    'SwapEmployeeCommuteFromIceVehicleToPublicTransport',
  SwapFlightClassFromBusinessToEconomy: 'SwapFlightClassFromBusinessToEconomy',
  SwapFlightClassFromFirstToEconomy: 'SwapFlightClassFromFirstToEconomy',
  SwapGroundTransportToRailFreight: 'SwapGroundTransportToRailFreight',
  SwapProductsSentToLandfillToRecycled: 'SwapProductsSentToLandfillToRecycled',
  SwapToLowerGwpRefrigerants: 'SwapToLowerGWPRefrigerants',
  SwapWasteFromLandfillToCompost: 'SwapWasteFromLandfillToCompost',
  SwapWasteFromLandfillToRecycle: 'SwapWasteFromLandfillToRecycle',
  SwitchNaturalGasToHeatPumps: 'SwitchNaturalGasToHeatPumps',
  SwitchToLowEmissionsCloudProvider: 'SwitchToLowEmissionsCloudProvider',
} as const;

export type GQPrecomputedLever =
  (typeof GQPrecomputedLever)[keyof typeof GQPrecomputedLever];
export type GQPrepareActivityRecordExportInput = {
  stableId: Scalars['ID']['input'];
};

export type GQPrepareActivityRecordExportPayload = {
  __typename?: 'PrepareActivityRecordExportPayload';
  backgroundJobId: Scalars['ID']['output'];
};

export type GQPrepareFootprintGenerationParamsInput = {
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
  useOnlyApprovedData: Scalars['Boolean']['input'];
};

export type GQPrepareFootprintGenerationParamsPayload = {
  __typename?: 'PrepareFootprintGenerationParamsPayload';
  workflowId: Scalars['ID']['output'];
};

export type GQPresetCustomTag = {
  __typename?: 'PresetCustomTag';
  ingestionCustomField: GQIngestionCustomField;
  ingestionCustomFieldDatasource: GQIngestionCustomFieldDatasource;
};

export type GQPresetOrgUnit = {
  __typename?: 'PresetOrgUnit';
  orgUnit: GQOrgUnit;
  orgUnitDatasource: GQOrgUnitDatasource;
};

export type GQPreviewUtilitiesDiffInput = {
  ctsVersionId: Scalars['String']['input'];
  embedId: Scalars['String']['input'];
  fileMetadataId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  userUploadTaskId: Scalars['String']['input'];
};

export type GQPrivateDisclosure = {
  __typename?: 'PrivateDisclosure';
  assetCorporateId: Maybe<Scalars['ID']['output']>;
  assetPersonalMotorVehicleInsuranceId: Maybe<Scalars['ID']['output']>;
  cdpVendorData: Maybe<GQCdpVendorData>;
  companyId: Maybe<Scalars['ID']['output']>;
  companyIngestionRecordUpdateId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  disclosureQualityExplanation: Maybe<Scalars['String']['output']>;
  disclosureQualityScore: Maybe<GQDisclosureQualityScore>;
  engagementTaskId: Maybe<Scalars['ID']['output']>;
  footprintDisclosureId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
  orgName: Scalars['String']['output'];
  privateDisclosureType: GQPrivateDisclosureType;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  sourceId: Scalars['ID']['output'];
  surveyId: Maybe<Scalars['ID']['output']>;
  thirdPartyVerified: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userEditorId: Scalars['ID']['output'];
  watershedEditorId: Maybe<Scalars['ID']['output']>;
};

export type GQPrivateDisclosureInput = {
  orgId?: InputMaybe<Scalars['ID']['input']>;
};

export const GQPrivateDisclosureType = {
  Cdp: 'CDP',
  Estimate: 'Estimate',
  ManualEntry: 'ManualEntry',
  Survey: 'Survey',
  SurveyEstimate: 'SurveyEstimate',
  WatershedMeasurement: 'WatershedMeasurement',
} as const;

export type GQPrivateDisclosureType =
  (typeof GQPrivateDisclosureType)[keyof typeof GQPrivateDisclosureType];
export type GQProductFootprintRowReference = {
  __typename?: 'ProductFootprintRowReference';
  id: Scalars['String']['output'];
  product: Scalars['String']['output'];
  totalEmissions: Scalars['Float']['output'];
};

export type GQProductFootprintRowUpdatedTaxonomy = {
  __typename?: 'ProductFootprintRowUpdatedTaxonomy';
  categoryEmissionsPercent: Maybe<Scalars['Float']['output']>;
  emissionsPerUnit: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  product: Scalars['String']['output'];
  relatedMaterials: Array<GQMaterialFootprintRowReference>;
  totalEmissions: Scalars['Float']['output'];
  totalEmissionsPercent: Maybe<Scalars['Float']['output']>;
  unitsProduced: Scalars['Float']['output'];
  unitsProducedPercentage: Maybe<Scalars['Float']['output']>;
  vectors: Maybe<Array<GQVectorNameAndValues>>;
};

export const GQProductWaitlistType = {
  Ppa: 'Ppa',
  SupplyChain: 'SupplyChain',
  Vppa: 'Vppa',
} as const;

export type GQProductWaitlistType =
  (typeof GQProductWaitlistType)[keyof typeof GQProductWaitlistType];
export type GQProductionGraphData = {
  __typename?: 'ProductionGraphData';
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastVersionId: Maybe<Scalars['ID']['output']>;
  lifecycleAssessmentId: Scalars['ID']['output'];
  nodes: Array<GQProductionGraphNode>;
};

export type GQProductionGraphNode = {
  __typename?: 'ProductionGraphNode';
  airDistance: Maybe<Scalars['Float']['output']>;
  airDistanceUnit: Maybe<Scalars['String']['output']>;
  assumptions: Maybe<Scalars['String']['output']>;
  autobomTier: Maybe<Scalars['Float']['output']>;
  biogenicEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  conversionFactorCitation: Maybe<Scalars['String']['output']>;
  conversionFactorList: Maybe<Scalars['String']['output']>;
  conversionFactorUnit: Maybe<Scalars['String']['output']>;
  conversionFactorValue: Maybe<Scalars['Float']['output']>;
  countryCodes: Maybe<Scalars['String']['output']>;
  cumulativeBiogenicEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  cumulativeEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  cumulativeFlagEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  downstreamNodeIdentifier: Maybe<Scalars['String']['output']>;
  ecoinventActivitiesRaw: Maybe<Scalars['String']['output']>;
  ecoinventActivity: Maybe<Scalars['String']['output']>;
  ecoinventCpcClassification: Maybe<Scalars['String']['output']>;
  ecoinventEmissionsFactor: Maybe<Scalars['Float']['output']>;
  ecoinventGeography: Maybe<Scalars['String']['output']>;
  ecoinventHsCodeClassification: Maybe<Scalars['String']['output']>;
  ecoinventIsicClassification: Maybe<Scalars['String']['output']>;
  ecoinventReferenceProductName: Maybe<Scalars['String']['output']>;
  ecoinventUnit: Maybe<Scalars['String']['output']>;
  edits: Maybe<Scalars['String']['output']>;
  emissionsKgco2e: Maybe<Scalars['Float']['output']>;
  facilityAssumptions: Maybe<Scalars['String']['output']>;
  facilityCompany: Maybe<Scalars['String']['output']>;
  facilityDescription: Maybe<Scalars['String']['output']>;
  facilityLocation: Maybe<Scalars['String']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  flagEmissionsKgco2e: Maybe<Scalars['Float']['output']>;
  identifier: Scalars['String']['output'];
  inputRates: Array<GQProductionGraphNodeInputRate>;
  lifecycleStage: GQLifecycleStage;
  mappedEcoinventActivity: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nodeType: GQProductionGraphNodeType;
  outputAmount: Scalars['Float']['output'];
  outputAmountUnit: Scalars['String']['output'];
  outputMaterialName: Maybe<Scalars['String']['output']>;
  railDistance: Maybe<Scalars['Float']['output']>;
  railDistanceUnit: Maybe<Scalars['String']['output']>;
  rawProcessInputsResponse: Maybe<Scalars['String']['output']>;
  roadDistance: Maybe<Scalars['Float']['output']>;
  roadDistanceUnit: Maybe<Scalars['String']['output']>;
  supplierName: Maybe<Scalars['String']['output']>;
  tags: Maybe<Array<Scalars['String']['output']>>;
  unitConversionResponse: Maybe<Scalars['String']['output']>;
  waterDistance: Maybe<Scalars['Float']['output']>;
  waterDistanceUnit: Maybe<Scalars['String']['output']>;
};

export type GQProductionGraphNodeEdit = {
  data?: InputMaybe<GQProductionGraphNodeEditDataInput>;
  nodeId: Scalars['String']['input'];
  type: GQProductionGraphNodeEditType;
};

export type GQProductionGraphNodeEditDataInput = {
  airDistance?: InputMaybe<Scalars['Float']['input']>;
  airDistanceUnit?: InputMaybe<Scalars['String']['input']>;
  assumptions?: InputMaybe<Scalars['String']['input']>;
  autobomTier?: InputMaybe<Scalars['Float']['input']>;
  biogenicEmissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  conversionFactorCitation?: InputMaybe<Scalars['String']['input']>;
  conversionFactorList?: InputMaybe<Scalars['String']['input']>;
  conversionFactorUnit?: InputMaybe<Scalars['String']['input']>;
  conversionFactorValue?: InputMaybe<Scalars['Float']['input']>;
  countryCodes?: InputMaybe<Scalars['String']['input']>;
  cumulativeBiogenicEmissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  cumulativeEmissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  cumulativeFlagEmissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  downstreamNodeIdentifier?: InputMaybe<Scalars['String']['input']>;
  ecoinventActivitiesRaw?: InputMaybe<Scalars['String']['input']>;
  ecoinventActivity?: InputMaybe<Scalars['String']['input']>;
  ecoinventCpcClassification?: InputMaybe<Scalars['String']['input']>;
  ecoinventEmissionsFactor?: InputMaybe<Scalars['Float']['input']>;
  ecoinventGeography?: InputMaybe<Scalars['String']['input']>;
  ecoinventHsCodeClassification?: InputMaybe<Scalars['String']['input']>;
  ecoinventIsicClassification?: InputMaybe<Scalars['String']['input']>;
  ecoinventReferenceProductName?: InputMaybe<Scalars['String']['input']>;
  ecoinventUnit?: InputMaybe<Scalars['String']['input']>;
  edits?: InputMaybe<Scalars['String']['input']>;
  emissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  facilityAssumptions?: InputMaybe<Scalars['String']['input']>;
  facilityCompany?: InputMaybe<Scalars['String']['input']>;
  facilityDescription?: InputMaybe<Scalars['String']['input']>;
  facilityLocation?: InputMaybe<Scalars['String']['input']>;
  facilityName?: InputMaybe<Scalars['String']['input']>;
  fieldsToNull: Array<Scalars['String']['input']>;
  flagEmissionsKgco2e?: InputMaybe<Scalars['Float']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  inputRates?: InputMaybe<Array<GQProductionGraphNodeInputRateInput>>;
  lifecycleStage?: InputMaybe<GQLifecycleStage>;
  mappedEcoinventActivity?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeType?: InputMaybe<GQProductionGraphNodeType>;
  outputAmount?: InputMaybe<Scalars['Float']['input']>;
  outputAmountUnit?: InputMaybe<Scalars['String']['input']>;
  outputMaterialName?: InputMaybe<Scalars['String']['input']>;
  processAmount?: InputMaybe<Scalars['Float']['input']>;
  processAssumptions?: InputMaybe<Scalars['String']['input']>;
  processEmissions?: InputMaybe<Scalars['Float']['input']>;
  processInputs?: InputMaybe<Scalars['String']['input']>;
  processUnit?: InputMaybe<Scalars['String']['input']>;
  railDistance?: InputMaybe<Scalars['Float']['input']>;
  railDistanceUnit?: InputMaybe<Scalars['String']['input']>;
  rawProcessInputsResponse?: InputMaybe<Scalars['String']['input']>;
  roadDistance?: InputMaybe<Scalars['Float']['input']>;
  roadDistanceUnit?: InputMaybe<Scalars['String']['input']>;
  supplierName?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  unitConversionResponse?: InputMaybe<Scalars['String']['input']>;
  waterDistance?: InputMaybe<Scalars['Float']['input']>;
  waterDistanceUnit?: InputMaybe<Scalars['String']['input']>;
};

export const GQProductionGraphNodeEditType = {
  AddNode: 'AddNode',
  DeleteNode: 'DeleteNode',
  EditNode: 'EditNode',
} as const;

export type GQProductionGraphNodeEditType =
  (typeof GQProductionGraphNodeEditType)[keyof typeof GQProductionGraphNodeEditType];
export type GQProductionGraphNodeInputRate = {
  __typename?: 'ProductionGraphNodeInputRate';
  childNodeIdentifier: Scalars['String']['output'];
  rate: Scalars['Float']['output'];
  unitChildPerUnit: Scalars['String']['output'];
};

export type GQProductionGraphNodeInputRateInput = {
  childNodeIdentifier: Scalars['String']['input'];
  rate: Scalars['Float']['input'];
  unitChildPerUnit: Scalars['String']['input'];
};

export const GQProductionGraphNodeType = {
  Material: 'material',
  ProcessInput: 'process_input',
  Processing: 'processing',
  Transportation: 'transportation',
} as const;

export type GQProductionGraphNodeType =
  (typeof GQProductionGraphNodeType)[keyof typeof GQProductionGraphNodeType];
export type GQProductionGraphProcess = {
  __typename?: 'ProductionGraphProcess';
  geography: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  processName: Scalars['String']['output'];
  referenceProductDescription: Maybe<Scalars['String']['output']>;
  referenceProductName: Maybe<Scalars['String']['output']>;
};

export type GQProgramSubstatusData = {
  __typename?: 'ProgramSubstatusData';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  statusLabel: Scalars['String']['output'];
};

export type GQPruneOrphanedQuestionsForConfigPayload = {
  __typename?: 'PruneOrphanedQuestionsForConfigPayload';
  deleteCount: Scalars['Float']['output'];
};

export type GQPublicDisclosure = {
  __typename?: 'PublicDisclosure';
  cdpVendorData: Maybe<GQCdpVendorData>;
  company: Maybe<GQCompany>;
  companyId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  externalScore: Maybe<Scalars['String']['output']>;
  gsUrl: Maybe<Scalars['String']['output']>;
  historicalEmissionsYearsDetails: Array<GQEmissionsYear>;
  id: Scalars['ID']['output'];
  publicUrl: Scalars['String']['output'];
  publishingYear: Scalars['Int']['output'];
  qualityScore: Maybe<GQDisclosureQualityScore>;
  recordEndAt: Maybe<Scalars['DateTime']['output']>;
  recordStartAt: Scalars['DateTime']['output'];
  reportType: GQExternalReportType;
  reportingYears: Array<Scalars['Int']['output']>;
  sectionUrls: Maybe<Array<GQExternalReportSectionUrl>>;
  source: GQPublicDisclosureSource;
  sourceRecordId: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQPublicDisclosureConnection = {
  __typename?: 'PublicDisclosureConnection';
  edges: Array<GQPublicDisclosureEdge>;
  pageInfo: GQPageInfo;
};

export type GQPublicDisclosureEdge = {
  __typename?: 'PublicDisclosureEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQPublicDisclosure>;
};

export type GQPublicDisclosureInput = {
  excludeCdp?: InputMaybe<Scalars['Boolean']['input']>;
  mustHaveUrl?: InputMaybe<Scalars['Boolean']['input']>;
};

export const GQPublicDisclosureSource = {
  Licensed: 'Licensed',
  Scraped: 'Scraped',
} as const;

export type GQPublicDisclosureSource =
  (typeof GQPublicDisclosureSource)[keyof typeof GQPublicDisclosureSource];
export type GQPublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQPublishCtsVersionPayload = {
  __typename?: 'PublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQPublishCustomTransformForUserUploadInput = {
  ctsTransformId: Scalars['String']['input'];
  userUploadId: Scalars['String']['input'];
  userUploadTaskId: Scalars['String']['input'];
};

export type GQPublishCustomTransformForUserUploadPayload = {
  __typename?: 'PublishCustomTransformForUserUploadPayload';
  jobId: Scalars['ID']['output'];
};

export type GQPublishDraftFootprintInput = {
  description: Scalars['String']['input'];
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQPublishDraftFootprintPayload = {
  __typename?: 'PublishDraftFootprintPayload';
  footprintSnapshot: Maybe<GQFootprintSnapshot>;
};

export type GQPublishOrgStructureVersionInput = {
  effectiveFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  effectiveToDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
};

export type GQPublishOrgStructureVersionPayload = {
  __typename?: 'PublishOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQPublishValueMappingArtifactInput = {
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQPublishValueMappingArtifactPayload = {
  __typename?: 'PublishValueMappingArtifactPayload';
  publishedArtifactRevisionId: Scalars['ID']['output'];
};

export type GQPushBartBuildingInput = {
  activityDataExtractorId: Scalars['ID']['input'];
  mergeStrategy?: InputMaybe<GQBuildingsMergeStrategy>;
  userUploadId: Scalars['ID']['input'];
};

export type GQPushCtsVersionToOneSchemaInProductInput = {
  buildingId?: InputMaybe<Scalars['ID']['input']>;
  ctsVersionId: Scalars['ID']['input'];
  datasetId: Scalars['ID']['input'];
  datasourceId?: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQPushCtsVersionToOneSchemaInProductPayload = {
  __typename?: 'PushCtsVersionToOneSchemaInProductPayload';
  oneSchemaTemplateKey: Scalars['String']['output'];
};

export type GQPushStagingReportConfigToProdInput = {
  isTesting: Scalars['Boolean']['input'];
  prodConfigId: Scalars['ID']['input'];
  stagingConfigId: Scalars['ID']['input'];
};

export type GQPushStagingReportConfigToProdPayload = {
  __typename?: 'PushStagingReportConfigToProdPayload';
  prodConfig: GQReportConfig;
};

export type GQPushUtilitiesDiffInput = {
  ctsVersionId: Scalars['String']['input'];
  embedId?: InputMaybe<Scalars['String']['input']>;
  fileMetadataId: Scalars['String']['input'];
  fileName?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['String']['input'];
};

export type GQQuantityTrendAnomaly = {
  __typename?: 'QuantityTrendAnomaly';
  highlightMonth: Scalars['YearMonth']['output'];
};

export type GQQuery = {
  __typename?: 'Query';
  activeMeasurementProjects: Array<GQMeasurementProject>;
  /** @deprecated Use measurementProjectsRelevantToViewer instead. Remove after Jan 30, 2025 */
  activeMeasurementProjectsWithVisibleTasks: Array<GQMeasurementProject>;
  activeOrganization: GQActiveOrganization;
  activityContextMetadata: GQActivityContextMetadata;
  activityDataDataLineage: GQCalculationDataLineagePayload;
  activityTypeSchemas: Array<GQActivityTypeSchema>;
  adminUsers: Array<GQUser>;
  aiAgentProcessMessage: GQAiAgentChatMessage;
  allFacilityIds: Array<Scalars['String']['output']>;
  allMaterialityAssessments: Array<GQMaterialityAssessment>;
  allReportConfigs: Array<GQReportConfig>;
  allRoles: Array<GQRole>;
  allSupportCases: Array<GQSupportCase>;
  allUsers: Array<GQUser>;
  asset:
    | GQAssetCorporate
    | GQAssetGroup
    | GQAssetPersonalMotorVehicleInsurance
    | GQAssetRealEstate
    | GQAssetSovereignBond;
  assetCorporate: GQAssetCorporate;
  assetGroups: Array<GQAssetGroup>;
  assetHoldings: Array<GQFinanceAssetHolding>;
  assetIdForName: Scalars['ID']['output'];
  assetPersonalMotorVehicleInsurances: Array<GQAssetPersonalMotorVehicleInsurance>;
  assetSovereignBonds: Array<GQAssetSovereignBond>;
  assetYears: Array<GQFinanceAssetYearNew>;
  assetsAutoMatchData: Array<GQAssetAutoMatchData>;
  assetsCorporate: Array<GQAssetCorporate>;
  assetsCorporateFinancialData: Maybe<Array<Maybe<GQAssetFinancialData>>>;
  assetsCorporatePaginated: GQAssetsCorporateConnection;
  assetsIndustryCodeData: Array<GQAssetIndustryCodeData>;
  assetsRealEstate: Array<GQAssetRealEstate>;
  assignReportQuestionContainerPreviewEmail: GQEmailContents;
  auditLogs: GQAuditLogConnection;
  auditReportMetadata: GQAuditReportMetadataPayload;
  availableCanonicalDatasetsForPlan: Array<GQCanonicalDataset>;
  availableReleasesForSelectedDatasets: Array<GQBusinessActivityTypeWithReleases>;
  /** @deprecated Use averageCurrencyExchangeRates instead */
  averageCurrencyExchangeRate: GQCurrencyExchangeRate;
  averageCurrencyExchangeRatePairs: Array<GQCurrencyExchangeRate>;
  averageCurrencyExchangeRates: Array<GQCurrencyExchangeRate>;
  backgroundJob: Maybe<GQBackgroundJob>;
  backgroundJobStatus: Maybe<GQBackgroundJobStatus>;
  backgroundJobStatuses: Array<GQBackgroundJobStatus>;
  benchmarkCompanies: GQCompanyConnection;
  benchmarkNaicsCodes: Array<Scalars['String']['output']>;
  biAiQuery: GQBiAiQueryResponse;
  biCalculationDataLineage: GQCalculationDataLineagePayload;
  biCustomMetrics: Array<GQBiCustomMetric>;
  biDimensionListOptions: GQBiDimensionListOptionsResult;
  biDimensionListOptionsV2Parity: GQBiDimensionListOptionsResult;
  biDisplayValueMappings: Array<GQBiDisplayValueMapping>;
  biFilterDisplayValueMappings: Array<GQBiDisplayValueMapping>;
  biPostgresToActivityData: GQBiPostgresToActivityDataPayload;
  biQueryData: GQBiQueryDataResult;
  biQueryDataMultiple: Array<GQBiQueryDataResult>;
  biQueryDataV2: GQBiQueryDataResult;
  biQueryDataV2Parity: GQBiQueryDataResult;
  biQueryMetadata: GQBiQueryMetadataResult;
  biSavedViews: Maybe<Array<GQBiSavedView>>;
  biSchemas: GQBiSchemasResult;
  buildingSupportingDocsExport: GQBuildingSupportingDocsExport;
  buildingTemplateDataUntyped: Maybe<Scalars['UntypedData']['output']>;
  buildingUntyped: GQBuildingUntyped;
  buildingUtilitiesExport: GQBuildingUtilitiesExport;
  buildingWithUtilities: GQBuildingWithUtilities;
  businessActivityTypeById: GQBusinessActivityType;
  businessActivityTypeByIds: Array<GQBusinessActivityType>;
  businessActivityTypeByNameAndVersionId: GQBusinessActivityType;
  businessActivityTypeVersionById: GQBusinessActivityTypeVersion;
  businessActivityTypeVersionLive: GQBusinessActivityTypeVersion;
  businessActivityTypeVersions: Array<GQBusinessActivityTypeVersion>;
  calculationMethodVersionInProduct: GQEmissionsModelRelease;
  calculationMethodVersionsForFootprint: Array<GQEmissionsModelRelease>;
  canExportFootprintForSnapshot: Scalars['Boolean']['output'];
  canonicalClimateProgramProjects: Array<GQCanonicalClimateProgramProject>;
  canonicalDataset: GQCanonicalDataset;
  cdpApiRegistrationForReport: GQCdpApiRegistrationPayload;
  cdpLatestSyncWorkflowStatus: GQCdpLatestSyncWorkflowStatusPayload;
  cdpSyncQuestionStatus: Array<GQCdpSyncQuestionStatus>;
  cdpSyncQuestionStatusForReport: Array<GQCdpSyncQuestionStatus>;
  cdpSyncWorkflowStatus: GQCdpSyncWorkflowStatusPayload;
  climateProgramCalendarEvents: Array<GQClimateProgramCalendarEvent>;
  climateProgramProject: GQClimateProgramProject;
  climateProgramProjects: Array<GQClimateProgramProject>;
  climateProgramTimelineProject: GQClimateProgramTimelineProject;
  climateProgramTimelineProjects: Array<GQClimateProgramTimelineProject>;
  companiesForDashboard: Array<GQCompany>;
  company: GQCompany;
  companyDisclosures: Array<GQDisclosure>;
  companyForDashboard: Maybe<GQCompany>;
  companyFuzzyMatchWithEfs: GQCompanySearchResult;
  companyPortalSettings: GQCompanyPortalSettings;
  companyReportingFacts: Array<GQCompanyReportingFacts>;
  computeReportQuestionSeriesMetric: GQReportQuestionSeriesMetric;
  computeReportQuestionSingleValueMetric: GQReportQuestionSingleValueMetric;
  contacts: Array<GQSupplierContact>;
  contactsForCompany: Array<GQSupplierContact>;
  ctsForm: GQCtsForm;
  currencyExchangeRateFromUsdAsOfMonth: GQCurrencyExchangeRate;
  currentCalculationMethodVersionsInProduct: Array<GQEmissionsModelRelease>;
  currentFinanceSnapshot: Maybe<GQFinanceSnapshot>;
  customEmissionsFactorActivityUserUploadTasks: Array<GQUserUploadTask>;
  customEmissionsFactorsForOrg: Maybe<
    Array<GQCustomEmissionsFactorsForDashboard>
  >;
  customEmissionsFactorsTask: Maybe<GQMeasurementParentTask>;
  customEmissionsSpecifiersForOrg: Maybe<Array<Scalars['String']['output']>>;
  customReportControls: Maybe<GQCustomReportControlsPayload>;
  customReportsWithBiSavedView: Array<GQReport>;
  customerTargetSchema: Maybe<GQCustomerTargetSchema>;
  customerTargetSchemaVersion: GQCustomerTargetSchemaVersion;
  dataApprovalMeasurementProjectChangelog: GQDataApprovalMeasurementProjectChangelogPayload;
  dataApprovalObjectChangelog: GQDataApprovalObjectChangelogPayload;
  dataApprovalUserUploadTaskChangelog: GQDataApprovalUserUploadTaskChangelogPayload;
  dataGovernanceDownloadAllUrl: Scalars['String']['output'];
  dataset: Maybe<GQDataset>;
  datasets: Array<GQDataset>;
  datasource: Maybe<GQDatasource>;
  datasourceValueMappingRule: GQDatasourceValueMappingRule;
  defaultFootprint: Maybe<GQFootprint>;
  defaultFootprintSnapshotForLegacyDrilldownRedirect: Maybe<GQFootprintSnapshot>;
  defaultFootprintWithFallback: Maybe<GQFootprint>;
  demoPlatformRun: Maybe<GQDemoPlatformRun>;
  demoPlatformRuns: GQDemoPlatformRunConnection;
  disclosureEmissionsFactors: Array<GQDisclosureEmissionsFactor>;
  documentCandidates: GQDocumentCandidatesPayload;
  drilldownForReportQuestion: GQDrilldownForReportQuestionPayload;
  drilldownSavedViews: Array<GQDrilldownSavedView>;
  dustBotQuery: GQDustBotQueryResponse;
  /** @deprecated Clean power page is deprecated */
  eacPriceEstimates: Array<GQEacPriceEstimate>;
  emEvalFingerprintLineageGraph: GQFingerprintLineageGraphPayload;
  emissionsModelEditorEvalInProduct: GQEmissionsModelEditorEvalInProductPayload;
  emissionsModelStableInProduct: GQEmissionsModelStable;
  emissionsModelVersionImportedDescendantsFromDirectImportIdsInProduct: Array<GQEmissionsModelVersion>;
  emissionsModelVersionInProduct: GQEmissionsModelVersion;
  emissionsModelVersionsForFootprint: Array<GQEmissionsModelVersionWithReleaseIds>;
  emissionsYear: GQEmissionsYear;
  engagementCohorts: Array<GQEngagementCohort>;
  engagementTask: Maybe<GQEngagementTask>;
  engagementTaskBatch: GQEngagementTaskBatch;
  /** @deprecated Use engagementTaskBatch for the deploy statuses */
  engagementTaskBatchDeployStatus: GQEngagementTaskBatchDeployStatus;
  engagementTaskBatches: Array<GQEngagementTaskBatch>;
  engagementTaskByCrossOrgModelId: Maybe<GQEngagementTask>;
  engagementTaskConfig: GQEngagementTaskConfig;
  engagementTaskConfigs: Array<GQEngagementTaskConfig>;
  engagementTaskQuestions: Array<GQEngagementTaskQuestion>;
  engagementTaskQuestionsForBaseConfigId: Array<GQEngagementTaskQuestionSelectOption>;
  engagementTasks: GQEngagementTaskConnection;
  engagementTasksAssignedToOrg: Array<GQEngagementTask>;
  engagementTasksForCompanyAndOrgV1: Array<GQEngagementTask>;
  evaluatedEmissionsModels: GQEvaluatedEmissionsModelsResults;
  expenseCategoriesForSuppliers: Array<GQSupplierExpenseCategory>;
  facilities: GQFacilityConnection;
  facilitiesExportForMeasurementProject: GQFacilitiesExportForMeasurementProject;
  facility: GQFacility;
  filterSelectedAllocationInstructions: GQFilterSelectedAllocationInstructionsPayload;
  financeAssetAuditReportOptions: Array<GQFinanceAssetAuditReportOption>;
  financeAssetsYearOverYear: GQFinanceAssetsYearOverYear;
  financeAutomaticReminders: Array<GQFinanceAutomaticReminder>;
  financeChangelog: GQFinanceChangelogConnection;
  financeDataFlags: Array<GQFinanceDataFlag>;
  financeFundsYearOverYear: GQFinanceFundsYearOverYear;
  financeGridWorksheet: GQFinanceWorksheet;
  financeHoldingView: GQFinanceView;
  financeImport: GQFinanceImport;
  financeImports: Array<GQFinanceImport>;
  financeInputDataFlags: Array<GQFinanceInputDataFlag>;
  financeMeasurementProgressRows: Array<GQFinanceMeasurementProgressRow>;
  financeMetadata: GQFinanceMetadata;
  financeModelCardMetadata: GQFinanceModelCardMetadataPayload;
  financeSettings: GQFinanceSettings;
  financeShowYourWork: Maybe<GQFinanceShowYourWorkData>;
  financeSnapshot: GQFinanceSnapshot;
  financeSnapshotAsset: GQFinanceSnapshotAsset;
  financeSnapshotAssets: GQFinanceSnapshotAssets;
  financeSnapshotAssetsGroupedByCompany: GQFinanceSnapshotAssetsGroupedByCompany;
  financeSnapshotFilterOptions: Scalars['UntypedData']['output'];
  financeSnapshotFunds: GQFinanceSnapshotFunds;
  financeSnapshotHoldings: GQFinanceSnapshotHoldings;
  financeSnapshotTags: Array<GQFinanceSnapshotTag>;
  financeSnapshotWaterfallChart: GQWaterfallChartPayload;
  financeSnapshots: Array<GQFinanceSnapshot>;
  financeTags: Array<GQFinanceTag>;
  financeView: GQFinanceView;
  financialsReviewItem: Maybe<GQFinancialsReviewItem>;
  financialsReviewItems: GQFinancialsReviewItemConnection;
  flags: Array<GQFeatureFlag>;
  footprint: GQFootprint;
  footprintAnalysis: Maybe<GQFootprintAnalysis>;
  footprintAssemblyRun: GQFootprintAssemblyRun;
  footprintAuditBundle: GQFootprintAuditBundle;
  footprintAuditVerificationTemplateUrl: Scalars['String']['output'];
  footprintCategoryRules: Array<GQFootprintCategoryRule>;
  footprintExclusionRules: Array<GQFootprintExclusionRule>;
  footprintExports: GQFootprintAuditBundleConnection;
  footprintKindsWithProductsOrMaterials: Maybe<
    Array<GQFootprintKindWithProductsOrMaterials>
  >;
  footprintSnapshotExclusionRule: GQFootprintSnapshotExclusionRule;
  footprintSnapshotInDashboard: GQFootprintSnapshot;
  footprintTags: Array<GQFootprintTag>;
  footprintUsagesForExclusionRuleStables: Array<GQFootprintUsagesForExclusionRuleStable>;
  footprints: GQFootprintConnection;
  forceRedirect: Maybe<Scalars['String']['output']>;
  forecast: Maybe<GQForecast>;
  formalReport: Maybe<GQReport>;
  formalReports: GQReportConnection;
  fund: Maybe<GQFund>;
  funds: Array<GQFund>;
  getAllUserVisibleFootprintSnapshots: Array<GQFootprintSnapshot>;
  getOneSchemaEmbedStatus: Maybe<Scalars['String']['output']>;
  getReportObjectConfigPath: Maybe<GQGetReportObjectConfigPathPayload>;
  getValueMappingOutputOptions: Array<GQValueMappingOutputOption>;
  groupedAuditLogs: GQGroupedAuditLogConnection;
  hasFootprintReadyForRedux: Scalars['Boolean']['output'];
  i18nSandbox: GQI18nSandbox;
  ingestionCustomField: Maybe<GQIngestionCustomField>;
  inputDataByExtBartIds: GQInputDataQueryResult;
  integrationConnections: GQIntegrationConnectionConnection;
  integrationPartners: GQIntegrationPartnerConnection;
  latestBusinessActivityTypeInstanceForName: GQBusinessActivityType;
  latestBusinessActivityTypeInstances: Array<GQBusinessActivityType>;
  latestCurrencyExchangeRate: Maybe<GQCurrencyExchangeRate>;
  latestCustomerTargetSchemaVersionsForActivityType: Array<GQCustomerTargetSchemaVersion>;
  latestDocumentParseAttempt: GQDocumentParseAttempt;
  latestFootprintAssemblyRun: Maybe<GQFootprintAssemblyRun>;
  latestOrgStructureVersion: Maybe<GQOrgStructureVersion>;
  latestOrgUnitTypesForCanonicalDatasetKind: Array<GQOrgUnitType>;
  latestReportConfigs: Array<GQReportConfig>;
  latestSupplierAutoMappingRun: Maybe<GQLatestSupplierAutoMappingRun>;
  legacyActivityRecords: Array<GQActivityRecord>;
  legacyFootprint: Maybe<GQLegacyFootprint>;
  lineageRun: Maybe<GQLineageRun>;
  marketplaceAllocationInstructionsInDashboard: GQMarketplaceAllocationInstructionConnection;
  marketplacePurchaseLineItems: GQMarketplacePurchaseLineItemConnection;
  marketplacePurchases: GQMarketplacePurchaseConnection;
  marketplaceVoluntaryCarbonMarketDisclosures: Array<GQMarketplaceVoluntaryCarbonMarketDisclosure>;
  materialComparisonView: Array<GQMaterialVariantAndLcaRow>;
  materialVariant: GQMaterialVariant;
  materialityAssessment: Maybe<GQMaterialityAssessment>;
  materialsUpdatedTaxonomy: Maybe<Array<GQMaterialFootprintRowUpdatedTaxonomy>>;
  measurementProject: GQMeasurementProject;
  measurementProjectsInterval: Maybe<Scalars['YMInterval']['output']>;
  measurementProjectsRelevantToViewer: Array<GQMeasurementProject>;
  measurementProjectsWithFacilities: Array<GQMeasurementProject>;
  methodologyCustomizationDisplaySections: Array<GQMethodologyCustomizationDisplaySection>;
  methodologyCustomizationMappingConfig: GQMethodologyCustomizationMappingConfig;
  methodologyCustomizationOneSchemaTemplateForType: Scalars['UntypedData']['output'];
  methodologyCustomizationRowsForOrg: GQMethodologyCustomizationBundle;
  methodologyCustomizationTypeConfig: GQMethodologyCustomizationTypeConfig;
  methodologyExplainer: Maybe<GQMethodologyExplainer>;
  multipleTransformRunGraphsForFingerprints: GQMultipleTransformRunGraphsForFingerprintPayload;
  numGeneratingFootprintsForOrg: Scalars['Int']['output'];
  orderedBusinessActivityTypeInstancesForName: Array<GQBusinessActivityType>;
  orgRolesWithPermission: Array<GQRole>;
  orgStructureVersion: GQOrgStructureVersion;
  orgStructureVersionUpload: GQOrgStructureVersionUpload;
  orgStructureVersions: Array<GQOrgStructureVersion>;
  orgStructureVersionsPaginated: GQOrgStructureVersionConnection;
  orgUnit: GQOrgUnit;
  orgUnitType: GQOrgUnitType;
  orgUnitTypeRelationships: Array<GQOrgUnitTypeRelationship>;
  orgUnitTypes: Array<GQOrgUnitType>;
  orgUnitTypesPaginated: GQOrgUnitTypeConnection;
  orgUnits: Array<GQOrgUnit>;
  orgUnitsPaginated: GQOrgUnitConnection;
  orgUsers: GQUserConnection;
  orgUsersWithPermission: Array<GQUser>;
  organization: GQOrganization;
  peerCompanyGroups: Array<GQPeerCompanyGroup>;
  peerExternalReportAnswersForIdentifiers: GQPeerExternalReportAnswersForIdentifiersPayload;
  pendingCompanyChangeRequests: Array<GQCompanyChangeRequest>;
  pendingUserPermissionRequests: Array<GQUserPermissionRequest>;
  pendingUserPermissionRequestsForUser: Array<GQUserPermissionRequest>;
  permissionItem: Maybe<GQPermissionItem>;
  permissionObjectsPaginated: Maybe<GQPermissionObjectConnection>;
  permissions: GQPermissionConnection;
  personalMotorVehicleInsuranceAssetHoldings: Array<GQFinanceAssetHolding>;
  personalMotorVehicleInsuranceAssetYears: Array<GQFinanceAssetYearNew>;
  plan: GQPlan;
  plans: GQPlanConnection;
  plansWithDefaults: GQPlanConnection;
  postMeasurementSupplierMappingTableRows: GQSupplierMappingTableRowsPayload;
  preMeasurementSupplierMappingTableRows: GQSupplierMappingTableRowsPayload;
  preMeasurementSupplierMappingV1Analytics: GQSupplierMappingV1Analytics;
  preferredLocale: Maybe<Scalars['String']['output']>;
  previewCadtBuildingsPush: Maybe<GQCadtBuildingsPushPreview>;
  privateDisclosures: Maybe<Array<GQPrivateDisclosure>>;
  productWaitlistTypes: Array<GQProductWaitlistType>;
  productionGraphProcess: Array<GQProductionGraphProcess>;
  productsUpdatedTaxonomy: Maybe<Array<GQProductFootprintRowUpdatedTaxonomy>>;
  publicDisclosure: GQPublicDisclosure;
  publicDisclosureSearch: GQPublicDisclosureConnection;
  publicDisclosures: Maybe<Array<GQPublicDisclosure>>;
  publicDisclosuresPaginated: GQPublicDisclosureConnection;
  recommendedPublicDisclosures: GQPublicDisclosureConnection;
  recommendedReportKinds: Maybe<GQRecommendedReportKinds>;
  referenceDataCitationInProduct: GQReferenceDataCitation;
  referenceDataRevisionInProduct: GQReferenceDataRevision;
  referenceDataSourceInProduct: GQReferenceDataSource;
  referenceDataVersionBySlugInProduct: GQReferenceDataVersion;
  referenceDataVersionInProduct: GQReferenceDataVersion;
  reportAttachmentItems: Array<GQReportAttachmentItem>;
  /** @deprecated Use ReportQuestionInstance.attachmentItems instead. */
  reportAttachmentItemsForQuestion: Array<GQReportAttachmentItem>;
  reportQuestion: GQReportQuestion;
  reportQuestionContainer: GQReportQuestionContainer;
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  reportQuestionInstancesAggregate: GQReportQuestionInstancesAggregate;
  reportQuestions: Array<GQReportQuestion>;
  reportWaitlistTypes: Array<Scalars['String']['output']>;
  reportWithConfig: GQReportWithConfig;
  role: Maybe<GQRole>;
  roles: GQRoleConnection;
  sampledFinancialAccountIds: Array<Scalars['String']['output']>;
  sandboxEmissionsYears: GQEmissionsYearConnection;
  selectedDatasetsByBatWithMetadata: GQDatasetsByBatWithMetadata;
  signOneSchemaJwt: GQOneSchemaJwtPayload;
  singleRowMethodologyExplainer: GQSingleRowMethodologyExplainer;
  singleRowMethodologyExplainerForEmissionsYear: GQSingleRowMethodologyExplainer;
  sovereignBondAssetYears: Array<GQFinanceAssetYearNew>;
  supplier: Maybe<GQSupplier>;
  supplierActivityTags: GQSupplierActivityTags;
  supplierAttachmentDownloadUrl: Scalars['String']['output'];
  supplierAttachments: Array<GQSupplierAttachment>;
  supplierAttachmentsByCrossOrgIds: Array<GQSupplierAttachment>;
  supplierCustomData: Array<GQSupplierCustomData>;
  supplierDetailedEmissions: Maybe<GQSupplierDetailedEmissions>;
  supplierEmissionsFactorsTableMetadata: GQSupplierEmissionsFactorsTableMetadata;
  supplierEmissionsFactorsTableRows: GQSupplierEmissionsFactorsTableRows;
  supplierFilterOptions: Array<GQSupplierFilterOption>;
  supplierMappingFilterOptions: Array<GQSupplierFilterOption>;
  supplierScorecards: Array<GQSupplierScorecard>;
  supplierTableColumnsDashboard: Array<GQSupplierTableColumn>;
  supplierViews: Array<GQSupplierView>;
  suppliersSettings: GQSuppliersSettings;
  suppliersV2: GQSuppliersData;
  suppliersWithTargetDisclosures: GQSuppliersWithInterval;
  supportCase: GQSupportCase;
  supportCaseNotificationCount: Scalars['Int']['output'];
  supportCases: GQSupportCaseConnection;
  tcfdArchetypeOpportunities: Array<GQTcfdArchetypeOpportunity>;
  tcfdArchetypeRisks: Array<GQTcfdArchetypeRisk>;
  throwErrorQuery: Maybe<GQThrowErrorPayload>;
  traceDetailsForEvaluation: Array<GQTraceDetailsResult>;
  uniqueExternalReportFilterValues: GQUniqueExternalReportFilterValues;
  uploadsForActivityType: GQUploadConnection;
  user: Maybe<GQUser>;
  userHasManageFinancialsPermission: Scalars['Boolean']['output'];
  userHasManageValueMappingPermission: Scalars['Boolean']['output'];
  userHasPermission: Scalars['Boolean']['output'];
  userHasViewDatasourcePermission: Scalars['Boolean']['output'];
  userUpload: GQUserUpload;
  userUploadCandidate: Maybe<GQUserUploadCandidate>;
  userUploadCandidates: GQUserUploadCandidatePayload;
  userUploadTask: Maybe<GQUserUploadTask>;
  userUploadTaskByFileMetadataId: Maybe<GQUserUploadTask>;
  userUploadTaskForDatasource: GQUserUploadTask;
  userUploadedTableById: GQUserUploadedTable;
  userUploadsById: Array<GQUserUpload>;
  usersAndRolesForPermissions: Scalars['UntypedData']['output'];
  usersFuzzySearch: Array<GQUser>;
  usersWithSupplierPermission: Array<GQUser>;
  utilitiesMetadata: GQUtilitiesMetadata;
  valueMapping: GQValueMapping;
  valueMappingAggregatedQuantities: Maybe<GQValueMappingAggregatedQuantities>;
  valueMappingCommentsForSubject: Array<GQValueMappingComment>;
  valueMappingConfig: Scalars['UntypedData']['output'];
  valueMappingFileRowData: Array<GQValueMappingFileData>;
  valueMappingHistoryForGroup: GQValueMappingHistoryForGroupPayload;
  valueMappingRows: Maybe<GQValueMappingRowConnection>;
  valueMappingTableSourceData: GQValueMappingTableSourceData;
  valueMappingTask: GQValueMappingTask;
  valueMappingWithLatestStatus: GQValueMapping;
  variousSuppliers: GQVariousSuppliersPayload;
  vendorMatchingTask: Maybe<GQVendorMatchingTask>;
  visibleMarketplaceProjectArchetypes: GQMarketplaceProjectArchetypeConnection;
  yearMeasurementProjects: Array<GQMeasurementProject>;
};

export type GQQueryActiveMeasurementProjectsArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryActiveMeasurementProjectsWithVisibleTasksArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryActivityContextMetadataArgs = {
  input: GQBiActivityQueryContextInput;
};

export type GQQueryActivityDataDataLineageArgs = {
  queryContext: GQBiQueryContextInput;
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryAiAgentProcessMessageArgs = {
  chatHistory: InputMaybe<Scalars['JSONString']['input']>;
  commandResults: InputMaybe<Scalars['JSONString']['input']>;
  context: Scalars['JSONString']['input'];
  featureId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type GQQueryAllReportConfigsArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryAssetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryAssetCorporateArgs = {
  assetCorporateId: Scalars['ID']['input'];
};

export type GQQueryAssetIdForNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryAssetsAutoMatchDataArgs = {
  assetIds: Array<Scalars['ID']['input']>;
};

export type GQQueryAssetsCorporateFinancialDataArgs = {
  assetIds: Array<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryAssetsCorporatePaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryAssetsIndustryCodeDataArgs = {
  assetIds: Array<Scalars['ID']['input']>;
};

export type GQQueryAssignReportQuestionContainerPreviewEmailArgs = {
  input: GQAssignReportQuestionContainerPreviewEmailInput;
};

export type GQQueryAuditLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  assetId: InputMaybe<Scalars['ID']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  eventKind: InputMaybe<Scalars['String']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  fundId: InputMaybe<Scalars['ID']['input']>;
  interval: InputMaybe<Scalars['YMInterval']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgStructureVersionId: InputMaybe<Scalars['ID']['input']>;
  orgUnitId: InputMaybe<Scalars['ID']['input']>;
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  supportCaseId: InputMaybe<Scalars['ID']['input']>;
  userId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryAuditReportMetadataArgs = {
  input: GQAuditReportMetadataInput;
};

export type GQQueryAvailableReleasesForSelectedDatasetsArgs = {
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
};

export type GQQueryAverageCurrencyExchangeRateArgs = {
  baseCurrency: Scalars['String']['input'];
  interval: Scalars['YMInterval']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryAverageCurrencyExchangeRatePairsArgs = {
  inputs: Array<GQCurrencyExchangeRateInput>;
};

export type GQQueryAverageCurrencyExchangeRatesArgs = {
  baseCurrency: Scalars['String']['input'];
  intervals: Array<Scalars['YMInterval']['input']>;
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryBackgroundJobArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBackgroundJobStatusesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryBenchmarkCompaniesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  naicsCode: InputMaybe<Scalars['String']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryBenchmarkNaicsCodesArgs = {
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryBiAiQueryArgs = {
  input: GQBiAiQueryInput;
};

export type GQQueryBiCalculationDataLineageArgs = {
  conversionFactorId: InputMaybe<Scalars['String']['input']>;
  emissionsModelEvaluationId: InputMaybe<Scalars['String']['input']>;
  fingerprint: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryBiDimensionListOptionsArgs = {
  input: GQBiDimensionListOptionsInput;
};

export type GQQueryBiDimensionListOptionsV2ParityArgs = {
  input: GQBiDimensionListOptionsInput;
};

export type GQQueryBiDisplayValueMappingsArgs = {
  input: Array<GQBiDisplayValueMappingRequestInput>;
};

export type GQQueryBiFilterDisplayValueMappingsArgs = {
  input: GQBiFilterDisplayValueMappingsInput;
};

export type GQQueryBiPostgresToActivityDataArgs = {
  input: GQBiActivityQueryContextInput;
};

export type GQQueryBiQueryDataArgs = {
  input: GQBiQueryDataInputLegacy;
};

export type GQQueryBiQueryDataMultipleArgs = {
  input: GQBiQueryDataMultipleInput;
};

export type GQQueryBiQueryDataV2Args = {
  input: GQBiQueryDataInput;
};

export type GQQueryBiQueryDataV2ParityArgs = {
  input: GQBiQueryDataInputLegacy;
  options: InputMaybe<GQBiQueryOptionsParity>;
};

export type GQQueryBiQueryMetadataArgs = {
  input: GQBiQueryMetadataInput;
};

export type GQQueryBiSavedViewsArgs = {
  input: GQBiSavedViewsInput;
};

export type GQQueryBiSchemasArgs = {
  input: GQBiSchemasInput;
};

export type GQQueryBuildingSupportingDocsExportArgs = {
  buildingIds: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQQueryBuildingTemplateDataUntypedArgs = {
  userUploadTaskId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryBuildingUntypedArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBuildingWithUtilitiesArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeByIdArgs = {
  batId: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeByIdsArgs = {
  batIds: Array<Scalars['ID']['input']>;
};

export type GQQueryBusinessActivityTypeByNameAndVersionIdArgs = {
  name: Scalars['String']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQQueryBusinessActivityTypeVersionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCalculationMethodVersionInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCalculationMethodVersionsForFootprintArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryCanExportFootprintForSnapshotArgs = {
  footprintSnapshotId: Scalars['ID']['input'];
};

export type GQQueryCanonicalClimateProgramProjectsArgs = {
  isForMeasurement: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCanonicalDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCdpApiRegistrationForReportArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQQueryCdpSyncQuestionStatusArgs = {
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQQueryCdpSyncQuestionStatusForReportArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQQueryCdpSyncWorkflowStatusArgs = {
  workflowId: Scalars['String']['input'];
};

export type GQQueryClimateProgramProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryClimateProgramProjectsArgs = {
  includeMeasureWhatMatters: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryClimateProgramTimelineProjectArgs = {
  id: Scalars['ID']['input'];
  objectType: GQClimateProgramTimelineProjectObjectType;
};

export type GQQueryCompaniesForDashboardArgs = {
  disableBetterCompanyUpgrade: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['ID']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCompanyDisclosuresArgs = {
  companyId: Scalars['ID']['input'];
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQQueryCompanyForDashboardArgs = {
  disableBetterCompanyUpgrade: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryCompanyFuzzyMatchWithEfsArgs = {
  allowMatchById: InputMaybe<Scalars['Boolean']['input']>;
  filterByPublicDisclosures: InputMaybe<Scalars['Boolean']['input']>;
  filterHiddenCompanies: InputMaybe<Scalars['Boolean']['input']>;
  q: Scalars['String']['input'];
  searchDemo: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryCompanyPortalSettingsArgs = {
  rootCompanyId: Scalars['ID']['input'];
};

export type GQQueryCompanyReportingFactsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryComputeReportQuestionSeriesMetricArgs = {
  input: GQComputeReportQuestionMetricInput;
};

export type GQQueryComputeReportQuestionSingleValueMetricArgs = {
  input: GQComputeReportQuestionMetricInput;
};

export type GQQueryContactsForCompanyArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryCtsFormArgs = {
  ctsFormId: Scalars['ID']['input'];
};

export type GQQueryCurrencyExchangeRateFromUsdAsOfMonthArgs = {
  month: Scalars['YearMonth']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryCustomEmissionsFactorActivityUserUploadTasksArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomEmissionsFactorsForOrgArgs = {
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryCustomEmissionsFactorsTaskArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomEmissionsSpecifiersForOrgArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryCustomReportControlsArgs = {
  input: GQCustomReportControlsInput;
};

export type GQQueryCustomReportsWithBiSavedViewArgs = {
  input: GQCustomReportsWithBiSavedViewInput;
};

export type GQQueryCustomerTargetSchemaArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryCustomerTargetSchemaVersionArgs = {
  ctsVersionId: Scalars['ID']['input'];
  customerTargetSchemaId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryDataApprovalMeasurementProjectChangelogArgs = {
  input: GQDataApprovalMeasurementProjectChangelogInput;
};

export type GQQueryDataApprovalObjectChangelogArgs = {
  input: GQDataApprovalObjectChangelogInput;
};

export type GQQueryDataApprovalUserUploadTaskChangelogArgs = {
  input: GQDataApprovalUserUploadTaskChangelogInput;
};

export type GQQueryDataGovernanceDownloadAllUrlArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryDatasetArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDatasetsArgs = {
  sortBy: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryDatasourceArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDatasourceValueMappingRuleArgs = {
  datasourceId: InputMaybe<Scalars['ID']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  valueMappingRuleId: Scalars['String']['input'];
};

export type GQQueryDefaultFootprintArgs = {
  status: InputMaybe<GQFootprintStatus>;
};

export type GQQueryDefaultFootprintSnapshotForLegacyDrilldownRedirectArgs = {
  viewDraft: Scalars['Boolean']['input'];
};

export type GQQueryDefaultFootprintWithFallbackArgs = {
  status: InputMaybe<GQFootprintStatus>;
};

export type GQQueryDemoPlatformRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryDemoPlatformRunsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryDisclosureEmissionsFactorsArgs = {
  privateDisclosureIds: Array<Scalars['ID']['input']>;
  publicDisclosureIds: Array<Scalars['ID']['input']>;
};

export type GQQueryDocumentCandidatesArgs = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQQueryDrilldownForReportQuestionArgs = {
  input: GQDrilldownForReportQuestionInput;
};

export type GQQueryDustBotQueryArgs = {
  input: GQDustBotQueryInput;
};

export type GQQueryEmEvalFingerprintLineageGraphArgs = {
  emissionsModelEvaluationId: Scalars['String']['input'];
  fingerprint: Scalars['String']['input'];
  footprintSnapshotId: Scalars['String']['input'];
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryEmissionsModelEditorEvalInProductArgs = {
  input: GQEmissionsModelEditorEvalInProductInput;
};

export type GQQueryEmissionsModelStableInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelVersionImportedDescendantsFromDirectImportIdsInProductArgs =
  {
    ids: Array<Scalars['ID']['input']>;
  };

export type GQQueryEmissionsModelVersionInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryEmissionsModelVersionsForFootprintArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryEmissionsYearArgs = {
  emissionsYearId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskArgs = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskBatchArgs = {
  engagementTaskBatchId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskBatchDeployStatusArgs = {
  engagementTaskBatchId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskByCrossOrgModelIdArgs = {
  crossOrgModelId: Scalars['String']['input'];
};

export type GQQueryEngagementTaskConfigArgs = {
  engagementTaskConfigId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskConfigsArgs = {
  configGroup: InputMaybe<GQEngagementTaskConfigGroup>;
  includeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeGlobalConfigs: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedGlobal: InputMaybe<Scalars['Boolean']['input']>;
  includeUnpublishedOrgSpecific: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQQueryEngagementTaskQuestionsForBaseConfigIdArgs = {
  baseConfigId: Scalars['ID']['input'];
  excludeComplex: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEngagementTasksArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  ignoreLearningTasks: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEngagementTasksForCompanyAndOrgV1Args = {
  companyId: Scalars['ID']['input'];
  rootOnly: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryEvaluatedEmissionsModelsArgs = {
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  materialId: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
  productId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryExpenseCategoriesForSuppliersArgs = {
  companyIds: Array<Scalars['ID']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  useDuckdbPostgresConnection: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryFacilitiesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<Array<GQFacilitiesFilterInput>>;
  filterWithOperator: InputMaybe<GQFacilitiesFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<Array<GQFacilitiesSortInput>>;
};

export type GQQueryFacilitiesExportForMeasurementProjectArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryFacilityArgs = {
  id: Scalars['ID']['input'];
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryFilterSelectedAllocationInstructionsArgs = {
  selectedAllocationInstructionIds: Array<Scalars['ID']['input']>;
};

export type GQQueryFinanceAssetAuditReportOptionsArgs = {
  assetCorporateId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceAssetsYearOverYearArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFinanceChangelogArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: InputMaybe<Array<GQBiQueryFilterInput>>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceDataFlagsArgs = {
  assetId: InputMaybe<Scalars['ID']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceFundsYearOverYearArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  before: InputMaybe<Scalars['String']['input']>;
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFinanceGridWorksheetArgs = {
  standardView: GQFinanceStandardView;
};

export type GQQueryFinanceHoldingViewArgs = {
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinanceImportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinanceInputDataFlagsArgs = {
  assetId: InputMaybe<Scalars['ID']['input']>;
  financeSnapshotId: Scalars['ID']['input'];
  fundId: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceMeasurementProgressRowsArgs = {
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceModelCardMetadataArgs = {
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceShowYourWorkArgs = {
  filters: Scalars['UntypedData']['input'];
  financeMetric: GQFinanceMetric;
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotAssetArgs = {
  assetId: Scalars['ID']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotAssetsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotAssetsGroupedByCompanyArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotFilterOptionsArgs = {
  assetYearId: InputMaybe<Scalars['ID']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotFundsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotHoldingsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortDirection: InputMaybe<Scalars['String']['input']>;
  sortField: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type GQQueryFinanceSnapshotTagsArgs = {
  financeSnapshotId: Scalars['ID']['input'];
};

export type GQQueryFinanceSnapshotWaterfallChartArgs = {
  baselineSnapshotId: Scalars['ID']['input'];
  baselineYear: Scalars['Int']['input'];
  comparisonYear: Scalars['Int']['input'];
  filters: Scalars['UntypedData']['input'];
  financeSnapshotId: Scalars['ID']['input'];
  financeWaterfallChartType: GQFinanceWaterfallChartType;
};

export type GQQueryFinanceTagsArgs = {
  tagType: InputMaybe<GQFinanceTagType>;
};

export type GQQueryFinanceViewArgs = {
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  financeSnapshotId: InputMaybe<Scalars['ID']['input']>;
  isTotalEmissions: InputMaybe<Scalars['Boolean']['input']>;
  standardView: InputMaybe<GQFinanceStandardView>;
  viewId: InputMaybe<Scalars['ID']['input']>;
  year: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFinancialsReviewItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFinancialsReviewItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryFootprintArgs = {
  footprintId: Scalars['String']['input'];
};

export type GQQueryFootprintAnalysisArgs = {
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryFootprintAssemblyRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintAuditBundleArgs = {
  bundleId: Scalars['String']['input'];
};

export type GQQueryFootprintCategoryRulesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryFootprintExclusionRulesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryFootprintExportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryFootprintKindsWithProductsOrMaterialsArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryFootprintSnapshotExclusionRuleArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFootprintSnapshotInDashboardArgs = {
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQQueryFootprintUsagesForExclusionRuleStablesArgs = {
  exclusionRuleStableIds: Array<Scalars['ID']['input']>;
};

export type GQQueryFootprintsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryForceRedirectArgs = {
  pathname: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryForecastArgs = {
  canViewABIs: InputMaybe<Scalars['Boolean']['input']>;
  shouldLazilyCreate: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQQueryFormalReportArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryFormalReportsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportMode: GQReportMode;
  reportStatus: InputMaybe<GQReportStatus>;
};

export type GQQueryFundArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryGetAllUserVisibleFootprintSnapshotsArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQQueryGetOneSchemaEmbedStatusArgs = {
  embedId: Scalars['String']['input'];
};

export type GQQueryGetReportObjectConfigPathArgs = {
  input: GQGetReportObjectConfigPathInput;
};

export type GQQueryGetValueMappingOutputOptionsArgs = {
  columnName: Scalars['String']['input'];
  configId: Scalars['String']['input'];
  searchTerm: InputMaybe<Scalars['String']['input']>;
  selectedValue: InputMaybe<Scalars['String']['input']>;
  suggestedValues: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryGroupedAuditLogsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  eventKinds: Array<Scalars['String']['input']>;
  filters: InputMaybe<Scalars['UntypedData']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryHasFootprintReadyForReduxArgs = {
  orgFiscalYearStartMonth: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryIngestionCustomFieldArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryInputDataByExtBartIdsArgs = {
  extBartIds: Array<Scalars['ID']['input']>;
};

export type GQQueryIntegrationConnectionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryIntegrationPartnersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryLatestBusinessActivityTypeInstanceForNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryLatestCurrencyExchangeRateArgs = {
  targetCurrency: Scalars['String']['input'];
};

export type GQQueryLatestCustomerTargetSchemaVersionsForActivityTypeArgs = {
  businessActivityType: Scalars['String']['input'];
};

export type GQQueryLatestDocumentParseAttemptArgs = {
  fileId: Scalars['ID']['input'];
  modelType: GQDocumentParseAttemptModelType;
};

export type GQQueryLatestOrgUnitTypesForCanonicalDatasetKindArgs = {
  canonicalDatasetKind: GQCanonicalDatasetKind;
};

export type GQQueryLatestSupplierAutoMappingRunArgs = {
  entity: InputMaybe<GQAutoMappingEntity>;
};

export type GQQueryLegacyFootprintArgs = {
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  shouldConvertCurrency: InputMaybe<Scalars['Boolean']['input']>;
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryLineageRunArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMarketplaceAllocationInstructionsInDashboardArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePurchaseLineItemsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMarketplacePurchasesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMaterialVariantArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMaterialityAssessmentArgs = {
  materialityAssessmentId: Scalars['ID']['input'];
};

export type GQQueryMaterialsUpdatedTaxonomyArgs = {
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  groupByMonth: Scalars['Boolean']['input'];
  orgMaterial: InputMaybe<Scalars['String']['input']>;
  orgRawMaterial: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMeasurementProjectArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryMeasurementProjectsRelevantToViewerArgs = {
  limit: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryMethodologyCustomizationMappingConfigArgs = {
  configId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationOneSchemaTemplateForTypeArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationRowsForOrgArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyCustomizationTypeConfigArgs = {
  customizationTypeId: Scalars['String']['input'];
};

export type GQQueryMethodologyExplainerArgs = {
  footprintSnapshotId: Scalars['String']['input'];
  version: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryMultipleTransformRunGraphsForFingerprintsArgs = {
  emissionsModelEvaluationIds: InputMaybe<Array<Scalars['String']['input']>>;
  fingerprints: Array<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  singleMeasureSelector: GQBiQuerySingleMeasureSelectorInput;
};

export type GQQueryNumGeneratingFootprintsForOrgArgs = {
  includeHidden: Scalars['Boolean']['input'];
};

export type GQQueryOrderedBusinessActivityTypeInstancesForNameArgs = {
  name: Scalars['String']['input'];
};

export type GQQueryOrgRolesWithPermissionArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  objectType: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQQueryOrgStructureVersionArgs = {
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgStructureVersionUploadArgs = {
  orgStructureVersionUploadId: Scalars['ID']['input'];
};

export type GQQueryOrgStructureVersionsArgs = {
  versionId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryOrgStructureVersionsPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
};

export type GQQueryOrgUnitArgs = {
  orgUnitId: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitTypeArgs = {
  orgUnitTypeId: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitTypeRelationshipsArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitTypesArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitTypesPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitsArgs = {
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUnitsPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  orgUnitTypeId: InputMaybe<Scalars['ID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  versionId: Scalars['ID']['input'];
};

export type GQQueryOrgUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
};

export type GQQueryOrgUsersWithPermissionArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  objectType: InputMaybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQQueryOrganizationArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryPeerExternalReportAnswersForIdentifiersArgs = {
  reportQuestionIdentifiers: Array<Scalars['String']['input']>;
};

export type GQQueryPendingUserPermissionRequestsForUserArgs = {
  permission: InputMaybe<GQPermissionType>;
};

export type GQQueryPermissionItemArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPermissionObjectsPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  permissionObjectType: GQPermissionObjectType;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  userOrRoleMetadata: InputMaybe<GQUserOrRoleMetadata>;
};

export type GQQueryPermissionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeWatershedEmployees: InputMaybe<Scalars['Boolean']['input']>;
  includedPermissions: InputMaybe<Array<GQPermissionType>>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPlanArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPlansArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeHidden: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  state: InputMaybe<GQPlanState>;
};

export type GQQueryPlansWithDefaultsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryPostMeasurementSupplierMappingTableRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Array<GQBiQueryFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  paginationArgs: InputMaybe<GQOffsetPaginationArgsInput>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: Array<GQDataGridSortItemInput>;
};

export type GQQueryPreMeasurementSupplierMappingTableRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filters: Array<GQBiQueryFilterInput>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  paginationArgs: InputMaybe<GQOffsetPaginationArgsInput>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: Array<GQDataGridSortItemInput>;
};

export type GQQueryPreMeasurementSupplierMappingV1AnalyticsArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryPreviewCadtBuildingsPushArgs = {
  activityDataExtractorId: Scalars['ID']['input'];
};

export type GQQueryProductionGraphProcessArgs = {
  query: Scalars['String']['input'];
};

export type GQQueryProductsUpdatedTaxonomyArgs = {
  dateInterval: InputMaybe<Scalars['YMInterval']['input']>;
  footprintKind: InputMaybe<Scalars['String']['input']>;
  footprintSnapshotId: Scalars['String']['input'];
  groupByMonth: Scalars['Boolean']['input'];
  product: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPublicDisclosureArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryPublicDisclosureSearchArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  allScopes: InputMaybe<Scalars['Boolean']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  onlyMarketBased: InputMaybe<Scalars['Boolean']['input']>;
  publishingYears: InputMaybe<Array<Scalars['Int']['input']>>;
  reportTypes: InputMaybe<Array<GQExternalReportType>>;
  revenueMustExist: InputMaybe<Scalars['Boolean']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryPublicDisclosuresArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  limit: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  reportType: InputMaybe<GQExternalReportType>;
};

export type GQQueryPublicDisclosuresPaginatedArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  companyId: InputMaybe<Scalars['ID']['input']>;
  companyIds: InputMaybe<Array<Scalars['ID']['input']>>;
  filterLicensed: InputMaybe<Scalars['Boolean']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  mustHaveURL: InputMaybe<Scalars['Boolean']['input']>;
  publishingYears: InputMaybe<Array<Scalars['Int']['input']>>;
  reportTypes: InputMaybe<Array<GQExternalReportType>>;
  search: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryRecommendedPublicDisclosuresArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryRecommendedReportKindsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryReferenceDataCitationInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataRevisionInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataSourceInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReferenceDataVersionBySlugInProductArgs = {
  slug: Scalars['String']['input'];
};

export type GQQueryReferenceDataVersionInProductArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportAttachmentItemsArgs = {
  kinds: InputMaybe<Array<GQReportAttachmentItemKind>>;
};

export type GQQueryReportAttachmentItemsForQuestionArgs = {
  reportId: Scalars['ID']['input'];
  reportQuestionId: Scalars['ID']['input'];
};

export type GQQueryReportQuestionArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionContainerArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryReportQuestionInstancesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryReportQuestionInstancesAggregateArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryReportQuestionsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryReportWithConfigArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryRoleArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryRolesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
};

export type GQQuerySandboxEmissionsYearsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQuerySelectedDatasetsByBatWithMetadataArgs = {
  datasetIdsPerMeasurementProjectId: Array<GQDatasetIdsPerMeasurementProjectId>;
};

export type GQQuerySignOneSchemaJwtArgs = {
  payload: Scalars['JSONString']['input'];
};

export type GQQuerySingleRowMethodologyExplainerArgs = {
  bundleId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
};

export type GQQuerySingleRowMethodologyExplainerForEmissionsYearArgs = {
  emissionsYearId: Scalars['String']['input'];
  rowId: Scalars['Int']['input'];
};

export type GQQuerySupplierArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  enableExperimentalCache: InputMaybe<Scalars['Boolean']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  vendorName: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySupplierActivityTagsArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
};

export type GQQuerySupplierAttachmentDownloadUrlArgs = {
  fileMetadataId: Scalars['ID']['input'];
};

export type GQQuerySupplierAttachmentsArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQuerySupplierAttachmentsByCrossOrgIdsArgs = {
  crossOrgModelIds: Array<Scalars['String']['input']>;
};

export type GQQuerySupplierDetailedEmissionsArgs = {
  companyId: InputMaybe<Scalars['ID']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  vendorName: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySupplierEmissionsFactorsTableRowsArgs = {
  companyId: Scalars['String']['input'];
};

export type GQQuerySupplierFilterOptionsArgs = {
  dimension: Scalars['String']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  nestedDimensions: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQuerySupplierMappingFilterOptionsArgs = {
  dimension: Scalars['String']['input'];
};

export type GQQuerySuppliersV2Args = {
  enableExperimentalCache: InputMaybe<Scalars['Boolean']['input']>;
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  includedGhgCategories: InputMaybe<Array<Scalars['String']['input']>>;
  options: InputMaybe<GQSuppliersV2Options>;
  tableFilters: InputMaybe<Array<GQBiQueryFilterInput>>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySuppliersWithTargetDisclosuresArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  viewFilters: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
};

export type GQQuerySupportCaseArgs = {
  id: Scalars['ID']['input'];
};

export type GQQuerySupportCaseNotificationCountArgs = {
  issueType: InputMaybe<GQSupportCaseIssueType>;
  objectId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQuerySupportCasesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  hasUnreadNotifications: InputMaybe<Scalars['Boolean']['input']>;
  issueType: InputMaybe<GQSupportCaseIssueType>;
  last: InputMaybe<Scalars['Int']['input']>;
  objectId: InputMaybe<Scalars['String']['input']>;
  onlyOpen: InputMaybe<Scalars['Boolean']['input']>;
  priority: InputMaybe<GQSupportCasePriority>;
  search: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  status: InputMaybe<GQSupportCaseStatus>;
  userId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryThrowErrorQueryArgs = {
  input: GQThrowErrorInput;
};

export type GQQueryTraceDetailsForEvaluationArgs = {
  evaluationId: Scalars['ID']['input'];
  tracesByFingerprint: Array<GQTraceByFingerprintInput>;
};

export type GQQueryUploadsForActivityTypeArgs = {
  activityType: Scalars['String']['input'];
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserHasManageValueMappingPermissionArgs = {
  orgId: Scalars['ID']['input'];
  valueMappingRuleId: InputMaybe<Scalars['String']['input']>;
};

export type GQQueryUserHasPermissionArgs = {
  allowAnyObject: InputMaybe<Scalars['Boolean']['input']>;
  objectId: InputMaybe<Scalars['ID']['input']>;
  permission: GQPermissionType;
};

export type GQQueryUserHasViewDatasourcePermissionArgs = {
  allowAnyObject: InputMaybe<Scalars['Boolean']['input']>;
  datasourceId: InputMaybe<Scalars['ID']['input']>;
};

export type GQQueryUserUploadArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadCandidateArgs = {
  userUploadCandidateId: Scalars['ID']['input'];
};

export type GQQueryUserUploadCandidatesArgs = {
  userUploadCandidateIds: Array<Scalars['ID']['input']>;
};

export type GQQueryUserUploadTaskArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadTaskByFileMetadataIdArgs = {
  fileMetadataId: Scalars['ID']['input'];
};

export type GQQueryUserUploadTaskForDatasourceArgs = {
  datasourceId: Scalars['ID']['input'];
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryUserUploadedTableByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryUserUploadsByIdArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type GQQueryUsersAndRolesForPermissionsArgs = {
  permissions: Array<GQHasPermissionInput>;
};

export type GQQueryUsersFuzzySearchArgs = {
  q: Scalars['String']['input'];
};

export type GQQueryUsersWithSupplierPermissionArgs = {
  companyId: Scalars['ID']['input'];
};

export type GQQueryUtilitiesMetadataArgs = {
  filterInterval: InputMaybe<Scalars['YMInterval']['input']>;
};

export type GQQueryValueMappingArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingAggregatedQuantitiesArgs = {
  configId: Scalars['ID']['input'];
  contextColumn: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
};

export type GQQueryValueMappingCommentsForSubjectArgs = {
  orgId: Scalars['ID']['input'];
  subjectId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingConfigArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingFileRowDataArgs = {
  filter: Array<GQValueMappingFileDataFilter>;
  sortColumn: Scalars['String']['input'];
  userUploadIds: Array<Scalars['ID']['input']>;
};

export type GQQueryValueMappingHistoryForGroupArgs = {
  groupId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingRowsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GQValueMappingFilterType>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  measurementProjectId: InputMaybe<Scalars['ID']['input']>;
  searchText: InputMaybe<Scalars['String']['input']>;
  sortModel: InputMaybe<Array<GQDataGridSortItemInput>>;
  valueMappingConfigId: Scalars['ID']['input'];
};

export type GQQueryValueMappingTableSourceDataArgs = {
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryValueMappingTaskArgs = {
  id: Scalars['ID']['input'];
};

export type GQQueryValueMappingWithLatestStatusArgs = {
  projectId: InputMaybe<Scalars['String']['input']>;
  valueMappingConfigId: Scalars['String']['input'];
};

export type GQQueryVariousSuppliersArgs = {
  footprintInterval: Scalars['YMInterval']['input'];
  footprintSnapshotId: InputMaybe<Scalars['String']['input']>;
  includedGhgCategories: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQQueryVendorMatchingTaskArgs = {
  measurementProjectId: Scalars['ID']['input'];
};

export type GQQueryVisibleMarketplaceProjectArchetypesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQQueryYearMeasurementProjectsArgs = {
  reportingYear: Scalars['Int']['input'];
};

export type GQQuestion = {
  comments: GQCommentConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type GQQuestionCommentsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQReassignFacilitiesTaskInput = {
  assignments: Array<GQReassignFacilitiesTaskRowInput>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQReassignFacilitiesTaskPayload = {
  __typename?: 'ReassignFacilitiesTaskPayload';
  measurementProject: GQMeasurementProject;
};

export type GQReassignFacilitiesTaskRowInput = {
  buildingId: Scalars['ID']['input'];
  facilitiesType: GQFacilitiesType;
  userIds: Array<Scalars['ID']['input']>;
};

export type GQRecommendedReportKinds = {
  __typename?: 'RecommendedReportKinds';
  id: Scalars['ID']['output'];
  recommended: Array<GQReportKindWithReason>;
  required: Array<GQReportKindWithReason>;
  state: GQRecommendedReportKindsState;
};

export const GQRecommendedReportKindsState = {
  Complete: 'Complete',
  NotStarted: 'NotStarted',
  PartiallyComplete: 'PartiallyComplete',
} as const;

export type GQRecommendedReportKindsState =
  (typeof GQRecommendedReportKindsState)[keyof typeof GQRecommendedReportKindsState];
export type GQReductionTargetByYear = {
  __typename?: 'ReductionTargetByYear';
  reductionTarget: Scalars['Float']['output'];
  targetYear: Scalars['YearMonth']['output'];
};

export type GQReductionTargetByYearInput = {
  reductionTarget: Scalars['Float']['input'];
  targetYearExclusive: Scalars['YearMonth']['input'];
};

export type GQReductionTargetsInfo = {
  __typename?: 'ReductionTargetsInfo';
  heading: Scalars['String']['output'];
  reductionTargets: Array<GQReductionTargetsInfoItem>;
};

export type GQReductionTargetsInfoItem = {
  __typename?: 'ReductionTargetsInfoItem';
  description: Scalars['String']['output'];
  heading: Scalars['String']['output'];
  subheading: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
};

export type GQReferenceDataAncestorRelationship = {
  __typename?: 'ReferenceDataAncestorRelationship';
  upstreamVersionId: Scalars['ID']['output'];
  upstreamVersionSlug: Scalars['String']['output'];
  versionId: Scalars['ID']['output'];
  versionSlug: Scalars['String']['output'];
};

export type GQReferenceDataCitation = {
  __typename?: 'ReferenceDataCitation';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  authorUserId: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  hasLicenseRestrictions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  internalSourceNotes: Scalars['String']['output'];
  internalSourceUrl: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCustomerProvided: Scalars['Boolean']['output'];
  isSupplierSpecific: Scalars['Boolean']['output'];
  org: Maybe<GQOrganization>;
  orgId: Maybe<Scalars['String']['output']>;
  privateDisclosureId: Maybe<Scalars['String']['output']>;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export const GQReferenceDataKind = {
  Composite: 'Composite',
  Translation: 'Translation',
} as const;

export type GQReferenceDataKind =
  (typeof GQReferenceDataKind)[keyof typeof GQReferenceDataKind];
export type GQReferenceDataRevision = {
  __typename?: 'ReferenceDataRevision';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSource: Maybe<GQAssumptionSource>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  internalSourceNotes: Maybe<Scalars['String']['output']>;
  isLatestForOrgAndState: Scalars['Boolean']['output'];
  isOverlay: Maybe<Scalars['Boolean']['output']>;
  isValueMappingLegacyMigration: Maybe<Scalars['Boolean']['output']>;
  kind: Maybe<GQReferenceDataRevisionKind>;
  lastCompatibleSchema: Maybe<Scalars['ID']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  organization: Maybe<GQOrganization>;
  outputFileId: Scalars['ID']['output'];
  revisionName: Scalars['String']['output'];
  rowsAsJson: Maybe<Scalars['JSONString']['output']>;
  schemaJson: Maybe<Scalars['JSONSchema']['output']>;
  schemaLastCheckedAgainst: Maybe<Scalars['ID']['output']>;
  signedParquetOutputUrl: Scalars['String']['output'];
  signedSourceUrl: Maybe<Scalars['String']['output']>;
  source: GQReferenceDataSource;
  sourceFileId: Maybe<Scalars['ID']['output']>;
  sourceId: Scalars['ID']['output'];
  sqlRun: Maybe<GQReferenceDataSql>;
  state: GQReferenceDataRevisionState;
  tableAlias: Maybe<Scalars['String']['output']>;
  updates: Scalars['JSONSchema']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  version: GQReferenceDataVersion;
  versionId: Scalars['ID']['output'];
};

export type GQReferenceDataRevisionConnection = {
  __typename?: 'ReferenceDataRevisionConnection';
  edges: Array<Maybe<GQReferenceDataRevisionEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataRevisionEdge = {
  __typename?: 'ReferenceDataRevisionEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataRevision>;
};

export const GQReferenceDataRevisionKind = {
  CsvUpload: 'CsvUpload',
  SqlQuery: 'SqlQuery',
  SystemGenerated: 'SystemGenerated',
} as const;

export type GQReferenceDataRevisionKind =
  (typeof GQReferenceDataRevisionKind)[keyof typeof GQReferenceDataRevisionKind];
export type GQReferenceDataRevisionRow = {
  __typename?: 'ReferenceDataRevisionRow';
  citationName: Scalars['String']['output'];
  citationNotes: Maybe<Scalars['String']['output']>;
  citationSlug: Scalars['String']['output'];
  citationUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCustomerProvided: Maybe<Scalars['Boolean']['output']>;
  rowFields: Scalars['JSONString']['output'];
};

export const GQReferenceDataRevisionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataRevisionState =
  (typeof GQReferenceDataRevisionState)[keyof typeof GQReferenceDataRevisionState];
export type GQReferenceDataSchema = {
  __typename?: 'ReferenceDataSchema';
  id: Scalars['ID']['output'];
  schemaJson: Scalars['JSONSchema']['output'];
};

export type GQReferenceDataSchemaUpdateValidation = {
  __typename?: 'ReferenceDataSchemaUpdateValidation';
  errors: Array<Scalars['String']['output']>;
  warnings: Array<Scalars['String']['output']>;
};

export type GQReferenceDataSource = {
  __typename?: 'ReferenceDataSource';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  assumptionSources: Maybe<Array<GQAssumptionSource>>;
  author: Maybe<GQUser>;
  createdAt: Scalars['DateTime']['output'];
  externalSourceNotes: Maybe<Scalars['String']['output']>;
  externalSourceUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isCompositeData: Scalars['Boolean']['output'];
  isSyncedWithCliq: Scalars['Boolean']['output'];
  kind: Maybe<GQReferenceDataKind>;
  latestPublishedVersion: Maybe<GQReferenceDataVersion>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  organization: Maybe<GQOrganization>;
  orgsWithRevisions: Maybe<Array<GQOrganization>>;
  referenceDataVersions: Array<GQReferenceDataVersion>;
  tags: Maybe<Array<GQCalculationTag>>;
  updatedAt: Scalars['DateTime']['output'];
  userAuthorId: Maybe<Scalars['ID']['output']>;
};

export type GQReferenceDataSourceLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataSourceOrgsWithRevisionsArgs = {
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceReferenceDataVersionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReferenceDataSourceConnection = {
  __typename?: 'ReferenceDataSourceConnection';
  edges: Array<Maybe<GQReferenceDataSourceEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReferenceDataSourceEdge = {
  __typename?: 'ReferenceDataSourceEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReferenceDataSource>;
};

export type GQReferenceDataSql = {
  __typename?: 'ReferenceDataSql';
  createdAt: Scalars['DateTime']['output'];
  duckdbVersion: Scalars['String']['output'];
  error: Maybe<Scalars['String']['output']>;
  gitHash: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  queryResultFileId: Maybe<Scalars['ID']['output']>;
  revisionId: Maybe<Scalars['ID']['output']>;
  revisionLastUpdatedDate: Maybe<Scalars['DateTime']['output']>;
  revisionState: Maybe<GQReferenceDataRevisionState>;
  signedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  signedParquetRawResultUrl: Maybe<Scalars['String']['output']>;
  sql: Scalars['String']['output'];
  status: Maybe<GQReferenceDataSqlStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  warnings: Maybe<Scalars['JSONString']['output']>;
};

export const GQReferenceDataSqlStatus = {
  Error: 'Error',
  Success: 'Success',
} as const;

export type GQReferenceDataSqlStatus =
  (typeof GQReferenceDataSqlStatus)[keyof typeof GQReferenceDataSqlStatus];
export type GQReferenceDataVersion = {
  __typename?: 'ReferenceDataVersion';
  archivedAt: Maybe<Scalars['DateTime']['output']>;
  author: Maybe<GQUser>;
  canPublish: Scalars['Boolean']['output'];
  citations: Array<GQReferenceDataCitation>;
  compositeDataOrgDetails: Maybe<Array<GQCompositeDataOrgDetail>>;
  createdAt: Scalars['DateTime']['output'];
  hasPublishedRevision: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  latestPublishedRevisionByVersionId: Maybe<GQReferenceDataRevision>;
  latestReferenceDataSqlRun: Maybe<GQReferenceDataSql>;
  latestSchema: Maybe<GQReferenceDataSchema>;
  latestSignedParquetOutputUrl: Maybe<Scalars['String']['output']>;
  referenceDataRevisions: Maybe<Array<GQReferenceDataRevision>>;
  referenceDataSqlRuns: Array<GQReferenceDataSql>;
  slug: Scalars['String']['output'];
  source: GQReferenceDataSource;
  sourceId: Scalars['ID']['output'];
  state: GQReferenceDataVersionState;
  updatedAt: Scalars['DateTime']['output'];
  upstreamReferenceDataVersions: Array<GQReferenceDataVersion>;
  userAuthorId: Maybe<Scalars['ID']['output']>;
  versionName: Scalars['String']['output'];
  versionVintage: Scalars['DateTime']['output'];
};

export type GQReferenceDataVersionLatestPublishedRevisionByVersionIdArgs = {
  anyOrg: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestReferenceDataSqlRunArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionLatestSignedParquetOutputUrlArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataRevisionsArgs = {
  excludeArchived: InputMaybe<Scalars['Boolean']['input']>;
  includeDrafts: InputMaybe<Scalars['Boolean']['input']>;
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export type GQReferenceDataVersionReferenceDataSqlRunsArgs = {
  orgId: InputMaybe<Scalars['ID']['input']>;
};

export const GQReferenceDataVersionState = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type GQReferenceDataVersionState =
  (typeof GQReferenceDataVersionState)[keyof typeof GQReferenceDataVersionState];
export type GQReferenceValueDetails = {
  __typename?: 'ReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  refDataCellPath: Scalars['String']['output'];
};

export type GQRefreshAssetYearEstimationsInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearIds: Array<Scalars['ID']['input']>;
};

export type GQRefreshAssetYearEstimationsPayload = {
  __typename?: 'RefreshAssetYearEstimationsPayload';
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQRefreshMarketplacePurchaseInput = {
  eacMetadata?: InputMaybe<GQUpdateMarketplacePurchaseEacMetadataInput>;
  id: Scalars['ID']['input'];
  offerExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  status: GQMarketplacePurchaseStatus;
};

export type GQRefreshReportPayload = {
  __typename?: 'RefreshReportPayload';
  report: GQReport;
  reportGenerationJobId: Maybe<Scalars['String']['output']>;
};

export const GQRefrigerantLeakageSource = {
  InstalledEquipment: 'InstalledEquipment',
  Manufacturing: 'Manufacturing',
} as const;

export type GQRefrigerantLeakageSource =
  (typeof GQRefrigerantLeakageSource)[keyof typeof GQRefrigerantLeakageSource];
export type GQRegenerateAuditReportInput = {
  footprintAuditBundleId: Scalars['ID']['input'];
};

/** The region that the organization is in. This dictates where their data is stores and processed. */
export const GQRegion = {
  Eu: 'eu',
  Us: 'us',
} as const;

export type GQRegion = (typeof GQRegion)[keyof typeof GQRegion];
export type GQRejectDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRejectDataPayload = {
  __typename?: 'RejectDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRejectReportQuestionInstanceInput = {
  comment: Scalars['String']['input'];
  reportQuestionInstanceId: Scalars['ID']['input'];
};

export type GQRejectReportQuestionInstancePayload = {
  __typename?: 'RejectReportQuestionInstancePayload';
  reportQuestionInstance: GQReportQuestionInstance;
  success: Scalars['Boolean']['output'];
};

export type GQRemoveCompanyFromPeerCompanyGroupInput = {
  companyId: Scalars['ID']['input'];
  peerCompanyGroupId: Scalars['ID']['input'];
};

export type GQRemoveCompanyFromPeerCompanyGroupPayload = {
  __typename?: 'RemoveCompanyFromPeerCompanyGroupPayload';
  peerCompanyGroup: Maybe<GQPeerCompanyGroup>;
};

export type GQRemoveUserInput = {
  userId: Scalars['ID']['input'];
};

export type GQRemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  user: GQUser;
};

export type GQRenameBiSavedViewInput = {
  name: Scalars['String']['input'];
  stableId: Scalars['ID']['input'];
};

export type GQRenameFootprintInput = {
  footprintId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQRenameFootprintPayload = {
  __typename?: 'RenameFootprintPayload';
  footprint: GQFootprint;
};

export type GQRenameReportInput = {
  newName: Scalars['String']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQRenameReportPayload = {
  __typename?: 'RenameReportPayload';
  report: GQReport;
};

export type GQRenewableEnergyPercentagePoint = {
  __typename?: 'RenewableEnergyPercentagePoint';
  renewableEnergyFraction: Scalars['Float']['output'];
  yearStart: Scalars['YearMonth']['output'];
};

export type GQReport = GQClimateProgramTimelineObject &
  GQIdInterface &
  GQPermissionObject & {
    __typename?: 'Report';
    cdpApiRegistration: GQCdpApiRegistrationPayload;
    config: GQReportConfig;
    createdAt: Scalars['DateTime']['output'];
    csrdDataRequirementsConfig: Maybe<Scalars['JSONString']['output']>;
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    downloadableDocxUrl: Scalars['String']['output'];
    downloadableXlsxUrl: Scalars['String']['output'];
    editedAt: Scalars['DateTime']['output'];
    footprintSnapshot: Maybe<GQFootprintSnapshot>;
    id: Scalars['ID']['output'];
    isHidden: Maybe<Scalars['Boolean']['output']>;
    latestCdpSyncWorkflowStatus: Maybe<GQCdpSyncWorkflowStatusPayload>;
    name: Scalars['String']['output'];
    organization: GQOrganization;
    publishedAt: Maybe<Scalars['DateTime']['output']>;
    reportKind: Scalars['String']['output'];
    reportStatus: GQReportStatus;
    updatedAt: Scalars['DateTime']['output'];
    /** User with access to this report and their level of access */
    userAccess: Array<GQReportUserAccess>;
    warnings: Array<GQReportAnswerWarning>;
  };

export type GQReportAccessOriginWithMetadata = {
  __typename?: 'ReportAccessOriginWithMetadata';
  isGlobal: Scalars['Boolean']['output'];
  permission: GQPermissionType;
};

export type GQReportAllQuestionsInput = {
  includeComputedItems?: InputMaybe<Scalars['Boolean']['input']>;
  includeInputs?: InputMaybe<Scalars['Boolean']['input']>;
  includeItems?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQReportAnswer = {
  __typename?: 'ReportAnswer';
  cdpSyncEvents: Array<GQCdpSyncQuestionStatus>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  editorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputJson: Scalars['JSONString']['output'];
  latestCdpSyncEvent: Maybe<GQCdpSyncQuestionStatus>;
  outputJson: Maybe<Scalars['JSONString']['output']>;
  question: GQReportQuestion;
  report: GQReport;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerCreatedByUserChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportAnswerCreatedByUserChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      inputJson: Maybe<Scalars['JSONString']['output']>;
      outputJson: Maybe<Scalars['JSONString']['output']>;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportAnswerHistoryConnection = {
  __typename?: 'ReportAnswerHistoryConnection';
  edges: Array<Maybe<GQReportAnswerHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerHistoryEdge = {
  __typename?: 'ReportAnswerHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswer>;
};

export type GQReportAnswerOverride = {
  __typename?: 'ReportAnswerOverride';
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  editorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  outputJson: Scalars['JSONString']['output'];
  question: GQReportQuestion;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportAnswerOverrideHistoryConnection = {
  __typename?: 'ReportAnswerOverrideHistoryConnection';
  edges: Array<Maybe<GQReportAnswerOverrideHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerOverrideHistoryEdge = {
  __typename?: 'ReportAnswerOverrideHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerOverride>;
};

export const GQReportAnswerOverrideState = {
  UseDefault: 'UseDefault',
  UseOverride: 'UseOverride',
} as const;

export type GQReportAnswerOverrideState =
  (typeof GQReportAnswerOverrideState)[keyof typeof GQReportAnswerOverrideState];
export type GQReportAnswerOverrideStateHistoryConnection = {
  __typename?: 'ReportAnswerOverrideStateHistoryConnection';
  edges: Array<Maybe<GQReportAnswerOverrideStateHistoryEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportAnswerOverrideStateHistoryEdge = {
  __typename?: 'ReportAnswerOverrideStateHistoryEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportAnswerOverrideStateLogEvent>;
};

export type GQReportAnswerOverrideStateLogEvent = {
  __typename?: 'ReportAnswerOverrideStateLogEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  id: Scalars['ID']['output'];
  overrideState: GQReportAnswerOverrideState;
};

export type GQReportAnswerWarning = {
  __typename?: 'ReportAnswerWarning';
  acknowledgedBy: Maybe<GQUser>;
  answer: GQReportAnswer;
  id: Scalars['ID']['output'];
  outputJson: Scalars['JSONString']['output'];
  reportId: Scalars['String']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportAttachment = {
  __typename?: 'ReportAttachment';
  authorId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  reportId: Scalars['ID']['output'];
  reportQuestionId: Scalars['ID']['output'];
};

export type GQReportAttachmentItem = {
  __typename?: 'ReportAttachmentItem';
  authorId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  fmdId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kind: GQReportAttachmentItemKind;
  name: Maybe<Scalars['String']['output']>;
  orgId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Maybe<Scalars['String']['output']>;
};

export const GQReportAttachmentItemKind = {
  File: 'File',
  Url: 'Url',
} as const;

export type GQReportAttachmentItemKind =
  (typeof GQReportAttachmentItemKind)[keyof typeof GQReportAttachmentItemKind];
export const GQReportCalloutIcon = {
  Error: 'Error',
  Info: 'Info',
  Question: 'Question',
  Warning: 'Warning',
} as const;

export type GQReportCalloutIcon =
  (typeof GQReportCalloutIcon)[keyof typeof GQReportCalloutIcon];
export type GQReportConfig = {
  __typename?: 'ReportConfig';
  allContainers: Array<GQReportQuestionContainer>;
  allQuestions: Array<GQReportQuestion>;
  allVisibleInputs: Array<GQReportQuestion>;
  allVisibleItems: Array<GQReportQuestionOrContainer>;
  allVisibleQuestions: Array<GQReportQuestion>;
  brandColor: Scalars['String']['output'];
  computedItemIds: Array<Scalars['ID']['output']>;
  computedItems: Array<GQReportQuestion>;
  containers: Array<GQReportQuestionContainer>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  /** @deprecated Unused field */
  hasReductionPlan: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inputIds: Array<Scalars['ID']['output']>;
  inputs: Array<GQReportQuestion>;
  isCorporateReport: Scalars['Boolean']['output'];
  isCreationBlocked: Scalars['Boolean']['output'];
  /** @deprecated Unused field */
  isDirectEditLocked: Maybe<Scalars['Boolean']['output']>;
  isFinanceReport: Scalars['Boolean']['output'];
  isHiddenFromOverview: Scalars['Boolean']['output'];
  isRegulatoryExposureBlocked: Scalars['Boolean']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  /** @deprecated Use containers or questions instead. */
  items: Array<GQReportQuestionOrContainer>;
  lastLintedAt: Maybe<Scalars['DateTime']['output']>;
  latestComponentUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  latestLinterStatus: Maybe<GQConfigLinterStatus>;
  longName: Scalars['String']['output'];
  questions: Array<GQReportQuestion>;
  reportObjectives: Array<GQCanonicalReportObjective>;
  reportType: Scalars['String']['output'];
  requiredPermissions: Array<GQPermissionType>;
  shortName: Scalars['String']['output'];
  status: Maybe<GQReportConfigStatus>;
  updatedAt: Scalars['DateTime']['output'];
  useApprovals: Scalars['Boolean']['output'];
  useMateriality: Scalars['Boolean']['output'];
  useOverrides: Scalars['Boolean']['output'];
  useReportingIntention: Scalars['Boolean']['output'];
  useTableDisplay: Maybe<Scalars['Boolean']['output']>;
};

export type GQReportConfigAllQuestionsArgs = {
  input: InputMaybe<GQReportAllQuestionsInput>;
};

export type GQReportConfigAllVisibleInputsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigAllVisibleItemsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigAllVisibleQuestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportConfigComputedItemsArgs = {
  filter: InputMaybe<GQReportQuestionFilter>;
};

export type GQReportConfigLatestComponentUpdatedAtArgs = {
  id: Scalars['ID']['input'];
};

export type GQReportConfigConnection = {
  __typename?: 'ReportConfigConnection';
  edges: Array<Maybe<GQReportConfigEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportConfigEdge = {
  __typename?: 'ReportConfigEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportConfig>;
};

export type GQReportConfigImportBehaviorFlagsInput = {
  deleteUnusedItems: Scalars['Boolean']['input'];
  modifyExistingItems: Scalars['Boolean']['input'];
};

export type GQReportConfigSrcPayload = {
  __typename?: 'ReportConfigSrcPayload';
  lintStatus: GQConfigLinterStatus;
  src: Scalars['JSONString']['output'];
};

export const GQReportConfigStatus = {
  Beta: 'Beta',
  ComingSoon: 'ComingSoon',
  Inactive: 'Inactive',
  New: 'New',
} as const;

export type GQReportConfigStatus =
  (typeof GQReportConfigStatus)[keyof typeof GQReportConfigStatus];
export type GQReportConnection = {
  __typename?: 'ReportConnection';
  edges: Array<Maybe<GQReportEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportEdge = {
  __typename?: 'ReportEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReport>;
};

export type GQReportExplanation = {
  __typename?: 'ReportExplanation';
  markdownText: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQReportInput = {
  __typename?: 'ReportInput';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportItemCallout = GQIReportItem & {
  __typename?: 'ReportItemCallout';
  callout: Maybe<Scalars['String']['output']>;
  icon: Maybe<GQReportCalloutIcon>;
  id: Scalars['ID']['output'];
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportItemKind = {
  Callout: 'CALLOUT',
  SecrStandardTable: 'SECR_STANDARD_TABLE',
  SecrTable: 'SECR_TABLE',
  SingleValue: 'SINGLE_VALUE',
  StandardTable: 'STANDARD_TABLE',
} as const;

export type GQReportItemKind =
  (typeof GQReportItemKind)[keyof typeof GQReportItemKind];
export type GQReportItemSecrStandardTable = GQIReportItem & {
  __typename?: 'ReportItemSecrStandardTable';
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQReportItemSingleValue = GQIReportItem & {
  __typename?: 'ReportItemSingleValue';
  explanation: Maybe<GQReportExplanation>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  sublabel: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type GQReportItemStandardTable = GQIReportItem & {
  __typename?: 'ReportItemStandardTable';
  columns: Array<GQReportTableColumn>;
  id: Scalars['ID']['output'];
  isFullWidth: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  optional: Maybe<Scalars['Boolean']['output']>;
  optionalMessage: Maybe<Scalars['String']['output']>;
  rows: Array<GQReportTableRow>;
  sublabel: Maybe<Scalars['String']['output']>;
};

export type GQReportKindWithReason = {
  __typename?: 'ReportKindWithReason';
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  reason: Maybe<Scalars['String']['output']>;
  reportingYear: Maybe<Scalars['Int']['output']>;
};

export const GQReportMode = {
  Corporate: 'Corporate',
  Finance: 'Finance',
} as const;

export type GQReportMode = (typeof GQReportMode)[keyof typeof GQReportMode];
export type GQReportObjectConfigPathItem = {
  __typename?: 'ReportObjectConfigPathItem';
  id: Maybe<Scalars['ID']['output']>;
  isComputed: Scalars['Boolean']['output'];
  isInput: Scalars['Boolean']['output'];
  label: Maybe<Scalars['String']['output']>;
};

export const GQReportObjectType = {
  Module: 'module',
  Question: 'question',
} as const;

export type GQReportObjectType =
  (typeof GQReportObjectType)[keyof typeof GQReportObjectType];
export const GQReportPermissionAction = {
  Edit: 'EDIT',
  View: 'VIEW',
} as const;

export type GQReportPermissionAction =
  (typeof GQReportPermissionAction)[keyof typeof GQReportPermissionAction];
export type GQReportPermissionActionContainer = {
  __typename?: 'ReportPermissionActionContainer';
  originsWithMetadata: Array<GQReportAccessOriginWithMetadata>;
};

export type GQReportQuestion = {
  __typename?: 'ReportQuestion';
  allowNotes: Scalars['Boolean']['output'];
  allowedAttachmentKinds: Maybe<Array<GQReportAttachmentItemKind>>;
  answer: Maybe<GQReportAnswer>;
  answerOverride: Maybe<GQReportAnswerOverride>;
  assignees: Array<GQUser>;
  cdpSyncHistory: GQCdpSyncQuestionHistoryConnection;
  componentId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  dependentItems: GQDependentReportItems;
  /** @deprecated use parentContainer label instead */
  displayName: Maybe<Scalars['String']['output']>;
  dynamicInputJson: Maybe<Scalars['JSONString']['output']>;
  externalAnswerSuggestions: Array<GQExternalReportAnswerSuggestion>;
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  history: GQReportAnswerHistoryConnection;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  identifierId: Scalars['String']['output'];
  instance: GQReportQuestionInstance;
  isComputed: Scalars['Boolean']['output'];
  isDynamic: Maybe<Scalars['Boolean']['output']>;
  materiality: Maybe<GQMaterialityStatus>;
  notes: Maybe<GQDiscussion>;
  overrideHistory: GQReportAnswerOverrideHistoryConnection;
  overrideState: GQReportAnswerOverrideState;
  overrideStateHistory: GQReportAnswerOverrideStateHistoryConnection;
  parentContainer: Maybe<GQReportQuestionContainer>;
  path: Array<GQReportObjectConfigPathItem>;
  peerExternalReportAnswers: Maybe<GQPeerExternalReportAnswers>;
  questionsWithMatchingOutputSchema: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedInputs: GQResolvedReportQuestionInputs;
  resolvedReportingIntention: Maybe<GQResolvedReportingIntention>;
  resolvedStatus: GQResolvedReportQuestionStatus;
  resolvedStatusIncludingContainer: GQResolvedReportQuestionStatus;
  resolvedWarnings: Array<GQReportAnswerWarning>;
  staticInputJson: Maybe<Scalars['JSONString']['output']>;
  statusJson: Scalars['JSONString']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportQuestionAnswerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAnswerOverrideArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionCdpSyncHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionDependentItemsArgs = {
  filter: InputMaybe<GQDependentQuestionFilter>;
};

export type GQReportQuestionExternalAnswerSuggestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionInstanceArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionMaterialityArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionNotesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideStateArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionOverrideStateHistoryArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedInputsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedReportingIntentionArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedStatusIncludingContainerArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionActorChangelogEvent = {
  actor: GQUser;
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionApprovalActionChangelogEvent = {
  actor: GQUser;
  comment: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
  previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionApprovedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionApprovedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export const GQReportQuestionApproverAction = {
  Add: 'ADD',
  Remove: 'REMOVE',
} as const;

export type GQReportQuestionApproverAction =
  (typeof GQReportQuestionApproverAction)[keyof typeof GQReportQuestionApproverAction];
export type GQReportQuestionApproverActionInput = {
  action: GQReportQuestionApproverAction;
  approverId: Scalars['ID']['input'];
};

export type GQReportQuestionApproversUpdatedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionApproversUpdatedChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      newApprovers: Array<GQUser>;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovers: Array<GQUser>;
      timestamp: Scalars['DateTime']['output'];
    };

export const GQReportQuestionAssigneeAction = {
  Assign: 'ASSIGN',
  Unassign: 'UNASSIGN',
} as const;

export type GQReportQuestionAssigneeAction =
  (typeof GQReportQuestionAssigneeAction)[keyof typeof GQReportQuestionAssigneeAction];
export type GQReportQuestionAssigneeActionInput = {
  action: GQReportQuestionAssigneeAction;
  assigneeId: Scalars['ID']['input'];
  grant?: InputMaybe<GQReportQuestionAssigneePermissionGrant>;
};

export const GQReportQuestionAssigneePermissionGrant = {
  EditReportQuestionInstance: 'EditReportQuestionInstance',
  ViewReportQuestionInstance: 'ViewReportQuestionInstance',
} as const;

export type GQReportQuestionAssigneePermissionGrant =
  (typeof GQReportQuestionAssigneePermissionGrant)[keyof typeof GQReportQuestionAssigneePermissionGrant];
export type GQReportQuestionAssigneesUpdatedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionAssigneesUpdatedChangelogEvent';
      actor: GQUser;
      id: Scalars['ID']['output'];
      newAssignees: Array<GQUser>;
      previousAssignees: Array<GQUser>;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionChangelogEvent = {
  id: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type GQReportQuestionContainer = {
  __typename?: 'ReportQuestionContainer';
  allVisibleContainers: Array<GQReportQuestionContainer>;
  allVisibleQuestions: Array<GQReportQuestion>;
  assignees: Array<GQUser>;
  automaticProgress: GQResolvedContainerProgress;
  containers: Array<GQReportQuestionContainer>;
  description: Maybe<Scalars['String']['output']>;
  fullWidth: Scalars['Boolean']['output'];
  guidanceJson: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  itemIds: Array<Scalars['ID']['output']>;
  items: Array<GQReportQuestionOrContainer>;
  label: Maybe<Scalars['String']['output']>;
  overriddenProgress: Maybe<GQResolvedContainerProgress>;
  parentContainer: Maybe<GQReportQuestionContainer>;
  questionsWithResolvedWarnings: Array<GQReportQuestion>;
  reportConfig: GQReportConfig;
  reportConfigId: Scalars['ID']['output'];
  resolvedStatus: GQResolvedReportQuestionStatus;
  statusJson: Scalars['JSONString']['output'];
};

export type GQReportQuestionContainerAllVisibleContainersArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAllVisibleQuestionsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAssigneesArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerAutomaticProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerOverriddenProgressArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerQuestionsWithResolvedWarningsArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionContainerResolvedStatusArgs = {
  reportId: Scalars['ID']['input'];
};

export type GQReportQuestionFilter = {
  componentId?: InputMaybe<Scalars['String']['input']>;
};

export type GQReportQuestionIdentifier = {
  __typename?: 'ReportQuestionIdentifier';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
};

export type GQReportQuestionIdentifierConnection = {
  __typename?: 'ReportQuestionIdentifierConnection';
  edges: Array<Maybe<GQReportQuestionIdentifierEdge>>;
  pageInfo: GQPageInfo;
};

export type GQReportQuestionIdentifierEdge = {
  __typename?: 'ReportQuestionIdentifierEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQReportQuestionIdentifier>;
};

export type GQReportQuestionIdentifierMapping = {
  __typename?: 'ReportQuestionIdentifierMapping';
  id: Scalars['ID']['output'];
  questionIdentifier: GQReportQuestionIdentifier;
  relatedQuestionIdentifiers: Array<GQReportQuestionIdentifier>;
};

export type GQReportQuestionInstance = GQIdInterface &
  GQPermissionObject & {
    __typename?: 'ReportQuestionInstance';
    approvalInfo: Maybe<GQReportQuestionInstanceApprovalInfo>;
    attachmentItems: Array<GQReportAttachmentItem>;
    canAnswer: Scalars['Boolean']['output'];
    canAssign: Scalars['Boolean']['output'];
    canSetApprovers: Scalars['Boolean']['output'];
    changelog: GQReportQuestionInstanceChangelogConnection;
    id: Scalars['ID']['output'];
    lastUpdatedAt: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    question: GQReportQuestion;
    subtitle: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    /** User with access to this RQI and their level of access */
    userAccess: Array<GQReportQuestionInstanceUserAccess>;
  };

export type GQReportQuestionInstanceChangelogArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export const GQReportQuestionInstanceApprovalAction = {
  ChangeResponse: 'ChangeResponse',
  Respond: 'Respond',
  Submit: 'Submit',
  Withdraw: 'Withdraw',
} as const;

export type GQReportQuestionInstanceApprovalAction =
  (typeof GQReportQuestionInstanceApprovalAction)[keyof typeof GQReportQuestionInstanceApprovalAction];
/** Contains information about a report question instance's approvals */
export type GQReportQuestionInstanceApprovalInfo = {
  __typename?: 'ReportQuestionInstanceApprovalInfo';
  approvers: Array<GQReportQuestionInstanceApprover>;
  /** Action suggested to the current user. This might be 'Respond' if user is an approver and the question is waiting for review or null if no action is required by the current user */
  currentUserAction: Maybe<GQReportQuestionInstanceApprovalAction>;
  /** The approval status for the review of the current user. */
  currentUserApprovalRequestStatus: GQReportQuestionInstanceApprovalRequestStatus;
  id: Scalars['ID']['output'];
  isLocked: Scalars['Boolean']['output'];
  status: GQReportQuestionInstanceApprovalStatus;
};

export const GQReportQuestionInstanceApprovalRequestStatus = {
  Approved: 'Approved',
  NotRequested: 'NotRequested',
  Pending: 'Pending',
  Rejected: 'Rejected',
} as const;

export type GQReportQuestionInstanceApprovalRequestStatus =
  (typeof GQReportQuestionInstanceApprovalRequestStatus)[keyof typeof GQReportQuestionInstanceApprovalRequestStatus];
export const GQReportQuestionInstanceApprovalStatus = {
  Approved: 'Approved',
  NotSubmitted: 'NotSubmitted',
  Rejected: 'Rejected',
  Submitted: 'Submitted',
} as const;

export type GQReportQuestionInstanceApprovalStatus =
  (typeof GQReportQuestionInstanceApprovalStatus)[keyof typeof GQReportQuestionInstanceApprovalStatus];
/** Represents an approver for a report question instance */
export type GQReportQuestionInstanceApprover = {
  __typename?: 'ReportQuestionInstanceApprover';
  comment: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: GQReportQuestionInstanceApprovalRequestStatus;
  user: GQUser;
};

export type GQReportQuestionInstanceChangelogConnection = {
  __typename?: 'ReportQuestionInstanceChangelogConnection';
  edges: Array<GQReportQuestionInstanceChangelogEdge>;
  pageInfo: GQPageInfo;
};

export type GQReportQuestionInstanceChangelogEdge = {
  __typename?: 'ReportQuestionInstanceChangelogEdge';
  cursor: Scalars['String']['output'];
  node:
    | GQReportAnswerCreatedByUserChangelogEvent
    | GQReportQuestionApprovedChangelogEvent
    | GQReportQuestionApproversUpdatedChangelogEvent
    | GQReportQuestionAssigneesUpdatedChangelogEvent
    | GQReportQuestionRejectedChangelogEvent
    | GQReportQuestionSubmittedChangelogEvent
    | GQReportQuestionWithdrawnChangelogEvent;
};

export type GQReportQuestionInstancePayload = {
  __typename?: 'ReportQuestionInstancePayload';
  reportId: Maybe<Scalars['String']['output']>;
  reportQuestionId: Maybe<Scalars['String']['output']>;
};

export const GQReportQuestionInstancePermissionAction = {
  Edit: 'EDIT',
  View: 'VIEW',
} as const;

export type GQReportQuestionInstancePermissionAction =
  (typeof GQReportQuestionInstancePermissionAction)[keyof typeof GQReportQuestionInstancePermissionAction];
/** Represents the permissions a user has on a report question instance */
export type GQReportQuestionInstancePermissionActionContainer = {
  __typename?: 'ReportQuestionInstancePermissionActionContainer';
  action: GQReportQuestionInstancePermissionAction;
  origin: Maybe<GQPermissionType>;
};

/** Represents a user granted access to a report question instance */
export type GQReportQuestionInstanceUserAccess = {
  __typename?: 'ReportQuestionInstanceUserAccess';
  actions: Array<GQReportQuestionInstancePermissionActionContainer>;
  id: Scalars['ID']['output'];
  user: GQUser;
};

export type GQReportQuestionInstancesAggregate = {
  __typename?: 'ReportQuestionInstancesAggregate';
  userAccesses: Array<GQReportQuestionInstancesAggregateUserAccess>;
};

export type GQReportQuestionInstancesAggregatePermissionActionContainer = {
  __typename?: 'ReportQuestionInstancesAggregatePermissionActionContainer';
  count: Scalars['Int']['output'];
  origins: Array<GQPermissionType>;
  originsWithMetadata: Array<GQReportingAggregateOriginWithMetadata>;
};

export type GQReportQuestionInstancesAggregateUserAccess = {
  __typename?: 'ReportQuestionInstancesAggregateUserAccess';
  edit: Maybe<GQReportQuestionInstancesAggregatePermissionActionContainer>;
  user: GQUser;
  view: Maybe<GQReportQuestionInstancesAggregatePermissionActionContainer>;
};

export const GQReportQuestionNoteItemStatus = {
  Done: 'Done',
  NeedsUserResponse: 'NeedsUserResponse',
} as const;

export type GQReportQuestionNoteItemStatus =
  (typeof GQReportQuestionNoteItemStatus)[keyof typeof GQReportQuestionNoteItemStatus];
export type GQReportQuestionOrContainer =
  | GQReportQuestion
  | GQReportQuestionContainer;

export type GQReportQuestionRejectedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionRejectedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionSeriesMetric = {
  __typename?: 'ReportQuestionSeriesMetric';
  series: Maybe<Array<GQReportQuestionSeriesMetricItem>>;
};

export type GQReportQuestionSeriesMetricItem = {
  __typename?: 'ReportQuestionSeriesMetricItem';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type GQReportQuestionSingleValueMetric = {
  __typename?: 'ReportQuestionSingleValueMetric';
  value: Maybe<Scalars['String']['output']>;
};

export const GQReportQuestionStatus = {
  Blocked: 'Blocked',
  Excluded: 'Excluded',
  Optional: 'Optional',
  Required: 'Required',
} as const;

export type GQReportQuestionStatus =
  (typeof GQReportQuestionStatus)[keyof typeof GQReportQuestionStatus];
export type GQReportQuestionSubmittedChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionSubmittedChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportQuestionWithdrawnChangelogEvent =
  GQReportQuestionActorChangelogEvent &
    GQReportQuestionApprovalActionChangelogEvent &
    GQReportQuestionChangelogEvent & {
      __typename?: 'ReportQuestionWithdrawnChangelogEvent';
      actor: GQUser;
      comment: Maybe<Scalars['String']['output']>;
      id: Scalars['ID']['output'];
      isLocked: Scalars['Boolean']['output'];
      newApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      previousApprovalStatus: GQReportQuestionInstanceApprovalStatus;
      timestamp: Scalars['DateTime']['output'];
    };

export type GQReportSection = {
  __typename?: 'ReportSection';
  id: Scalars['ID']['output'];
  isCollapsible: Maybe<Scalars['Boolean']['output']>;
  itemNumber: Maybe<Scalars['String']['output']>;
  items: Array<
    | GQReportItemCallout
    | GQReportItemSecrStandardTable
    | GQReportItemSingleValue
    | GQReportItemStandardTable
  >;
  label: Maybe<Scalars['String']['output']>;
  sectionId: Scalars['String']['output'];
  sublabel: Maybe<Scalars['String']['output']>;
};

export const GQReportStatus = {
  Editing: 'Editing',
  Failed: 'Failed',
  Locked: 'Locked',
  Processing: 'Processing',
  Published: 'Published',
  Submitted: 'Submitted',
} as const;

export type GQReportStatus =
  (typeof GQReportStatus)[keyof typeof GQReportStatus];
export type GQReportTableColumn = {
  __typename?: 'ReportTableColumn';
  alignment: Maybe<GQTableCellAlignment>;
  isMarkdown: Maybe<Scalars['Boolean']['output']>;
  label: Maybe<Scalars['String']['output']>;
  minWidthPx: Maybe<Scalars['Int']['output']>;
};

export type GQReportTableRow = {
  __typename?: 'ReportTableRow';
  colspans: Maybe<Array<Scalars['Int']['output']>>;
  explanations: Maybe<Array<Maybe<GQReportExplanation>>>;
  strong: Maybe<Scalars['Boolean']['output']>;
  values: Array<Scalars['String']['output']>;
};

/** Represents a user granted access to a report */
export type GQReportUserAccess = {
  __typename?: 'ReportUserAccess';
  edit: Maybe<GQReportPermissionActionContainer>;
  user: GQUser;
  view: Maybe<GQReportPermissionActionContainer>;
};

export type GQReportWithConfig = {
  __typename?: 'ReportWithConfig';
  config: GQReportConfig;
  id: Scalars['ID']['output'];
  report: GQReport;
};

export type GQReportingAggregateOriginWithMetadata = {
  __typename?: 'ReportingAggregateOriginWithMetadata';
  isGlobal: Scalars['Boolean']['output'];
  permission: GQPermissionType;
};

export type GQReportingFootprintHealthCheckFailure = {
  __typename?: 'ReportingFootprintHealthCheckFailure';
  outputJson: Scalars['JSONString']['output'];
  validatorId: Scalars['String']['output'];
};

export type GQReportingFootprintHealthChecksInput = {
  footprintSnapshotId: Scalars['ID']['input'];
  interval?: InputMaybe<Scalars['YMInterval']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQReportingFootprintHealthChecksPayload = {
  __typename?: 'ReportingFootprintHealthChecksPayload';
  failures: Array<GQReportingFootprintHealthCheckFailure>;
  footprintSnapshotId: Scalars['ID']['output'];
  interval: Scalars['YMInterval']['output'];
};

export type GQReportingFramework = {
  __typename?: 'ReportingFramework';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GQReportingFrameworkDataPoint = {
  __typename?: 'ReportingFrameworkDataPoint';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  reportingFramework: GQReportingFramework;
  updatedAt: Scalars['DateTime']['output'];
};

export const GQReportingIntention = {
  IsNotReporting: 'IsNotReporting',
  IsReporting: 'IsReporting',
} as const;

export type GQReportingIntention =
  (typeof GQReportingIntention)[keyof typeof GQReportingIntention];
export type GQReportingIntentionLogEvent = {
  __typename?: 'ReportingIntentionLogEvent';
  createdAt: Scalars['DateTime']['output'];
  createdBy: GQUser;
  reason: Maybe<Scalars['String']['output']>;
};

export type GQRepresentativeNaicsCode = GQIdInterface & {
  __typename?: 'RepresentativeNaicsCode';
  companyId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  naicsCode: Scalars['String']['output'];
};

export type GQRepresentativeNaicsCodeConnection = {
  __typename?: 'RepresentativeNaicsCodeConnection';
  edges: Array<Maybe<GQRepresentativeNaicsCodeEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRepresentativeNaicsCodeEdge = {
  __typename?: 'RepresentativeNaicsCodeEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRepresentativeNaicsCode>;
};

export type GQRequestDataApprovalInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRequestDataApprovalPayload = {
  __typename?: 'RequestDataApprovalPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRequestHelpInput = {
  message: Scalars['String']['input'];
};

export type GQRequestHelpPayload = {
  __typename?: 'RequestHelpPayload';
  status: Scalars['String']['output'];
};

export type GQRequestUnlockDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQRequestUnlockDataPayload = {
  __typename?: 'RequestUnlockDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQRerunBartForPresetTagWorkflowInput = {
  ingestionCustomFieldDatasourceId: Scalars['ID']['input'];
};

export type GQRerunBartForPresetTagWorkflowOutput = {
  __typename?: 'RerunBartForPresetTagWorkflowOutput';
  userUploadTasks: Array<GQUserUploadTask>;
  workflowId: Scalars['String']['output'];
};

export type GQResendUserInviteLinkInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQResendUserInviteLinkPayload = {
  __typename?: 'ResendUserInviteLinkPayload';
  user: GQUser;
};

export type GQResetLockedUserInput = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQResetLockedUserPayload = {
  __typename?: 'ResetLockedUserPayload';
  user: GQUser;
};

export type GQResolveDataIssueInput = {
  id: Scalars['ID']['input'];
};

export type GQResolveDataIssuePayload = {
  __typename?: 'ResolveDataIssuePayload';
  issue: GQDataIssue;
};

export type GQResolveDiscussionInput = {
  discussionId: Scalars['ID']['input'];
};

export type GQResolveDiscussionPayload = {
  __typename?: 'ResolveDiscussionPayload';
  discussion: GQDiscussion;
};

export const GQResolvedContainerProgress = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotApplicable: 'NotApplicable',
  NotStarted: 'NotStarted',
  OptedOut: 'OptedOut',
} as const;

export type GQResolvedContainerProgress =
  (typeof GQResolvedContainerProgress)[keyof typeof GQResolvedContainerProgress];
export type GQResolvedReportQuestionInputs =
  | GQResolvedReportQuestionInputsFailure
  | GQResolvedReportQuestionInputsSuccess;

export type GQResolvedReportQuestionInputsFailure = {
  __typename?: 'ResolvedReportQuestionInputsFailure';
  errorMessage: Maybe<Scalars['String']['output']>;
  questionIds: Array<Scalars['ID']['output']>;
};

export type GQResolvedReportQuestionInputsSuccess = {
  __typename?: 'ResolvedReportQuestionInputsSuccess';
  inputs: Scalars['JSONString']['output'];
  unparsedInputs: Scalars['JSONString']['output'];
};

export type GQResolvedReportQuestionStatus = {
  __typename?: 'ResolvedReportQuestionStatus';
  blockingMessage: Maybe<Scalars['String']['output']>;
  excludingQuestionId: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  status: GQReportQuestionStatus;
};

export type GQResolvedReportingIntention = {
  __typename?: 'ResolvedReportingIntention';
  event: Maybe<GQReportingIntentionLogEvent>;
  id: Scalars['ID']['output'];
  intention: GQReportingIntention;
};

export type GQRespondToDataIssueInput = {
  id: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQRespondToDataIssuePayload = {
  __typename?: 'RespondToDataIssuePayload';
  issue: GQDataIssue;
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQRespondToDiscussionInput = {
  discussionId: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  message: Scalars['String']['input'];
  shouldNotifyWatershed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQRespondToDiscussionPayload = {
  __typename?: 'RespondToDiscussionPayload';
  discussion: GQDiscussion;
};

export type GQRestoreDeletedSavedViewInput = {
  stableId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type GQRevenueSourceOption = {
  __typename?: 'RevenueSourceOption';
  currencyCode: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  year: Scalars['Int']['output'];
};

export type GQReviewFinancialsReviewItemInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  reviewItemId: Scalars['ID']['input'];
};

export type GQReviewFinancialsReviewItemPayload = {
  __typename?: 'ReviewFinancialsReviewItemPayload';
  financialsUserUploadTasks: Array<GQUserUploadTask>;
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  reviewItem: GQFinancialsReviewItem;
};

export type GQRevokePermissionItemInput = {
  permissionItemId: Scalars['ID']['input'];
};

export type GQRevokePermissionItemPayload = {
  __typename?: 'RevokePermissionItemPayload';
  permissionItem: GQPermissionItem;
};

export type GQRevokeUserAppSessionsInput = {
  userId: Scalars['ID']['input'];
};

export type GQRevokeUserAppSessionsPayload = {
  __typename?: 'RevokeUserAppSessionsPayload';
  user: GQUser;
};

export type GQRevokeUserRoleInput = {
  orgAccessId: Scalars['ID']['input'];
  orgId?: InputMaybe<Scalars['ID']['input']>;
  roleId: Scalars['ID']['input'];
};

export type GQRevokeUserRolePayload = {
  __typename?: 'RevokeUserRolePayload';
  userRoleAssignment: Maybe<GQUserRoleAssignment>;
};

export type GQRole = {
  __typename?: 'Role';
  apiKeys: Array<GQApiKeyRoleAssignment>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Maybe<GQUser>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  org: GQOrganization;
  permissionOrigin: Maybe<GQRolePermissionOrigin>;
  permissions: Array<GQPermissionItem>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<GQUserRoleAssignment>;
  watershedManaged: Scalars['Boolean']['output'];
  workosGroupId: Maybe<Scalars['String']['output']>;
};

export type GQRolePermissionOriginArgs = {
  permission: GQHasPermissionInput;
};

export type GQRoleConnection = {
  __typename?: 'RoleConnection';
  edges: Array<Maybe<GQRoleEdge>>;
  pageInfo: GQPageInfo;
};

export type GQRoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQRole>;
};

export type GQRolePermissionOrigin = {
  __typename?: 'RolePermissionOrigin';
  id: Scalars['ID']['output'];
  permission: Maybe<GQPermissionItem>;
};

export type GQRunCeeForEngagementTaskInput = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQRunCeeForEngagementTaskPayload = {
  __typename?: 'RunCeeForEngagementTaskPayload';
  ceeJobId: Maybe<Scalars['ID']['output']>;
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQRunCtsTransformEditorDashboardInput = {
  businessActivityTypeId?: InputMaybe<Scalars['String']['input']>;
  businessActivityTypeName?: InputMaybe<Scalars['String']['input']>;
  inputFileMetadataId?: InputMaybe<Scalars['ID']['input']>;
  parentId: Scalars['ID']['input'];
  parentType: GQCustomerTargetSchemaTransformParentType;
  sql: Scalars['String']['input'];
  transformTarget: GQCustomerTargetSchemaTransformTarget;
};

export type GQRunCtsTransformEditorPayload = {
  __typename?: 'RunCtsTransformEditorPayload';
  workflowId: Scalars['String']['output'];
};

export type GQRunDemoPlatformInput = {
  demoPlatformConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQRunDemoPlatformPayload = {
  __typename?: 'RunDemoPlatformPayload';
  demoPlatformRunId: Scalars['ID']['output'];
  jobId: Scalars['ID']['output'];
};

export type GQRunPleeForEngagementTaskInput = {
  engagementTaskId: Scalars['ID']['input'];
};

export type GQRunPleeForEngagementTaskPayload = {
  __typename?: 'RunPleeForEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQSbtCommitment = {
  __typename?: 'SBTCommitment';
  baselineYear: Maybe<Scalars['Int']['output']>;
  commitmentDeadline: Maybe<Scalars['Date']['output']>;
  commitmentType: Maybe<GQSbtCommitmentType>;
  id: Scalars['ID']['output'];
  longTermTargetYear: Maybe<Scalars['Int']['output']>;
  nearTermTargetYear: Maybe<Scalars['Int']['output']>;
  netZeroCommitted: Maybe<Scalars['Boolean']['output']>;
  netZeroTargetYear: Maybe<Scalars['Int']['output']>;
  stage: Maybe<GQSbtCommitmentStage>;
  targetClassification: Maybe<GQSbtTargetClassification>;
};

export const GQSbtScope1And2Target = {
  OnePointFiveC: 'OnePointFiveC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope1And2Target =
  (typeof GQSbtScope1And2Target)[keyof typeof GQSbtScope1And2Target];
export const GQSbtScope3Target = {
  Geva7: 'GEVA7',
  OnePointFiveC: 'OnePointFiveC',
  TwoC: 'TwoC',
  WellBelowTwoC: 'WellBelowTwoC',
} as const;

export type GQSbtScope3Target =
  (typeof GQSbtScope3Target)[keyof typeof GQSbtScope3Target];
export const GQSbtTermLength = {
  LongTerm: 'LongTerm',
  NearTerm: 'NearTerm',
} as const;

export type GQSbtTermLength =
  (typeof GQSbtTermLength)[keyof typeof GQSbtTermLength];
export const GQSasbIndustry = {
  ECommerce: 'ECommerce',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  HotelsAndLodging: 'HotelsAndLodging',
  Other: 'Other',
  SoftwareAndItServices: 'SoftwareAndITServices',
} as const;

export type GQSasbIndustry =
  (typeof GQSasbIndustry)[keyof typeof GQSasbIndustry];
export const GQSasbIndustryType = {
  AdvertisingAndMarketing: 'AdvertisingAndMarketing',
  AerospaceAndDefense: 'AerospaceAndDefense',
  AgriculturalProducts: 'AgriculturalProducts',
  AirFreightAndLogistics: 'AirFreightAndLogistics',
  Airlines: 'Airlines',
  AlcoholicBeverages: 'AlcoholicBeverages',
  ApparelAccessoriesAndFootwear: 'ApparelAccessoriesAndFootwear',
  ApplianceManufacturing: 'ApplianceManufacturing',
  AssetManagementAndCustodyActivities: 'AssetManagementAndCustodyActivities',
  AutoParts: 'AutoParts',
  Automobiles: 'Automobiles',
  Biofuels: 'Biofuels',
  BiotechnologyAndPharmaceuticals: 'BiotechnologyAndPharmaceuticals',
  BuildingProductsAndFurnishings: 'BuildingProductsAndFurnishings',
  CarRentalAndLeasing: 'CarRentalAndLeasing',
  CasinosAndGaming: 'CasinosAndGaming',
  Chemicals: 'Chemicals',
  CoalOperations: 'CoalOperations',
  CommercialBanks: 'CommercialBanks',
  ConstructionMaterials: 'ConstructionMaterials',
  ConsumerFinance: 'ConsumerFinance',
  ContainersAndPackaging: 'ContainersAndPackaging',
  CruiseLines: 'CruiseLines',
  DrugRetailers: 'DrugRetailers',
  ECommerce: 'ECommerce',
  Education: 'Education',
  ElectricalAndElectronicEquipment: 'ElectricalAndElectronicEquipment',
  ElectricalUtilitiesAndPowerGenerators:
    'ElectricalUtilitiesAndPowerGenerators',
  ElectronicManufacturingServicesAndOriginalDesignManufacturing:
    'ElectronicManufacturingServicesAndOriginalDesignManufacturing',
  EngineeringAndConstructionServices: 'EngineeringAndConstructionServices',
  FoodRetailersAndDistributors: 'FoodRetailersAndDistributors',
  ForestryManagement: 'ForestryManagement',
  FuelCellsAndIndustrialBatteries: 'FuelCellsAndIndustrialBatteries',
  GasUtilitiesAndDistributors: 'GasUtilitiesAndDistributors',
  Hardware: 'Hardware',
  HealthCareDelivery: 'HealthCareDelivery',
  HealthCareDistributors: 'HealthCareDistributors',
  HomeBuilders: 'HomeBuilders',
  HotelsAndLodging: 'HotelsAndLodging',
  HouseholdAndPersonalProducts: 'HouseholdAndPersonalProducts',
  IndustrialMachineryAndGoods: 'IndustrialMachineryAndGoods',
  Insurance: 'Insurance',
  InternetMediaAndServices: 'InternetMediaAndServices',
  InvestmentBankingAndBrokerage: 'InvestmentBankingAndBrokerage',
  IronAndSteelProducers: 'IronAndSteelProducers',
  LeisureFacilities: 'LeisureFacilities',
  ManagedCare: 'ManagedCare',
  MarineTransportation: 'MarineTransportation',
  MeatPoultryAndDairy: 'MeatPoultryAndDairy',
  MediaAndEntertainment: 'MediaAndEntertainment',
  MedicalEquipmentAndSupplies: 'MedicalEquipmentAndSupplies',
  MetalsAndMining: 'MetalsAndMining',
  MortgageFinance: 'MortgageFinance',
  MultilineAndSpecialtyRetailersAndDistributors:
    'MultilineAndSpecialtyRetailersAndDistributors',
  NonAlcoholicBeverages: 'NonAlcoholicBeverages',
  OilAndGasExplorationAndProduction: 'OilAndGasExplorationAndProduction',
  OilAndGasMidstream: 'OilAndGasMidstream',
  OilAndGasRefiningAndMarketing: 'OilAndGasRefiningAndMarketing',
  OilAndGasServices: 'OilAndGasServices',
  ProcessedFoods: 'ProcessedFoods',
  ProfessionalAndCommercialServices: 'ProfessionalAndCommercialServices',
  PulpAndPaperProducts: 'PulpAndPaperProducts',
  RailTransportation: 'RailTransportation',
  RealEstate: 'RealEstate',
  RealEstateServices: 'RealEstateServices',
  Restaurants: 'Restaurants',
  RoadTransportation: 'RoadTransportation',
  SecurityAndCommodityExchanges: 'SecurityAndCommodityExchanges',
  Semiconductors: 'Semiconductors',
  SoftwareAndItServices: 'SoftwareAndITServices',
  SolarTechnologyAndProjectDevelopers: 'SolarTechnologyAndProjectDevelopers',
  TelecommunicationServices: 'TelecommunicationServices',
  Tobacco: 'Tobacco',
  ToysAndSportingGoods: 'ToysAndSportingGoods',
  WasteManagement: 'WasteManagement',
  WaterUtilitiesAndServices: 'WaterUtilitiesAndServices',
  WindTechnologyAndProjectDevelopers: 'WindTechnologyAndProjectDevelopers',
} as const;

export type GQSasbIndustryType =
  (typeof GQSasbIndustryType)[keyof typeof GQSasbIndustryType];
export const GQSasbSectorType = {
  ConsumerGoods: 'ConsumerGoods',
  ExtractivesAndMineralsProcessing: 'ExtractivesAndMineralsProcessing',
  Financials: 'Financials',
  FoodAndBeverage: 'FoodAndBeverage',
  HealthCare: 'HealthCare',
  Infrastructure: 'Infrastructure',
  RenewableResourcesAndAlternativeEnergy:
    'RenewableResourcesAndAlternativeEnergy',
  ResourceTransformation: 'ResourceTransformation',
  Services: 'Services',
  TechnologyAndCommunications: 'TechnologyAndCommunications',
  Transportation: 'Transportation',
} as const;

export type GQSasbSectorType =
  (typeof GQSasbSectorType)[keyof typeof GQSasbSectorType];
export type GQSaveCategoryRuleGhgCategoryNoteInput = {
  note: Scalars['String']['input'];
  rule: GQFootprintCategoryRuleInput;
};

export type GQSaveCategoryRuleGhgCategoryNotePayload = {
  __typename?: 'SaveCategoryRuleGhgCategoryNotePayload';
  note: Scalars['String']['output'];
};

export type GQSaveDeltaProgressValueMappingInput = {
  configId: Scalars['ID']['input'];
  deltaJson: Scalars['JSONString']['input'];
  projectId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSaveDeltaProgressValueMappingPayload = {
  __typename?: 'SaveDeltaProgressValueMappingPayload';
  metadata: Maybe<GQValueMappingRowsMetadata>;
  revisionData: GQDatasourceValueMappingRevisionData;
  updatedRows: Array<GQValueMappingRow>;
};

export type GQSaveEmployeeFeedbackInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
};

export type GQSaveEmployeeFeedbackPayload = {
  __typename?: 'SaveEmployeeFeedbackPayload';
  id: Maybe<Scalars['String']['output']>;
};

export type GQSaveIncompleteOneSchemaImportInput = {
  embedId: Scalars['String']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQSaveIncompleteOneSchemaImportPayload = {
  __typename?: 'SaveIncompleteOneSchemaImportPayload';
  jobId: Scalars['ID']['output'];
  userUploadTask: GQUserUploadTask;
};

export type GQSaveProductionGraphInput = {
  lifecycleAssessmentId: Scalars['ID']['input'];
  productionGraphEdits: Array<GQProductionGraphNodeEdit>;
};

export const GQSbtCommitmentStage = {
  Committed: 'Committed',
  Removed: 'Removed',
  TargetsSet: 'TargetsSet',
} as const;

export type GQSbtCommitmentStage =
  (typeof GQSbtCommitmentStage)[keyof typeof GQSbtCommitmentStage];
export const GQSbtCommitmentType = {
  BaOnePointFiveOptionOne: 'BAOnePointFiveOptionOne',
  BaOnePointFiveOptionOneAndTwo: 'BAOnePointFiveOptionOneAndTwo',
  BaOnePointFiveOptionTwo: 'BAOnePointFiveOptionTwo',
  NetZero: 'NetZero',
  Standard: 'Standard',
} as const;

export type GQSbtCommitmentType =
  (typeof GQSbtCommitmentType)[keyof typeof GQSbtCommitmentType];
export type GQSbtTarget = GQIdInterface & {
  __typename?: 'SbtTarget';
  action: Maybe<Scalars['String']['output']>;
  baseYear: Maybe<Scalars['String']['output']>;
  commitmentDeadline: Maybe<Scalars['String']['output']>;
  commitmentType: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyTemperatureAlignment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  datePublished: Maybe<Scalars['String']['output']>;
  dt: Scalars['Date']['output'];
  fullTargetLanguage: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isin: Maybe<Scalars['String']['output']>;
  lei: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  organizationType: Maybe<Scalars['String']['output']>;
  reasonForCommitmentExtensionOrRemoval: Maybe<Scalars['String']['output']>;
  region: Maybe<Scalars['String']['output']>;
  scope: Maybe<Scalars['String']['output']>;
  sector: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  target: Maybe<Scalars['String']['output']>;
  targetClassification: Maybe<Scalars['String']['output']>;
  targetSubtype: Maybe<Scalars['String']['output']>;
  targetType: Maybe<Scalars['String']['output']>;
  targetValue: Maybe<Scalars['String']['output']>;
  targetWording: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  yearType: Maybe<Scalars['String']['output']>;
};

export const GQSbtTargetClassification = {
  OnePointFiveDegrees: 'OnePointFiveDegrees',
  TwoDegrees: 'TwoDegrees',
  WellBelowTwoDegrees: 'WellBelowTwoDegrees',
} as const;

export type GQSbtTargetClassification =
  (typeof GQSbtTargetClassification)[keyof typeof GQSbtTargetClassification];
export type GQSbtiTargetExclusion = {
  __typename?: 'SbtiTargetExclusion';
  filters: GQFilterExpressionGroupWithNewFilters;
  id: Scalars['ID']['output'];
};

export const GQScheduleType = {
  Customized: 'Customized',
  SingleParameterized: 'SingleParameterized',
} as const;

export type GQScheduleType =
  (typeof GQScheduleType)[keyof typeof GQScheduleType];
export type GQScienceBasedTargetCommitment = GQCompanyClimateCommitment &
  GQIdInterface & {
    __typename?: 'ScienceBasedTargetCommitment';
    commitment: GQSbtCommitment;
    commitmentMadeDate: Maybe<Scalars['Date']['output']>;
    commitmentPeriodEnd: Maybe<Scalars['Date']['output']>;
    commitmentPeriodStart: Maybe<Scalars['Date']['output']>;
    description: Maybe<Scalars['String']['output']>;
    externalUrl: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    kind: GQCompanyClimateCommitmentKind;
    submittedToSBTi: Scalars['Boolean']['output'];
  };

export const GQScope2Source = {
  Location: 'Location',
  Market: 'Market',
  Other: 'Other',
} as const;

export type GQScope2Source =
  (typeof GQScope2Source)[keyof typeof GQScope2Source];
export const GQScope3EvaluationStatus = {
  NotEvaluated: 'NOT_EVALUATED',
  NotRelevantCalculated: 'NOT_RELEVANT_CALCULATED',
  NotRelevantExplanationProvided: 'NOT_RELEVANT_EXPLANATION_PROVIDED',
  QuestionNotApplicable: 'QUESTION_NOT_APPLICABLE',
  RelevantCalculated: 'RELEVANT_CALCULATED',
  RelevantNotYetCalculated: 'RELEVANT_NOT_YET_CALCULATED',
  Unknown: 'UNKNOWN',
} as const;

export type GQScope3EvaluationStatus =
  (typeof GQScope3EvaluationStatus)[keyof typeof GQScope3EvaluationStatus];
export const GQScope3OverrideKind = {
  Overall: 'Overall',
  Subscope: 'Subscope',
} as const;

export type GQScope3OverrideKind =
  (typeof GQScope3OverrideKind)[keyof typeof GQScope3OverrideKind];
export type GQSdiInstructionsFaqType = {
  __typename?: 'SdiInstructionsFaqType';
  answerMd: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type GQSdiInstructionsStep = {
  __typename?: 'SdiInstructionsStep';
  descriptionMd: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type GQSendIntegrationInviteInput = {
  datasourceId: Scalars['ID']['input'];
  integrationPartnerId: Scalars['ID']['input'];
  measurementProjectId?: InputMaybe<Scalars['ID']['input']>;
  noteFromAdmin?: InputMaybe<Scalars['String']['input']>;
  recipientUserId: Scalars['ID']['input'];
  shouldSendEmail: Scalars['Boolean']['input'];
  triggererOrgId: Scalars['ID']['input'];
  triggererUserId: Scalars['ID']['input'];
};

export type GQSendIntegrationInvitePayload = {
  __typename?: 'SendIntegrationInvitePayload';
  numberOfEmails: Scalars['Int']['output'];
  sampleEmail: GQEmailContents;
};

export type GQSerializableError = {
  __typename?: 'SerializableError';
  code: Maybe<Scalars['String']['output']>;
  details: Maybe<Scalars['JSONString']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  stackTrace: Maybe<Scalars['String']['output']>;
};

export type GQSerializedWithHash = {
  hash: Maybe<Scalars['String']['output']>;
  serialized: Scalars['UntypedData']['output'];
};

export type GQSetApproversInput = {
  approverIds: Array<Scalars['ID']['input']>;
  permissions?: InputMaybe<Array<GQCreatePermissionItemInput>>;
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQSetApproversPayload = {
  __typename?: 'SetApproversPayload';
  target: GQUserUploadTask;
};

export type GQSetArchiveEngagementTaskConfigInput = {
  id: Scalars['ID']['input'];
  isArchived: Scalars['Boolean']['input'];
};

export type GQSetArchiveEngagementTaskConfigPayload = {
  __typename?: 'SetArchiveEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQSetAssetBenchmarksInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetCorporateId: Scalars['ID']['input'];
  publicDisclosures: Array<GQDisclosureIdWithReportingYear>;
};

export type GQSetAssetBenchmarksPayload = {
  __typename?: 'SetAssetBenchmarksPayload';
  status: Maybe<Scalars['String']['output']>;
};

export type GQSetEngagementCohortAndMembersInput = {
  cohortId: Scalars['ID']['input'];
  companyIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQSetEngagementCohortAndMembersPayload = {
  __typename?: 'SetEngagementCohortAndMembersPayload';
  engagementCohortWithCompanyIds: GQEngagementCohortWithCompanyIds;
  oldCompanyIds: Array<Scalars['ID']['output']>;
};

export type GQSetEngagementCohortsForCompanyInput = {
  cohortIds: Array<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
};

export type GQSetEngagementCohortsForCompanyPayload = {
  __typename?: 'SetEngagementCohortsForCompanyPayload';
  cohortIds: Array<Scalars['ID']['output']>;
  companyId: Scalars['ID']['output'];
};

export type GQSetEngagementNotificationSettingsForUserInput = {
  settings: Array<GQEngagementNotificationSettingInput>;
  side: GQEngagementNotificationSide;
  userId: Scalars['ID']['input'];
};

export type GQSetPeerCompanyGroupMemberFeatureStatusInput = {
  id: Scalars['ID']['input'];
  revenueAtRisk?: InputMaybe<Scalars['Int53']['input']>;
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSetPeerCompanyGroupMemberFeatureStatusPayload = {
  __typename?: 'SetPeerCompanyGroupMemberFeatureStatusPayload';
  peerCompanyGroupMember: GQPeerCompanyGroupMember;
};

export type GQSetPreferredLocaleInput = {
  locale: Scalars['String']['input'];
};

export type GQSetPreferredLocalePayload = {
  __typename?: 'SetPreferredLocalePayload';
  preferredLocale: Maybe<Scalars['String']['output']>;
};

export type GQSetPublishEngagementTaskConfigInput = {
  id: Scalars['ID']['input'];
  isPublished: Scalars['Boolean']['input'];
};

export type GQSetPublishEngagementTaskConfigPayload = {
  __typename?: 'SetPublishEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQSetUserUploadedTableSheetsNotIngestedInput = {
  userUploadAttemptId: Scalars['ID']['input'];
  userUploadedTableIdsNotIngested: Array<Scalars['ID']['input']>;
};

export type GQSetUserUploadedTableSheetsNotIngestedPayload = {
  __typename?: 'SetUserUploadedTableSheetsNotIngestedPayload';
  userUploadAttempt: GQUserUploadAttempt;
};

export const GQSfdrFundClassification = {
  ArticleEight: 'ArticleEight',
  ArticleNine: 'ArticleNine',
  ArticleSix: 'ArticleSix',
} as const;

export type GQSfdrFundClassification =
  (typeof GQSfdrFundClassification)[keyof typeof GQSfdrFundClassification];
export type GQSharedFile = {
  __typename?: 'SharedFile';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  expiresAt: Scalars['DateTime']['output'];
  fileMetadata: GQFileMetadata;
  id: Scalars['ID']['output'];
  measurementProject: Maybe<GQMeasurementProject>;
  orgId: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
  user: GQUser;
  watershedEmployee: Maybe<GQWatershedEmployee>;
};

export type GQSharedFileConnection = {
  __typename?: 'SharedFileConnection';
  edges: Array<Maybe<GQSharedFileEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileEdge = {
  __typename?: 'SharedFileEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFile>;
};

export type GQSharedFileRequest = {
  __typename?: 'SharedFileRequest';
  createdAt: Scalars['DateTime']['output'];
  datasource: GQDatasource;
  fileMetadata: GQFileMetadata;
  fulfilledAt: Maybe<Scalars['DateTime']['output']>;
  fulfilledBy: Maybe<GQWatershedEmployee>;
  id: Scalars['ID']['output'];
  organization: GQOrganization;
  sharedFile: Maybe<GQSharedFile>;
  user: GQUser;
};

export type GQSharedFileRequestConnection = {
  __typename?: 'SharedFileRequestConnection';
  edges: Array<Maybe<GQSharedFileRequestEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSharedFileRequestEdge = {
  __typename?: 'SharedFileRequestEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSharedFileRequest>;
};

export type GQSharedMeasurementProject = {
  __typename?: 'SharedMeasurementProject';
  id: Scalars['ID']['output'];
  measurementProjectId: Scalars['ID']['output'];
  orgId: Scalars['ID']['output'];
};

export type GQSimpleTimeseries = {
  __typename?: 'SimpleTimeseries';
  base: Scalars['Date']['output'];
  frequency: GQTimeseriesFrequency;
  id: Maybe<Scalars['ID']['output']>;
  values: Array<Scalars['Float']['output']>;
};

export type GQSimpleTimeseriesInput = {
  base?: InputMaybe<Scalars['Date']['input']>;
  values: Array<Scalars['Float']['input']>;
};

export type GQSingleRowMethodologyExplainer = {
  __typename?: 'SingleRowMethodologyExplainer';
  biogenicCo2LocationTrace: Maybe<GQSingleRowTrace>;
  biogenicCo2Trace: Maybe<GQSingleRowTrace>;
  cleanPowerAvoidedEmissionsTrace: Maybe<GQSingleRowTrace>;
  files: Array<GQFootprintAnalysisFile>;
  footprintExclusionRule: Maybe<GQFootprintExclusionRule>;
  id: Scalars['String']['output'];
  locationTrace: Maybe<GQSingleRowTrace>;
  marketTrace: Maybe<GQSingleRowTrace>;
  microplasticsInflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  microplasticsOutflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  pipelineV2Chain: Maybe<GQCalculationChain>;
  substanceInflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  substanceOutflowEmissionsTrace: Maybe<GQSingleRowTrace>;
  wasteEmissionsTrace: Maybe<GQSingleRowTrace>;
  waterConsumptionEmissionsTrace: Maybe<GQSingleRowTrace>;
};

export type GQSingleRowTrace = {
  __typename?: 'SingleRowTrace';
  convertedActivity: Scalars['Float']['output'];
  convertedActivityUnit: Scalars['String']['output'];
  emissions: Scalars['Float']['output'];
  fingerprint: Scalars['String']['output'];
  functionalUnit: Scalars['Float']['output'];
  functionalUnitUnit: Scalars['String']['output'];
  ghgCategoryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  trace: Scalars['UntypedData']['output'];
  traceDetails: GQTraceDetails;
};

export type GQSnapshotFootprintKindMeta = {
  __typename?: 'SnapshotFootprintKindMeta';
  description: Scalars['String']['output'];
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintKind: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQSoftDeleteCsrdDataRequirementsConfigInput = {
  id: Scalars['ID']['input'];
};

export type GQSoftDeleteCsrdDataRequirementsConfigPayload = {
  __typename?: 'SoftDeleteCsrdDataRequirementsConfigPayload';
  payload: GQCsrdDataRequirementsConfig;
};

export const GQSpendMethodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethodology =
  (typeof GQSpendMethodology)[keyof typeof GQSpendMethodology];
export const GQSpendMethoodology = {
  Ceda: 'CEDA',
  Useeio: 'USEEIO',
} as const;

export type GQSpendMethoodology =
  (typeof GQSpendMethoodology)[keyof typeof GQSpendMethoodology];
export type GQStartCdpSyncAnswersInput = {
  overwriteConflicts?: InputMaybe<Scalars['Boolean']['input']>;
  questionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reportId: Scalars['ID']['input'];
};

export type GQStartCdpSyncAnswersPayload = {
  __typename?: 'StartCdpSyncAnswersPayload';
  id: Scalars['ID']['output'];
  workflowId: Scalars['String']['output'];
};

export type GQStartFileFeedEmbeddedSessionInput = {
  ctsvOneSchemaFileFeedId: Scalars['ID']['input'];
};

export const GQStationaryCombustionFuelKind = {
  AgriculturalByproducts: 'AgriculturalByproducts',
  AnthraciteCoal: 'AnthraciteCoal',
  AsphaltAndRoadOil: 'AsphaltAndRoadOil',
  AviationGasoline: 'AviationGasoline',
  Bagasse: 'Bagasse',
  Bamboo: 'Bamboo',
  Biodiesel: 'Biodiesel',
  BiofuelsAndWaste: 'BiofuelsAndWaste',
  BituminousCoal: 'BituminousCoal',
  BlastFurnaceGas: 'BlastFurnaceGas',
  Butane: 'Butane',
  Butylene: 'Butylene',
  CoalCoke: 'CoalCoke',
  CokeOvenGas: 'CokeOvenGas',
  CompressedNaturalGas: 'CompressedNaturalGas',
  CrudeOil: 'CrudeOil',
  Diesel: 'Diesel',
  DistrictChilledWater: 'DistrictChilledWater',
  DistrictHeat: 'DistrictHeat',
  Ethane: 'Ethane',
  Ethanol: 'Ethanol',
  Ethylene: 'Ethylene',
  FuelGas: 'FuelGas',
  FuelOil1: 'FuelOil1',
  FuelOil2: 'FuelOil2',
  FuelOil4: 'FuelOil4',
  HeavyGasOils: 'HeavyGasOils',
  Isobutane: 'Isobutane',
  Isobutylene: 'Isobutylene',
  Kerosene: 'Kerosene',
  KeroseneTypeJetFuel: 'KeroseneTypeJetFuel',
  LandfillGas: 'LandfillGas',
  LigniteCoal: 'LigniteCoal',
  LiquefiedNaturalGas: 'LiquefiedNaturalGas',
  LiquefiedPetroleumGases: 'LiquefiedPetroleumGases',
  Lubricants: 'Lubricants',
  MixedCommercialSectorCoal: 'MixedCommercialSectorCoal',
  MixedElectricPowerSectorCoal: 'MixedElectricPowerSectorCoal',
  MixedIndustrialCoking: 'MixedIndustrialCoking',
  MixedIndustrialSector: 'MixedIndustrialSector',
  MotorGasoline: 'MotorGasoline',
  MunicipalSolidWaste: 'MunicipalSolidWaste',
  Naphtha: 'Naphtha',
  NaturalGas: 'NaturalGas',
  NaturalGasoline: 'NaturalGasoline',
  NorthAmericanHardwood: 'NorthAmericanHardwood',
  NorthAmericanSoftwood: 'NorthAmericanSoftwood',
  OtherBiomassGases: 'OtherBiomassGases',
  OtherOil: 'OtherOil',
  Peat: 'Peat',
  PentanesPlus: 'PentanesPlus',
  PetrochemicalFeedstocks: 'PetrochemicalFeedstocks',
  PetroleumCoke: 'PetroleumCoke',
  Plastics: 'Plastics',
  Propane: 'Propane',
  PropaneGas: 'PropaneGas',
  Propylene: 'Propylene',
  RenderedAnimalFat: 'RenderedAnimalFat',
  ResidualFuelOil5: 'ResidualFuelOil5',
  ResidualFuelOil6: 'ResidualFuelOil6',
  SolidByproducts: 'SolidByproducts',
  SpecialNaphtha: 'SpecialNaphtha',
  SteamHeat: 'SteamHeat',
  Straw: 'Straw',
  SubBituminousCoal: 'SubBituminousCoal',
  Tires: 'Tires',
  UnfinishedOils: 'UnfinishedOils',
  UsedOil: 'UsedOil',
  VegetableOil: 'VegetableOil',
  WoodAndWoodResiduals: 'WoodAndWoodResiduals',
} as const;

export type GQStationaryCombustionFuelKind =
  (typeof GQStationaryCombustionFuelKind)[keyof typeof GQStationaryCombustionFuelKind];
export type GQStringKeyValue = {
  __typename?: 'StringKeyValue';
  key: Scalars['String']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type GQStringKeyValueInput = {
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type GQSubmitDataLineageFeedbackInput = {
  feedback: Scalars['String']['input'];
  sentiment: Scalars['String']['input'];
  url: Scalars['String']['input'];
  version: Scalars['String']['input'];
};

export type GQSubmitDataLineageFeedbackPayload = {
  __typename?: 'SubmitDataLineageFeedbackPayload';
  status: Scalars['String']['output'];
};

export type GQSubmitEarlyUploadHelpRequestInput = {
  cleaningStepType?: InputMaybe<GQImportFlowDataCleaningStep>;
  fileMetadataIdForDataCleaningStep?: InputMaybe<Scalars['ID']['input']>;
  fileMetadataIdsAllThatWereUploaded: Array<Scalars['ID']['input']>;
  message: Scalars['String']['input'];
  stepId?: InputMaybe<Scalars['String']['input']>;
  userUploadTaskId: Scalars['ID']['input'];
  userUploadedTableIdForDataCleaningStep?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSubmitEarlyUploadHelpRequestPayload = {
  __typename?: 'SubmitEarlyUploadHelpRequestPayload';
  userUploads: Array<GQUserUpload>;
};

export type GQSubmitEngagementTaskInput = {
  answers?: InputMaybe<Array<GQEngagementTaskAnswerInput>>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQSubmitEngagementTaskPayload = {
  __typename?: 'SubmitEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQSubmitFacilitiesBulkAdditionInput = {
  facilitiesToAdd: Array<GQFacilitiesAdditionInput>;
  measurementProjectId: Scalars['ID']['input'];
};

export type GQSubmitFacilitiesBulkAdditionPayload = {
  __typename?: 'SubmitFacilitiesBulkAdditionPayload';
  measurementProject: GQMeasurementProject;
  numFacilitiesCreated: Scalars['Int']['output'];
  org: GQOrganization;
};

export type GQSubmitFacilitiesInput = {
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQSubmitFacilitiesPayload = {
  __typename?: 'SubmitFacilitiesPayload';
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
  submittedUserUploadTask: GQUserUploadTask;
};

export type GQSubmitReportQuestionInstanceInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reportQuestionInstanceId: Scalars['ID']['input'];
};

export type GQSubmitReportQuestionInstancePayload = {
  __typename?: 'SubmitReportQuestionInstancePayload';
  reportQuestionInstance: GQReportQuestionInstance;
  success: Scalars['Boolean']['output'];
};

export type GQSubmitUserUploadTaskInput = {
  id: Scalars['ID']['input'];
};

export type GQSubmitUserUploadTaskPayload = {
  __typename?: 'SubmitUserUploadTaskPayload';
  userUploadTask: Maybe<GQUserUploadTask>;
};

export type GQSubmitVendorMatchingTaskInput = {
  vendorMatchingTaskId: Scalars['ID']['input'];
};

export type GQSubmitVendorMatchingTaskPayload = {
  __typename?: 'SubmitVendorMatchingTaskPayload';
  batchRerunBartSqlJobId: Scalars['ID']['output'];
  measurementTask: GQMeasurementParentTask | GQMeasurementTaskFlat;
};

export type GQSuggestionSource = {
  __typename?: 'SuggestionSource';
  pageNumber: Maybe<Scalars['Int']['output']>;
};

export const GQSuggestionSourceType = {
  AiBenchmark: 'AiBenchmark',
  AiExtraction: 'AiExtraction',
} as const;

export type GQSuggestionSourceType =
  (typeof GQSuggestionSourceType)[keyof typeof GQSuggestionSourceType];
export type GQSupplier = {
  __typename?: 'Supplier';
  cleanedFootprintVendorNames: Array<Scalars['String']['output']>;
  climateProgress: GQCompanyClimateProgress;
  company: Maybe<GQCompany>;
  customDataV2: Maybe<Scalars['JSONString']['output']>;
  disclosures: Array<GQDisclosure>;
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  engagementCohortIds: Array<Scalars['ID']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorNames: Array<Scalars['String']['output']>;
  ghgCategoryIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  historicalEmissions: Maybe<GQSupplierHistoricalEmissions>;
  id: Scalars['ID']['output'];
  industryAverageAllocatedEmissions: Maybe<GQSupplierHistoricalEmissions>;
  isTotalSpendingPartial: Maybe<Scalars['Boolean']['output']>;
  latestCdpDisclosurePublishingYear: Maybe<Scalars['Int']['output']>;
  latestDisclosureDateTime: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  notes: Maybe<Scalars['String']['output']>;
  percentEmissions: Scalars['Float']['output'];
  rows: Array<GQSupplierRow>;
  sbtiStage: GQCompanySbtCommitmentStage;
  supplierType: GQSupplierType;
  totalKgco2e: Scalars['Float']['output'];
  totalKgco2eFromFootprint: Maybe<Scalars['Float']['output']>;
  totalSpendUsd: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use totalSpendUsd instead */
  totalSpending: Maybe<Scalars['Float']['output']>;
};

export type GQSupplierDisclosuresArgs = {
  opts: InputMaybe<GQDisclosureInput>;
};

export type GQSupplierEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierActivityTags = {
  __typename?: 'SupplierActivityTags';
  tags: Array<GQStringKeyValue>;
};

export type GQSupplierAttachment = {
  __typename?: 'SupplierAttachment';
  companyId: Scalars['String']['output'];
  crossOrgModelId: Maybe<Scalars['String']['output']>;
  fileMetadataId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isImported: Scalars['Boolean']['output'];
  surveyId: Maybe<Scalars['ID']['output']>;
};

export const GQSupplierAutoMappingRunStatus = {
  Completed: 'Completed',
  Errored: 'Errored',
  Running: 'Running',
} as const;

export type GQSupplierAutoMappingRunStatus =
  (typeof GQSupplierAutoMappingRunStatus)[keyof typeof GQSupplierAutoMappingRunStatus];
export type GQSupplierChart = {
  __typename?: 'SupplierChart';
  aggregateType: Maybe<GQSupplierChartAggregateType>;
  chartType: GQSupplierChartType;
  colorScale: Maybe<Array<Scalars['String']['output']>>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Maybe<Scalars['ID']['output']>;
  yField: Maybe<Scalars['ID']['output']>;
};

export const GQSupplierChartAggregateType = {
  Count: 'Count',
  SpendSum: 'SpendSum',
  Sum: 'Sum',
} as const;

export type GQSupplierChartAggregateType =
  (typeof GQSupplierChartAggregateType)[keyof typeof GQSupplierChartAggregateType];
export type GQSupplierChartConfig = {
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierChartInput = {
  chartType: GQSupplierChartType;
  description?: InputMaybe<Scalars['String']['input']>;
  emissionsForecastChartConfig?: InputMaybe<GQSupplierEmissionsForecastChartConfigInput>;
  engagementFunnelChartConfig?: InputMaybe<GQSupplierEngagementFunnelChartInput>;
  metricsChartConfig?: InputMaybe<GQSupplierMetricsChartConfigInput>;
  sbtCommitmentOverTimeChartConfig?: InputMaybe<GQSupplierSbtCommitmentOverTimeChartConfigInput>;
  standardChartConfig?: InputMaybe<GQSupplierStandardChartInput>;
  tasksStatusChangeOverTimeChartConfig?: InputMaybe<GQSupplierTaskStatusChangeOverTimeChartConfigInput>;
  title: Scalars['String']['input'];
};

export const GQSupplierChartType = {
  Donut: 'Donut',
  EmissionsForecast: 'EmissionsForecast',
  EngagementFunnel: 'EngagementFunnel',
  HorizontalBar: 'HorizontalBar',
  Metrics: 'Metrics',
  SbtCommitmentOverTime: 'SbtCommitmentOverTime',
  TasksStatusChangeOverTime: 'TasksStatusChangeOverTime',
} as const;

export type GQSupplierChartType =
  (typeof GQSupplierChartType)[keyof typeof GQSupplierChartType];
export type GQSupplierContact = GQIContact & {
  __typename?: 'SupplierContact';
  company: GQCompany;
  companyId: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Maybe<Scalars['String']['output']>;
  userId: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierCustomData = {
  __typename?: 'SupplierCustomData';
  companyId: Scalars['ID']['output'];
  customData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  notes: Maybe<Scalars['String']['output']>;
  priority: Maybe<GQSupplierPriority>;
};

export type GQSupplierDataExportInput = {
  expandScope: Scalars['Boolean']['input'];
  footprintSnapshotId: Scalars['String']['input'];
};

export type GQSupplierDataExportPayload = {
  __typename?: 'SupplierDataExportPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSupplierDetailedEmissions = {
  __typename?: 'SupplierDetailedEmissions';
  emissionsByCategory: Array<GQCategorizedEmissionData>;
  emissionsByMonth: Array<GQLabeledTimeseries>;
  emissionsFactors: Array<GQEmissionsFactorRecordForDashboard>;
  emissionsModelEmissionsFactors: Array<GQEmissionsModelEmissionsFactor>;
  id: Scalars['ID']['output'];
  rows: Array<GQSupplierRow>;
};

export type GQSupplierDetailedEmissionsEmissionsByMonthArgs = {
  groupBy: GQSupplierEmissionsGroupByField;
};

export type GQSupplierEmissionsFactorsTableMetadata = {
  __typename?: 'SupplierEmissionsFactorsTableMetadata';
  displayColumns: Array<GQMethodologyCustomizationColumnDisplayFormat>;
  quantityColumnName: Scalars['String']['output'];
};

export type GQSupplierEmissionsFactorsTableRows = {
  __typename?: 'SupplierEmissionsFactorsTableRows';
  referenceDataRevisionRows: Array<GQReferenceDataRevisionRow>;
};

export type GQSupplierEmissionsForecastChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEmissionsForecastChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  plan: Maybe<GQPlan>;
  title: Scalars['String']['output'];
};

export type GQSupplierEmissionsForecastChartConfigInput = {
  planId: Scalars['ID']['input'];
};

export const GQSupplierEmissionsGroupByField = {
  BusinessCategory: 'businessCategory',
  BusinessSubcategory: 'businessSubcategory',
} as const;

export type GQSupplierEmissionsGroupByField =
  (typeof GQSupplierEmissionsGroupByField)[keyof typeof GQSupplierEmissionsGroupByField];
export type GQSupplierEngagementFunnelChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierEngagementFunnelChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  funnels: Array<GQEngagementFunnel>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierEngagementFunnelChartInput = {
  engagementFunnels?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQSupplierExpenseCategory = {
  __typename?: 'SupplierExpenseCategory';
  companyId: Scalars['ID']['output'];
  expenseCategories: Maybe<Array<GQExpenseCategoryData>>;
};

export type GQSupplierFilterOption = {
  __typename?: 'SupplierFilterOption';
  formattedValue: Scalars['String']['output'];
  rawValue: Scalars['FilterValue']['output'];
};

export type GQSupplierHistoricalEmissions = {
  __typename?: 'SupplierHistoricalEmissions';
  expenseCategory: Maybe<Scalars['String']['output']>;
  publicUrl: Maybe<Scalars['String']['output']>;
  publishingYear: Maybe<Scalars['Int']['output']>;
  reportingYear: Scalars['Int']['output'];
  revenue: Maybe<Scalars['Float']['output']>;
  revenueCurrency: Maybe<Scalars['String']['output']>;
  revenueUsd: Maybe<Scalars['Float']['output']>;
  scope1: Maybe<Scalars['Float']['output']>;
  scope1Ratio: Maybe<Scalars['Float']['output']>;
  scope2: Maybe<Scalars['Float']['output']>;
  scope2Ratio: Maybe<Scalars['Float']['output']>;
  scope3: Maybe<Scalars['Float']['output']>;
  scope3Ratio: Maybe<Scalars['Float']['output']>;
  scope301: Maybe<Scalars['Float']['output']>;
  scope302: Maybe<Scalars['Float']['output']>;
  scope303: Maybe<Scalars['Float']['output']>;
  scope304: Maybe<Scalars['Float']['output']>;
  scope305: Maybe<Scalars['Float']['output']>;
  scope306: Maybe<Scalars['Float']['output']>;
  scope307: Maybe<Scalars['Float']['output']>;
  scope308: Maybe<Scalars['Float']['output']>;
  scope309: Maybe<Scalars['Float']['output']>;
  scope310: Maybe<Scalars['Float']['output']>;
  scope311: Maybe<Scalars['Float']['output']>;
  scope312: Maybe<Scalars['Float']['output']>;
  scope313: Maybe<Scalars['Float']['output']>;
  scope314: Maybe<Scalars['Float']['output']>;
  scope315: Maybe<Scalars['Float']['output']>;
  scope316: Maybe<Scalars['Float']['output']>;
  scope317: Maybe<Scalars['Float']['output']>;
  source: GQEmissionsSource;
  surveyId: Maybe<Scalars['ID']['output']>;
  units: GQCompanyEmissionsUnits;
};

export type GQSupplierMappingRowConnection = {
  __typename?: 'SupplierMappingRowConnection';
  edges: Array<Maybe<GQSupplierMappingRowEdge>>;
  pageInfo: GQPageInfo;
};

export type GQSupplierMappingRowEdge = {
  __typename?: 'SupplierMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQSupplierMappingTableRow>;
};

export type GQSupplierMappingTableRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableRow';
  autoMatched: Maybe<GQAutoMatched>;
  autoMatches: Array<GQCompanyAutoMatch>;
  id: Scalars['ID']['output'];
  orgCompany: Maybe<GQOrgCompany>;
  orgCompanyHasSupplierSpecificEF: Maybe<Scalars['Boolean']['output']>;
  orgCompanyId: Maybe<Scalars['ID']['output']>;
  pendingCompanyCreateRequest: Maybe<GQCompanyChangeRequest>;
  ranAutoMapping: Scalars['Boolean']['output'];
  rawVendorEntity: Scalars['String']['output'];
  subRows: Array<GQSupplierMappingTableSubRow>;
  totalSpendUsd: Scalars['Float']['output'];
};

export type GQSupplierMappingTableRowsPayload = {
  __typename?: 'SupplierMappingTableRowsPayload';
  autoMappingButtonConditions: GQAutoMappingButtonConditions;
  id: Scalars['ID']['output'];
  mappedSupplierSpendUsd: Scalars['Float']['output'];
  totalNumVendorRows: Scalars['Int']['output'];
  totalSupplierSpendUsd: Scalars['Float']['output'];
  vendorRows: GQSupplierMappingRowConnection;
};

export type GQSupplierMappingTableSubRow = GQIdInterface & {
  __typename?: 'SupplierMappingTableSubRow';
  category: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  currencyQuantity: Scalars['Float']['output'];
  datasetNames: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  spendUsd: Scalars['Float']['output'];
  subCategory: Scalars['String']['output'];
};

export type GQSupplierMappingV1Analytics = {
  __typename?: 'SupplierMappingV1Analytics';
  mappedSupplierSpendUsd: Scalars['Float']['output'];
  totalSupplierSpendUsd: Scalars['Float']['output'];
};

export const GQSupplierMatchingSortOption = {
  Matched: 'matched',
  Spend: 'spend',
  Unmatched: 'unmatched',
} as const;

export type GQSupplierMatchingSortOption =
  (typeof GQSupplierMatchingSortOption)[keyof typeof GQSupplierMatchingSortOption];
export type GQSupplierMetricsChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierMetricsChartConfig';
  chartType: GQSupplierChartType;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metricsTypes: Array<GQSupplyChainMetricType>;
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
};

export type GQSupplierMetricsChartConfigInput = {
  metricsTypes: Array<GQSupplyChainMetricType>;
};

export const GQSupplierPriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQSupplierPriority =
  (typeof GQSupplierPriority)[keyof typeof GQSupplierPriority];
export type GQSupplierRow = {
  __typename?: 'SupplierRow';
  beaCode: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['String']['output'];
  company: Maybe<GQCompany>;
  convertedQuantity: Scalars['Float']['output'];
  convertedUnit: Scalars['String']['output'];
  emissionsFactor: Maybe<GQEmissionsFactorRecordForDashboard>;
  emissionsFactorId: Maybe<Scalars['String']['output']>;
  footprintTags: Array<GQStringKeyValue>;
  footprintVendorName: Maybe<Scalars['String']['output']>;
  ghgCategoryId: Maybe<Scalars['String']['output']>;
  groupingKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputUnit: Maybe<Scalars['String']['output']>;
  kgco2eQuantity: Scalars['Float']['output'];
  month: Maybe<Scalars['YearMonth']['output']>;
  name: Scalars['String']['output'];
  rawVendorName: Maybe<Scalars['String']['output']>;
  subcategoryId: Scalars['String']['output'];
  supplierType: GQSupplierType;
};

export type GQSupplierSbtCommitmentOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierSbtCommitmentOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    orgId: Maybe<Scalars['ID']['output']>;
    title: Scalars['String']['output'];
  };

export type GQSupplierSbtCommitmentOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
};

export type GQSupplierScoreCriteria = {
  __typename?: 'SupplierScoreCriteria';
  field: Scalars['String']['output'];
  filters: Array<GQBiQueryFilter>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  supplierScoreId: Scalars['String']['output'];
  weight: Scalars['Int']['output'];
};

export type GQSupplierScorecard = {
  __typename?: 'SupplierScorecard';
  criteria: Array<GQSupplierScoreCriteria>;
  field: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQSupplierSpecificInfoForBulkDeploy = {
  expenseCategories?: InputMaybe<
    Array<GQDiscloseEmissionsExpenseCategoriesInput>
  >;
  supplierCompanyId: Scalars['ID']['input'];
  supplierContactIds: Array<Scalars['ID']['input']>;
};

export type GQSupplierStandardChartConfig = GQSupplierChartConfig & {
  __typename?: 'SupplierStandardChartConfig';
  aggregateType: GQSupplierChartAggregateType;
  chartType: GQSupplierChartType;
  colorScale: Array<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  orgId: Maybe<Scalars['ID']['output']>;
  title: Scalars['String']['output'];
  xField: Scalars['ID']['output'];
  yField: Maybe<Scalars['ID']['output']>;
};

export type GQSupplierStandardChartInput = {
  aggregateType: GQSupplierChartAggregateType;
  colorScale: Array<Scalars['String']['input']>;
  xField: Scalars['ID']['input'];
  yField?: InputMaybe<Scalars['ID']['input']>;
};

export type GQSupplierSurveyResponseExportInput = {
  surveyIds: Array<Scalars['ID']['input']>;
};

export type GQSupplierSurveyResponseExportPayload = {
  __typename?: 'SupplierSurveyResponseExportPayload';
  workflowId: Scalars['String']['output'];
};

export type GQSupplierTable = {
  __typename?: 'SupplierTable';
  columns: Array<GQSupplierTableColumn>;
};

export type GQSupplierTableColumn = {
  __typename?: 'SupplierTableColumn';
  baseEngagementTaskConfigId: Maybe<Scalars['ID']['output']>;
  columnFormat: Maybe<GQSupplierTableColumnFormat>;
  csatTagName: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  field: Scalars['String']['output'];
  footprintTagName: Maybe<Scalars['String']['output']>;
  headerName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSupplierScore: Scalars['Boolean']['output'];
  isSupplierScoreCriteria: Scalars['Boolean']['output'];
  questionKey: Maybe<Scalars['String']['output']>;
  selectOptions: Maybe<Array<Scalars['String']['output']>>;
  visible: Maybe<Scalars['Boolean']['output']>;
};

export const GQSupplierTableColumnFormat = {
  CurrencyUsdCents: 'CurrencyUsdCents',
  Date: 'Date',
  FootprintTag: 'FootprintTag',
  Integer: 'Integer',
  Number: 'Number',
  Percentage: 'Percentage',
  SingleSelect: 'SingleSelect',
  String: 'String',
} as const;

export type GQSupplierTableColumnFormat =
  (typeof GQSupplierTableColumnFormat)[keyof typeof GQSupplierTableColumnFormat];
export type GQSupplierTaskStatusChangeOverTimeChartConfig =
  GQSupplierChartConfig & {
    __typename?: 'SupplierTaskStatusChangeOverTimeChartConfig';
    chartType: GQSupplierChartType;
    colorScale: Array<Scalars['String']['output']>;
    description: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isCumulative: Maybe<Scalars['Boolean']['output']>;
    orgId: Maybe<Scalars['ID']['output']>;
    tasksStatusChangeConfig: Maybe<Array<GQSurveyDefinitionAndStatus>>;
    title: Scalars['String']['output'];
  };

export type GQSupplierTaskStatusChangeOverTimeChartConfigInput = {
  colorScale: Array<Scalars['String']['input']>;
  isCumulative?: InputMaybe<Scalars['Boolean']['input']>;
  tasksStatusChangeConfig?: InputMaybe<Scalars['JSONString']['input']>;
};

export const GQSupplierType = {
  KnownSupplier: 'KnownSupplier',
  NonFootprintSupplier: 'NonFootprintSupplier',
  SandboxCompany: 'SandboxCompany',
  SmallSuppliersAggregated: 'SmallSuppliersAggregated',
  UnknownSuppliersAggregated: 'UnknownSuppliersAggregated',
} as const;

export type GQSupplierType =
  (typeof GQSupplierType)[keyof typeof GQSupplierType];
export type GQSupplierView = GQIdInterface & {
  __typename?: 'SupplierView';
  chartOrder: Maybe<Array<GQSupplierViewChartOrder>>;
  chartsV2: Array<
    | GQSupplierEmissionsForecastChartConfig
    | GQSupplierEngagementFunnelChartConfig
    | GQSupplierMetricsChartConfig
    | GQSupplierSbtCommitmentOverTimeChartConfig
    | GQSupplierStandardChartConfig
    | GQSupplierTaskStatusChangeOverTimeChartConfig
  >;
  columnOrder: Maybe<Array<GQSupplierViewColumnOrder>>;
  filters: Maybe<GQFilterExpressionGroupWithNewFilters>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reductionPlan: Maybe<GQPlan>;
  table: GQSupplierTable;
};

export type GQSupplierViewChartOrder = {
  __typename?: 'SupplierViewChartOrder';
  chartId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
};

export type GQSupplierViewColumnOrder = {
  __typename?: 'SupplierViewColumnOrder';
  columnId: Scalars['ID']['output'];
  visible: Scalars['Boolean']['output'];
  width: Scalars['Int']['output'];
};

export type GQSuppliersData = {
  __typename?: 'SuppliersData';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
};

export type GQSuppliersSettings = {
  __typename?: 'SuppliersSettings';
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  footprintSnapshotIds: Array<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  includedGhgCategories: Array<Scalars['String']['output']>;
  suppressNotifications: Scalars['Boolean']['output'];
  viewLatestSupplierMapping: Scalars['Boolean']['output'];
};

export type GQSuppliersV2Options = {
  filterToCollapsibleVendors?: InputMaybe<Scalars['Boolean']['input']>;
  shouldUseUncollapsedFootprintFields?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQSuppliersWithInterval = {
  __typename?: 'SuppliersWithInterval';
  /** @deprecated no longer used */
  footprintInterval: Maybe<Scalars['YMInterval']['output']>;
  suppliers: Array<GQSupplier>;
};

export const GQSupplyChainMetricType = {
  ForecastedSuppliersReductionInTargetYearKgco2e:
    'ForecastedSuppliersReductionInTargetYearKgco2e',
  ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives:
    'ForecastedSuppliersReductionInTargetYearKgco2eFromInitiatives',
  ForecastedSupplyChainSavingsInTargetYear:
    'ForecastedSupplyChainSavingsInTargetYear',
  ForecastedSupplyChainSavingsInTargetYearFromInitiatives:
    'ForecastedSupplyChainSavingsInTargetYearFromInitiatives',
  SuppliersCommitedToSbti: 'SuppliersCommitedToSbti',
  SurveysApproved: 'SurveysApproved',
  SurveysSent: 'SurveysSent',
  SurveysSubmitted: 'SurveysSubmitted',
  TotalSupplyChainKgco2e: 'TotalSupplyChainKgco2e',
} as const;

export type GQSupplyChainMetricType =
  (typeof GQSupplyChainMetricType)[keyof typeof GQSupplyChainMetricType];
export type GQSupplyChainSurveyEventContext = {
  customerOrg?: InputMaybe<GQContextOrgInfo>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  supplierOrg?: InputMaybe<GQContextOrgInfo>;
  surveyStatus?: InputMaybe<GQCompanySurveyStatus>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type GQSupportCase = {
  __typename?: 'SupportCase';
  createdAt: Scalars['DateTime']['output'];
  currentAssignee: Maybe<Scalars['String']['output']>;
  currentUserIsSubcribed: Maybe<Scalars['Boolean']['output']>;
  description: Maybe<Scalars['String']['output']>;
  hasUnreadNotifications: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  issueType: Maybe<GQSupportCaseIssueType>;
  lastUpdatedBy: Scalars['String']['output'];
  /** @deprecated Use relatedItem instead */
  objectId: Maybe<Scalars['String']['output']>;
  priority: GQSupportCasePriority;
  productUrl: Maybe<Scalars['String']['output']>;
  relatedItem: Maybe<GQSupportCaseRelatedItem>;
  status: GQSupportCaseStatus;
  subject: Scalars['String']['output'];
  subscriberUsers: Array<GQUser>;
  timeline: GQAuditLogConnection;
  updatedAt: Scalars['DateTime']['output'];
  user: Maybe<GQUser>;
};

export type GQSupportCaseTimelineArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQSupportCaseAttachmentForComment = {
  fileMetadataId: Scalars['ID']['input'];
  fileName: Scalars['String']['input'];
};

export type GQSupportCaseAttachmentInput = {
  fileName: Scalars['String']['input'];
  sizeBytes: Scalars['Int']['input'];
};

export type GQSupportCaseAttachments = {
  __typename?: 'SupportCaseAttachments';
  fileMetadataId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type GQSupportCaseConnection = {
  __typename?: 'SupportCaseConnection';
  edges: Array<GQSupportCaseEdge>;
  pageInfo: GQPageInfo;
};

export type GQSupportCaseEdge = {
  __typename?: 'SupportCaseEdge';
  cursor: Scalars['String']['output'];
  node: GQSupportCase;
};

export const GQSupportCaseIssueType = {
  Ceda: 'Ceda',
  DataUpload: 'DataUpload',
  DatasetGuidance: 'DatasetGuidance',
  Finance: 'Finance',
  FinancedEmissions: 'FinancedEmissions',
  Footprint: 'Footprint',
  GeneralPlatform: 'GeneralPlatform',
  GettingStarted: 'GettingStarted',
  Marketplace: 'Marketplace',
  Measurement: 'Measurement',
  Measurements: 'Measurements',
  Methodology: 'Methodology',
  PhysicalProducts: 'PhysicalProducts',
  ReductionsAndTargets: 'ReductionsAndTargets',
  Reporting: 'Reporting',
  ReportingAndRegulations: 'ReportingAndRegulations',
  SupplyChain: 'SupplyChain',
} as const;

export type GQSupportCaseIssueType =
  (typeof GQSupportCaseIssueType)[keyof typeof GQSupportCaseIssueType];
export const GQSupportCasePriority = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQSupportCasePriority =
  (typeof GQSupportCasePriority)[keyof typeof GQSupportCasePriority];
export type GQSupportCaseRelatedItem = {
  __typename?: 'SupportCaseRelatedItem';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export const GQSupportCaseStatus = {
  Complete: 'Complete',
  OnHoldPendingWatershedAction: 'OnHoldPendingWatershedAction',
  PendingCustomerReply: 'PendingCustomerReply',
  PendingWatershedReply: 'PendingWatershedReply',
} as const;

export type GQSupportCaseStatus =
  (typeof GQSupportCaseStatus)[keyof typeof GQSupportCaseStatus];
export type GQSupportingDocument = GQIdInterface & {
  __typename?: 'SupportingDocument';
  buildingUtilityId: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  file: GQFileMetadata;
  id: Scalars['ID']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQSurveyDefinitionAndStatus = {
  __typename?: 'SurveyDefinitionAndStatus';
  engagementTaskConfigId: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export const GQSurveyDefinitionCategory = {
  DataCollection: 'DataCollection',
  Learning: 'Learning',
  MakeCommitments: 'MakeCommitments',
  Other: 'Other',
  ReductionProjects: 'ReductionProjects',
  ScienceBasedTargets: 'ScienceBasedTargets',
} as const;

export type GQSurveyDefinitionCategory =
  (typeof GQSurveyDefinitionCategory)[keyof typeof GQSurveyDefinitionCategory];
export type GQSyncedPostgresTableReference = GQBaseTableReference & {
  __typename?: 'SyncedPostgresTableReference';
  alias: Scalars['String']['output'];
  ref: Scalars['String']['output'];
  tableType: GQTableReferenceType;
};

export const GQTableCellAlignment = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
} as const;

export type GQTableCellAlignment =
  (typeof GQTableCellAlignment)[keyof typeof GQTableCellAlignment];
export type GQTablePointer = {
  __typename?: 'TablePointer';
  ids: Array<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
};

export const GQTableReferenceType = {
  Bart: 'Bart',
  BartInstance: 'BartInstance',
  DataRegistry: 'DataRegistry',
  FinanceFootprintSnapshot: 'FinanceFootprintSnapshot',
  FootprintBart: 'FootprintBart',
  FootprintSidecar: 'FootprintSidecar',
  FootprintSnapshot: 'FootprintSnapshot',
  LineageAssetFileMetadata: 'LineageAssetFileMetadata',
  SyncedPostgres: 'SyncedPostgres',
} as const;

export type GQTableReferenceType =
  (typeof GQTableReferenceType)[keyof typeof GQTableReferenceType];
export type GQTabularData = {
  __typename?: 'TabularData';
  cells: Array<Array<Scalars['JSONString']['output']>>;
};

export type GQTabularDataInput = {
  cells: Array<Array<Scalars['JSONString']['input']>>;
};

export type GQTarget = GQIdInterface & {
  __typename?: 'Target';
  actualAmount: Maybe<Scalars['Int']['output']>;
  descriptor: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  kind: GQTargetKind;
  startDate: Scalars['Date']['output'];
  targetAmount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  unit: GQTargetUnit;
};

export type GQTargetConnection = {
  __typename?: 'TargetConnection';
  edges: Array<Maybe<GQTargetEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Target' and its cursor. */
export type GQTargetEdge = {
  __typename?: 'TargetEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQTarget>;
};

export const GQTargetKind = {
  AvoidedEmissions: 'AvoidedEmissions',
  EmissionsReduction: 'EmissionsReduction',
  NetZero: 'NetZero',
  Offsets: 'Offsets',
} as const;

export type GQTargetKind = (typeof GQTargetKind)[keyof typeof GQTargetKind];
export const GQTargetReductionRate = {
  Custom: 'Custom',
  LinearAnnualReduction: 'LinearAnnualReduction',
  YearOverYear: 'YearOverYear',
} as const;

export type GQTargetReductionRate =
  (typeof GQTargetReductionRate)[keyof typeof GQTargetReductionRate];
export const GQTargetUnit = {
  Percent: 'Percent',
  PercentAbsolute: 'PercentAbsolute',
  Tco2E: 'TCO2E',
  Year: 'Year',
} as const;

export type GQTargetUnit = (typeof GQTargetUnit)[keyof typeof GQTargetUnit];
export type GQTaskStatusDump = {
  __typename?: 'TaskStatusDump';
  assigneeNames: Maybe<Array<Scalars['String']['output']>>;
  datasetName: Maybe<Scalars['String']['output']>;
  status: GQTaskWatershedProcessStateSimplified;
  taskId: Scalars['ID']['output'];
  taskName: Scalars['String']['output'];
};

export const GQTaskUploadState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskUploadState =
  (typeof GQTaskUploadState)[keyof typeof GQTaskUploadState];
export const GQTaskWatershedProcessState = {
  Blocked: 'Blocked',
  Completed: 'Completed',
  InProgress: 'InProgress',
  NotStarted: 'NotStarted',
  ReviewRequired: 'ReviewRequired',
} as const;

export type GQTaskWatershedProcessState =
  (typeof GQTaskWatershedProcessState)[keyof typeof GQTaskWatershedProcessState];
export const GQTaskWatershedProcessStateSimplified = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  NeedsApproval: 'NeedsApproval',
  None: 'None',
  NotStarted: 'NotStarted',
} as const;

export type GQTaskWatershedProcessStateSimplified =
  (typeof GQTaskWatershedProcessStateSimplified)[keyof typeof GQTaskWatershedProcessStateSimplified];
export type GQTcfdArchetypeOpportunity = GQIdInterface &
  GQITcfdOpportunity & {
    __typename?: 'TcfdArchetypeOpportunity';
    archetypeRiskId: Maybe<Scalars['ID']['output']>;
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
  };

export type GQTcfdArchetypeRisk = GQIdInterface &
  GQITcfdRisk & {
    __typename?: 'TcfdArchetypeRisk';
    distributionModel: GQTcfdStrategyModuleDistributionModel;
    geographies: Array<Scalars['String']['output']>;
    highTrajectoryImpact: GQTcfdRiskImpact;
    id: Scalars['ID']['output'];
    kind: GQTcfdRiskKind;
    lowTrajectoryImpact: GQTcfdRiskImpact;
    mediumTrajectoryImpact: GQTcfdRiskImpact;
    physicalRiskSeverity: Maybe<GQTcfdPhysicalRiskSeverity>;
    possibleImpactDescription: Maybe<Scalars['String']['output']>;
    sectors: Array<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    userDescription: Scalars['String']['output'];
    whyWeChoseThisMd: Scalars['String']['output'];
  };

export const GQTcfdPhysicalRiskSeverity = {
  Acute: 'Acute',
  Chronic: 'Chronic',
} as const;

export type GQTcfdPhysicalRiskSeverity =
  (typeof GQTcfdPhysicalRiskSeverity)[keyof typeof GQTcfdPhysicalRiskSeverity];
export const GQTcfdRiskImpact = {
  High: 'High',
  Low: 'Low',
  Medium: 'Medium',
} as const;

export type GQTcfdRiskImpact =
  (typeof GQTcfdRiskImpact)[keyof typeof GQTcfdRiskImpact];
export const GQTcfdRiskKind = {
  Market: 'Market',
  Physical: 'Physical',
  PolicyAndLegal: 'PolicyAndLegal',
  Reputation: 'Reputation',
  Technology: 'Technology',
} as const;

export type GQTcfdRiskKind =
  (typeof GQTcfdRiskKind)[keyof typeof GQTcfdRiskKind];
export const GQTcfdStrategyModuleDistributionModel = {
  B2B: 'B2B',
  B2BAndB2C: 'B2BAndB2C',
  B2C: 'B2C',
} as const;

export type GQTcfdStrategyModuleDistributionModel =
  (typeof GQTcfdStrategyModuleDistributionModel)[keyof typeof GQTcfdStrategyModuleDistributionModel];
export const GQTeam = {
  AiAdoption: 'AIAdoption',
  Calcprint: 'Calcprint',
  CopyDemoData: 'CopyDemoData',
  DataInfrastructure: 'DataInfrastructure',
  DataIngestion: 'DataIngestion',
  DataScience: 'DataScience',
  DatasetsAndObjects: 'DatasetsAndObjects',
  DeveloperExperience: 'DeveloperExperience',
  Engineering: 'Engineering',
  EnterpriseFoundations: 'EnterpriseFoundations',
  Infrastructure: 'Infrastructure',
  Methodology: 'Methodology',
  ProductionGraph: 'ProductionGraph',
  Reductions: 'Reductions',
  Reporting: 'Reporting',
  SupplyChain: 'SupplyChain',
  TestTeam: 'TestTeam',
  Unowned: 'Unowned',
  WinCsrd: 'WinCsrd',
  WinFinance: 'WinFinance',
} as const;

export type GQTeam = (typeof GQTeam)[keyof typeof GQTeam];
export type GQTestingApproveReportQuestionInstancesInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
};

export type GQTestingApproveReportQuestionInstancesPayload = {
  __typename?: 'TestingApproveReportQuestionInstancesPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
};

export type GQTestingRejectReportQuestionInstancesInput = {
  comment: Scalars['String']['input'];
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
};

export type GQTestingRejectReportQuestionInstancesPayload = {
  __typename?: 'TestingRejectReportQuestionInstancesPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
};

export type GQTestingSubmitReportQuestionInstancesInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
};

export type GQTestingSubmitReportQuestionInstancesPayload = {
  __typename?: 'TestingSubmitReportQuestionInstancesPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
};

export type GQTestingWithdrawReportQuestionInstancesInput = {
  comment: Scalars['String']['input'];
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
};

export type GQTestingWithdrawReportQuestionInstancesPayload = {
  __typename?: 'TestingWithdrawReportQuestionInstancesPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
};

export type GQThrowErrorInput = {
  errorCode?: InputMaybe<Scalars['String']['input']>;
  fooString?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type GQThrowErrorPayload = {
  __typename?: 'ThrowErrorPayload';
  message: Maybe<Scalars['String']['output']>;
};

export type GQTimeseries = {
  __typename?: 'Timeseries';
  base: Scalars['YearMonth']['output'];
  frequency: GQTimeseriesFrequency;
  values: Array<Scalars['Float']['output']>;
};

export const GQTimeseriesFrequency = {
  Monthly: 'Monthly',
  Yearly: 'Yearly',
} as const;

export type GQTimeseriesFrequency =
  (typeof GQTimeseriesFrequency)[keyof typeof GQTimeseriesFrequency];
export type GQTraceBartValueDetails = {
  __typename?: 'TraceBartValueDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

export type GQTraceByFingerprintInput = {
  fingerprint: Scalars['String']['input'];
  trace: Scalars['String']['input'];
};

export type GQTraceDetails = {
  __typename?: 'TraceDetails';
  bartValueDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelEvaluationId: Maybe<Scalars['String']['output']>;
  emissionsModelVariableDetails: Array<Scalars['UntypedData']['output']>;
  emissionsModelVersionId: Maybe<Scalars['String']['output']>;
  referenceValueDetails: Array<Scalars['UntypedData']['output']>;
};

export type GQTraceDetailsResult = {
  __typename?: 'TraceDetailsResult';
  details: GQTraceDetails;
  fingerprint: Scalars['String']['output'];
};

export type GQTraceEmissionsModelVariableDetails = {
  __typename?: 'TraceEmissionsModelVariableDetails';
  description: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  pointer: Scalars['String']['output'];
};

export type GQTraceReferenceValueCitationDetails = {
  __typename?: 'TraceReferenceValueCitationDetails';
  displayName: Maybe<Scalars['String']['output']>;
  isCustomerProvided: Maybe<Scalars['Boolean']['output']>;
  notes: Maybe<Scalars['String']['output']>;
  org: GQTraceReferenceValueCitationDetailsOrg;
  publicDisclosureId: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueCitationDetailsOrg = {
  __typename?: 'TraceReferenceValueCitationDetailsOrg';
  orgId: Maybe<Scalars['String']['output']>;
  orgName: Maybe<Scalars['String']['output']>;
};

export type GQTraceReferenceValueDetails = {
  __typename?: 'TraceReferenceValueDetails';
  citation: GQTraceReferenceValueCitationDetails;
  description: Maybe<Scalars['String']['output']>;
  pointer: Scalars['String']['output'];
};

/**
 * #####################################################
 * Marketplace
 * #####################################################
 */
export type GQTrackedEditor = GQUser | GQWatershedEmployee;

export type GQTransformAndSubmitUploadInput = {
  uploadId: Scalars['ID']['input'];
};

export type GQTransformAndSubmitUploadPayload = {
  __typename?: 'TransformAndSubmitUploadPayload';
  jobId: Scalars['ID']['output'];
};

export type GQTransformRun = {
  __typename?: 'TransformRun';
  additionalOutput: Maybe<Scalars['JSONString']['output']>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  parameters: Maybe<Scalars['JSONString']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  tables: Array<GQTransformRunTable>;
  transformVersionId: Scalars['String']['output'];
};

export type GQTransformRunGraph = {
  __typename?: 'TransformRunGraph';
  orderedNodes: Array<Scalars['String']['output']>;
  transformRuns: Array<GQTransformRun>;
};

export type GQTransformRunTable = {
  __typename?: 'TransformRunTable';
  name: Scalars['String']['output'];
  pointer: GQTablePointer;
  relationship: GQTransformRunTableRelationship;
};

export const GQTransformRunTableRelationship = {
  Input: 'Input',
  Output: 'Output',
} as const;

export type GQTransformRunTableRelationship =
  (typeof GQTransformRunTableRelationship)[keyof typeof GQTransformRunTableRelationship];
export type GQUnassignFacilitiesFromTaskInput = {
  buildingIds: Array<Scalars['String']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUnassignFacilitiesFromTaskPayload = {
  __typename?: 'UnassignFacilitiesFromTaskPayload';
  facilities: Array<GQFacility>;
  measurementProject: GQMeasurementProject;
};

export type GQUnassignFromContainerInput = {
  assigneeId: Scalars['ID']['input'];
  containerId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
};

export type GQUnassignFromContainerPayload = {
  __typename?: 'UnassignFromContainerPayload';
  container: GQReportQuestionContainer;
  unassignedCount: Scalars['Float']['output'];
};

export type GQUnboundParameterBinding = {
  expression: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type GQUncompleteUserUploadTaskInput = {
  userUploadTaskId: Scalars['ID']['input'];
};

export type GQUniqueExternalReportFilterValues = {
  __typename?: 'UniqueExternalReportFilterValues';
  companies: Array<GQCompanyFilterValues>;
  publishingYears: Array<Scalars['Int']['output']>;
  reportTypes: Array<GQExternalReportType>;
};

export type GQUnitConversionRecord = {
  __typename?: 'UnitConversionRecord';
  assumptionId: Maybe<Scalars['String']['output']>;
  assumptionUuid: Maybe<Scalars['String']['output']>;
  conversionDescription: Maybe<Scalars['String']['output']>;
  conversionMultiplier: Maybe<Scalars['Float']['output']>;
  inputUnit: Maybe<Scalars['String']['output']>;
  outputUnit: Maybe<Scalars['String']['output']>;
};

export type GQUnlockDataInput = {
  comment: Scalars['String']['input'];
  targetId: Scalars['ID']['input'];
  targetKind: Scalars['String']['input'];
};

export type GQUnlockDataPayload = {
  __typename?: 'UnlockDataPayload';
  newChangelogEvents: Array<GQDataApprovalChangelogEvent>;
  target: GQUserUploadTask;
};

export type GQUnpublishCtsVersionInput = {
  id: Scalars['ID']['input'];
};

export type GQUnpublishCtsVersionPayload = {
  __typename?: 'UnpublishCtsVersionPayload';
  ctsVersion: GQCustomerTargetSchemaVersion;
  customerTargetSchema: GQCustomerTargetSchema;
};

export type GQUnpublishOrgStructureVersionInput = {
  id: Scalars['ID']['input'];
  reason: Scalars['String']['input'];
};

export type GQUnpublishOrgStructureVersionPayload = {
  __typename?: 'UnpublishOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQUpdateApproversForReportQuestionInstancesInput = {
  approverActions: Array<GQReportQuestionApproverActionInput>;
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateApproversForReportQuestionInstancesPayload = {
  __typename?: 'UpdateApproversForReportQuestionInstancesPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
};

export type GQUpdateAssetCommentInput = {
  commentText: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetCommentPayload = {
  __typename?: 'UpdateAssetCommentPayload';
  assetComment: GQAssetComment;
};

export type GQUpdateAssetCorporateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  companyBea?: InputMaybe<Scalars['String']['input']>;
  companyDunsNumber?: InputMaybe<Scalars['String']['input']>;
  companyEin?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  companyIsin?: InputMaybe<Scalars['String']['input']>;
  companyLei?: InputMaybe<Scalars['String']['input']>;
  companyLocalRegistryId?: InputMaybe<Scalars['String']['input']>;
  companySAndPId?: InputMaybe<Scalars['String']['input']>;
  companyTicker?: InputMaybe<Scalars['String']['input']>;
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  companyVatId?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  naicsCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetCorporatePayload = {
  __typename?: 'UpdateAssetCorporatePayload';
  asset: Maybe<GQAssetCorporate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetGroupInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetGroupPayload = {
  __typename?: 'UpdateAssetGroupPayload';
  asset: Maybe<GQAssetGroup>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetHoldingNewInput = {
  action: GQFinanceAuditAction;
  assetClass?: InputMaybe<GQFundAssetClass>;
  attributionFactorOverride?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  fundId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  outstandingAmountNative?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ1?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ2?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ3?: InputMaybe<Scalars['Float']['input']>;
  outstandingAmountNativeQ4?: InputMaybe<Scalars['Float']['input']>;
  premiumNative?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  yearOfInitialInvestment?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetHoldingPayload = {
  __typename?: 'UpdateAssetHoldingPayload';
  assetHolding: GQFinanceAssetHolding;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetPersonalMotorVehicleInsuranceInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetPersonalMotorVehicleInsurancePayload = {
  __typename?: 'UpdateAssetPersonalMotorVehicleInsurancePayload';
  asset: Maybe<GQAssetPersonalMotorVehicleInsurance>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetRealEstateInput = {
  action: GQFinanceAuditAction;
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingSizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<GQCountryAlpha2>;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetRealEstatePayload = {
  __typename?: 'UpdateAssetRealEstatePayload';
  asset: Maybe<GQAssetRealEstate>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetSovereignBondInput = {
  action: GQFinanceAuditAction;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  sovereignEntity?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateAssetSovereignBondPayload = {
  __typename?: 'UpdateAssetSovereignBondPayload';
  asset: Maybe<GQAssetSovereignBond>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYear = {
  companyValueUsdCents?: InputMaybe<Scalars['Int53']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateAssetYearNewInput = {
  action: GQFinanceAuditAction;
  assetCommercialRealEstateId?: InputMaybe<Scalars['ID']['input']>;
  assetCorporateId?: InputMaybe<Scalars['ID']['input']>;
  assetGroupId?: InputMaybe<Scalars['ID']['input']>;
  assetPersonalMotorVehicleInsuranceId?: InputMaybe<Scalars['ID']['input']>;
  assetSovereignBondId?: InputMaybe<Scalars['ID']['input']>;
  buildingSizeNative?: InputMaybe<Scalars['Int']['input']>;
  cloudSpendNative?: InputMaybe<Scalars['Float']['input']>;
  committedToNearTermSbtAlignedNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  currencyCode?: InputMaybe<GQCurrencyCode>;
  demonstratingYoyEmissionsInLineWithNetZero?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  emissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  estimationMethodology?: InputMaybe<GQEstimationMethodology>;
  flightMiles?: InputMaybe<Scalars['Int']['input']>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  iciAlignmentOverride?: InputMaybe<GQIciAlignmentStatus>;
  id: Scalars['ID']['input'];
  initiatedDecarbonizationPlan?: InputMaybe<Scalars['Boolean']['input']>;
  measuringMaterialScope3Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  measuringScope1And2Emissions?: InputMaybe<Scalars['Boolean']['input']>;
  percentEmployeesWfh?: InputMaybe<Scalars['Float']['input']>;
  percentRenewable?: InputMaybe<Scalars['Float']['input']>;
  revenueNative?: InputMaybe<Scalars['Float']['input']>;
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
  scenario?: InputMaybe<Scalars['String']['input']>;
  scope1OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope1OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2LocationOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2LocationOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope2MarketOverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope2MarketOverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope3OverrideKind?: InputMaybe<GQScope3OverrideKind>;
  scope3OverridePcaf?: InputMaybe<Scalars['Int']['input']>;
  scope3OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope301OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope302OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope303OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope304OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope305OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope306OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope307OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope308OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope309OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope310OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope311OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope312OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope313OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope314OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  scope315OverrideTco2e?: InputMaybe<Scalars['Float']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
  totalMwh?: InputMaybe<Scalars['Float']['input']>;
  valueNative?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearNewPayload = {
  __typename?: 'UpdateAssetYearNewPayload';
  assetYear: GQFinanceAssetYearNew;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearRevenueSourceInput = {
  action?: InputMaybe<GQFinanceAuditAction>;
  assetYearId: Scalars['ID']['input'];
  revenueSourceEmissionsYearId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateAssetYearRevenueSourcePayload = {
  __typename?: 'UpdateAssetYearRevenueSourcePayload';
  assetYearId: Scalars['ID']['output'];
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateAssetYearsNewInput = {
  action: GQFinanceAuditAction;
  assetYears: Array<GQUpdateAssetYearNewInput>;
  fileInfo?: InputMaybe<GQFinanceAuditFileInfoInput>;
  /** @deprecated No longer needed */
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateAssetYearsNewPayload = {
  __typename?: 'UpdateAssetYearsNewPayload';
  assetYears: Array<GQFinanceAssetYearNew>;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateBiCustomMetricInput = {
  expression: Scalars['Expression']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type GQUpdateBiSavedViewInput = {
  chartKind: GQBiChartKind;
  customMetrics?: InputMaybe<Array<GQBiCustomMetricRefInput>>;
  dimensions: Array<Scalars['String']['input']>;
  editVisibility: GQBiSavedViewVisibility;
  filters: Array<GQBiQueryFilterInput>;
  /** @deprecated Use stableId instead. */
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  normalizedMeasures: Array<GQBiDynamicNormalizedMeasureInput>;
  order?: InputMaybe<GQBiQueryOrderInput>;
  ownerId: Scalars['ID']['input'];
  queryContext?: InputMaybe<GQBiSavedViewQueryContextInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  stableId?: InputMaybe<Scalars['ID']['input']>;
  timeIntervals: Array<Scalars['YMInterval']['input']>;
  viewVisibility: GQBiSavedViewVisibility;
};

export type GQUpdateBuildingInput = {
  buildingKind?: InputMaybe<Scalars['String']['input']>;
  buildingName: Scalars['String']['input'];
  buildingSubkind?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  endYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  id: Scalars['ID']['input'];
  isWithoutNaturalGas?: InputMaybe<Scalars['Boolean']['input']>;
  isWithoutRefrigerants?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  leaseType?: InputMaybe<GQBuildingLeaseType>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  needsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  periods?: InputMaybe<Array<GQCreateBuildingPeriodInput>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sizeUnit?: InputMaybe<GQBuildingSizeUnit>;
  startYearMonth?: InputMaybe<Scalars['YearMonth']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  supportingDocumentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateBuildingPayload = {
  __typename?: 'UpdateBuildingPayload';
  buildingUntyped: GQBuildingUntyped;
  facility: GQFacility;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQUpdateBuildingUtilityInput = {
  cleanPowerContractualInstrument?: InputMaybe<GQCleanPowerContractualInstrument>;
  cleanPowerPercent?: InputMaybe<Scalars['Float']['input']>;
  customFieldValues?: InputMaybe<Array<GQBuildingUtilityCustomFieldInput>>;
  fuelCombustionReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isWasteHazardous?: InputMaybe<Scalars['Boolean']['input']>;
  isWasteRadioactive?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  onsiteCleanFuelKind?: InputMaybe<Scalars['String']['input']>;
  periodEnd?: InputMaybe<Scalars['Date']['input']>;
  periodStart?: InputMaybe<Scalars['Date']['input']>;
  productExternalId?: InputMaybe<Scalars['String']['input']>;
  productName?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  refrigerantLeakageSource?: InputMaybe<GQRefrigerantLeakageSource>;
  refrigerantName?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<GQBuildingUtilityUnit>;
  userUploadTaskId?: InputMaybe<Scalars['ID']['input']>;
  wasteMaterial?: InputMaybe<GQBuildingWasteMaterial>;
  wasteTreatment?: InputMaybe<GQBuildingWasteTreatment>;
  waterUseKind?: InputMaybe<GQBuildingWaterUseKind>;
};

export type GQUpdateBuildingUtilityPayload = {
  __typename?: 'UpdateBuildingUtilityPayload';
  /** @deprecated Use buildingWithUtilities.completionIntervals instead */
  buildingCompletionIntervalsUntyped: GQBuildingCompletionIntervalsUntyped;
  buildingUtility: GQBuildingUtility;
  buildingWithUtilities: GQBuildingWithUtilities;
  updatedUserUploadTask: Maybe<GQUserUploadTask>;
};

export type GQUpdateBuildingsInput = {
  buildings: Array<GQUpdateBuildingInput>;
  /** @deprecated Use UpdateBuildingInput.needsConfirmation instead */
  setNeedsConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateBuildingsPayload = {
  __typename?: 'UpdateBuildingsPayload';
  buildingsUntyped: Array<GQBuildingUntyped>;
  organization: GQOrganization;
  updatedUserUploadTasks: Array<GQUserUploadTask>;
};

export type GQUpdateCandidateRowInput = {
  data: Scalars['JSONString']['input'];
  userUploadCandidateId: Scalars['ID']['input'];
  userUploadCandidateRowId: Scalars['ID']['input'];
};

export type GQUpdateClimateProgramCalendarEventInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type GQUpdateClimateProgramProjectDatasetRequirementInput = {
  additionalNotesMd?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type GQUpdateClimateProgramProjectInput = {
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQUpdateClimateProgramTimelineProjectInput = {
  completedAt?: InputMaybe<Scalars['Date']['input']>;
  customKind?: InputMaybe<GQClimateProgramProjectKind>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  objectType: GQClimateProgramTimelineProjectObjectType;
  plannedYear?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type GQUpdateCtsFormInput = {
  ctsFormId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type GQUpdateCtsInput = {
  canonicalDatasetIds: Array<Scalars['ID']['input']>;
  ctsId: Scalars['ID']['input'];
  descriptionMd?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rank: Scalars['Int']['input'];
};

export type GQUpdateCtsPayload = {
  __typename?: 'UpdateCtsPayload';
  schema: GQCustomerTargetSchema;
};

export type GQUpdateDatasourceInput = {
  canonicalDatasourceId?: InputMaybe<Scalars['ID']['input']>;
  customerTargetSchemaIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  importInstructionsMd?: InputMaybe<Scalars['String']['input']>;
  instructionsConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isUserConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  refreshCadence?: InputMaybe<GQDatasetCadence>;
};

export type GQUpdateEngagementTaskConfigInput = {
  containers?: InputMaybe<Array<GQEngagementTaskContainerInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  questions: Array<GQEngagementTaskQuestionInput>;
  sidebarTitle?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateEngagementTaskConfigPayload = {
  __typename?: 'UpdateEngagementTaskConfigPayload';
  engagementTaskConfig: GQEngagementTaskConfig;
};

export type GQUpdateEngagementTaskInput = {
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  engagementTaskId: Scalars['ID']['input'];
  rootAssignee?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<GQCompanySurveyStatus>;
  supplierAssignee?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdateEngagementTaskPayload = {
  __typename?: 'UpdateEngagementTaskPayload';
  engagementTask: Maybe<GQEngagementTask>;
  runCeeBackgroundJobId: Maybe<Scalars['ID']['output']>;
};

export type GQUpdateFinanceAutomaticRemindersInput = {
  dataUploadConfig: Scalars['JSONString']['input'];
  dataUploadDate: Scalars['DateTime']['input'];
  footprintFinalizationConfig: Scalars['JSONString']['input'];
  footprintFinalizationDate: Scalars['DateTime']['input'];
};

export type GQUpdateFinanceAutomaticRemindersPayload = {
  __typename?: 'UpdateFinanceAutomaticRemindersPayload';
  financeAutomaticReminders: Array<GQFinanceAutomaticReminder>;
};

export type GQUpdateFinanceColumnWidthInput = {
  id: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type GQUpdateFinanceSavedViewInput = {
  charts?: InputMaybe<Array<Scalars['JSONString']['input']>>;
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  layout?: InputMaybe<Scalars['String']['input']>;
  metrics?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFinanceSavedViewPayload = {
  __typename?: 'UpdateFinanceSavedViewPayload';
  financeSavedView: GQFinanceSavedView;
};

export type GQUpdateFinanceSettingsInput = {
  disabledFinanceDataFlags?: InputMaybe<Array<Scalars['String']['input']>>;
  estimateDownstreamScope3?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateFinanceSettingsPayload = {
  __typename?: 'UpdateFinanceSettingsPayload';
  financeSettings: GQFinanceSettings;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateFinanceTagInput = {
  columnConfiguration?: InputMaybe<Scalars['UntypedData']['input']>;
  columnFormat?: InputMaybe<GQGridColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tagType?: InputMaybe<GQFinanceTagType>;
};

export type GQUpdateFinanceTagPayload = {
  __typename?: 'UpdateFinanceTagPayload';
  financeTag: GQFinanceTag;
};

export type GQUpdateFinanceWorksheetInput = {
  setColumnOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  setColumnWidth?: InputMaybe<GQUpdateFinanceColumnWidthInput>;
  setHiddenColumns?: InputMaybe<Array<Scalars['ID']['input']>>;
  worksheetId: Scalars['ID']['input'];
};

export type GQUpdateFinanceWorksheetPayload = {
  __typename?: 'UpdateFinanceWorksheetPayload';
  worksheet: GQFinanceWorksheet;
};

export type GQUpdateFootprintAssemblyRunStatusInput = {
  id: Scalars['ID']['input'];
  status: GQFootprintAssemblyRunStatus;
};

export type GQUpdateFootprintAssemblyRunStatusPayload = {
  __typename?: 'UpdateFootprintAssemblyRunStatusPayload';
  footprintAssemblyRun: GQFootprintAssemblyRun;
};

export type GQUpdateFootprintSliceInitiativeInput = {
  costTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  initiativeId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reductionTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  scheduleType?: InputMaybe<GQScheduleType>;
};

export type GQUpdateFootprintSliceInitiativePayload = {
  __typename?: 'UpdateFootprintSliceInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQUpdateForecastInput = {
  acknowledgedFootprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  createdCustomIntensityConfigs?: InputMaybe<
    Array<GQCreateCustomIntensityConfigInput>
  >;
  footprintSnapshotId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  intervalEnd?: InputMaybe<Scalars['YearMonth']['input']>;
  referencePeriodInterval?: InputMaybe<Scalars['YMInterval']['input']>;
  updatedCustomIntensityConfigs?: InputMaybe<
    Array<GQCustomIntensityConfigInput>
  >;
};

export type GQUpdateForecastPayload = {
  __typename?: 'UpdateForecastPayload';
  forecast: GQForecast;
  plans: Array<GQPlan>;
};

export type GQUpdateForecastScenarioInput = {
  deletedGrowthForecastIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  growthForecasts?: InputMaybe<Array<GQGrowthForecastInput>>;
  id: Scalars['ID']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateFundInput = {
  excludeAsSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['ID']['input']>;
  fundCategory?: InputMaybe<Scalars['String']['input']>;
  fundGroup?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nameSortable?: InputMaybe<Scalars['String']['input']>;
  tagData?: InputMaybe<Scalars['UntypedData']['input']>;
};

export type GQUpdateFundPayload = {
  __typename?: 'UpdateFundPayload';
  fund: GQFund;
  invalidatedSnapshots: Array<GQFinanceSnapshot>;
};

export type GQUpdateMarketplacePurchaseEacMetadataInput = {
  ghgCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  interval: Scalars['YMInterval']['input'];
  marketTco2e: Scalars['Float']['input'];
  requestNotes?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMaterialityAssessmentInput = {
  materialityAssessmentId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateMaterialityAssessmentPayload = {
  __typename?: 'UpdateMaterialityAssessmentPayload';
  materialityAssessments: Array<GQMaterialityAssessment>;
  updated: GQMaterialityAssessment;
};

export type GQUpdateMeasurementProjectInput = {
  coverageEndDate?: InputMaybe<Scalars['Date']['input']>;
  coverageStartDate?: InputMaybe<Scalars['Date']['input']>;
  deadline?: InputMaybe<Scalars['Date']['input']>;
  isApprovalFlowEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  kickoff?: InputMaybe<Scalars['Date']['input']>;
  measurementProjectId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUpdateMeasurementProjectPayload = {
  __typename?: 'UpdateMeasurementProjectPayload';
  measurementProject: GQMeasurementProject;
};

export type GQUpdateOrgInput = {
  cookieTimeoutDays?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  magicLinkDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  passwordAuthDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  sessionTimeoutMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type GQUpdateOrgPayload = {
  __typename?: 'UpdateOrgPayload';
  organization: Maybe<GQOrganization>;
};

export type GQUpdateOrgProfileInput = {
  climateCommitments?: InputMaybe<Array<GQCompanyClimateCommitmentKind>>;
  climateGoals?: InputMaybe<Array<Scalars['String']['input']>>;
  climateMotivation?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fiscalYearStartMonth?: InputMaybe<Scalars['Int']['input']>;
  hasClimateCommitments?: InputMaybe<GQYesNoUnknown>;
  hasMeasuredFootprintOutsideOfWatershed?: InputMaybe<GQYesNoUnknown>;
  industry?: InputMaybe<Scalars['String']['input']>;
  isCompanyDataConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isComplete?: InputMaybe<Scalars['Boolean']['input']>;
  lastNonWatershedMeasurementYearString?: InputMaybe<
    Scalars['String']['input']
  >;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['ID']['input']>;
  otherClimateCommitment?: InputMaybe<Scalars['String']['input']>;
  otherOperatingCountries?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQUpdateOrgProfilePayload = {
  __typename?: 'UpdateOrgProfilePayload';
  orgProfile: GQOrgProfile;
  organization: GQOrganization;
};

export type GQUpdateOrgUnitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  relationships?: InputMaybe<Array<GQUpdateOrgUnitRelationshipInput>>;
};

export type GQUpdateOrgUnitRelationshipInput = {
  orgUnitIdFrom: Scalars['ID']['input'];
  orgUnitIdTo: Scalars['ID']['input'];
};

export type GQUpdateOrgUnitTypeInput = {
  enabledDatasets?: InputMaybe<Array<GQDatasetForOrgUnitTypeInput>>;
  enabledForAllDatasets?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isLegalEntity?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredForAllDatasets?: InputMaybe<Scalars['Boolean']['input']>;
  versionId: Scalars['ID']['input'];
};

export type GQUpdateOrgUnitTypePayload = {
  __typename?: 'UpdateOrgUnitTypePayload';
  orgUnitType: GQOrgUnitType;
};

export type GQUpdateOrgUnitTypeRelationshipInput = {
  cardinality?: InputMaybe<GQOrgUnitRelationshipCardinality>;
  defaultOrgUnitId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateOrgUnitTypeRelationshipPayload = {
  __typename?: 'UpdateOrgUnitTypeRelationshipPayload';
  orgUnitTypeRelationship: GQOrgUnitTypeRelationship;
  orgUnits: Array<GQOrgUnit>;
};

export type GQUpdateOrgUnitsInput = {
  orgUnits: Array<GQUpdateOrgUnitInput>;
};

export type GQUpdateOrgUnitsPayload = {
  __typename?: 'UpdateOrgUnitsPayload';
  orgUnits: Array<GQOrgUnit>;
};

export type GQUpdatePeerCompanyGroupInput = {
  footprintKinds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  revenueForRevenueAtRisk?: InputMaybe<Scalars['Int53']['input']>;
  shouldHideFromFootprintOverview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdatePermissionsInput = {
  orgAccessId?: InputMaybe<Scalars['ID']['input']>;
  permissionsToGrant: Array<Scalars['String']['input']>;
  permissionsToRevoke: Array<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQUpdatePermissionsPayload = {
  __typename?: 'UpdatePermissionsPayload';
  permissions: Scalars['UntypedData']['output'];
};

export type GQUpdatePlanAndArtifactsInput = {
  carbonOffsets?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonOffsetsCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonRemoval?: InputMaybe<GQSimpleTimeseriesInput>;
  carbonRemovalCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  hideCarbonAvoidance?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  reduxCarbonOffsets?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonOffsetsCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonRemoval?: InputMaybe<GQSimpleTimeseriesInput>;
  reduxCarbonRemovalCosts?: InputMaybe<GQSimpleTimeseriesInput>;
  targets?: InputMaybe<Array<GQAbbreviatedPlanTargetInput>>;
  variables?: InputMaybe<GQPlanVariablesInput>;
};

export type GQUpdatePlanInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['ID']['input'];
  planId: Scalars['ID']['input'];
  variables?: InputMaybe<GQPlanVariablesInput>;
};

export type GQUpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: GQPlan;
};

export type GQUpdatePlanSettingsInput = {
  baselineYearStart?: InputMaybe<Scalars['YearMonth']['input']>;
  isSbtValidated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  planSubmissionDate?: InputMaybe<Scalars['YearMonth']['input']>;
  termLength?: InputMaybe<GQSbtTermLength>;
};

export type GQUpdatePlanSettingsPayload = {
  __typename?: 'UpdatePlanSettingsPayload';
  plan: GQPlan;
};

export type GQUpdatePlanStateInput = {
  planId: Scalars['ID']['input'];
  state: GQPlanState;
};

export type GQUpdatePlanStatePayload = {
  __typename?: 'UpdatePlanStatePayload';
  plan: GQPlan;
};

export type GQUpdatePlanTargetCategorySetPayload = {
  __typename?: 'UpdatePlanTargetCategorySetPayload';
  planTarget: GQPlanTarget;
};

export type GQUpdatePlanTargetInput = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  baseYear?: InputMaybe<Scalars['YearMonth']['input']>;
  comparisonType?: InputMaybe<GQPlanTargetTargetComparisonType>;
  customIntensityConfigId?: InputMaybe<Scalars['ID']['input']>;
  customNotes?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Switching to targetYearInclusive and reductionAmount */
  emissionsTargetCustom?: InputMaybe<GQSimpleTimeseriesInput>;
  filters?: InputMaybe<GQFilterExpressionGroupInput>;
  id: Scalars['ID']['input'];
  intensityType?: InputMaybe<GQPlanTargetIntensityType>;
  interimTarget?: InputMaybe<GQInterimTargetInput>;
  reductionAmount?: InputMaybe<Scalars['Float']['input']>;
  reductionRate?: InputMaybe<GQTargetReductionRate>;
  reportDescription?: InputMaybe<Scalars['String']['input']>;
  targetYearInclusive?: InputMaybe<Scalars['YearMonth']['input']>;
};

export type GQUpdatePlanTargetPayload = {
  __typename?: 'UpdatePlanTargetPayload';
  forecast: GQForecast;
  planTarget: GQPlanTarget;
};

export type GQUpdatePrecomputedActivityBasedInitiativeInput = {
  costTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  implementationTimeseries?: InputMaybe<GQSimpleTimeseriesInput>;
  initiativeId: Scalars['ID']['input'];
  lever?: InputMaybe<GQPrecomputedLever>;
  scheduleType?: InputMaybe<GQScheduleType>;
};

export type GQUpdatePrecomputedActivityBasedInitiativePayload = {
  __typename?: 'UpdatePrecomputedActivityBasedInitiativePayload';
  initiative: GQFootprintSliceInitiative | GQPrecomputedActivityBasedInitiative;
  plan: GQPlan;
};

export type GQUpdatePrivateDisclosureInput = {
  disclosureTargets?: InputMaybe<Array<GQDisclosureTargetInput>>;
  privateDisclosureId: Scalars['ID']['input'];
};

export type GQUpdatePrivateDisclosurePayload = {
  __typename?: 'UpdatePrivateDisclosurePayload';
  disclosure: GQDisclosure;
};

export type GQUpdateReportAnswerOverrideStatesInput = {
  overrideState: GQReportAnswerOverrideState;
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateReportAnswerOverrideStatesPayload = {
  __typename?: 'UpdateReportAnswerOverrideStatesPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQUpdateReportConfigDashboardInput = {
  id: Scalars['ID']['input'];
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQUpdateReportConfigDashboardPayload = {
  __typename?: 'UpdateReportConfigDashboardPayload';
  reportConfig: GQReportConfig;
};

export type GQUpdateReportConfigInput = {
  brandColor?: InputMaybe<Scalars['String']['input']>;
  computedQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inputQuestionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isCorporateReport?: InputMaybe<Scalars['Boolean']['input']>;
  isCreationBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  isFinanceReport?: InputMaybe<Scalars['Boolean']['input']>;
  isHiddenFromOverview?: InputMaybe<Scalars['Boolean']['input']>;
  isRegulatoryExposureBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  itemIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  longName?: InputMaybe<Scalars['String']['input']>;
  reportObjectives?: InputMaybe<Array<GQCanonicalReportObjective>>;
  reportType?: InputMaybe<Scalars['String']['input']>;
  requiredPermissions?: InputMaybe<Array<GQPermissionType>>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GQReportConfigStatus>;
  useApprovals?: InputMaybe<Scalars['Boolean']['input']>;
  useMateriality?: InputMaybe<Scalars['Boolean']['input']>;
  useOverrides?: InputMaybe<Scalars['Boolean']['input']>;
  useReportingIntention?: InputMaybe<Scalars['Boolean']['input']>;
  useTableDisplay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateReportConfigPayload = {
  __typename?: 'UpdateReportConfigPayload';
  reportConfig: GQReportConfig;
};

export type GQUpdateReportInput = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportStatus?: InputMaybe<GQReportStatus>;
};

export type GQUpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  report: GQReport;
};

export type GQUpdateReportQuestionAssigneesInput = {
  assigneeIds: Array<Scalars['ID']['input']>;
  ignoredAssigneeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  questionIds: Array<Scalars['ID']['input']>;
  reportId: Scalars['ID']['input'];
  reportShortName?: InputMaybe<Scalars['String']['input']>;
  shouldSendEmail: Scalars['Boolean']['input'];
};

export type GQUpdateReportQuestionAssigneesPayload = {
  __typename?: 'UpdateReportQuestionAssigneesPayload';
  questions: Array<GQReportQuestion>;
};

export type GQUpdateReportQuestionContainerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  items?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQUpdateReportQuestionContainerPayload = {
  __typename?: 'UpdateReportQuestionContainerPayload';
  container: GQReportQuestionContainer;
};

export type GQUpdateReportQuestionInput = {
  allowNotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowedAttachmentKinds?: InputMaybe<Array<GQReportAttachmentItemKind>>;
  componentId?: InputMaybe<Scalars['String']['input']>;
  dynamicInputs?: InputMaybe<Scalars['JSONString']['input']>;
  guidance?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['ID']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  isComputed?: InputMaybe<Scalars['Boolean']['input']>;
  isDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  staticInputs?: InputMaybe<Scalars['JSONString']['input']>;
  status?: InputMaybe<Scalars['JSONString']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateReportQuestionNoteInput = {
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
  resolved?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateReportQuestionNoteItemInput = {
  markdown?: InputMaybe<Scalars['String']['input']>;
  memberUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  reportId: Scalars['String']['input'];
  reportQuestionId: Scalars['String']['input'];
  reportQuestionNoteId: Scalars['String']['input'];
  reportQuestionNoteItemId: Scalars['String']['input'];
  status?: InputMaybe<GQReportQuestionNoteItemStatus>;
};

export type GQUpdateReportQuestionNoteItemPayload = {
  __typename?: 'UpdateReportQuestionNoteItemPayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQUpdateReportQuestionNotePayload = {
  __typename?: 'UpdateReportQuestionNotePayload';
  discussion: GQDiscussion;
  reportQuestion: GQReportQuestion;
};

export type GQUpdateReportQuestionPayload = {
  __typename?: 'UpdateReportQuestionPayload';
  question: GQReportQuestion;
};

export type GQUpdateReportingIntentionsInput = {
  intention: GQReportingIntention;
  reason?: InputMaybe<Scalars['String']['input']>;
  reportId: Scalars['ID']['input'];
  reportQuestionIds: Array<Scalars['ID']['input']>;
};

export type GQUpdateReportingIntentionsPayload = {
  __typename?: 'UpdateReportingIntentionsPayload';
  reportQuestions: Array<GQReportQuestion>;
};

export type GQUpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<GQPermissionItemInput>;
  roleId: Scalars['ID']['input'];
  watershedManaged?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  role: GQRole;
};

export type GQUpdateSnapshotNameInput = {
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  snapshotName: Scalars['String']['input'];
};

export type GQUpdateSnapshotNamePayload = {
  __typename?: 'UpdateSnapshotNamePayload';
  snapshot: GQFinanceSnapshot;
};

export type GQUpdateSupplierAttachmentInput = {
  companyId: Scalars['ID']['input'];
  fileMetadataId: Scalars['String']['input'];
  isImported: Scalars['Boolean']['input'];
};

export type GQUpdateSupplierAttachmentPayload = {
  __typename?: 'UpdateSupplierAttachmentPayload';
  attachment: GQSupplierAttachment;
};

export type GQUpdateSupplierChartInput = {
  id: Scalars['ID']['input'];
  supplierChartInput: GQSupplierChartInput;
};

export type GQUpdateSupplierChartPayload = {
  __typename?: 'UpdateSupplierChartPayload';
  views: Array<GQSupplierView>;
};

export type GQUpdateSupplierColumnOrderInput = {
  columnIds: Array<Scalars['ID']['input']>;
  viewId: Scalars['ID']['input'];
};

export type GQUpdateSupplierColumnOrderPayload = {
  __typename?: 'UpdateSupplierColumnOrderPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierColumnVisibilityBatchInput = {
  columnVisibilities: Array<GQColumnVisibility>;
  viewId: Scalars['ID']['input'];
};

export type GQUpdateSupplierColumnVisibilityBatchPayload = {
  __typename?: 'UpdateSupplierColumnVisibilityBatchPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierColumnWidthInput = {
  columnId: Scalars['ID']['input'];
  viewId: Scalars['ID']['input'];
  width: Scalars['Int']['input'];
};

export type GQUpdateSupplierColumnWidthPayload = {
  __typename?: 'UpdateSupplierColumnWidthPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierContactInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierContactPayload = {
  __typename?: 'UpdateSupplierContactPayload';
  contact: GQSupplierContact;
};

export type GQUpdateSupplierCustomDataInput = {
  companyId: Scalars['ID']['input'];
  customData?: InputMaybe<Scalars['JSONString']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<GQSupplierPriority>;
  supplierId: Scalars['ID']['input'];
};

export type GQUpdateSupplierCustomDataPayload = {
  __typename?: 'UpdateSupplierCustomDataPayload';
  supplierCustomData: GQSupplierCustomData;
  supplierId: Scalars['ID']['output'];
};

export type GQUpdateSupplierSavedViewInput = {
  chartOrder?: InputMaybe<Array<GQChartOrderInput>>;
  filters?: InputMaybe<GQFilterExpressionGroupWithNewFiltersInput>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierSavedViewPayload = {
  __typename?: 'UpdateSupplierSavedViewPayload';
  view: GQSupplierView;
};

export type GQUpdateSupplierScoreCriteriaInput = {
  filters?: InputMaybe<Array<GQBiQueryFilterInput>>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type GQUpdateSupplierScoreCriteriaPayload = {
  __typename?: 'UpdateSupplierScoreCriteriaPayload';
  supplierScoreCriteria: GQSupplierScoreCriteria;
};

export type GQUpdateSupplierTableColumnInput = {
  columnFormat?: InputMaybe<GQSupplierTableColumnFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  headerName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  selectOptions?: InputMaybe<Array<Scalars['String']['input']>>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateSupplierTableColumnPayload = {
  __typename?: 'UpdateSupplierTableColumnPayload';
  views: Array<GQSupplierView>;
};

export type GQUpdateSuppliersSettingsInput = {
  footprintSnapshotIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hideGlobalCharts?: InputMaybe<Scalars['Boolean']['input']>;
  includedGhgCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  suppressNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  viewLatestSupplierMapping?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpdateSuppliersSettingsPayload = {
  __typename?: 'UpdateSuppliersSettingsPayload';
  suppliersSettings: GQSuppliersSettings;
};

export type GQUpdateSupportCaseInput = {
  priority?: InputMaybe<GQSupportCasePriority>;
  shouldSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<GQSupportCaseStatus>;
  supportCaseId: Scalars['ID']['input'];
};

export type GQUpdateSupportCasePayload = {
  __typename?: 'UpdateSupportCasePayload';
  supportCase: GQSupportCase;
};

export type GQUpdateUserIngestionReviewInput = {
  additionalQuestions?: InputMaybe<Array<GQAdditionalQuestionInput>>;
  /** @deprecated unused */
  canonicalDatasetKind?: InputMaybe<GQCanonicalDatasetKind>;
  /** @deprecated unused */
  datasourceId?: InputMaybe<Scalars['String']['input']>;
  ingestionSchemaId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated unused */
  lastUploadInSessionCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  measurementEventKind: GQDataIngestionMeasurementEventKind;
  /** @deprecated unused */
  measurementProjectId?: InputMaybe<Scalars['String']['input']>;
  shouldTriggerParseAttempt?: InputMaybe<Scalars['Boolean']['input']>;
  userReviewMetadata?: InputMaybe<GQUserReviewMetadataInput>;
  userUploadedTableId: Scalars['ID']['input'];
};

export type GQUpdateUserIngestionReviewPayload = {
  __typename?: 'UpdateUserIngestionReviewPayload';
  userIngestionReview: GQUserIngestionReview;
  userUploadedTable: GQUserUploadedTable;
};

export type GQUpdateUserInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  permissionsToGrant: Array<Scalars['ID']['input']>;
  permissionsToRevoke: Array<Scalars['ID']['input']>;
  rolesToGrant: Array<Scalars['ID']['input']>;
  rolesToRevoke: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserNameInput = {
  newName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GQUpdateUserNamePayload = {
  __typename?: 'UpdateUserNamePayload';
  user: GQUser;
};

export type GQUpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: GQUser;
};

export type GQUpdateUserPermissionRequestInput = {
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<GQUserPermissionRequestState>;
  userPermissionRequestId: Scalars['ID']['input'];
};

export type GQUpdateUserPermissionRequestPayload = {
  __typename?: 'UpdateUserPermissionRequestPayload';
  userPermissionRequest: Maybe<GQUserPermissionRequest>;
};

export type GQUpdateUserUploadInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
};

export type GQUpdateUserUploadPayload = {
  __typename?: 'UpdateUserUploadPayload';
  userUpload: Maybe<GQUserUpload>;
};

export type GQUpdateWatershedFootprintReviewRequestStatusInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  shouldSendReplyToDiscussionThread?: InputMaybe<Scalars['Boolean']['input']>;
  status: GQWatershedFootprintReviewRequestStatus;
  watershedFootprintReviewRequestId: Scalars['ID']['input'];
};

export type GQUpdateWatershedFootprintReviewRequestStatusPayload = {
  __typename?: 'UpdateWatershedFootprintReviewRequestStatusPayload';
  watershedFootprintReviewRequest: GQWatershedFootprintReviewRequest;
};

export type GQUpload = {
  __typename?: 'Upload';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  editor: Maybe<GQEditor>;
  id: Scalars['ID']['output'];
  stableId: Scalars['String']['output'];
  versionId: Scalars['ID']['output'];
};

export type GQUploadConnection = {
  __typename?: 'UploadConnection';
  edges: Array<Maybe<GQUploadEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUploadEdge = {
  __typename?: 'UploadEdge';
  cursor: Scalars['String']['output'];
  node: GQUpload;
};

export type GQUpsertCompanyPortalSettingsInput = {
  portalCallout?: InputMaybe<Scalars['String']['input']>;
  portalTitle: Scalars['String']['input'];
  sidebarContent?: InputMaybe<Scalars['String']['input']>;
  toggleHeaderImage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUpsertCompanyPortalSettingsPayload = {
  __typename?: 'UpsertCompanyPortalSettingsPayload';
  companyPortalSettings: GQCompanyPortalSettings;
};

export type GQUpsertCompanyReportingFactsInput = {
  asOfMonth: Scalars['YearMonth']['input'];
  companyId: Scalars['ID']['input'];
  updateFields: GQCompanyReportingFactsInput;
};

export type GQUpsertCompanyReportingFactsPayload = {
  __typename?: 'UpsertCompanyReportingFactsPayload';
  companyReportingFacts: GQCompanyReportingFacts;
};

export type GQUpsertDocumentParseAttemptInput = {
  modelType: GQDocumentParseAttemptModelType;
  userUploadId: Scalars['ID']['input'];
  utilityUsageData?: InputMaybe<GQUtilityUsageDataInput>;
};

export type GQUpsertEngagementTaskAnswersInput = {
  answers: Array<GQEngagementTaskAnswerInput>;
  engagementTaskId: Scalars['ID']['input'];
};

export type GQUpsertEngagementTaskAnswersPayload = {
  __typename?: 'UpsertEngagementTaskAnswersPayload';
  engagementTask: Maybe<GQEngagementTask>;
};

export type GQUpsertFootprintCategoryRulesInput = {
  rules: Array<GQFootprintCategoryRuleInput>;
};

export type GQUpsertFootprintCategoryRulesPayload = {
  __typename?: 'UpsertFootprintCategoryRulesPayload';
  organization: GQOrganization;
};

export type GQUpsertFootprintExclusionRulesPayload = {
  __typename?: 'UpsertFootprintExclusionRulesPayload';
  organization: GQOrganization;
};

export type GQUpsertFootprintExclusionRulesV2Input = {
  rules: Array<GQFootprintExclusionRuleV2Input>;
};

export type GQUpsertNonFootprintSupplierInput = {
  companyId: Scalars['ID']['input'];
  tags: Array<GQStringKeyValueInput>;
};

export type GQUpsertNonFootprintSupplierPayload = {
  __typename?: 'UpsertNonFootprintSupplierPayload';
  supplier: GQSupplier;
};

export type GQUpsertOrgCompanyForNameInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  source: GQOrgCompanySource;
};

export type GQUpsertOrgCompanyForNamePayload = {
  __typename?: 'UpsertOrgCompanyForNamePayload';
  orgCompany: GQOrgCompany;
  orgCompanyHasSupplierSpecificEF: Scalars['Boolean']['output'];
};

export type GQUpsertOrgStructureVersionInput = {
  effectiveFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  effectiveToDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type GQUpsertOrgStructureVersionPayload = {
  __typename?: 'UpsertOrgStructureVersionPayload';
  orgStructureVersion: GQOrgStructureVersion;
};

export type GQUpsertReportAnswerInput = {
  oldOutputJson?: InputMaybe<Scalars['JSONString']['input']>;
  outputJson?: InputMaybe<Scalars['JSONString']['input']>;
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  unparsedInputJson: Scalars['JSONString']['input'];
};

export type GQUpsertReportAnswerOverrideInput = {
  componentId: Scalars['String']['input'];
  oldOutputJson?: InputMaybe<Scalars['JSONString']['input']>;
  outputJson: Scalars['JSONString']['input'];
  questionId: Scalars['ID']['input'];
  reportId: Scalars['ID']['input'];
  unparsedInputJson?: InputMaybe<Scalars['JSONString']['input']>;
};

export type GQUpsertReportAnswerOverridePayload = {
  __typename?: 'UpsertReportAnswerOverridePayload';
  doesConflict: Scalars['Boolean']['output'];
  updatedAnswer: GQReportAnswerOverride;
};

export type GQUpsertReportAnswerPayload = {
  __typename?: 'UpsertReportAnswerPayload';
  doesConflict: Scalars['Boolean']['output'];
  updatedAnswer: GQReportAnswer;
};

export type GQUpsertReportQuestionInstancesAccessInput = {
  actions: Array<GQReportQuestionInstancePermissionAction>;
  reportQuestionInstanceIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type GQUpsertReportQuestionInstancesAccessPayload = {
  __typename?: 'UpsertReportQuestionInstancesAccessPayload';
  reportQuestionInstances: Array<GQReportQuestionInstance>;
  success: Scalars['Boolean']['output'];
  user: GQUser;
};

export type GQUpsertReportsAccessInput = {
  actions: Array<GQReportPermissionAction>;
  reportIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type GQUpsertReportsAccessPayload = {
  __typename?: 'UpsertReportsAccessPayload';
  reports: Array<GQReport>;
  success: Scalars['Boolean']['output'];
  user: GQUser;
};

export type GQUser = GQIdInterface &
  GQPerson & {
    __typename?: 'User';
    accessibleOrgs: Array<GQOrgLimitedProfile>;
    consecutiveLoginFailures: Scalars['Int']['output'];
    createdAt: Scalars['DateTime']['output'];
    deletedAt: Maybe<Scalars['DateTime']['output']>;
    didLatestEmailBounce: Scalars['Boolean']['output'];
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    engagementNotificationSettings: Array<GQEngagementNotificationSetting>;
    hasAnyPermissions: Scalars['Boolean']['output'];
    hasPermission: Scalars['Boolean']['output'];
    hasPermissions: Scalars['Boolean']['output'];
    hasValidAppSessions: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    isE2ETester: Scalars['Boolean']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    loginActivated: Scalars['Boolean']['output'];
    managedByDirectoryType: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    onboardingsCompleted: Array<GQOnboardingKind>;
    orgAccessCreatedAt: Scalars['DateTime']['output'];
    orgAccessId: Scalars['ID']['output'];
    permissionOrigin: Maybe<GQUserPermissionOrigin>;
    permissions: Array<GQPermissionItem>;
    preferredLocale: Maybe<Scalars['String']['output']>;
    primaryLoginOrgId: Scalars['ID']['output'];
    roles: Array<GQUserRoleAssignment>;
    /** @deprecated signupLink should not be exposed in dashboard */
    signupLink: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTime']['output'];
    userUploadTasks: Array<GQUserUploadTask>;
  };

export type GQUserEngagementNotificationSettingsArgs = {
  side: GQEngagementNotificationSide;
};

export type GQUserHasAnyPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserHasPermissionArgs = {
  objectId: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  permission: GQPermissionType;
};

export type GQUserHasPermissionsArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserOrgAccessCreatedAtArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserOrgAccessIdArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserPermissionOriginArgs = {
  orgId: Scalars['ID']['input'];
  permissions: Array<GQHasPermissionInput>;
};

export type GQUserPermissionsArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserRolesArgs = {
  includeDeletedOrgAccess: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
};

export type GQUserSignupLinkArgs = {
  orgId: Scalars['ID']['input'];
};

export type GQUserConnection = {
  __typename?: 'UserConnection';
  edges: Array<Maybe<GQUserEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'User' and its cursor. */
export type GQUserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUser>;
};

export type GQUserEditor = {
  __typename?: 'UserEditor';
  user: GQUser;
};

export type GQUserFilterInput = {
  includeWatershedEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  withAllPermissions?: InputMaybe<Array<GQHasPermissionInput>>;
  withSomePermissions?: InputMaybe<Array<GQHasPermissionInput>>;
};

export type GQUserIngestionReview = {
  __typename?: 'UserIngestionReview';
  id: Scalars['ID']['output'];
  ingestionSchemaId: Maybe<Scalars['String']['output']>;
  userUploadedTableId: Scalars['ID']['output'];
};

export type GQUserInputtedForecastPeriodPoint = {
  __typename?: 'UserInputtedForecastPeriodPoint';
  customGrowthFactors: Array<GQForecastCustomIntensityDataPoint>;
  date: Scalars['Date']['output'];
};

export const GQUserOrRole = {
  Role: 'Role',
  User: 'User',
} as const;

export type GQUserOrRole = (typeof GQUserOrRole)[keyof typeof GQUserOrRole];
export type GQUserOrRoleMetadata = {
  id: Scalars['ID']['input'];
  type: GQUserOrRole;
};

export type GQUserPermission = {
  __typename?: 'UserPermission';
  id: Scalars['ID']['output'];
  objectId: Maybe<Scalars['ID']['output']>;
  objectType: Maybe<GQPermissionObjectType>;
  permission: GQPermissionType;
};

export type GQUserPermissionOrigin = {
  __typename?: 'UserPermissionOrigin';
  id: Scalars['ID']['output'];
  permission: Maybe<GQPermissionItem>;
  role: Maybe<GQUserRoleAssignment>;
};

export type GQUserPermissionRequest = {
  __typename?: 'UserPermissionRequest';
  creatorOrgId: Maybe<Scalars['ID']['output']>;
  creatorUser: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgId: Scalars['ID']['output'];
  permission: Maybe<GQPermissionType>;
  respondedAt: Maybe<Scalars['DateTime']['output']>;
  responderUser: Maybe<Scalars['ID']['output']>;
  state: GQUserPermissionRequestState;
  userId: Maybe<Scalars['ID']['output']>;
};

export const GQUserPermissionRequestState = {
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
} as const;

export type GQUserPermissionRequestState =
  (typeof GQUserPermissionRequestState)[keyof typeof GQUserPermissionRequestState];
export type GQUserReviewMetadataInput = {
  buildingAreaUnitOverride?: InputMaybe<GQBuildingSizeUnit>;
  columnMappings?: InputMaybe<Array<GQColumnMappingInput>>;
  currencyOverride?: InputMaybe<GQCurrencyOverrideInput>;
  headerRowIndex?: InputMaybe<Scalars['Int']['input']>;
  skipBottomNRows?: InputMaybe<Scalars['Int']['input']>;
  stepsCompleted?: InputMaybe<Array<GQImportFlowDataCleaningStep>>;
};

export type GQUserRoleAssignment = {
  __typename?: 'UserRoleAssignment';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  org: GQOrganization;
  revokedAt: Maybe<Scalars['DateTime']['output']>;
  role: GQRole;
  updatedAt: Scalars['DateTime']['output'];
  user: GQUser;
};

export type GQUserUpload = GQIdInterface & {
  __typename?: 'UserUpload';
  actualUserUploadId: Maybe<Scalars['ID']['output']>;
  allAttempts: Array<GQUserUploadAttempt>;
  /** @deprecated Use UserUploadCandidate */
  apiUpload: Maybe<GQApiUpload>;
  associatedUtilityCount: Scalars['Int']['output'];
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  ctsVersion: Maybe<GQCustomerTargetSchemaVersion>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  errorReason: Maybe<Scalars['String']['output']>;
  /** @deprecated Use UserUploadTask.hasStaleSnapshot instead */
  hasStaleSnapshot: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isBeingValueMapped: Scalars['Boolean']['output'];
  latestAdeIdForSDIFacilitiesFlow: Maybe<Scalars['String']['output']>;
  latestAttempt: GQUserUploadAttempt;
  measurementProject: Maybe<GQMeasurementProject>;
  name: Scalars['String']['output'];
  org: GQOrganization;
  processingMode: Maybe<GQUserUploadProcessingMode>;
  processingWorkflowId: Maybe<Scalars['String']['output']>;
  revisionRoot: Maybe<Scalars['ID']['output']>;
  status: GQUserUploadStatus;
  transformsIncludingDeleted: Array<GQCustomerTargetSchemaTransform>;
  uploader: Maybe<GQUser>;
  /** @deprecated Use userUploadKind */
  userUploadCandidate: Maybe<GQUserUploadCandidate>;
  userUploadKind: Maybe<GQUserUploadCandidateKind>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
  userVisibleErrorMessage: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadMeasurementProjectArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadAttempt = GQIdInterface & {
  __typename?: 'UserUploadAttempt';
  category: GQFileCategory;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestUutsIfRevisionExists: Maybe<Array<GQUserUploadedTable>>;
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  remoteWritten: Scalars['Boolean']['output'];
  size: Scalars['Int53']['output'];
  uploader: Maybe<GQUser>;
  userUpload: GQUserUpload;
  userUploadSource: Maybe<GQUserUploadSource>;
  userUploadedTables: Array<GQUserUploadedTable>;
};

export type GQUserUploadAttemptConnection = {
  __typename?: 'UserUploadAttemptConnection';
  edges: Array<Maybe<GQUserUploadAttemptEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadAttemptEdge = {
  __typename?: 'UserUploadAttemptEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadCandidate = {
  __typename?: 'UserUploadCandidate';
  createdAt: Scalars['Date']['output'];
  ctsVersion: GQCustomerTargetSchemaVersion;
  dataset: GQDataset;
  datasetId: Scalars['ID']['output'];
  errorCount: Maybe<Scalars['Int']['output']>;
  errorData: Maybe<Scalars['JSONString']['output']>;
  fileFeed: Maybe<GQCtsvOneSchemaFileFeed>;
  id: Scalars['ID']['output'];
  latestFileFeedImport: Maybe<GQCtsvOneSchemaFileFeedImport>;
  name: Scalars['String']['output'];
  rowCount: Scalars['Int']['output'];
  status: GQUserUploadCandidateStatus;
  submissions: Array<GQUserUploadCandidateSubmission>;
  userUploadKind: GQUserUploadCandidateKind;
};

export type GQUserUploadCandidateEntry = {
  __typename?: 'UserUploadCandidateEntry';
  candidateRows: Array<GQUserUploadCandidateRowFields>;
  ctsVersion: GQCustomerTargetSchemaVersion;
  id: Scalars['ID']['output'];
};

export const GQUserUploadCandidateKind = {
  Api: 'Api',
  Document: 'Document',
  File: 'File',
  Form: 'Form',
  Integration: 'Integration',
  Manual: 'Manual',
} as const;

export type GQUserUploadCandidateKind =
  (typeof GQUserUploadCandidateKind)[keyof typeof GQUserUploadCandidateKind];
export type GQUserUploadCandidatePayload = {
  __typename?: 'UserUploadCandidatePayload';
  candidateEntries: Array<GQUserUploadCandidateEntry>;
};

export type GQUserUploadCandidateRowFields = {
  __typename?: 'UserUploadCandidateRowFields';
  citation: Maybe<Scalars['JSONString']['output']>;
  data: Scalars['JSONString']['output'];
  id: Scalars['ID']['output'];
};

export const GQUserUploadCandidateStatus = {
  Error: 'error',
  Pending: 'pending',
  Processing: 'processing',
  Submitted: 'submitted',
  Validated: 'validated',
} as const;

export type GQUserUploadCandidateStatus =
  (typeof GQUserUploadCandidateStatus)[keyof typeof GQUserUploadCandidateStatus];
export type GQUserUploadCandidateSubmission = {
  __typename?: 'UserUploadCandidateSubmission';
  apiSubmissionId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  datasourceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  measurementProject: GQMeasurementProject;
  processFileWorkflowId: Maybe<Scalars['ID']['output']>;
  userUploadTaskId: Scalars['String']['output'];
};

export type GQUserUploadConnection = {
  __typename?: 'UserUploadConnection';
  edges: Array<Maybe<GQUserUploadEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadDataGovernanceInfo = {
  __typename?: 'UserUploadDataGovernanceInfo';
  approvers: Array<GQUserWithApprovalStatus>;
  dataset: Scalars['String']['output'];
  datasource: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uploadedAt: Scalars['DateTime']['output'];
  uploadedBy: Maybe<Scalars['String']['output']>;
  userUploadKind: Maybe<GQUserUploadCandidateKind>;
};

export type GQUserUploadEdge = {
  __typename?: 'UserUploadEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUpload>;
};

export type GQUserUploadFailure = {
  __typename?: 'UserUploadFailure';
  filename: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export const GQUserUploadProcessingMode = {
  Api: 'Api',
  IdiRawFile: 'IdiRawFile',
  Legacy: 'Legacy',
  NoProcessing: 'NoProcessing',
  Synchronous: 'Synchronous',
} as const;

export type GQUserUploadProcessingMode =
  (typeof GQUserUploadProcessingMode)[keyof typeof GQUserUploadProcessingMode];
export const GQUserUploadSource = {
  InProduct: 'InProduct',
  PublicApi: 'PublicAPI',
} as const;

export type GQUserUploadSource =
  (typeof GQUserUploadSource)[keyof typeof GQUserUploadSource];
export const GQUserUploadStatus = {
  Errored: 'Errored',
  Finalized: 'Finalized',
  Processed: 'Processed',
  Processing: 'Processing',
  Uploaded: 'Uploaded',
  Uploading: 'Uploading',
  Validated: 'Validated',
} as const;

export type GQUserUploadStatus =
  (typeof GQUserUploadStatus)[keyof typeof GQUserUploadStatus];
export type GQUserUploadTask = GQApprovalTarget &
  GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'UserUploadTask';
    approvalStatus: GQApprovalStatus;
    approvers: Array<GQUserWithApprovalStatus>;
    /** @deprecated Use assignees instead */
    assignee: Maybe<GQUser>;
    assignees: Array<GQUser>;
    buildingIds: Array<Scalars['ID']['output']>;
    buildingLockedRevisions: Maybe<Array<Maybe<GQBuildingLockedRevisions>>>;
    buildingTemplateDataUntyped: Maybe<Scalars['UntypedData']['output']>;
    createdAt: Scalars['DateTime']['output'];
    ctsForm: Maybe<GQCtsForm>;
    datasetRequirements: Array<GQClimateProgramProjectRequirementDataset>;
    datasource: GQDatasource;
    dueAt: Maybe<Scalars['DateTime']['output']>;
    facilitiesPreview: GQFacilitiesPreview;
    hasIncompleteBuildings: Scalars['Boolean']['output'];
    hasStaleSnapshot: Scalars['Boolean']['output'];
    /** @deprecated Use valueMappingTask instead. Delete may9 */
    hasValueMappingTask: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    importState: GQDatasourceImportState;
    /** @deprecated Causes performance issues, do not use! */
    isDatasetComplete: Maybe<Scalars['Boolean']['output']>;
    /** @deprecated Use lockState instead */
    isLocked: Scalars['Boolean']['output'];
    issues: Maybe<GQDataIssueConnection>;
    lockState: GQApprovalTargetLockState;
    measurementProject: GQMeasurementProject;
    measurementTask: GQMeasurementTaskFlat;
    state: GQUserUploadTaskState;
    /** @deprecated Use lockState instead */
    unlockRequested: Scalars['Boolean']['output'];
    updatedAt: Scalars['DateTime']['output'];
    userUploads: Maybe<GQUserUploadConnection>;
    valueMappingTask: Maybe<GQValueMappingTask>;
    valueMappingTasks: Array<GQValueMappingTask>;
  };

export type GQUserUploadTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskUserUploadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  categories: InputMaybe<Array<GQFileCategory>>;
  excludeProcessingModes: InputMaybe<Array<GQUserUploadProcessingMode>>;
  first: InputMaybe<Scalars['Int']['input']>;
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQUserUploadTaskConnection = {
  __typename?: 'UserUploadTaskConnection';
  edges: Array<Maybe<GQUserUploadTaskEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'UserUploadTask' and its cursor. */
export type GQUserUploadTaskEdge = {
  __typename?: 'UserUploadTaskEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadTask>;
};

export const GQUserUploadTaskState = {
  Completed: 'Completed',
  Created: 'Created',
  HasReviewItems: 'HasReviewItems',
  InfoRequired: 'InfoRequired',
  Uploaded: 'Uploaded',
  Verifying: 'Verifying',
} as const;

export type GQUserUploadTaskState =
  (typeof GQUserUploadTaskState)[keyof typeof GQUserUploadTaskState];
export type GQUserUploadVerificationLog = GQIdInterface & {
  __typename?: 'UserUploadVerificationLog';
  dataReviewCompleted: Scalars['Boolean']['output'];
  dataset: Scalars['String']['output'];
  datasource: Scalars['String']['output'];
  fileId: Scalars['ID']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sheetName: Maybe<Scalars['String']['output']>;
  submittedAt: Scalars['DateTime']['output'];
  submittedBy: Scalars['String']['output'];
  transformation: Maybe<Scalars['String']['output']>;
};

export type GQUserUploadedTable = GQIdInterface & {
  __typename?: 'UserUploadedTable';
  /** @deprecated unused, remove jan18 */
  additionalQuestions: Maybe<Array<GQAdditionalQuestionResponse>>;
  businessActivityType: Maybe<Scalars['String']['output']>;
  dataPreview: Maybe<GQUserUploadedTableDataPreview>;
  error: Maybe<Scalars['String']['output']>;
  errorMessage: Maybe<Scalars['String']['output']>;
  errorType: Maybe<Scalars['String']['output']>;
  extBartId: Maybe<Scalars['ID']['output']>;
  gcloudStorageUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationDataPull: Maybe<GQIntegrationDataPull>;
  isUsingLatestUIR: Scalars['Boolean']['output'];
  latestParseAttemptGCSSignedUrl: Maybe<Scalars['String']['output']>;
  numRows: Maybe<Scalars['Int']['output']>;
  parentFileMetadata: GQFileMetadata;
  parseConfig: Maybe<GQUserUploadedTableParseConfig>;
  rawDataPreview: Maybe<GQUserUploadedTableDataPreview>;
  rawDataUnshiftedPreview: Maybe<GQUserUploadedTableDataPreview>;
  roboCleanerResult: Maybe<GQUserUploadedTableRoboCleanerResult>;
  schema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  sheetIndex: Maybe<Scalars['Int']['output']>;
  sheetName: Maybe<Scalars['String']['output']>;
  shouldIgnore: Scalars['Boolean']['output'];
  sourceFileDeleted: Scalars['Boolean']['output'];
  sqlTableName: Maybe<Scalars['String']['output']>;
  status: GQUserUploadedTableStatus;
  uirSchema: Maybe<Array<GQUserUploadedTableSchemaColumn>>;
  userIngestionReview: Maybe<GQUserIngestionReview>;
  userReviewMetadata: Maybe<GQUserUploadedTableUserReviewMetadata>;
  userUploadTask: Maybe<GQUserUploadTask>;
  userVisibleAttempt: Maybe<GQUserUploadAttempt>;
};

export type GQUserUploadedTableUserVisibleAttemptArgs = {
  includeDeleted: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQUserUploadedTableCell = {
  __typename?: 'UserUploadedTableCell';
  columnName: Scalars['String']['output'];
  value: Maybe<Scalars['JSONString']['output']>;
};

export type GQUserUploadedTableConnection = {
  __typename?: 'UserUploadedTableConnection';
  edges: Array<Maybe<GQUserUploadedTableEdge>>;
  pageInfo: GQPageInfo;
};

export type GQUserUploadedTableDataPreview = {
  __typename?: 'UserUploadedTableDataPreview';
  /** @deprecated Use dataUntyped instead, delete on apr29 */
  data: Array<Array<GQUserUploadedTableCell>>;
  dataUntyped: Scalars['UntypedData']['output'];
  schema: GQUserUploadedTableDataPreviewSchema;
};

export type GQUserUploadedTableDataPreviewSchema = {
  __typename?: 'UserUploadedTableDataPreviewSchema';
  fields: Array<GQUserUploadedTableDataPreviewSchemaField>;
};

export type GQUserUploadedTableDataPreviewSchemaField = {
  __typename?: 'UserUploadedTableDataPreviewSchemaField';
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type GQUserUploadedTableEdge = {
  __typename?: 'UserUploadedTableEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQUserUploadedTable>;
};

export type GQUserUploadedTableParseConfig = {
  __typename?: 'UserUploadedTableParseConfig';
  meltIdVars: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  noHeader: Scalars['Boolean']['output'];
  skipBottomNRows: Scalars['Int']['output'];
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableRoboCleanerResult = {
  __typename?: 'UserUploadedTableRoboCleanerResult';
  skipTopNRows: Scalars['Int']['output'];
};

export type GQUserUploadedTableSchemaColumn = {
  __typename?: 'UserUploadedTableSchemaColumn';
  alias: Scalars['String']['output'];
  forwardFill: Scalars['Boolean']['output'];
  index: Scalars['Int']['output'];
  kind: GQUserUploadedTableSchemaColumnKind;
  name: Scalars['String']['output'];
};

export const GQUserUploadedTableSchemaColumnKind = {
  Boolean: 'Boolean',
  Date: 'Date',
  Epoch: 'Epoch',
  EuroDate: 'EuroDate',
  EuroFloat: 'EuroFloat',
  ExcelOrdinalDate: 'ExcelOrdinalDate',
  Float: 'Float',
  Month: 'Month',
  String: 'String',
} as const;

export type GQUserUploadedTableSchemaColumnKind =
  (typeof GQUserUploadedTableSchemaColumnKind)[keyof typeof GQUserUploadedTableSchemaColumnKind];
export const GQUserUploadedTableStatus = {
  Error: 'Error',
  Pending: 'Pending',
  Success: 'Success',
  Warning: 'Warning',
} as const;

export type GQUserUploadedTableStatus =
  (typeof GQUserUploadedTableStatus)[keyof typeof GQUserUploadedTableStatus];
export type GQUserUploadedTableUserReviewMetadata = {
  __typename?: 'UserUploadedTableUserReviewMetadata';
  buildingAreaUnitOverride: Maybe<GQBuildingSizeUnit>;
  columnMappings: Array<GQColumnMapping>;
  currencyOverride: Maybe<GQCurrencyOverride>;
  headerRowIndex: Scalars['Int']['output'];
  ingestionQuestionResponses: Maybe<Array<GQIngestionQuestionResponse>>;
  skipBottomNRows: Scalars['Int']['output'];
  stepsCompleted: Maybe<Array<GQImportFlowDataCleaningStep>>;
};

export type GQUserWithApprovalStatus = {
  __typename?: 'UserWithApprovalStatus';
  approvalLastUpdatedAt: Maybe<Scalars['DateTime']['output']>;
  approvalStatus: Maybe<GQApprovalStatus>;
  user: GQUser;
};

export type GQUtilitiesMetadata = {
  __typename?: 'UtilitiesMetadata';
  id: Scalars['ID']['output'];
  utilityTypes: Array<GQUtilitiesMetadataByType>;
};

export type GQUtilitiesMetadataByType = {
  __typename?: 'UtilitiesMetadataByType';
  containingInterval: Scalars['YMInterval']['output'];
  utilityType: Maybe<GQBuildingUtilityType>;
};

export type GQUtilityUsageData = {
  __typename?: 'UtilityUsageData';
  energyConsumptionAmount: Maybe<Scalars['String']['output']>;
  energyConsumptionUnit: Maybe<Scalars['String']['output']>;
  receiverAddress: Maybe<Scalars['String']['output']>;
  serviceAddress: Maybe<Scalars['String']['output']>;
  serviceEndDate: Maybe<Scalars['String']['output']>;
  serviceStartDate: Maybe<Scalars['String']['output']>;
};

export type GQUtilityUsageDataInput = {
  energyConsumptionAmount?: InputMaybe<Scalars['String']['input']>;
  energyConsumptionUnit?: InputMaybe<Scalars['String']['input']>;
  receiverAddress?: InputMaybe<Scalars['String']['input']>;
  serviceAddress?: InputMaybe<Scalars['String']['input']>;
  serviceEndDate?: InputMaybe<Scalars['String']['input']>;
  serviceStartDate?: InputMaybe<Scalars['String']['input']>;
};

export type GQUutRowsDataLineage = {
  __typename?: 'UutRowsDataLineage';
  fileMetadataId: Scalars['String']['output'];
  isTruncated: Scalars['Boolean']['output'];
  rows: Array<Scalars['JSONString']['output']>;
  userUploadedTable: GQUserUploadedTable;
};

export type GQValidateFormAgainstCtsErrorPayload = {
  __typename?: 'ValidateFormAgainstCtsErrorPayload';
  errorMessage: Scalars['String']['output'];
};

export type GQValidateFormAgainstCtsInput = {
  ctsVersionId: Scalars['ID']['input'];
  data: Scalars['JSONString']['input'];
  datasetId: Scalars['ID']['input'];
};

export type GQValidateFormAgainstCtsPayload = {
  __typename?: 'ValidateFormAgainstCtsPayload';
  errors: Array<GQValidateFormAgainstCtsErrorPayload>;
};

export type GQValidationWarning = {
  __typename?: 'ValidationWarning';
  blocking: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type GQValueMapping = {
  __typename?: 'ValueMapping';
  id: Scalars['ID']['output'];
  metadata: Maybe<GQValueMappingRowsMetadata>;
  /** @deprecated use DatasourceValueMappingRule.revisionData instead */
  revisionState: Maybe<GQReferenceDataRevisionState>;
  valueMappingRows: Array<GQValueMappingRow>;
};

export type GQValueMappingAggregatedQuantities = {
  __typename?: 'ValueMappingAggregatedQuantities';
  mappedQuantity: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
};

export type GQValueMappingComment = {
  __typename?: 'ValueMappingComment';
  createdAt: Scalars['DateTime']['output'];
  creator: GQUser;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  deleter: Maybe<GQUser>;
  id: Scalars['ID']['output'];
  lastEditedAt: Maybe<Scalars['DateTime']['output']>;
  lastEditor: Maybe<GQUser>;
  message: Scalars['String']['output'];
  org: GQOrganization;
  subjectId: Scalars['ID']['output'];
  valueMappingConfigId: Scalars['ID']['output'];
};

export type GQValueMappingFileData = {
  __typename?: 'ValueMappingFileData';
  fileData: GQUserUpload;
  id: Scalars['ID']['output'];
  matchedRows: Scalars['JSONSchema']['output'];
};

export type GQValueMappingFileDataFilter = {
  columnNames: Array<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export const GQValueMappingFilterType = {
  All: 'all',
  Flagged: 'flagged',
  Incomplete: 'incomplete',
  Required: 'required',
  Suggested: 'suggested',
} as const;

export type GQValueMappingFilterType =
  (typeof GQValueMappingFilterType)[keyof typeof GQValueMappingFilterType];
export type GQValueMappingHistoryEntry = {
  __typename?: 'ValueMappingHistoryEntry';
  changedAt: Scalars['DateTime']['output'];
  changedBy: GQUser;
  columnName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  newValue: Maybe<Scalars['UntypedData']['output']>;
  oldValue: Maybe<Scalars['UntypedData']['output']>;
  operation: Scalars['String']['output'];
  revision: GQReferenceDataRevision;
  rowId: Scalars['ID']['output'];
};

export type GQValueMappingHistoryForGroupPayload = {
  __typename?: 'ValueMappingHistoryForGroupPayload';
  entries: Array<GQValueMappingHistoryEntry>;
  id: Scalars['ID']['output'];
};

export type GQValueMappingOutputOption = {
  __typename?: 'ValueMappingOutputOption';
  category: Maybe<Scalars['String']['output']>;
  descriptionLong: Maybe<Scalars['String']['output']>;
  extraData: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['String']['output'];
  isSuggested: Scalars['Boolean']['output'];
  subtitle: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type GQValueMappingOutputPicker = {
  __typename?: 'ValueMappingOutputPicker';
  columnName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  options: Array<GQValueMappingOutputOption>;
  placeholder: Maybe<Scalars['String']['output']>;
};

export const GQValueMappingPublishStatus = {
  Failed: 'Failed',
  LatestPublishSucceeded: 'LatestPublishSucceeded',
  NeverPublished: 'NeverPublished',
  Processing: 'Processing',
} as const;

export type GQValueMappingPublishStatus =
  (typeof GQValueMappingPublishStatus)[keyof typeof GQValueMappingPublishStatus];
export type GQValueMappingRow = {
  __typename?: 'ValueMappingRow';
  baseRowJson: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQValueMappingRowConnection = {
  __typename?: 'ValueMappingRowConnection';
  edges: Array<Maybe<GQValueMappingRowEdge>>;
  metadata: GQValueMappingRowsMetadata;
  pageInfo: GQPageInfo;
};

export type GQValueMappingRowEdge = {
  __typename?: 'ValueMappingRowEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQValueMappingRow>;
};

export type GQValueMappingRowsMetadata = {
  __typename?: 'ValueMappingRowsMetadata';
  allRowsAreFilled: Scalars['Boolean']['output'];
  flaggedRowCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  suggestionsRowCount: Scalars['Int']['output'];
  totalRowCount: Scalars['Int']['output'];
  unmappedRowCount: Scalars['Int']['output'];
};

export type GQValueMappingTableSourceData = {
  __typename?: 'ValueMappingTableSourceData';
  id: Scalars['ID']['output'];
  outputOptionPickers: Array<GQValueMappingOutputPicker>;
};

export type GQValueMappingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'ValueMappingTask';
    id: Scalars['ID']['output'];
    issues: Maybe<GQDataIssueConnection>;
    mappingRuleId: Scalars['String']['output'];
    measurementProject: GQMeasurementProject;
    measurementProjectId: Scalars['ID']['output'];
    orgId: Scalars['ID']['output'];
    publishStatus: GQValueMappingPublishStatus;
    status: GQTaskWatershedProcessState;
    statusSimplified: GQTaskWatershedProcessStateSimplified;
  };

export type GQValueMappingTaskIssuesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  isPublished: InputMaybe<Scalars['Boolean']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
};

export type GQVariousSupplierInfo = {
  __typename?: 'VariousSupplierInfo';
  companyId: Maybe<Scalars['String']['output']>;
  totalKgco2e: Scalars['Float']['output'];
  totalSpendUsd: Scalars['Float']['output'];
  vendor: Scalars['String']['output'];
};

export type GQVariousSuppliersPayload = {
  __typename?: 'VariousSuppliersPayload';
  variousSuppliers: Array<GQVariousSupplierInfo>;
};

export type GQVectorNameAndValues = {
  __typename?: 'VectorNameAndValues';
  vectorName: Scalars['String']['output'];
  vectorValues: Maybe<Array<Scalars['String']['output']>>;
};

export type GQVendor = {
  __typename?: 'Vendor';
  program: Maybe<Scalars['String']['output']>;
  programUrl: Maybe<Scalars['String']['output']>;
  sbtTarget: Maybe<Scalars['String']['output']>;
  targetYear: Maybe<Scalars['Int']['output']>;
  vendor: Scalars['String']['output'];
};

export type GQVendorConnection = {
  __typename?: 'VendorConnection';
  edges: Array<Maybe<GQVendorEdge>>;
  pageInfo: GQPageInfo;
};

/** A Relay edge containing a 'Vendor' and its cursor. */
export type GQVendorEdge = {
  __typename?: 'VendorEdge';
  cursor: Scalars['String']['output'];
  node: Maybe<GQVendor>;
};

export type GQVendorMatchingTask = GQIdInterface &
  GQMeasurementTaskObject & {
    __typename?: 'VendorMatchingTask';
    assignee: Maybe<GQUser>;
    completedAt: Maybe<Scalars['DateTime']['output']>;
    id: Scalars['ID']['output'];
    measurementProject: GQMeasurementProject;
    wasAutomapped: Scalars['Boolean']['output'];
  };

export type GQWasteEmissionsExclusion = {
  __typename?: 'WasteEmissionsExclusion';
  shouldExclude: Scalars['Boolean']['output'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export type GQWasteEmissionsExclusionInput = {
  shouldExclude: Scalars['Boolean']['input'];
  wasteEmissionsMethodology: GQWasteEmissionsMethodology;
};

export const GQWasteEmissionsMethodology = {
  BuildingWaste: 'BuildingWaste',
  Employees: 'Employees',
  Waste: 'Waste',
} as const;

export type GQWasteEmissionsMethodology =
  (typeof GQWasteEmissionsMethodology)[keyof typeof GQWasteEmissionsMethodology];
export type GQWaterfallBlock = {
  __typename?: 'WaterfallBlock';
  id: Scalars['ID']['output'];
  isIntermediateSum: Maybe<Scalars['Boolean']['output']>;
  isSum: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type GQWaterfallChartPayload = {
  __typename?: 'WaterfallChartPayload';
  waterfallBlocks: Array<GQWaterfallBlock>;
};

export type GQWatershedEmployee = GQIdInterface &
  GQPerson & {
    __typename?: 'WatershedEmployee';
    displayName: Scalars['String']['output'];
    email: Scalars['String']['output'];
    handle: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    /** @deprecated Please use isWatershedEmployee or isWatershedContractor */
    isWatershed: Scalars['Boolean']['output'];
    isWatershedContractor: Scalars['Boolean']['output'];
    isWatershedEmployee: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    orgPointsOfContact: Array<GQOrgPointOfContact>;
    user: GQUser;
  };

export type GQWatershedFootprintReviewRequest = {
  __typename?: 'WatershedFootprintReviewRequest';
  createdAt: Scalars['Date']['output'];
  discussion: Maybe<GQDiscussion>;
  id: Scalars['ID']['output'];
  status: GQWatershedFootprintReviewRequestStatus;
  updatedAt: Scalars['Date']['output'];
  user: GQUser;
};

export const GQWatershedFootprintReviewRequestStatus = {
  Done: 'Done',
  NeedsWatershedResponse: 'NeedsWatershedResponse',
  WatershedReviewInitiated: 'WatershedReviewInitiated',
} as const;

export type GQWatershedFootprintReviewRequestStatus =
  (typeof GQWatershedFootprintReviewRequestStatus)[keyof typeof GQWatershedFootprintReviewRequestStatus];
export const GQWatershedPlan = {
  Custom: 'Custom',
  Enterprise: 'Enterprise',
  Essential: 'Essential',
  Finance: 'Finance',
  None: 'None',
  Other: 'Other',
  PortcoPackage: 'PortcoPackage',
  Premium: 'Premium',
  Standard: 'Standard',
} as const;

export type GQWatershedPlan =
  (typeof GQWatershedPlan)[keyof typeof GQWatershedPlan];
export const GQWatershedPlanLegacy = {
  Lite: 'Lite',
  NetZero: 'NetZero',
  NoPlan: 'NoPlan',
  Pro: 'Pro',
  Standard: 'Standard',
} as const;

export type GQWatershedPlanLegacy =
  (typeof GQWatershedPlanLegacy)[keyof typeof GQWatershedPlanLegacy];
export type GQWithdrawCompanyChangeRequestInput = {
  id: Scalars['ID']['input'];
};

export type GQWithdrawCompanyChangeRequestPayload = {
  __typename?: 'WithdrawCompanyChangeRequestPayload';
  id: Scalars['ID']['output'];
};

export type GQWithdrawReportQuestionInstanceInput = {
  comment: Scalars['String']['input'];
  reportQuestionInstanceId: Scalars['ID']['input'];
};

export type GQWithdrawReportQuestionInstancePayload = {
  __typename?: 'WithdrawReportQuestionInstancePayload';
  reportQuestionInstance: GQReportQuestionInstance;
  success: Scalars['Boolean']['output'];
};

export type GQWorkivaContributionsPayload = {
  __typename?: 'WorkivaContributionsPayload';
  data: Scalars['UntypedData']['output'];
  id: Scalars['String']['output'];
  interval: Scalars['YMInterval']['output'];
};

export type GQWorkosConnection = {
  __typename?: 'WorkosConnection';
  connectionType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GQWorkosDirectory = {
  __typename?: 'WorkosDirectory';
  createdAt: Scalars['DateTime']['output'];
  directoryType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type GQYearMonthInterval = {
  __typename?: 'YearMonthInterval';
  end: Scalars['YearMonth']['output'];
  start: Scalars['YearMonth']['output'];
};

export const GQYesNoUnknown = {
  No: 'No',
  Unknown: 'Unknown',
  Yes: 'Yes',
} as const;

export type GQYesNoUnknown =
  (typeof GQYesNoUnknown)[keyof typeof GQYesNoUnknown];
export type GQBiAiQueryAvailableDimensionsMetadata = {
  family?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type GQBiAiQueryAvailableMetricMetadata = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  fieldFamily?: InputMaybe<Scalars['String']['input']>;
  fieldId: Scalars['String']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type GQSuggestMappingsPayload = {
  __typename?: 'suggestMappingsPayload';
  jobId: Scalars['ID']['output'];
};
