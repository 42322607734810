import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const StatusInformationIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 12 12" fill="currentColor" {...props}>
      <path d="M1 1h10v10H1z" />
    </Icon>
  )
);
export default StatusInformationIcon;
