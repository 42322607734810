import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import round10 from './round10';
import { optionalCurrencySymbolFromCode } from './currencies';
import { CurrencyCode } from '@watershed/constants/currency';
import { SupportedLocale } from '@watershed/intl/constants';
import {
  formatNumber as intlFormatNumber,
  formatList,
} from '@watershed/intl/formatters';
import must from './must';
import { i18n } from '@watershed/intl';

type WhenPlural = string | ((singular: string) => string);

/**
 * @deprecated Use the `plural` lingui macro, like Lingui <Plural/> or plural().
 * Ask for help in #proj-localization-eng if needed.
 *
 * returns the first argument when count is singular, otherwise returns the
 * second if it's a string or the result of calling the second argument as a
 * function with the singular argument. Mostly useful as a helper to `pluralize`
 * below, but sometimes useful for plural related prefixes such as
 * @example
 * ```ts
 *  whenPlural(countOfpeople, 'this', 'these') + ' ' + pluralizeString(countOfPeople, 'person') // => 'these people' etc
 * ```
 */
export function whenPlural(
  count: number,
  singular: string,
  plural: WhenPlural
): string {
  return count === 1
    ? singular
    : typeof plural === 'function'
      ? plural(singular)
      : plural;
}

/**
 * @deprecated Use internationalization-friendly strings, like Lingui <Plural/> or plural().
 * Ask for help in #proj-localization-eng if needed.
 *
 * Given a singular word and the count of that word, returns either the singular
 * or plural version of the word with the count.
 *
 * e.g.: pluralize(3, 'dish') => '3 dishes'
 *
 * @param count
 * @param item -> singular version of word
 * @param options
 * @returns
 */
export function pluralize(
  count: number,
  item: string,
  options: { omitCount?: boolean } = {}
): string {
  const { omitCount = false } = options;
  const str = pluralizeString(count, item);
  if (omitCount) return str;
  return `${formatNumber(count, {
    locale: i18n.locale as SupportedLocale,
  })} ${str}`;
}

/**
 * See https://www.grammarly.com/blog/plural-nouns/. Ideally we'd use an
 * existing library for this, but we haven't so far so someone will need to add
 * one :')
 */
function pluralizeSpecialCaseForYApplies(item: string): boolean {
  if (!item.endsWith('y')) return false;
  if ('aeiou'.includes(item[item.length - 2])) return false;
  return true;
}

/**
 * @deprecated Use internationalization-friendly strings. Ask for help in
 * #proj-localization-eng if needed.
 */
export function makeStringPlural(item: string): string {
  if (pluralizeSpecialCaseForYApplies(item)) {
    return `${item.slice(0, -1)}ies`;
  }
  if (item.endsWith('s')) {
    return `${item}es`;
  }
  return `${item}s`;
}

/**
 * @deprecated Use internationalization-friendly strings. Ask for help in
 * #proj-localization-eng if needed.
 *
 * Given a word, returns either the singular or plural version of the word given
 * the count
 *
 * e.g., pluralizeString(1, 'dish') => 'dish' pluralizeString(2, 'dish') =>
 * 'dishes'
 */
export function pluralizeString(count: number, item: string): string {
  return whenPlural(count, item, makeStringPlural);
}

/**
 * @deprecated Use Intl.listFormat
 */
export function listFormat(
  items: Array<string>,
  conjunction: 'or' | 'and' = 'and'
): string {
  if (items.length < 2) {
    return items.join('');
  }

  const firsts = items.slice(0, items.length - 1);
  const last = items[items.length - 1];
  const addOxfordComma = items.length > 2;
  return (
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/no-join-commas
    firsts.join(', ') + `${addOxfordComma ? ',' : ''} ${conjunction} ` + last
  );
}

/**
 * Renders a list of strings as a bullet list, where each item is a line
 * starting with "- ".
 */
export function bulletList(items: Array<string>): string {
  return items.map((item) => `- ${item}`).join('\n');
}

/**
 * @param fraction the fraction to turn into a percent, e.g. `0.01` turns into `'1%'`
 * @param maximumFractionDigits (default is 1 if num < 10, 0 otherwise) the maximum number of digits to display after the decimal point.
 * @param includeTrailingZeros display all fractional digits, e.g. if `fractionDigits=2`, `0.024` turns into `'2.40%'` instead of `'2.4%'`.
 * @param disableGroupingSeparators disable the usage of grouping separators, e.g. the comma
 *        for the thousand separator in en-US or the decimal for the thousand separator in de-DE.
 * @param alwaysDisplaySign always display the sign of the number, even if it's positive. Note that there is a chance that the number
 *        will be displayed as -0, e.g. `-0` turns into `-0%`.
 * @returns the localized percent, e.g. `0.046` turns into `'4.6%'`
 */
export function formatPercentage(
  fraction: number,
  {
    locale = i18n.locale as SupportedLocale,
    maximumFractionDigits = Math.abs(fraction * 100) < 10 ? 2 : 1,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
    ...opts
  }: {
    locale?: SupportedLocale;
    maximumFractionDigits?: number;
    includeTrailingZeros?: boolean;
    alwaysDisplaySign?: boolean;
    disableGroupingSeparators?: boolean;
  } & Omit<
    Intl.NumberFormatOptions,
    | 'minimumFractionDigits'
    | 'maximumFractionDigits'
    | 'useGrouping'
    | 'signDisplay'
    | 'style'
  > = {}
): string {
  return formatNumber(fraction, {
    locale,
    style: 'percent',
    maximumFractionDigits,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
    ...opts,
  });
}

/**
 * @param fraction the fraction to turn into a percent, e.g. `0.01` turns into `'1%'`
 * @param maximumFractionDigits (default is 1 if num < 10, 0 otherwise) the maximum number of digits to display after the decimal point.
 * @param includeTrailingZeros display all fractional digits, e.g. if `fractionDigits=2`, `0.024` turns into `'2.40%'` instead of `'2.4%'`.
 * @param disableGroupingSeparators disable the usage of grouping separators, e.g. the comma
 *        for the thousand separator in en-US or the decimal for the thousand separator in de-DE.
 * @param alwaysDisplaySign always display the sign of the number, even if it's positive. Note that there is a chance that the number
 *        will be displayed as -0, e.g. `-0` turns into `-0%`.
 * @returns the localized percent without rounding to zero, e.g. `0.0004555` turns into `'<0.1%'` if `maximumFractionDigits=1`
 */
export function formatPercentageNonzero(
  fraction: number,
  {
    locale = i18n.locale as SupportedLocale,
    maximumFractionDigits = Math.abs(fraction * 100) < 10 ? 1 : 0,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
    ...opts
  }: {
    locale?: SupportedLocale;
    maximumFractionDigits?: number;
    includeTrailingZeros?: boolean;
    alwaysDisplaySign?: boolean;
    disableGroupingSeparators?: boolean;
  } & Omit<
    Intl.NumberFormatOptions,
    | 'minimumFractionDigits'
    | 'maximumFractionDigits'
    | 'useGrouping'
    | 'signDisplay'
    | 'style'
  > = {}
): string {
  return formatNumberNonzero(fraction, {
    locale,
    ...opts,
    style: 'percent',
    maximumFractionDigits,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
  });
}

// Rounds the give number to fractionDigits decimal points of precision.
//
// By default numbers less than 10 are given one point of precision and greater
// numbers are rounded to the nearest whole.
export function roundNumber(
  num: number,
  fractionDigits = Math.abs(num) > 10 ? 0 : 1
): number {
  return round10(num, fractionDigits);
}

/**
 * @param maximumFractionDigits (default is 0 if num > 10, 1 otherwise) the maximum number of digits to display after the decimal point.
 * @param includeTrailingZeros display all fractional digits, e.g. if `maximumFractionDigits=3`, `24.20` turns into `'24.200'` instead of `'24.2'`.
 * @param disableGroupingSeparators disable the usage of grouping separators, e.g. the comma
 *        for the thousand separator in en-US or the decimal for the thousand separator in de-DE.
 * @param alwaysDisplaySign always display the sign of the number, even if it's positive. Note that there is a chance that the number
 *        will be displayed as -0, i.e. `formatNumber(-0.1, { alwaysDisplaySign = true }) = '-0'`.
 * @returns the localized number
 */
export function formatNumber(
  num: number,
  {
    locale = i18n.locale as SupportedLocale,
    maximumFractionDigits = Math.abs(num) > 10 ? 0 : 1,
    includeTrailingZeros,
    disableGroupingSeparators,
    alwaysDisplaySign,
    ...opts
  }: {
    locale?: SupportedLocale;
    maximumFractionDigits?: number;
    includeTrailingZeros?: boolean;
    disableGroupingSeparators?: boolean;
    alwaysDisplaySign?: boolean;
  } & Omit<
    Intl.NumberFormatOptions,
    | 'minimumFractionDigits'
    | 'maximumFractionDigits'
    | 'useGrouping'
    | 'signDisplay'
  > = {}
): string {
  // Intl.NumberFormat min/max fraction digits must be between 0 and 100.
  maximumFractionDigits = Math.max(0, Math.min(100, maximumFractionDigits));

  return intlFormatNumber(num, {
    locale,
    ...opts,
    minimumFractionDigits: includeTrailingZeros ? maximumFractionDigits : 0,
    maximumFractionDigits,
    // fall back to undefined because this option is not a boolean it has true,
    // false and a bunch of other string options, and those are chosen
    // dynamically as a default, so let the browser do it's thing with
    // `undefined` see:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#usegrouping
    useGrouping: disableGroupingSeparators ? false : undefined,
    // Use signDisplay options to avoid showing a negative sign on values that display as zero.
    // The + 0 hack above won't cut it here - it only works for integers.
    // What we really want is signDisplay 'negative', but we don't have it available in our JS version yet
    // See https://github.com/tc39/proposal-intl-numberformat-v3/issues/17
    signDisplay: alwaysDisplaySign
      ? 'always'
      : num <= 0
        ? 'exceptZero'
        : 'auto',
  });
}

/**
 *
 * @param maximumFractionDigits (default is 2) the maximum number of digits to display after the decimal point.
 * @param includeTrailingZeros display all fractional digits, e.g. if `fractionDigits=3`, `24.20` turns into `'24.200'` instead of `'24.2'`.
 * @param disableGroupingSeparators disable the usage of grouping separators, e.g. the comma
 *        for the thousand separator in en-US or the decimal for the thousand separator in de-DE.
 * @param alwaysDisplaySign always display the sign of the number, even if it's positive. Note that negative zero is a valid number, so
 *        `-0` will stay as `'-0'` instead of turning into `'0'`.
 * @returns the localized number without rounding to zero, e.g. `0.00004555` turns into `'<0.01'` if `maximumFractionDigits=2`
 */
export function formatNumberNonzero(
  num: number,
  {
    locale,
    maximumFractionDigits = 2,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
    ...opts
  }: {
    locale?: SupportedLocale;
    maximumFractionDigits?: number;
    includeTrailingZeros?: boolean;
    alwaysDisplaySign?: boolean;
    disableGroupingSeparators?: boolean;
  } & Omit<
    Intl.NumberFormatOptions,
    | 'minimumFractionDigits'
    | 'maximumFractionDigits'
    | 'useGrouping'
    | 'signDisplay'
  > = {}
): string {
  let smallestRepresentableValue =
    (Math.sign(num) * 1) / Math.pow(10, maximumFractionDigits);

  // Intl.NumberFormat will format 0.1 as 10% if using the percent style, so if we want
  // X fractional digits in the final percent number then we need to check against 10^-(X+2)
  if (opts.style === 'percent') {
    smallestRepresentableValue = smallestRepresentableValue / 100;
  }
  const showInequality =
    num !== 0 && Math.abs(num) < Math.abs(smallestRepresentableValue);
  const maybeInequality = showInequality ? (num < 0 ? '>' : '<') : '';
  const finalNum = showInequality ? smallestRepresentableValue : num;

  return `${maybeInequality}${formatNumber(finalNum, {
    locale,
    maximumFractionDigits,
    includeTrailingZeros,
    alwaysDisplaySign,
    disableGroupingSeparators,
    ...opts,
  })}`;
}

export function formatFileSize(bytes: number): string {
  if (bytes > 1024 ** 3) {
    return `${formatNumber(bytes / 1024 ** 3)} GB`;
  } else if (bytes > 1024 ** 2) {
    return `${formatNumber(bytes / 1024 ** 2)} MB`;
  } else {
    return `${formatNumber(bytes / 1024)} KB`;
  }
}

/**
 *
 * @param maximumFractionDigitsForSmallNumber the number of fraction digits to display if num < 1
 * @returns the localized number with magnitude formatting (e.g. 1.2M, 1.2B, 1.2T)
 */
export function formatNumberMagnitude(
  num: number,
  {
    locale,
    maximumFractionDigitsForSmallNumber = 2,
  }: {
    locale?: SupportedLocale;
    maximumFractionDigitsForSmallNumber?: number;
  } = {}
): string {
  const maximumFractionDigits =
    Math.abs(num) < 1 ? maximumFractionDigitsForSmallNumber : 1;
  return formatNumber(num, {
    locale,
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short',
  });
}

/**
 *
 * @param withCents should the cost be displayed with cents (Note: the Japanese yen doesn't use a minor unit so will never display cents)
 * @param withMagnitude should the cost be displayed with magnitude formatting (e.g. $1.2M, $1.2B, $1.2T)
 * @param currencyCode (default is USD) the currency code to use for formatting
 * @returns the localized cost
 */
export function formatCost(
  cost: number | null | undefined,
  {
    withCents = false,
    withMagnitude = true,
    currencyCode = 'USD',
    locale = i18n.locale as SupportedLocale as SupportedLocale,
  } = {}
): string {
  if (cost == null || Number.isNaN(cost)) {
    return '—';
  }
  const opts = {
    locale,
    style: 'currency',
    currency: currencyCode,
    // Use signDisplay options to avoid showing a negative sign on values that display as zero.
    // The + 0 hack above won't cut it here - it only works for integers.
    // What we really want is signDisplay 'negative', but we don't have it available in our JS version yet
    // See https://github.com/tc39/proposal-intl-numberformat-v3/issues/17
    signDisplay: cost <= 0 ? 'exceptZero' : 'auto',
  } as Intl.NumberFormatOptions;

  // Skip magnitude formatting if we are displaying cents.
  if (withCents) {
    // We don't call formatNumber here because it enforces a minimum & maximum number of fraction digits,
    // which we don't want for currency formatting because some currencies don't have minor units.
    return intlFormatNumber(cost, opts);
  }

  opts.maximumFractionDigits = 0;
  if (withMagnitude) {
    opts.notation = 'compact';
    opts.compactDisplay = 'short';
  }
  return intlFormatNumber(cost, opts);
}

export function formatUsdCents(usdCents: number): string {
  return formatCost(usdCents / 100, {
    withCents: usdCents % 100 !== 0,
    withMagnitude: false,
  });
}

export function formatUsdCentsPriceRange(
  priceUsdCentsMin: number,
  priceUsdCentsMax: number
): string {
  return priceUsdCentsMin === priceUsdCentsMax
    ? formatUsdCents(priceUsdCentsMin)
    : `${formatUsdCents(priceUsdCentsMin)} – ${formatUsdCents(
        priceUsdCentsMax
      )}`;
}

export function formatTco2IntensityPerMillion(
  cost: number | null | undefined,
  currencyCode: CurrencyCode
): string {
  if (cost == null || Number.isNaN(cost)) {
    return '—';
  }
  const fractionDigits = Math.abs(cost) > 10 ? 0 : 1;
  return `${formatNumber(cost, {
    locale: i18n.locale as SupportedLocale,
    maximumFractionDigits: fractionDigits,
    includeTrailingZeros: true,
  })}${formatTco2PerMillion(currencyCode)}`;
}

export function formatTco2PerMillion(
  currencyCode: CurrencyCode | null
): string {
  const symbol = optionalCurrencySymbolFromCode(currencyCode);
  return `tCO₂e/${symbol}M`;
}

function randomString(): string {
  return Math.random().toString(36).substring(2, 15);
}

function randomDigits(length: number): string {
  let out = '';
  for (let i = 0; i < length; i++) {
    out += Math.floor(Math.random() * 10).toString();
  }
  return out;
}

function subscriptDigit(digit: number): string {
  return String.fromCharCode('\u2080'.charCodeAt(0) + digit);
}

function subscriptDigits(input: string): string {
  const output: Array<string> = [];
  for (let i = 0; i < input.length; i++) {
    const char = input.charAt(i);
    if (char >= '0' && char <= '9') {
      output.push(subscriptDigit(parseInt(char, 10)));
    } else {
      output.push(char);
    }
  }
  return output.join('');
}

/**
 * !!! This function is not localized. Use it only in contexts where the
 * language is known to be English e.g. in app-admin
 *
 * Splits a camelCaseString or a TitleCaseString into multiple sentence-cased
 *  words.
 */
export function splitByCaps(input: string): string {
  return smartSentenceCase(input.replace(/([A-Z])/g, ' $1').trim());
}

/**
 * !!! This function is not localized. Use it only in contexts where the
 * language is known to be English.
 */
export function startCase(input: string): string {
  return input
    .split(/\s+/)
    .map((part) => {
      // If acronym, skip
      if (part.toUpperCase() === part) {
        return part;
      } else {
        return upperFirst(part);
      }
    })
    .join(' ');
}

/**
 * @deprecated Avoid English-centric string manipulation functions.
 * Doesn't lower case acronyms.
 */
export function smartLowerCase(
  input: string,
  {
    locale = i18n.locale as SupportedLocale,
  }: {
    locale?: SupportedLocale;
  } = {}
): string {
  return input
    .split(/\s+/)
    .map((part, i) => {
      if (part.toLocaleUpperCase(locale) === part) {
        return part;
      }
      if (
        (locale === 'en-US' || locale === 'en-GB') &&
        part.toLowerCase() === 'id'
      ) {
        return 'ID';
      }
      return part.toLocaleLowerCase(locale);
    })
    .join(' ');

  // const segments = segmentText(input, {
  //   locale,
  //   granularity: 'word',
  // });

  // return Array.from(segments)
  //   .map(({ segment, isWordLike }, i) => {
  //     if (
  //       !isWordLike ||
  //       segment.toLocaleUpperCase(locale) === segment
  //     ) {
  //       return segment;
  //     } else {
  //       return segment.toLocaleLowerCase(locale);
  //     }
  //   })
  //   .join('')
  //   .replace(/\s+/g, ' ');
}

/**
 * @deprecated Avoid English-centric string manipulation functions.
 * Doesn't lower case acronyms and uppercased "id".
 */
export function smartSentenceCase(
  input: string,
  { locale = i18n.locale as SupportedLocale }: { locale?: SupportedLocale } = {}
): string {
  const sentenceCased = input
    .split(/\s+/)
    .map((part, i) => {
      if (part.toLocaleUpperCase(locale) === part) {
        return part;
      }
      if (
        (locale === 'en-US' || locale === 'en-GB') &&
        part.toLowerCase() === 'id'
      ) {
        return 'ID';
      }

      if (i > 0) {
        return part.toLocaleLowerCase(locale);
      } else {
        return part.charAt(0).toLocaleUpperCase(locale) + part.slice(1);
      }
    })
    .join(' ');
  return sentenceCased;
}

/**
 * @deprecated Avoid English-centric string manipulation functions.
 */
export function underscoresToSpaces(input: string): string {
  return input.split('_').join(' ');
}

/**
 * @deprecated Avoid English-centric string manipulation functions. Ask for help
 * in #proj-localization-eng if needed.
 */
export function humanize(input: string): string {
  return splitByCaps(underscoresToSpaces(input));
}

export function temporalWebUrl(path: string): string {
  const temporalWebAddress = must(
    process.env.NEXT_PUBLIC_TEMPORAL_WEB_ADDRESS ??
      process.env.TEMPORAL_WEB_ADDRESS
  );

  return new URL(path, temporalWebAddress).toString();
}

export function apiUrl(path: string): string {
  return new URL(path, 'https://api.watershedclimate.com').toString();
}

export function apiDocsUrl(path: string): string {
  return new URL(path, 'https://api-docs.watershedclimate.com').toString();
}

/**
 * Keep in sync with:
 * workspaces/app-dashboard/services.config.js
 */
const previewDeployURLBases = {
  'dashboard-preview': 'https://dashboard-preview-3jxu.onrender.com',
  'dashboard-preview-two': 'https://dashboard-preview-two.onrender.com',
  'dashboard-preview-three': 'https://dashboard-preview-three.onrender.com',
  'dashboard-preview-eu-one': 'https://dashboard-preview-eu-one.onrender.com',
  'dashboard-pre-production-us':
    'https://dashboard-pre-production-us.onrender.com',
  'dashboard-infra-us': 'https://dashboard-infra-us.onrender.com',
  'dashboard-infra-eu': 'https://dashboard-infra-eu.onrender.com',
  'dashboard-production-graph-us':
    'https://dashboard-production-graph-us.onrender.com',
};
export type PreviewDeployName = keyof typeof previewDeployURLBases;
export const previewDeployNames = Object.keys(
  previewDeployURLBases
) as Array<PreviewDeployName>;

/**
 * Return an environment specific dashboard URL. Fallback to localhost for
 * Storybook. E.g. localhost:3000 vs dashboard.watershedclimate.com
 * @param path
 * @param options: option to specify a particular preview deploy host
 */
export function dashboardUrl(
  path: string,
  options?: {
    previewDeployName?: PreviewDeployName;
  }
): string {
  return new URL(
    path,
    options?.previewDeployName
      ? previewDeployURLBases[options.previewDeployName]
      : (process.env.NEXT_PUBLIC_DASHBOARD_HOST ?? 'http://localhost:3000')
  ).toString();
}

/**
 * Return an environment specific admin URL. E.g. localhost:3001 vs
 * admin.watershedclimate.com
 * @param path
 */
export function adminUrl(path: string): string {
  return new URL(
    path,
    process.env.NEXT_PUBLIC_ADMIN_HOST ?? 'http://localhost:3001'
  ).toString();
}

export function joinWithAnd(words: Iterable<string>): string {
  return formatList([...words], {
    type: 'conjunction',
    locale: i18n.locale,
    style: 'long',
  });
}

export function joinQuotedWithAnd(words: Iterable<string>): string {
  return formatList(
    Array.from(words).map((word) => '"' + word + '"'),
    { type: 'conjunction', locale: i18n.locale, style: 'long' }
  );
}

export function joinTickedWithAnd(words: Iterable<string>): string {
  return formatList(
    Array.from(words).map((word) => '`' + word + '`'),
    { type: 'conjunction', locale: i18n.locale, style: 'long' }
  );
}

export function getRevenueIntensity(
  kgCo2e: number,
  revenueDollars: number
): number {
  const tCo2e = kgCo2e / 1000;
  return tCo2e / (revenueDollars / 1_000_000);
}

// Normalize user-inputted strings
export function normalizeValue(inputValue: string): string {
  let result = inputValue.trim();

  if (result === '') {
    return '0';
  }

  // This is to convert cases like .4 -> 0.4, -.4 -> -0.4.
  const noPrecedingZeroForOnlyDecimalRegex = /(-?)(\..*)/;
  const parts = noPrecedingZeroForOnlyDecimalRegex.exec(result)?.groups;
  if (parts) {
    const optSignPart = parts[1];
    const decimalPart = parts[2];

    result = `${optSignPart}0${decimalPart}`;
  }

  // Remove trailing decimal point
  if (result.endsWith('.')) {
    result = result.substr(0, result.length - 1);
  }

  // Pass to-and-from number
  const valueAsNumber = parseFloat(result);
  const numberAsString = valueAsNumber.toString();
  return numberAsString;
}

// This is the same as normalizeValue, but allows for empty values
export function normalizeValueAllowEmpty(inputValue: string): string {
  let result = inputValue.trim();

  if (result === '') {
    return '';
  }

  // This is to convert cases like .4 -> 0.4, -.4 -> -0.4.
  const noPrecedingZeroForOnlyDecimalRegex = /(-?)(\..*)/;
  const parts = noPrecedingZeroForOnlyDecimalRegex.exec(result)?.groups;
  if (parts) {
    const optSignPart = parts[1];
    const decimalPart = parts[2];

    result = `${optSignPart}0${decimalPart}`;
  }

  // Remove trailing decimal point
  if (result.endsWith('.')) {
    result = result.substr(0, result.length - 1);
  }

  // Pass to-and-from number
  const valueAsNumber = parseFloat(result);
  const numberAsString = valueAsNumber.toString();
  return numberAsString;
}

export { randomString, subscriptDigits, randomDigits };

/**
 * Converts a string to PascalCase.
 *
 * !!! This function is not localized. Use it only in contexts where the
 * language is known to be English.
 */
export function pascalCase(s: string): string {
  return upperFirst(camelCase(s));
}

/**
 * Returns a boolean indicating whether a value is a Promise.
 */
export function isPromise(value: unknown): value is Promise<unknown> {
  return (
    value instanceof Promise ||
    (typeof (value as Promise<unknown> | null)?.then === 'function' &&
      typeof (value as Promise<unknown> | null)?.catch === 'function' &&
      typeof (value as Promise<unknown> | null)?.finally === 'function')
  );
}

/**
 * Returns a boolean indicating whether a Date is invalid. An "invalid Date" is
 * like what you get from `new Date('foo')`.
 */
export function isDateInvalid(input: Date): boolean {
  return isNaN(Number(input));
}

/**
 * Returns two letters representing a name. If the name has multiple words
 * (space-separated chunks), returns the first letters of the first and last
 * words. Otherwise, returns the first two letters of the name.
 */
export function nameToInitials(name: string): string {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  const words = name.toLocaleUpperCase().split(' ');
  if (words.length === 1) {
    return words[0].slice(0, 2);
  } else {
    return `${words[0][0]}${words[words.length - 1][0]}`;
  }
}
