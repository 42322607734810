import { useState } from 'react';
import { Trans } from '@lingui/react/macro';
import { Box, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Link from '@watershed/ui-core/components/TextLink';
import Button from '@watershed/ui-core/components/Button';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';
import clsx from 'clsx';
import { makeSerializableError } from '@watershed/shared-universal/utils/serializableError';
import PopOutIcon from '@watershed/icons/components/PopOut';
import KeyValueRows, { IRenderValue } from './KeyValueRows';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import Linkify from 'react-linkify';
import { temporalWorkflowUrl } from '@watershed/shared-universal/adminRoutes';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      whiteSpace: 'pre-wrap',
      margin: 0,
    },
    message: {
      color: theme.palette.grey100,
      margin: 0,
      whiteSpace: 'pre-wrap',
      fontWeight: 'normal',
    },
    messageSingleLine: {
      font: 'inherit',
      fontWeight: 'inherit',
    },
    code: {
      color: theme.palette.grey100,
      fontFamily: CODE_FONT_FAMILY,
      fontWeight: 'normal',
      fontSize: 12,
      wordBreak: 'break-all',
    },
    detailsButton: {
      color: 'inherit',
      font: 'inherit',
      verticalAlign: 'baseline',
      textDecoration: 'underline',
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      margin: -16,
    },
  })
);

/**
 * Renders a detail value on the left and a new-tab link to related information
 * on the right.
 */
function DetailValueWithRelatedLink({
  renderedValue,
  href,
  linkText,
}: {
  renderedValue: React.ReactNode;
  href: string;
  linkText: string;
}) {
  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>{renderedValue}</Box>
      <Box flexShrink={0}>
        <Link target="_blank" href={href}>
          <Box display="flex" alignItems="center">
            <PopOutIcon style={{ display: 'block' }} size="1rem" />
            <div>{linkText}</div>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}

function DetailValue({
  detailKey,
  detailValue,
  detailData,
}: {
  detailKey: string;
  detailValue: any;
  detailData: Map<string, any>;
}) {
  const classes = useStyles();

  const stringifiedDetailValue =
    typeof detailValue === 'object'
      ? JSON.stringify(detailValue, null, 2)
      : String(detailValue).trim();

  const renderedValue = (
    <pre className={clsx(classes.value, classes.code)}>
      <Linkify textDecorator={(urlText) => urlText.split(/\?/)[0]}>
        {stringifiedDetailValue}
      </Linkify>
    </pre>
  );

  if (detailKey === 'runId') {
    const workflowId = detailData.get('workflowId');
    const runId = detailData.get('runId');

    return (
      <DetailValueWithRelatedLink
        renderedValue={renderedValue}
        href={temporalWorkflowUrl(workflowId, runId)}
        linkText="View workflow history"
      />
    );
  }

  return renderedValue;
}

/**
 * It's useful to sort error properties so we can put the most meaningful ones
 * at the top of the list, the most verbose or least meaningful at the bottom.
 */
export function sortErrorProperties(
  properties: Array<[string, any]>
): Array<[string, any]> {
  // Add to these lists when we have other known properties we want to control
  // sorting for. Any properties not in these lists will be in the middle of the
  // list in their original order.
  const firsts = [
    'message',
    'code',
    'errorType',
    'workflowType',
    'activityType',
    'workflowId',
    'runId',
    'activityId',
  ];
  const lasts = ['stackTrace'];

  return properties.slice().sort(([keyA], [keyB]) => {
    if (firsts.includes(keyA) && firsts.includes(keyB)) {
      return firsts.indexOf(keyA) - firsts.indexOf(keyB);
    }
    if (firsts.includes(keyA)) {
      return -1;
    }
    if (firsts.includes(keyB)) {
      return 1;
    }

    if (lasts.includes(keyA) && lasts.includes(keyB)) {
      return lasts.indexOf(keyA) - lasts.indexOf(keyB);
    }
    if (lasts.includes(keyA)) {
      return 1;
    }
    if (lasts.includes(keyB)) {
      return -1;
    }

    return 0;
  });
}

const renderValue: IRenderValue = (k: string, v: any, d: Map<string, any>) => {
  return <DetailValue detailKey={k} detailValue={v} detailData={d} />;
};

interface DetailedErrorProps {
  /**
   * Typically, `error` should be an Error instance, a string, or a
   * SerializedError object. But we'll try to coerce any value into a
   * presentable shape.
   */
  error: Error | string | any;
  detailsButtonPosition?: 'right' | 'bottom';
}

/**
 * Render errors with an arbitrary number of possibly verbose details. Presents
 * just the `message` and `code` upfront, with the rest of the details stashed
 * in a dialog. Expected properties in the error's "details" might be rendered
 * in specialized ways, like a workflow run ID linking out to the Temporal UI.
 */
export default function DetailedError({
  error,
  detailsButtonPosition = 'right',
}: DetailedErrorProps) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const normalizedError =
    error instanceof Error || typeof error === 'string'
      ? makeSerializableError(error)
      : error;
  const properties: Record<string, unknown> = {
    ...normalizedError.details,
    message: normalizedError.message,
    code: normalizedError.code,
    stackTrace: normalizedError.stackTrace,
    errorType: normalizedError.errorType,
  };
  delete properties.__typename;
  const hasDetails = Object.values(properties).filter(
    (x) => x != null && x !== ''
  ).length;
  const keyValueList = new Map(sortErrorProperties(Object.entries(properties)));

  const dialog = isDialogOpen && (
    <Dialog
      onClose={closeDialog}
      header={{ title: 'Error details' }}
      maxWidth="md"
      // Force the dialog to be on top of the snackbar.
      sx={{ zIndex: 9999 }}
      actions={[
        <Button key="ok" onClick={closeDialog} color="primary" type="button">
          <Trans context="Button copy">OK</Trans>
        </Button>,
      ]}
    >
      <div className={classes.wrapper}>
        <KeyValueRows
          keyWidth={160}
          data={keyValueList}
          renderValue={renderValue}
          paddingX={16}
        />
      </div>
    </Dialog>
  );

  const trimmedMessage = normalizedError.message.trim();
  const messageClasses = clsx(
    classes.message,
    trimmedMessage.includes('\n') ? classes.code : classes.messageSingleLine
  );

  return (
    <>
      <Box
        display={detailsButtonPosition === 'right' ? 'flex' : 'block'}
        alignItems="baseline"
      >
        <Box flexGrow={1}>
          <pre className={messageClasses} style={{ overflowWrap: 'anywhere' }}>
            {trimmedMessage}
          </pre>
          {normalizedError.code && (
            <code className={classes.code}>
              <Trans context="Error detail UI">Error code:</Trans>{' '}
              {normalizedError.code}
            </code>
          )}
          {normalizedError.details?.message && (
            <>
              <br />
              <code className={classes.code}>
                <Trans context="Error details UI">Details:</Trans>{' '}
                {normalizedError.details.message}
              </code>
            </>
          )}
        </Box>
        {hasDetails > 1 && (
          <Box
            flexShrink={0}
            marginLeft={detailsButtonPosition === 'right' ? 1 : 0}
            marginTop={detailsButtonPosition === 'bottom' ? 1 : 0}
          >
            <Link
              type="button"
              onClick={openDialog}
              className={classes.detailsButton}
            >
              <Trans context="Link copy to view more details">Details</Trans>
            </Link>
          </Box>
        )}
      </Box>
      {dialog}
    </>
  );
}

/**
 * A DetailedError pre-wrapped in an ErrorBox.
 */
export function DetailedErrorBox({
  level = 'error',
  ...props
}: DetailedErrorProps & {
  level?: 'error' | 'warning';
}) {
  return (
    <ErrorBox
      level={level}
      sx={{
        alignItems: 'flex-start',
        padding: 2,
      }}
    >
      <DetailedError {...props} />
    </ErrorBox>
  );
}
