export const FootprintKind = {
  CorporateFootprint: 'corporate_footprint',
};

export const HeaderKeys = {
  cloudflareRayId: 'cf-ray',
  forwardedFor: 'x-forwarded-for',
  isDevProxy: 'x-watershed-dev-proxy',
  activeOrganizationId: 'x-ws-active-organization-id',
  fullstorySessionUrl: 'x-fullstory-session-url',
  fullstorySessionId: 'x-fullstory-session-id',
  watershedBrowserRequestId: 'watershed-browser-request-id',
  playwrightTest: 'x-playwright-test',
  playwrightTestType: 'x-playwright-test-type',
  playwrightCheckUrl: 'x-playwright-test-check-url',
  playwrightTestId: 'x-playwright-test-id',
  playwrightTestFile: 'x-playwright-test-file',
  localizationEnv: 'x-ws-localization-env',
};

export const CHANGESET_NAME_REGEX = /^[a-zA-z0-9_\- ]+$/;

// Constrain changeset name lengths so that pipeline tests run with those
// changesets don't throw BQ label value errors.
const QA_CONVERSION_FACTOR_TABLE_ID_SUFFIX = '_conversion_factor';
const BQ_MAX_LABEL_VALUE_LENGTH = 63;
export const CHANGESET_NAME_MAX_LENGTH =
  BQ_MAX_LABEL_VALUE_LENGTH - QA_CONVERSION_FACTOR_TABLE_ID_SUFFIX.length;

// Prefix to avoid naming collisions post column-mapping
export const UNMAPPED_COLUMN_PREFIX = 'wx_';

// Sidebar constants
export const SIDEBAR_BREAKPOINT_WIDTH = 1420;
export const SIDEBAR_EXPANDED_WIDTH = 260;
export const SIDEBAR_COLLAPSED_WIDTH = 48;
