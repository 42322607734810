import { Typography } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import WarningIcon from '@watershed/icons/components/Warning';
import TextLink from './TextLink';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export function UnexpectedErrorState() {
  return (
    <>
      <WarningIcon size={128} marginBottom={1} color="error.main" />
      <Typography variant="h1" gutterBottom>
        <Trans context="Generic error message header">
          Something went wrong
        </Trans>
      </Typography>
      <Typography variant="body2" data-testid={TestIds.UnexpectedErrorPageBody}>
        <Trans context="Generic error message content">
          Our engineering team has been notified and will investigate the issue.
          Reloading the page or hitting the back button in your browser may
          resolve the issue. If you have additional questions, please email{' '}
          <TextLink href="mailto:support@watershed.com">
            support@watershed.com
          </TextLink>
        </Trans>
      </Typography>
    </>
  );
}
