import { NextRouter, useRouter } from 'next/router';
import { useMemo } from 'react';

export interface GlobalLocation {
  location: { search: string; pathname: string; hash: string } & {
    state: unknown;
    toString: () => string;
    pathnameWithQuery: string;
  };
  push: NextRouter['push'];
  replace: NextRouter['replace'];
  queryParams: URLSearchParams;
}

// I am not 100% confident in this approach,
// and we don't really use it on the server
// because today we aren't doing any server rendering
// but we should tear down useGlobalLocation anyway
// because it was only an abstraction for use during
// the migration
export function getServerSideLocation(origin: string, routerPath: string) {
  const url = new URL(`${origin.replace(/\/$/, '')}${routerPath}`);
  return {
    ancestorOrigins: {},
    href: url.href,
    origin: url.origin,
    protocol: url.protocol,
    host: url.host,
    hostname: url.hostname,
    port: url.port,
    pathname: url.pathname,
    search: url.search,
    hash: url.hash,
  };
}

/*
  We are in the midst of a migration from React Router to Next.js
  We have discovered that the two, living together, have separate 
  histories, and this is causing issues.

  1. Push/replace doesn't trigger the other library's hooks
  2. Reading from state doesn't capture updates from the other library

  This hook solves #1 by using both libraries hooks, therefore always updating
  regardless of which library triggers the update.

  This hook solves #2 by using the state from the window, which is updated 
  in either case.

  Once we are fully on Next.js and have removed react-router-dom as a 
  dependency, we can discuss the best path forward for this hook and
  whether we plan to use it or the Next.js tools directly.
*/
export default function useGlobalLocation(): GlobalLocation {
  const router = useRouter();

  // For server rendering we need to avoid checking the window object
  const isServer = typeof window === 'undefined' || !window.location;
  const locationKey = isServer ? router.asPath : window.location.toString();

  if (!process.env.NEXT_PUBLIC_API_HOST) {
    throw new Error('Must have process.env.NEXT_PUBLIC_API_HOST');
  }
  const serverSideLocation = getServerSideLocation(
    process.env.NEXT_PUBLIC_API_HOST,
    router.asPath
  );

  const location = useMemo(
    () => ({
      ...(isServer ? serverSideLocation : window.location),
      state: {},
      toString: () =>
        isServer ? serverSideLocation.href : window.location.toString(),
      pathname: isServer
        ? router.asPath.split('?')?.[0]
        : window.location.pathname,
      pathnameWithQuery: isServer
        ? router.asPath
        : window.location.toString().replace(window.location.origin, ''),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationKey]
  );

  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationKey]
  );

  return {
    location,
    queryParams,
    push: router.push,
    replace: router.replace,
  };
}
