import { Dialog, DialogHeaderProps, DialogProps } from './Dialog';
import { Trans, useLingui } from '@lingui/react/macro';
import { Stack, SxProps, Typography } from '@mui/material';
import Button, { ButtonProps } from '@watershed/ui-core/components/Button';
import ProgressButton from './ProgressButton';
import ErrorBox from './ErrorBox';
import React, { useCallback, useState } from 'react';
import { usePortalContext } from '../utils/PortalContext';
import DeleteIcon from '@watershed/icons/components/Delete';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { Theme } from '@mui/system';
import invariant from 'invariant';

import { TextFieldNonFormik } from './Form/TextField';

type DialogConfirmProps = React.ComponentProps<typeof DialogConfirm>;

export type OpenDialogConfirmProps = Omit<DialogConfirmProps, 'onClose'> &
  Partial<Pick<DialogConfirmProps, 'onClose'>>;

export function useDialogConfirm() {
  const { addPortal, removePortal } = usePortalContext();

  const openDialog = useCallback(
    (props: OpenDialogConfirmProps) =>
      new Promise<boolean>((resolve) => {
        const portalId = addPortal(
          <DialogConfirm
            {...props}
            onClose={async (isConfirmed) => {
              await props.onClose?.(isConfirmed);
              resolve(isConfirmed);
              removePortal(portalId);
            }}
          />
        );
      }),
    [addPortal, removePortal]
  );

  return openDialog;
}

export function getDialogConfirmDeleteProps({
  itemName,
}: {
  itemName: LocalizedString;
}) {
  return {
    title: `Delete ${itemName}`,
    message: `Are you sure you want to delete this ${itemName}?`,
    confirmText: 'Delete',
    confirmIcon: <DeleteIcon />,
  };
}

// TODO (bryan): Organize the "confirm" button props into a single component,
// rather than having so many?
export default function DialogConfirm({
  onClose: initialOnClose,
  title,
  titleVariant,
  subtitle,
  border,
  headerActions,
  message,
  confirmText,
  confirmIcon,
  confirmColor,
  cancelText,
  cancelColor,
  errorMessage,
  autoFocusConfirm = true,
  disableConfirm,
  messageSx,
  promptText,
  ...dialogProps
}: {
  onClose: (isConfirmed: boolean) => void | Promise<void>;
  title: DialogHeaderProps['title'];
  titleVariant?: DialogHeaderProps['titleVariant'];
  subtitle?: DialogHeaderProps['subtitle'];
  border?: DialogHeaderProps['border'];
  headerActions?: DialogHeaderProps['actions'];
  message?: React.ReactNode;
  confirmText?: ButtonProps['children'];
  confirmIcon?: ButtonProps['startIcon'];
  confirmingText?: ButtonProps['children'];
  confirmColor?: ButtonProps['color'];
  cancelText?: ButtonProps['children'];
  cancelColor?: ButtonProps['color'];
  errorMessage?: React.ReactNode;
  autoFocusConfirm?: boolean;
  disableConfirm?: boolean;
  messageSx?: SxProps<Theme>;
  promptText?: LocalizedString;
} & Pick<
  DialogProps,
  'maxWidth' | 'disableBackdropClick' | 'disableEscapeKeyDown'
>) {
  const { t } = useLingui();
  confirmText ??= t({
    message: 'OK',
    context: 'Button title to confirm an action in a dialog.',
  });
  cancelText ??= t({
    message: 'Cancel',
    context: 'Button title to cancel out of a dialog.',
  });

  const [isConfirming, setIsConfirming] = useState(false);
  const [inputPromptText, setInputPromptText] = useState<string | undefined>(
    undefined
  );
  const onClose = async (isConfirmed: boolean) => {
    setIsConfirming(isConfirmed);
    await initialOnClose(isConfirmed);
    setIsConfirming(false);
  };
  const isMessageString = typeof message === 'string';
  invariant(
    isMessageString || !messageSx,
    'messageSx is not supported when message is an element. Use messageSx with a string message only'
  );
  const BodyElem = isMessageString ? Stack : React.Fragment;
  const bodyElemProps = isMessageString
    ? {
        gap: 1,
      }
    : undefined;
  // TODO (bryan): I think this string-parsing is a lil too clever - can/should
  // we audit existing usage and just pass in a variant instead? I see how this
  // inference helps avoid nonsensical dialogs (e.g. destructive action with a
  // blue button), but it doesn't feel extensible (e.g. I wanted to add the word
  // "Replace" but didn't want to break other usage that may rely on the
  // existing inference.)
  const inferredConfirmColor =
    typeof confirmText === 'string' &&
    (confirmText.includes('Delete') ||
      confirmText.includes('Remove') ||
      confirmText.includes('Reset'))
      ? 'error'
      : 'primary';

  const isPromptTextInvalid = !!promptText && inputPromptText !== promptText;
  const maybePromptTextTooltip = isPromptTextInvalid
    ? t`Please enter the text "${promptText}" to confirm.`
    : undefined;

  return (
    <Dialog
      maxWidth="sm"
      {...dialogProps}
      onClose={() => onClose(false)}
      header={{
        title,
        titleVariant,
        subtitle,
        actions: headerActions,
        hideClose: true,
        border,
      }}
      actions={
        <>
          <Button onClick={() => onClose(false)} color={cancelColor}>
            {cancelText}
          </Button>
          <ProgressButton
            data-testid={TestIds.DialogConfirmSubmitButton}
            onClick={() => onClose(true)}
            isInProgress={isConfirming}
            startIcon={confirmIcon}
            autoFocus={autoFocusConfirm}
            color={confirmColor ?? inferredConfirmColor}
            disabled={disableConfirm || isPromptTextInvalid}
            tooltip={maybePromptTextTooltip}
          >
            {confirmText}
          </ProgressButton>
        </>
      }
      children={
        message || errorMessage || promptText ? (
          <BodyElem {...bodyElemProps}>
            {isMessageString ? (
              <Typography variant="body2" sx={messageSx}>
                {message}
              </Typography>
            ) : (
              message
            )}
            {promptText && (
              <TextFieldNonFormik
                label={
                  <Trans context="Wraps a provided dialog prompt with instructions">
                    Enter "{promptText}" without the quotes to continue.
                  </Trans>
                }
                id="prompt"
                value={inputPromptText}
                onChange={(e) => setInputPromptText(e.target.value)}
              />
            )}
            {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
          </BodyElem>
        ) : undefined
      }
    />
  );
}
