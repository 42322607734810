import gql from 'graphql-tag';
import {
  useFindDuckMutation,
  useShouldShowDuckQuery,
} from '@watershed/app-admin/generated/urql';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDialog } from '@watershed/ui-core/hooks/useDialog';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import PopOutIcon from '@watershed/icons/components/PopOut';
import { routeForDuckHunt } from '@watershed/shared-universal/adminRoutes';
import { useRouter } from 'next/router';

import Button from '@watershed/ui-core/components/Button';

gql`
  query ShouldShowDuck($duckName: String!) {
    shouldShowDuck(duckName: $duckName)
  }

  mutation FindDuck($input: FindDuckInput!) {
    findDuck(input: $input) {
      description
    }
  }
`;

function DuckHuntDuckDialog({
  duckName,
  description,
  onClose,
}: {
  duckName: string;
  description: string;
  onClose: () => void;
}) {
  const router = useRouter();

  const lines = description.split('\n\n');
  const linesWithLinksReplaced = lines.map((line) =>
    line.replace(/\[(.*?)\]\((.*?)\)/g, (match, text, url) => {
      return `${text}, see ${url}`;
    })
  );

  return (
    <Dialog onClose={onClose}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h1">
          🦆 Quack! You found the {duckName}! 🦆
        </Typography>
        {linesWithLinksReplaced.map((line) => (
          <Typography key={line} variant="body1">
            {line}
          </Typography>
        ))}
        <Button
          onClick={() => {
            // TODO: URGENT Please fix this by await-ing or void-ing this line.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            router.push(routeForDuckHunt());
            onClose(); // if already hunt page, just close dialog
          }}
          startIcon={<PopOutIcon />}
        >
          Go to hunt page
        </Button>
      </Stack>
    </Dialog>
  );
}

/**
 * This is an easter egg scavenger hunt around Admin! Go to /duck-hunt to learn
 * more. It shouldn't affect functionality anywhere it's used, but if you have
 * concerns, please don't hesitate to reach out to @danielchiu.
 */
export default function DuckHuntDuck({ duckName }: { duckName: string }) {
  const openDialog = useDialog(DuckHuntDuckDialog);

  const [res] = useShouldShowDuckQuery({ variables: { duckName } });
  const [, executeFindDuck] = useFindDuckMutation();

  if (res.fetching || res.error || !res.data?.shouldShowDuck) {
    return null;
  }

  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={async (e) => {
        e.stopPropagation();
        const res = await executeFindDuck({
          input: { duckName },
        });

        if (res.error || !res.data) {
          // don't hit sentry
          console.error(`error finding duck ${duckName}`, res.error);
          return;
        }
        openDialog({ duckName, description: res.data.findDuck.description });
      }}
    >
      🦆
    </Box>
  );
}
