import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip';
import { type Theme } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';
import BoxLink from '@watershed/ui-core/components/BoxLink';
import { getPaletteUtils } from '@watershed/style/styleUtils';
import { TopLevelNavItem, TOP_LEVEL_NAV_ITEMS } from './AdminTopLevelNavLinks';

export default function AdminAppsDrawer({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) {
  return (
    <Slide in={open} onMouseLeave={onClose} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: 'fixed',
          width: 400,
          borderRadius: 2,
          border: (theme) => `1px solid ${theme.palette.background.default}`,
          top: 48 + 8,
          right: 8,
          backgroundColor: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => getPaletteUtils(theme.palette).boxShadowLg,
          zIndex: 100,
        }}
      >
        <QuickLinks links={TOP_LEVEL_NAV_ITEMS} />
      </Box>
    </Slide>
  );
}

interface Props {
  links: Array<TopLevelNavItem>;
}
function QuickLink({ item }: { item: TopLevelNavItem }) {
  const iconComponent = (
    <Box
      bgcolor={item.bg}
      sx={{
        width: 64,
        height: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 64,
        color: (theme: Theme) => theme.palette.common.white,
        marginBottom: 1,
        '& svg': {
          width: 40,
          height: 40,
        },
      }}
    >
      {item.icon}
    </Box>
  );
  const labelComponent = (
    <Typography variant="h4" component="strong">
      {item.shortLabel ?? item.label}
    </Typography>
  );
  return (
    <Grid item xs={3}>
      <Tooltip title={item.label} placement="top">
        <BoxLink
          href={item.to}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textDecoration: 'none',
            background: 'transparent',
            border: 0,
            cursor: 'pointer',
            minWidth: 63,
            transition: (theme: Theme) => theme.transitions.create('opacity'),
            '& strong': {
              transition: (theme: Theme) => theme.transitions.create('color'),
            },
            '&:hover strong, &:focus strong': {
              color: (theme: Theme) => theme.palette.text.secondary,
            },
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          {iconComponent}
          {labelComponent}
        </BoxLink>
      </Tooltip>
    </Grid>
  );
}

function QuickLinks({ links }: Props) {
  return (
    <Grid container spacing={2} padding={2}>
      {links.map((navItem) => (
        <QuickLink key={navItem.label} item={navItem} />
      ))}
    </Grid>
  );
}
