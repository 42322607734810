import { Stack, Typography } from '@mui/material';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import React from 'react';

export type CommandPaletteShortcutButtonProps = {
  description: string;
  onClick?: () => void;
} & (
  | {
      iconRepresentation?: undefined;
      textRepresentation: string | React.ReactNode;
    }
  | {
      iconRepresentation: React.ReactNode;
      textRepresentation?: undefined;
    }
);

export default function CommandPaletteShortcutButton({
  description,
  textRepresentation,
  iconRepresentation,
  onClick,
}: CommandPaletteShortcutButtonProps) {
  return (
    <Stack
      gap={0.75}
      direction="row"
      component="button"
      onClick={onClick}
      border="0"
      borderRadius="4px"
      py={0.5}
      pl={0.5}
      pr={1}
      bgcolor="transparent"
      alignItems="center"
      sx={{
        ':hover': {
          backgroundColor: (theme) => theme.palette.grey10,
          '& div': {
            backgroundColor: (theme) => theme.palette.grey20,
          },
        },
      }}
    >
      {textRepresentation ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          fontSize="11px"
          px={0.5}
          height={20}
          flexShrink={0}
          borderRadius="4px"
          textTransform="uppercase"
          letterSpacing="0.025rem"
          color={(theme) => theme.palette.grey70}
          fontWeight="bold"
          border={(theme) => `1px solid ${theme.palette.grey30}`}
          fontFamily={CODE_FONT_FAMILY}
        >
          {textRepresentation}
        </Stack>
      ) : (
        <Stack
          color={(theme) => theme.palette.grey50}
          component="span"
          mr="-2px"
        >
          {iconRepresentation}
        </Stack>
      )}
      <Typography variant="body3" color={(theme) => theme.palette.grey50}>
        {description}
      </Typography>
    </Stack>
  );
}
