import { Box, BoxProps, Grow, Theme, Typography } from '@mui/material';
import { SystemCssProperties } from '@mui/system';
import InfoIcon from '@watershed/icons/components/Info';
import WarningIcon from '@watershed/icons/components/Warning';

/**
 * Use ui-core/Callout instead.
 */
export default function ErrorBox({
  variant = 'contained',
  level = 'error',
  children,
  color,
  ...props
}: BoxProps & {
  variant?: 'contained' | 'text';
  level?: 'error' | 'warning' | 'info';
} & Pick<SystemCssProperties<Theme>, 'color'>) {
  const mainColor = ((): string => {
    switch (level) {
      case 'error':
        return 'error.main';
      case 'warning':
        return 'warning.dark';
      case 'info':
        return 'info.main';
    }
  })();
  const styleProps =
    variant === 'contained'
      ? {
          bgcolor: mainColor,
          color: 'white',
          padding: 1,
          borderRadius: '6px',
        }
      : {
          color: mainColor,
        };
  const iconProps = {
    size: 16,
    marginRight: 1,
    flexShrink: 0,
  };
  return (
    <Grow in>
      <Box
        display="flex"
        alignItems="start"
        {...styleProps}
        aria-live="assertive"
        role="alert"
        {...props}
      >
        <Box display="flex" alignItems="center" sx={{ height: '24px' }}>
          {level === 'info' ? (
            <InfoIcon {...iconProps} />
          ) : (
            <WarningIcon {...iconProps} />
          )}
        </Box>
        <Box flexGrow={1}>
          <Typography variant="h4" component="div" color={color ?? 'inherit'}>
            {children}
          </Typography>
        </Box>
      </Box>
    </Grow>
  );
}
