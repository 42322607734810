import {
  Box,
  // eslint-disable-next-line no-restricted-imports
  Dialog as MUIDialog,
  type DialogProps as MUIDialogProps,
  // eslint-disable-next-line no-restricted-imports
  DialogContent,
  // eslint-disable-next-line no-restricted-imports
  DialogActions,
  Theme,
  Typography,
  BoxProps,
  // eslint-disable-next-line no-restricted-imports
  IconButton,
  type TypographyProps,
  SxProps,
  Stack,
} from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import Skeleton from '@watershed/ui-core/components/Skeleton';
import React, {
  ReactNode,
  ComponentType,
  ComponentProps,
  useRef,
  useContext,
} from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import PageSplit, { PageSplitProps } from './PageSplit';
import CloseIcon from '@watershed/icons/components/Close';
import withWaitForModalClose from '../utils/withWaitForModalClose';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { mixinSx } from '@watershed/style/styleUtils';
import AnimateHeight from './AnimateHeight';
import { UnexpectedErrorState } from './UnexpectedErrorState';

const headerSx: SxProps<Theme> = {
  display: 'grid',
  // minmax(0px, 1fr) is used to prevent the title from overflowing the dialog.
  // The default for 1fr is silently minmax(1fr, 100%) which causes the title to overflow if it can't line-break.
  // Instead, we want it to be cut off with an ellipsis.
  gridTemplateColumns: 'minmax(0px, 1fr) auto',
  color: (theme) => theme.palette.text.primary,
  alignItems: 'start',
  justifyContent: 'space-between',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  padding: (theme) => theme.spacing(2),
  borderTopLeftRadius: (theme) => theme.shape.borderRadius,
  borderTopRightRadius: (theme) => theme.shape.borderRadius,
  backgroundColor: (theme) => theme.palette.background.paper,
};

const borderBottomSx: SxProps<Theme> = {
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
};

const noHeaderBorderSx: SxProps<Theme> = {
  paddingBottom: 0,
};

const subtitleSx: SxProps<Theme> = {
  textWrap: 'balance',
  margin: (theme) => theme.spacing(0, 0, 0),
  lineHeight: '24px',
  '& a': {
    color: 'inherit',
  },
};

const dialogSectionSx: SxProps<Theme> = {
  marginLeft: (theme) => theme.spacing(-2),
  marginRight: (theme) => theme.spacing(-2),
  '&:first-of-type': {
    marginTop: (theme) => theme.spacing(-2),
  },
  '&:last-of-type:not(:first-of-type)': {
    marginBottom: (theme) => theme.spacing(-2),
  },
  '&:not(:last-of-type)': {
    paddingBottom: (theme) => theme.spacing(2),
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  },
};

const DialogHeader = ({
  title,
  titleVariant = 'h2',
  renderTitle,
  sectionName,
  subtitle,
  actions,
  hideClose,
  onClose,
  border,
  sx,
  actionsSx,
}: DialogHeaderProps) => {
  const { t } = useLingui();
  return (
    <Stack
      flexDirection="row"
      component="header"
      sx={mixinSx(headerSx, border ? borderBottomSx : noHeaderBorderSx, sx)}
    >
      <Stack padding={0} gap={0.5} flexGrow={1}>
        {sectionName && (
          <Typography variant="body2" gutterBottom>
            {sectionName}
          </Typography>
        )}
        {/* TODO(ankitr): Change renderTitle + title into something with MaybeTypography */}
        {renderTitle && typeof title === 'string' ? (
          renderTitle(title)
        ) : (
          <Typography
            variant={titleVariant}
            // Applying lineHeight to typography to align centrally when close button is shown.
            sx={{
              lineHeight: '28px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            id="title"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" sx={subtitleSx}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        position="relative"
        gap={1}
        sx={actionsSx}
      >
        {actions}
        {!hideClose && (
          <IconButton
            component="button"
            onClick={onClose}
            sx={{
              padding: 0.5,
              alignSelf: 'center',
              color: (theme) => theme.palette.grey70,
            }}
            title={t({
              message: 'Close',
              context: 'Button text to close dialog',
            })}
            tabIndex={0}
            size="large"
            data-testid={TestIds.CloseDialogButton}
          >
            <CloseIcon size={20} />
          </IconButton>
        )}
      </Box>
    </Stack>
  );
};

export type DialogProps = Omit<MUIDialogProps, 'open' | 'fullScreen'> & {
  variant?: 'standard' | 'expanded' | 'fullScreen';
  open?: boolean;
  onClose: () => void;
  disableClose?: boolean;
  header?: DialogHeaderProps;
  actions?: ReactNode;
  actionsProps?: ComponentProps<typeof DialogActions>;
  component?: ComponentType<
    React.PropsWithChildren<{
      sx?: SxProps<Theme>;
      children?: BoxProps['children'];
    }>
  >;
  contentSx?: SxProps<Theme>;
  componentSx?: SxProps<Theme>;
  disableBackdropClick?: boolean;
  loading?: DialogLoadingConfig | boolean;
  animateHeight?: boolean;
  disableWaitForModalClose?: boolean;
  scrollContent?: boolean;
};

type DialogLoadingConfig = {
  loading: boolean;
  height?: string | number;
};

export const Dialog = withWaitForModalClose(function Dialog({
  variant = 'standard',
  header,
  children,
  actions,
  actionsProps,
  style,
  contentSx,
  open = true,
  component: Component = Box,
  componentSx,
  className,
  onClose,
  disableClose,
  disableBackdropClick,
  fullWidth = true,
  loading = false,
  animateHeight = false,
  scrollContent = false,
  ...props
}: DialogProps) {
  const paperProps: DialogProps['PaperProps'] =
    variant === 'expanded'
      ? {
          ...(props.PaperProps ?? {}),
          sx: mixinSx(
            {
              bottom: 0,
              position: 'fixed',
              height: '95vh',
              borderTopLeftRadius: (theme) => theme.shape.borderRadius,
              borderTopRightRadius: (theme) => theme.shape.borderRadius,
            },
            props.PaperProps?.sx
          ),
        }
      : props.PaperProps;
  const loadingConfig: DialogLoadingConfig =
    typeof loading === 'boolean'
      ? {
          loading,
          height: 200,
        }
      : loading;
  // A bit of an edge case, but we want that case to be buttery smooth. If the
  // dialog goes back into a loading state, e.g. after the user edits a form
  // field that triggers a new query, we want to avoid a jittery UX where the
  // dialog height reverts to the original `loadingConfig.height` and then
  // animates back to the new content height when the query completes. Instead,
  // we want to keep the _last_ non-loading content height and use that for the
  // next loading state height.
  const lastNonLoadingHeightRef = useRef<number | null>(null);

  const { ErrorFallback } = useDialogContext();

  return (
    <MUIDialog
      fullWidth={fullWidth}
      open={open}
      onClose={(_, reason) => {
        if (disableClose) {
          return;
        }
        if (reason === 'backdropClick' && disableBackdropClick) {
          return;
        }
        if (reason === 'escapeKeyDown' && props.disableEscapeKeyDown) {
          return;
        }
        onClose();
      }}
      PaperProps={paperProps}
      fullScreen={variant === 'fullScreen' || variant === 'expanded'}
      {...props}
    >
      {/* ErrorBoundary because dialogs often fire off queries, which can fail,
      and it's a poor experience to crash the whole page */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {header && (
          <DialogHeader
            onClose={onClose}
            hideClose={disableClose}
            border={loadingConfig.loading || !!children}
            {...header}
          />
        )}
        <Component
          sx={mixinSx(
            variant === 'expanded' ? { height: '100%' } : undefined,
            scrollContent ? { height: '100%', overflowY: 'auto' } : undefined,
            componentSx
          )}
        >
          {(() => {
            const content = loadingConfig.loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={lastNonLoadingHeightRef.current ?? loadingConfig.height}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {children && (
                  <DialogContent
                    style={style}
                    sx={contentSx}
                    className={className}
                    children={children}
                  />
                )}
                {!scrollContent && actions && (
                  <DialogActions {...actionsProps} children={actions} />
                )}
              </>
            );

            if (animateHeight) {
              return (
                <AnimateHeight
                  onTransitionEnd={(e) => {
                    if (!loadingConfig.loading) {
                      lastNonLoadingHeightRef.current = (
                        e.currentTarget as HTMLElement
                      ).getBoundingClientRect().height;
                    }
                  }}
                >
                  {content}
                </AnimateHeight>
              );
            }
            return content;
          })()}
        </Component>
        {scrollContent && actions && (
          <DialogActions {...actionsProps} children={actions} />
        )}
      </ErrorBoundary>
    </MUIDialog>
  );
});

export function DialogLoading(props: DialogProps) {
  return (
    <Dialog
      header={{
        title: '',
        renderTitle: () => <Skeleton width={360} height={32} />,
      }}
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingY={6}
      >
        <CircularProgress />
      </Box>
    </Dialog>
  );
}

// probably should name this better / make it the defaut?

export function DialogOpenVariant(props: DialogProps) {
  const headerDefaults = props.header && {
    header: { titleVariant: 'h2', border: false, ...props.header } as const,
  };
  return <Dialog {...props} {...headerDefaults} />;
}

export interface DialogHeaderProps {
  sectionName?: LocalizedString;
  title: LocalizedString | React.ReactNode;
  titleVariant?: TypographyProps['variant'];
  renderTitle?: (title: LocalizedString) => React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  hideClose?: boolean;
  onClose?: () => void;
  border?: boolean;
  sx?: SxProps<Theme>;
  actionsSx?: SxProps<Theme>;
  /**
   * Styling that applies to the grid item wrapping the title content
   */
  titleGridItemSx?: SxProps<Theme>;
}

export const DialogSplit = (props: PageSplitProps) => (
  <PageSplit {...props} style={{ margin: -16, ...props.style }} />
);

type TitleProps =
  | { title?: LocalizedString; titleVariant?: 'h2' | 'h3' | 'h4' }
  | { title?: React.ReactNode; titleVariant?: undefined };

export function DialogSection({
  title,
  titleVariant,
  actions,
  anchor,
  children,
  showBottomBorder,
  sectionSx,
  titleSx,
  ...boxProps
}: Omit<BoxProps, 'title'> &
  TitleProps & {
    sectionSx?: SxProps<Theme>;
    titleSx?: SxProps<Theme>;
    actions?: React.ReactNode;
    anchor?: string;
    children: React.ReactNode;
    showBottomBorder?: boolean;
  }) {
  // Kind of annoying but it keeps the type discrimination intact for TitleProps
  const titleProps =
    typeof title === 'string' ? { title, titleVariant } : { title };

  const bottomBorderProps = showBottomBorder ? borderBottomSx : null;

  return (
    <Box
      component="section"
      paddingTop={(boxProps.paddingTop ?? title) ? undefined : 2}
      sx={mixinSx(dialogSectionSx, bottomBorderProps, sectionSx)}
      id={anchor}
    >
      {title && (
        <DialogSectionHeader {...titleProps} actions={actions} sx={titleSx} />
      )}
      <Box paddingX={2} {...boxProps}>
        {children}
      </Box>
    </Box>
  );
}

export type DialogSectionHeaderProps = Omit<BoxProps, 'title'> &
  TitleProps & {
    actions?: React.ReactNode;
  };

export function DialogSectionHeader({
  title,
  titleVariant = 'h2',
  actions,
  ...boxProps
}: DialogSectionHeaderProps) {
  return (
    <Box
      padding={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...boxProps}
    >
      {typeof title === 'string' ? (
        <Typography variant={titleVariant} sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      ) : (
        title
      )}
      {actions}
    </Box>
  );
}

function DefaultErrorFallback(props: FallbackProps) {
  return (
    <Stack
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        paddingY: 12,
        paddingX: 6,
        maxWidth: 660,
        margin: 'auto',
      }}
    >
      <UnexpectedErrorState />
    </Stack>
  );
}

const DialogContext = React.createContext<{
  ErrorFallback: React.ComponentType<FallbackProps>;
}>({
  ErrorFallback: DefaultErrorFallback,
});

export function DialogContextProvider({
  ErrorFallback,
  children,
}: {
  ErrorFallback: React.ComponentType<FallbackProps>;
  children: React.ReactNode;
}) {
  return (
    <DialogContext.Provider value={{ ErrorFallback }}>
      {children}
    </DialogContext.Provider>
  );
}

function useDialogContext() {
  return useContext(DialogContext);
}
