import Box from '@mui/material/Box';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';

export default function LoadingPage() {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingY={6}
    >
      <CircularProgress />
    </Box>
  );
}
