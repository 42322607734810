import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { GQActiveWatershedEmployeeFieldsFragment } from '@watershed/app-admin/generated/graphql-operations';

interface AdminContextData {
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment;

  // There are two possible side drawers in Admin, accessible on all Org pages.
  openSideDrawer: 'inbox' | 'context' | null;
  setOpenSideDrawer: Dispatch<SetStateAction<'inbox' | 'context' | null>>;
}

const AdminContext = React.createContext<AdminContextData>({
  activeWatershedEmployee: {
    id: 'id',
    name: 'name',
    handle: 'handle',
    email: 'email',
    user: {
      id: 'id',
      createdAt: new Date(),
      primaryLoginOrgId: 'orgId',
      accessibleOrgs: [],
    },
    orgPointsOfContact: [],
  },
  openSideDrawer: null,
  setOpenSideDrawer: () => {},
});

export function AdminContextProvider({
  activeWatershedEmployee,
  children,
}: React.PropsWithChildren<{
  activeWatershedEmployee: GQActiveWatershedEmployeeFieldsFragment;
}>) {
  const [openSideDrawer, setOpenSideDrawer] = useState<
    'inbox' | 'context' | null
  >(null);

  return (
    <AdminContext.Provider
      value={{
        activeWatershedEmployee,
        openSideDrawer,
        setOpenSideDrawer,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export function useAdminContext() {
  return useContext(AdminContext);
}
