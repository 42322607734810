import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const StatusStableNewIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 12 12" fill="currentColor" {...props}>
      <rect width={10} height={10} x={1} y={1} rx={5} />
    </Icon>
  )
);
export default StatusStableNewIcon;
