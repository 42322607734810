import React, {
  Suspense,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  memo,
  startTransition,
  useRef,
  useEffect,
  useCallback,
} from 'react';

import { unstable_getScrollbarSize as getScrollbarSize } from '@mui/utils';

import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from '@tanstack/react-query';

import { ScopeProvider } from 'jotai-scope';
import { AtomsHydrator } from '@watershed/shared-frontend/components/jotai';

import { styled } from '@mui/material/styles';
import { CommandPalettePageContextProvider } from './CommandPaletteContext';
import {
  CommandPaletteHistoryEntry,
  CommandPalettePageContextShape,
  CommandPalettePush,
  CommandPaletteSelection,
} from './types';
import ComandPaletteLoadingFallback from './ComandPaletteLoadingFallback';
import { selectionAtom } from './atoms';
import { useAtomValue } from 'jotai';

interface CommandPaletteProps {
  initialNavigationState: Array<CommandPaletteHistoryEntry<any>>;
}

const NativeDialog = styled('dialog')({});

function useLazyRef<T>(fn: () => T) {
  const ref = React.useRef<T | null>(null);

  if (ref.current === null) {
    ref.current = fn();
  }

  return ref.current;
}

/**
 * @param selectionOverride - A selection override is a selection used in the
 * _current_ session of the command palette. It is ephemeral and will be unset
 * upon closing the command palette, i.e. ending the current session.
 *
 * You can access the overridden value via the `selectionAtom` atom, as long as
 * it's fetched within the CommandPalette subtree
 *
 * You probably want to use this when you want to open the command palette
 * with a specific selection, like when you're opening the command palette
 * from a button or link
 */
export interface CommandPaletteRef {
  toggle: () => void;
  open: () => void;
  openAt: (
    location: CommandPaletteHistoryEntry<any>,
    options?: { selectionOverride?: CommandPaletteSelection }
  ) => void;
  close: () => void;
}

export function buildCommandPalettePageContext(
  navigationStack: Array<CommandPaletteHistoryEntry<any>>,
  setNavigationStack: React.Dispatch<
    React.SetStateAction<Array<CommandPaletteHistoryEntry<any>>>
  >,
  dialogRef?: React.RefObject<HTMLDialogElement>
) {
  const push: CommandPalettePush = (component, props) => {
    startTransition(() => {
      setNavigationStack((navigationStack) => {
        return [...navigationStack, { component, props: props ?? {} }];
      });
    });
  };

  const pop = () => {
    startTransition(() => {
      setNavigationStack((navigationStack) => navigationStack.slice(0, -1));
    });
  };

  const shake = () => {
    if (dialogRef?.current) {
      dialogRef.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (dialogRef.current) {
          dialogRef.current.style.transform = '';
        }
      }, 100);
    }
  };

  const close = () => {
    dialogRef?.current?.close();
  };

  return {
    location: navigationStack,
    push,
    pop,
    shake,
    close,
  } as CommandPalettePageContextShape;
}

export default memo(
  forwardRef(function CommandPalette(
    { initialNavigationState }: CommandPaletteProps,
    ref: React.Ref<CommandPaletteRef>
  ) {
    const [isOpen, setOpen] = useState(false);
    const [scrollbarSize, setScrollbarSize] = useState<null | number>(null);
    const [navigationStack, setNavigationStack] = useState<
      Array<CommandPaletteHistoryEntry<any>>
    >(initialNavigationState);

    const selection = useAtomValue(selectionAtom);
    const [selectionOverride, setSelectionOverride] =
      useState<CommandPaletteSelection | null>(null);

    const dialogRef = useRef<HTMLDialogElement>(null);

    const open = useCallback(() => {
      setNavigationStack(initialNavigationState);
      setScrollbarSize(getScrollbarSize(document));
      setOpen(true);
    }, [initialNavigationState]);
    const close = useCallback(() => {
      dialogRef.current?.close();
      setSelectionOverride(null);
    }, []);

    const openAt = useCallback(
      (
        location: CommandPaletteHistoryEntry<any>,
        options?: { selectionOverride?: CommandPaletteSelection }
      ) => {
        startTransition(() => {
          setNavigationStack([location]);
          setScrollbarSize(getScrollbarSize(document));
          setOpen(true);

          if (options?.selectionOverride) {
            setSelectionOverride(options.selectionOverride);
          }
        });
      },
      []
    );

    const toggle = useCallback(
      () => (dialogRef.current?.open ? close() : open()),
      [close, open]
    );

    useImperativeHandle(ref, () => ({
      toggle,
      open,
      openAt,
      close,
    }));

    // As we want a temporary cache for the lifetime of the palette, we create a
    // new query client and copy all the queries from the ancestor query client.
    const ancestorQueryClient = useQueryClient();
    const queryClient = useLazyRef(() => {
      const ancestorCache = ancestorQueryClient.getQueryCache();
      const ancestoryQueries = ancestorCache.getAll();

      const client = new QueryClient();
      const cache = client.getQueryCache();

      ancestoryQueries.forEach((query) => {
        cache.add(query);
      });

      return client;
    });

    const pageContext = useMemo(
      () =>
        buildCommandPalettePageContext(
          navigationStack,
          setNavigationStack,
          dialogRef
        ),
      [navigationStack]
    );

    const currentLocation = navigationStack[navigationStack.length - 1];

    if (isOpen && !currentLocation) {
      throw new Error(
        'Command Palette cannot be open with an empty navigation stack'
      );
    }

    const PageComponent = currentLocation?.component;
    const pageProps = currentLocation?.props;

    useEffect(() => {
      if (isOpen) {
        dialogRef.current?.showModal();
      }
    }, [isOpen]);

    return (
      <QueryClientProvider client={queryClient}>
        <CommandPalettePageContextProvider value={pageContext}>
          <NativeDialog
            ref={dialogRef}
            onClick={(event) => {
              if (event.target === dialogRef.current) {
                dialogRef.current?.close();
              }
            }}
            onClose={async () => {
              await Promise.allSettled(
                dialogRef.current
                  ?.getAnimations()
                  .map((animation) => animation.finished) ?? []
              );
              // The command palette session is ending, so unset the selection
              // override, which should not be used beyond the current session
              setSelectionOverride(null);
              setNavigationStack(initialNavigationState);
              setOpen(false);
            }}
            sx={{
              'body:has(&[open])': {
                overflow: 'hidden',
                paddingRight: scrollbarSize + 'px',
              },
              // Align to the left edge of the screen to avoid the position jank
              // caused by adding the overflow hidden to the html element
              marginLeft: 'calc(32px + 50vw - min(50vw, 320px))',
              // Align to the top of the screen so that the dialog doesn't jump
              // around when we navigate between pages of different heights.
              marginTop: `calc(15vh + 32px)`,
              padding: 0,
              boxShadow:
                '0px 24px 56px -12px rgba(79, 89, 110, 0.2), 0px 8px 24px -18px rgba(79, 89, 110, 0.24), 0px 0px 24px 2px rgba(79, 89, 110, 0.16)',
              maxWidth: 'calc(640px - 64px)',
              width: 'calc(100% - 64px)',
              overflow: 'hidden',
              transition:
                'opacity 80ms ease-in-out, transform 80ms ease-in-out, display 80ms allow-discrete, overlay 80ms allow-discrete',
              borderRadius: '12px',
              backgroundColor: 'white',
              border: (theme) => `1px solid ${theme.palette.border}`,
              opacity: 0,
              transform: 'scale(1) translateY(16px)',
              '::backdrop': {
                opacity: 0,
                transition: 'opacity 192ms ease',
              },
              '&[open]': {
                opacity: 1,
                transform: 'scale(1) translateY(0)',
                '::backdrop': {
                  opacity: 0.5,
                },
              },
              '@starting-style': {
                '&[open]': {
                  opacity: 0,
                  transform: 'scale(1) translateY(16px)',
                  '::backdrop': {
                    opacity: 0,
                  },
                },
              },
            }}
          >
            {isOpen ? (
              <ScopeProvider atoms={[selectionAtom]}>
                <Suspense fallback={<ComandPaletteLoadingFallback />}>
                  {/*
                    Hydrate the selection atom with the overridden value if it
                    exists. The override is applied within the current scope
                    of the CommandPalette subtree
                  */}
                  <AtomsHydrator
                    initialValues={[
                      [selectionAtom, selectionOverride ?? selection],
                    ]}
                  >
                    <PageComponent
                      {...pageProps}
                      key={navigationStack.length}
                    />
                  </AtomsHydrator>
                </Suspense>
              </ScopeProvider>
            ) : null}
          </NativeDialog>
        </CommandPalettePageContextProvider>
      </QueryClientProvider>
    );
  })
);
