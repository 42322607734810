import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const CircleCheckmarkFillIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm5.235-13.322a1 1 0 0 0-1.47-1.356l-5.294 5.735-2.264-2.264a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.442-.029l6-6.5Z"
        clipRule="evenodd"
      />
    </Icon>
  )
);
export default CircleCheckmarkFillIcon;
