import uniq from 'lodash/uniq';
import {
  PipelineResultWithTagsSchema,
  SHOULD_EXCLUDE_TAG_NAME,
  SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
} from '../TSchema/schemas/footprint/PipelineResultWithTagsSchema';
import { Rule } from './types';

export const GLOBAL_TAG_NAME_QUERY_PARAM = 'tag';
export const GLOBAL_TAG_VERSION_QUERY_PARAM = 'version';

export const SCHEMA_VERSION = '1.0';

export type TagList = Array<
  keyof (typeof PipelineResultWithTagsSchema.schema)['properties']
> &
  Array<string>;

export const ONTOLOGY_TAGS: TagList = [
  'ghg_category_id',
  'category_id',
  'subcategory_id',
];

export const FINANCE_TAGS: TagList = ['asset_id'];

export const WATER_TAGS: TagList = [
  'aqueduct_stable_row_id',
  'aqueduct_version',
];

const COMMON_POLLUTANT_TAGS: TagList = [
  'pollutant_cas_number',
  'eu_pollutant_release_and_transfer_register_pollutant_group',
  'pollutant_substance_of_concern',
  'pollutant_substance_of_concern_hazard_class',
];

export const POLLUTANT_TAGS: TagList = [...COMMON_POLLUTANT_TAGS];

export const SUBSTANCE_INFLOW_TAGS: TagList = [
  ...COMMON_POLLUTANT_TAGS,
  'substance_inflow_medium',
];
export const SUBSTANCE_OUTFLOW_TAGS: TagList = [
  ...COMMON_POLLUTANT_TAGS,
  'substance_outflow_medium',
];
export const MICROPLASTICS_INFLOW_TAGS: TagList = [
  'microplastics_inflow_medium',
];
export const MICROPLASTICS_OUTFLOW_TAGS: TagList = [
  'microplastics_outflow_medium',
];

export const WASTE_TAGS: TagList = [
  'waste_material',
  'waste_treatment',
  'waste_lifecycle',
  'is_waste_hazardous',
  'is_waste_csrd_hazardous',
  'is_waste_radioactive',
];

export const MATERIALS_TAGS: TagList = [
  'material_sustainable_sourcing_scheme',
  'material_application_of_cascading_principle',
  'material_is_biological',
  'material_is_secondary',
  'material_is_recyclable',
];

export const ALL_NON_CARBON_RESULT_TAGS: TagList = uniq([
  ...POLLUTANT_TAGS,
  ...WATER_TAGS,
  ...WASTE_TAGS,
  ...MATERIALS_TAGS,
  ...SUBSTANCE_INFLOW_TAGS,
  ...SUBSTANCE_OUTFLOW_TAGS,
  ...MICROPLASTICS_INFLOW_TAGS,
  ...MICROPLASTICS_OUTFLOW_TAGS,
]);

export const EMISSIONS_CATEGORY_1 = 'emissions_category_1';
export const EMISSIONS_CATEGORY_2 = 'emissions_category_2';
export const EMISSIONS_CATEGORY_3 = 'emissions_category_3';

export const CARBON_TAGS: TagList = [
  EMISSIONS_CATEGORY_1,
  EMISSIONS_CATEGORY_2,
  EMISSIONS_CATEGORY_3,
  'fuel_combustion_kind',
];

export const ENERGY_TAGS: TagList = [
  'fuel_kind',
  'energy_kind',
  'clean_power_contractual_instrument',
];

export const COMMON_TAGS: TagList = [
  'building_name',
  'description',
  'measurement_fidelity',
  'product',
];

export const CUSTOM_EFS_TAGS: TagList = ['unit'];

// We keep an allowlist of global tags because we still want to
// approve any additions here rather than allow creating news ones by
// anyone in the editor.
export const ALL_DEFAULT_TAGS: TagList = [
  ...COMMON_TAGS,
  ...ONTOLOGY_TAGS,
  ...CARBON_TAGS,
  ...ENERGY_TAGS,
  ...FINANCE_TAGS,
  ...ALL_NON_CARBON_RESULT_TAGS,
  ...CUSTOM_EFS_TAGS,
];

// Footprint tagging aims to remove these columns from a pipeline result
// altogether, so want to prevent them from being inputs.
export const PIPELINE_RESULT_COLUMNS_TO_IGNORE: TagList = ONTOLOGY_TAGS;

// If tags are provided for these tag names, the tag values
// will override the existing values in the pipeline result if there.
// We also won't treat them as "custom," so they won't show up
// in drilldown unless it's explicitly a column on the drilldown
// footprint contribution schema.
export const KNOWN_TAG_NAMES: TagList = [
  ...ALL_DEFAULT_TAGS,
  'footprint_kind',
  SHOULD_EXCLUDE_TAG_NAME,
];

// We warn if the number of distsinct values of a given tag
// in the pipeline result (for preview) or the footprint (in snapshot creation)
// is larger than this number.
export const WARN_NUM_DISTINCT_VALUES_PER_TAG: number = 500;

// Footprint waste selection constants
export const WASTE_SHOULD_EXCLUDE_RULE: Rule = {
  if: {
    columnName: 'bart_activity_type_name',
    operator: '=',
    value: 'waste',
  },
  then: {
    outputValue: SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
  },
};

export const BUILDING_WASTE_SHOULD_EXCLUDE_RULE: Rule = {
  if: {
    columnName: 'bart_activity_type_name',
    operator: '=',
    value: 'building_waste',
  },
  then: {
    outputValue: SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
  },
};

export const EMPLOYEE_WASTE_SHOULD_EXCLUDE_RULE: Rule = {
  if: {
    columnName: 'bart_activity_type_name',
    operator: '=',
    value: 'employees',
  },
  then: {
    rules: [
      {
        if: {
          columnName: 'emissions_model_process_kind',
          operator: '=',
          value: 'Waste',
        },
        then: {
          outputValue: SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
        },
      },
    ],
  },
};
