import { GQWatershedPlanLegacy } from '@watershed/shared-universal/generated/graphql-schema-types';

export default function formatOrgName(org: {
  name: string;
  demoOrg: boolean;
  testOrg: boolean;
  watershedPlanLegacy?: GQWatershedPlanLegacy;
}): string {
  const suffixes = [];
  if (org.testOrg) {
    suffixes.push('test');
  }
  if (org.demoOrg) {
    suffixes.push('demo');
  }
  if (org.watershedPlanLegacy === GQWatershedPlanLegacy.NoPlan) {
    suffixes.push('supplier');
  }
  if (suffixes.length === 0) return org.name;
  return `${org.name} ${suffixes.map((s) => `[${s}]`).join('')}`;
}
