import { useLoginAsUserMutation } from '@watershed/app-admin/generated/urql';

import { loginAsApiEndpointUrl } from '@watershed/shared-universal/adminRoutes';
import must from '@watershed/shared-universal/utils/must';
import { switchPinnedOrganizationId } from '@watershed/shared-frontend/utils/pinnedOrganizationId';
import Button, { ButtonProps } from '@watershed/ui-core/components/Button';
import * as Sentry from '@watershed/shared-universal/utils/sentry';
import { gql } from 'graphql-tag';
import { useAdminContext } from './AdminContext';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
  PreviewDeployName,
  previewDeployNames,
} from '@watershed/shared-universal/utils/helpers';
import DropdownMenu from '@watershed/ui-core/components/DropdownMenu';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';

gql`
  mutation LoginAsUser($input: LoginAsUserInput!) {
    loginAsUser(input: $input) {
      token
      isSuccessful
    }
  }
`;

export function LoginAsButtons({
  targetOrgId,
  userId,
  redirect,
  size = 'small',
  hidePreviewDeployOptions = false,
  ...props
}: {
  targetOrgId: string;
  userId?: string;
  redirect?: string;
  hidePreviewDeployOptions?: boolean;
  size?: ButtonProps['size'];
} & ButtonProps) {
  const { activeWatershedEmployee } = useAdminContext();
  const accessibleOrgIds = activeWatershedEmployee.user.accessibleOrgs.map(
    (org) => org.id
  );
  const targetLoginUserIsMyself = activeWatershedEmployee.user.id === userId;

  // if i have direct access to the org, and the target login as user is myself or unset
  // show the login as myself button instead of login as button
  const shouldShowLoginAsMyself =
    (targetLoginUserIsMyself || !userId) &&
    accessibleOrgIds.includes(targetOrgId);

  return shouldShowLoginAsMyself ? (
    <LoginAsMyselfButton
      targetOrgId={targetOrgId}
      wsEmployeeUserName={activeWatershedEmployee.name}
      hidePreviewDeployOptions={hidePreviewDeployOptions}
      size={size}
      redirect={redirect}
      {...props}
    >
      {props.children}
    </LoginAsMyselfButton>
  ) : (
    <LoginAsButton
      targetOrgId={targetOrgId}
      hidePreviewDeployOptions={hidePreviewDeployOptions}
      userId={userId}
      redirect={redirect}
      size={size}
      {...props}
    >
      {props.children}
    </LoginAsButton>
  );
}

const LoginAsButtonBase: React.FC<
  {
    onClickLogin: (previewDeployName?: PreviewDeployName) => void;
    children: React.ReactNode;
    hidePreviewDeployOptions: boolean;
  } & ButtonProps
> = ({ onClickLogin, children, hidePreviewDeployOptions, ...props }) => {
  const shouldRenderPreviewDeployOptions =
    getCurrentDevEnv() !== 'local-dev' && !hidePreviewDeployOptions;
  return (
    <ButtonGroup size={props.size}>
      <Button
        {...props}
        onClick={(e) => {
          // to stop opening the org page when clicking the button
          e.stopPropagation();
          onClickLogin(undefined);
        }}
      >
        {children}
      </Button>
      {shouldRenderPreviewDeployOptions && (
        <DropdownMenu
          buttonProps={props}
          items={[
            {
              id: 'default',
              label: 'Default',
              // Explicitly set no preview deploy
              onSelect: () => onClickLogin(undefined),
            },
            ...previewDeployNames.map((name) => ({
              id: name,
              label: name,
              onSelect: () => onClickLogin(name),
            })),
          ]}
        />
      )}
    </ButtonGroup>
  );
};

function LoginAsButton({
  targetOrgId,
  hidePreviewDeployOptions,
  userId,
  redirect,
  size = 'small',
  ...props
}: {
  targetOrgId: string;
  hidePreviewDeployOptions: boolean;
  userId?: string;
  redirect?: string;
  size?: ButtonProps['size'];
} & ButtonProps) {
  const [, loginAsUser] = useLoginAsUserMutation();
  const handleClick = async (previewDeployName?: PreviewDeployName) => {
    const result = await loginAsUser({
      input: { targetOrgId, targetUserId: userId },
    });
    if (result.error || !result.data) {
      alert('Error logging in as');
    }
    const data = must(result.data);
    const token = data.loginAsUser.token;
    if (data.loginAsUser.token) {
      const url = loginAsApiEndpointUrl({
        orgId: targetOrgId,
        userId,
        redirect,
        token: token ?? undefined,
        previewDeployName,
      });
      window.open(url, '_blank', 'noopener');
    } else {
      Sentry.captureException(
        new Error(
          "Can't generate login as token (DRI: @enterprise-foundations-oncall)"
        ),
        { level: 'warning' }
      );
      alert(
        'Error generating login as token. Something is broken, ask in #errors'
      );
    }
  };

  return (
    <LoginAsButtonBase
      onClickLogin={handleClick}
      size={size}
      hidePreviewDeployOptions={hidePreviewDeployOptions}
    >
      [As Other] {props.children}
    </LoginAsButtonBase>
  );
}

function LoginAsMyselfButton({
  targetOrgId,
  redirect,
  wsEmployeeUserName,
  hidePreviewDeployOptions,
  ...props
}: {
  targetOrgId: string;
  wsEmployeeUserName: string;
  hidePreviewDeployOptions: boolean;
  redirect?: string;
  userId?: string;
} & ButtonProps) {
  const handleClick = (previewDeployName?: PreviewDeployName) => {
    switchPinnedOrganizationId({
      orgId: targetOrgId,
      redirect,
      reloadInOrgHomeIfLoggedOut: true,
      previewDeployName,
    });
  };

  return (
    <LoginAsButtonBase
      {...props}
      onClickLogin={handleClick}
      hidePreviewDeployOptions={hidePreviewDeployOptions}
    >
      {props.children} as {wsEmployeeUserName}
    </LoginAsButtonBase>
  );
}
