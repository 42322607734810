import { GQBiQueryFilter } from '@watershed/shared-universal/generated/graphql-schema-types';
import { BiChartKind } from '@watershed/constants/bi';

import { BiNormalizedMeasure, BiQueryComponentHashKey } from './types';
import { YMInterval } from '../utils/YearMonth';

/**
 * TODO split this up into a union type of BiActivityQueryParams
 * and BiSnapshotQueryParams that extend BiCommonQueryParams
 */
export type BiQueryParams = {
  measures?: Array<BiNormalizedMeasure> | null;
  filters?: Array<GQBiQueryFilter> | null;
  dimensions?: Array<string> | null;
  timeIntervals?: Array<YMInterval> | null;
  view?: string | null;
  chartKind?: BiChartKind | null;
  // footprint specific
  footprintKind?: string | null;
  searchTerm?: string | null;
};

export function makeBiQueryHashParams(
  params: BiQueryParams
): Record<string, string | undefined | null> {
  return {
    [BiQueryComponentHashKey.footprintKind]: getStringifiedOrNull(
      params.footprintKind
    ),
    [BiQueryComponentHashKey.view]: getStringifiedOrNull(params.view),
    [BiQueryComponentHashKey.chartKind]: getStringifiedOrNull(params.chartKind),
    [BiQueryComponentHashKey.filters]: getStringifiedOrNull(params.filters),
    [BiQueryComponentHashKey.dimensions]: getStringifiedOrNull(
      params.dimensions
    ),
    [BiQueryComponentHashKey.measures]: getStringifiedOrNull(params.measures),
    [BiQueryComponentHashKey.intervals]: getStringifiedOrNull(
      params.timeIntervals
    ),
  };
}

export function getStringifiedOrNull(value: unknown): string | null {
  return value != null ? JSON.stringify(value) : null;
}
