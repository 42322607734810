import omit from 'lodash/omit';
import { t } from '@lingui/core/macro';
import { z } from 'zod';
import {
  GQBiQueryContextInput,
  GQBuildingUtilityType,
  GQExternalReportType,
  GQFilterFieldWatershed,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { CanonicalDatasetKind } from '@watershed/constants/datasets';
import DrilldownFilters from './utils/DrilldownFilters';
import { makeBetterEnum } from './utils/betterEnum';
import getPathWithQuery from './utils/getPathWithQuery';
import { YMInterval } from './utils/YearMonth';
import { ParsedUrlQueryInput } from 'querystring';
import {
  DRILLDOWN_DRAWER_PARAM,
  FOOTPRINT_CONFIGURATION_DRAWER_TABS,
  FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM,
  FootprintConfigurationDrawerTab,
} from './footprint/constants';
import {
  EF_ACTIVE_TAB_QUERY_PARAM,
  EF_CUSTOMIZATION_TYPE_QUERY_PARAM,
  EF_TAB_LIBRARY,
  EmissionsFactorsTab,
} from './methodologyCustomization/methodologyCustomizationConstants';
import {
  LearningHubEntryPoint,
  LearningHubArticle,
  learningHubBaseUrlForViewMode,
  learningHubHashForArticle,
  LearningHubViewMode,
} from './learningHubRoutes';
import {
  DEDUPLICATION_SOURCE_ONE_FIELD_PARAM,
  DEDUPLICATION_SOURCE_ONE_VALUE_PARAM,
  DEDUPLICATION_SOURCE_TWO_FIELD_PARAM,
  DEDUPLICATION_SOURCE_TWO_VALUE_PARAM,
} from './footprintProductRules/constants';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import {
  BiQueryParams,
  getStringifiedOrNull,
  makeBiQueryHashParams,
} from './bi/hashUtils';
import { BiSingleMeasureSelector } from './bi/types';
import { ActivityDataTabType } from '@watershed/shared-universal/activityDatasets/types';
import { DemoPlatformStep } from './demoPlatformSchemas/DemoPlatformConstants';
import { dashboardUrl } from '@watershed/shared-universal/utils/helpers';
import { encodeSnapshotIdsQueryParam } from './parseSnapshotIdsQueryParams';
export type NextjsLinkHref = {
  pathname: string;
  query?: ParsedUrlQueryInput;
};

// Production marketing website URLs
export const PRIVACY_POLICY_URL = 'https://watershed.com/privacy';
export const DATA_PORTAL_TERMS_URL = 'https://watershed.com/data-portal-terms';

// Support email
export const SUPPORT_EMAIL_ADDRESS = 'support@watershed.com';
export const SUPPORT_EMAIL_LINK = `mailto:${SUPPORT_EMAIL_ADDRESS}`;
export const INTEGRATIONS_SUPPORT_EMAIL_ADDRESS =
  'integrations-support@watershed.com';
export const INTEGRATIONS_SUPPORT_EMAIL_LINK = `mailto:${INTEGRATIONS_SUPPORT_EMAIL_ADDRESS}`;

export const WIN_FINANCE_PREFIX = '/finance';

export const DATA_ISSUE_ID_QUERY_PARAM = 'issue';

export const ACTIVE_ORGANIZATION_ID_QUERY_PARAM = 'activeOrganizationId';

// Optional query params supported by the login page
export const LOGIN_QUERY_PARAMS = [
  'email',
  'customerId',
  'customerRelation',
  'watershedGeneric',
  'recipientCompanyId',
] as const;
export type LoginQueryParams = {
  [T in (typeof LOGIN_QUERY_PARAMS)[number]]?: string;
};

export enum ImportStage {
  TemplateSelect = 'templateSelect',
  Import = 'import',
  Review = 'review',
  ReviewUtilities = 'reviewUtilities',
}

// We make an String enum for RunOcr because it's used as a query parameter, which should be a string
export enum RunOcr {
  True = 'true',
  False = 'false',
}

export const LOGIN_CUSTOMER_RELATION_OPTIONS = {
  SUPPLIER: 'supplier',
  PORTCO: 'portco',
} as const;

export type LoginCustomerRelationOption =
  (typeof LOGIN_CUSTOMER_RELATION_OPTIONS)[keyof typeof LOGIN_CUSTOMER_RELATION_OPTIONS];

export function routeForLogin(
  params: { redirect?: string } & LoginQueryParams = {}
): string {
  return getPathWithQuery('/login', params);
}

export function routeForOrgChooser(
  params: { redirect?: string } & LoginQueryParams = {}
): string {
  return getPathWithQuery('/choose-org', params);
}

export function routeForSignup(params: {
  token: string;
  email: string;
}): string {
  return getPathWithQuery('/signup', params);
}

export function routeForHome(): string {
  return '/home';
}

export function routeForBenchmarks(query?: ParsedUrlQueryInput): string {
  return getPathWithQuery('/benchmarks', query);
}

export function routeForOrgStructure({
  backTo,
  orgStructureVersionId,
  orgUnitTypeId,
}: {
  backTo?: BackTo;
  orgStructureVersionId?: string;
  orgUnitTypeId?: string;
} = {}): string {
  return getPathWithQuery(
    `/org-structure${orgStructureVersionId ? `/${orgStructureVersionId}` : ''}${
      orgStructureVersionId && orgUnitTypeId ? `/${orgUnitTypeId}` : ''
    }`,
    { backTo }
  );
}

export function routeForOrgStructureVersionExplore({
  orgStructureVersionId,
}: {
  orgStructureVersionId: string;
}): string {
  return `/org-structure/${orgStructureVersionId}/explore`;
}

export function routeForOrgStructureVersionImport({
  orgStructureVersionId,
  importStage,
}: {
  orgStructureVersionId: string;
  importStage?: ImportStage;
}): string {
  return getPathWithQuery(`/org-structure/${orgStructureVersionId}/import`, {
    importStage,
  });
}

export function routeForCsrdDataRequirements(): string {
  return '/measure/csrd-data-requirements';
}

export function routeForSsoConfig(): string {
  return '/sso-config';
}

export function routeForAuditLogs(): string {
  return '/logs';
}

export function routeForReport(id: string): string {
  return `/reports/${id}`;
}

export function routeForReportQuestion(
  reportId: string,
  questionId: string
): string {
  return `/reports/edit/${reportId}/question/${questionId}`;
}

export function routeForReportQuestionEmbeddedDrilldown({
  reportId,
  questionId,
}: {
  reportId: string;
  questionId: string;
}): string {
  return `/reports/edit/${reportId}/question/${questionId}/drilldown`;
}

/**
 * Edit a report from a ~2023 report config.
 */
export function routeForEditReport(id: string, section?: string): string {
  const basePath = `/reports/edit/${id}`;
  if (!section || id === section) {
    return basePath;
  }
  return `${basePath}/${Buffer.from(section)
    .toString('base64')
    .replace('==', '')}`;
}

export function urlForEditReport(
  id: string,
  linkToTableView = false,
  filterOnCurrentUserAssignments = false
): string {
  const basePath = routeForEditReport(id);
  if (!linkToTableView) return dashboardUrl(basePath);

  const tablePath = `${basePath}?tab=table`;
  if (!filterOnCurrentUserAssignments) return dashboardUrl(tablePath);

  return dashboardUrl(`${tablePath}&tableFilters[assignedTo]=@me`);
}

export function routeForMethodologyInProduct(): string {
  return '/measure/methodology';
}

export function routeForEmissionsModel(stableId: string): string {
  return `/measure/methodology/emissions-models/${stableId}`;
}

export function routeForReferenceDataInProduct(versionId: string): string {
  return `/measure/methodology/reference-data/${versionId}`;
}

export function routeForMethodologies(): string {
  return '/calculate/methodologies';
}

export function routeForReferenceData(): string {
  return '/calculate/reference-data';
}

export function routeForEmissionsModels(): string {
  return '/calculate/emissions-models';
}

/**
 * @deprecated - Please use routeForFootprintBiDrilldown instead! We've actively
 * killed all uses of this except for one reporting context that we still need
 * to untangle before we can fully remove this route.
 *
 * This produces a route to the old drilldown page, which already
 * automatically redirects to the new drilldown page anyway.
 *
 * TODO: REP-7219 (remove when fixing this)
 */
export function deprecatedDoNotUseRouteForFootprintDrilldown({
  filters,
  groupBy,
  footprintSnapshotId,
  footprintKind,
}: {
  filters?: DrilldownFilters;
  // string option below is to support custom tags
  groupBy?: Array<GQFilterFieldWatershed | string>;
  footprintSnapshotId?: string;
  footprintKind?: string;
} = {}): string {
  let params = new URLSearchParams();
  if (filters) {
    params = new URLSearchParams([
      ...params.entries(),
      ...filters.toUrlSearchParams().entries(),
    ]);
  }

  if (groupBy && groupBy.length > 0) {
    // eslint-disable-next-line @watershed/no-join-commas
    params.append('groupBy', groupBy.join(','));
  }
  if (footprintSnapshotId) {
    params.append(FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM, footprintSnapshotId);
  }
  if (footprintKind) {
    params.append('footprintKind', footprintKind);
  }
  return getPathWithQuery('/footprints/details', params);
}

export function routeForFootprintBiDrilldown(
  params: {
    footprintSnapshotId?: string;
    footprintSnapshotIds?: Array<string>;
    drawer?: FootprintConfigurationDrawerTab;
  } = {},
  /**
   * Use makeBiQueryHashParams to generate the appropriate params
   */
  fragment: Record<string, any> | string = {},
  useFinancePrefix: boolean = false
): string {
  const { footprintSnapshotId, footprintSnapshotIds, ...restParams } = params;
  const finalParams = {
    ...restParams,
    footprintSnapshotId,
    footprintSnapshotIds: footprintSnapshotId
      ? encodeSnapshotIdsQueryParam([
          ...(footprintSnapshotIds || []),
          footprintSnapshotId,
        ])
      : encodeSnapshotIdsQueryParam(footprintSnapshotIds || []),
  };

  return getPathWithQuery(
    useFinancePrefix
      ? `${WIN_FINANCE_PREFIX}/data-explorer`
      : `/footprints/drilldown`,
    finalParams,
    fragment
  );
}

export function routeForSavedViewsLandingPage(): string {
  return '/footprints/drilldown/overview';
}

/**
 * This loads the index page that has links to all of the footprint exports (aka
 * audit bundles).
 */
export function routeForFootprintExports(): string {
  return `/footprints/exports`;
}

/**
 * This loads a detail page for one footprint export. The id passed is a
 * footprint audit bundle id, since the export is backed by an "audit bundle"
 * model in the database (old name for it).
 */
export function routeForFootprintExportDetail(fabId: string): string {
  return `${routeForFootprintExports()}/${fabId}`;
}

export function routeForFootprintExportSchema(): string {
  return `${routeForFootprintExports()}/schema`;
}

export function routeForRenewableEnergy(): string {
  return '/marketplace/clean-power';
}

export function routeForFootprintProductLevelEmissions(
  type?: 'products' | 'materials'
): string {
  if (type) {
    return getPathWithQuery(`/footprints/models/`, { type });
  }
  return '/footprints/models';
}

function urlSearchParamsForEmissionsSlice({
  footprintSnapshotId,
  footprintKind,
  viewDraft,
  orgRawMaterial,
  dateInterval,
}: {
  footprintSnapshotId: string | null;
  footprintKind: string;
  viewDraft: boolean;
  orgRawMaterial?: string | null;
  dateInterval?: YMInterval;
}): URLSearchParams {
  const params: { [key: string]: string } = { footprintKind };
  if (viewDraft) params.viewDraft = 'true';
  if (footprintSnapshotId) params.footprintSnapshotId = footprintSnapshotId;
  if (orgRawMaterial) params.orgRawMaterial = orgRawMaterial;
  if (dateInterval) params.dateRange = dateInterval.toURLString();
  return new URLSearchParams(params);
}

export function routeForProductEmissionsSlice({
  productId,
  footprintKind,
  footprintSnapshotId = null,
  viewDraft = false,
  dateInterval,
}: {
  productId: string;
  footprintKind: string;
  footprintSnapshotId: string | null;
  viewDraft?: boolean;
  dateInterval?: YMInterval;
}): string {
  const params = urlSearchParamsForEmissionsSlice({
    footprintSnapshotId,
    footprintKind,
    viewDraft,
    dateInterval,
  });
  return getPathWithQuery(
    `/footprints/models/product/${encodeURIComponent(productId)}`,
    params
  );
}

export function routeForMaterialEmissionsSlice({
  orgMaterial,
  orgRawMaterial,
  footprintKind,
  footprintSnapshotId = null,
  viewDraft = false,
  dateInterval,
}: {
  orgMaterial: string;
  orgRawMaterial: string | null;
  footprintKind: string;
  footprintSnapshotId?: string | null;
  viewDraft?: boolean;
  dateInterval?: YMInterval;
}): string {
  const params = urlSearchParamsForEmissionsSlice({
    footprintSnapshotId,
    footprintKind,
    viewDraft,
    orgRawMaterial,
    dateInterval,
  });
  return getPathWithQuery(
    `/footprints/models/material/${encodeURIComponent(orgMaterial)}`,
    params
  );
}

/**
 * Routes you to edit/create a footprint, with the last selected step you were
 * on from the last time you were editing it. Lands you on the footprints list
 * with this footprint highlighted as the footprint generates.
 */
export function routeForFootprintAssembly({
  footprintId,
  configStep,
}: {
  footprintId: string;
  configStep?: number;
}): string {
  const routeToPushOnSubmit = routeForFootprintList({
    footprintIdToHighlight: footprintId,
  });
  return getPathWithQuery(`/footprints/${footprintId}/configuration/assembly`, {
    to: routeToPushOnSubmit,
    'config-step': configStep,
  });
}

/**
 * Route for the main list of all footprints, where you can configure and manage
 * each. Sometimes, you'll want to highlight a specific footprint, like for when
 * you start generating a new version and return to the list as it's generating.
 * Use the `footprintIdToHighlight` parameter to offer a brief visual indicator.
 */
export function routeForFootprintList({
  footprintIdToHighlight,
}: { footprintIdToHighlight?: string | null } = {}): string {
  return isNotNullish(footprintIdToHighlight)
    ? `/footprints?highlightedFootprintId=${footprintIdToHighlight}`
    : '/footprints';
}

interface DeduplicationSourceDefaults {
  field: string;
  value: string;
}

export function routeForFootprintDeduplication({
  stableId,
  page,
  ruleDefaults,
}: {
  stableId?: string;
  page?: 'new';
  ruleDefaults?: {
    sourceOne?: Array<DeduplicationSourceDefaults>;
    sourceTwo?: Array<DeduplicationSourceDefaults>;
  };
} = {}): string {
  const params = new URLSearchParams();
  if (ruleDefaults?.sourceOne) {
    ruleDefaults.sourceOne.forEach(({ field, value }) => {
      params.append(DEDUPLICATION_SOURCE_ONE_FIELD_PARAM, field);
      params.append(DEDUPLICATION_SOURCE_ONE_VALUE_PARAM, value);
    });
  }
  if (ruleDefaults?.sourceTwo) {
    ruleDefaults.sourceTwo.forEach(({ field, value }) => {
      params.append(DEDUPLICATION_SOURCE_TWO_FIELD_PARAM, field);
      params.append(DEDUPLICATION_SOURCE_TWO_VALUE_PARAM, value);
    });
  }

  const base = '/footprints/configuration/deduplication';
  const segment = stableId ?? page;

  return getPathWithQuery(segment ? `${base}/${segment}` : base, params);
}

export function routeForFootprintDeduplicationPermalink(
  footprintSnapshotExclusionRuleId: string
): string {
  return `/footprints/configuration/deduplication/permalink/${footprintSnapshotExclusionRuleId}`;
}

export function routeForFootprintRecategorization({
  stableId,
  page,
}: {
  stableId?: string;
  page?: 'new';
} = {}): string {
  const base = '/footprints/configuration/categories';
  const segment = stableId ?? page;
  return segment ? `${base}/${segment}` : base;
}

export function routeForEngagementTaskConfigure({
  state,
  engagementTaskConfigId,
  redirect,
}: {
  state: 'edit' | 'preview' | 'list';
  engagementTaskConfigId: string;
  redirect?: string | null;
}): string {
  const basePath = routeForFootprintSuppliers({
    activeTab: 'config',
    configTab: 'tasks',
  });

  let queryParam = '';
  if (redirect) {
    queryParam = `?redirect=${redirect}`;
  }

  return `${basePath}/${engagementTaskConfigId}/${state}${queryParam}`;
}

export function routeForFootprintSuppliers({
  activeTab = 'overview',
  configTab = 'overview',
  search,
}: { activeTab?: string; configTab?: string; search?: string } = {}): string {
  let path = `/footprints/suppliers/${activeTab}`;
  if (activeTab === 'config') {
    path += `/${configTab}`;
  }

  return getPathWithQuery(path, { search });
}

export function routeForFootprintSuppliersVarious({
  supplierViewId,
}: {
  supplierViewId: string | null;
}): string {
  return getPathWithQuery(`/footprints/suppliers/various`, {
    supplierView: supplierViewId ?? undefined,
  });
}

export function routeForFootprintSupplierDetails(
  companyId: string,
  query: {
    createTaskDialogOpen?: boolean;
    supplierViewId: string | null;
  } = {
    // TODO(SUP-4974): make supplierViewId required
    supplierViewId: null,
  }
): string {
  return getPathWithQuery(`/footprints/suppliers/company/${companyId}`, {
    createTaskDialogOpen: query.createTaskDialogOpen ? 'true' : undefined,
    supplierView: query.supplierViewId ?? undefined,
  });
}

export function routeForFootprintSupplierDetailsDataHistory(
  companyId: string,
  params: { disclosureId?: string }
): string {
  return getPathWithQuery(
    `/footprints/suppliers/company/${companyId}/history`,
    {
      disclosureId: params.disclosureId ?? undefined,
    }
  );
}

export function routeForFootprintSupplierDetailsEmissionsFactors(
  companyId: string
): string {
  return `/footprints/suppliers/company/${companyId}/emissions-factors`;
}

export function routeForFootprintSupplierDetailsByVendorName(
  vendorName: string,
  params: {
    supplierViewId: string | null;
  } = {
    // TODO(SUP-4974): make supplierViewId required
    supplierViewId: null,
  }
): string {
  return getPathWithQuery(`/footprints/suppliers/vendor/${vendorName}`, {
    supplierView: params.supplierViewId ?? undefined,
  });
}

export function routeForTaskAssignmentStatus({
  taskBatchId,
  isFinance,
}: {
  taskBatchId: string;
  isFinance: boolean;
}): string {
  const path = isFinance
    ? routeForFinanceTasks()
    : routeForFootprintSuppliers({
        activeTab: 'tasks',
      });
  return getPathWithQuery(path, {
    bulkDeployStep: 'copyLinks',
    taskBatchId,
  });
}

/**
 * Shows an individual page for the footprint with details and links to
 * drilldown.
 *
 * **Tip:** if you need a footprint id to pass in here and only have a
 * `footprintSnapshotId`, you can query for `footprint.id` via
 * `footprintSnapshotInDashboard`'s `footprint` field.
 */
export function routeForFootprintDetail({
  footprintId,
  footprintSnapshotId,
  openFootprintConfigurationDrawer,
}: {
  footprintId: string;
  footprintSnapshotId?: string;
  openFootprintConfigurationDrawer?: boolean;
}): string {
  const params = new URLSearchParams();
  if (footprintSnapshotId !== undefined) {
    params.append(FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM, footprintSnapshotId);
  }
  if (openFootprintConfigurationDrawer) {
    params.append(
      DRILLDOWN_DRAWER_PARAM,
      FOOTPRINT_CONFIGURATION_DRAWER_TABS.general
    );
  }

  return getPathWithQuery(`/footprints/${footprintId}`, params);
}

export function routeForActivityDataDataLineage({
  measureSelector,
  queryContext,
}: {
  measureSelector: BiSingleMeasureSelector;
  queryContext: GQBiQueryContextInput;
}): string {
  const hash = {
    ...makeBiQueryHashParams({
      measures: [
        {
          baseMeasure: measureSelector.measure,
          normalizingQuery: null,
        },
      ],
      filters: measureSelector.filters ?? null,
      timeIntervals: [measureSelector.timeInterval],
    }),
  };
  return getPathWithQuery(
    '/measure/activity-data/lineage',
    {
      queryContext: getStringifiedOrNull(queryContext),
    },
    hash
  );
}

export function routeForFootprintDrilldownCalculations({
  footprintSnapshotId,
  footprintKind,
  measureSelector,
  measureTotal,
  breadcrumb,
}: {
  footprintSnapshotId: string;
  footprintKind: string;
  measureSelector: BiSingleMeasureSelector;
  measureTotal?: number;
  breadcrumb?: {
    title: string;
    path: string;
  };
}): string {
  const hash = {
    ...makeBiQueryHashParams({
      measures: [
        {
          baseMeasure: measureSelector.measure,
          normalizingQuery: null,
        },
      ],
      filters: measureSelector.filters ?? null,
      timeIntervals: [measureSelector.timeInterval],
    }),
  };
  const params = new URLSearchParams();
  params.set('footprintSnapshotId', footprintSnapshotId);
  params.set('footprintKind', footprintKind);
  if (breadcrumb) {
    params.set('breadcrumbText', breadcrumb.title);
    params.set('breadcrumbPath', breadcrumb.path);
  }
  isNotNullish(measureTotal) &&
    params.set('measureTotal', measureTotal.toString());
  return getPathWithQuery('/footprints/drilldown/calculations', params, hash);
}

export const FootprintOverviewFragmentId = makeBetterEnum(
  'overview_top_suppliers'
);
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type FootprintOverviewFragmentId =
  keyof typeof FootprintOverviewFragmentId;

export function routeForFootprintOverview({
  fragmentId,
  footprintSnapshotId,
}: {
  fragmentId?: FootprintOverviewFragmentId;
  footprintSnapshotId?: string;
} = {}): string {
  const params = new URLSearchParams();
  if (footprintSnapshotId) {
    params.append(FOOTPRINT_SNAPSHOT_DRILLDOWN_PARAM, footprintSnapshotId);
  }
  return getPathWithQuery(`/footprints/overview`, params, fragmentId);
}

export function routeForFormalReporting({
  page,
}: {
  page?: 'overview' | 'reports' | 'benchmarks';
} = {}): string {
  return `/reports/official/${page ?? 'overview'}`;
}

export function routeForPeerReportsLibrary(
  reportKind?: GQExternalReportType,
  publishingYear?: number
): string {
  const params = new URLSearchParams();
  if (reportKind) params.set('reportKind', reportKind);
  if (publishingYear) params.set('reportingYear', publishingYear.toString());
  return getPathWithQuery('/reports/official/benchmarks', params);
}

/**
 * Can be used on all types of reports - one click quantitative, bespoke TCFD, or config based.
 */
export function routeForFormalReport({
  config,
  reportId,
  editStage,
}: {
  config: { id: string; reportType: string };
  reportId: string;
  editStage?: string;
}): string {
  return routeForEditReport(reportId);
}

export function routeForMeasureIndex({
  projectId,
}: {
  projectId?: string;
}): string {
  return projectId ? `/measure/${projectId}/active` : routeForMeasureProjects();
}
routeForMeasureProjects;

export function routeForMeasureProjects(): string {
  return '/measure/projects';
}

export function routeForActivityDataOverview(): string {
  return '/measure/activity-data';
}

export const QUERY_PARAM_GLOBAL_FACILITIES_PAGE_FACILITY_ID = 'facility';

export function routeForGlobalFacilitiesPage(facilityId?: string): string {
  const facilityIdParams = facilityId
    ? { [QUERY_PARAM_GLOBAL_FACILITIES_PAGE_FACILITY_ID]: facilityId }
    : {};

  // TODO (bryan, DI-11867): Add a query-param for version, too - so we can link
  // to a specific version of a facility (e.g. from an old SYW page, etc)

  return getPathWithQuery('/measure/facilities', { ...facilityIdParams });
}

export const QUERY_PARAM_BI_FILTER_TOOLTIP = 'tooltipDimension';
export function routeForActivityData(
  activityType: string,
  biQueryParams?: BiQueryParams,
  otherQueryParams?: {
    tab?: ActivityDataTabType;
    [QUERY_PARAM_BI_FILTER_TOOLTIP]?: string;
  }
): string {
  // BI query params are passed as a fragment, others are passed as query string
  const fragment = biQueryParams ? makeBiQueryHashParams(biQueryParams) : {};

  return getPathWithQuery(
    `/measure/activity-data/${activityType}`,
    otherQueryParams,
    fragment
  );
}

export function routeForLegacyActivityData(): string {
  return routeForActivityData('legacy-data');
}

///////////////////////////////////////////////////////////////////////////////
// DATASETS
///////////////////////////////////////////////////////////////////////////////
export function routeForDatasets(): string {
  return '/measure/dataset';
}

export function routeForDataset(datasetId: string): string {
  return `/measure/dataset/${datasetId}`;
}

export function routeForDatasetApiUploads(datasetId: string): string {
  return `/measure/dataset/${datasetId}?activeTab=uploads`;
}

export function routeForApiUploadIndex(
  query: {
    datasetId?: string;
    status?: string;
    after?: string;
    before?: string;
    first?: string;
    last?: string;
  } = {}
): string {
  return getPathWithQuery(`${routeForDatasets()}/uploads`, query);
}

export function routeForUploadSchemasIndex(): string {
  return `${routeForDatasets()}/schemas`;
}

export function routeForUploadSchemasItem({
  datasetId,
  schemaId,
  schemaVersionId,
}: {
  datasetId: string;
  schemaId: string;
  schemaVersionId?: string;
}): string {
  return getPathWithQuery(
    `${routeForDatasets()}/schemas/${datasetId}/${schemaId}`,
    schemaVersionId ? { version: schemaVersionId } : undefined
  );
}

export function routeForApiUploadItem({
  apiUploadId,
}: {
  apiUploadId: string;
}): string {
  return `${routeForDatasets()}/uploads/${apiUploadId}`;
}

export function routeForDatasetApiUploadItem({
  datasetId,
  apiUploadId,
}: {
  datasetId: string;
  apiUploadId: string;
}): string {
  return `${routeForDatasets()}/${datasetId}?activeTab=uploads&apiUploadId=${apiUploadId}`;
}

export function routeForCtsForm({ ctsFormId }: { ctsFormId: string }): string {
  return getPathWithQuery(`/measure/ctsForm/${ctsFormId}`, {});
}

///////////////////////////////////////////////////////////////////////////////
// DATASETS (end)
///////////////////////////////////////////////////////////////////////////////

export function routeForDownloadingUserUploadFile(
  id: string,
  activeOrganizationId: string | null
): string {
  const params = new URLSearchParams();
  if (activeOrganizationId) {
    params.set(ACTIVE_ORGANIZATION_ID_QUERY_PARAM, activeOrganizationId);
  }
  return getPathWithQuery(`/api/files/user-uploads/${id}`, params);
}

export function routeForDownloadingReportAttachmentFile(id: string): string {
  return `/api/files/report-attachments/${id}`;
}

export function routeForDataGovernance(backTo?: BackTo): string {
  return getPathWithQuery(
    '/measure/data-governance',
    backTo ? { backTo } : undefined
  );
}

export const MEASUREMENT_PROJECT_ROUTE_QUERY_SCHEMA = z.object({
  filters: z
    .object({
      viewType: z.enum(['list', 'board']).optional(),
      groupBy: z.enum(['dataset', 'taskStatus']).optional(),
      assignees: z
        .object({
          type: z.enum(['all', 'specific']),
          userIds: z.array(z.string()).optional(),
        })
        .optional(),
      statuses: z.array(z.string()).optional(),
    })
    .optional(),
  new: z.literal('1').optional(),
});

export function routeForMeasurementProject(
  measurementProjectId: string,
  options?: z.infer<typeof MEASUREMENT_PROJECT_ROUTE_QUERY_SCHEMA>,
  activeTab: 'data-collection' | 'objects' = 'data-collection'
): string {
  return getPathWithQuery(
    `/measure/${measurementProjectId}/${activeTab}`,
    omit(
      {
        ...options,
        filters: JSON.stringify(options?.filters),
      },
      'tab'
    )
  );
}

export function routeForEmissionsFactors({
  activeTab,
  customizationTypeId,
}: {
  activeTab?: EmissionsFactorsTab;
  customizationTypeId?: string;
} = {}): string {
  return getPathWithQuery(`/measure/emissions-factors`, {
    [EF_ACTIVE_TAB_QUERY_PARAM]: activeTab,
    [EF_CUSTOMIZATION_TYPE_QUERY_PARAM]: customizationTypeId,
  });
}

export function routeForEmissionsFactorsImport({
  customizationTypeId,
  activeTab,
}: {
  customizationTypeId: string;
  activeTab?: EmissionsFactorsTab;
}): string {
  return getPathWithQuery(
    `/measure/emissions-factors/import/${customizationTypeId}`,
    {
      [EF_ACTIVE_TAB_QUERY_PARAM]: activeTab ?? EF_TAB_LIBRARY,
    }
  );
}

export function routeForNewMeasurement(
  backTo?: 'add-project' | undefined
): string {
  return getPathWithQuery('/measure/new', { backTo });
}

export function routeForEditCompanyTags(backTo?: BackTo): string {
  return getPathWithQuery('/measure/custom-tags', { backTo });
}

export function routeForDatasource(
  measurementProjectId: string,
  datasourceId: string,
  options: {
    importStep?: string;
    tab?: string;
    issue?: string;
    buildingTab?: string;
    importStage?: ImportStage;
    anchor?: string;
    utilityType?: GQBuildingUtilityType;
    buildingId?: string;
  } = {}
): string {
  const res = getPathWithQuery(
    `/measure/${measurementProjectId}/datasources/${datasourceId}/${
      options?.tab ?? 'current'
    }`,
    omit(options, ['tab', 'anchor']),
    options.anchor
  );
  return res;
}

export function routeForDatasourceWithoutMeasurementProject(
  datasourceId: string
): string {
  return `/measure/datasources/${datasourceId}/current`;
}

export function routeForDatasourceIssue(
  measurementProjectId: string,
  datasourceId: string,
  issueId: string
): string {
  return routeForDatasource(measurementProjectId, datasourceId, {
    issue: issueId,
  });
}

export const DATASOURCE_ID_QUERY_PARAM = 'datasourceId';

export function routeForFacilities({
  measurementProjectId,
  userUploadTaskId,
  issueId,
  importStage,
  FacilitiesDiscussionId,
  importKind,
  selectedFacility,
  ocr,
}: {
  measurementProjectId: string;
  userUploadTaskId?: string;
  issueId?: string;
  importStage?: string;
  FacilitiesDiscussionId?: string;
  importKind?: CanonicalDatasetKind;
  selectedFacility?: {
    identifier: string;
    measure: string;
  };
  ocr?: RunOcr;
}): string {
  const ocrParam = ocr != null ? { ocr } : {}; // OCR query should only show up when marked true
  return getPathWithQuery(`/measure/facilities/manage`, {
    measurementProjectId,
    userUploadTaskId,
    [DATA_ISSUE_ID_QUERY_PARAM]: issueId,
    importStage,
    FacilitiesDiscussionId,
    importKind,
    selectedFacilityIdentifier: selectedFacility?.identifier,
    selectedMeasure: selectedFacility?.measure,
    ...ocrParam,
  });
}

export function routeForFacility({
  measurementProjectId,
  datasourceId,
  buildingId,
  utilityType,
}: {
  measurementProjectId: string;
  datasourceId: string;
  buildingId: string;
  utilityType?: GQBuildingUtilityType;
}): string {
  return getPathWithQuery(
    `/measure/${measurementProjectId}/datasources/${datasourceId}/facility/${buildingId}`,
    {
      utilityType,
    }
  );
}

export function routeForFacilitiesTracker({
  measurementProjectId,
}: {
  measurementProjectId: string;
}): string {
  return getPathWithQuery(`/measure/facilities/tracker`, {
    measurementProjectId,
  });
}

export function routeForLatestMeasurementProjectWithFacilities(query?: {
  [key: string]: string | undefined | null;
}): string {
  return getPathWithQuery(`/measure/facilities/latest`, query);
}

export function routeForFacilitiesTaskManagement({
  measurementProjectId,
}: {
  measurementProjectId: string;
}): NextjsLinkHref {
  return {
    pathname: `/measure/facilities/configure`,
    query: { measurementProjectId, activeTab: 'tasks' },
  };
}

export function routeForCustomEmissionsFactors({
  measurementProjectId,
}: {
  measurementProjectId: string;
}): string {
  return `/measure/${measurementProjectId}/customEmissionsFactors`;
}

export function routeForClimateProgramTimeline(): string {
  return '/climate-program';
}

export function routeForAddClimateProgramProject(
  climateProgramProjectKind?: string
): string {
  return (
    '/climate-program/add-project' +
    (climateProgramProjectKind ? `#${climateProgramProjectKind}` : '')
  );
}

export function routeForMarketplace(anchor?: string): string {
  if (anchor) {
    return `/marketplace/explore#${anchor}`;
  }
  return '/marketplace/explore';
}

export function routeForMarketplacePurchases(): string {
  return '/marketplace/purchases';
}

export function routeForPortfolioBuilder(portfolioId?: string): string {
  const base = '/marketplace/portfolios';
  if (portfolioId) {
    return `${base}/${portfolioId}`;
  }
  return base;
}

export function routeForApiSettings(): string {
  return '/settings/api';
}

export function routeForIntegrationsSettings(): string {
  return '/settings/integrations';
}

export function routeForWorkspaceSettings(): string {
  return '/settings/preferences';
}

export function routeForFinanceSettings(): string {
  return '/settings/finance';
}

export function routeForAccessSettings(): string {
  return '/settings/access';
}

export function routeForOrganizationUsers(): string {
  return '/settings/users';
}

export function routeForUserSettings(userId: string): string {
  return `/settings/user/${userId}`;
}

export function routeForOrganizationRoles(): string {
  return '/settings/roles';
}

export function routeForRoleSettings(roleId: string): string {
  return `/settings/role/${roleId}`;
}

export function routeForOrganizationPermissions(): string {
  return '/settings/permissions';
}

export function routeForSharedFileDownload(id: string): string {
  return `/shared-file/${id}`;
}

export function routeForSharedFiles(): string {
  return '/measure/shared-files';
}

export function routeForValueMappings({
  measurementProjectId,
  mappingRuleId,
  datasourceId,
  options,
}: {
  measurementProjectId: string;
  mappingRuleId: string;
  datasourceId?: string;
  options?: {
    tab?: string;
    issue?: string;
  };
}): string {
  return getPathWithQuery(
    `/measure/${measurementProjectId}/mappings/${mappingRuleId}`,
    {
      datasourceId,
      ...options,
    }
  );
}

export function routeForFinancialMappings({
  reviewItemId = undefined,
  measurementProjectId,
}: {
  reviewItemId?: string;
  measurementProjectId: string;
}): string {
  return `/measure/${measurementProjectId}/verification${
    reviewItemId !== undefined ? `/${reviewItemId}` : ''
  }`;
}

export function routeForFinancialMappingsIndex(): string {
  return '/measure/verification';
}

export function routeForVendorMatchingTask({
  measurementProjectId,
}: {
  measurementProjectId: string;
}): string {
  return `/measure/${measurementProjectId}/vendor-matching`;
}

/**
 * WIN FINANCE ROUTES (start)
 */

export function routeForFinanceHome(
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return { pathname: `${WIN_FINANCE_PREFIX}/home`, query };
}

export function routeForFinanceOverview(
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return { pathname: WIN_FINANCE_PREFIX, query };
}

export function routeForFund(
  fundId: string,
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return { pathname: `${WIN_FINANCE_PREFIX}/funds/${fundId}`, query };
}

export function routeForFundGroup(
  fundGroup: string,
  {
    fundCategory,
  }: {
    fundCategory?: string | null;
  },
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return {
    pathname: fundCategory
      ? `${WIN_FINANCE_PREFIX}/fund-categories/${encodeURIComponent(
          fundCategory
        )}/fund-groups/${encodeURIComponent(fundGroup)}`
      : `${WIN_FINANCE_PREFIX}/fund-groups/${encodeURIComponent(fundGroup)}`,
    query,
  };
}

export function routeForFundCategory(
  fundCategory: string,
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return {
    pathname: `${WIN_FINANCE_PREFIX}/fund-categories/${encodeURIComponent(
      fundCategory
    )}`,
    query,
  };
}

export function routeForFinanceView(
  viewId: string,
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return { pathname: `${WIN_FINANCE_PREFIX}/views/${viewId}`, query };
}

export type FinanceAssetActiveTab = 'summary' | 'info' | 'changelog' | null;

export function routeForAsset(
  assetId: string,
  {
    viewId,
    fundId,
    activeTab,
  }: {
    fundId?: string | null;
    viewId?: string | null;
    activeTab?: FinanceAssetActiveTab;
  },
  queryParam: ParsedUrlQueryInput
): NextjsLinkHref {
  const query = {
    ...queryParam,
    activeTab: activeTab ?? 'summary',
  };

  if (viewId) {
    return {
      pathname: `${WIN_FINANCE_PREFIX}/views/${viewId}/assets/${assetId}`,
      query,
    };
  }

  if (fundId) {
    return {
      pathname: `${WIN_FINANCE_PREFIX}/funds/${fundId}/assets/${assetId}`,
      query,
    };
  }

  return {
    pathname: `${WIN_FINANCE_PREFIX}/assets/${assetId}`,
    query,
  };
}

export function routeForAssetName(assetName: string): string {
  return `${WIN_FINANCE_PREFIX}/assets/name/${encodeURIComponent(assetName)}`;
}

export enum FinanceMeasurementTab {
  Import = 'import',
  Flags = 'flags',
  MeasurementProgress = 'progress',
  Collection = 'collection',
  Tasks = 'tasks',
  Previous = 'previous',
  Changelog = 'changelog',
  Sandbox = 'sandbox',
  CompanyMatching = 'company-matching',
  Settings = 'settings',
}

export type FinanceForecastingTab = 'portfolio' | 'asset';

export function routeForFinanceMeasurement(
  query: ParsedUrlQueryInput,
  tab?: FinanceMeasurementTab
): NextjsLinkHref {
  return {
    pathname: `${WIN_FINANCE_PREFIX}/measurement/${
      tab ?? FinanceMeasurementTab.Import
    }`,
    query,
  };
}

export enum FinanceYearOverYearTab {
  Metrics = 'metrics',
  Assets = 'assets',
  Funds = 'funds',
}

export function routeForFinanceYearOverYear(
  query: ParsedUrlQueryInput,
  tab?: FinanceYearOverYearTab
): NextjsLinkHref {
  return {
    pathname: `${WIN_FINANCE_PREFIX}/compare/${
      tab ?? FinanceYearOverYearTab.Metrics
    }`,
    query,
  };
}

export function routeForFinanceDueDiligence(
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return {
    pathname: `${WIN_FINANCE_PREFIX}/diligence`,
    query,
  };
}

export function routeForFinanceImportFile(id: string): string {
  return `/api/finance/finance-import-file/${id}`;
}

export function routeForAssetCommentAttachment(id: string): string {
  return `/api/finance/asset-comment-attachment/${id}`;
}

export function routeForFinanceDataFlags(
  query: ParsedUrlQueryInput
): NextjsLinkHref {
  return { pathname: `${WIN_FINANCE_PREFIX}/measurement/flags`, query };
}

function routeForFinanceTasks(): string {
  return `${WIN_FINANCE_PREFIX}/measurement/tasks`;
}

export function routeForFinanceTaskTemplates(): string {
  return `${WIN_FINANCE_PREFIX}/measurement/tasks?view=taskTemplates`;
}

export function routeForFinanceFootprintAssembly(): string {
  return `${WIN_FINANCE_PREFIX}/footprint/assembly`;
}

export function routeForFinanceDataExplorer({
  footprintSnapshotId,
}: {
  footprintSnapshotId?: string;
} = {}): NextjsLinkHref {
  return {
    pathname: `${WIN_FINANCE_PREFIX}/data-explorer`,
    query: footprintSnapshotId ? { footprintSnapshotId } : undefined,
  };
}

export function routeForFinanceDataExplorerOverview(): string {
  return `${routeForFinanceDataExplorer().pathname}/overview`;
}

/**
 * WIN FINANCE ROUTES (end)
 */

export function routeForUtilitySupportDocument(
  userUploadId: string,
  activeOrganizationId: string | null
): string {
  const params = new URLSearchParams();
  if (activeOrganizationId) {
    params.set(ACTIVE_ORGANIZATION_ID_QUERY_PARAM, activeOrganizationId);
  }
  return getPathWithQuery(
    `/api/data-ingestion/supporting-document/${userUploadId}`,
    params
  );
}

export function routeForCustomerHub(
  customerCompanyId: string,
  query: {
    previewAs?: string;
    showSettings?: string;
    recipientCompanyId?: string;
  } & LoginQueryParams = {}
): string {
  return getPathWithQuery(`/surveys/hub/${customerCompanyId}`, query);
}

export function routeForTasks(): string {
  return '/tasks';
}

// Route for reductions overall, replaces routeForAllPlans
export function routeForReductions(): string {
  return routeForReduxEnterprise();
}

export function routeForReduxEnterprise({
  planId,
}: {
  planId?: string;
} = {}): string {
  const prefix = '/redux';
  if (planId) return `${prefix}/plan/${planId}`;
  return prefix;
}

// This is separate to routeForReduxEnterprise as a few functions in this file
// append path segments to the output of routeForReduxEnterprise, if someone
// inadvertently passed param values to those functions things would break
// eg /redux/settings would become /redux?scenarioId=123/settings
// TODO(EUR-1427): Clean up and consolidate redux enterprise route generation.
export function routeForReduxEnterpriseWithForecastScenario({
  planId,
  scenarioId,
}: {
  planId?: string;
  scenarioId?: string;
}): string {
  return getPathWithQuery(routeForReduxEnterprise({ planId }), {
    scenarioId,
  });
}

export function routeForReduxEnterprisePlanSettings(planId: string): string {
  const root = routeForReduxEnterprise({ planId });
  return `${root}/settings`;
}

export function routeForReduxPlanTarget(
  planId: string,
  targetId: string
): string {
  return `${routeForReduxEnterprise({ planId })}/targets/${targetId}`;
}

export function routeForReduxPlanRemoval({
  planId,
  scenarioId,
}: {
  planId: string;
  scenarioId?: string;
}): string {
  return getPathWithQuery(`${routeForReduxEnterprise({ planId })}/removal`, {
    scenarioId,
  });
}

export function routeForReduxForecasts(
  scenarioId?: string,
  returnToPlanId?: string
): string {
  const root = routeForReduxEnterprise();
  return getPathWithQuery(`${root}/forecasts`, { scenarioId, returnToPlanId });
}

export type LearningHubRouteParams = {
  from: LearningHubEntryPoint;
  article?: LearningHubArticle;
  viewMode?: LearningHubViewMode;
};

export function routeForLearningHub({
  from,
  article,
  viewMode,
}: LearningHubRouteParams): string {
  const baseUrl = learningHubBaseUrlForViewMode(viewMode ?? 'regular');
  const hash = article ? learningHubHashForArticle(article) : undefined;
  return getPathWithQuery(baseUrl, { from }, hash);
}

export function routeForForbiddenLoginAsAction(action: string): string {
  return getPathWithQuery('/forbidden-login-as', { action });
}

export function routeForOrgProfileFlow(step: string): string {
  return `/onboarding/${step}`;
}

function routeForStorybookInProduction(): string {
  return `https://go.watershed.com/sb`;
}

function routeForStorybookInDevelopment(): string {
  return `http://localhost:6006`;
}

export function routeForStorybook(): string {
  return process.env.NODE_ENV === 'production'
    ? routeForStorybookInProduction()
    : routeForStorybookInDevelopment();
}

export function routeForPublicApiDocs(): string {
  return 'https://api-docs.watershed.com/docs';
}

// Used as backTo query param to support breadcrumbs on pages linked from multiple parents
export enum BackTo {
  Datasets = 'datasets',
  AddProject = 'add-project',
  Projects = 'projects',
  Measurements = 'measurements',
}

export function getBackToBreadcrumb(backTo: BackTo): {
  title: string;
  url: string;
} {
  switch (backTo) {
    case 'datasets':
      return {
        title: t({ context: 'Breadcrumb page title', message: `Datasets` }),
        url: routeForDatasets(),
      };
    case 'add-project':
      return {
        title: t({
          context: 'Breadcrumb page title',
          message: `Project library`,
        }),
        url: routeForAddClimateProgramProject(),
      };
    case 'projects':
    case 'measurements':
      return {
        title: t({ context: 'Breadcrumb page title', message: `Measurements` }),
        url: routeForMeasureProjects(),
      };
    default:
      throw new Error(`Unknown backTo: ${backTo}`);
  }
}

/**
 * Paginated list of previous runs
 */
export function routeForDemoPlatformIndex(): string {
  return '/demo-platform';
}

/**
 * Route to create a new demo platform run
 */
export function routeForDemoPlatformNew(query?: {
  step?: DemoPlatformStep;
  configJson?: string;
}): string {
  return query
    ? getPathWithQuery('/demo-platform/new', query)
    : '/demo-platform/new';
}

/**
 * Single item view for a demo platform run
 */
export function routeForDemoPlatformRun({
  demoPlatformRunId,
  jobId,
}: { demoPlatformRunId: string; jobId?: string }): NextjsLinkHref {
  return {
    pathname: `/demo-platform/${demoPlatformRunId}`,
    query: jobId ? { jobId } : undefined,
  };
}

export function routeForOnboarding(): string {
  return '/onboarding';
}

export function routeForSupport(): string {
  return '/support';
}

export function routeForSupportCase(
  caseId: string,
  { conf }: { conf?: boolean } = {}
): string {
  return `/support/${caseId}${conf ? '?conf=true' : ''}`;
}

export function routeForProductionGraphMaterials(): string {
  return `/purchased-material`;
}

export function routeForMaterialVariant(materialVariantId: string): string {
  return `/purchased-material/material-variant/${materialVariantId}`;
}
