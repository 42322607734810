import {
  set as setIdb,
  get as getIdb,
  setMany as setManyIdb,
  getMany as getManyIdb,
  clear as clearIdb,
  createStore,
} from 'idb-keyval';

// We keep a registry of indexdb keys for type safety and to avoid storage of
// arbitrary junk
const _REGISTERED_INDEXEDDB_KEYS = [
  'reducibleFootprint',
  'reductionsRefData',
  'reducibleFootprintHash',
  'reductionsRefDataHash',
  'reductionsContributionsQueryV2',
  'reductionsContributionsQueryV3', // Using the new columnar format, FY24-Q3
] as const;
export type RegisteredIndexedDBKey =
  (typeof _REGISTERED_INDEXEDDB_KEYS)[number];

const INDEXEDDB_NAME = 'watershed';
const INDEXEDDB_OBJECT_STORE = 'key_value_store';

// We put this in a function because we can't have this run on the server. Calls
// to IdbKeyValueStore should only be within the render function or component
// callbacks.
const getCustomStore = () =>
  createStore(INDEXEDDB_NAME, INDEXEDDB_OBJECT_STORE);

interface IndexedDBKeyValueStore {
  // We could support any values that are supported by IndexedDB, but will start
  // with just strings for now
  set(key: RegisteredIndexedDBKey, value: string | undefined): Promise<void>;
  get(key: RegisteredIndexedDBKey): Promise<string | undefined>;
  setMany(
    pairs: ReadonlyArray<[RegisteredIndexedDBKey, string | undefined]>
  ): Promise<void>;
  // This `| []` allows us to maintain the tuple length in the return argument,
  // and correctly infers that arguments are tuples instead of arrays. See
  // https://www.typescriptlang.org/play#code/GYVwdgxgLglg9mABKSBGAPAFUQUwB5Q5gAmAzogE44CGxCANgJ6LgDWYcA7mANoC6APgAUANwBciTAEoJ2AN4BYAFCJKOKCApIRygL7LlKaPCRGATFlwEiZNbQbM2Hbv0QAfRP2HjJMyYkUVNQ0tRB0lfSVlCARSKERqRABeZHAIVCEeAHJgODgsgBpEMwBmABY+KUQAemrEITiKGDAAc3dEMBAAWwAjHAopfmjY+J7k1MgzTJy8wuLyypq6nkbmlqLO3v6+IA
  getMany<T extends ReadonlyArray<RegisteredIndexedDBKey> | []>(
    keys: T
  ): Promise<{ -readonly [index in keyof T]: string | undefined }>;
  clear(): Promise<void>;
}

/**
 * This is a wrapper around idb-keyval with our custom store and registered keys
 * for type safety.
 */
export const IdbKeyValueStore: IndexedDBKeyValueStore = {
  set: (key, value) => setIdb(key, value, getCustomStore()),
  get: (key) => getIdb(key, getCustomStore()),
  // @ts-expect-error setMany in idb-keyval doesn't actually need a mutable
  // array.
  setMany: (pairs) => setManyIdb(pairs, getCustomStore()),
  // @ts-expect-error the types on idb-keyval types don't quite do what we want
  // them to.
  getMany: (keys) => getManyIdb(keys, getCustomStore()),
  clear: () => clearIdb(getCustomStore()),
};
