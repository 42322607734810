import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const StatusCompleteIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 12 12" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M6 10.583a4.583 4.583 0 1 0 0-9.166 4.583 4.583 0 0 0 0 9.166Zm2.181-5.55a.417.417 0 0 0-.612-.566l-2.206 2.39-.943-.943a.417.417 0 1 0-.59.589l1.25 1.25a.417.417 0 0 0 .601-.012l2.5-2.708Z"
        clipRule="evenodd"
      />
    </Icon>
  )
);
export default StatusCompleteIcon;
