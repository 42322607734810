/**
 * Lightweight wrappers around the Intl formatters in order to reuse instances
 * as much as possible, and to default to the correct locale. They all accept
 * a locale override option for cases where it benefits us to be more explicit.
 *
 * We reuse the instances because the overhead of creating them is relatively
 * high, and it's possible that these functions will be called on a hot path.
 */
import invariant from 'invariant';
import { PSEUDO_LOCALE, SupportedLocale } from './constants';
import { pseudoLocalize } from './utils';
import { i18n } from '.';

const minDate = new Date(Date.UTC(1, 0, 1));
minDate.setUTCFullYear(1);
const maxDate = new Date(8640000000000000);

function getCacheKey(locale: string, options: object = {}): string {
  return `${locale}-${JSON.stringify(options)}`;
}

// These caches don't have a mechanism to keep them from growing indefinitely,
// this probably isn't an issue because the number of locales and options is
// limited, but it's something to keep in mind.
const dateFormatters = new Map<string, Intl.DateTimeFormat>();

const DatePresets: Record<string, Intl.DateTimeFormatOptions> = {
  // Example: Oct 26, 2021
  MED_DATE: { day: 'numeric', month: 'short', year: 'numeric' },
  // Example: Oct 26, 2021 3:00 PM
  MED_DATETIME_12: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
  // Example: Oct 26, 2021 15:00
  MED_DATETIME_24: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
  // Example: October 26, 2021
  FULL_DATE: { day: 'numeric', month: 'long', year: 'numeric' },
} as const;

type FormatDateOptions =
  | (Intl.DateTimeFormatOptions & {
      locale?: SupportedLocale;
      preset?: undefined;
      // timeZone should already be on Intl.DateTimeFormatOptions but not in the ts version we are using
      timeZone?: string; // From IANA Time Zone Database e.g. "UTC", "America/New_York""
    })
  | {
      locale?: SupportedLocale;
      preset: keyof typeof DatePresets;
      // timeZone should already be on Intl.DateTimeFormatOptions but not in the ts version we are using
      timeZone?: string; // From IANA Time Zone Database e.g. "UTC", "America/New_York""
    };

// TODO: Default options
// TODO: Accept other date-like objects
export function formatDate(
  date: Date,
  { locale = i18n.locale as SupportedLocale, ...options }: FormatDateOptions = {
    preset: undefined,
  }
): string {
  if ('preset' in options && options.preset !== undefined) {
    options = {
      ...options,
      ...DatePresets[options.preset],
      preset: undefined,
    };
  }

  const cacheKey = getCacheKey(locale, options);

  if (!dateFormatters.has(cacheKey)) {
    dateFormatters.set(cacheKey, new Intl.DateTimeFormat(locale, options));
  }

  const result = (dateFormatters.get(cacheKey) as Intl.DateTimeFormat).format(
    date
  );

  if (locale === PSEUDO_LOCALE) {
    return pseudoLocalize(result);
  }

  return result;
}

export function formatDateRange(
  startDate: undefined,
  endDate: Date,
  options?: FormatDateOptions
): string;
export function formatDateRange(
  startDate: Date,
  endDate: undefined,
  options?: FormatDateOptions
): string;
export function formatDateRange(
  startDate: Date,
  endDate: Date,
  options?: FormatDateOptions
): string;
export function formatDateRange(
  startDate: Date | undefined,
  endDate: Date | undefined,
  { locale = i18n.locale as SupportedLocale, ...options }: FormatDateOptions = {
    preset: undefined,
  }
): string {
  invariant(startDate || endDate, 'At least one date must be defined');

  if ('preset' in options && options.preset !== undefined) {
    options = {
      ...options,
      ...DatePresets[options.preset],
      preset: undefined,
    };
  }

  const cacheKey = getCacheKey(locale, options);

  if (!dateFormatters.has(cacheKey)) {
    dateFormatters.set(cacheKey, new Intl.DateTimeFormat(locale, options));
  }

  const formatter = dateFormatters.get(cacheKey) as Intl.DateTimeFormat;

  let result;

  if (startDate && endDate) {
    result = formatter.formatRange(startDate, endDate);
  } else {
    // Because we want to support open ranges (i.e start or end isn't defined), we
    // can't use formatRange directly, as it doesn't support undefined values.
    // Instead, we use formatRangeToParts with dummy placeholders for start and
    // end, and then filter out the parts we don't want.
    //
    // This even *appears* to work when the parts are in different orders, because
    // there are unicode RTL marks in the output if needed.
    let parts = formatter.formatRangeToParts(
      startDate ?? minDate,
      endDate ?? maxDate
    );

    if (!startDate) {
      parts = parts.filter((part) => part.source !== 'startRange');
    } else if (!endDate) {
      parts = parts.filter((part) => part.source !== 'endRange');
    }

    result = parts.map((part) => part.value).join('');
  }

  if (locale === PSEUDO_LOCALE) {
    return pseudoLocalize(result);
  }

  return result;
}

const numberFormatters = new Map<string, Intl.NumberFormat>();

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  locale?: string;
}

export function formatNumber(
  number: number,
  {
    locale = i18n.locale as SupportedLocale,
    ...options
  }: FormatNumberOptions = {}
): string {
  const cacheKey = getCacheKey(locale, options);

  if (!numberFormatters.has(cacheKey)) {
    numberFormatters.set(cacheKey, new Intl.NumberFormat(locale, options));
  }

  return (numberFormatters.get(cacheKey) as Intl.NumberFormat).format(number);
}

// TODO: Default options
export function formatCurrency(
  amount: number,
  currency: string,
  opts: Omit<FormatNumberOptions, 'currency' | 'style'>
): string {
  return formatNumber(amount, { ...opts, style: 'currency', currency });
}

const relativeTimeFormatters = new Map<string, Intl.RelativeTimeFormat>();

interface FormatRelativeTimeOptions extends Intl.RelativeTimeFormatOptions {
  locale?: string;
}

// TODO: Default options
export function formatRelativeTime(
  value: number,
  unit: Intl.RelativeTimeFormatUnit,
  { locale = i18n.locale, ...options }: FormatRelativeTimeOptions = {}
): string {
  const cacheKey = getCacheKey(locale, options);

  if (!relativeTimeFormatters.has(cacheKey)) {
    relativeTimeFormatters.set(
      cacheKey,
      new Intl.RelativeTimeFormat(locale, options)
    );
  }

  return (
    relativeTimeFormatters.get(cacheKey) as Intl.RelativeTimeFormat
  ).format(value, unit);
}

const listFormatters = new Map<string, Intl.ListFormat>();

interface FormatListOptions extends Intl.ListFormatOptions {
  locale?: string;
}

/**
 * @param formattingOptions: defaults to 'unit' type and 'short' style
 */
export function formatList(
  items: Array<string>,
  { locale = i18n.locale, ...options }: FormatListOptions = {}
): string {
  options = {
    type: options.type ?? 'unit',
    style: options.style ?? 'short',
  };
  const cacheKey = getCacheKey(locale, options);

  if (!listFormatters.has(cacheKey)) {
    listFormatters.set(cacheKey, new Intl.ListFormat(locale, options));
  }

  return (listFormatters.get(cacheKey) as Intl.ListFormat).format(items);
}

// const segmenters = new Map<string, Intl.Segmenter>();

// interface SegmenterOptions extends Intl.SegmenterOptions {
//   locale?: string;
// }

// TODO: Default options
// commented out because Segmenter is a new API and not yet available on some
// browser versions: https://linear.app/watershed/issue/ENT-409/[iscustomer]-jpmorgan-chase-and-co-new-honeycomb-drilldown-error
// export function segmentText(
//   text: string,
//   { locale, ...options }: SegmenterOptions = {}
// ): Intl.Segments {
//   locale ??= DEFAULT_LOCALE;

//   const cacheKey = getCacheKey(locale, options);

//   if (!segmenters.has(cacheKey)) {
//     segmenters.set(cacheKey, new Intl.Segmenter(locale, options));
//   }

//   return (segmenters.get(cacheKey) as Intl.Segmenter).segment(text);
// }
