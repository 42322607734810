import { Event, EventHint } from '@sentry/browser';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import {
  getErrorCode,
  hasKnownErrorCode,
  shouldSendErrorToSentry,
} from '@watershed/errors/ErrorRegistry';
import {
  SentryBeforeSendPolicy,
  getBeforeSendPolicy,
} from '@watershed/shared-universal/utils/universalSentryUtils';
import { ErrorData, hasErrorData } from '@watershed/errors/WatershedError';

export default async function sentryBeforeSend(event: Event, hint?: EventHint) {
  const error = await hint?.originalException;

  // Don't send these to datadog, they're user transparent and not actionable
  if (error) {
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    if (isIgnorableGoogleAnalyticsError(error)) {
      return null;
    }
  }

  let isFilteredOutOfSentry = false;
  try {
    if (error) {
      if (
        !shouldSendErrorToSentry(
          'Browser',
          // @ts-expect-error
          error?.code
        ) &&
        getBeforeSendPolicy(event.extra) !== SentryBeforeSendPolicy.ALWAYS
      ) {
        Analytics.error('unreportedError', {
          error,
          code: hasKnownErrorCode(error) ? getErrorCode(error) : null,
        });

        isFilteredOutOfSentry = true;
        return null;
      }
    }

    const fullStorySessionUrl = await Analytics.getFullStorySessionURL();
    if (fullStorySessionUrl) {
      addLinkToContext(event, 'fullStorySessionUrl', fullStorySessionUrl);
    }

    if (hasErrorData(error)) {
      addDataToContext(event, error.data);
    }
  } finally {
    if (typeof window === 'undefined') {
      // See #incident-2023-03-16-errors-misplaced for a case where the server
      // was incorrectly loading the browser's sentryBeforeSend. We think we've
      // stopped it, but we're leaving this in for now to help debug if it
      // happens again.
      console.error(
        'Error in sentryBeforeSend from the server',
        error,
        isFilteredOutOfSentry
        // event.contexts
      );
    }
  }

  return event;
}

function addLinkToContext(event: Event, linkName: string, linkUrl: string) {
  const contexts = {
    ...event.contexts,
    Links: {
      ...event.contexts?.Links,
      [linkName]: linkUrl,
    },
  };
  event.contexts = contexts;
}

function addDataToContext(event: Event, data: ErrorData) {
  const contexts = {
    ...event.contexts,
    ErrorData: data,
  };
  event.contexts = contexts;
}

/**
 * We want to ignore TypeError: Illegal Invocation errors from google analytics.
 *
 * Example stacktrace to ignore:

 TypeError: Illegal invocation
  at PB(/gtag/js:388:23)
  at QB(/gtag/js:383:107)
  at TB.flush(/gtag/js:385:598)
  at ? (/gtag/js:385:104)
  at i(webpack://_N_E/../../node_modules/.pnpm/@sentry+browser@6.19.3/node_modules/@sentry/browser/esm/helpers.js:73:23)

 */
export function isIgnorableGoogleAnalyticsError(error: Error | string) {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const errorString = error.stack || String(error);
  return (
    errorString.includes('Illegal invocation') &&
    errorString.includes('gtag/js')
  );
}
