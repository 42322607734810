import {
  ALL_FUEL_KINDS,
  AQUEDUCT_VERSION,
  WASTE_LIFECYCLES,
  WASTE_MATERIAL,
} from '../../../batSchemas/constants';
import {
  ENERGY_KIND_VALUES,
  EU_PRTR_POLLUTANT_GROUP_NAMES,
  MICROPLASTICS_OUTFLOW_MEDIUM,
  SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
  SUBSTANCE_OF_CONCERN,
  SUBSTANCE_OUTFLOW_MEDIUM,
} from '../../../canonicalSchemas/constants';
import { TSchema } from '../../meta';
import { PipelineResultSchema } from './PipelineResultSchema';

export const SHOULD_EXCLUDE_TAG_NAME = 'should_exclude';
// We don't use true/false because the tagging editor assumes they're booleans
export const SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE = 'yes';
export const SHOULD_EXCLUDE_TAG_VALUES = [
  SHOULD_EXCLUDE_TAG_VALUE_EXCLUDE,
  'no',
];

export const FUEL_COMBUSTION_KIND_VALUES = ['stationary', 'mobile'];

export const MEASUREMENT_FIDELITIES = [
  'ActivityBased',
  'Cee',
  'IndirectActivityBased',
  'SpendBased',
  'SquareFootageBased',
  'SupplierSpendBased',
  'UtilitiesBased',
] as const;
export type MeasurementFidelity = (typeof MEASUREMENT_FIDELITIES)[number];

/**
 * A pipeline result after default and custom tags have been applied.
 */
export const PipelineResultWithTagsSchema = {
  slug: 'pipeline_result_with_tags:13.15',
  schema: {
    properties: {
      ...(
        PipelineResultSchema satisfies {
          // This check will fail if the imported schema version changes.
          // If this happens, you must update this schema's version as well.
          slug: 'pipeline_result:13.9';
        }
      ).schema.properties,

      // This is overriding the `enum` value declared in `PipelineResultSchema`.
      // See
      // https://linear.app/watershed/issue/CPT-838/footprint-generation-failure-for-mongodb.
      // This should be temporary fix until we have a better solution in place.
      category_id: {
        type: 'string',
        nullable: true,
        description:
          "The first level of Watershed's business category taxonomy.",
        examples: ['apparel', 'restaurants', 'offices', 'employees'],
      },

      building_name: {
        description:
          'The building name associated with the pipeline result row',
        type: 'string',
        examples: ['Montreal Office'],
        nullable: true,
      },
      measurement_fidelity: {
        description: 'The fidelity of measurement methodology',
        type: 'enum',
        options: MEASUREMENT_FIDELITIES,
        nullable: true,
      },
      emissions_category_1: {
        description: 'First level emissions category',
        type: 'string',
        examples: ['electricity'],
        nullable: true,
      },
      emissions_category_2: {
        description: 'Second level emissions category',
        type: 'string',
        examples: ['natural_gas'],
        nullable: true,
      },
      emissions_category_3: {
        description: 'Third level emissions category',
        type: 'string',
        examples: ['manufacturing'],
        nullable: true,
      },
      fuel_combustion_kind: {
        description: 'The kind of fuel combustion',
        type: 'enum',
        options: FUEL_COMBUSTION_KIND_VALUES,
        nullable: true,
      },
      fuel_kind: {
        description: 'The kind of fuel consumed',
        type: 'enum',
        options: ALL_FUEL_KINDS,
        nullable: true,
      },
      energy_kind: {
        description: 'The primary form by which energy was carried',
        type: 'enum',
        options: ENERGY_KIND_VALUES,
        nullable: true,
      },
      asset_id: {
        description:
          'The finance asset ID associated with the pipeline result row',
        type: 'string',
        examples: ['fnh_2d555wpcak1oA8aEiPBs'],
        nullable: true,
      },
      waste_material: {
        description: 'The waste material with the pipeline result row',
        type: 'enum',
        options: WASTE_MATERIAL,
        nullable: true,
      },
      waste_treatment: {
        description:
          'The waste treatment associated with the pipeline result row',
        type: 'string', // This is loosened from `WASTE_TREATMENTS` temporarily - see CPT-2149
        nullable: true,
      },
      waste_lifecycle: {
        description:
          'The waste lifecycle associated with the pipeline result row',
        type: 'enum',
        options: WASTE_LIFECYCLES,
        nullable: true,
      },
      // TODO(CSRD): We should remove one of the hazard fields. Whether or not the user marked as hazardous is
      // captured on the BART. The footprint field should capture the derived value (though it's unclear)
      // what SYW for this would look like.
      is_waste_hazardous: {
        description:
          'Whether or not the waste associated with the pipeline result row is hazardous',
        type: 'boolean',
        nullable: true,
      },
      is_waste_csrd_hazardous: {
        description:
          'Whether or not the waste associated with the pipeline result row is hazardous according to CSRD regulations',
        type: 'boolean',
        nullable: true,
      },
      is_waste_radioactive: {
        description:
          'Whether or not the waste associated with the pipeline result row is radioactive',
        type: 'boolean',
        nullable: true,
      },
      pollutant_cas_number: {
        description:
          'The pollutant cas number associated with the pipeline result row',
        type: 'string',
        nullable: true,
      },
      eu_pollutant_release_and_transfer_register_pollutant_group: {
        description:
          'The pollutant group associated with the pipeline result row. The ontology comes from the EU Pollutant Release and Transfer Register.',
        type: 'enum',
        options: EU_PRTR_POLLUTANT_GROUP_NAMES,
        nullable: true,
      },
      pollutant_substance_of_concern: {
        description:
          'The pollutant substance of concern associated with the pipeline result row',
        type: 'enum',
        options: SUBSTANCE_OF_CONCERN,
        nullable: true,
      },
      // TODO(CSRD): This is not a string. This is a list of enums. We currently pass it in as a list comma-separated
      // strings.
      pollutant_substance_of_concern_hazard_class: {
        description:
          'The pollutant substance of concern hazard class associated with the pipeline result row',
        type: 'string',
        nullable: true,
      },
      substance_inflow_medium: {
        description:
          'The medium through which the substance entered the system',
        type: 'enum',
        options: SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
        nullable: true,
      },
      microplastics_inflow_medium: {
        description:
          'The medium through which the microplastics entered the system',
        type: 'enum',
        options: SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM,
        nullable: true,
      },
      substance_outflow_medium: {
        description: 'The medium through which the substance left the system',
        type: 'enum',
        options: SUBSTANCE_OUTFLOW_MEDIUM,
        nullable: true,
      },
      microplastics_outflow_medium: {
        description:
          'The medium through which the microplastics left the system',
        type: 'enum',
        options: MICROPLASTICS_OUTFLOW_MEDIUM,
        nullable: true,
      },
      [SHOULD_EXCLUDE_TAG_NAME]: {
        description:
          'Whether to exclude this row from the footprint snapshot. Not a boolean because tagging only supports strings.',
        type: 'enum',
        options: SHOULD_EXCLUDE_TAG_VALUES,
        nullable: true,
      },
      aqueduct_stable_row_id: {
        description:
          'The corresponding Aqueduct stable ID (aq30_id) for the location at which this water activity occurred.',
        type: 'string',
        nullable: true,
      },
      aqueduct_version: {
        description:
          'The Aqueduct dataset version that the aqueduct_stable_row_id corresponds to.',
        type: 'enum',
        options: AQUEDUCT_VERSION,
        nullable: true,
      },
      material_sustainable_sourcing_scheme: {
        description:
          'If the material is sustainably sourced, the certification scheme used.',
        type: 'string',
        nullable: true,
      },
      material_application_of_cascading_principle: {
        description:
          'A description of how the material was put into an order of value to create resource effectiveness.',
        type: 'string',
        nullable: true,
      },
      material_is_biological: {
        description:
          'Whether the material is derived from or produced by biological organisms.',
        type: 'string',
        nullable: true,
      },
      material_is_secondary: {
        description: 'Whether the material is not a virgin material.',
        type: 'string',
        nullable: true,
      },
      material_is_recyclable: {
        description: 'Whether the material can be recycled.',
        type: 'string',
        nullable: true,
      },
      tags: {
        description:
          'Stringified JSON containing all tags associated with the pipeline result row.',
        type: 'string',
        nullable: true,
      },
      org_structure_version_id: {
        description:
          'The organizational structure version id associated with the pipeline result row',
        type: 'string',
        nullable: true,
      },
    },
    prefixProperties: {
      'tag:': {
        type: 'string',
        nullable: true,
      },
      org_unit_: {
        type: 'string',
        nullable: true,
      },
    },
  },
} as const satisfies TSchema;
