import {
  makeCustomErrorInvariant,
  CustomErrorInvariant,
  WatershedErrorOptions,
  WatershedError,
  makeRethrower,
} from './WatershedError';

export class DataResponseEmpty extends WatershedError {
  status = 400;
  constructor(message?: string, options?: WatershedErrorOptions) {
    super('DATA_RESPONSE_EMPTY', message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DataResponseEmpty);
    }
    Object.setPrototypeOf(this, DataResponseEmpty.prototype);
  }

  static invariant: CustomErrorInvariant =
    makeCustomErrorInvariant(DataResponseEmpty);
  static wrapAndRethrow = makeRethrower(DataResponseEmpty);
}
