import { atom } from 'jotai';

import { CommandPaletteSelection } from './types';

/**
 * The selected items on a page, i.e. individual report question rows
 * This is used to define the selection scope of the command palette.
 *
 * @example
 * const selection = useAtomValue(selectionAtom);
 *
 * const handleSelection = (selection: CommandPaletteSelection) => {
 *   setSelection(selection);
 * };
 *
 * Make sure to unset the atom value when the selection is no longer relevant,
 * e.g. when unmounting a component:
 *
 * useEffect(() => {
 *   return () => setSelectionAtom(null);
 * }, [setSelectionAtom]);
 *
 * TODO: Add guardrails to avoid reading the selection outside the scope of
 * a page, e.g. User upload task selections in Reporting
 */
export const selectionAtom = atom<CommandPaletteSelection | null>(null);
